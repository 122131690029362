import { FormikProvider } from "formik";
import { useAddEvent } from "../../hooks/useAddEvent";
import { SelectValues } from "../../interfaces/calender";
import { AlertCustom } from "../AlertCustom";
import { FooterForm } from "./FooterForm";
import { InputCustom } from "./InputCustom";
import { SelectCustom } from "./SelectCustom";
import { TextTareaCustom } from "./TextTareaCustom";

interface Props {
  closeModal: () => void;
  handleAddEvent: (event: any) => void;
  options: SelectValues[];
}
export const AddEvent = ({ closeModal, handleAddEvent, options }: Props) => {
  const { formik, loading, handleClear, response, isAdmin, uploadFiles, removeFile, nameImgSelected, selectedFile, selectedImage } = useAddEvent({
    handleAddEvent,
    option: options[0].value,
    closeModal
  });

  console.log("options", options)

  return (
    <>
      {response && (
        <AlertCustom
          detail={response.detail}
          handleClear={handleClear}
          status={response.status}
          title={response.title}
        />
      )}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <InputCustom
            {...formik.getFieldProps("date")}
            id="date"
            label="Data dell'evento"
            placeholder="Data dell'evento"
            type="date"
          />
          <InputCustom
            {...formik.getFieldProps("start")}
            id="init"
            label="Inizio dell'evento"
            placeholder="Inizio dell'evento"
            type="time"
            disabled={formik.values.date === ""}
          />
          <InputCustom
            {...formik.getFieldProps("end")}
            id="init"
            label="Fine dell'evento"
            placeholder="Fine dell'evento"
            type="time"
            disabled={formik.values.date === ""}
          />
          <InputCustom
            {...formik.getFieldProps("title")}
            id="aggiungi"
            label="Aggiungi un titolo"
            placeholder="Aggiungi un titolo"
            type="text"
          />
          <SelectCustom
            {...formik.getFieldProps("bussines")}
            id="negozio"
            label="Negozio"
            placeholder="Negozio"
            options={options}
            disabled={!isAdmin}
          />
          <TextTareaCustom
            {...formik.getFieldProps("details")}
            id="particolari"
            label="Note"
            placeholder="Note"
            rows={5}
          />

          <div className='form-group mb-2 w-100 text-center'
            style={{
              width: '200px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}>
            {selectedImage ? (
              <p>
                <b>· Selezionato:</b> {selectedImage}
              </p>
            ) : null}

            <img
              src="https://i.postimg.cc/y8BSJnHY/rolex1.png"
              className="d-none text-center"
              style={{ height: "20%", width: "20%" }}
              id="img-container"
              alt=""
            />
            <br />

            {selectedFile && (
              <a
                onClick={() => removeFile()}
                style={{ cursor: "pointer" }}
                className="text-danger"
              >
                rimuovere {selectedFile}
              </a>
            )}
          </div>

          <div className='form-group mb-4'>

            <label htmlFor="document-files" style={{ cursor: "pointer" }}>
              <img src={require("../../assets/icons/link.png")} style={
                {
                  width: 25,
                  cursor: "pointer",
                  height: 25,
                  //marginHorizontal: 20,
                  //tintColor: "#b3b3b3",
                  position: "absolute",
                  right: 30
                }
              } alt="" />
            </label>
            <input
              type="file"
              id="document-files"
              accept="application/pdf"
              style={{ display: "none" }}
              {...formik.getFieldProps("fileCalendar")}
              onChange={(e) => uploadFiles(e.target.files, "document")}
            />

            <label htmlFor="image-files" style={{ cursor: "pointer" }}>
              <img src={require("../../assets/icons/camera2.png")} style={
                {
                  width: 25,
                  cursor: "pointer",
                  height: 25,
                  //marginHorizontal: 20,
                  //tintColor: "#b3b3b3",
                  position: "absolute",
                  right: 70
                }
              } alt="" />
            </label>
            <input
              type="file"
              name="image-files"
              id="image-files"
              accept="image/png, image/gif, image/jpeg"
              style={{ display: "none" }}
              {...formik.getFieldProps("imageCalendar")}
              onChange={(e) => uploadFiles(e.target.files, "image")}
            />
          </div>

          <FooterForm
            loading={loading}
            disabled={loading || formik.isSubmitting}
            btnCancel="Annulla"
            btnSubmit="Salvare"
            closeModal={closeModal}
          />
        </form>
      </FormikProvider >
    </>
  );
};