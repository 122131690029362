import aplicationConnect from "../../../api/applicationConnect";
import axios from "axios";
import ConfigCapone from "../../../utils/ConfigCapone";

export const bussinesList = async () => {
  try {
    const response = await aplicationConnect.get(`Bussines?parameter=1`);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const bussinesTaskByIdUser = async (idUser: Number) => {
  try {
    const response = await aplicationConnect.get(
      `TaskBussinesUsers/GetByIdUser`,
      {
        params: {
          idUser: idUser,
        },
      }
    );
    // debugger
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};

export const bussinesListByIdTask = async (id) => {
  
  try {
    const response = await aplicationConnect.get(
      `TaskBussines?value=${id}&parameter=idTask`
    );
    const { data } = response;

    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};

export const usersTasksSelected = async (id) => {

  try {
    const response = await aplicationConnect.get(`TaskUser/GetByIdTask/${id}`);
    const { data } = response;
   
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const deleteTasksIdUser = async (idTask, idUser) => {
  try {
    const response = await aplicationConnect.delete(
      `TaskUser/DeleteByIdTaskAndIdUser/${idTask}/${idUser}`
    );

    const { data } = response;
    if (data.status === "success") {
      console.log("TasksUser eliminado");

      return data;
    }
  } catch (error) {}
};

export const userListByIdTask = async (id) => {
 
  try {
    const response = await aplicationConnect.get(
      `TaskUser?value=${id}&parameter=idTask`
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};

//userTaskByIdUser
export const userTaskByIdUser = async (idUser: Number) => {
  try {
    const response = await aplicationConnect.get(`TaskUser/GetByIdUser`, {
      params: {
        idUser: idUser,
      },
    });
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const userListAll = async () => {
  try {
    const response = await aplicationConnect.get(`User?parameter=1`);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const postTask = async (dataSend: any) => {
  try {
    const response = await aplicationConnect.post(`Task`, dataSend);
    const { data } = response;
    if (data.status === "success") {
      console.log("task success");
      return data.id;
    }
  } catch (error) {
    throw new Error();
  }
};
export const putTasksData = async (dataModifi: any) => {
  console.log("entre puttaskdata?", dataModifi);

  //debugger;
  try {
    const response = await aplicationConnect.put(`Task`, dataModifi);
    const { data } = response;
    console.log("dataresponse", data);

    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};

export const deleteTasksDataBussines = async (id) => {
  try {
    const response = await aplicationConnect.delete(`TaskBusssines/${id}`);

    const { data } = response;
    if (data.status === "success") {
      console.log("TasksBussines eliminado");

      return data;
    }
  } catch (error) {}
};

export const deleteTasksDataUser = async (id) => {
  try {
    const response = await aplicationConnect.delete(`TaskUser/${id}`);

    const { data } = response;
    if (data.status === "success") {
      console.log("TasksUser eliminado");

      return data;
    }
  } catch (error) {}
};
export const deleteTaskData = async (id) => {
  deleteTaskDataBussines(id);
  deleteTaskDataUsers(id);

  try {
    const response = await aplicationConnect.delete(`Task/${id}`);

    const { data } = response;
    if (data.status === "success") {
      console.log("Task eliminado");

      return data;
    }
  } catch (error) {}
};

export const deleteTaskDataBussines = async (id) => {
  try {
    const response = await aplicationConnect.delete(`TaskBussines/${id}`);

    const { data } = response;
    if (data.status === "success") {
      console.log("TaskBussines eliminado");

      return data;
    }
  } catch (error) {}
};

export const deleteTaskDataUsers = async (id) => {
  try {
    const response = await aplicationConnect.delete(`TaskUser/${id}`);

    const { data } = response;
    if (data.status === "success") {
      console.log("TaskUsers eliminado");

      return data;
    }
  } catch (error) {}
};

export const postTaskBussinesService = async (dataSend: any) => {
  
  try {
    const response = await aplicationConnect.post(`TaskUser`, dataSend);
    
    const { data } = response;
    if (data.status === "success") {
      return data.id;
    }
  } catch (error) {
    throw new Error();
  }
};

export const postTaskBussinesData = async (dataSend: any) => {
 
  try {
    const response = await aplicationConnect.post(`TaskBussines`, dataSend);
    const { data } = response;
    if (data.status === "success") {
      return data.id;
    }

  } catch (error) {
    throw new Error();
  }
};






export const deleteTasksBussinesId = async (idTask, idBussines) => {
  try {
    const response = await aplicationConnect.delete(
      `TaskBussines/DeleteTaskBussinesByIdTaskAndIdBussines/${idTask}/${idBussines}`
    );
  
    const { data } = response;
    if (data.status === "success") {
      console.log("TaskUser eliminado");

      return data;
    }
  } catch (error) {}
};
//postTaskUserService
export const postTaskUserService = async (dataSend: any) => {
  
  try {
    const response = await aplicationConnect.post(`TaskUser`, dataSend);
    
    const { data } = response;
    if (data.status === "success") {
      return data.id;
    }
  } catch (error) {
    throw new Error();
  }
};
export const putTaskUserIsChecked = async (idTaskUser: number) => {
  //debugger;
  try {
    const response = await aplicationConnect.put(
      `TaskUser/isChecked?idTaskUser=${idTaskUser}&isChecked=1`
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};



export const putTaskBussinesIsChecked = async (id) => {
  try {
    const response = await aplicationConnect.put(
      `TaskBussinesUsers/isChecked?idTaskBussinesUser=${id}&isChecked=1`
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const listByidTaskBussines = async (idTaskBussines: Number) => {
  //debugger;
  try {
    const response = await aplicationConnect.get(
      `TaskBussinesUsers/GetByIdTaskBussines`,
      {
        params: {
          idTaskBussines: idTaskBussines,
        },
      }
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};

export const listByidTaskChecked = async (idTaskBussines: Number) => {
  //debugger;
  try {
    const response = await aplicationConnect.get(
      `TaskUser/GetByIdTaskChecked/${idTaskBussines}`,
      {
        params: {
          idTaskBussines: idTaskBussines,
        },
      }
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};

export const getPdf = async (name:string,ext:string) => {
  
  try {
    const response = await axios.get(
      `${ConfigCapone.urlapi}/api/UploadImage/${name}/${ext}`,
    );
    
    if (response.status == 200) {
      
      return response;
    }
  } catch (error) {
    
  }
};

export const getIdRoleData = async (iduser: number) => {
  
  try {
    const response = await aplicationConnect.get(`User/${iduser}`);
    const { data } = response;
    
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};
