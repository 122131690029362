import React from "react";
import { Text} from "react-native";
import { FilterMagazzino } from "./FilterMagazzino";

interface Props {
  onChangeTextProdotto:any;
  searchInputProdotto:any;
  handleSearchFilter:any;
}
export const SearchMagazzino = ({onChangeTextProdotto ,searchInputProdotto,handleSearchFilter}: Props) => {
  return (
    <>
      <div className="col-12 col-sm-7 col-md-6 col-lg-9">
        <div className="mt-2" style={{ marginLeft: -13 }}>
          <Text
            style={{
              fontWeight: "500",
              fontSize: 20,
              alignSelf: "center",
            }}
          >
            Cerca prodotto
          </Text>
        </div>
      </div>
      <div className="col-12 col-sm-5 col-md-6 col-lg-3">
        <FilterMagazzino
          placeholder="Cerca prodotto"
          marginRight
          handleSearchFilter={handleSearchFilter}
          onChangeTextProdotto={onChangeTextProdotto}
          searchInputProdotto={searchInputProdotto}
        />
      </div>
    </>
  );
};
