import { useIsFocused } from "@react-navigation/native";
import { useCallback, useEffect, useState } from "react";
import { View } from "react-big-calendar";
import { myEventsList } from "../components/Calender/elements";
import { AddEvent, EventCalender, ResponseEvent } from "../interfaces/calender";
import { getAllEvents,getAllEventsNotify } from "../services/calender";
import { useAuth } from "./useAuth";
import { useModal } from "./useModal";

export const useCalender = () => {
  const { user, permitedFilter } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState<EventCalender[]>([] as EventCalender[]);
  const [eventsNotify, setEventsNotify] = useState<EventCalender[]>([] as EventCalender[]);
  const [event, setEvent] = useState<EventCalender | undefined>(undefined);
  const [view, setView] = useState("");
  const isFocused = useIsFocused();

  const [eventsForMonthView, setEventsForMonthView] = useState([]);
  const [eventsByDay, setEventsByDay] = useState([]);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [dataDay, setDataDay] = useState([])
  const {closeModal : closeModalDay, isOpen: isOpenDay, setIsOpen: setIsOpenDay} = useModal()

  const handleView = (view: View) => {
    setView(view);
  };
  const handleChangeEvent = (event: EventCalender) => {
    setEvent(event);
  };
  const closeModal = () => {
    setEvent(undefined);
  };
  const handleData = async () => {
    const rol = permitedFilter ? 2 : user.idRole;
    try {
      const events: ResponseEvent[] = await getAllEvents(rol, user.idBussines);
      const normalizedEvents = events.map(
        ({ details, end, idCalendar, start, title }): EventCalender => {
          const date = start.split(" ");
          const normalizedDateStart = start.split(" ");
          const normalizedDateEnd = end.split(" ");
          return {
            details,
            end: new Date(`${normalizedDateEnd[0]}T${normalizedDateEnd[1]}`),
            start: new Date(
              `${normalizedDateStart[0]}T${normalizedDateStart[1]}`
            ),
            title,
            id: Number(idCalendar),
          };
        }
      );
      setEvents(normalizedEvents);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDataNotify = async () => {
    const rol = permitedFilter ? 2 : user.idRole;
    try {
      const events: ResponseEvent[] = await getAllEventsNotify(rol, user.idBussines);
      const normalizedEvents = events.map(
        ({ details, end, idCalendar, start, title }): EventCalender => {
          const date = start.split(" ");
          const normalizedDateStart = start.split(" ");
          const normalizedDateEnd = end.split(" ");
          return {
            details,
            end: new Date(`${normalizedDateEnd[0]}T${normalizedDateEnd[1]}`),
            start: new Date(
              `${normalizedDateStart[0]}T${normalizedDateStart[1]}`
            ),
            title,
            id: Number(idCalendar),
          };
        }
      );
      setEventsNotify(normalizedEvents);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEventClick = (event) => {
    console.log("event",event)  
    setDateSelected(event.start);
    const filteredEventsData = filteredEvents(event.start);
    if(filteredEventsData.length > 0) setIsOpenDay(true)
    setDataDay(filteredEventsData)
  };

  const filteredEvents = (date) => {
    return events.filter((event) => {
      const eventDate = new Date(event.start);
      return (
        date.getFullYear() === eventDate.getFullYear() &&
        date.getMonth() === eventDate.getMonth() &&
        date.getDate() === eventDate.getDate()
      );
    });
  };

  useEffect(() => {
    const eventsByDay = events.reduce((acc, event) => {
      const eventDate = new Date(event.start).toDateString();
      if (!acc[eventDate]) {
        acc[eventDate] = [];
      }
      acc[eventDate].push(event);
      return acc;
    }, {});

    const eventsForView = Object.values(eventsByDay).map((eventsOfDay:any) => {
      return {
        ...eventsOfDay[0],
        multiple: eventsOfDay.length > 1
      };
    });
    setEventsForMonthView(eventsForView);

    // event for day
    const eventsMultipleByDay = events.filter(event => {
      const eventDate = new Date(event.start);
      const currentDate = new Date();
      return (
        eventDate.getDate() === currentDate.getDate() &&
        eventDate.getMonth() === currentDate.getMonth() &&
        eventDate.getFullYear() === currentDate.getFullYear()
      );
    });
    setEventsByDay(eventsMultipleByDay)   
  }, [events]);

  useEffect(() => {
    if (user) {
      handleData();
      handleDataNotify();
    }
  }, [user,isFocused]);
  return {
    events,
    eventsNotify,
    event,
    handleView,
    handleChangeEvent,
    closeModal,
    isLoading,
    handleEventClick,
    setIsOpenDay,
    eventsForMonthView,
    dateSelected,
    isOpenDay,
    closeModalDay,
    dataDay,
    eventsByDay
  };
};
