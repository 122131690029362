/* eslint-disable multiline-ternary */
import { useField } from 'formik'

export const InputCustomStep = ({
  campo,
  id,
  placeholder,
  label,
  completed = 'col-md-6',
  ...props
}: any) => {
  const [field, meta] = useField(props)

  return (
    <div className={completed}>
      <div className="form-group mb-4">
        <label htmlFor={id}>{label}</label>
        <input
          {...field}
          className="form-control"
          id={campo}
          placeholder={placeholder}
          name={campo}
          aria-describedby={placeholder}
          {...props}
        />
        {meta.touched && Boolean(meta.error) ? (
          <div className="message-container mb-3">
            <div className="help-block">{meta.error}</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
