import { useContext, useEffect, useState } from "react";

export const useWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const updateWidth = () => {setWidth(window.innerWidth);};
        const updateHeight = () => {setHeight(window.innerHeight);};

        window.addEventListener('resize', updateWidth);
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateWidth);
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    return {
        width,
        setWidth,
        height,
        setHeight
    };

};
