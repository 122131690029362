import { StyleSheet } from "react-native";

export const RoundedButtonStyle = StyleSheet.create({
    button: {
        position: 'absolute',
       
        borderRadius: 1000,
        justifyContent: 'center',
    },
    icon: {
        color: 'white',
        alignSelf: 'center',
        fontWeight: '700',
        
    }
});