import { ModalCustom } from "../Modal/ModalCustom";
import ButtonWhite from "../Button/ButtonWhite";
import Select, { StylesConfig } from "react-select";
import { useEffect, useState } from "react";
import { View } from "react-native";
import { TouchableOpacity } from "react-native";
import { Image } from "react-native-elements";
import { styles } from "../../theme/GlobalStyle";
import { Controller, useForm } from "react-hook-form";
import Loading from "../Loading";
import axios from "axios";
import moment from "moment";
import ConfigCapone from "../../utils/ConfigCapone";

interface Props {
  isOpen: boolean;
  setIsOpen;
  BussinesList: [];
  UserList: [];
  UserTecnicoList: [];
  title: string;
  actionSave;
}
const styleInput = {
  width: "100%",
  backgroundColor: "transparent",
  height: 50,
  margin: 0,
  borderWidth: 2,
  borderRadius: 50,
  borderColor: "black",
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
};
const styleLabel = {
  fontSize: 18,
  marginLeft: 5,
  fontFamily: "Arsapia Regular font",
  color: "black",
};
const styleInputNoEdit = {
  width: "90%",
  height: 40,
  margin: 12,
  borderRadius: 50,
  marginVertical: 6,
  backgroundColor: "rgb(235, 235, 235)",
  fontFamily: "Arsapia Regular font",
  paddingLeft: 20,
};

const styleInputTextArea = {
  width: "90%",
  backgroundColor: "rgb(234, 234, 234)",
  height: "auto",
  borderRadius: 25,
  paddingLeft: 10,
  fontFamily: "Arsapia Regular font",
  textAlignVertical: "auto",
  padding: 30,
  margin: "12px",
};
const colourStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "rgb(235, 235, 235)",
    width: "90%",

    margin: 12,
    borderRadius: 50,
    marginVertical: 6,
    fontFamily: "Arsapia Regular font",
    color: "#a2a2a2",
    paddingLeft: 20,
  }),
};
const ModalForm = ({
  isOpen,
  setIsOpen,
  BussinesList,
  UserList,
  UserTecnicoList,
  title,
  actionSave,
}: Props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
  } = useForm();
  const [selectedOption, setSelectedOption] = useState(null);
  const [nameFileSelected, setNameFileSelected] = useState<string>();
  const [nameImageSelected, setNameImageSelected] = useState<string>();
  const [errorMsgCheck, seterrorMsgCheck] = useState<boolean>(false);
  const [bussinesListState, setBussinesListState] = useState()
  const [errorMsgInputCheck, seterrorMsgInputCheck] = useState<boolean>(false);

  const [bussinesListData, setBussinesListData] = useState([])
  const [bussinesOrigin, setBussinesOrigin] = useState([])

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);

    if (e.target.value == 'option2') {
      handleChange([])
    }
    if (e.target.value == 'option3') {
      handleChange([])
    }
  };
  const uploadFile = async (file: any, type: string, name: string, extension: string, typeFile) => {

    if (typeFile == "document") {
      let myNewFile: any = null;
      let formData = new FormData();

      myNewFile = new File([file[0]], name, {
        type: file[0].type,
      });

      formData.append("files", myNewFile);
      formData.append("type", typeFile);
      formData.append("name", name);
      formData.append("extension", extension);
      await axios
        .post(`${ConfigCapone.apicapone}/upload/` + type, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("res", res);
        });

    }

    if (typeFile == "image") {
      let myNewFile: any = null;
      let formData = new FormData();

      myNewFile = new File([file[0]], name, {
        type: file[0].type,
      });

      formData.append("files", myNewFile);
      formData.append("type", typeFile);
      formData.append("name", name);
      formData.append("extension", extension);
      await axios
        .post(`${ConfigCapone.apicapone}/upload/` + type, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("res", res);
        });

    }
  }

  const getFileName = () => {
    const formatDate = "YYYY_MM_DD_HH_mm_ss";
    let fileDate: any = new Date();
    const currentDate = moment(fileDate).format(formatDate);
    return currentDate;
  };

  const uploadFiles = (e: any, type: string) => {
    if (type == 'image') {
      setNameImageSelected(e ? e[0].name : null)
    }
    if (type == 'document') {
      setNameFileSelected(e ? e[0].name : null);
    }
  };

  const removeFile = (option: number) => {
    if (option == 1) {
      setNameImageSelected(null)
      setValue("image-files", '');
    } else {
      setNameFileSelected(null);
      setValue('document-files', '')
    }
  };

  const changeValuesSelected = () => {
    if (selectedOption == "option1") {
      setValue("Dipendenti", "");
      setValue("Tecnici", "");
    }
    if (selectedOption == "option2") {
      setValue("Store", "");
      setValue("Tecnici", "");
    }
    if (selectedOption == "option3") {
      setValue("Store", "");
      setValue("Dipendenti", "");
    }
  };


  const onSubmit = async (data) => {
    let file: any = getValues("document-files");
    let image: any = getValues("image-files");

    let firstValidation = validateChecks();
    let secondValidation = validateInputsChecks();

    if (!firstValidation && !secondValidation) {

      if (file.length > 0) {
        let extension: string = file[0].name.substr(
          file[0].name.lastIndexOf(".") + 1
        );
        let name: string = file[0].name?.split(".")[0];
        let fileName = name + "_" + getFileName() + "." + extension;
        let typeFile = "document"
        await uploadFile(file, "tasknews", fileName, extension, typeFile);
        setValue("nameFile", fileName);

      }

      if (image.length > 0) {
        let extension: string = image[0].name.substr(
          image[0].name.lastIndexOf(".") + 1
        );
        let name: string = image[0].name?.split(".")[0];
        let imageName = name + "_" + getFileName() + "." + extension;
        let typeFile = "image"
        await uploadFile(image, "tasknews", imageName, extension, typeFile);
        setValue("nameImage", imageName);
      }

      actionSave(getValues());
      reset();
    }
    reset();
  };


  const validateChecks = () => {
    if (selectedOption) {
      seterrorMsgCheck(false);
      return false
    } else {
      seterrorMsgCheck(true);
      return true
    }
  };


  const validateInputsChecks = () => {
    if (
      getValues("Dipendenti")?.length > 0 ||
      getValues("Store")?.length > 0 ||
      getValues("Tecnici")?.length > 0
    ) {
      seterrorMsgInputCheck(false);
      return false
    } else {
      seterrorMsgInputCheck(true);
      return true
    }
  };

  const handleChange = (options) => {

    if (options.some(opt => opt.value === "7")) {

      const selectedValues = bussinesOrigin.filter((opt: any) => opt.value !== "7")
      setValue('Store', selectedValues);
      setBussinesListData(selectedValues)
    } else {

      const isOptionAll = bussinesListData.find(opt => opt.value == '7')
      if ((bussinesListData.length - 1) == options.length && isOptionAll) {
        setValue('Store', options);
        setBussinesListData(options)
      } else {
        const data = bussinesListData.find(opt => opt.value == '7')
        if (!data) {
          bussinesListData.push({ label: 'Tutti', value: '7' })
        }
        setValue('Store', options);
      }
    }
  };

  useEffect(() => {
    changeValuesSelected();
  }, [selectedOption]);

  useEffect(() => {
    if (BussinesList) {
      console.log('bussines000', BussinesList)
      setBussinesListData(BussinesList)
      setBussinesOrigin(BussinesList)
    }
  }, [BussinesList])

  useEffect(() => {
    setNameFileSelected(null)
    setNameImageSelected(null)
  }, [isOpen])

  return (
    <>
      {isOpen && (
        <ModalCustom title={title} isOpen={isOpen} closeModal={setIsOpen}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group pt-0">
              <label htmlFor="" style={styleLabel}>
                <span>Titolo</span>
              </label>
              <input
                type="text"
                className="form-control mt-0"
                style={styleInputNoEdit}
                {...register("Titolo", {
                  required: "Per favore, inserisci un titolo valido",
                })}
              />
              {errors.Titolo && (
                <p style={{ color: "red" }}>{errors.Titolo.message}</p>
              )}
            </div>
            <div className="form-group mt-2">
              <label htmlFor="" className="mb-3 pt-2">
                <strong>Filtri</strong>
              </label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                id="option1"
                name="option1"
                value="option1"
                className="me-2"
                style={{ transform: "scale(1.6)" }}
                checked={selectedOption === "option1"}
                onChange={handleOptionChange}
              />
              <label htmlFor="option1" style={styleLabel}>
                Store
              </label>
            </div>
            <div className="form-group">
              <Controller
                name="Store"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    styles={colourStyles}
                    //defaultValue={[BussinesList[2], BussinesList[3]]}
                    isMulti
                    isDisabled={selectedOption !== "option1"}
                    options={bussinesListData}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    {...field}
                    onChange={handleChange}
                  />
                )}
              />
            </div>
            <div className="form-group pt-4">
              <input
                type="checkbox"
                id="option2"
                name="option2"
                value="option2"
                className="me-2"
                style={{ transform: "scale(1.6)" }}
                checked={selectedOption === "option2"}
                onChange={handleOptionChange}
              />
              <label htmlFor="option2" style={styleLabel}>
                Dipendenti
              </label>
            </div>
            <div className="form-group mb-0">
              <Controller
                name="Dipendenti"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    styles={colourStyles}
                    //defaultValue={[UserList[2], optionBussines[3]]}
                    isMulti
                    options={UserList}
                    isDisabled={selectedOption !== "option2"}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="form-group pt-4">
              <input
                type="checkbox"
                id="option3"
                name="option3"
                value="option3"
                className="me-2"
                style={{ transform: "scale(1.6)" }}
                checked={selectedOption === "option3"}
                onChange={handleOptionChange}
              />
              <label htmlFor="option3" style={styleLabel}>
                Tecnici
              </label>
            </div>
            <div className="form-group">
              <Controller
                name="Tecnici"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    styles={colourStyles}
                    //defaultValue={[UserList[2], optionBussines[3]]}
                    isMulti
                    options={UserTecnicoList}
                    isDisabled={selectedOption !== "option3"}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    {...field}
                  />
                )}
              />
            </div>
            {errorMsgCheck && (
              <p style={{ color: "red" }}>
                Per favore, seleziona almeno un'opzione.
              </p>
            )}
            {errorMsgInputCheck && (
              <p style={{ color: "red" }}>
                Per favore, seleziona almeno un contenuto dalle opzioni.
              </p>
            )}
            <div className="form-group mb-2">
              <label htmlFor="textArea" className="mb-2" style={styleLabel}>
                <span>Testo</span>
              </label>
              <textarea
                name="textArea"
                id=""
                cols={30}
                rows={5}
                className="form-control mt-0"
                style={styleInputTextArea}
                {...register("Testo")}
              ></textarea>
            </div>

            <div className="form-group mb-1 w-100 text-center">
              {nameImageSelected ? (
                <p className="container-max-name">
                  <b>· Selezionato:</b> {nameImageSelected}
                </p>
              ) : null}

              {nameImageSelected && (
                <a
                  onClick={() => removeFile(1)}
                  style={{ cursor: "pointer", position: "relative", top: -15 }}
                  className="container-max-name"
                >
                  Rimuovere: {nameImageSelected}
                </a>
              )}
            </div>

            <div className="form-group mb-2 w-100 text-center">
              {nameFileSelected ? (
                <p className="container-max-name">
                  <b>· Selezionato:</b> {nameFileSelected}
                </p>
              ) : null}

              {nameFileSelected && (
                <a
                  onClick={() => removeFile(2)}
                  style={{ cursor: "pointer", position: "relative", top: -15 }}
                  className="container-max-name"
                >
                  Rimuovere: {nameFileSelected}
                </a>
              )}
            </div>

            <div className="d-flex">
              <div className="col-5 w-100 text-center">
                <ButtonWhite title="Salva" ModalAction={() => { }} disabled={false} />
              </div>
              <div className="col-5 pt-1">
                <label htmlFor="image-files" style={{ cursor: "pointer", marginLeft: '' }}>
                  <img src={require("../../assets/icons/camera2.png")} style={
                    {
                      width: 25,
                      cursor: "pointer",
                      height: 25,
                      //marginHorizontal: 20,
                      //tintColor: "#b3b3b3",
                      marginLeft: -90,
                      right: 70
                    }
                  } alt="" />
                </label>
                <input
                  type="file"
                  name="image-files"
                  id="image-files"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                  {...register("image-files", {
                    onChange: (e) => uploadFiles(e.target.files, "image"),
                  })}
                />
                <label htmlFor="document-files" style={{ cursor: "pointer", marginLeft: '' }}>
                  <img
                    src={require("../../assets/icons/link.png")}
                    style={{
                      width: 25,
                      cursor: "pointer",
                      height: 25,
                      //marginHorizontal: 20,
                      //tintColor: "#b3b3b3",
                      marginTop: 0,
                      marginLeft: -55,
                    }}
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="document-files"
                  accept="application/pdf"
                  style={{ display: "none" }}
                  //onChange={(e) => uploadFiles(e.target.files, "document")}
                  {...register("document-files", {
                    onChange: (e) => uploadFiles(e.target.files, "document"),
                  })}
                />

              </div>
            </div>
          </form>

        </ModalCustom>
      )}
    </>
  );
};

export default ModalForm;
