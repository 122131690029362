import React from "react";
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { View, Image, Text, StatusBar, TouchableOpacity, StyleSheet, Button } from "react-native";
import { styles } from "../../theme/GlobalStyle";
import { SafeAreaView } from "react-native-safe-area-context";
import { RoundedButton } from "../../components/RoundedButton";
import "../../theme/styleBackground.css";
import { Navbar } from "../../components/Navbar";
import { useIsFocused } from "@react-navigation/native";

interface Props extends NativeStackScreenProps<any, any> { }
export const MagazzinoHomeScreen = ({ navigation }: Props) => {

  const isFocused = useIsFocused();

  return (
    <div className="square">
      <SafeAreaView
        style={stylesMagazzino.safeArea}
      >
        <div className="container pt-5 roitateDiv">
          <View style={[styles.containerHome]}>
            <div
              className="row text-center"
              style={{ paddingTop: 10, paddingLeft: 45 }}
            >
              <div className="col-12 d-flex justify-content-center">
                <View style={stylesMagazzino.backButtonContainer}>
                  <TouchableOpacity onPress={() => navigation.navigate("HomeSCDueScreen", {  refresh  : true })}>
                    <View>
                      <Image
                        style={stylesMagazzino.backButton}
                        source={require("../../assets/iconoskubitsoft/Atras-01.png")}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
 
                <Image
                  style={stylesMagazzino.logoCapone}
                  source={require("../../assets/images/SergioCaponeLogo.jpg")}
                />
              </div>
            </div>
            <View style={{ justifyContent: "center", flex: 1, marginTop: 50 }}>
              <View style={{ justifyContent: "center", flex: 1, padding: 25 }}>
                <div className="row">
                  <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-12 m-auto mt-3">
                    <TouchableOpacity
                      onPress={() => navigation.navigate("MagazzinoCercaScreen", { controlPanel: false })}
                    >
                      <RoundedButton
                        icon={require("../../assets/iconoskubitsoft/Lupa-02.png")}
                        size={48}
                        position={{ top: -10, left: -32 }}
                        styleIcon={90}
                      />
                      <View style={styles.largeButton}>
                        <Text
                          style={stylesMagazzino.textButton}
                        >
                          Cerca Prodotto
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-12 m-auto mt-3">
                    <TouchableOpacity
                      onPress={() => navigation.navigate("MagazzinoAddScreen")}
                    >
                      <RoundedButton
                        icon={require("../../assets/icons-step-due/Icona-plus.png")}
                        size={48}
                        styleIcon={81}
                        position={{ top: -10, left: -26 }}
                      />
                      <View style={styles.largeButton}>
                        <Text
                          style={stylesMagazzino.textButton}
                        >
                          Carica Prodotto
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </div>
                </div>
              </View>
            </View>
          </View>
        </div>
      </SafeAreaView>
    </div>
  )
}

const stylesMagazzino = StyleSheet.create({
  backButtonContainer: {
    position: "absolute",
    top: -35,
    left: -90
  },
  backButton: {
    width: 150,
    height: 150,
    alignSelf: 'flex-start',
    tintColor: 'black',
  },
  safeArea: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
    backgroundColor: "white",
  },
  logoCapone: {
    height: 100,
    width: 300,
    margin: 0,
  },
  textButton: {
    fontSize: 20,
    fontWeight: "500",
    textAlign: "center",
  }
})
