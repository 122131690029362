import { useIsFocused } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Notify, ResponseNotify } from "../interfaces/Notify";
import { getAllNotify } from "../services/Notify";

interface NotifyData {
  length: number;
  items: Notify[];
  normalizedResults: Notify[];
}
export const useNotify = () => {
  const [notify, setNotify] = useState<NotifyData | undefined>(undefined);
  const isFocused = useIsFocused();
  const handleNotify = async () => {
    try {
      const results: ResponseNotify[] = await getAllNotify(2);
      const normalizedResults = results.map(
        ({
          actionLog,
          dateExport,
          id,
          message,
          read,
          idUser,
          name,
          lastName,
        }): Notify => {
          return {
            date: dateExport,
            id: Number(id),
            message,
            read: Number(read),
            subject: actionLog,
            user: name + " " + lastName,
            coduser: Number(idUser),
          };
        }
      );
      const rows = normalizedResults.filter((item) => item.read === 0);
      setNotify({
        length: rows.length,
        items: rows.slice(0, 3),
        normalizedResults,
      });
    } catch (error) {}
  };
  useEffect(() => {
    handleNotify();
  }, [isFocused]);
  return { notify };
};
