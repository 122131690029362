import {
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";

import { styles } from "../../../../../theme/GlobalStyle";


import { useEffect, useState } from "react";

import ConfigCapone from "../../../../../utils/ConfigCapone";
import axios from "axios";

import { useContext } from "react";


import { UserContext } from "../../../../../context/UserContext";
import Loading from "../../../../../components/Loading";
import { CheckLabel } from "../../../../../components/CheckLabel";
import { HeaderModal } from "../../../../../components/HeaderModal";
import ModalOptionSchemaProdotto from "../../../../../components/magazzino/ModalOptionSchemaProdotto";
import ModalOptionSpostamentoNegozio from "../../../../../components/magazzino/ModalOptionSpostamentoNegozio";
import ModalOptionClienteEsistente from "../../../../../components/magazzino/ModalOptionClienteEsistente";
import ButtonComponent from "../../../../../components/ComponentsAtelier/buttoms/buttonComponent";
import '../../Form/css/magazzinoAtelierForm.css';
import ModalClientExitsAtelier from "./ModalClientExitsAtelier";
import { useMagazzinoAtelierModal } from "../hooks/useMagazinoAtelierModal";
import { id } from "date-fns/locale";
import { set } from 'date-fns';
import ModalSellProdottoAtelier from "./ModalSellProdottoAtelier";
import ModalMoveStoreAtelier from "./ModalMoveStoreAtelier";
import { useWidth } from "../../../../../core/hook/useWidth";


interface ISchemaProdottoCard {
  modalVisible: any;
  setModalVisible: any;
  navigation?: any
  dataMagazzino?: any
  dataMatricola?: any
  listMagazzino?: any
  goToSchedaProdotto?: any
  goToCrm?: any
  getDataMagazzino?: any
}

const SchemaProdottoAtelierCard = ({
  modalVisible = false,
  setModalVisible,
  navigation,
  dataMagazzino,
  dataMatricola,
  listMagazzino,
  goToSchedaProdotto,
  goToCrm,
  getDataMagazzino
}: ISchemaProdottoCard) => {

  const {
    activeSpinner,
    changeListOnline,
    checked,
    changeListMatricola,
    goToEdit,
    idMatricola,
    idBussinessSeller,
    typeMatricola,
    updateStateMatricole,
    modalOptionClienteEsistente, 
    setModalOptionClienteEsistente,
    autosuggestModalClientEsistenteInput,
    setAutosuggestModalClientEsistenteInput,
    searchModalInputClient, 
    setSearchModalInputClient,
    handleSearchModalFilter,
    modalVisibleCardSell, 
    setModalVisibleCardSell,
    imageArrayList
    } = useMagazzinoAtelierModal({navigation, setModalVisible, listMagazzino, dataMagazzino, goToSchedaProdotto, goToCrm,dataMatricola,getDataMagazzino});
    
    const { width } = useWidth()

    const [noInput, setNoInput] = useState({
      height: 40,
      margin: 12,
      borderRadius: 50,
      marginVertical: 6,
      backgroundColor: "rgb(235, 235, 235)",
      fontFamily: "Arsapia Regular font",
      color: "#a2a2a2",
      paddingLeft: 20,
      width: 390
    })

    useEffect(() => {
      if(width < 500){
        if(width < 376){
          setNoInput({...noInput, width: 265})
        }else{
          setNoInput({...noInput, width: 280})
        }
      }else{
        setNoInput({...noInput, width: 390})
      }
    }, [width])
    

  return (
    <View style={styleSchemaProdottoCard.centeredView}>
      {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <View
          style={[styleSchemaProdottoCard.centeredView, { backgroundColor: "rgba(0,0,0,.32)" }]}
        >
          <View style={styleSchemaProdottoCard.modalView}>
            <HeaderModal
              setModalVisible={setModalVisible}
              modalVisible={modalVisible}
              title={"Scheda prodotto"}
            />
            <ScrollView>           

              <Text style={styles.label}>Brand</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.desc_brand ? dataMagazzino?.desc_brand : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Prezzo</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.prezzo ? parseFloat(dataMagazzino?.prezzo).toFixed(2) + ' €' : "0.00"
                }
                editable={false}
              />

              <Text style={styles.label}>Referenza</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.referencia ? dataMagazzino?.referencia : "Nessun dato"
                }
                editable={false}
              />
             
              <Text style={styles.label}>Descrizione</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.desc_famiglia ? dataMagazzino?.desc_famiglia : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Anno</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.year ? dataMagazzino?.year : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Informazione su richiesta</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.informationRequest ? dataMagazzino?.informationRequest : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Diametro</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.diameter ? dataMagazzino?.diameter : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Collezione</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.collection ? dataMagazzino?.collection : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Cassa</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.house ? dataMagazzino?.house : "Nessun dato"
                }
                editable={false}
              />

              
              <Text style={styles.label}>Vetro</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.glass ? dataMagazzino?.glass : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Tenuta</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.estate ? dataMagazzino?.estate : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Movimento</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.movement ? dataMagazzino?.movement : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Calibro</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.caliber ? dataMagazzino?.caliber : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Riserva di carica</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.powerReserve ? dataMagazzino?.powerReserve : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Bracciale</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.bracelet ? dataMagazzino?.bracelet : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Colore quadrante</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.coloreQuadrante ? dataMagazzino?.coloreQuadrante : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Foto allegate</Text>
               {
                imageArrayList.length > 0 
                ?
                <div className='text-center' style={{margin: 0}}>
                    {imageArrayList.map((image, index) => (
                      <div key={index}>                                         
                          { image?.id 
                              ? <img src={`${ConfigCapone.apicapone}/image/${image.name}`} className="img-fluid" alt="Image" style={{ width: 230, border: '1px solid black', marginTop: 5 }} />
                              : <img src={URL.createObjectURL(image)} className="img-fluid" alt="Image" style={{ width: 230, border: '1px solid black', marginTop: 5 }} /> 
                          }  
                          <br />
                          <a style={{color: 'rgb(83 130 201)'}} >{image.name}</a>                                                                                       
                      </div>
                    ))}
                </div>   
                :
                <div className='allegate-button'>
                  <div style={{marginTop:8, marginLeft: 16}}>
                      <div className='button-import-img' >
                          <label style={{border: '1px solid', borderColor: 'black', borderRadius: '20px', padding: '2px 10px', color: 'white', backgroundColor: 'black'}}>
                              <i className="fas fa-light fa-camera"></i>
                              Scegli il file
                          </label>
                          <label>Nessun file selezionato</label>
                      </div>            
                      <label style={{fontSize:'10px'}}>Importa file (max 1MB)</label>
                  </div>            
              </div>
              }
              
              <Text style={styles.label}>Scatola</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.box ? dataMagazzino?.box == '1' ? 'Si' : 'No' : "Nessun dato"
                }
                editable={false}
              />

               <Text style={styles.label}>Garanzia</Text>
              <TextInput
                style={noInput}
                value={
                  dataMagazzino?.guarantees ? dataMagazzino?.guarantees  == '1' ? 'Si' : 'No' : "Nessun dato"
                }
                editable={false}
              />          

              {dataMagazzino?.quantity != 0 && <Text style={styles.label}>Matricola</Text>}

              {(dataMatricola)
                ?
                (dataMatricola.map((data, index) => {
                  return (
                    <div className="row mb-5" key={index}>
                      <div className="col-5 mt-2">

                        <Text style={styleSchemaProdottoCard.buttonOutline}
                        >{
                            data?.matricola ? (data?.matricola.length < 10 ? data?.matricola : data?.matricola.substr(1, 7) + "...") : "Nessun dato"}</Text>
                      </div>
                      <div className="col-7">

                        {/* <TouchableOpacity>
                          <CheckLabel
                            name="Spostamento"
                            id={'Spostamento' + data.idMatricola}
                            type="text"
                            checked={checked}
                            onChange={(value) => changeListMatricola("PutMoveBusiness", value, data.idMatricola, data.matricola)}
                            text="Spostamento negozio"
                            marginleft={true}
                          />
                        </TouchableOpacity> */}


                        <TouchableOpacity>
                          <label htmlFor={'meso' + data.idMatricola} style={{ fontSize: 18, marginBottom: 5, fontFamily: "Arsapia Regular font" }}>
                            <input
                              type="checkbox"
                              name="meso"
                              className="checkDoble"
                              style={{
                                margin: 0,
                                marginRight: 15,
                                fontSize: 100
                              }}
                              checked={data.putAside}
                              id={'meso' + data.idMatricola}
                              onChange={({ target }) => changeListOnline("PutAside", target.value, data.idMatricola)}
                            />
                            Messo da parte
                          </label>

                        </TouchableOpacity>

                        <TouchableOpacity>
                          <CheckLabel
                            name="vendita"
                            id={'vendita' + data.idMatricola}
                            checked={checked}
                            onChange={(value) => changeListMatricola("PutSale", value, data.idMatricola)}
                            type="text"
                            text="Vendita"
                            marginleft={true}

                          />
                        </TouchableOpacity>

                        <TouchableOpacity>
                          <CheckLabel
                            name="venditaNuovoCliente"
                            id={'venditaNuovoCliente' + data.idMatricola}
                            checked={checked}
                            onChange={(value) => changeListMatricola("PutSaleNewCustomer", value, data.idMatricola)}
                            type="text"
                            text="Vendita ad un nuovo cliente"
                            marginleft={true}

                          />
                        </TouchableOpacity>

                        <TouchableOpacity>
                          <CheckLabel
                            name="venditaExistClient"
                            id={'venditaExistClient' + data.idMatricola}
                            type="text"
                            checked={checked}
                            onChange={(value) => changeListMatricola("PutSaleExistingCustomer", value, data.idMatricola)}
                            text="Vendita ad un cliente già esistente"
                            marginleft={true}

                          />
                        </TouchableOpacity>

                        <TouchableOpacity>

                          <label htmlFor={'online' + data.idMatricola} style={{ fontSize: 18, marginBottom: 5, fontFamily: "Arsapia Regular font" }}>
                            <input
                              type="checkbox"
                              name="online"
                              className="checkDoble"
                              checked={data.putOnline}
                              style={{
                                margin: 0,
                                marginRight: 15,
                                fontSize: 100
                              }}
                              id={'online' + data.idMatricola}
                              onChange={({ target }) => changeListOnline("PutOnline", target.value, data.idMatricola)}
                            />
                            Online
                          </label>
                        </TouchableOpacity>
                      </div>
                    </div>
                  )
                }))
                : ""
              }


              {dataMagazzino?.quantity >= 0 &&
                <View>
                  <button
                    style={{
                      width: 200,
                      marginRight: 15,
                      marginBottom: 30,
                      marginTop: dataMagazzino?.quantity == 0 && 30,
                      height: 40,
                      alignSelf: "end",
                      backgroundColor: "transparent",
                      borderRadius: 50,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => goToEdit(dataMagazzino, dataMatricola)}
                    // disabled={!activeButton}
                  >
                    Modifica Scheda
                  </button>
                </View>
              }

              { 
                <ModalSellProdottoAtelier 
                  idMatricola={idMatricola}
                  idBussinessSeller={idBussinessSeller}
                  typeMatricola={typeMatricola}
                  goToSchedaProdotto={goToSchedaProdotto}
                  updateStateMatricole={() => updateStateMatricole(idMatricola, true, typeMatricola, 0)}
                  modalVisible={modalVisibleCardSell}
                  setModalVisible={setModalVisibleCardSell}
                  setModalSchedaProdotto={setModalVisible}            
                /> 
              }

              {
                <ModalClientExitsAtelier 
                  idMatricola={idMatricola}
                  idBussinessSeller={idBussinessSeller}
                  typeMatricola={typeMatricola}
                  goToSchedaProdotto={goToSchedaProdotto}
                  updateStateMatricole={updateStateMatricole}
                  modalVisible={modalOptionClienteEsistente}
                  setModalVisible={setModalOptionClienteEsistente}
                  searchModalInputClient={searchModalInputClient}
                  setSearchModalInputClient={setSearchModalInputClient}
                  handleSearchModalFilter={handleSearchModalFilter}
                  dataMagazzino={dataMagazzino}
                  autosuggest={autosuggestModalClientEsistenteInput}
                  setAutosuggest={setAutosuggestModalClientEsistenteInput}
                  setModalSchedaProdotto={setModalVisible}
                />
              }
            </ScrollView>
          </View>
        </View>
      </Modal>
    </View>
  )

}


export const styleSchemaProdottoCard = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    flex: 1,
  },
  inputNotEdit: {
    height: 40,
    margin: 12,
    borderRadius: 50,
    marginVertical: 6,
    backgroundColor: "rgb(235, 235, 235)",
    fontFamily: "Arsapia Regular font",
    color: "#a2a2a2",
    paddingLeft: 20,
  },
  button: {
    width: 200,
    marginTop: 30,
    height: 40,
    alignSelf: "auto",
    backgroundColor: "transparent",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonOutline: {
    height: 45,
    width: "100%",
    borderWidth: 2,
    fontFamily: "Arsapia Regular font",
    color: "#ADADAC",
    fontWeight: "400",
    paddingLeft: 26,
    paddingRight: 26,
    paddingBottom: 8,
    paddingTop: 8,
    marginTop: '50%',
    textAlign: 'center',
    borderRadius: 50,
    marginRight: "1%",
  }
});

export default SchemaProdottoAtelierCard;
