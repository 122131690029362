import * as Yup from "yup";
import { useFormik } from "formik";
import { useFormRepair } from "./useFormRepair";
import { selectProps } from "../../interfaces/formRepair";
import { useEffect, useState } from "react";
import { getBrand } from "../../services/FormRepair";

export const useRepair = () => {
  const { initialValues, next } = useFormRepair();
  const [optionsBrand, setOptionsBrand] = useState<selectProps[]>(
    [] as selectProps[]
  );
  const repairSchema = Yup.object().shape({
    brandOfProduct: Yup.string().required("Il campo è obbligatorio"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: repairSchema,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        try {
          next(values);
          setSubmitting(false);
        } catch (error) {
          console.log(error);
        }
      }, 500);
    },
  });
  const handleData = async () => {
    try {
      const results = await getBrand();
      setOptionsBrand(results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleData()
  }, [])
  
  return { formik, optionsBrand };
};
