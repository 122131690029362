import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { dateTimeNow, FindByIdClientCrm, FindClient, getAllBussines, getAllInteressi, getTitle, ListInteressiByClient, ModifyClientManifestazione, postInteressiClient, postNewInteressiClient } from '../services/interessiServices';
import ConfigCapone from '../utils/ConfigCapone';
import { IClient } from '../interfaces/IClient';
import { getInteressiByClient } from '../services/InteressiDetails';
import { useUserPermission } from './useUserPermission';
import { UserContext } from '../context/UserContext';
import { deleteInteressiClientDetails } from '../services/InteressiDetails';
import { formatMessageEmailInteressi } from '../screens/CRM/FormatMessageEmail/formatMessageEmailInteressi';
import { httpGetClient } from '../screens/CRM/Atelier/services/CrmAtelierFormServices';
import { formatMessageEmailInteressiAtelier } from '../screens/CRM/FormatMessageEmail/formatMessageEmailInteressiAtelier';
const useAddListInteressi = (
    idClientExist: any,
    setIdClientExist: any,
    dataClient: any,
    setDataClient: any,
    getValues: any,
    setValue: any,
    optionAddEdit: any,
    setOptionAddEdit: any,
    setmodaleMessaggeWithUrl: any,
    setModalVisibleCardBlackList: any,
    setActiveSpinner: any,
    isAtelier?: boolean,
) => {

    const { userInLogin } = useContext(UserContext);
    const { isAdmin, isDipendente, isDirectore, bussinesUser } = useUserPermission()
    const [dataBussinesList, setDataBussinesList] = useState([]);
    const [dataInteressi, setDataInteressi] = useState([]);
    const [dataListInteressiByClient, setDataListInteressiByClient] = useState([]);
    const [titleType, setTitleType] = useState([]);
    const [currentValue, setCurrentValue] = useState(0);
    const [hoverValue, setHoverValue] = useState(undefined);
    const stars = Array(3).fill(0);
    //delete Liste
    const [modal, setModal] = useState(false);
    const [idDeleteUser, setIdDeleteUser] = useState(0);
    const [idDeleteClient, setIdDeleteClient] = useState(0);
    // blacklist
    const [stateBlackList, setStateBlackList] = useState(false);
    const [stateIsImportant, setStateIsImportant] = useState(false);
    const [checkBoxBlackListChecked, setCheckBoxBlackListChecked] = useState(false);
    const [checkBoxIsImportantChecked, setCheckBoxIsImportantChecked] = useState(false);

    // suggestion
    const [searchInputName, setSearchInputName] = useState<string>();
    const [autosuggestInputName, setAutosuggestInputName] = useState<IClient[]>([]);

    //
    const [checkBoxEmail, setCheckBoxEmail] = useState(false);
    //Ringraziamento
    const [checkBoxEmailRingraziamento, setCheckBoxEmailRingraziamento] = useState<any>([]);
    const colors = {
        orange: "black",
        grey: "#EAECEF"
    };
    let idListDeleteClient = 0;
    let idClientDelete = 0;
    const handleClickStart = (value, state?: boolean) => {
        if (value == currentValue && !state) {
            setCurrentValue(0);
        } else {
            setCurrentValue(value)
        }
    }

    const handleMouseOverStart = newHoverValue => {
        setHoverValue(newHoverValue)
    };

    const handleMouseLeaveStart = () => {
        setHoverValue(undefined)
    }

    //
    const changeBlackList = (value: any, state?: boolean) => {
        setStateBlackList(false);
        setCheckBoxBlackListChecked(value)
        if (checkBoxBlackListChecked == value && !state) {
            setCheckBoxBlackListChecked(null)
        }
    }
    const changeIsImportant = (value: any, state?: boolean) => { // changeIsImportant
        console.log("estas en el check: ", value)
        setStateIsImportant(false);
        setCheckBoxIsImportantChecked(value)
        if (checkBoxIsImportantChecked == value && !state) {
            setCheckBoxIsImportantChecked(null)
        }
    }


    // addListInteressi
    const addListManifestazione = async (idClient: number, getValues: any) => {
        let EmailSend24Hours = 0;
        if (checkBoxEmailRingraziamento[`Ringraziamento-0`]) {
            EmailSend24Hours = checkBoxEmailRingraziamento[`Ringraziamento-0`];
        }
        const listInteressi = {
            idClient: idClient,
            idList: getValues("ListInserire") ? getValues("ListInserire") : null,
            DateInsert: getValues("DateInsert") ? dateTimeNow(getValues("DateInsert")) : dateTimeNow(),
            Note: getValues("NoteInteressi") ? getValues("NoteInteressi") : null,
            EmaildiRingraziamento: EmailSend24Hours,
        }
        let status = await postInteressiClient(listInteressi);
        return status
    }

    // onChangeText
    const onChangeTextName = async (text?: string) => {
        setSearchInputName(text);
        if (text.length > 0) {
            if (optionAddEdit == false) {
                if (!isAtelier) {
                    await axios
                        .get(`${ConfigCapone.urlapi}/api/Client/${text}/10/`)
                        .then((response) => {
                            setAutosuggestInputName(response.data.response)
                        })
                        .catch((err) => console.log(err));
                } else {
                    const response = await httpGetClient(text);
                    setAutosuggestInputName(response.response)
                }
            }
        }
    };

    // dataInteressi
    const getAllListInteressi = () => {
        const interessi = getAllInteressi(0, 0, isAtelier)
        interessi.then((response) => {
            if (isAdmin) {
                if (!isAtelier) {
                    setDataInteressi(response.data.response)
                } else {
                    setDataInteressi(response.data.response?.filter(x => x.idBussines == '9'))
                }
            } else {
                if (isDirectore) {
                    let result = response.data.response?.filter((x: any) => x.idBussines == userInLogin.idBussines);
                    setDataInteressi(result)
                }
                if (isDipendente) {
                    setDataInteressi([])
                }
            }
        })
    }

    // data list interessi by client
    const getListClientInteressi = async () => {
        if (idClientExist != 0) {
            const result: any = await ListInteressiByClient(idClientExist);
            setDataListInteressiByClient(result);
        }
    }
    const getListTitle = async () => {
        const result: any = await getTitle();
        setTitleType(result);
    }
    //ListBussines
    const getListBussines = async () => {
        const result: any = await getAllBussines()
        setDataBussinesList(result)
    }
    // resultData
    const resultSearch = async (data: IClient, active?: boolean) => {
        console.log(data)
        setDataClient(data);
        setValue("Title", data.idTitle ? data.idTitle : "")
        setValue("Name", data?.name ? data.name : "");
        setValue("Phone", data?.phone ? data.phone : "");
        setValue("Email", data?.email ? data.email : "");
        setValue("Note", data?.note ? data.note : "");
        setIdClientExist(Number(data.idClient));
        handleClickStart(data.starValuation ? Number(data.starValuation) : 0, data.starValuation && true);
        changeBlackList((data.blackList != null && data.blackList != 0) ? "blackList-1" : "", data.blackList && true);
        changeEmail((data.sendEmail != null && data.sendEmail != 0) ? true : "", data.sendEmail && true);


        let ListEmailRingraziamento: any = [];

        setCheckBoxEmailRingraziamento([]);
        await axios
            .get(`${ConfigCapone.urlapi}/api/ListInteressiClient/FindByIdClient/${data.idClient}`)
            .then((response) => {
                ListEmailRingraziamento = response.data.response
            })
            .catch((err) => console.log(err));

        ListEmailRingraziamento.forEach((element: any) => {
            if (element.emaildiRingraziamento == "1") {
                changeEmailRingraziamento(`Ringraziamento-${element.id}`)
            }
        });
        console.log(ListEmailRingraziamento)
        setValue("OriginIdBussines", data?.origenIdBussines ? data?.origenIdBussines : "")
        const interessiByClient = getInteressiByClient(Number(data.idClient))
        interessiByClient.then((response) => {
            if (response != undefined) {
                if (response.data.response.length > 0) {
                    setValue("PresentCrm", "Presente");
                }
                else {
                    setValue("PresentCrm", "Non presente");
                }
            } else {
            }
        })

        setIdClientExist(Number(data?.idClient));
        if (data.blackList != null && data.blackList != 0) {
            if (!active) setModalVisibleCardBlackList(true);
        }
        const result: any = await ListInteressiByClient(Number(data?.idClient));
        setValue("ListInserire", "");
        setValue("DateInsert", "");
        setValue("NoteInteressi", "")//result[0].note
        setOptionAddEdit(true);
        getListClientInteressi();

    }

    // send email
    const changeEmail = async (value?: any, state?: boolean) => {
        setCheckBoxEmail(value)

        if (checkBoxEmail == value && !state) {
            setCheckBoxEmail(null)
        }

        if (value) {
            if (dataClient) {
                if (dataClient?.email?.length > 0) {

                    let dataSendEmail = {
                        type: "notice",
                        email: dataClient?.email,
                        subject: "Elenco degli interessi",
                        message: `Grazie per esserti iscritto alla lista degli interessi`
                    };
                    await axios
                        .post(
                            `${ConfigCapone.apicapone}/api/forma`,
                            dataSendEmail
                        )
                        .then((res) => {
                            console.log("email send");
                            state = true
                        })
                        .catch((err) => {
                            console.log("there was an error: " + err)
                            state = false
                        });

                } else {
                    setmodaleMessaggeWithUrl({
                        active: true,
                        message: "L'utente non ha un'email registrata.",
                        title: "Errore",
                        route: "",
                    });
                    setCheckBoxEmail(false);
                }
            }
        }

        if (dataClient) {
            if (dataClient?.email?.length > 0) {
                await axios
                    .put(`${ConfigCapone.urlapi}/api/Client/ModifyEmail/${dataClient.idClient}/${value}`)
                    .then((response) => {
                        console.log("email update")
                    })
                    .catch((err) => {
                        console.log("there was an error: " + err)
                    });
            }
        }
    }
    const changeEmailRingraziamento = async (value?: any) => {
        setCheckBoxEmailRingraziamento((state: any) => ({
            ...state,
            [value]: state[value]
                ? 0 : 1
        }))
        console.log("renderizad")
    }

    const changeStatusEmailSend = async () => {
        let idArray: any;
        console.log(Object.keys(checkBoxEmailRingraziamento).length)
        for (const [key, value] of Object.entries(checkBoxEmailRingraziamento)) {
            idArray = key?.split("-")
            console.log({ id: idArray[1], emaildiRingraziamento: value })
            if (idArray[1] != 0) {
                await axios
                    .put(`${ConfigCapone.urlapi}/api/ListInteressiClient/SendEmaildiRingraziamento`, { id: idArray[1], emaildiRingraziamento: value })
                    .then((response) => {
                    })
                    .catch((err) => console.log(err));
            }

        }
        // let dataSend:any = [];
        // let idArray:any;
        //     let dataListIds =Object.keys(checkBoxEmailRingraziamento)
        //     console.log(dataListIds)
        //     for (let index = 0; index < dataListIds.length; index++) {

        //         idArray = dataListIds[index]?.split("-")
        //        dataSend.push({id: Number(idArray[1])})               
        //     }
        //     console.log(dataSend)
    }
    const verifyPhone = async () => {
        let verifyPhone = getValues("Phone") ? getValues("Phone") : null;
        // if (verifyPhone) {
        //     let result = await FindClient(verifyPhone);
        //     if (optionAddEdit) {
        //         // edit
        //         if (result?.length > 0) {
        //             let data = result.find(x => Number(x.idClient) !== idClientExist)
        //             console.log("data ", data, " - ", data?.length, " - ", idClientExist);

        //             if (data) {
        //                 if (Object.keys(data).length > 0) {
        //                     return false;
        //                 }
        //             } else {
        //                 return true;
        //             }
        //         } else {
        //             return true;
        //         }

        //     } else {
        //         // add
        //         if (result?.length > 0) {
        //             return false;
        //         } else {
        //             return true;
        //         }

        //     }
        // }
        if (verifyPhone) {
            let result = await FindClient(verifyPhone);
            //console.log("dataX",result,"--",verifyPhone)
            if (optionAddEdit) {
                // edit
                if (Object.keys(result)?.length > 0) {
                    let data = result.find(x => Number(x.idClient) !== idClientExist)
                    //console.log("dataX ", data, " - ", Object.keys(result)?.length, " - ", idClientExist);
                    if (data) {
                        if (Object.keys(data)?.length > 0) {
                            let numberExist = result?.find(x => Number(x.idClient) == idClientExist)
                            //console.log("id",numberExist)
                            if (Number(numberExist?.idClient) == idClientExist) {
                                //console.log("dataX ",Number(numberExist?.idClient),"true",idClientExist)
                                return true;
                            } else {
                                //console.log("dataX ",Number(numberExist?.idClient),"false",idClientExist)
                                return false;
                            }
                        }
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                // add
                if (Object.keys(result)?.length > 0) {
                    let data = result?.find(x => Number(x.phone) == getValues("Phone"))
                    //console.log(data)
                    if (data) {
                        if (Number(data?.idClient) == idClientExist) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }

                } else {
                    return true;
                }

            }
        }
        return false;
    }

    const handleData = async (activeNavigation: boolean = false, isChecked?: boolean) => {

        setActiveSpinner(true);

        let result = await verifyPhone()

        let isDipendenteOrIsDirectore = isDipendente || isDirectore;

        if (result) {
            const newClientList = {
                idUser: Number(userInLogin?.idUser),
                idTitle: Number(getValues("Title")),
                Name: getValues("Name") ? getValues("Name") : null,
                Email: getValues("Email") ? getValues("Email") : null,
                LastName: getValues("Name") ? getValues("Name") : null,
                Phone: getValues("Phone") ? getValues("Phone") : null,
                SendEmail: checkBoxEmail ? true : false,
                DateModification: dateTimeNow(),
                Note: getValues("Note") ? getValues("Note") : null,
                BlackList: checkBoxBlackListChecked ? true : false,
                IsImportant: checkBoxIsImportantChecked ? true : false,
                StarValuation: currentValue != 0 ? currentValue : null,
                OriginIdBussines: isDipendenteOrIsDirectore ? bussinesUser : getValues("OriginIdBussines") ? getValues("OriginIdBussines") : null,
            }
            console.log("newClientList: ", newClientList)
            let result = await postNewInteressiClient(newClientList);

            let idClient = Number(result);

            let status = await addListManifestazione(idClient, getValues);
            if (ConfigCapone.configTest) {
                let statusEmail = Object.keys(checkBoxEmailRingraziamento)
                if (statusEmail.includes("Ringraziamento-0")) {
                    const titleClientSend = titleType.find(element => element.idTitle == newClientList.idTitle)
                    const bussinesData = dataBussinesList.find(element => element.idBussines == newClientList.OriginIdBussines)
                    const refDesc = dataInteressi.find(o => o.idList == getValues("ListInserire"))
                    const nameList = refDesc?.name
                    const ValueRefList = nameList.split(" ")
                    const position = ValueRefList.length
                    const NameListSend = ValueRefList[position - 1]

                    let emailMessage = ''
                    console.log(bussinesData?.desc_Bussines);
                    if(bussinesData?.desc_Bussines === 'Atelier'){
                        emailMessage = formatMessageEmailInteressiAtelier(titleClientSend?.desc_Title, newClientList.Name, NameListSend, nameList)
                    }else{
                        emailMessage = formatMessageEmailInteressi(titleClientSend?.desc_Title, newClientList.Name, NameListSend, bussinesData?.desc_Bussines, null)
                    }
                    //const emailTest = ["p.grimaldi@qbitsoft.it","info@sergiocapone.com"];
                    //const emailTest = ["fernando.maraza@gmail.com","gregorydang@gmail.com"];
                    for (let i = 0; i < ConfigCapone.emailTest.length; i++) {
                        let dataSend = {
                            from: "customerservice@sergiocapone.com",
                            type: "notice",
                            email: ConfigCapone.emailTest[i],
                            //subject: "Manifestazione d'interesse",
                            subject: "Grazie per la sua manifestazione di interesse",
                            message: emailMessage
                        };
                        await axios
                            .post(
                                `${ConfigCapone.apicapone}/api/forma/no-signature`,
                                dataSend
                            )
                            .then((res) => {
                                console.log("email send");
                            })
                            .catch((err) => {
                                console.log("there was an error: " + err)
                            });
                    }

                }
            }
            let client = await FindByIdClientCrm(idClient);

            if (status == "OK") {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: "Salvata con successo.",
                    title: "Informazione",
                    route: "",
                });
            }

            setOptionAddEdit(true);
            await resultSearch(client, true);

        } else {
            setmodaleMessaggeWithUrl({
                active: true,
                message: "Numero di telefono già registrato",
                title: "Errore",
                route: "",
            });
        }
        setActiveSpinner(false);
    }
    const confirmToDelete = async (idList: number, idClient: number) => {
        setIdDeleteUser(idList)
        setIdDeleteClient(idClient)
        setModal(true);
    }
    const confirmToDeleteTrue = async (idDeleteUser, idDeleteClient) => {
        deleteInteressiClientDetails(idDeleteUser, idDeleteClient)
        setModal(false);
        let client = await FindByIdClientCrm(idClientExist);
        if (client) {
            await resultSearch(client, true);
        }

    }
    const handleDataEdit = async (activeNavigation: boolean = false, isChecked?: boolean) => {

        setActiveSpinner(true);

        let result = await verifyPhone()

        console.log("result", result);


        if (result) {

            const updateClient = {
                idClient: idClientExist,
                idUser: Number(userInLogin?.idUser),
                idTitle: Number(getValues("Title")),
                Name: getValues("Name") ? getValues("Name") : null,
                Email: getValues("Email") ? getValues("Email") : null,
                LastName: getValues("Name") ? getValues("Name") : null,
                Phone: getValues("Phone") ? getValues("Phone") : null,
                SendEmail: checkBoxEmail ? true : false,
                DateModification: dateTimeNow(),
                Note: getValues("Note") ? getValues("Note") : null,
                BlackList: checkBoxBlackListChecked ? true : false,
                IsImportant: checkBoxIsImportantChecked ? true : false,
                StarValuation: currentValue != 0 ? currentValue : null,
                OriginIdBussines: getValues("OriginIdBussines") ? getValues("OriginIdBussines") : null,
            }

            if (ConfigCapone.configTest) {
                let statusEmail = Object.keys(checkBoxEmailRingraziamento)
                if (statusEmail.includes("Ringraziamento-0")) {
                    const titleClientSend = titleType.find(element => element.idTitle == updateClient.idTitle)
                    const bussinesData = dataBussinesList.find(element => element.idBussines == updateClient.OriginIdBussines)
                    const refDesc = dataInteressi.find(o => o.idList == getValues("ListInserire"))

                    const nameList = refDesc?.name
                    const ValueRefList = nameList.split(" ")

                    const position = ValueRefList.length
                    const NameListSend = ValueRefList[position - 1]

                    let emailMessage = ''
                    if(bussinesData?.desc_Bussines === 'Atelier'){
                        emailMessage = formatMessageEmailInteressiAtelier(titleClientSend?.desc_Title, updateClient.Name, NameListSend, nameList)
                    }else{
                        emailMessage = formatMessageEmailInteressi(titleClientSend?.desc_Title, updateClient.Name, NameListSend, bussinesData?.desc_Bussines, dataClient.country)
                    }
                    //const emailTest = ["fernando.maraza@gmail.com","gregorydang@gmail.com"];
                    //const emailTest = ["p.grimaldi@qbitsoft.it","info@sergiocapone.com"];
                    for (let i = 0; i < ConfigCapone.emailTest.length; i++) {
                        let dataSend = {
                            from: "customerservice@sergiocapone.com",
                            type: "notice",
                            email: ConfigCapone.emailTest[i],
                            //subject: "Manifestazione d'interesse",
                            subject: "Grazie per la sua manifestazione di interesse",
                            message: emailMessage
                        };
                        await axios
                            .post(
                                `${ConfigCapone.apicapone}/api/forma/no-signature`,
                                dataSend
                            )
                            .then((res) => {
                                console.log("email send");
                            })
                            .catch((err) => {
                                console.log("there was an error: " + err)
                            });
                    }

                }
            }


            await ModifyClientManifestazione(updateClient);

            let exists = dataListInteressiByClient.find(x => x.idList === getValues("ListInserire"));

            if (!exists) {

                let status = await addListManifestazione(idClientExist, getValues);

                let client = await FindByIdClientCrm(idClientExist);

                console.log(client);
                console.log(status);

                if (status == "OK") {
                    setmodaleMessaggeWithUrl({
                        active: true,
                        message: "Modifica salvata con successo.",
                        title: "Informazione",
                        route: "",
                    });
                }

                await resultSearch(client, true);

            } else {

                setmodaleMessaggeWithUrl({
                    active: true,
                    message: "Sei già registrato in quella lista di interessi.",
                    title: "Informazione",
                    route: "",
                });
            }

            setOptionAddEdit(true)

        } else {
            setmodaleMessaggeWithUrl({
                active: true,
                message: "Numero di telefono già registrato",
                title: "Errore",
                route: "",
            });
        }


        setActiveSpinner(false);

    }
    useEffect(() => {
        getAllListInteressi()
        getListBussines()
        getListTitle()
        isAtelier && setValue('OriginIdBussines', 9)
    }, [])
    useEffect(() => {
        changeStatusEmailSend()
    }, [checkBoxEmailRingraziamento])


    useEffect(() => {
        getListClientInteressi()
    }, [idClientExist])

    return {
        dataInteressi,
        dataListInteressiByClient,
        resultSearch,
        changeEmail,
        onChangeTextName,
        handleData,
        handleDataEdit,
        stars,
        currentValue,
        handleClickStart,
        handleMouseOverStart,
        handleMouseLeaveStart,
        hoverValue,
        colors,
        stateBlackList,
        checkBoxBlackListChecked,
        changeBlackList,
        changeIsImportant,
        checkBoxIsImportantChecked,
        checkBoxEmail,
        searchInputName,
        autosuggestInputName,
        setSearchInputName,
        setAutosuggestInputName,
        confirmToDelete,
        confirmToDeleteTrue,
        modal,
        setModal,
        idDeleteUser,
        idDeleteClient,
        checkBoxEmailRingraziamento,
        changeEmailRingraziamento,
        dataBussinesList,
        titleType
    }
}

export default useAddListInteressi