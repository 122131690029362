import axios from 'axios';
import ConfigCapone from '../utils/ConfigCapone'
import Cookies from 'universal-cookie';
  //cookie
  const cookies = new Cookies();
export default class api_capone<T> {
    
    async GetHttpAPI(parameters:string,controller:string):Promise<T>{
        const baseUrl = `${ConfigCapone.urlapi}${controller}${parameters}`
 
        return new Promise((resolve, reject) => {
            axios.get(baseUrl).then((response) => {
                resolve(response.data as T);
            }, (err) => {
                reject(err);
            });
         });
    } 

    async PostHttpAPI(parameters:string,controller:string,data:any):Promise<T>{
        const baseUrl = `${ConfigCapone.urlapi}${controller}${parameters}` 
        return new Promise((resolve, reject) => {
            axios.post(baseUrl,data,{
                headers: {
                  'Authorization': `Bearer ${cookies.get('token')}`
                }
              }).then((response) => {
                resolve(response.data as T);
            }, (err) => {
                reject(err);
            });
         });
    } 

    async PutHttpAPI(parameters:string,controller:string,data:any):Promise<T>{
        const baseUrl = `${ConfigCapone.urlapi}${controller}${parameters}`
  
        return new Promise((resolve, reject) => {
            axios.put(baseUrl,data,{
                headers: {
                  'Authorization': `Bearer ${cookies.get('token')}`
                }
              }).then((response) => {
                resolve(response.data as T);
            }, (err) => {
                reject(err);
            });
         });
    } 

    async DeleteHttpAPI(parameters:string,controller:string):Promise<T>{
        const baseUrl = `${ConfigCapone.urlapi}${controller}${parameters}`
        return new Promise((resolve, reject) => {
            axios.delete(baseUrl).then((response) => {
                resolve(response.data as T);
            }, (err) => {
                reject(err);
            });
         });
    } 
}