import React from 'react'
import { BsNewspaper } from 'react-icons/bs'
import { View, Image, Text } from "react-native";

const HeaderToday = () => {
  return (
    <div className="d-flex justify-content-between align-items-center  fw-bold ">
      <div className="">
        <img src={require("../../../assets/icons-step-due/calendar.png")} alt="" style={{width: 40}} />
        <span>Eventi Oggi</span>
      </div>
    </div>
  )
}

export default HeaderToday
