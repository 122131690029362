import { useIsFocused } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useProfile } from "../../../../components/NavProfile/hook/useProfile";
import ShowContentSidebar from "../../../../components/NavProfile/ShowContentSidebar";
import { Navbar } from "../../../../components/Navbar";
import NavProfile from "../../../../components/NavProfile/NavProfile";
import { DetailsListeScreen } from "../../DetailsListeScreen";

interface Props extends NativeStackScreenProps<any, any> { }
const ListDetailOrdiniAtelier = ({ route, navigation }: Props) => {

    const isFocused = useIsFocused();
    const { sidebarVisible, toggleSidebar } = useProfile();


    return (
        <>
            <ShowContentSidebar
                sidebarVisible={sidebarVisible}
                toggleSidebar={toggleSidebar}
            />
            <div className="container-layout">
                <div className="row row-layout">
                    <div
                        className={` ${sidebarVisible
                            ? "col-12 d-block"
                            : "col-lg-3 col-md-none d-none d-md-non d-lg-block"
                            } sidebar-container-layout sidebar-transition`}
                    >
                        <Navbar
                            activeNav={isFocused}
                            componentName={'OrdiniAtelier'} 
                            componentDad="OrdiniAtelier"
                        />
                    </div>
                    <div
                        className="col-lg-9 col-md-12 col-sm-12 content-container-layout "
                        style={{ paddingLeft: "1.5rem", overflowY: 'auto' }}
                    >
                        <NavProfile
                            showMenuBack={true}
                            sidebarVisible={sidebarVisible}
                            toggleSidebar={toggleSidebar}
                            goBack="OrdiniAtelier"
                            goBackName="Lista Ordini Atelier"
                        />
                        <div className="magazzino-atelier-form-container">
                            <DetailsListeScreen route={route} navigation={navigation} isAtelier/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListDetailOrdiniAtelier