import React from 'react'
import { Text, View } from 'react-native'

export const GetItemClientAtelier = (value: string) => {
    return (
        <div>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ marginLeft: 10, flexShrink: 1 }}>
                    <Text
                        style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}
                    >
                        {value}
                    </Text>
                </View>
            </View>
        </div>
    )
}
