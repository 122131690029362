import { useState } from "react"

const useFormListOrdiniAtelier = () => {

    const [openModal, setopenModal] = useState(false)
    const [operationModal, setOperationModal] = useState<'edit' | "delete" | 'add'>('add')

    const handleOpenModal = () => {
        setopenModal(true)
    }

    const handleCloseModal = () => {
        setopenModal(false)
    }

    const handleOperationModal = (operation: 'edit' | "delete" | 'add') => {
        setOperationModal(operation)
        handleOpenModal();
    }
    
    return {
        //*States
        openModal,operationModal,
        //*Functions Hadlers
        handleCloseModal,handleOperationModal,
    }
}

export default useFormListOrdiniAtelier