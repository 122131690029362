import React, { useState } from "react";
import { Image } from "react-native-elements";
import { StyleSheet } from "react-native";

interface Props {
  name?: any;
  onSelected?: any;
  id?: any;
  type?: any;
  text?: any;
  styleIcon?: any;
  top?: any;
  height?: any;
  icon?: any;
  iconText?: any;
  marginleft?: any;
  checked?: any;
  onChange?: any;
  multiSelected?: any;
}

export const CheckLabel = ({
  name,
  onSelected,
  id,
  type,
  text,
  styleIcon,
  top,
  height,
  icon,
  iconText,
  marginleft,
  checked,
  onChange,
  multiSelected,
}: Props) => {
  const [optionSelected, setoptionSelected] = useState<any>();
  return icon ? (
    <label
      htmlFor={id}
      style={{
        fontSize: 18,
        marginBottom: 5,
        fontFamily: "Arsapia Regular font",
      }}
      className="d-flex"
    >
      <input
        type="checkbox"
        className="checksMagazzino"
        style={{
          margin: marginleft ? 0 : 10,
          marginLeft: marginleft ? 0 : 10,
          marginRight: marginleft ? 15 : 10,
          fontSize: 100,
        }}
        name={name}
        onClick={onSelected}
        checked={multiSelected ? multiSelected : id === checked && true}
        onChange={() => onChange && onChange(id)}
        id={id}
      />
      {
        <img
          src={icon}
          alt=""
          style={{
            marginLeft: 2,
            marginTop: top && top,
            width: styleIcon ? styleIcon : 35,
            height: styleIcon ? height : 30,
          }}
        />      
      }
      {
        iconText && <span style={{
          paddingLeft: 5,
          paddingTop: 1.5
        }}>{iconText}</span>
      }
    </label>
  ) : (
    <label
      htmlFor={id}
      style={{
        fontSize: 18,
        marginBottom: 5,
        fontFamily: "Arsapia Regular font",
      }}
    >
      <input
        type="checkbox"
        className="checksMagazzino"
        style={{
          margin: marginleft ? 0 : 10,
          marginLeft: marginleft ? 0 : 10,
          marginRight: marginleft ? 15 : 10,
          fontSize: 100,
        }}
        name={name}
        onClick={onSelected}
        checked={multiSelected ? multiSelected : id === checked && true}
        onChange={() => onChange && onChange(id)}
        id={id}
      />
      {text}
    </label>
  );
};
