import {
    Modal,
    ScrollView,
    StyleSheet,
    TouchableOpacity,
    Text,
    View,
} from "react-native";
import { HeaderModal } from "../HeaderModal";
import { stylesModal } from "../../theme/ModalStyles";

interface IModalBlackList {
    modalVisible: boolean;
    setModalVisible: any;
}

const ModalBlackList = ({
    modalVisible = false,
    setModalVisible,
}: IModalBlackList) => { 

    return (
        <View>
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <ScrollView style={{width: "100%", height: "100%"}}> 
                    <View
                    style={[
                        stylesModal.centeredView,
                        { paddingTop: 250, paddingBottom: 150 },
                    ]}
                    >
                        <View style={[stylesModal.modalView,{width:350}]}>
                            <HeaderModal
                            setModalVisible={setModalVisible}
                            modalVisible={modalVisible}
                            title={"Black list"}
                            paddingRight={40}
                            />
                            <div className="row w-100">
                                 <div className="col-12 d-flex justify-content-center mb-4 mt-4">
                                    <div className="row">
                                        <div className="col-12">  

                                        <TouchableOpacity
                                                onPress={()=>setModalVisible(false)}
                                            >
                                                <View style={[ModalBlackListStyle.buttonWhite, {width: 125}]}>
                                                    <Text style={ModalBlackListStyle.buttonTextWhite}>
                                                    {"Ho capito"}
                                                    </Text>
                                                </View>
                                            </TouchableOpacity>
                                        </div>                                            
                                    </div>
                                 </div>
                            </div>
                        </View>
                    </View>
                </ScrollView>
            </Modal>
        </View>
    )

}

const ModalBlackListStyle = StyleSheet.create({
    buttonTextWhite: {
        color: "black",
        alignSelf: "center",
        fontWeight: "500",
        fontSize: 18,
    },
    buttonWhite: {
        backgroundColor: "white",
        justifyContent: "center",
        borderRadius: 50,
        width: 130,
        height: 40,
        borderWidth: 1,
        borderColor: "black",
    },
    labelError: {
        fontSize: 18,
        marginLeft: 35,
        marginTop: 15,
        fontFamily: "Arsapia Regular font",
        color: "red",
    }
});

export default ModalBlackList;
