import aplicationConnect from "../../../api/applicationConnect";

export const bussinesTaskByIdUserScreen = async (idUser:Number) => {
    try {
      const response = await aplicationConnect.get(`TaskBussinesUsers/GetByIdUser`,{
        params:{
            idUser:idUser
        }
      });
      const { data } = response;
      if (data.status === "success") {
        return data.response;
      }
  
    } catch (error) {
      throw new Error();
    }
  };
  //userNewByIdUser
  export const userTaskByIdUser = async (idUser:Number) => {
    try {
      const response = await aplicationConnect.get(`TaskUser/GetByIdUser`,{
        params:{
            idUser:idUser
        }
      });
      const { data } = response;
      if (data.status === "success") {
        return data.response;
      }
  
    } catch (error) {
      throw new Error();
    }
  };
  export const listByidTaskBussines = async (idNewBussines:Number) => {
    try {
      const response = await aplicationConnect.get(`TaskBussinesUsers/GetByIdTaskBussines`,{
        params:{
          idNewBussines:idNewBussines
        }
      });
      const { data } = response;
      if (data.status === "success") {
        return data.response;
      }
  
    } catch (error) {
      throw new Error();
    }
  };
  export const putTaskBussinesIsChecked = async (dataSend) => {
    const dataModify={
      idTaskBussinesUsers:Number(dataSend.idTaskBussinesUsers),
      idTaskBussines:Number(dataSend.idTaskBussines),
      idUser:Number(dataSend.idUser),
      isChecked:1
    }
    try {
      const response = await aplicationConnect.put(`TaskBussinesUsers`,dataModify);
      const { data } = response;
      if (data.status === "success") {
        return data.response;
      }
  
    } catch (error) {
      throw new Error();
    }
  };
  export const putTaskUserIsChecked = async (idNewUser:number) => {
    
    try {
      const response = await aplicationConnect.put(`TaskUser/isChecked?idTaskUser=${idNewUser}&isChecked=1`);
      const { data } = response;
      if (data.status === "success") {
        return data.response;
      }
  
    } catch (error) {
      throw new Error();
    }
  };