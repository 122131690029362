import axios from "axios";
import ConfigCapone from "../utils/ConfigCapone";

const apiCalendar = `${ConfigCapone.urlapi}/api/Calendar`;
export const getNegozio = async () => {
  try {
    const response = await axios.get(
      `${ConfigCapone.urlapi}/api/Bussines?parameter=1`
    );
    const { data } = response;
    if (data.status) {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const getImage = async (name:string,ext:string) => {
  
  try {
    const response = await axios.get(
      `${ConfigCapone.urlapi}/api/UploadImage/${name}/${ext}`,
    );
    
    if (response.status == 200) {
      
      return response;
    }
  } catch (error) {
    
  }
};
export const registerEvent = async (values: any) => {
  try {
    const response = await axios.post(apiCalendar, values);
    const { data } = response;
    if (data.status === "success") {
      return data;
    }
  } catch (error) {
    throw new Error();
  }
};
export const updateEvent = async (values: any) => {
  try {
    const response = await axios.put(apiCalendar, values);
    const { data } = response;
    if (data.status === "success") {
      return data;
    } else {
      throw new Error();
    }
  } catch (error) {
    throw new Error();
  }
};
export const getAllEvents = async (rol: number | string, idBussines : number | string) => {
  try {
    const response = await axios.get(
      `${apiCalendar}/SearchListCalendarUser?idRol=${rol}&idBussines=${idBussines }`
    );
    const { data } = response;
    if (data.status === "success") {
      
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const getAllEventsNotify = async (rol: number | string, idBussines : number | string) => {
  try {
    const response = await axios.get(
      `${apiCalendar}/SearchListCalendarUserNotify?idRol=${rol}&idBussines=${idBussines }`
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const deleteEvent = async (id: String) => {
  try {
    const response = await axios.delete(`${apiCalendar}/${id}`);
    const { data } = response;
    if (data.status === "success") {
      return data.id;
    }
  } catch (error) {
    throw new Error();
  }
};
