import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useContext, useEffect, useState } from 'react'
import { Image, SafeAreaView, ScrollView, StatusBar, StyleSheet, Text, TouchableOpacity, View, FlatList, Pressable, TextInput } from 'react-native'
import { HeaderBar } from '../../components/HeaderBar';
import { SearchBox } from '../../components/SearchBox';
import { styles, tableHeader, tableRow } from '../../theme/GlobalStyle';
import { UserContext } from "../../context/UserContext";
import { IClient } from "../../interfaces/IClient";
import ConfigCapone from "../../utils/ConfigCapone";
import axios from "axios";
import ReactExport from 'react-data-export';
import { todayFormatDate } from "../../utils/helpers";
import { useNotifyCrud } from "../../hooks/useNotifyCrud";
import Loading from '../../components/Loading';
import { getAllSuggestionListInteressiClient, getAllInteressiClientDetails, deleteInteressiClientDetails, interessiClientDetailsImportant, interessiClientListReminder, interessiClientListReminderByID, updateListInteressiClientFiledNote } from '../../services/InteressiDetails';
import Cookies from 'universal-cookie';
import { BsStarFill } from "react-icons/bs";
import PopupConfirmation from "../../components/PopupConfirmation";
import { stylesRepair, styleSearchDetailsListe } from '../../theme/interessi/interessiStyle';
import { headers } from '../../components/Interessi/Interessi';
import PopUpMessageWithRoute from "../../components/PopUpMessageWithRoute";
import { IListeDetailsClient } from "../../interfaces/IListeDetailsClient";
import { dateTimeNowFormatDDMMYYY } from '../../services/interessiServices';
import Pagination from "../../components/Pagination/pagination";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
interface Props extends NativeStackScreenProps<any, any> {
    isAtelier?: boolean;
}
export const DetailsListeScreen = ({ route, navigation, isAtelier }: Props) => {
    const [editingNote, setEditingNote] = useState(null);
    const [onClickeditingNote, setOnClickeditingNote] = useState(false);
    const [noteChanged, setNoteChanged] = useState(false);
    let arrayData: any = route.params || [];
    let idListRoute: any = arrayData[0];
    let dname: any = arrayData[1];
    const { userInLogin } = useContext(UserContext);
    const { createdPosted } = useNotifyCrud();
    const [dataInteressiFilterTable, setDataInteressiFilterTable] = useState([]);
    const [ListFilterClient, setListFilterClient] = useState<any[]>();
    const [activeSpinner, setActiveSpinner] = useState(false);
    const [reloadData, setReloadData] = useState<boolean>(false)
    // search
    const [searchInputClient, setSearchInputClient] = useState<string>();
    const [autosuggestInputName, setAutosuggestInputName] = useState<IListeDetailsClient[]>([]);
    //cookie
    const cookies = new Cookies();
    //modal delete
    const [paginaActual, setPaginaActual] = useState(1);
    const [paginationAlls, setPaginationAlls] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalDeleteNote, setModalDeleteNote] = useState(false);
    const [idDeleteUser, setIdDeleteUser] = useState(0);
    const [idDeleteClient, setIdDeleteClient] = useState(0);
    // export
    const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({
        title: "Informazione",
        message: "",
        active: false,
        route: "",
    });
    const [isImportFilter, setIsImportFilter] = useState(false);
    const [nameFileExport, setNameFileExport] = useState<any>("Tabella Clienti " + todayFormatDate);
    const stars = Array(3).fill(0)
    const colors = {
        orange: "black",
        grey: "#EAECEF",
    };
    const [parametersGet, setparametersGet] = useState({
        page: 1,
        limit: null,
        name: "",
        isImportant: 11,
        idList: arrayData[0]

    });
    let ubic = "9";
    const [paginationListe, setpaginatcionListe] = useState({
        currentPage: 0,
        total: 0,
        totalPages: 0,
    });
    const sendEmailReminder = async (id: number, importantClient) => {
        if (importantClient === 1) {
            if (ConfigCapone.configTest) {
                const dataReminderBYidClient = await interessiClientListReminderByID()
                let dataClientInteressiSendEmail;
                dataClientInteressiSendEmail = dataReminderBYidClient.filter(x => x.idClient == id);
                setActiveSpinner(true)
                if (dataClientInteressiSendEmail.length > 0) {
                    for (let index = 0; index < dataClientInteressiSendEmail.length; index++) {
                        const nameList = dataClientInteressiSendEmail[index].listName
                        const nameListTwo = nameList.trimEnd()
                        const ValueRefList = nameListTwo.split(" ")
                        const position = ValueRefList.length
                        const NameListSend = ValueRefList[position - 1]
                        let emailData = {
                            from: "customerservice@sergiocapone.com",
                            type: "notice",
                            email: `${userInLogin.emailUser}`,
                            subject: "Manifestazione d'interesse REMIND",
                            message: `<p>Ricorda che il cliente ${dataClientInteressiSendEmail[index].clientName} sta aspettando l'orologio ${NameListSend}, contattalo per sentire se è ancora interessato</p>`

                        };

                        await axios
                            .post(`${ConfigCapone.apicapone}/api/forma`, emailData)
                            .then((res) => {
                                console.log(res);
                                console.log("info : ", emailData);
                            })
                            .catch((err) => console.log("there was an error: " + err));
                    }
                }
                setActiveSpinner(false)

            }

        }

    }
    const isImportant = (idClient: number, importantClient: number) => {
        const inter = interessiClientDetailsImportant(idClient, importantClient, Number(userInLogin.idUser))
        inter.then((response) => {
            setReloadData(true)
            sendEmailReminder(idClient, importantClient)
        })
    }
    const onChangeTextName = async () => {
        const inter = getAllSuggestionListInteressiClient(arrayData[0], searchInputClient)
        inter.then((response) => {
            if (response != undefined) {
                setAutosuggestInputName(response.data.response)
            } else {
                setDataInteressiFilterTable([])
            }
            setReloadData(false)
        })
    };
    const getItemText = (item: IListeDetailsClient) => {
        let mainText = item.name + " - " + item.phone;
        return (
            <div>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ marginLeft: 10, flexShrink: 1 }}>
                        <Text style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}>{mainText}</Text>
                    </View>
                </View>
            </div>
        );
    };
    const showSuccessPopup = (mess: string, tiTle: string) => {
        setmodaleMessaggeWithUrl({
            active: true,
            message: mess,
            title: tiTle,
            route: "",
        })
    };
    const confirmAction = (Landing: any, data: any) => {
        navigation.navigate(Landing, data);
        setmodaleMessaggeWithUrl({
            title: "Informazione",
            message: "",
            active: false,
            route: "",
        });
    };
    const searchClientDetails = () => {
        console.log("aquifucnion")

    }
    const changeParametersFilter = () => {
        setparametersGet({
            page: paginaActual,
            limit: null,
            name: searchInputClient ? searchInputClient : "",
            isImportant: isImportFilter ? 1 : 11,
            idList: arrayData[0]

        })
        setReloadData(true)
    }
    useEffect(() => {
        changeParametersFilter()
    }, [paginaActual])
    useEffect(() => {
        setPaginaActual(1)
        changeParametersFilter()
    }, [isImportFilter])
    useEffect(() => {
        changeParametersFilter()
        setPaginaActual(1)
    }, [searchInputClient])

    //DELETE LIST
    const confirmToDelete = async (idList: number, idClient: number) => {
        setIdDeleteUser(idList)
        setIdDeleteClient(idClient)
        setModal(true);
    }
    const confirmToDeleteTrue = async (idList: number, idClient: number) => {
        deleteInteressiClientDetails(idList, idClient)
        setModal(false);
        setReloadData(true)
    }

    const paginationAll = () => {
        const numerodepaginas = Array(Number(paginationListe.totalPages)).fill(0)
        setPaginationAlls(numerodepaginas)
    }
    const getAllDetailsClient = () => {
        const inter = getAllInteressiClientDetails(parametersGet)
        inter.then((response) => {
            if (response != undefined) {
                setDataInteressiFilterTable(response.data.response)
                setpaginatcionListe({
                    currentPage: response.data.currentPage,
                    total: response.data.total,
                    totalPages: response.data.totalPages,
                });
            } else {
                setDataInteressiFilterTable([])
            }
            setReloadData(false)
        })
    }
    useEffect(() => {
        getAllDetailsClient()
    }, [reloadData])
    useEffect(() => {
        paginationAll()
    }, [paginationListe])
    useEffect(() => {
        getAllDetailsClient()
        paginationAll()
    }, [])
    console.log("aqui ruta", arrayData, idListRoute)

    const handleNoteChange = (clientId, newNote) => {
        const updatedData = dataInteressiFilterTable.map((item) =>
            item.idClient === clientId ? { ...item, note: newNote } : item
        );
        setDataInteressiFilterTable(updatedData);
        setNoteChanged(true);
    };

    const saveNoteChanges = (clientId) => {
        const clientData = dataInteressiFilterTable.find(item => item.idClient === clientId);
        if (clientData) {
            // Si se encuentra el objeto, puedes acceder al campo note
            const id = clientData.id;
            const note = clientData.note;
            const _updateListInteressiClientFiledNote = updateListInteressiClientFiledNote(id, clientId, note)
            _updateListInteressiClientFiledNote.then((response) => {
                if (response != undefined) {
                    console.log("Note actual: ", response);
                    if (response === "OK") {
                        setmodaleMessaggeWithUrl({
                            active: true,
                            message: "Modifica effettuata con successo.",
                            title: "Informazione",
                            route: "",
                        });
                        setNoteChanged(false);
                        setEditingNote(null);
                        setOnClickeditingNote(false);
                    }
                } else {
                }
            })
        } else {
            console.log("Cliente no encontrado en dataInteressiFilterTable");
        }
    };

    const EditingNote = (data: any) => {
        setOnClickeditingNote(true);
        setEditingNote(data)
    }

    const handleDeleteNote = async (clientId: number, note: string) => {
        console.log("note: ", note)
        setModalDeleteNote(note.trim() ? true : false);
        setIdDeleteClient(clientId)
    }

    const confirmToDeleteTrueNote = async (clientId: number) => {
        const clientData = dataInteressiFilterTable.find(item => item.idClient === clientId);
        if (clientData) {
            const id = clientData.id;
            const note = ''
            const _updateListInteressiClientFiledNote = updateListInteressiClientFiledNote(id, clientId, note)
            _updateListInteressiClientFiledNote.then((response) => {
                if (response != undefined) {
                    console.log("Note actual: ", response);
                    if (response === "OK") {
                        const inter = getAllInteressiClientDetails(parametersGet)
                        inter.then((response) => {
                            if (response != undefined) {
                                setDataInteressiFilterTable(response.data.response)
                            } else {
                                setDataInteressiFilterTable([])
                            }
                        })
                        setModalDeleteNote(false);
                        setEditingNote(null)
                    }
                } else {
                }
            })
        }
        setReloadData(true)
    }

    return (
        <div className="square">
            <ScrollView style={styles.scroll}>
                {activeSpinner && <Loading activeSpinner={activeSpinner} />}
                <SafeAreaView style={[stylesRepair.areaView]}>
                    {!isAtelier &&
                        <View style={{ paddingHorizontal: 12 }}
                        >
                            <HeaderBar
                                hasRoundedIcon={false}
                                title=""
                                onBack={() => navigation.navigate("ListeScreen", ubic)}
                            />
                        </View>
                    }

                    <View style={[stylesRepair.areaContent]}>
                        <div className="row px-2">
                            <div className="col-sm-0 p-0"></div>
                            <div className="col-md-7 pb-2 col-sm-7 p-0">
                                <div className="mt-2">
                                    <Text
                                        style={[stylesRepair.sectionTitle2]}
                                    >
                                        Liste  
                                    </Text>
                                </div>
                                <div className="mt-2">
                                    <Text
                                        style={[stylesRepair.sectionTitle]}
                                    >
                                        {arrayData[1]}
                                    </Text>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-5 p-0">
                                <SearchBox
                                    placeholder="Cerca cliente..."
                                    marginRight={true}
                                    value={searchInputClient}
                                    setValue={setSearchInputClient}
                                    handleSearchFilter={searchClientDetails}
                                    onChangeText={onChangeTextName}
                                />
                                {searchInputClient && autosuggestInputName.length > 0 ? (
                                    <View style={styleSearchDetailsListe.styleDetailSearch}>
                                        <FlatList
                                            data={autosuggestInputName}
                                            showsVerticalScrollIndicator={false}
                                            renderItem={({ item, index }) => (
                                                <Pressable
                                                    style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }]}
                                                    onPress={() => {
                                                        setSearchInputClient(item.name)
                                                        setAutosuggestInputName([])
                                                    }
                                                    }
                                                >
                                                    {getItemText(item)}
                                                </Pressable>
                                            )}
                                        />
                                    </View>
                                ) : null}
                            </div>
                        </div>
                        <div className="row mt-5 px-2 ">
                            <div className="col-5">
                                <Text
                                    style={[stylesRepair.sectionTitle]}
                                >
                                    Filtra importanti
                                </Text>
                                <input onChange={() => setIsImportFilter(!isImportFilter)} type="checkbox"></input>
                            </div>
                            <div className="col-7 d-flex justify-content-end">
                                <TouchableOpacity
                                    onPress={() => dataInteressiFilterTable.length > 0 ? navigation.navigate("LabelsScreenListDetails", { searchInputClient, isImportFilter, idListRoute, dname, isAtelier }) : showSuccessPopup("Non ci sono record.", "Informazione")}
                                    style={{ marginRight: 10 }}
                                >
                                    <View style={[styles.buttonWhite, { width: 200 }]}> 
                                        <Text style={styles.buttonTextWhite}>
                                            {"Stampa"}
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            </div>
                        </div>
                        <div className={`col-12 mt-3 ${isAtelier && 'p-0'}`} style={{ paddingLeft: 40, paddingRight: 0 }}>
                            <div className="table-responsive">
                                <table className="table table-borderless mt-3" style={{ fontSize: isAtelier && '.9em' }}>
                                    <thead style={{ backgroundColor: "black", color: "white" }}>
                                        <tr className="mr-4" >
                                            <th
                                                style={{ borderTopLeftRadius: 50, borderBottomLeftRadius: 50 }}
                                                scope="col"
                                                className="align-self-center"
                                            ></th>
                                            {
                                                headers.map((item, index) => {
                                                    return (
                                                        <th
                                                            key={index}
                                                            style={tableHeader}
                                                            scope="col"
                                                            className="align-self-center mr-4 px-4"
                                                        >
                                                            {item}
                                                        </th>
                                                    )
                                                })
                                            }
                                            <th
                                                style={{
                                                    borderTopRightRadius: 50,
                                                    borderBottomRightRadius: 50,
                                                }}
                                                scope="col"
                                                className="align-self-center"
                                            ></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataInteressiFilterTable && dataInteressiFilterTable?.map((data, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    style={{ borderBottom: "1px solid gray" }}
                                                >
                                                    <td
                                                        style={tableRow}
                                                        className="align-self-center py-3"
                                                    >

                                                        <TouchableOpacity
                                                            style={{
                                                                flex: 1,
                                                                paddingHorizontal: 5,
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                            onPress={() => navigation.navigate(isAtelier ? "AggiungiCrmAtelier" : 'ControlPanelScreen', { clientList: data.idClient, isDetailListe: true, arrayData })}
                                                        >
                                                            <Image
                                                                style={{ width: 30, height: 30 }}
                                                                source={require("../../assets/icons/icon-note.png")}
                                                            />
                                                        </TouchableOpacity>

                                                    </td>
                                                    <td
                                                        style={{ ...tableRow, fontSize: 12 }}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {(userInLogin?.idRole === "2" || userInLogin?.idRole === "5") ?
                                                            <TouchableOpacity
                                                                style={{
                                                                    flex: 1,
                                                                    paddingHorizontal: 5,
                                                                    alignItems: "center",
                                                                    justifyContent: "center"
                                                                }}
                                                                onPress={() => confirmToDelete(data.idList, data.idClient)}
                                                            >
                                                                <Image
                                                                    style={{ width: 20, height: 20 }}
                                                                    source={require("../../assets/icons/icon-delete.png")}
                                                                />
                                                            </TouchableOpacity> : null


                                                        }
                                                    </td>
                                                    <td
                                                        style={tableRow}
                                                        className="align-self-center py-3"
                                                    >
                                                        {data.name}
                                                    </td>
                                                    <td
                                                        style={tableRow}
                                                        className="align-self-center py-3"
                                                    >

                                                        <div style={{ display: "center", flexDirection: "row", marginLeft: 20 }}>
                                                            {stars.map((_, index) => {
                                                                return (
                                                                    <BsStarFill
                                                                        key={index}
                                                                        size={20}
                                                                        color={(data.starValuation) > index ? colors.orange : colors.grey}
                                                                        style={{
                                                                            marginRight: 10,
                                                                        }}
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                    </td>
                                                    <td
                                                        style={tableRow}
                                                        className="align-self-center mr-4 px-4 py-3"
                                                    >
                                                        {dateTimeNowFormatDDMMYYY(data.dateInsert)}
                                                    </td>
                                                    <td
                                                        style={tableRow}
                                                        className="align-self-center mr-4 px-4 py-3"
                                                    >
                                                        {data.phone}
                                                    </td>

                                                    <td style={tableRow} className={`align-self-center mr-4 ${editingNote === data.idClient ? "p-0 " : "px-4 py-3"} `}>
                                                        {editingNote === data.idClient ? (
                                                            // Mostrar el campo de textarea en modo de edición
                                                            <div>
                                                                <textarea
                                                                    value={data.note}
                                                                    onChange={(e) => handleNoteChange(data.idClient, e.target.value)}
                                                                    style={{
                                                                        border: '1px solid #ccc',
                                                                        borderRadius: '4px',
                                                                        padding: '8px',
                                                                        width: '100%',
                                                                        height: '100%',
                                                                    }}
                                                                />

                                                            </div>
                                                        ) : (// Mostrar el valor normal y los iconos de editar y papelera
                                                            <div>
                                                                <span>{data.note}</span>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td style={tableRow} >
                                                        <td style={tableRow} className="align-self-center py-3">
                                                            {editingNote === data.idClient && onClickeditingNote ? (
                                                                <img
                                                                    src={require('../../assets/icons/guardar.png')}
                                                                    alt="Sava"
                                                                    onClick={() => saveNoteChanges(data.idClient)}
                                                                    style={{
                                                                        flex: 1,
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        width: 20, height: 20
                                                                    }} />
                                                            ) : (// Mostrar el valor normal y los iconos de editar y papelera
                                                                <img
                                                                    src={require('../../assets/icons/icon-edit.png')}
                                                                    alt="Modifica "
                                                                    onClick={() => EditingNote(data.idClient)}
                                                                    style={{
                                                                        flex: 1,
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        width: 20, height: 25
                                                                    }} />
                                                            )}
                                                        </td>
                                                        <td style={tableRow} className="align-self-center py-3">
                                                            <TouchableOpacity
                                                                style={{
                                                                    flex: 1,
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <img
                                                                    style={{ width: 20, height: 21 }}
                                                                    src={require('../../assets/icons/icon-delete.png')}
                                                                    onClick={() => handleDeleteNote(data.idClient, data.note)}
                                                                />
                                                            </TouchableOpacity>
                                                        </td>
                                                    </td>

                                                    <td
                                                        style={tableRow}
                                                        className="align-self-center mr-4 px-4 py-3"
                                                    >
                                                        {data.totalSales + ' €'}
                                                    </td>
                                                    <td
                                                        style={tableRow}
                                                        className="align-self-center mr-4 px-4 py-3"
                                                    >
                                                        {data.countSales}
                                                    </td>
                                                    <td
                                                        style={tableRow}
                                                        className="align-self-center mr-4 px-4 py-3"
                                                    >
                                                        <input type="checkbox" style={{ transform: 'scale(1.8)' }} onChange={() => isImportant(data.idClient, data.isImportant == "1" ? 0 : 1)} checked={data.isImportant == "1" ? (true) : (false)} ></input>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {(dataInteressiFilterTable.length > 0) && (
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={paginaActual}
                                        totalCount={paginationListe.totalPages}
                                        pageSize={1}
                                        onPageChange={(page) => setPaginaActual(page)}
                                    />)}
                            </div>
                        </div>
                        <div className='mt-4'>
                            {/* {dataInteressiFilterTable.length>0 && 
                <nav aria-label="Page navigation example ">
                  <ul className="pagination justify-content-center">
                    <li className="page-item " onClick={()=>(paginaActual>1)?setPaginaActual(paginaActual-1):console.log("Hasta aqui llego")}>
                      <a className="page-link rounded-circle"><Image
                        style={{
                          width: 25,
                          height: 25,
                          tintColor: "rgb(200,200,200)",
                        }}
                        source={require("../../assets/icons/icon-left.png")}
                      /></a>
                    </li>
                    {paginationAlls?.map((d: any, index) => (
                      <li className="page-item" onClick={()=>setPaginaActual(index + 1)}>{paginaActual==index+1?<a className="btn btn-secondary border border-light rounded-circle">{index + 1}</a>:<a className="btn btn-outline-secondary border border-light rounded-circle">{index + 1}</a>}</li>
                    ))
                    }
                    <li className="page-item " onClick={()=>(paginaActual<paginationListe.totalPages)?setPaginaActual(paginaActual+1):console.log("Hasta aqui llego")}>
                      <a className="page-link rounded-circle"><Image
                        style={{
                          width: 25,
                          height: 25,
                          tintColor: "rgb(200,200,200)",
                        }}
                        source={require("../../assets/icons/icon-right.png")}
                      /></a>
                    </li>
                  </ul>
                </nav>
                } */}
                        </div>
                    </View>
                    {modaleMessaggeWithUrl.active && (
                        <PopUpMessageWithRoute
                            routeNav={modaleMessaggeWithUrl.route}
                            dataNav={[]}
                            title={modaleMessaggeWithUrl.title}
                            message={modaleMessaggeWithUrl.message}
                            stateModal={modaleMessaggeWithUrl.active}
                            setStateModal={setmodaleMessaggeWithUrl}
                            confirmAction={confirmAction}
                        />
                    )}
                </SafeAreaView>
                {modal && <PopupConfirmation
                    title={""}
                    message={"Sei sicuro di voler rimuovere il client dall'elenco?"}
                    confirmAction={() => confirmToDeleteTrue(idDeleteUser, idDeleteClient)}
                    stateModal={modal}
                    setStateModal={setModal}
                />}
                {modalDeleteNote &&
                    <PopupConfirmation
                        title={""}
                        message={"Sei sicuro di voler eliminare la nota?"}
                        confirmAction={() => confirmToDeleteTrueNote(idDeleteClient)}
                        stateModal={modalDeleteNote}
                        setStateModal={setModalDeleteNote}
                    />}
            </ScrollView>
        </div>
    )
}

