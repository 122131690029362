export const sortWhitCampoAsc = (a: any, b: any, campo: string) => {
  if (a[campo] < b[campo]) {
    return -1;
  }
  if (a[campo] > b[campo]) {
    return 1;
  }
  return 0;
};

export const sortWhitCampoDesc = (a: any, b: any, campo: string) => {
  if (a[campo] > b[campo]) {
    return -1;
  }
  if (a[campo] < b[campo]) {
    return 1;
  }
  return 0;
};


export const compareDate = (a: any, b: any) => {
  const init = new Date(a.date).getTime()
  const end = new Date(b.date).getTime()
  if (init > end) {
    return -1;
  }
  if (init < end) {
    return 1;
  }
  return 0;
};

export const aleatorio = (inferior: number, superior: number) => {
  const numPosibilidades = superior - inferior
  let aleatorio = Math.random() * (numPosibilidades + 1)
  aleatorio = Math.floor(aleatorio)
  return inferior + aleatorio
}