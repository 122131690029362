import { Text } from "react-native"
import React from "react"
interface Props{
    title:String;
}
const TitleStatische =({title}:Props)=>{
    return(
        <div className="col-12 mt-2 mb-2 text-center">
                <Text
                    style={{
                        fontWeight: "500",
                        fontSize: 18,
                        alignSelf: "center",
                        marginLeft: -10,
                    }}
                >
                    {title}
                </Text>
            </div>
    )
}

export default TitleStatische;