import {
    Text,
    View,
    StyleSheet,
    Image,
    ScrollView,
} from "react-native";

import ReactSvgPieChart from "react-svg-piechart"
import { LabelStatistiche } from "../../components/magazzino/LabelStatistiche";

export const MagazzinoGraphsStatistiche = () => {

    const data = [
            { name: "Pisa", value: 25, color: "#FFE100" },
            { name: "Livorno", value: 20, color: "#CA6C59" },
            { name: "Viareggio", value: 20, color: "#FF000C" },
            { name: "Forte dei Marmi", value: 25, color: "#007EF2" },
            { name: "Pontedera", value: 5, color: "#FF9600" },
            { name: "Vicopisano", value: 5, color: "#6CC68D" }
    ];
    return (
        <div className="square">   
            <ScrollView>
                <div className="row" style={{ backgroundColor: "white" }}>
                    <div className="col-md-10 col-sm-10 col-12 m-auto ml-5 pl-5">
                        <View style={stylesGraphMagazzino.container}>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <Text style={{fontWeight: "500",fontSize: 18,alignSelf: "center", marginLeft:-10}}
                                    >Vendite mese di Maggio</Text>
                                </div>
                                <div className="col-4 p-3 mt-5" style={{backgroundColor:"#EDECEC", borderRadius:20, width:200, height:125, marginRight:25}}>
                                    <Text style={stylesGraphMagazzino.label}>Pisa</Text>
                                    <div className="d-flex justify-content-between align-items-end">                  
                                        <Image
                                            style={stylesGraphMagazzino.image}
                                            source={require("../../assets/icons/camera.png")}
                                        />                                                                   
                                        <Text style={stylesGraphMagazzino.labelBotton}>60</Text>
                                    </div>                               
                                </div>
                                <div className="col-4 p-3 mt-5" style={{backgroundColor:"#EDECEC", borderRadius:20, width:200, height:125, marginRight:25}}>
                                    <Text style={stylesGraphMagazzino.label}>Pisa</Text>
                                    <div className="d-flex justify-content-between align-items-end">                  
                                        <Image
                                            style={stylesGraphMagazzino.image}
                                            source={require("../../assets/icons/camera.png")}
                                        />                                                                   
                                        <Text style={stylesGraphMagazzino.labelBotton}>60</Text>
                                    </div>                               
                                </div>
                                <div className="col-4 p-3 mt-5" style={{backgroundColor:"#EDECEC", borderRadius:20, width:200, height:125, marginRight:25}}>
                                    <Text style={stylesGraphMagazzino.label}>Pisa</Text>
                                    <div className="d-flex justify-content-between align-items-end">                  
                                        <Image
                                            style={stylesGraphMagazzino.image}
                                            source={require("../../assets/icons/camera.png")}
                                        />                                                                   
                                        <Text style={stylesGraphMagazzino.labelBotton}>60</Text>
                                    </div>                               
                                </div>
                                
                            
                            </div>

                            <div className="row mt-5 pb-5">
                                <div className="col-6" style={{width:250, marginLeft:-34, marginTop:5}}>
                                    <ReactSvgPieChart                                               
                                    data={data}
                                    expandOnHover
                                    angleMargin={0}
                                    strokeWidth={4}
                                    />                               
                                </div>
                                <div className="col-3 mt-4">
                                    <div className="row">
                                        {data.map((d:any) => (
                                            <LabelStatistiche name={d.name} color={d.color} value={d.value}/>
                                        ))}
                                    </div>                                   
                                </div>
                                <div className="col-3"></div>
               
                            </div>
                        </View>
                    </div>
                </div>
            </ScrollView>
        </div>
    )
}

const stylesGraphMagazzino = StyleSheet.create({   
    container: {
        flex: 1,
        backgroundColor: 'white',
        paddingHorizontal: 47,
        paddingBottom: 40,
        alignContent: "center",
        justifyContent: "center",
    } ,
    label:{
        fontFamily: "Arsapia Regular font", 
        fontSize:18
    },
    labelBotton:{
        position:"relative", 
        top:50, 
        right: 20, 
        fontWeight: "bold", 
        fontSize:18, 
        fontFamily: "Arsapia Regular font",
    },
    image:{
        position:"relative", 
        top:47,
        width: 25,
        height: 25,
        tintColor: "#999999",
        alignSelf: "center",
    }
});
