import ReactSvgPieChart from "react-svg-piechart";
import { SalesOfMonth } from "../../interfaces/statistiche";
import { LabelStatistiche } from "../magazzino/LabelStatistiche";
import { CharPieFormat } from "../../services/interfaces/GetBussinesCount";

interface Props {
  sales: CharPieFormat[];
}
export const ChartStatistiche = ({ sales }: Props) => {
  return (
    <div className="row mt-5 pb-5">
      <div className="col-12 col-md-6 mt-1">
        <div className="d-flex justify-content-center">
          <div className="chart-custom">
            <ReactSvgPieChart
              data={sales}
              expandOnHover
              angleMargin={0}
              strokeWidth={4}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4" style={{ marginTop: 32 }}>
        <div className="row justify-content-center align-items-center">
          {sales.map((item,index) => {
            return (
              <LabelStatistiche
                name={item.title}
                color={item.color}
                value={
                  item.value !== 0
                    ? Number(((item.value * 100) / item.total).toFixed(2))
                    : 0
                }
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
