import React from 'react'
import { Image, Text, TouchableOpacity, View } from 'react-native'
import { headerBarStyle as style } from '../theme/HeaderBarStyle';

interface Props {
    hasRoundedIcon?: boolean,
    hasTitle?: boolean,
    hasLogo?: boolean,
    title?: string,
    onBack: ()=>void,
    iconHeader?: any
}

export const HeaderBar = ({hasRoundedIcon = true, hasTitle = true, hasLogo=true, title, onBack, iconHeader='../assets/iconoskubitsoft/user-01.png'}:Props) => {
    return (
        
        <div className='row py-5'>
            <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex p-0'>
                <View style={style.leftButtonContainer}>
                    <TouchableOpacity
                        onPress={()=>onBack()}
                    >
                        <View>
                            <Image 
                                style={style.leftButton}
                                source={require('../assets/icons/Atras-01.png')}
                            />
                        </View>
                    </TouchableOpacity>
                </View>
            </div>
            <div className='col-xl-7 col-lg-7 col-md-7 col-sm-5 col-6 m-auto'>
                {
                    hasTitle 
                    && 
                    <View style={[style.container2,{marginLeft:'-12%'}]}>
                    {
                        (hasRoundedIcon)
                        ?
                        <View style={{
                            justifyContent: 'center'
                        }}>
                            <View
                                style={style.sectionIconContainer}
                                >
                                <Image 
                                    style={style.sectionIcon}
                                    source={iconHeader}
                                />
                            </View>
                        </View>
                        :
                        <View></View>
                    }
                    <View style={style.sectionTextContainer}>
                        <Text style={style.sectionText}>
                            {title}
                        </Text>
                    </View>
                </View>
                }
            </div>
            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-5 col-4 m-auto'>
                {
                    hasLogo
                    &&
                    <View style={style.container3}>
                        <Image 
                            style={style.sectionLogo}
                            source={require('../assets/images/sergio-capone.jpg')}
                        />
                    </View> 
                }           
            </div>
        </div>
    )
}