import React from 'react'
import { useShow } from '../../../../hooks/useShow';
import { Collapse } from "react-collapse";
import { CollapseTitleAtelier } from '../views/CollapseTitleAtelier';
import InputCheck from '../../../CRM/components/InputChek';
import { CheckMagazzino } from '../../../../components/MagazzinoRefactor/CheckMagazzino';

type Props = {
    onChange: (value: number) => void;
}

const FilterMagazzinoQuantitaAtelier = ({onChange,}:Props) => {
    const { show, toggle } = useShow();

    return ( 
        <>
            <CollapseTitleAtelier title="Quantità     " toggle={toggle} />
            <Collapse isOpened={show}>
                <div>
                     <CheckMagazzino
                        id="0"
                        marginleft
                        name="ugualezero"
                        text="Uguale a 0"
                        handlechange={onChange}
                    />
                    <br />
                </div>
            </Collapse>
        </>
    );
}

export default FilterMagazzinoQuantitaAtelier