import React from "react";
import CSS from "csstype";

export const TableMagazzinoHeader = () => {
  return (
    <thead style={{ backgroundColor: "black", color: "white", position: 'sticky', top: 0, zIndex: 10 }}>
      <tr className="mr-4">
        <th
          style={{
            borderTopLeftRadius: 50, 
            borderBottomLeftRadius: 50,
          }}
          scope="col"
          className="align-self-center mr-4 px-4"
        ></th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Referenza
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Descrizione
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Brand
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Quantità
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Messi da parte
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Negozio
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Prezzo
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        ></th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        ></th>
        <th
          style={{
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
          }}
          scope="col"
          className="align-self-center mr-4 px-4"
        ></th>
      </tr>
    </thead>
  );
};

const tableHeader: CSS.Properties = {
  textAlign: "center",
  fontWeight: "400",
  verticalAlign: "middle",
};
