import { tableHeader } from "../../GeneralStyles/Statistiche";

export const TableRepairStatisticsHeader = () => {
  return (
    <thead style={{ backgroundColor: "black", color: "white" }}>
      <tr className="mr-4">
        <th
          style={{
            borderTopLeftRadius: 50,
            borderBottomLeftRadius: 50,
            textAlign: "center",
            fontWeight: "400",
            verticalAlign: "middle",
          }}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Negozio
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          N. Riparazioni
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          N. Conclusi
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          N. Prev. rifiutati
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
           N. In. azienda
        </th>
        <th
          style={{
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
            textAlign: "center",
            fontWeight: "400",
            verticalAlign: "middle",
          }}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
         N. In garanzia
        </th>
      </tr>
    </thead>
  );
};
