import { listNavBar } from "../../GeneralStyles/SCDue/HomeSCDue";
import { useAuth } from "../../hooks/useAuth";
import { ButtonNavbar } from "./ButtonNavbar";

import { ListItem } from "./ListItem";
import { Logo } from "./Logo";
import ConfigCapone from "../../utils/ConfigCapone";
import { useEffect, useState } from "react";
import { menu, menuBtn, menuBtnMail, menuEmail } from "./elements.tsx";

type ListProps = {
  activeNav: boolean;
  componentName: string;
  componentDad?:string;
}
export const List = ({ activeNav, componentName,  componentDad }: ListProps) => {
  const indexCollapse = menu.findIndex(x => x.subItems.find(y => (y.componentChildren != undefined && y.componentChildren.includes(componentName))));

  const { user, permitedCustomEmail, IdRole } = useAuth();
  const [collapsedItems, setCollapsedItems] = useState({});
  const [collapsed, setCollapsed] = useState(indexCollapse !== -1 ? indexCollapse : 0);

  const handleCollapseToggle = (component, collapsed) => {
    setCollapsedItems({
      ...collapsedItems,
      [component]: collapsed
    });
  }; 

  useEffect(() => {
    if(( Number(IdRole) == 4 || Number(IdRole) == 5) && Number(user?.idBussines) == 9) {
      menu[1].subItems[2].permited.push("4", "5")
      menu[1].subItems[3].permited.push("4", "5")
      setCollapsed(1)
    }
  }, [user])

  if (!user) return <>cargando</>;
  
  return (
    <ul className="p-0 m-0">
      <div className="">
        <Logo />
        {permitedCustomEmail
          ? menuEmail.map((item, index) => (

            <ListItem
              key={item.component}
              item={item}
              level={1}
              onCollapseToggle={handleCollapseToggle}
              index={index}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              activeClasss={collapsed === index ? "active" : ""}
              IdRole={IdRole}
              IdBusines=""
            />
          )) 
          : menu.map((item, index) => (
            <ListItem
              key={item.component}
              item={item}
              level={1}
              onCollapseToggle={handleCollapseToggle}
              index={index}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              activeClasss={collapsed === index ? "active" : ""}
              IdRole={IdRole}
              IdBusines={user.idBussines}
              activeNav={activeNav}
              componentName={componentDad ? componentDad : componentName}
            />
          ))}
      </div>
      <div className="">
        <>
          {permitedCustomEmail
            ? menuBtnMail.map(({ component, icon, isAuthorized, }) => (
              <ButtonNavbar
                key={component}
                icon={icon}
                component={component}
                isAuthorized={isAuthorized}
                text=""
              />
            ))
            : menuBtn.map(({ component, icon, isAuthorized, text, permited, bussinesPermited }) => (
              <ButtonNavbar
                key={component}
                icon={icon}
                permited={permited}
                bussinesPermited={bussinesPermited}
                component={component}
                IdRole={IdRole}
                isAuthorized={isAuthorized}
                IdBusines={user.idBussines}
                text={text}
              />
            ))}
        </>
      </div>
    </ul>
  );
};
