import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { ResponseMsg } from "../../hooks/useEditEvent";
interface Props {
  show: boolean;
  handleDelete: () => void;
  handleCancelDelete: () => void;
  response: ResponseMsg | undefined;
  closeModal: () => void;
}

export const ConfirmDelete = ({
  show,
  handleDelete,
  handleCancelDelete,
  closeModal,
  response,
}: Props) => {
  return (
    <div className={`modal-delete ${show ? "show" : ""}`}>
      <div className="modal-contenido">
        {response ? (
          <>
            <div className="d-flex text-warning justify-content-center mb-3 flex-column align-items-center text-center">
              <span className={`icon-info ${response.status === "sucess" ? 'sucess' :'error'} mb-2`}>
                {response.status === "sucess" ? (
                  <AiOutlineCheck size={75} className="text-success" />
                ) : (
                  <AiOutlineClose size={75} className="text-danger" />
                )}
              </span>
              <h4 className="text-black">{response.title}</h4>
              <p className="text-black">{response.detail}</p>
            </div>
            <div className="d-flex text-warning justify-content-center mb-3 flex-column align-items-center">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <button
                  className="btn btn-success"
                  onClick={() => closeModal()}
                >
                  Accettare
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex text-warning justify-content-center mb-3 flex-column align-items-center">
              <h3 className="text-black">Sei sicuro?</h3>
              <p className="text-black">
                Si prega di notare che cancellerà l'evento e tutte le sue
                informazioni
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <button className="btn btn-info" onClick={() => handleDelete()}>
                Confermare
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => handleCancelDelete()}
              >
                Annulla
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
