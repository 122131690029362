import React, { useState } from 'react'
import { ExportListMagazzino } from '../../../../services/MagazzinoRefactor';
import ReactExport from "react-data-export/";
import { todayFormatDate } from '../../../../utils/helpers';


interface Props {
    clientList:any;
    setLoading:any;
}

const useExportExcelClientiAtelier = (clientList:any,setLoading:any) => {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const nameFileExport = "Tabella Clienti Atelier " + todayFormatDate

    const [tableFilterCsv, setTableFilterCsv] = useState<any>([]);
    const [donwnloadExcel, setDonwnloadExcel] = useState<boolean>(false);
  
    const handleDataExport = async () => {
      setLoading(true);
      setDonwnloadExcel(true);
      await dataExportClient();
      setLoading(false);
      setDonwnloadExcel(false);
    };
  
    const dataExportClient = async () => {
      setTableFilterCsv([]);
      try {
        const result = clientList
        setTableFilterCsv(result);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
      
    };

    const DataSet = [
      {
        columns: [
            { title: "Cliente", style: { font: { sz: "18", bold: true } } },
            { title: "E-mail", style: { font: { sz: "18", bold: true } } },
            { title: "Telefono", style: { font: { sz: "18", bold: true } } },
            { title: "Indirizzo", style: { font: { sz: "18", bold: true } } },
            { title: "Nazione", style: { font: { sz: "18", bold: true } } },
        ],
        data:
          tableFilterCsv &&
          tableFilterCsv.map((data: any) => [
            {
              value: data.name ? data.name : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
            {
              value: data.email ? data.email : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
            {
              value: data.phone ? data.phone : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
            {
              value: data.address ? data.address : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
            {
              value: data.country ? data.country : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
          ]),
      },
    ];
  
  return {donwnloadExcel,DataSet,handleDataExport,ExcelFile,ExcelSheet,nameFileExport}
}

export default useExportExcelClientiAtelier