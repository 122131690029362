import { useContext, useEffect, useState } from "react";
import { IUser, getUserFromId } from "../../../../api/ClientService";
import { httpDeleteRepair, httpGetSearchClientTableCrm, httpGetServiceById, httpGetUserAssitenza, httpPostChangeStattusService, loadDataForUpdateCrmAssistenza } from "../services/CrmAtelierFormServices";
import { UserContext } from "../../../../context/UserContext";
import { DataSearchClientTableCrm } from "../interfaces/GetSearchClientTableCrm";
import { IRepairData } from "../../../../api/RepairData";
import { StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native";
import { View } from "react-native";
import { Text } from "react-native";


export const useCrmAtelierAssistenza = ({ listSearchClientTableCrm, setListSearchClientTableCrm }) => {

    const { userInLogin } = useContext(UserContext);

    const [listUsers, setListUsers] = useState<any[]>();

    const [dataRepair, setDataRepair] = useState<any>();
    const [operatore, setOperatore] = useState<IUser>();
    const [dataTableSend, setDataTableSend] = useState<any>();
    const [idStateRepair, setIdStateRepair] = useState(0);
    const [userListAssistenza, setUserListAssistenza] = useState<any>([]);
    const [clientAssistenzia, setClientAssistenzia] = useState<any>([]);
    const [modalRepair, setModalRepair] = useState(false);
    const [dataClientRepair, setDataClientRepair] = useState([]);
    const [modalNoticeVisible, setModalNoticeVisible] = useState(false);
    const [modalVisibleTabRepair, setModalVisibleTabRepair] = useState(false);
    const [modalEmail, setModalEmail] = useState<boolean>(false);

    const [viewPagination, setViewPagination] = useState<boolean>(false);
    const [blockLeft, setBlockLeft] = useState<boolean>(true);
    const [blockRight, setBlockRight] = useState<boolean>(false);
    const [navs, setNavs] = useState([]);
    const [arrayDatas, setArrayDatas] = useState<any>([]);
    const [number, setNumber] = useState(5);
    const [numPages, setNumPages] = useState(0);
    const [page, setPage] = useState(1);
    let dataAux: any = [];

    const [isTechnique, setIsTechnique] = useState<boolean>((userInLogin?.idRole === '3' || userInLogin?.idRole === '7' || userInLogin?.idRole === '8') ? true : false);

    const handleChangeStatoTableCrm = async (evt: React.ChangeEvent<HTMLSelectElement>, IdClient: number | string, IdService: number | string) => {
        const { value } = evt.target;
        if (value !== "") {
            const response = await httpPostChangeStattusService(IdService, value, isTechnique ? userInLogin.idUser : 0);
            if (response.response === "OK") {
                const responseTable = await httpGetSearchClientTableCrm(IdClient);
                setListSearchClientTableCrm(responseTable.response);
            }
        }
    }

    const confirmDeleteCrm = (id: number | string) => {
        setIdStateRepair(Number(id));
        setModalRepair(true);
    };

    const handleDeleteCrm = async (id: number | string) => {
        try {
            await httpDeleteRepair(idStateRepair);
            const responseTable = await httpGetSearchClientTableCrm(id);
            setListSearchClientTableCrm(responseTable.response);
            setModalRepair(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleNotice = async (data: any, description: string) => {
        setDataClientRepair(data);
        description === "Avvisa" && setModalNoticeVisible(true);
        if (description === 'ring') {
            const { response } = await httpGetUserAssitenza()
            setUserListAssistenza(response)
            setModalEmail(true);
        }
    };

    const goToRepairTab = async (data: DataSearchClientTableCrm) => {
        setDataTableSend(data)
        let { response: dataService } = await httpGetServiceById(data.idService)
        await getUserFromId(dataService.idUser).then((result: IUser) => setOperatore(result));
        let dataToNewRepair = await loadDataForUpdateCrmAssistenza(data);
        setDataRepair(dataService);
        setClientAssistenzia(dataToNewRepair);
        setModalVisibleTabRepair(true);
    };

    const handleNextUsers = () => {
        let data: IRepairData[] = arrayDatas.slice(number, number + 5);
        setListUsers(data);
        setNumber(number + 5);
        setBlockLeft(false);
        if (number + 5 >= arrayDatas.length) {
            setBlockRight(true);
        }
    };

    const handlePreviousUsers = () => {
        let data: IRepairData[] = arrayDatas.slice(number - 10, number - 5);
        setNumber(number - 5);
        setListUsers(data);
        setBlockRight(false);
        if (number - 5 <= 5) {
            setBlockLeft(true);
        }
    };

    const handleViewPage = (page: number) => {
        setPage(page)
        const newPage = page * 5;
        if (arrayDatas.length > 0) {
            setListUsers(arrayDatas?.slice(page * 5 - 5, page * 5));
        } else {
            setListUsers(dataAux?.slice(page * 5 - 5, page * 5));
        }
        setNumber(newPage);
        setBlockRight(false);
        setBlockLeft(true);
        if (page > 1) {
            setBlockLeft(false);
        }
        if (navs.length == 0) {
            if (page == numPages) {
                setBlockRight(true);
            }
        } else {
            if (page == navs.length) {
                setBlockRight(true);
            }
        }
    };

    const loadNumberPages = (pages: number) => {
        let navsAux: any = [];
        for (let i = 0; i < pages; i++) {
            navsAux.push(
                <TouchableOpacity key={i} onPress={() => handleViewPage(i + 1)}>
                    <View>
                        <Text
                            style={{
                                fontWeight: "400",
                                color: "black",
                                fontSize: 14,
                                paddingHorizontal: 10,
                            }}
                        >
                            {number / 5 == i + 1 && (
                                <Text style={{ fontWeight: "bold" }}>»</Text>
                            )}
                            {i + 1}
                            {number / 5 == i + 1 && (
                                <Text style={{ fontWeight: "bold" }}>«</Text>
                            )}
                        </Text>
                    </View>
                </TouchableOpacity>
            );
        }
        setNavs(navsAux);
    };

    const restartPagination = (resp: any) => {
        if (resp.length > 0) {
            dataAux = resp;
            setArrayDatas(resp);
            setListUsers(resp.slice(0, 5));
            const numberPages = Math.ceil(resp.length / 5);
            loadNumberPages(numberPages);
            if (resp.length <= 5) {
                setViewPagination(false);
            } else {
                setViewPagination(true);
            }
        }
    };

    const stylesCrmRepair = StyleSheet.create({
        imageWithMargin: {
            width: 15,
            marginLeft: 30,
            height: 15,
            tintColor: "rgb(200,200,200)",
        },
        arrowDown: {
            width: 20,
            height: 20,
            marginTop: 28,
            tintColor: "#808080",
            // alignSelf: "center",
        }
    })

    useEffect(() => {
        loadNumberPages(Math.ceil((arrayDatas ? arrayDatas.length : 0) / 5));
    }, [number]);

    useEffect(() => {
        restartPagination(listSearchClientTableCrm)
    }, [listSearchClientTableCrm]);

    return {
        modalRepair,
        setModalRepair, idStateRepair, dataClientRepair, modalNoticeVisible, setModalNoticeVisible, modalEmail, setModalEmail, userListAssistenza, modalVisibleTabRepair,
        setModalVisibleTabRepair, clientAssistenzia, dataRepair, operatore, dataTableSend,
        handleChangeStatoTableCrm, confirmDeleteCrm, handleDeleteCrm, handleNotice, goToRepairTab,
        viewPagination, blockLeft, handlePreviousUsers, navs, blockRight, handleNextUsers, stylesCrmRepair, listUsers
    }
}
