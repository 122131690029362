import { RouteProp, useRoute } from "@react-navigation/native";
import { RootStackParams } from "../../../navigator/NavigatorConfig";
import { useContext, useEffect, useState } from "react";
import { Notify } from "../../../interfaces/Notify";
import { putRead } from "../../../services/Notify";
import { UserContext } from "../../../context/UserContext";
import { bussinesTaskByIdUserScreen, listByidTaskBussines, putTaskUserIsChecked, putTaskBussinesIsChecked, userTaskByIdUser } from "../Services/TaskServices";

export const useTaskCrud = () => {
    const { userInLogin } = useContext(UserContext);
    const [ListTaskBussinesById,setListTaskBussinesById] =useState({
        items:[],normalizedResults:[],length
    })
    const [listIdTaskBussinesView,setListIdTaskBussineView] = useState()
    const { params } = useRoute<RouteProp<RootStackParams, "TaskScreen">>();
    const [isLoading, setIsLoading] = useState(false);
    const [myNotify, setMyNotify] = useState<Notify[]>(params.items);
    const [view, setView] = useState<number | undefined>(undefined);
    const handleView = async (value: number) => {
      try {
        const notify = myNotify.find((item) => item.id === value);
        if (!notify) {
          throw new Error();
        }
        if(notify.read === 0){
          await putRead(value,notify.coduser)
        }      
        setMyNotify([
          ...myNotify.filter((item) => item.id !== value),
          {
            ...notify,
            read: 1,
          },
        ]);
        setView(view === value ? undefined : value);
      } catch (error) {
        console.log(error)
      }
  
    };
    const confirmviewTask = async (data) => {
      let conditionalPut = data.idNewUser
      
      if(conditionalPut){
          const result = await putTaskUserIsChecked(data.idNewUser)
          if(result){
            await GetListTaskBussinesById()
          }
      }else{
         const result = await putTaskBussinesIsChecked(data)
          if(result){
            await GetListTaskBussinesById()
          }
      }
       
    }
    const GetListTaskBussinesById = async () => {
        const idUser = Number(userInLogin.idUser)
        
        const result = await bussinesTaskByIdUserScreen(idUser)
        const resultUser = await GetListTaskUserById()
        let resultConcat = result;
        if(resultUser){
            resultConcat = result.concat(resultUser)
        }
        resultConcat.sort(function(a, b) {
          return  new Date(b.dataInsert).getTime() - new Date(a.dataInsert).getTime();
        });
        //debugger
        if(result){
            const items = resultConcat.slice(0, 3);
            const normalizedResults = resultConcat
            const dataRender = {items,normalizedResults,length:normalizedResults.length}
            setListTaskBussinesById(dataRender)
        }
    }
    //ListNewUser
    const GetListTaskUserById = async () => {
      const idUser = Number(userInLogin.idUser)
      
      const result = await userTaskByIdUser(idUser)
      if(result){
          return result
          // const items = result.slice(0, 3);
          // const normalizedResults = result
          // const normalizedResultLength = result.filter(x => x.isChecked == 0)
          // const dataRender = {items,normalizedResults,length:normalizedResultLength.length}
          // setListNewUserView(dataRender)
      }
  }
    const GetListByIdTaskBussines = async (dataSend) => {
      
      const result = await listByidTaskBussines(dataSend)
      if(result){
        setListIdTaskBussineView(result)
      }
  }
    console.log("aqui paramns",params)
    useEffect(() => {
        GetListTaskBussinesById()
    }, [])
    
    return {
        ListTaskBussinesById,
        handleView,
        view,
        myNotify: "filtered().data",
        isLoading,
        confirmviewTask,
        GetListByIdTaskBussines,
        listIdTaskBussinesView
}
}