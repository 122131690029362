import { useField } from "formik";

export const SelectCustom = ({
  placeholder,
  id,
  icon,
  label,
  options,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  return (
    <div className="form-group mb-4">
      <label htmlFor={id}>{label}</label>
      <select
        {...field}
        className="form-control"
        id={id}
        placeholder={placeholder}
        aria-describedby={id}
        {...props}
        
      >
        <option value="">Seleziona</option>
        {options.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
        <option value="7">Tutti</option>
        <option value="8">Privato</option>
      </select>
      {meta.touched && Boolean(meta.error) ? (
        <div className="message-container mb-3">
          <div className="help-block">{meta.error}</div>
        </div>
      ) : null}
    </div>
  );
};
