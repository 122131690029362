import { dateTimeNowNotify } from "../../services/interessiServices";

interface Props{
    eventsNotify:any
}
const ListNotify =({eventsNotify}:Props)=>{
return(
    <div>
        <ul className="list-group">
        {eventsNotify.map(({title,start,details,end},index)=>(
            <><li className="list-group-item">
                <b>Titolo </b>{title}
                <br/> 
                <b>Inizio </b> {dateTimeNowNotify(start)}
                <br/>
                <b>Fine </b> {dateTimeNowNotify(end)}
                <br/>
                {details?<><b>Dettaglio </b>{details}</>:null}</li>
               <br/></> 
        )
            
        )} 
        
        </ul>
    </div>
)
}

export default ListNotify;