import { createContext, useReducer } from "react";
import { Adittional } from "../../components/FormRepair/steps/Adittional";
import { Client } from "../../components/FormRepair/steps/Client";
import { Finish } from "../../components/FormRepair/steps/Finish";
import { Repair } from "../../components/FormRepair/steps/Repair";
import { useAuth } from "../../hooks/useAuth";
import {
  FormRepairProps,
  FormRepairState,
  Props,
} from "../../interfaces/formRepair";

import { formRepairReducer } from "./reducer";

const steps = [
  <Client key={0} />,
  <Repair key={1} />,
  <Adittional key={2} />,
  <Finish key={3} />,
];
export const initialState: FormRepairState = {
  title: "",
  name: "",
  address: "",
  email: "",
  telephone: "",
  business: "",
  user: "",
  brandOfProduct: "",
  referens: "",
  enrollment: "",
  envelopeNumber: "",
  file: undefined,
  fileName: "",
  note: "",
  currentStep: 0,
  view: [0],
};

export const FormRepairContext = createContext<FormRepairProps>(
  {} as FormRepairProps
);

export const FormRepairProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(formRepairReducer, initialState);
  const { user } = useAuth();
  const next = (data: FormRepairState) => {
    try {
      dispatch({
        type: "[FORMREPAIR] step",
        payload: {
          ...data,
          currentStep: state.currentStep + 1,
          view: [...state.view, state.currentStep + 1],
        },
      });
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  };
  const prev = (data: FormRepairState) => {
    try {
      if (state.currentStep > 0) {
        dispatch({
          type: "[FORMREPAIR] step",
          payload: {
            ...data,
            currentStep: state.currentStep - 1,
          },
        });
      }
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  };
  return (
    <FormRepairContext.Provider
      value={{
        globalState: {
          ...state,
          user: user?.name,
        },
        steps,
        next,
        prev,
      }}
    >
      {children}
    </FormRepairContext.Provider>
  );
};
