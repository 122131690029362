import { Calendar } from "react-big-calendar";
import { useCalender } from "../../hooks/useCalender";
import { localizer, messagesCalender } from "./elements";
import { Header } from "./Header";
import { HeaderCalender } from "./HeaderCalender";
import { Row } from "./Row";
import { Tolbar } from "./Tolbar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../GeneralStyles/styleCalender.css";
import { FooterCelender } from "./FooterCelender";
import { NotifyCalendar } from "./NotifyCalendar";
import { useModalNotify } from "../../hooks/useModalNotify";
import { useEffect, useState } from "react";
import { ModalAplicationNotify } from "../Modal/ModalAplicationNotify";
import ListNotify from "./ListNotify";
import { ModalCalendarDay } from "../Modal/ModalCustom";
import { useNavigation } from "@react-navigation/native";
import { NavigationProps } from "../../navigator/NavigatorConfig";
import { useModal } from "../../hooks/useModal";
interface Props{
  activeNotify:boolean;
  setAtiveNotify:any;
}
export const Calender = ({activeNotify,setAtiveNotify}:Props) => {
  const { events,eventsNotify, handleView, handleEventClick,
       setIsOpenDay, eventsForMonthView, dateSelected, isOpenDay, closeModalDay, dataDay
  } = useCalender();
  const { closeModal, isOpen, openModalAdd ,setIsOpen} = useModalNotify();

  const navigation = useNavigation<NavigationProps>();

  const navigate = () => {
    setIsOpenDay(false)
    navigation.navigate("CalenderDetailScreen", {
      date: dateSelected.getTime().toString()
    })
    setIsOpenDay(false)
  }

  useEffect(()=>{
    if(eventsNotify.length > 0 && activeNotify ){
      setIsOpen(true)   
    }
  },[eventsNotify])
  
  return (
    <div className="">
      <HeaderCalender />
      <Calendar
        className="calender-main"
        showAllEvents={false}
        doShowMoreDrillDown={false}
        onView={handleView}
        events={eventsForMonthView}
        showMultiDayTimes={true}
        culture="it-CH"
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '70vh' }}
        messages={{
          ...messagesCalender,
        }}
        views={["month"]}
        defaultView="month"
        onSelectEvent={handleEventClick}
        components={{
          toolbar: Tolbar,
          header: Header,
          month: {
            dateHeader: Row,
            event: NotifyCalendar
          },
        }}
      />
      <FooterCelender />
      <ModalAplicationNotify
          title="Prossimi eventi"
          isOpen={isOpen}
          closeModal={closeModal}
          setAtiveNotify={setAtiveNotify}
        > 
         <ListNotify eventsNotify={eventsNotify}/>
        </ModalAplicationNotify>  

        <ModalCalendarDay
          title="Eventi della giornata"
          isOpen={isOpenDay}
          closeModal={closeModalDay}
          navigation={navigate}
        >
          <ListNotify eventsNotify={dataDay}/>
        </ModalCalendarDay>   
    </div>
  );
};
