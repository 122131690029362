import { TouchableOpacity } from "react-native";
import { useAuth } from "../../hooks/useAuth";
import { useNavigationWhitProps } from "../../hooks/useNavigationWhitProps";
import { RoundedButton } from "../RoundedButton";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import Cookies from 'universal-cookie';
interface Props {
  component: any;
  icon: JSX.Element;
  isAuthorized?: boolean;
  text: string;
  permited: string[];
  bussinesPermited: string[];
  IdRole:string;
  IdBusines:string;
}
export const ButtonNavbar = ({ component, icon, isAuthorized = false, text, permited, IdRole, IdBusines, bussinesPermited }: Props) => {
  const { navigationWhitProps } = useNavigationWhitProps();
  const { userInLogin, setUserInLogin } = useContext(UserContext);
  const { isAdmin } = useAuth();
  const cookies = new Cookies();
  //const permited = !isAuthorized || (isAuthorized && isAdmin);
  const _permited = true;
  const DestroySession = () => {
    if (component == "Assistenza - Sergio Capone") {
      setUserInLogin(null);
      cookies.remove('token', { path: '/' });
      cookies.remove('userInLogin', { path: '/' });
    }
  }
  return ( 
    <>
      {( permited.includes(IdRole) && bussinesPermited.includes(IdBusines)) &&
        // <div className="my-4" style={{ height: 50, marginLeft: -12 }}>
        //   <TouchableOpacity
        //     onPress={() =>{ navigationWhitProps.navigate(component);DestroySession()}}
        //   >
        //     <RoundedButton
        //       icon={require(`../../assets/icons-step-due/${icon}`)}
        //       size={58}
        //       styleIcon={70}
        //     />
        //   </TouchableOpacity>
        // </div>
        <li>
          <div className="listFooter">
            <div className="icon">
              {/* <img src={require(`../../assets/icons-step-due/${icon}`)} alt="" /> */}
              {icon}
            </div>
            <button
              onClick={() => { navigationWhitProps.navigate(component); DestroySession() }}
            >
              <div className="text">
                {text}
              </div>
            </button>
          </div>
        </li>

      }
    </>
  );
};
