import React, { useState } from "react";
import {
  TextInput,
  StyleSheet,
  TouchableOpacity,
  View,
  Image,
} from "react-native";

interface Props {
  placeholder: string;
  handleSearchFilter: () => void;
  marginRight: boolean;
  onChangeTextProdotto:any;
  searchInputProdotto:any;

}
export const FilterMagazzino = ({
  placeholder,
  onChangeTextProdotto,
  searchInputProdotto,
  handleSearchFilter,
  marginRight= false
}: Props) => {
  return (
    <div className="d-flex justify-content-center px-0">
      <TextInput
        placeholder={placeholder}
        style={[stylesRepair.input, stylesRepair.searchText]}
        onChangeText={ onChangeTextProdotto}
        value={searchInputProdotto}
      />
      <TouchableOpacity
        onPress={() => handleSearchFilter()}
        style={{
          margin: marginRight ? "0" : "auto",
          marginRight: marginRight ? -12 : 0,
        }}
      >
        <View style={[stylesRepair.viewDisable]}>
          <Image
            style={[stylesRepair.searchIcon]}
            source={require("../../assets/icons/icon-search.png")}
          />
        </View>
      </TouchableOpacity>
    </div>
  );
};

const stylesRepair = StyleSheet.create({
  input: {
    height: 45,
    width: "110%",
    borderWidth: 2,
    fontFamily: "Arsapia Regular font",
    padding: 6,
    borderRadius: 50,
    marginRight: "1%",
  },
  searchText: {
    alignSelf: "flex-end",
    borderColor: "#A4A4A4",
    paddingRight: 2,
    paddingLeft: 15,
    fontSize: 20,
  },
  searchIcon: {
    width: 20,
    height: 20,
    tintColor: "white",
    alignSelf: "center",
  },
  viewDisable: {
    backgroundColor: "black",
    borderRadius: 50,
    justifyContent: "center",
    width: 40,
    height: 40,
    marginTop: "2%",
  },
});
