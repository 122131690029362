import { ScrollView, View, SafeAreaView, StatusBar } from "react-native";
import { Collapse } from "react-collapse";
import { styles } from "../../theme/GlobalStyle";
import {
  CercaStatische,
  DipendenteStatische,
  FilterStatische,
  FilterWhit,
  NegozioStatische,
  Statische,
  TableStatische,
  BussinesStatistiche,
} from "../../components/MagazzinoStatistiche";
import { HeaderStatistiche } from "../../components/MagazzinoStatistiche/HeaderStatistiche";
import { stylesStatisticheMagazzino } from "../../GeneralStyles/Statistiche";
import { useMagazzinoStatistiche } from "../../hooks/useMagazzinoStatistiche";
import Loading from "../../components/Loading";
import { NotResults } from "../../components/MagazzinoStatistiche/NotResults";
import { MeseAnno } from "../../components/MagazzinoStatistiche/MeseAnno";
import { FilterMeseAnno } from "../../components/MagazzinoStatistiche/FilterMeseAnno";
import { VenditeProdotti } from "../../components/MagazzinoStatistiche/VenditeProdotti";
import { VenditePerCitta } from "../../components/MagazzinoStatistiche/VenditePerCitta";
import TitleStatische from "../../components/MagazzinoStatistiche/TitleStatische";
import { FilterMagazzino } from "../../components/MagazzinoRefactor/FilterMagazzino";
import { RepairStatistics } from "../../components/MagazzinoStatistiche/RepairStatistics";

interface Props {
  onChangeTextProdotto: any;
  searchInputProdotto: any;
  handleSearchFilter: any;
}

export const MagazzinoStatistiche = ({ onChangeTextProdotto, searchInputProdotto, handleSearchFilter }: Props) => {
  const {
    isLoading,
    bussines,
    brand,
    sales,
    dipendiente,
    showD,
    showN,
    showNP,
    showBP,
    showRP,
    showVC,
    showBRS,
    salesOfMonth,
    showDate,
    showDateGraphic,
    dateFilter,
    dateGraphicFilter,
    totalVendite,
    venditeProdotti,
    venditePerCitta,
    paginationListe,
    paginaActual,
    paginationListeCitta,
    paginaActualCitta,
    referenza,
    dataGraphic,
    handleChangeDipendiente,
    handleChangeReferenza,
    handleChangeCheck,
    handleCheck,
    handleChangeCity,
    handleChangeStatistic,countBussines,
    statisticDate,
    city,
    statisticData,
    handleShowDipendiente,
    handleShowNegozio,
    handleShowNegozioProdotti,
    handleShowMeseAnno,
    handleShowMeseAnnoGraphic,
    handleChangeRepairs,
    handleShowMeseAnnoRepairs,
    handleChangeDateFilter,
    handleShowBrandProdotti,
    handleShowReferenzaProdotti,
    handleChangeCheckBrand,
    handleChangeCheckNegozioProdotti,
    handleChangeDateGraphicFilter,
    handleCheckNegozioProdotti,
    handleCheckBrand,
    setPaginaActual,
    setPaginaActualCitta,
    handleShowVenditeCitta,
    handleChangeCheckVenditeCitta,
    handleCheckVenditeCitta,
    handleChangeUserBusines,
    handleChangeDateRepairStatistics,
    userAndBusinesFilter,
    statisticDateRepairs,
    showDateRepairs,
    repairStatistics,
    paginationRipairs,
    setPaginaActualRepairs,
    paginaActualRepairs,
    handleShowMeseAnnoVenditeProdotti,
    showDateVenditeProdotti,
    handleChangeDateVenditeProdotti,
    statisticDateVenditeProdotti,
    handleShowBrandRepairStatistics,
    handleCheckBrandRepairStatistics,
    handleChangeCheckBrandRepairStatistics
  } = useMagazzinoStatistiche();
  if (isLoading) return <Loading activeSpinner={isLoading} />;
  return (
    <div className="square">
      <ScrollView style={styles.scroll}>
        <SafeAreaView
          style={{
            flex: 1,
            paddingTop: StatusBar.currentHeight,
            backgroundColor: "white",
          }}
        >
          <HeaderStatistiche />
          <View style={stylesStatisticheMagazzino.wrapper}>
            <FilterStatische />
            <TitleStatische title={"Vendite dipendenti"} />
            <div className="d-flex justify-content-end">
              <div className="col-12 col-sm-5 col-md-6 col-lg-3">
                <CercaStatische
                  value={userAndBusinesFilter}
                  handleSearch={handleChangeUserBusines}
                />
              </div>
            </div>


            <div className="mt-4 pt-2">
              <div className="row">
                <div className="col-12 col-sm-4 col-md-3 col-lg-3">
                  <div className="row mt-4">
                    <FilterWhit />

                    <MeseAnno handleShow={handleShowMeseAnno} />
                    <Collapse isOpened={showDate}>
                      <FilterMeseAnno
                        value={dateFilter}
                        handleSearch={handleChangeDateFilter}
                      />
                    </Collapse>
                    <DipendenteStatische handleShow={handleShowDipendiente} />
                    <Collapse isOpened={showD}>
                      <CercaStatische
                        value={dipendiente}
                        handleSearch={handleChangeDipendiente}
                      />
                    </Collapse>
                    <NegozioStatische handleShow={handleShowNegozio} />
                    <Collapse isOpened={showN}>
                      <BussinesStatistiche
                        bussines={bussines}
                        handleChangeCheck={handleChangeCheck}
                        handleCheck={handleCheck}
                      />
                    </Collapse>
                  </div>
                </div>
                {/* BEGIN TABLE */}


                {console.log(sales)}

                {sales == undefined && <NotResults />}
                {sales?.length > 0 &&
                  <TableStatische
                    sales={sales}
                    venditeTotali={totalVendite}
                  />}
                {/* END TABLE */}
              </div>
              <Statische
                items={statisticData}
                countBussines={countBussines}
                date={dateFilter}
                handleChangeStatistic={handleChangeStatistic}
                statisticDate={statisticDate}
                handleShowMeseAnnoGraphic={handleShowMeseAnnoGraphic}
                showDateGraphic={showDateGraphic}
                dateGraphicFilter={dateGraphicFilter}
                handleChangeDateGraphicFilter={handleChangeDateGraphicFilter} />
              <VenditeProdotti venditeProdotti={venditeProdotti}
                brand={brand}
                referenza={referenza}
                paginationListe={paginationListe}
                paginaActual={paginaActual}
                setPaginaActual={setPaginaActual}
                showNP={showNP}
                showBP={showBP}
                showRP={showRP}
                bussines={bussines}
                handleChangeCheckNegozioProdotti={handleChangeCheckNegozioProdotti}
                handleCheckNegozioProdotti={handleCheckNegozioProdotti}
                handleChangeCheckBrand={handleChangeCheckBrand}
                handleCheckBrand={handleCheckBrand}
                handleChangeReferenza={handleChangeReferenza}
                handleShowNegozioProdotti={handleShowNegozioProdotti}
                handleShowBrandProdotti={handleShowBrandProdotti}
                handleShowReferenzaProdotti={handleShowReferenzaProdotti}
                handleShowMeseAnnoVenditeProdotti={handleShowMeseAnnoVenditeProdotti}
                showDateVenditeProdotti={showDateVenditeProdotti}
                handleChangeDateVenditeProdotti={handleChangeDateVenditeProdotti}
                statisticDateVenditeProdotti={statisticDateVenditeProdotti}
              />
              <VenditePerCitta venditeProdotti={venditePerCitta}
                showVC={showVC}
                paginationListeCitta={paginationListeCitta}
                paginaActualCitta={paginaActualCitta}
                setPaginaActualCitta={setPaginaActualCitta}
                bussines={bussines}
                handleChangeCity={handleChangeCity}
                city={city}
                handleShowVenditeCitta={handleShowVenditeCitta}
                handleChangeCheckVenditeCitta={handleChangeCheckVenditeCitta}
                handleCheckVenditeCitta={handleCheckVenditeCitta}
              />
              <RepairStatistics repairStatistics={repairStatistics}
                brand={brand}
                showBRS={showBRS}
                handleChangeRepairs={handleChangeRepairs}
                statisticDateRepairs={statisticDateRepairs}
                handleShowMeseAnnoRepairs={handleShowMeseAnnoRepairs}
                showDateRepairs={showDateRepairs}
                handleChangeDateRepairStatistics={handleChangeDateRepairStatistics}
                handleShowBrandRepairStatistics={handleShowBrandRepairStatistics}
                handleCheckBrandRepairStatistics={handleCheckBrandRepairStatistics}
                handleChangeCheckBrandRepairStatistics={handleChangeCheckBrandRepairStatistics}
                showVC={showVC}
                paginationRipairs={paginationRipairs}
                paginaActualRepairs={paginaActualRepairs}
                setPaginaActualRepairs={setPaginaActualRepairs}
                bussines={bussines}
              />

            </div>
          </View>
        </SafeAreaView>
      </ScrollView>
    </div>
  );
};
