import axios from "axios";
import ConfigCapone from "../utils/ConfigCapone";

export const getOptionsTitle = async () => {
  try {
    const resp = await axios.get(
      `${ConfigCapone.urlapi}/api/Title?parameter=1`
    );
    if (resp.data.status === "success") {
      const normalizedResponse = resp.data.response.map(
        ({ desc_Title, idTitle }) => {
          return {
            value: idTitle,
            label: desc_Title,
          };
        }
      );
      return normalizedResponse;
    }
  } catch (error) {
    throw new Error();
  }
};

export const getBussiness = async () => {
  try {
    const resp = await axios(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`);
    if (resp.data.status === "success") {
      const normalizedResponse = resp.data.response.map(
        ({ desc_Bussines, idBussines }) => {
          return {
            value: idBussines,
            label: desc_Bussines,
          };
        }
      );
      return normalizedResponse;
    }
  } catch (error) {
    throw new Error();
  }
};

export const getProduct = async () => {
  try {
    const resp = await axios(`${ConfigCapone.urlapi}/api/Product?parameter=1`);
    if (resp.data.status === "success") {
      const normalizedResponse = resp.data.response.map(
        ({ desc_Product, idProduct }) => {
          return {
            value: idProduct,
            label: desc_Product,
          };
        }
      );
      return normalizedResponse;
    }
  } catch (error) {
    throw new Error();
  }
};

export const getBrand = async () => {
  try {
    const resp = await axios.get(
      `${ConfigCapone.urlapi}/api/Brand?parameter=1`
    );
    if (resp.data.status === "success") {
      const normalizedResponse = resp.data.response
        .filter((data) => data.desc_Brand !== "Altro")
        .map(({ desc_Brand, idBrand }) => {
          return {
            value: idBrand,
            label: desc_Brand,
          };
        });
      return normalizedResponse;
    }
  } catch (error) {
    throw new Error();
  }
};
