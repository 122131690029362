import { useField } from "formik";

export const InputCustom = ({
  placeholder,
  id,
  icon,
  label,
  ...props
}: any) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-group mb-4">
      <label htmlFor={id}>{label}</label>
      <input
        {...field}
        className="form-control"
        id={id}
        placeholder={placeholder}
        aria-describedby={id}
        {...props}
      />
      {meta.touched && Boolean(meta.error) ? (
        <div className="message-container mb-3">
          <div className="help-block">{meta.error}</div>
        </div>
      ) : null}
    </div>
  );
};
