import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useState } from 'react'
import { HiUserCircle } from "react-icons/hi";
import { IoIosArrowBack } from 'react-icons/io';
import { NavigationProps } from '../../navigator/NavigatorConfig';
import { useNavigation } from '@react-navigation/native';
import { useAuth } from '../../hooks/useAuth';

type NavProps = {
    showMenuBack?:boolean
    sidebarVisible?:boolean
    toggleSidebar?:any
    params?:any
    goBack?:any
    goBackName?:string
}



const NavProfile = ({showMenuBack,sidebarVisible,toggleSidebar,goBack,goBackName,params}:NavProps) => {
    const navigation = useNavigation<NavigationProps>();
    const { user } = useAuth();
    const handleGoBack = () => {
        if(goBackName === 'Pannello di controllo'){
            navigation.navigate("HomeSCDueScreen", {  refresh  : true })
        }else{
            navigation.goBack()
        }
    }
    return (
        <>
            {
                !sidebarVisible
                 &&
                <div className="d-lg-none" style={{zIndex: 1, position: 'relative', cursor: 'pointer', marginTop: '-10px', marginBottom: 5}} onClick={toggleSidebar}>
                        <img src={require('../../assets/icons/menu.png')} alt="" style={{width: 25}} />
                </div>
            }
            <div className='d-flex align-items-center justify-content-between mb-4' style={{cursor: "pointer"}}>
                <div className='d-flex align-items-center' 
                    onClick={() =>  handleGoBack()}>
                    {showMenuBack && <><IoIosArrowBack
                    size={25}
                />
                <h5 className='mt-2'>{goBackName}</h5>
                </>  }
                
                </div>
                <div>
                <HiUserCircle
                    size={40}
                />
                {user.name + " " + user.lastName.substring(0, 1) + "."}
                </div>
            </div> 
        </>
    )
}

export default NavProfile