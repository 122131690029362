import ButtonComponent from "../../../../components/ComponentsAtelier/buttoms/buttonComponent";
import InputComponent, { optionValues } from "../../../../components/ComponentsAtelier/inputs/inputComponent";
import useFormNuovaListaOrdiniAtelier from "./hooks/useFormNuovaListaOrdiniAtelier";

type Props = {
    listOrdini?: optionValues[];
    operation: 'edit' | "delete" | 'add';
    onBeforeSubmit?: () => void;
    onBeforeAnulla?: () => void;
}

const NuovaListaOrdini = ({ listOrdini, operation, onBeforeSubmit, onBeforeAnulla }: Props) => {

    const { handleSubmit, onSubmit, register, handleAnulla } = useFormNuovaListaOrdiniAtelier({ operation, onBeforeSubmit, onBeforeAnulla });

    return (
        <form className="row" onSubmit={handleSubmit(onSubmit)}>
            {(operation === 'add' || operation === 'edit') &&
                <div className="col-12">
                    <InputComponent
                        label=""
                        name="name"
                        register={register('name')}
                        inputProps={{ placeholder: operation === 'edit' ? "Inserire nuovo nome" : "" }}
                        type="text"
                    />
                </div>
            }
            {(operation === 'delete' || operation === 'edit') &&
                <div className="col-12">
                    <InputComponent
                        label=""
                        name=""
                        register={register('IdLista')}
                        type="select"
                        options={listOrdini}
                    />
                </div>
            }

            <hr className="mt-2" style={{ height: '1px', backgroundColor: '#dee2e6' }} />
            <div className="col-12 d-flex justify-content-end gap-2 mt-2">
                <ButtonComponent text={`Annulla`} variant={2} onClick={() => { handleAnulla() }} />
                <ButtonComponent text={`Conferma`} variant={1} type="submit" />
            </div>
        </form >
    )
}

export default NuovaListaOrdini