/* eslint-disable multiline-ternary */

import { useFormRepair } from "../../../hooks/formRepair/useFormRepair"

export const ActionsStep = () => {
  const { currentStep, prev, initialValues, steps } = useFormRepair()
  const position =
    currentStep === 0 ? 'justify-content-end' : ' justify-content-between'
  const restBlock = currentStep - (steps.length - 1) === 0
  return (
    <div className="row mt-2">
      <div className="col-md-11 mx-auto">
        <div className="row">
          <div className="col-md-12 mt-5">
            <div className="wrapper-steps-actions">
              <div className={`d-flex  ${position}`}>
                {currentStep > 0 && (
                  <button
                    onClick={() => prev(initialValues)}
                    className="btn btn-outline-secondary"
                  >
                    Precedente
                  </button>
                )}

                {restBlock ? (
                  <button className="btn btn-outline-success">
                    Finalizzare
                  </button>
                ) : (
                  <button className="btn btn-outline-dark" type="submit">
                    Seguente
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
