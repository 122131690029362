import React, { useEffect } from "react";
import {
  SafeAreaView,
  StatusBar,
  Text,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
} from "react-native";
import { HeaderBar } from "../components/HeaderBar";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useState } from "react";
import { AdminData } from "../interfaces/IAdminData";
import PopupConfirmation from "../components/PopupConfirmation";
import axios from "axios";
import ConfigCapone from "../utils/ConfigCapone";
import { Icon } from "react-native-elements";
import Loading from "../components/Loading";
import EditUserScreen from "./EditUserScreen";
import { styles } from "../theme/GlobalStyle";
import PopupMessage from "../components/PopupMessage";
import PopUpMessageWithRoute from "../components/PopUpMessageWithRoute";
import Cookies from 'universal-cookie';
interface Props extends NativeStackScreenProps<any, any> {}
export const AdminScreen = ({ navigation, route }: Props) => {
  const baseUrl = ConfigCapone.urlapi;
  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });
  let admin: AdminData = {
    id_User: 0,
    name_User: "",
    password_User: "",
    rol_User: 0,
    store_User: 0,
    des_buss: "",
    des_rol: "",
  };
  let users = [
    {
      active: true,
      idUser: 0,
      lastName: "",
      name: "",
      pass: "",
      total: "0",
      userName: "",
      desc_Role: "",
      desc_Bussines: "",
    },
  ];
  const [dataAdmin, setdataAdmin] = useState(users);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const getUsers = async () => {
    const res = await axios(baseUrl + "/api/User?parameter=1")
      .then((response) => setdataAdmin(response.data.response))
      .catch((error) => console.log(error));
  };
  //cookie
    const cookies = new Cookies();
  const [modalConfirm, setModalConfirm] = useState(false);
  const [ModalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    getUsers();
  }, [ModalVisible, modalConfirm]);
  const [dataSelected, setdata] = useState(admin);
  const [edit, setedit] = useState(false);
  const [idStateAdmin, setIdStateAdmin] = useState(0);
  const OpenModalEdit = (data: any) => {
    setModalVisible(true);
    setdata(data);
    setedit(true);
  };
  const OpenModalAdd = () => {
    setModalVisible(true);
    setdata({
      des_buss: "",
      des_rol: "",
      id_User: 0,
      name_User: "",
      password_User: "",
      rol_User: 0,
      store_User: 0,
    });
    setedit(false);
  };
  const confirmDelete = (id: number) => {
    setIdStateAdmin(id);
    setModalConfirm(true);
  };
  const handleDelete = async (id: number) => {
    setActiveSpinner(true);
    const response = await axios
      .delete(baseUrl + "/api/User/" + id,{
        headers: {
          'Authorization': `Bearer ${cookies.get('token')}`
        }
      })
      .then((response) =>response )
      .catch((error) => setModalMessage({
        active: true,
        message: "Si è verificato un errore durante la rimozione.",
      }));

    setModalConfirm(false);
    setActiveSpinner(false);
  };

  const openUnlockUser = async (id: number)=>{
    console.log(`id user: ${id}`);
    const response = await axios
      .put(baseUrl + "/api/User/unlock/" + id,{},{
        headers: {
          'Authorization': `Bearer ${cookies.get('token')}`
        }
      })
      .then((response) =>{
        setModalMessage({
          active: true,
          message: "Utente sbloccato con successo.",
        });
        getUsers();
      })
      .catch((error) => setModalMessage({
        active: true,
        message: "Si è verificato un errore durante la rimozione.",
      }));

    setModalConfirm(false);
    setActiveSpinner(false);
    return;
  };
  
  

  return (
    <ScrollView style={styles.scroll}>
      {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: StatusBar.currentHeight,
          backgroundColor: "white",
        }}
      >
        <View
          style={{
            paddingHorizontal: 20,
          }}
        >
          <HeaderBar
            title={"Admin"}
            onBack={() => navigation.navigate("HomeSCDueScreen", {  refresh  : true })}
            hasRoundedIcon={false}
          />
        </View>
        <View
          style={{
            flex: 1,
            paddingHorizontal: 20,
            paddingVertical: 20,
          }}
        >
          <div 
            className="row"
            style={{
              paddingRight: 12,
              paddingLeft: 12
            }}
          > 
            <div className="col-6 p-0"> 
                <Text
                  style={{
                    marginTop: 10,
                    fontWeight: "700",
                    fontSize: 20,
                  }}
                >
                  Lista utenti
                </Text>
                </div>
              <div className="col-6 p-0 d-flex justify-content-end">
                  <TouchableOpacity
                    style={{
                      paddingRight: 15
                    }}
                    onPress={() => OpenModalAdd()}
                  >
                    <View
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Icon name="plus" type="font-awesome" />
                      <Text
                        style={{
                          color: "black",
                          paddingLeft: 10,
                          textAlignVertical: "center",
                          paddingBottom: 2,
                          fontWeight: "500",
                          fontSize: 20
                        }}
                      >
                        Aggiungi utente
                      </Text>
                    </View>
                  </TouchableOpacity>
                </div>
          </div>
          <div className="table-responsive">
          <table className="table table-borderless mt-5 ">
            <thead style={{ backgroundColor: "black", color: "white", height: 50 }}>
              <tr className="mr-4">
                <th
                  style={{
                    borderTopLeftRadius: 50,
                    borderBottomLeftRadius: 50,
                    textAlign: "center", 
                    fontWeight: "400",
                    verticalAlign: "middle"
                  }}
                  scope="col"
                  className="align-self-center mr-4 px-4"
                >
                  Nome utente
                </th>
                <th
                  style={{
                    textAlign: "center", 
                    fontWeight: "400",
                    verticalAlign: "middle"
                  }}
                  scope="col"
                  className="align-self-center mr-4 px-4"
                >
                  Ruolo
                </th>
                <th
                  style={{
                    textAlign: "center", 
                    fontWeight: "400",
                    verticalAlign: "middle"
                  }}
                  scope="col"
                  className="align-self-center mr-4 px-4"
                >
                  Negozio
                </th>
                <th
                  style={{
                    textAlign: "center", 
                    fontWeight: "400",
                    verticalAlign: "middle"
                  }}
                  scope="col"
                  className="align-self-center mr-4 px-4"
                ></th>
                 <th
                  style={{
                    textAlign: "center", 
                    fontWeight: "400",
                    verticalAlign: "middle"
                  }}
                  scope="col"
                  className="align-self-center mr-4 px-4"
                ></th>
                <th
                  style={{
                    textAlign: "center", 
                    fontWeight: "400",
                    verticalAlign: "middle",
                    borderTopRightRadius: 50,
                    borderBottomRightRadius: 50,
                  }}
                  scope="col"
                  className="align-self-center mr-4 px-4"
                ></th>
              </tr>
            </thead>
            <tbody>
              {dataAdmin &&
                dataAdmin.map((data:any, key) => {
                  return (
                    <tr
                      key={data.idUser}
                      style={{ borderBottom: "1px solid 	#D3D3D3" }}
                    >
                      <td
                        style={{ textAlign: "center" }}
                        className="align-self-center mr-4 px-4"
                      >
                        {data.name}
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        className="align-self-center mr-4 px-4"
                      >
                         { data.desc_Role}
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        className="align-self-center mr-4 px-4"
                      >
                        {(data.idRole!=9 && data.idRole!=2)  ? data.desc_Bussines:null}
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        className="align-self-center mr-4 px-4"
                      >
                        {
                        (data?.failedAttemps >= 5)&&
                        <TouchableOpacity                     
                          style={{
                            flex: 1,
                            paddingHorizontal: 5,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onPress={() => openUnlockUser(data.idUser)}
                        >
                          <Image
                            style={{ width: 30, height: 30}}
                            source={require("../assets/icons/user_unlock.png")}
                          />
                        </TouchableOpacity>
                      }
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        className="align-self-center mr-4 px-4"
                      >
                        <TouchableOpacity                     
                          style={{
                            flex: 1,
                            paddingHorizontal: 5,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onPress={() => OpenModalEdit(data)}
                        >
                          <Image
                            style={{ width: 30, height: 30 }}
                            source={require("../assets/icons/icon-edit.png")}
                          />
                        </TouchableOpacity>
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        className="align-self-center mr-4 px-4"
                      >
                        <TouchableOpacity
                          style={{
                            flex: 1,
                            paddingHorizontal: 5,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onPress={() => confirmDelete(Number(data.idUser))}
                        >
                          <Image
                            style={{ width: 30, height: 30 }}
                            source={require("../assets/icons/icon-delete.png")}
                          />
                        </TouchableOpacity>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          </div> 
        </View>
      </SafeAreaView>
      {ModalVisible && (
        <EditUserScreen
          modalVisible={ModalVisible}
          setModalVisible={setModalVisible}
          data={dataSelected}
          edit={edit}
          setAddUser={setdataAdmin}
          allusers={dataAdmin}
        
        />
      )}
      {modalConfirm && (
        <PopupConfirmation
          title={"Elimina utente"}
          message={"Sei sicuro di volere eliminare l'utente?"}
          confirmAction={() => handleDelete(idStateAdmin)}
          stateModal={modalConfirm}
          setStateModal={setModalConfirm}
        />
      )}
       {modalMessage.active && (
              <PopupMessage
                title={"Informazione"}
                message={modalMessage.message}
                stateModal={modalMessage.active}
                setStateModal={setModalMessage}
              />
        )}
    </ScrollView>
  );
};
