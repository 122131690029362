export const toggleSidebar = () => {
  const overlay = document.querySelector(".overlay");
  const sidebarMenu = document.querySelector("#sidebar-menu");
  overlay?.classList.add("show");
  overlay?.addEventListener("click", function () {
    closedSidebar();
  });
  sidebarMenu?.classList.add("opened");
  sidebarMenu?.classList.remove("d-none");
};
export const closedSidebar = () => {
  const overlay = document.querySelector(".overlay");
  const sidebarMenu = document.querySelector("#sidebar-menu");
  overlay?.classList.remove("show");
  sidebarMenu?.classList.remove("opened");
  sidebarMenu?.classList.add("d-none");
};
