import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Text,
  View,
  TextInput,
  Image,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { HeaderModal } from "../components/HeaderModal";
import { styles } from "../theme/GlobalStyle";
import { stylesModal } from "../theme/ModalStyles";
import { useIsFocused } from "@react-navigation/native";
import axios from "axios";
import { SectionLoading } from "../components/SectionLoading";
import { Controller, useForm } from "react-hook-form";
import PopupMessage from "../components/PopupMessage";
import { getUserFromId, IUser, IClient } from "../api/ClientService";
import ConfigCapone from "../utils/ConfigCapone";
import { CheckLabel } from "../components/CheckLabel";
import { UserContext } from "../context/UserContext";
interface Props extends NativeStackScreenProps<any, any> { }
export const SendEmailScreenTecnic = ({
  modalVisible,
  setModalVisible,
  clientData,
  userList,
}: any) => {
  const [backColorSelect, setBackColorSelect] = useState("white");
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [hasPreventive, setHasPreventive] = useState(true);
  const [fReader, setFReader] = useState(null);
  const [fReaderArray, setFReaderArray] = useState([]);
  const [nameImgSelected, setNameImgSelected] = useState("");
  const [apiBussiness, setApiBussiness] = useState([]);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [typeFile, setTypeFile] = useState<any>();
  const [dataTecnico, setDataTecnico] = useState<any>();
  const [isEmptyBussinesTecnico, setIsEmptyBussinesTecnico] = useState(false);
  const { userInLogin } = useContext(UserContext);
  const [images, setImages] = useState<any>();
  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });
  const [dataTecnic, setDataTecnic] = useState("");
  const [imageArrayList, setImageArrayList] = useState<any>([]);

  console.log("clientData:", clientData)

  async function getDefaultValuesMessageTecnic() {
    setValue("subject", `Informazione orologio`);
    setValue(
      "message",
      `Salve,\n` +
      `Volevo chiedere informazioni riguardo la riparazione del cliente ${clientData.name} ` +
      `dell'orologio ${clientData.desc_Product} in data ${clientData.dateService.split("T")[0]
        .toString()
        .split("-")
        .reverse()
        .join("/")}.` +
      `\n\nGrazie,\n${userInLogin.userName}`
    );
  }

  const onChangeSelectTecnic = (e: Event) => {
    axios.get(
      `${ConfigCapone.urlapi}/api/User/${e}`
    ).then((response) => {
      console.log("e: ", response)
      let idUser = response.data.response.idUser;
      setDataTecnic(response.data.response.emailUser)
      getUserFromId(idUser).then((result: IUser) => {
        setValue(
          "message",
          `Gentile ${result.name},\n` +
          `Volevo chiedere informazioni riguardo la riparazione del cliente ${clientData.name} ` +
          `dell'orologio ${clientData.desc_Product} in data ${clientData.dateService.split("T")[0]
            .toString()
            .split("-")
            .reverse()
            .join("/")}.` +
          `\n\nGrazie,\n${userInLogin.userName}`
        );
      });
    }
    );
    setHasPreventive(false)
    setIsEmptyBussinesTecnico(false);
  }


  const {
    getValues,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      subject: "",
      message: "",
      business: "",
      tecnico: ""
    },
  });

  const uploadFiles = (e: any, type: string) => {
    setImages(Array.from(e));
    setNameImgSelected(e ? e[0].name : null);
    setTypeFile(type);

    if (type === "image" || type === "document") {
      let selectedFiles = Array.from(e);
      let filesArrayCopy = [...imageArrayList];

      selectedFiles.forEach((item: any) => {
        filesArrayCopy.push({
          name: item.name,
          type: type,
          data: item,
        });
      });

      setImageArrayList(filesArrayCopy);
    }
  };



  const selectTencnics = async () => {
    await axios
      .get(`${ConfigCapone.urlapi}/api/User?parameter=1`)
      .then((response) => {
        console.log("selectTencnics: ", response)
        const apiTecnico = response.data.response?.filter((t: any) => parseInt(t.idRole) == 3 || parseInt(t.idRole) == 7 || parseInt(t.idRole) == 8);
        setDataTecnico(apiTecnico);
      });
  }

  const isFocused = useIsFocused();

  useEffect(() => {
    //getPreventive();
    getDefaultValuesMessageTecnic();
    selectTencnics();
  }, [isFocused]);

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const sendEmail = async () => {
    let isError = false;
    let message = "";

    setLoading(true);
    setMessageSent(false);

    try {
      let filesData = [];
      if (imageArrayList && imageArrayList.length > 0) {
        for (let i = 0; i < imageArrayList.length; i++) {
          let data = imageArrayList[i].data;
          let _fReader: any = await readFileAsDataURL(data);

          filesData.push({
            filename: data.name ? data.name : null,
            content: _fReader.split("base64,")[1],
            encoding: "base64",
          });
        }
      }

      let dataSend = {
        type: "business",
        email: dataTecnic ? dataTecnic : "assistenza@sergiocapone.com",
        subject: getValues("subject").replace(/\n/g, '<br>\n'),
        message: getValues("message").replace(/\n/g, '<br>\n'),
        attachments: filesData,
      };

      await axios.post(`${ConfigCapone.apicapone}/api/forma/multiple/files`, dataSend);

      setLoading(false);
      setMessageSent(true);
    } catch (err) {
      isError = true;
      message = "E-mail non inviata. I file non devono essere più grandi di 50 MB.";
      console.error(message);
      setLoading(false);
      setModalMessage({
        active: true,
        message: message,
      });
    }
  };


  const removeFile = (index: number) => {
    const removedElement = imageArrayList.splice(index, 1)[0];
    setImageArrayList([...imageArrayList]);
  };

  return (
    <View style={stylesModal.centeredView}>
      <Modal animationType="slide" propagateSwipe={true} transparent={true} visible={modalVisible}>
        <ScrollView style={{ width: "100%", height: "100%" }}>
          <View
            style={[
              stylesModal.centeredView,
              { backgroundColor: "rgba(0,0,0,.32)", paddingTop: 130, paddingBottom: 130 },
            ]}
          >
            <View style={[stylesModal.modalViewTecnic]}>
              <HeaderModal
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                title={`Invia e-mail ai tecnici`}
                activatedPadLeft={true}
              />

              {loading && (
                <div style={{ alignSelf: "center" }}>
                  <SectionLoading />
                </div>
              )}
              <div className="row w-100">
                <div className="col-12">
                  <Controller
                    control={control}
                    name="tecnico"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        className="mt-3"
                        style={{
                          width: "100%",
                          backgroundColor: backColorSelect,
                          height: 45,
                          cursor: "pointer",
                          margin: 4,
                          borderWidth: 2,
                          borderRadius: 50,
                          borderColor: "rgb(235,235,235)",
                          paddingLeft: 10,
                          fontSize: 16,
                          marginLeft: -3,
                        }}
                        //onMouseEnter={() => setBackColorSelect("#EAEAEA")}
                        // onMouseLeave={() => setBackColorSelect("white")}
                        onChange={(e) => {
                          onChangeSelectTecnic(e.target.value)
                          //setIsEmptyBussinesTecnico(false)
                        }}
                        aria-label=".form-select "
                      >
                        <option selected value={""} style={{ display: "none" }}>
                          Selezionare il tecnico
                        </option>
                        {dataTecnico?.map((t: any) => (
                          <option key={Number(t.idUser)} value={t.idUser}>
                            {t.name}
                          </option>
                        ))}

                      </select>
                    )}
                    rules={{ required: false }}
                  />
                  <br />
                  {/* {errors.tecnico?.type === "required" && (
                    <Text style={styles2.labelError}>
                      Questo campo è obbligatorio
                    </Text>
                  )} */}
                </div>
              </div>

              {(isEmptyBussinesTecnico) && (
                <div className="col-12 mb-2">
                  <Text style={{ fontSize: 16, marginLeft: 20, fontFamily: "Arsapia Regular font", color: "red" }}>
                    Errore, selezionare un business o un tecnico
                  </Text>
                </div>
              )}

              <div className="row">
                <div className="col-12">
                  <Controller
                    control={control}
                    name="subject"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        style={[styles.input, styles.inputSubjectModal, { paddingRight: 20, marginBottom: 10 }]}
                        value={value}
                        placeholder="Oggetto"
                        onChangeText={(e: any) => onChange(e)}
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <br />
                  {errors.subject?.type === "required" && (
                    <div className="mb-2">
                      <Text style={[styles2.labelError]}>
                        Questo campo è obbligatorio
                      </Text>
                    </div>
                  )}
                  <Controller
                    control={control}
                    name="message"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        style={[
                          styles.inputContentModal,
                          {
                            fontSize: 16,
                          },
                        ]}
                        multiline={true}
                        numberOfLines={11}
                        editable={true}
                        value={value}
                        onBlur={onBlur}
                        onChangeText={(value) => onChange(value)}
                        placeholder="Scrivi messaggio..."
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <br />
                  {errors.message?.type === "required" && (
                    <Text style={styles2.labelError}>
                      Questo campo è obbligatorio
                    </Text>
                  )}
                </div>
              </div>



              <div className="row mt-1 mb-2">
                <div className="col-12 text-center">
                  <div>
                    {imageArrayList?.map((file, index) => (
                      <div className="mb-2" key={index}>
                        {file.type.includes('image')
                          ?
                          <>
                            <img
                              key={index}
                              src={URL.createObjectURL(file.data)}
                              style={{ height: 120, maxWidth: 200, marginRight: 10 }}
                              alt={`Image ${index + 1}`}
                              id="img-container-send"
                            />
                            <br />
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); 
                                removeFile(index);
                              }}
                              style={{ cursor: "pointer", border: 'none', background: 'white' }}
                              className="text-danger"
                            >
                              rimuovere {file.name.length > 30 ? file.name.slice(0, 30) + '...' : file.name}
                            </button>
                          </>
                          :
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); 
                              removeFile(index);
                            }}
                            style={{ cursor: "pointer", border: 'none', background: 'white' }}
                            className="text-danger"
                          >
                            rimuovere {file.name.length > 30 ? file.name.slice(0, 30) + '...' : file.name}
                          </button>
                        }
                      </div>
                    ))}
                  </div>
                  <br />
                </div>
              </div>

              {messageSent && (
                <div className="row w-100 mb-3">
                  <div className="col-12">
                    <div className="alert mb-0 mt-2 alert-success" role="alert">
                      <p className="mb-0">Messaggio inviato con successo</p>
                    </div>
                  </div>
                </div>
              )}

              <div className="row w-50 mb-4">
                <div
                  className="col-12 d-flex justify-content-between"
                  style={{ marginLeft: 28 }}
                >
                  <TouchableOpacity>
                    <label
                      htmlFor="image-files-send"
                      style={{ cursor: "pointer" }}
                    >
                      <Image
                        style={[
                          styles.iconModal,
                          {
                            tintColor: "#b3b3b3",
                            marginTop: 10,
                          },
                        ]}
                        source={require("../assets/icons/camera2.png")}
                      />
                    </label>
                    <input
                      type="file"
                      name="image-files-send"
                      id="image-files-send"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ display: "none" }}
                      multiple
                      onChange={(e) => uploadFiles(e.target.files, "image")}
                    />
                  </TouchableOpacity>

                  <TouchableOpacity>
                    <label
                      htmlFor="document-files-send"
                      style={{ cursor: "pointer" }}
                    >
                      <Image
                        style={[
                          styles.iconModal,
                          {
                            marginHorizontal: 20,
                            tintColor: "#b3b3b3",
                            marginTop: 10,
                          },
                        ]}
                        source={require("../assets/icons/link.png")}
                      />
                    </label>
                    <input
                      type="file"
                      name="document-files-send"
                      id="document-files-send"
                      accept="application/pdf"
                      style={{ display: "none" }}
                      multiple
                      onChange={(e) => uploadFiles(e.target.files, "document")}
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={handleSubmit(sendEmail)}
                    disabled={false}
                    style={{
                      width: 100,
                      alignSelf: "center",
                    }}
                  >
                    <View
                      style={[
                        styles.button,
                        { paddingHorizontal: 4, width: 140, backgroundColor: "black" },
                      ]}
                    >
                      <Text style={[styles.buttonText, { paddingHorizontal: 4 }]}>
                        {"Invia e-mail"}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </div>
              </div>
              {modalMessage.active && (
                <PopupMessage
                  title={"Informazione"}
                  message={modalMessage.message}
                  stateModal={modalMessage.active}
                  setStateModal={setModalMessage}
                />
              )}
            </View>
          </View>
        </ScrollView>
      </Modal>
    </View>
  );
};
const statesRepair = ["Seleziona il negozio", "Negozio 1", "Negozio 2"];
const styles2 = StyleSheet.create({
  labelError: {
    fontSize: 16,
    marginLeft: 10,
    fontFamily: "Arsapia Regular font",
    color: "red",
  },
});