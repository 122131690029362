import { RouteProp, useRoute } from "@react-navigation/native";
import { RootStackParams } from "../../../navigator/NavigatorConfig";
import { useContext, useEffect, useState } from "react";
import { Notify } from "../../../interfaces/Notify";
import { putRead } from "../../../services/Notify";
import { UserContext } from "../../../context/UserContext";
import { bussinesNewByIdUserScreen, listByidNewBussines, putNewUserIsChecked, putnewBussinesIsChecked, userNewByIdUser } from "../Services/NewServices";

export const useNewsCrud = () => {
    const { userInLogin } = useContext(UserContext);
    const [ListNewBussinesById,setListNewBussinesById] =useState({
        items:[],normalizedResults:[],length
    })
    const [listIdNewBussinesView,setListIdNewBussineView] = useState()
    const { params } = useRoute<RouteProp<RootStackParams, "NewsScreen">>();
    const [isLoading, setIsLoading] = useState(false);
    const [myNotify, setMyNotify] = useState<Notify[]>(params.items);
    const [view, setView] = useState<number | undefined>(undefined);
    const handleView = async (value: number) => {
      try {
        const notify = myNotify.find((item) => item.id === value);
        if (!notify) {
          throw new Error();
        }
        if(notify.read === 0){
          await putRead(value,notify.coduser)
        }      
        setMyNotify([
          ...myNotify.filter((item) => item.id !== value),
          {
            ...notify,
            read: 1,
          },
        ]);
        setView(view === value ? undefined : value);
      } catch (error) {
        console.log(error)
      }
  
    };
    const confirmviewNew = async (data) => {
      let conditionalPut = data.idNewUser
      debugger
      if(conditionalPut){
          const result = await putNewUserIsChecked(data.idNewUser)
          if(result){
            await GetListNewBussinesById()
          }
      }else{
         const result = await putnewBussinesIsChecked(data)
          if(result){
            await GetListNewBussinesById()
          }
      }
       
    }
    const GetListNewBussinesById = async () => {
        const idUser = Number(userInLogin.idUser)
        
        const result = await bussinesNewByIdUserScreen(idUser)
        const resultUser = await GetListNewUserById()
        let resultConcat = result;
        if(resultUser){
            resultConcat = result.concat(resultUser)
        }
        resultConcat.sort(function(a, b) {
          return  new Date(b.dataInsert).getTime() - new Date(a.dataInsert).getTime();
        });
        //debugger
        if(result){
            const items = resultConcat.slice(0, 3);
            const normalizedResults = resultConcat
            const dataRender = {items,normalizedResults,length:normalizedResults.length}
            setListNewBussinesById(dataRender)
        }
    }
    //ListNewUser
    const GetListNewUserById = async () => {
      const idUser = Number(userInLogin.idUser)
      
      const result = await userNewByIdUser(idUser)
      if(result){
          return result
          // const items = result.slice(0, 3);
          // const normalizedResults = result
          // const normalizedResultLength = result.filter(x => x.isChecked == 0)
          // const dataRender = {items,normalizedResults,length:normalizedResultLength.length}
          // setListNewUserView(dataRender)
      }
  }
    const GetListByIdNewBussines = async (dataSend) => {
      const result = await listByidNewBussines(dataSend)
      if(result){
        setListIdNewBussineView(result)
      }
  }
    console.log("aqui paramns",params)
    useEffect(() => {
        GetListNewBussinesById()
    }, [])
    
    return {
        ListNewBussinesById,
        handleView,
        view,
        myNotify: "filtered().data",
        isLoading,
        confirmviewNew,
        GetListByIdNewBussines,
        listIdNewBussinesView
}
}