import { useContext, useEffect, useState } from "react";
import { AllListCrmAtelier } from "../interfaces/ICrmAtelier";
import { DeleteClientAtelier, listAllClientAtelier, listAllCrmClientAtelierWhitSearch, listAllGender, listAllHobby, referenciaByAtelier } from "../services/CrmAtelierService";
import { SearchClientAtelier, listAllBrand } from "../../../Magazzino/Atelier/services/magazzinoAtelierService";
import { BrandResponse } from "../../../Magazzino/Atelier/interfaces/IMagazzinoAtelier";
import { useAuth } from "../../../../hooks/useAuth";

const dataStar = [
    {
        name: "star3",
        id: 3
    },
    {
        name: "star2",
        id: 2
    },
    {
        name: "star1",
        id: 1
    }
]

const dataSpentValue = [
    {
        name: "spent_value",
        id: "1",
        type: "text",
        text: "300 >"
    },
    {
        name: "spent_value",
        id: "2",
        type: "text",
        text: "100 a 300"
    },
    {
        name: "spent_value",
        id: "3",
        type: "text",
        text: "50 a 100"
    }
]

const dataAge = [
    {
        name: "age",
        id: "1",
        type: "text",
        text: "50 <"
    },
    {
        name: "age",
        id: "2",
        type: "text",
        text: "30 - 50"
    },
    {
        name: "age",
        id: "3",
        type: "text",
        text: "20 - 30"
    }
]

const dataEntryDate = [
    {
        name: "entry_date",
        id: 1,
        type: "text",
        text: "Da più di 1 mese"
    },
    {
        name: "entry_date",
        id: 2,
        type: "text",
        text: "Ultimo mese"
    },
    {
        name: "entry_date",
        id: 3,
        type: "text",
        text: "Ultimi 7 giorni"
    },
    {
        name: "entry_date",
        id: 4,
        type: "text",
        text: "Ultimi 3 giorni"
    },
    {
        name: "entry_date",
        id: 5,
        type: "text",
        text: "Ultime 24 ore"
    }
]

const dataUpdateDate = [
    {
        name: "update_date",
        id: 1,
        type: "text",
        text: "Da più di 1 mese"
    },
    {
        name: "update_date",
        id: 2,
        type: "text",
        text: "Ultimo mese"
    },
    {
        name: "update_date",
        id: 3,
        type: "text",
        text: "Ultimi 7 giorni"
    },
    {
        name: "update_date",
        id: 4,
        type: "text",
        text: "Ultimi 3 giorni"
    },
    {
        name: "update_date",
        id: 5,
        type: "text",
        text: "Ultime 24 ore"
    }
]

const dataOthers = [
    {
        name: "others",
        id: "clienteSuPiùListe-4",
        type: "text",
        text: "Cliente su più liste"
    },
    {
        name: "others",
        id: "deprecati-1",
        type: "text",
        text: "Deprecati"
    },
    {
        name: "others",
        id: "blackList-2",
        type: "text",
        text: "Black list"
    },
    {
        name: "others",
        id: "enviaCatalogo-3",
        type: "text",
        text: "Invia Catalogo"
    },
    {
        name: "others",
        id: "Nodeprecati-5",
        type: "text",
        text: "No deprecati"
    },
    {
        name: "others",
        id: "NoMailingList-6",
        type: "text",
        text: "No mailing list"
    }

]

export const useCrmAtelierList = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(false);
    const [currentPage, setcurrentPage] = useState(1)
    const [resetCurrentPage, setResetCurrentPage] = useState(false);
    const [reload, setReload] = useState(false)
    const [tableCsv, setTableCsv] = useState<any>([]);
    const [activeSuggestion, setActiveSuggestion] = useState(true);

    const [dataExcelClient, setDataExcelClient] = useState<any>([]);

    const [filterGender, setFilterGender] = useState<number[]>([]);
    const [filterStart, setfilterStart] = useState<number[]>([]);
    const [filterBrand, setfilterBrand] = useState<number[]>([]);
    const [filterSpendValue, setFilterSpendValue] = useState<number[]>([]);
    const [filterAge, setFilterAge] = useState<number[]>([]);
    const [filterHobby, setFilterHobby] = useState<number[]>([]);
    const [filterEntryDate, setFilterEntryDate] = useState<number[]>([]);
    const [filterUpdateDate, setFilterUpdateDate] = useState<number[]>([]);
    const [filterOthers, setFilterOthers] = useState<number[]>([]);

    const [searchInputClient, setSearchInputClient] = useState<string>();
    const [autosuggestInputClient, setAutosuggestInputClient] = useState<any>([]);

    const [searchInputReferenza, setSearchInputReferenza] = useState<string>();
    const [activeSuggestionReferenza, setActiveSuggestionReferenza] = useState(true)
    const [autosuggestInputReferenza, setAutosuggestInputReferenza] = useState<any[]>([]);

    const [matricola, setMatricola] = useState<string>();
    const [profession, setProfession] = useState<string>();
    const [note, setNote] = useState<string>();
    const [modal, setModal] = useState(false);
    const [idClientDelete, setIdClientDelete] = useState<number>();


    const [data, setData] = useState<AllListCrmAtelier>({
        clientList: [],
        startList: [],
        brandList: [],
        genderList: [],
        spentValueList: [],
        ageList: [],
        hobbyList: [],
        entryDateList: [],
        updateDateList: [],
        othersList: [],
    });

    const [paginationList, setPaginationList] = useState({
        currentPage: 1,
        total: 0,
        totalPages: 0,
    });

    const limit: number = 50;

    const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({
        title: "Informazione",
        message: "",
        active: false,
    });

    const showSuccessPopup = (mess: string, tiTle: string) => {
        setmodaleMessaggeWithUrl({
            active: true,
            message: mess,
            title: tiTle,
        })
    };

    const confirmAction = (Landing: any, data: any) => {
        setmodaleMessaggeWithUrl({
            title: "Informazione",
            message: "",
            active: false,
        });
    };

    const handleData = async () => {
        try {
            const response: any = await Promise.all([
                listAllClientAtelier(currentPage, limit),
                listAllGender(),
                listAllBrand(),
                listAllHobby()
            ]);

            const clients: any = response[0]?.response;
            const gender = response[1];
            const brandList: BrandResponse[] = response[2];
            let brandListFilter = brandList.filter((x) => x.desc_Brand != "Altro");
            const hobbyList = response[3];

            setPaginationList({
                currentPage: response[0]?.currentPage,
                total: response[0]?.total,
                totalPages: response[0]?.totalPages
            })

            dataExcel(false, false)

            setData({
                clientList: clients,
                genderList: gender,
                startList: dataStar,
                brandList: brandListFilter,
                spentValueList: dataSpentValue,
                ageList: dataAge,
                hobbyList: hobbyList,
                entryDateList: dataEntryDate,
                updateDateList: dataUpdateDate,
                othersList: dataOthers
            });

            setFirstLoad(true);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    };

    const updatePage = (page, update = true) => {
        setResetCurrentPage(true)
        setcurrentPage(page) 
    }

    const filtered = async () => {
        try {

            setReload(true);

            const filterActive =
                filterGender.length > 0 ||
                filterStart.length > 0 ||
                filterBrand.length > 0 ||
                filterSpendValue.length > 0 ||
                filterAge.length > 0 ||
                filterHobby.length > 0 ||
                filterEntryDate.length > 0 ||
                filterUpdateDate.length > 0 ||
                filterOthers.length > 0 ||
                searchInputReferenza ||
                matricola ||
                profession ||
                searchInputClient ||
                note;

            if (filterActive) {
                if (resetCurrentPage) {
                    setResetCurrentPage(false)
                } else {
                    setcurrentPage(1)
                }

                const results: any = await listAllCrmClientAtelierWhitSearch({
                    genderFilter: filterGender,
                    startFilter: filterStart,
                    brandFilter: filterBrand,
                    valoreSpesaFilter: filterSpendValue,
                    etaFilter: filterAge,
                    hobbyFilter: filterHobby,
                    dataInserimentoFilter: filterEntryDate,
                    dataAggiornamentoFilter: filterUpdateDate,
                    depricatedBackListEnviaCatalogoFilter: filterOthers,
                    referenzaFilter: searchInputReferenza ? searchInputReferenza : '',
                    MatricolaFilter: matricola ? matricola : '',
                    professionFilter: profession ? profession : '',
                    noteFilter: note ? note : '',
                    clientFilter: searchInputClient ? searchInputClient : '',
                    limit: limit,
                    page: (searchInputClient || searchInputReferenza) ? 1 : currentPage
                });

                dataExcel({
                    genderFilter: filterGender,
                    startFilter: filterStart,
                    brandFilter: filterBrand,
                    valoreSpesaFilter: filterSpendValue,
                    etaFilter: filterAge,
                    hobbyFilter: filterHobby,
                    dataInserimentoFilter: filterEntryDate,
                    dataAggiornamentoFilter: filterUpdateDate,
                    depricatedBackListEnviaCatalogoFilter: filterOthers,
                    referenzaFilter: searchInputReferenza ? searchInputReferenza : '',
                    MatricolaFilter: matricola ? matricola : '',
                    professionFilter: profession ? profession : '',
                    noteFilter: note ? note : '',
                    clientFilter: searchInputClient ? searchInputClient : '',
                    limit: limit,
                    page: currentPage
                }, true)
                if (results?.response?.length > 0) {

                    setData({
                        ...data,
                        clientList: [],
                    });

                    setData({
                        ...data,
                        clientList: results?.response,
                    })

                    setTableCsv(results?.response)
                    setPaginationList({
                        currentPage: results?.currentPage,
                        total: results?.total,
                        totalPages: results?.totalPages
                    })
                } else {
                    showSuccessPopup("Non ci sono record.", "Informazione")
                }
            } else {
                if (firstLoad) {
                    const results: any = await listAllClientAtelier(currentPage, limit);
                    dataExcel(false, false)
                    setPaginationList({
                        currentPage: results?.currentPage,
                        total: results?.total,
                        totalPages: results?.totalPages
                    })

                    setData({
                        ...data,
                        clientList: [],
                    });


                    setData({
                        ...data,
                        clientList: results?.response,
                    })

                    setTableCsv(results?.response)
                    setResetCurrentPage(false)
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setReload(false);
        }
    };

    const dataExcel = async (selectedFilters: any, isfilter: boolean) => {
        if (isfilter) {
            selectedFilters.limit = 0
            let result: any = await listAllCrmClientAtelierWhitSearch(selectedFilters)
            setDataExcelClient(result?.response)
            selectedFilters.limit = 50
        } else {
            let results: any = await listAllClientAtelier(1, 0)
            setDataExcelClient(results?.response)
        }
    }

    const handleFilterGender = (value: string) => {
        if (!value) return;
        let id = value.split('-')[1];
        const gender = Number(id);
        const isExits = filterGender.find((elem) => elem === gender);
        if (!isExits) {
            setFilterGender([...filterGender, gender]);
        } else {
            setFilterGender([...filterGender.filter((elem) => elem !== gender)]);
        }
    };
    const handleFilterStar = (value: string) => {
        if (!value) return;
        let id = value.split('-')[1];
        const start = Number(id);
        const isExits = filterStart.find((elem) => elem === start);
        if (!isExits) {
            setfilterStart([...filterStart, start]);
        } else {
            setfilterStart([...filterStart.filter((elem) => elem !== start)]);
        }
    };
    const handleFilterBrand = (value: string) => {
        if (!value) return;
        let id = value.split('-')[1];
        const brand = Number(id);
        const isExits = filterBrand.find((elem) => elem === brand);
        if (!isExits) {
            setfilterBrand([...filterBrand, brand]);
        } else {
            setfilterBrand([...filterBrand.filter((elem) => elem !== brand)]);
        }
    };
    const handleFilterSpendValue = (value: string) => {
        if (!value) return;
        let id = value.split('-')[1];
        const spend = Number(id);
        const isExits = filterSpendValue.find((elem) => elem === spend);
        if (!isExits) {
            setFilterSpendValue([...filterSpendValue, spend]);
        } else {
            setFilterSpendValue([...filterSpendValue.filter((elem) => elem !== spend)]);
        }
    };
    const handleFilterAge = (value: string) => {
        if (!value) return;
        let id = value.split('-')[1];
        const age = Number(id);
        const isExits = filterAge.find((elem) => elem === age);
        if (!isExits) {
            setFilterAge([...filterAge, age]);
        } else {
            setFilterAge([...filterAge.filter((elem) => elem !== age)]);
        }
    };
    const handleFilterHobby = (value: string) => {
        if (!value) return;
        let id = value.split('-')[1];
        const hobby = Number(id);
        const isExits = filterHobby.find((elem) => elem === hobby);
        if (!isExits) {
            setFilterHobby([...filterHobby, hobby]);
        } else {
            setFilterHobby([...filterHobby.filter((elem) => elem !== hobby)]);
        }
    };
    const handleFilterEntryDate = (value: string) => {
        if (!value) return;
        let id = value.split('-')[1];
        const entryDate = Number(id);
        const isExits = filterEntryDate.find((elem) => elem === entryDate);
        if (!isExits) {
            setFilterEntryDate([...filterEntryDate, entryDate]);
        } else {
            setFilterEntryDate([...filterEntryDate.filter((elem) => elem !== entryDate)]);
        }
    };
    const handleFilterUpdateDate = (value: string) => {
        if (!value) return;
        let id = value.split('-')[1];
        const updateDate = Number(id);
        const isExits = filterUpdateDate.find((elem) => elem === updateDate);
        if (!isExits) {
            setFilterUpdateDate([...filterUpdateDate, updateDate]);
        } else {
            setFilterUpdateDate([...filterUpdateDate.filter((elem) => elem !== updateDate)]);
        }
    };
    const handleFilterOthers = (value: string) => {
        if (!value) return;
        let id = value.split('-')[1];
        const updateDate = Number(id);
        const isExits = filterOthers.find((elem) => elem === updateDate);
        if (!isExits) {
            setFilterOthers([...filterOthers, updateDate]);
        } else {
            setFilterOthers([...filterOthers.filter((elem) => elem !== updateDate)]);
        }
    };

    const handleSearch = async () => {
        await filtered()
        setActiveSuggestionReferenza(false)
    }

    const onChangeTextReferenza = async (name: string) => {
        if (name) {
            try {
                setSearchInputReferenza(name)
                const response = await Promise.all([
                    referenciaByAtelier(name)
                ]);
                setActiveSuggestionReferenza(true)
                let referenza = response[0]
                if (referenza?.length > 0) {
                    let resultData = referenza.reduce((elements, obj: any, index) => {
                        let existingData = elements.find(
                            (element) => element.referenza === obj.referenza
                        );
                        if (!existingData) {
                            elements.push(obj);
                        }
                        return elements;
                    }, []);
                    setAutosuggestInputReferenza(resultData);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        } else {
            setSearchInputReferenza("")
        }
    };

    const onChangeTextClient = async (name: string) => {
        if (name) {
            try {
                setSearchInputClient(name)
                const response = await Promise.all([
                    SearchClientAtelier(name)
                ]);
                setActiveSuggestion(true)
                let prodotto = response[0]
                setAutosuggestInputClient(prodotto);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        } else {
            setSearchInputClient("")
        }
    }

    const handleSearchDescrizione = async () => {
        await filtered()
    }

    const handleSearchFilter = async () => {
        await filtered()
        setActiveSuggestion(false)
    }

    const confirmToDelete = async (idClient: number) => {
        setIdClientDelete(idClient)
        setModal(true);
    }

    const confirmToDeleteTrue = async (idClient: number) => {
        try {
            const response = await Promise.all([
                DeleteClientAtelier(idClient),
            ]);
            setFirstLoad(true);
            setModal(false);
            filtered();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false); 
        }
    }

    const emailModal = (email: string)=>{
        var mail = document.createElement("a");
        mail.href = `mailto:${email}`;
        mail.click();
    }

    useEffect(() => {
        handleData();
    }, []);

    useEffect(() => {
        resetCurrentPage && filtered()
    }, [currentPage])

    useEffect(() => {
        filtered()
    }, [filterGender, filterStart, filterBrand, filterSpendValue, filterAge, filterHobby, filterEntryDate, filterUpdateDate, filterOthers]);

    return {
        listClient: data.clientList,
        listGender: data.genderList,
        listStar: data.startList,
        listBrand: data.brandList,
        listSpendValue: data.spentValueList,
        listAge: data.ageList,
        listAllHobby: data.hobbyList,
        listEntryDate: data.entryDateList,
        listUpdateDate: data.updateDateList,
        listOthers: data.othersList,
        searchInputReferenza,
        onChangeTextReferenza, 
        matricola,
        setMatricola,
        profession,
        setProfession,
        note,
        setNote,
        handleSearch,
        handleFilterGender,
        handleFilterStar,
        handleFilterBrand,
        handleFilterSpendValue,
        handleFilterAge,
        handleFilterHobby,
        handleFilterEntryDate,
        handleFilterUpdateDate,
        handleSearchDescrizione,
        handleFilterOthers,
        paginationList,
        setPaginationList,
        currentPage,
        setcurrentPage,
        setResetCurrentPage,
        limit,
        loading: loading || reload,
        modaleMessaggeWithUrl,
        setmodaleMessaggeWithUrl,
        confirmAction,
        dataExcelClient,
        setLoading,
        onChangeTextClient,
        searchInputClient,
        activeSuggestion,
        autosuggestInputClient,
        setSearchInputClient,
        setAutosuggestInputClient,
        handleSearchFilter,
        activeSuggestionReferenza,
        autosuggestInputReferenza,
        setAutosuggestInputReferenza,
        setSearchInputReferenza,
        confirmToDelete,
        modal,
        setModal,
        idClientDelete,
        confirmToDeleteTrue,
        emailModal,
        updatePage,
    };

};
