import React, { useEffect, useState, useContext } from "react";
import { Navbar } from "../../../../components/Navbar";
import NavProfile from "../../../../components/NavProfile/NavProfile";
import { IoIosArrowDown } from "react-icons/io";

import "./css/cercaProdotto.css";
import { IoFilterSharp } from "react-icons/io5";
import { IoSearchOutline } from "react-icons/io5";
import { TableHeaderAtelier } from "./table/TableHeaderAtelier";
import { TableBodyAtelier } from "./table/TableBodyAtelier";
import { useMagazzinoAtelierTable } from "./hooks/useMagazinoAtelierTable";
import { FilterMagazzinoReferenza } from "../../../../components/MagazzinoRefactor/FilterMagazzinoReferenza";
import { SearchMagazzinoAtelier } from "./SearchMagazzinoAtelier";
import { FilterOptions } from "../../../../components/MagazzinoRefactor/FilterOptions";
import { FilterMagazzinoDescrizione } from "../../../../components/MagazzinoRefactor/FilterMagazzinoDescrizione";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useIsFocused } from "@react-navigation/native";
import { TableMagazzinoAtelier } from "./TableMagazzinoAtelier";
import { StackScreenProps } from "@react-navigation/stack";
import { FilterMagazzinoBrand } from "../../../../components/MagazzinoRefactor/FilterMagazzinoBrand";
import PopUpMessageWithRoute from "../../../../components/PopUpMessageWithRoute";
import { FilterMagazzinoAtelierDescrizione } from "./FilterMagazzinoAtelierDescription";
import { set } from "date-fns";
import { Suggestions } from "../../../../components/MagazzinoRefactor/Suggestions";
import PopPupMessageInfoProdottoCard from "../../../../components/PopPupMessageInfoProdottoCard";
import PopupConfirmation from "../../../../components/PopupConfirmation";
import { useAuth } from "../../../../hooks/useAuth";
import SchemaProdottoAtelierCard from "./modals/SchemaProdottoAtelierCard";

import { FilterAtelierOptions } from "./FilterAtelierOptions";
import { FilterSelectMagazzinoAtelier } from "./FilterSelectMagazzinoAtelier";
import useExportExcelMagazzinoAtelier from "./useExportExcelMagazzinoAtelier";
import ReactExport from "react-data-export/";
import { todayFormatDate } from "../../../../utils/helpers";
import { useWidth } from "../../../../core/hook/useWidth";
import { useProfile } from "../../../../components/NavProfile/hook/useProfile";
import ShowContentSidebar from "../../../../components/NavProfile/ShowContentSidebar";
import { SuggestionsMagazzinoAtelierReferenzia } from "./SuggestionsMagazzinoAtelierReferenzia";
import Loading from "../../../../components/Loading";
import ModalOptionMatricola from "../../../../components/magazzino/ModalOptionMatricola";
import { enOptonNavigate } from "../../../../schemas/Schemas";
import { FilterMagazzinoQuantita } from "../../../../components/MagazzinoRefactor/FilterMagazzinoQuantita";
import FilterMagazzinoQuantitaAtelier from "../components/FilterMagazzinoQuantitaAtelier";
import Pagination from "../../../../components/Pagination/pagination";

interface Props extends NativeStackScreenProps<any, any> { }

const MagazzinoCercaAtelierScreen = ({ navigation, route }: Props) => {
  const {
    handleSearchFilter,
    onChangeTextProdotto,
    searchInputProdotto,
    dataExcelMagazzino,
    setLoading,
    griglia,
    setgriglia,
    magazzinoList,
    confirmToDelete,
    goToSchedaProdotto,
    goToSchedaQuadrante,
    goToMatricola,
    crmSend,
    paginationList,
    currentPage,
    setcurrentPage,
    limit,
    setResetCurrentPage,
    brandList,
    handleFilterBrand,
    modaleMessaggeWithUrl,
    setmodaleMessaggeWithUrl,
    confirmAction,
    searchInputPrezzo,
    setSearchInputPrezzo,
    searchInputDescription,
    setSearchInputDescription,
    year,
    setYear,
    matricole,
    setMatricole,
    diameter,
    setDiameter,
    collection,
    setCollection,
    house,
    setHouse,
    glass,
    setGlass,
    estate,
    setEstate,
    movement,
    setMovement,
    caliber,
    setCaliber,
    powerReserve,
    setPowerReserve,
    bracelet,
    setBracelet,
    color,
    setColor,
    misura,
    setMisura,
    fileName,
    setFileName,
    box,
    setBox,
    guarantees,
    setGuarantees,
    handleSearchDescrizione,
    modalQuadrante,
    setModalQuadrante,
    modal,
    setModal,
    confirmToDeleteTrue,
    IdStateProduct,
    IdStateMagazzino,
    searchInputReferenza,
    onChangeTextReferenza,
    handleSearch,
    activeSuggestionReferenza,
    autosuggestInputReferenza,
    setAutosuggestInputReferenza,
    setSearchInputReferenza,

    modalVisibleCard,
    setModalVisibleCard,
    dataMagazzino,
    listMagazzino,
    listMatricola,

    activeSuggestion,
    autosuggestInputProdotto,
    setSearchInputProdotto,
    setAutosuggestInputProdotto,
    loading,
    modalVisibleMatricolaCard, 
    setModalVisibleMatricolaCard,
    handleData,
    handleFilterQuantita,
    filtered,
    heightResize,
    divRef
  } = useMagazzinoAtelierTable();

  const { user } = useAuth();
  const isFocused = useIsFocused();
  let arrayData: any = route.params || [];
  let dataCrm = arrayData ? arrayData.option : "";

  const goToCrm = async (dataMagazzino?: any, option?: any, clear = enOptonNavigate.clearForm) => {
    await navigation.navigate("AggiungiCrmAtelier", {
      dataMagazzino,
      option: { option: option },
      clear
    });
  };

  const { donwnloadExcel, DataSet, handleDataExport, ExcelFile, ExcelSheet, nameFileExport } = useExportExcelMagazzinoAtelier(dataExcelMagazzino, setLoading)
  const { width } = useWidth()
  const { sidebarVisible, toggleSidebar } = useProfile()

  return (
    <>
      {loading && <Loading activeSpinner={loading} />}
      <ShowContentSidebar
        sidebarVisible={sidebarVisible}
        toggleSidebar={toggleSidebar} 
      />
      <div className="container-layout">
        <div className="row row-layout">
          <div className={` ${sidebarVisible ? 'col-12 d-block' : 'col-lg-3 col-md-none d-none d-md-non d-lg-block'} sidebar-container-layout sidebar-transition`}>
            <Navbar activeNav={isFocused} componentName={route.name} componentDad="MagazzinoAtelier" />
          </div>
          <div
            className="col-lg-9 col-md-12 col-sm-12 content-container-layout"
            style={{ paddingLeft: "1.5rem", overflowY: 'auto', height: '100%', scrollbarWidth: 'thin' }}
          >
            <NavProfile
              showMenuBack={route.name !== 'MagazzinoAtelier'}
              sidebarVisible={sidebarVisible}
              toggleSidebar={toggleSidebar}
              goBack="MagazzinoAtelier"
              goBackName="Magazzino Atelier"
            />

            <div>
              <SearchMagazzinoAtelier
                handleSearchFilter={handleSearchFilter}
                onChangeTextProdotto={onChangeTextProdotto}
                searchInputProdotto={searchInputProdotto}
                dataExcelMagazzino={dataExcelMagazzino}
                setLoading={setLoading}
                griglia={griglia}
                setgriglia={setgriglia}
                donwnloadExcel={donwnloadExcel}
                DataSet={DataSet}
                ExcelFile={ExcelFile}
                nameFileExport={nameFileExport}
                ExcelSheet={ExcelSheet}
                handleDataExport={handleDataExport}
                width={width}
              />
            </div>
            <div className="row">
              <div className="col-12">
                {activeSuggestion &&
                  searchInputProdotto &&
                  autosuggestInputProdotto.length > 0 ? (
                  <SuggestionsMagazzinoAtelierReferenzia
                    autosuggestInput={autosuggestInputProdotto}
                    setSearchInput={setSearchInputProdotto}
                    setAutosuggestInput={setAutosuggestInputProdotto}
                  />
                ) : null}
              </div>
            </div>
            {width < 1111 &&
              <div style={{ marginRight: '-23px', marginTop: 10, display: 'flex', justifyContent: 'end' }}>
                <button className="button-container text-truncate" style={{ minWidth: 160 }} onClick={() => { griglia ? setgriglia(false) : setgriglia(true) }}>{griglia ? 'Vista a tabella' : 'Vista a griglia'}</button>
                {
                  (donwnloadExcel &&
                    DataSet[0].data.length > 0) && (
                    <ExcelFile filename={nameFileExport} hideElement>
                      <ExcelSheet dataSet={DataSet} name="Lista prodotti" />
                    </ExcelFile>
                  )
                }
                <button className="button-container text-truncate" type="button" style={{ minWidth: 150 }} onClick={() => { handleDataExport() }}>Esporta lista</button>
              </div>}
            <div className="row g-0" style={{ marginTop: 11, marginBottom: 70 }}>
              <div className="col-12 col-sm-12 col-md-5 col-lg-3 pt-4">
                <div ref={divRef} className="row">
                  <FilterAtelierOptions />
                  <div className="d-flex w-100 flex-column align-items-center">
                    <div className="m-w-75 ">
                      <FilterMagazzinoBrand
                        brandList={brandList}
                        handleFilterBrand={handleFilterBrand}
                      />
                      <FilterMagazzinoAtelierDescrizione
                        searchInput={searchInputPrezzo}
                        onChange={setSearchInputPrezzo}
                        handleSearch={handleSearchDescrizione}
                        title={"Prezzo       "}
                      />

                      <FilterMagazzinoReferenza
                        searchInputReferenza={searchInputReferenza}
                        onChangeTextReferenza={onChangeTextReferenza}
                        handleSearch={handleSearch}
                      />
                      <div className="col-12 ">
                        {activeSuggestionReferenza &&
                          searchInputReferenza &&
                          autosuggestInputReferenza.length > 0 ? (
                          <Suggestions
                            autosuggestInput={autosuggestInputReferenza}
                            setSearchInput={setSearchInputReferenza}
                            setAutosuggestInput={setAutosuggestInputReferenza}
                            isprodotto={false}
                          />
                        ) : null}
                      </div>

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={searchInputDescription}
                        onChange={setSearchInputDescription}
                        handleSearch={handleSearchDescrizione}
                        title={"Descrizione"}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={year}
                        onChange={setYear}
                        handleSearch={handleSearchDescrizione}
                        title={"Anno         "}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={matricole}
                        onChange={setMatricole}
                        handleSearch={handleSearchDescrizione}
                        title={"Matricole  "}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={diameter}
                        onChange={setDiameter}
                        handleSearch={handleSearchDescrizione}
                        title={"Diametro   "}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={collection}
                        onChange={setCollection}
                        handleSearch={handleSearchDescrizione}
                        title={"Collezione"}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={house}
                        onChange={setHouse}
                        handleSearch={handleSearchDescrizione}
                        title={"Cassa        "}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={glass}
                        onChange={setGlass}
                        handleSearch={handleSearchDescrizione}
                        title={"Vetro         "}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={estate}
                        onChange={setEstate}
                        handleSearch={handleSearchDescrizione}
                        title={"Tenuta       "}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={movement}
                        onChange={setMovement}
                        handleSearch={handleSearchDescrizione}
                        title={"Movimento"}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={caliber}
                        onChange={setCaliber}
                        handleSearch={handleSearchDescrizione}
                        title={"Calibro      "}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={powerReserve}
                        onChange={setPowerReserve}
                        handleSearch={handleSearchDescrizione}
                        title={"Riserva di \ncarica"}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={bracelet}
                        onChange={setBracelet}
                        handleSearch={handleSearchDescrizione}
                        title={"Bracciale   "}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={color}
                        onChange={setColor}
                        handleSearch={handleSearchDescrizione}
                        title={"Colore       \nquadrante"}
                      />

                      <FilterMagazzinoAtelierDescrizione
                        searchInput={fileName}
                        onChange={setFileName}
                        handleSearch={handleSearchDescrizione}
                        title={"Foto          \nallegate "} 
                      />

                      <FilterSelectMagazzinoAtelier
                        searchInput={box}
                        onChange={setBox}
                        title={"Scatola      "}
                      />

                      <FilterSelectMagazzinoAtelier
                        searchInput={guarantees}
                        onChange={setGuarantees}
                        title={"Garanzia   "}
                      />

                      <FilterMagazzinoQuantitaAtelier
                        onChange={handleFilterQuantita}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-9">
                <div className="table-responsive">
                  <table className="table table-borderless mt-3" >
                    <TableMagazzinoAtelier
                      data={magazzinoList}
                      confirmToDelete={confirmToDelete}
                      goToSchedaProdotto={goToSchedaProdotto}
                      goToSchedaQuadrante={goToSchedaQuadrante}
                      dataCrm={dataCrm}
                      goToMatricola={goToMatricola}
                      crmSend={crmSend}
                      paginationList={paginationList}
                      currentPage={currentPage}
                      setcurrentPage={setcurrentPage}
                      limit={limit}
                      setResetCurrentPage={setResetCurrentPage}
                      griglia={griglia}
                      heightRisize={heightResize + 'px'}
                    />

                  </table>
                </div>
                <div className="mt-2">
                   {
                    (paginationList.total > 0) && (
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={paginationList.total}
                        pageSize={limit}
                        onPageChange={(page) => { setResetCurrentPage(true), setcurrentPage(page) }}
                      />
                    )
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ModalOptionMatricola
        modalVisible={modalVisibleMatricolaCard}
        setModalVisible={setModalVisibleMatricolaCard}
        activeButton={true}
        navigation={navigation}
        goToMatricola={() => goToMatricola(dataMagazzino)}
        dataMatricola={listMatricola}
        dataMagazzino={dataMagazzino}
        goToCrm={goToCrm}
        dataCrm={dataCrm}
      />
      {modal && (
        <PopupConfirmation
          title={""}
          message={"Sei sicuro di voler eliminare il prodotto?"}
          confirmAction={() =>
            confirmToDeleteTrue(IdStateProduct, IdStateMagazzino)
          }
          stateModal={modal}
          setStateModal={setModal}
        />
      )}
      {modalVisibleCard && (
        <>
          <SchemaProdottoAtelierCard
            modalVisible={modalVisibleCard}
            setModalVisible={setModalVisibleCard}
            navigation={navigation}
            dataMagazzino={dataMagazzino}
            dataMatricola={listMatricola}
            listMagazzino={listMagazzino}
            goToSchedaProdotto={() => goToSchedaProdotto(dataMagazzino, "")}
            getDataMagazzino={() => filtered()}
            goToCrm={goToCrm}
          />
        </>
      )}
      {modalQuadrante.active && (
        <PopPupMessageInfoProdottoCard
          title={modalQuadrante.title}
          Misura={modalQuadrante.Misura}
          Colore={modalQuadrante.Colore}
          Movimento={modalQuadrante.Movimento}
          stateModal={modalQuadrante.active}
          setStateModal={setModalQuadrante}
        />
      )}
      {modaleMessaggeWithUrl.active && (
        <PopUpMessageWithRoute
          dataNav={[]}
          title={modaleMessaggeWithUrl.title}
          message={modaleMessaggeWithUrl.message}
          stateModal={modaleMessaggeWithUrl.active}
          setStateModal={setmodaleMessaggeWithUrl}
          confirmAction={confirmAction}
        />
      )}
    </>

  );
};

export default MagazzinoCercaAtelierScreen;
