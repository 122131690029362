export const formatMessageEmailInteressiAtelier = (
    title: string,
    name: string,
    refDesc:string,
    nameList: string,
  ) => {
    const titleVariable = title ? title : "";
    return `
    <table cellspacing="0" cellpadding="0" border="0" role="presentation" class="m_-3639123574147665703nl2go-body-table" width="100%" style="width: 100%;">
    <tbody>
        <tr>
            <td>
                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="m_-3639123574147665703r0-o" style="table-layout: fixed; width: 100%;">
                    <tbody>
                        <tr>
                            <td valign="top" class="m_-3639123574147665703r1-i" style="background-color: #ffffff;">
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" align="center" class="m_-3639123574147665703r3-o" style="table-layout: fixed; width: 600px;">
                                    <tbody>
                                        <tr>
                                            <td class="m_-3639123574147665703r4-i">
                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                    <tbody>
                                                        <tr>
                                                            <th width="100%" valign="middle" class="m_-3639123574147665703r5-c" style="font-weight: normal;">
                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="m_-3639123574147665703r6-o" style="table-layout: fixed; width: 100%;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td valign="top" class="m_-3639123574147665703r7-i" style="padding-left: 10px; padding-right: 10px;">
                                                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td class="m_-3639123574147665703r8-c" align="center">
                                                                                                <table
                                                                                                    cellspacing="0"
                                                                                                    cellpadding="0"
                                                                                                    border="0"
                                                                                                    role="presentation"
                                                                                                    width="300"
                                                                                                    class="m_-3639123574147665703r9-o"
                                                                                                    style="table-layout: fixed; width: 300px;"
                                                                                                >
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td class="m_-3639123574147665703r10-i" style="font-size: 0px; line-height: 0px; padding-bottom: 15px; padding-top: 15px;">
                                                                                                                <img
                                                                                                                    src="https://ci3.googleusercontent.com/meips/ADKq_NaKA5zOLab_tdB93L6PC9dgpo7_3b-UE0CV_-u3Dq7cddbkG_TXnWLkXAJsTabsn-mGfvYLsQ3I7j-3s3d41-xvluvP6lYuAUQ_4OlR-YanUbu9LhS2aeJjn-1cEpbJXDOfbgeyAJYMcP0Wy6xL=s0-d-e1-ft#https://9f4lr.img.bh.d.sendibt3.com/im/sh/gdi8gZ2Pk9pu.png?u=7126MWSP0tEIB8aZPDEHbrvzxxt"
                                                                                                                    width="300"
                                                                                                                    border="0"
                                                                                                                    style="display: block; width: 100%;"
                                                                                                                    class="CToWUd"
                                                                                                                    data-bit="iit"
                                                                                                                />
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" align="center" class="m_-3639123574147665703r3-o" style="table-layout: fixed; width: 600px;">
                    <tbody>
                        <tr>
                            <td valign="top">
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="m_-3639123574147665703r12-o" style="table-layout: fixed; width: 100%;">
                                    <tbody>
                                        <tr>
                                            <td class="m_-3639123574147665703r13-i" style="background-color: #ffffff; padding-bottom: 20px; padding-top: 20px;">
                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                    <tbody>
                                                        <tr>
                                                            <th width="100%" valign="top" class="m_-3639123574147665703r14-c" style="font-weight: normal;">
                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="m_-3639123574147665703r6-o" style="table-layout: fixed; width: 100%;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td valign="top" class="m_-3639123574147665703r7-i" style="padding-left: 15px; padding-right: 15px;">
                                                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td class="m_-3639123574147665703r15-c" align="center" style="font-size: 0px; line-height: 0px; padding-bottom: 15px; padding-top: 15px;">
                                                                                                <img
                                                                                                    src="https://ci3.googleusercontent.com/meips/ADKq_NaT2jtRTyXC7qrrmp9V0YBcAXx6m1iqfij4vL1p9nuCY-4yDMJpoMEmoYzsz2EjwSGZ2MbQIo4vlCVjNHNRAZZfp3PGimDE5yZvjEIsZZ27p8dqe0AcK9Y033VjdT3dDOPqeQTSLBDg-8GYjPHt=s0-d-e1-ft#https://9f4lr.img.bh.d.sendibt3.com/im/sh/JGHW1ysdCb2C.jpg?u=7126MWSP0tEIBEdHe2JtW1RZZqP"
                                                                                                    width="570"
                                                                                                    border="0"
                                                                                                    style="display: block; width: 100%;"
                                                                                                    class="CToWUd a6T"
                                                                                                    data-bit="iit"
                                                                                                    tabindex="0"
                                                                                                />
                                                                                                <div class="a6S" dir="ltr" style="opacity: 0.01; left: 619px; top: 420.788px;">
                                                                                                    <span data-is-tooltip-wrapper="true" class="a5q" jsaction="JIbuQc:.CLIENT">
                                                                                                        <button
                                                                                                            class="VYBDae-JX-I VYBDae-JX-I-ql-ay5-ays CgzRE"
                                                                                                            jscontroller="PIVayb"
                                                                                                            jsaction="click:h5M12e; clickmod:h5M12e;pointerdown:FEiYhc;pointerup:mF5Elf;pointerenter:EX0mI;pointerleave:vpvbp;pointercancel:xyn4sd;contextmenu:xexox;focus:h06R8; blur:zjh6rb;mlnRJb:fLiPzd;"
                                                                                                            data-idom-class="CgzRE"
                                                                                                            jsname="hRZeKc"
                                                                                                            aria-label="Scarica allegato "
                                                                                                            data-tooltip-enabled="true"
                                                                                                            data-tooltip-id="tt-c14"
                                                                                                            data-tooltip-classes="AZPksf"
                                                                                                            id=""
                                                                                                            jslog="91252; u014N:cOuCgd,Kr2w4b,xr6bB; 4:WyIjbXNnLWY6MTc5ODQ5OTcxMzAyNjEzNTA1NCJd; 43:WyJpbWFnZS9qcGVnIl0."
                                                                                                        >
                                                                                                            <span class="OiePBf-zPjgPe VYBDae-JX-UHGRz"></span>
                                                                                                            <span class="bHC-Q" data-unbounded="false" jscontroller="LBaJxb" jsname="m9ZlFb" soy-skip="" ssk="6:RWVI5c"></span>
                                                                                                            <span class="VYBDae-JX-ank-Rtc0Jf" jsname="S5tZuc" aria-hidden="true">
                                                                                                                <span class="bzc-ank" aria-hidden="true">
                                                                                                                    <svg height="20" viewBox="0 -960 960 960" width="20" focusable="false" class="aoH">
                                                                                                                        <path
                                                                                                                            d="M480-336 288-528l51-51 105 105v-342h72v342l105-105 51 51-192 192ZM263.72-192Q234-192 213-213.15T192-264v-72h72v72h432v-72h72v72q0 29.7-21.16 50.85Q725.68-192 695.96-192H263.72Z"
                                                                                                                        ></path>
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                            <div class="VYBDae-JX-ano"></div>
                                                                                                        </button>
                                                                                                        <div class="ne2Ple-oshW8e-J9" id="tt-c14" role="tooltip" aria-hidden="true">Scarica</div>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="m_-3639123574147665703r12-o" style="table-layout: fixed; width: 100%;">
                                    <tbody>
                                        <tr>
                                            <td class="m_-3639123574147665703r13-i" style="background-color: #ffffff; padding-bottom: 20px; padding-top: 20px; padding-left: 120px;">
                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                    <tbody>
                                                        <tr>
                                                            <th width="100%" valign="top" class="m_-3639123574147665703r14-c" style="font-weight: normal;">
                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="m_-3639123574147665703r6-o" style="table-layout: fixed; width: 100%;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td valign="top" class="m_-3639123574147665703r7-i" style="padding-left: 15px; padding-right: 15px; ">
                                                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td class="m_-3639123574147665703r11-c" align="center">
                                                                                                <table
                                                                                                    cellspacing="0"
                                                                                                    cellpadding="0"
                                                                                                    border="0"
                                                                                                    role="presentation"
                                                                                                    width="210"
                                                                                                    class="m_-3639123574147665703r0-o"
                                                                                                    style="table-layout: fixed; width: 210px;"
                                                                                                >
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td class="m_-3639123574147665703r10-i" style="font-size: 0px; line-height: 0px; padding-bottom: 15px; padding-top: 15px;">
                                                                                                                <img
                                                                                                                    src="https://9f4lr.img.bh.d.sendibt3.com/im/sh/nl0s1RgTODFo.png?u=7126MWSP0tEIBKfzWsjDq9qyuAf"
                                                                                                                    width="210"
                                                                                                                    border="0"
                                                                                                                    style="display: block; width: 40%;"
                                                                                                                    class="CToWUd"
                                                                                                                    data-bit="iit"
                                                                                                                />
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center" class="m_-3639123574147665703r12-o" style="table-layout: fixed; width: 100%;">
                                    <tbody>
                                        <tr>
                                            <td class="m_-3639123574147665703r13-i" style="background-color: #ffffff; padding-bottom: 20px; padding-top: 20px;">
                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                    <tbody>
                                                        <tr>
                                                            <th width="100%" valign="top" class="m_-3639123574147665703r14-c" style="font-weight: normal;">
                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="m_-3639123574147665703r6-o" style="table-layout: fixed; width: 100%;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td valign="top" class="m_-3639123574147665703r7-i" style="padding-left: 15px; padding-right: 15px;">
                                                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td class="m_-3639123574147665703r16-c" align="left">
                                                                                                <table
                                                                                                    cellspacing="0"
                                                                                                    cellpadding="0"
                                                                                                    border="0"
                                                                                                    role="presentation"
                                                                                                    width="100%"
                                                                                                    class="m_-3639123574147665703r17-o"
                                                                                                    style="table-layout: fixed; width: 100%;"
                                                                                                >
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                align="center"
                                                                                                                valign="top"
                                                                                                                class="m_-3639123574147665703r18-i m_-3639123574147665703nl2go-default-textstyle"
                                                                                                                style="
                                                                                                                    color: #3b3f44;
                                                                                                                    font-family: arial, helvetica, sans-serif;
                                                                                                                    font-size: 16px;
                                                                                                                    word-break: break-word;
                                                                                                                    line-height: 1.5;
                                                                                                                    padding-bottom: 15px;
                                                                                                                    padding-top: 15px;
                                                                                                                    text-align: center;
                                                                                                                "
                                                                                                            >
                                                                                                                <div>
                                                                                                                    <p style="margin: 0;"><span style="color: #7a7a7a;">Gentile ${titleVariable} ${name},</span></p>
                                                                                                                    <p style="margin: 0;">
                                                                                                                        <span style="color: #7a7a7a;">
                                                                                                                            è stato un piacere accoglierLa nel nostro Atelier Casa Capone. Questa mail per confermarLe di aver preso nota del
                                                                                                                            suo interesse per l’orologio (${nameList})
                                                                                                                        </span>
                                                                                                                        <br />
                                                                                                                        <span style="color: #7a7a7a;">ref. ${refDesc}.</span>
                                                                                                                    </p>
                                                                                                                    <p style="margin: 0;">
                                                                                                                        <span style="color: #7a7a7a;">
                                                                                                                            Faremo tutto il possibile per soddisfare la Sua richiesta e sarà nostra premura contattarLa ai recapiti che ci ha
                                                                                                                            gentilemente lasciato
                                                                                                                        </span>
                                                                                                                        <br />
                                                                                                                        <span style="color: #7a7a7a;">non appena avremo aggiornamenti in merito.</span>
                                                                                                                    </p>
                                                                                                                    <p style="margin: 0;"><span style="color: #7a7a7a;">Cordialmente,</span></p>
                                                                                                                    <p style="margin: 0;"><span style="color: #7a7a7a;">Atelier Casa Capone</span></p>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>

    `;


  };
  