import { postNotify } from "../services/Notify";
import { useAuth } from "./useAuth";
interface CreatedPosted {
  actionLog: string;
  message: string;
}
export const useNotifyCrud = () => {
  const { id } = useAuth();

  const createdPosted = async ({ actionLog, message }: CreatedPosted) => {
    const newNotify = {
      idUser: id,
      actionLog,
      message,
      dateExport: dateTimeNow(),
    };
    await postNotify(newNotify);
  };
  const dateTimeNow = () => {
    let date = new Date();
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const dateNow =
      year +
      "-" +
      month +
      "-" +
      day +
      "T" +
      hour +
      ":" +
      minute +
      ":" +
      seconds;

    return dateNow;
  };
  return { createdPosted };
};

