import React from "react";
import { TouchableOpacity, Text, View, Image } from "react-native";
import { stylesMagazzino } from "../../GeneralStyles/MagazzinoStyle";

interface Props {
  title: String;
  toggle: () => void;
}
export const CollapseTitle = ({ title, toggle }: Props) => {
  return (
    <div className="col-12 d-flex justify-content-between">
      <Text style={stylesMagazzino.labelFilter}>{title}</Text>
      <TouchableOpacity
        onPress={() => toggle()}
        style={{
          margin: "auto",
        }}
      >
        <View>
          <Image
            style={stylesMagazzino.arrowDown}
            source={require("../../assets/icons/arrow_down.png")}
          />
        </View>
      </TouchableOpacity>
    </div>
  );
};
