import { StatusBar, StyleSheet } from "react-native";

export const stylesCRM = StyleSheet.create({
    backButtonContainer: {
      position: "absolute",
      top: -35, 
      left: -90
    },
    backButton: {
      width: 150,
      height: 150,
      alignSelf: 'flex-start',
      tintColor: 'black',
    },
    safeArea: {
      flex: 1,
      paddingTop: StatusBar.currentHeight,
      backgroundColor: "white",
    },
    logoCapone: {
      height: 100,
      width: 300,
      margin: 0,
    },
    textButton: {
      fontSize: 20,
      fontWeight: "500",
      textAlign: "center",
    }
})