import { SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { IFormCrmAtelier, IFormHobby, IFormProdottiPosseduti, IFormProdottoOsservati, IFormRelazioni, IFormStatus } from "../interfaces/IFormCrmAtelier"
import { useContext, useEffect, useState } from "react"
import { httpDeletHobby, httpDeleteClientRelationship, httpDeleteMatricolaAtelier, httpDeleteProdottoOsservati, httpDeleteProduttoProsseduti, httpDeleteRepair, httpGetAddresType, httpGetBrand, httpGetBussines, httpGetClient, httpGetClientById, httpGetClientHobby, httpGetClientProdasservati, httpGetClientRelationship, httpGetFindByClientLimit, httpGetGender, httpGetHobby, httpGetMagazzinoByID, httpGetPhonetype, httpGetProduttoProciduti, httpGetRelationShip, httpGetSearchClientTableCrm, httpGetServiceById, httpGetStatus, httpGetTitle, httpGetUserAssitenza, httpGetUserById, httpPostChangeStattusService, httpPostClientProdasservati, httpPostClientRelationship, httpPostForma, httpPostFormaCustom, httpPostFormaNoSignature, httpPostHobby, httpPostProduttoProciduti, httpPostSaleAtelier, httpPostSaveClientCrm, httpPostStatusCLient, httpPutClientRelationship, httpPutMagazzinoQuantity, httpPutMatricolaAtelier, httpPutModifyClientCrm, httpPutProdottoOsservati, httpPutProduttoProsseduti, loadDataForUpdateCrmAssistenza } from "../services/CrmAtelierFormServices"
import { listAllHobby } from "../services/CrmAtelierService"
import { optionValues } from "../../../../components/ComponentsAtelier/inputs/inputComponent"
import { IClient } from "../../../../interfaces/IClient"
import { FormatDDMMYY, FormatHHSS, FormatYYMMDD, } from "../../../../utils/FormatingDate"
import { DataSearchClientTableCrm } from "../interfaces/GetSearchClientTableCrm"
import { DataGetFindByClientLimit } from "../interfaces/GetFindByClientLimit"
import { FindClient, ListInteressiByClient } from "../../../../services/interessiServices"
import { UserContext } from "../../../../context/UserContext"
import ConfigCapone from "../../../../utils/ConfigCapone"
import { formatMessageEmailBirthDay } from "../../FormatMessageEmail/formatMessageEmailBirthDay"
import { DataPost } from "../interfaces/PostFormaData"
import { formatMessageEmail } from "../../FormatMessageEmail/formatMessageEmail"
import { ClientSearchR } from "../interfaces/HooksInterfaces"
import { DataGetClienHobby } from "../interfaces/GetClientHobby"
import { RouteProduttoProciduti } from "../interfaces/PropsServices"
import { enJoinMagazzinoCrm } from "../schemas/Schemas"
import { IUser, getUserFromId } from "../../../../api/ClientService"
import { formatMessageEmailAtelier } from "../../FormatMessageEmail/formatMessageEmailAtelier"
import { enOptonNavigate } from "../../../../schemas/Schemas"


const alertData = {
    title: "Informazione",
    message: "",
    active: false,
    route: "",
}
export const valuesProdotto: IFormProdottiPosseduti = {
    idBrand: "",
    Data: "",
    Matricola: "",
    Prezzo: "",
    PrezzoApplicato: "",
    Prodotto: "",
    Referenza: "",
    isDiscount: false
}

export const valuesProdottoOsservato: IFormProdottoOsservati = {
    description: "",
}

export const valuesRelazione: IFormRelazioni = {
    idRelationship: "",
    Name: "",
}

const defaultValues: IFormCrmAtelier = {
    DateUpdateCrm: new Date(),
    BlackList: false,
    IsImportant: false,
    EnviaCatalogo: false,
    presenteLista: false,
    prodottiPosseduti: [],
    prodottoOsservati: [],
    relazioni: [valuesRelazione],
    OrigenIdBussines: 'Atelier',
}

type Props = {
    dataProciduti?: RouteProduttoProciduti | undefined;
    option?: enJoinMagazzinoCrm;
    IdClientEdit?: string | undefined | number;
    clear?: enOptonNavigate;
}

const useCrmAtelierForm = ({ dataProciduti, option, IdClientEdit,clear }: Props) => {

    //*Context
    const { userInLogin } = useContext(UserContext);

    //*States Primarios
    const [title, setTitle] = useState<optionValues[]>([])
    const [gender, setGender] = useState<optionValues[]>([]);
    const [addressType, setAddressType] = useState<optionValues[]>([]);
    const [phtoneType, setPhoneType] = useState<optionValues[]>([]);
    const [bussines, setBussines] = useState<optionValues[]>([]);
    const [hobby, setHobby] = useState<optionValues[]>([]);
    const [hobbyClient, setHobbyClient] = useState<DataGetClienHobby[]>([]);
    const [relationShip, setRelationShip] = useState<optionValues[]>([])
    const [brand, setBrand] = useState<optionValues[]>([]);
    const [listClients, setListClients] = useState<optionValues[]>([])
    const [listClientsR, setListClientsR] = useState<ClientSearchR>()
    const [clients, setClients] = useState<IClient[]>([]);
    const [clientsR, setClientsR] = useState<IClient[]>([]);
    const [listSearchClientTableCrm, setListSearchClientTableCrm] = useState<DataSearchClientTableCrm[]>([])
    const [statusList, setStatusList] = useState<optionValues[]>([])
    const [findByClientLimitList, setFindByClientLimitList] = useState<DataGetFindByClientLimit[]>([])
    const [clientSearch, setClientSearch] = useState<IClient>()
    const [totalProductPosseduti, setTotalProductPosseduti] = useState(0)


    //*States numbers
    const [starts, setStarts] = useState(0);
    const [startsSelect, setStartsSelect] = useState(0);


    //*UseState Bools
    const [showMano, setShowMano] = useState(false);
    const [showProdottoOsservati, setShowProdottoOsservati] = useState(false);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);

    //*States Any
    const [modalMessageWhitUrl, setModalMessageWhitUrl] = useState(alertData)

    //*useForm
    const { register, handleSubmit, formState: { errors }, setValue, control, watch, getValues, reset } = useForm<IFormCrmAtelier>({ defaultValues })

    const onSubmit: SubmitHandler<IFormCrmAtelier> = async (data: IFormCrmAtelier) => {
        setLoading(true);
        data.OrigenIdBussines = '9';
        data.idUser = userInLogin.idUser;
        data.StarValuation = startsSelect;
        data.LastName = data.Name;
        data.Address = (data?.AddressStreet != null ? data.AddressStreet : "") + " " + (data?.AddressNumber != null ? data.AddressNumber : "") + ", " + (data?.AddressCity != null ? data.AddressCity : "") + ", " + (data.ZipCode != null ? data.ZipCode : "") + ", " + (data.Country != null ? data.Country : "");
        data.MeasureLittleFingerLH = (data.MeasureLittleFingerLH && data.MeasureLittleFingerLH != null && data.MeasureLittleFingerLH != 0) ? Number(data.MeasureLittleFingerLH) : null;
        data.MeasureMiddleFingerLH = (data.MeasureMiddleFingerLH && data.MeasureMiddleFingerLH != null && data.MeasureMiddleFingerLH != 0) ? Number(data.MeasureMiddleFingerLH) : null;
        data.MeasureRingFingerLH = (data.MeasureRingFingerLH && data.MeasureRingFingerLH != null && data.MeasureRingFingerLH != 0) ? Number(data.MeasureRingFingerLH) : null;
        data.MeasureIndexFingerLH = (data.MeasureIndexFingerLH && data.MeasureIndexFingerLH != null && data.MeasureIndexFingerLH != 0) ? Number(data.MeasureIndexFingerLH) : null;
        data.MeasureThumbLH = (data.MeasureThumbLH && data.MeasureThumbLH != null && data.MeasureThumbLH != 0) ? Number(data.MeasureThumbLH) : null;
        data.MeasurePulseLH = (data.MeasurePulseLH && data.MeasurePulseLH != null && data.MeasurePulseLH != 0) ? Number(data.MeasurePulseLH) : null;
        data.MeasurePulseRH = (data.MeasurePulseRH && data.MeasurePulseRH != null && data.MeasurePulseRH != 0) ? Number(data.MeasurePulseRH) : null;
        data.MeasureLittleFingerRH = (data.MeasureLittleFingerRH && data.MeasureLittleFingerRH != null && data.MeasureLittleFingerRH != 0) ? Number(data.MeasureLittleFingerRH) : null;
        data.MeasureMiddleFingerRH = (data.MeasureMiddleFingerRH && data.MeasureMiddleFingerRH != null && data.MeasureMiddleFingerRH != 0) ? Number(data.MeasureMiddleFingerRH) : null;
        data.MeasureRingFingerRH = (data.MeasureRingFingerRH && data.MeasureRingFingerRH != null && data.MeasureRingFingerRH != 0) ? Number(data.MeasureRingFingerRH) : null;
        data.MeasureIndexFingerRH = (data.MeasureIndexFingerRH && data.MeasureIndexFingerRH != null && data.MeasureIndexFingerRH != 0) ? Number(data.MeasureIndexFingerRH) : null;
        data.MeasureThumbRH = (data.MeasureThumbRH && data.MeasureThumbRH != null && data.MeasureThumbRH != 0) ? Number(data.MeasureThumbRH) : null;
        data.DateBirth = data.DateBirth == "" ? null : data.DateBirth;
        if (edit) {
            await putData(data);
        } else {
            await postData(data);
        }
    }
    const { fields, append, remove, prepend } = useFieldArray({
        name: "prodottiPosseduti",
        control,
    });
    const { fields: fieldsPO, append: appendPO, remove: removePO, prepend: prependPO } = useFieldArray({
        name: "prodottoOsservati",
        control,
    });
    const { fields: fieldR, append: appendR, remove: removeR, prepend: prependR } = useFieldArray({
        name: "relazioni",
        control,
    });

    //*Handle Change

    const handleChangeRelazioni = async (evt: React.ChangeEvent<HTMLInputElement>, index?: number,) => {
        const { value, } = evt.target;
        console.log(value);
        if (value !== "") {
            const response = await httpGetClient(value);
            if (index != undefined) {
                setListClientsR({
                    listClientsR: formatOptions(response.response, "idClient", "name", undefined, "phone"),
                    index
                })
                setClientsR(response.response);

            } else {
                setListClients(formatOptions(response.response, "idClient", "name", undefined, "phone"));
                setClients(response.response);
            }
        } else {
            reset();
            setListClients([]);
            setListClientsR({
                listClientsR: [],
                index: undefined
            });
        }
    }

    //*Funciones Handle
    const handleData = async () => {
        try {
            const response = await Promise.all([
                httpGetTitle({}),
                httpGetGender({}),
                httpGetAddresType({}),
                httpGetPhonetype({}),
                httpGetBussines({}),
                httpGetHobby({}),
                httpGetRelationShip({}),
                httpGetBrand({}),
            ]);
            const [titleData, genderData, addresTypeData, phtoneTypeData, bussinesData, hobbyData, relationShipData, brandData] = response;

            setTitle(formatOptions(titleData.response, "idTitle", "desc_Title"));
            setGender(formatOptions(genderData.response, "idGender", "gender"));
            setAddressType(formatOptions(addresTypeData.response, "idAddressType", "addressType"));
            setPhoneType(formatOptions(phtoneTypeData.response, "idPhoneType", "phoneType"));
            setBussines(formatOptions(bussinesData.response, "idBussines", "desc_Bussines"));
            setHobby(formatOptions(hobbyData.response, "idHobby", "hobby", "image"));
            setRelationShip(formatOptions(relationShipData.response, "idRelationship", "name"));
            setBrand(formatOptions(brandData.response, "idBrand", "desc_Brand"));

        } catch (error) {
            throw new Error(error);
        } finally {
            console.info("Finalized Petitions");
        }
    }

    const handleShowMano = () => {
        setShowMano(!showMano)
    }

    const handleShowProdottoOsservati = () => {
        setShowProdottoOsservati(!showProdottoOsservati);
    }

    const handleResetListClient = () => {
        setListClients([])
    }

    const handleChangeOptionSelect = async (id: string | number) => {
        setLoading(true)
        const { response: client } = await httpGetClientById(id);

        setClientSearch(client);
        setValue("IdClient", client?.idClient);
        setValue("Name", client?.name);
        setValue("Email", client?.email);
        setValue("PhoneType", client?.idPhoneType);
        setValue("idAddressType", client?.idAddressType);
        setValue("Phone", client.phone);
        setValue("dataInserimentoCliente", FormatDDMMYY(client.dateInsert))
        setValue("dataAggiornamentoCliente", FormatDDMMYY(client.dateUpdateCrm));
        setValue("idGender", client.idGender);
        setValue("Country", client.country);
        setValue("AddressCity", client.addressCity);
        setValue("Address", client.address);
        setValue("ZipCode", client.zipCode);
        setValue('BlackList', client.blackList);
        setValue('IsImportant', client.isImportant);
        setValue("DateBirth", FormatYYMMDD(client.dateBirth));
        setValue('MeasureIndexFingerLH', client.measureIndexFingerLH);
        setValue('MeasureMiddleFingerLH', client.measureMiddleFingerLH);
        setValue('MeasureRingFingerLH', client.measureRingFingerLH);
        setValue('MeasureLittleFingerLH', client.measureLittleFingerLH);
        setValue('MeasurePulseLH', client.measurePulseLH);
        setValue('MeasureThumbLH', client.measureThumbLH);
        setValue('MeasureIndexFingerRH', client.measureIndexFingerRH);
        setValue('MeasureMiddleFingerRH', client.measureMiddleFingerRH);
        setValue('MeasureRingFingerRH', client.measureRingFingerRH);
        setValue('MeasureLittleFingerRH', client.measureLittleFingerRH);
        setValue('MeasurePulseRH', client.measurePulseRH);
        setValue('MeasureThumbRH', client.measureThumbRH);
        setValue('EnviaCatalogo', client.enviaCatalogo);
        setValue('AddressStreet', client.addressStreet);
        setValue('AddressNumber', client.addressNumber);
        setValue('Province', client.province);
        setValue('Profession', client.profession);
        setValue('Deprecated', client.deprecated);
        setValue('Note', client.note);
        setValue('sendEmailBirthday', client.sendEmailBirthday);
        setValue('StarValuation', client.starValuation);
        setStartsSelect(client.starValuation);

        setTimeout(() => {
            setValue("idTitle", client.idTitle);
        }, 100);

        getSelectedClient(client);
        handleResetListClient();
        setLoading(false)
    }

    const handleSelectStarts = (index: number, isHover = true) => {
        if (isHover) {
            setStarts(index);
        } else {
            setStartsSelect(index);
            setValue('StarValuation', String(index));
        }
    }

    const handleConfirm = () => {
        setModalMessageWhitUrl(alertData);
        // reset();
    }

    const handleSelectRelazioni = (id: string | number, index: number) => {
        const client = clientsR.find(x => x.idClient == id);
        setValue(`relazioni.${index}.Name`, client?.name);
        setValue(`relazioni.${index}.idClientRelationship`, client?.idClient);
        setListClientsR({
            index: -1,
            listClientsR: [],
        })
    }

    const handleRemoveProduttoProsseduti = (index: number, idClientProdPosseduti?: number | string) => {
        if (idClientProdPosseduti != undefined) {
            deleteProdduttoProsseduti(idClientProdPosseduti);
        }
        remove(index);
    }

    const handleremoveProdottiOsservati = (index: number, idClientProdAsservati: number | string) => {
        if (idClientProdAsservati != undefined) {
            deleteProdottoOsservati(idClientProdAsservati);
        }
        removePO(index);
    }

    const handleRemoveRelazioni = (index: number, idClientRelationship: number | string) => {
        if (idClientRelationship != undefined) {
            deleteClientRelationship(idClientRelationship);
        }
        removeR(index);
    }

    //*Peticiones Get

    const getSelectedClient = async (client: IClient) => {
        const response = await Promise.all([
            httpGetClientRelationship({ value: client.idClient, parameter: "idClient" }),
            httpGetSearchClientTableCrm(client.idClient),
            httpGetStatus({}),
            httpGetUserById(client.idUser),
            httpGetFindByClientLimit(client.idClient),
            ListInteressiByClient(Number(client.idClient)),
            httpGetClientHobby(client.idClient),
            httpGetProduttoProciduti(client.idClient),
            httpGetClientProdasservati(client.idClient),
        ]);

        const [clientRelationShipData, searchClientTableCrmData, statusData, dataUser, findByClientLimitData, listInteressiData,
            clientHobbyData, produttoProcidutiData, clientProdsasservatiData] = response;

        if (clientRelationShipData.response.length > 0) {
            const dataRelazioni = clientRelationShipData.response.map((item) => {
                const data: IFormRelazioni = {
                    Name: item.name,
                    idRelationship: item.idRelationship,
                    LastName: item.lastName,
                    idClient: item.idClient,
                    idClientRelationship: item.idClientRelationship,
                }
                return data;
            });
            removeR();
            prependR(dataRelazioni, { shouldFocus: false });
        }
        if (produttoProcidutiData.response.length > 0) {
            const dataProdotti = produttoProcidutiData.response.map((item) => {
                const data: IFormProdottiPosseduti = {
                    Data: FormatYYMMDD(item.data),
                    idBrand: item.idBrand,
                    idClient: item.idClient,
                    Matricola: item.matricola,
                    Prezzo: item.prezzo,
                    PrezzoApplicato: item.priceApplied,
                    Prodotto: item.prodotto,
                    Referenza: item.referenza,
                    SendEmail: false,
                    idClientProdPosseduti: item.idClientProdPosseduti,
                    idMatricola: item.idMatricola,
                }
                return data;
            })
            remove()
            prepend(dataProdotti, { shouldFocus: false });
        }
        if (clientProdsasservatiData.response.length > 0) {
            const dataProdotti = clientProdsasservatiData.response.map((item) => {
                const data: IFormProdottoOsservati = {
                    description: item.description,
                    idClient: item.idClient,
                    idClientProdAsservati: item.idClientProdAsservati,
                    idMatricola: item.idMatricola,
                }
                return data;
            })
            removePO();
            prependPO(dataProdotti, { shouldFocus: false });
        }

        setListSearchClientTableCrm(searchClientTableCrmData.response);
        setStatusList(formatOptions(statusData.response, "idStatus", "desc_Status"));
        setValue("schedaCreata", dataUser ? dataUser.response.name + " - " + dataUser.response.desc_role : "");
        setFindByClientLimitList(findByClientLimitData.response);
        listInteressiData.length > 0 &&
            setValue('presenteLista', true); //setListInteressi(listInteressiData);
        if (clientHobbyData.response.length > 0) {
            const hobbys: string[] = []
            setHobbyClient(clientHobbyData.response);
            for (let i = 0; i < clientHobbyData.response.length; i++) {
                hobbys.push(clientHobbyData.response[i].idHobby)
            }
            setValue('hobby', hobbys)
        }
        setEdit(true);
    }

    //*Peticiones Post

    const postData = async (data: IFormCrmAtelier) => {
        setLoading(true)
        const postClienteResponse = await httpPostSaveClientCrm(data);
        if (postClienteResponse.id) {
            try {
                data.IdClient = postClienteResponse.id;
                if (ConfigCapone.configTest && data.invioAuguri) {
                    const titleClient = title.find(x => x.value == data.idTitle);
                    await postSendForma(titleClient?.label, data.Name, "", "Atelier", data.Country, "Tanti auguri dalla Gioielleria Capone",);
                }
                (data.hobby.length > 0) && await postHobby(data.hobby, data.IdClient);
                (data.relazioni.length > 0) && await postRelationship(data.relazioni, data.IdClient, data.Name);
                (data.prodottoOsservati.length > 0) && await postProdottoOsservati(data.prodottoOsservati, data.IdClient);
                (data.prodottiPosseduti.length > 0) && await postProduttoProcesuti(data.prodottiPosseduti, data.IdClient, data.idTitle);
                await postStatusCLient(data.IdClient, userInLogin.idUser);
                (data.prodottiPosseduti.length > 0) && await postEmailSave(data.prodottiPosseduti, data.IdClient, data.idTitle);
                await refreshData(data.IdClient)
                setModalMessageWhitUrl({ active: true, message: "Salvato con successo", route: "", title: "Informazione" })
            } catch (error) {
                setModalMessageWhitUrl({ active: true, message: error, route: "", title: "Si è verificato un errore, contatta l'assistenza" })
            } finally {
                setLoading(false)
            }
        }
    }

    const postHobby = async (hobby: string[], IdClient: string) => {
        for (let i = 0; i < hobby.length; i++) {
            const dataSend: IFormHobby = {
                IdClient: IdClient,
                IdHobby: hobby[i]
            }
            try {
                const response = await httpPostHobby(dataSend);
                if (response) {
                    console.info("Post Hobby", response)
                }
            } catch (error) {
                setLoading(false);
                setModalMessageWhitUrl({ active: true, message: error, route: "", title: "Si è verificato un errore, contatta l'assistenza" })
                throw new Error(error);
            }
        }
    }

    const postRelationship = async (data: IFormRelazioni[], IdClient: string, name: string) => {

        let dataSendRelationClient = [];

        for (let i = 0; i < data.length; i++) {
            if (data[i].idRelationship != "") {
                data[i].idClient = IdClient;
                data[i].idRelationship = Number(data[i].idRelationship)

                if (data[i]?.idClientRelationship != undefined) {
                    dataSendRelationClient.push(data[i]);
                }
                try {
                    const response = await httpPostClientRelationship(data[i]);
                    if (response) {
                        console.info('Saved')
                    }
                } catch (error) {
                    setLoading(false);
                    setModalMessageWhitUrl({ active: true, message: error, route: "", title: "Si è verificato un errore, contatta l'assistenza" })
                    throw new Error(error);
                }
            }
        }

        // add reference to other client
        for (let i = 0; i < dataSendRelationClient.length; i++) {
            if (dataSendRelationClient[i]?.idClientRelationship != undefined) {
                let option = await optionRelationShip(dataSendRelationClient[i].idRelationship)
                dataSendRelationClient[i].idClient = dataSendRelationClient[i].idClientRelationship
                dataSendRelationClient[i].idClientRelation = IdClient;
                dataSendRelationClient[i].Name = name;
                dataSendRelationClient[i].idRelationship = option;
                try {
                    const response = await httpPostClientRelationship(dataSendRelationClient[i]);
                    if (response) {
                        console.info('Saved')
                    }
                } catch (error) {
                    setLoading(false);
                    setModalMessageWhitUrl({ active: true, message: error, route: "", title: "Si è verificato un errore, contatta l'assistenza" })
                    throw new Error(error);
                }
            }
        }
    }

    const postProdottoOsservati = async (data: IFormProdottoOsservati[], IdClient: string) => {
        for (let i = 0; i < data.length; i++) {
            data[i].idClient = Number(IdClient);
            try {
                const response = await httpPostClientProdasservati(data[i]);
                if (response) {
                    console.info('Saved')
                }
            } catch (error) {
                setLoading(false);
                setModalMessageWhitUrl({ active: true, message: error, route: "", title: "Si è verificato un errore, contatta l'assistenza" })
                throw new Error(error);
            }
        }
    }

    const postProduttoProcesuti = async (data: IFormProdottiPosseduti[], IdClient: string, idTitle: string) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].idBrand != "" && data[i].Prodotto != "" && data[i].Referenza != "" && data[i].Matricola != "" && data[i].Data != "" && data[i].Prezzo != "") {
                data[i].idClient = IdClient;
                data[i].Prezzo = Number(data[i].Prezzo);
                data[i].PrezzoApplicato = Number(data[i].PrezzoApplicato);
                try {
                    if (data[i].isDiscount) {
                        let { response } = await httpGetMagazzinoByID(data[i].idMagazzino)
                        let updateMagazzino = {
                            idMagazzino: Number(response?.idMagazzino),
                            quantity: Number(response?.quantity) - 1,
                            negozio: Number(response?.idBussines),
                        }
                        await httpPutMagazzinoQuantity(updateMagazzino)
                        await postSendFormaVendita(data[i], updateMagazzino)
                        await postSaleAndUpdateMatricola(data[i], updateMagazzino)
                    }
                    const response = await httpPostProduttoProciduti(data[i]);
                    if (response) {
                        console.info('Saved')
                        //await postEmailSave(data[i], IdClient, idTitle)
                    }
                } catch (error) {
                    setLoading(false);
                    setModalMessageWhitUrl({ active: true, message: error, route: "", title: "Si è verificato un errore, contatta l'assistenza" })
                    throw new Error(error);
                }
            }

        }
    }

    const postStatusCLient = async (IdClient: string, IdUser: string | number) => {
        const dataSend: IFormStatus = {
            idClient: Number(IdClient),
            idUser: Number(IdUser)
        }
        try {
            const response = await httpPostStatusCLient(dataSend);
            if (response) {
                console.info('Saved')
            }
        } catch (error) {
            setLoading(false);
            setModalMessageWhitUrl({ active: true, message: error, route: "", title: "Si è verificato un errore, contatta l'assistenza" })
            throw new Error(error);
        }
    }

    const postEmailSave = async (data: IFormProdottiPosseduti[], IdCliente: string, IdTitle?: string,) => {
        const titleClient = title.find(x => x.value == IdTitle);
        const { response: client } = await httpGetClientById(IdCliente);
        if (client.idClient) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].idBrand != "" && data[i].Prodotto != "" && data[i].Referenza != "" && data[i].Matricola != "" && data[i].Data != "" && data[i].Prezzo != "" && data[i].SendEmail) {
                    data[i].idClient = IdCliente;
                    if (ConfigCapone.configTest) {
                        await postSendForma(titleClient?.label, client?.name, data[i].Prodotto, "Atelier", client?.country, "Grazie per il suo acquisto");
                    }

                }
            }
        }

    }

    const postSendForma = async (title: string, name: string, obje: string, desc_bussines: string, country: any, subject: string, type: string = "notice", email?: string) => {
        if (ConfigCapone.configTest) {
            for (let i = 0; i < ConfigCapone.emailTest.length; i++) {
                const emailMessage = email != undefined ? email : formatMessageEmail(title ?? "", name ?? "", obje ?? "", desc_bussines ?? "", country ?? "")
                const dataSend: DataPost = {
                    from: "customerservice@sergiocapone.com",
                    type: type,
                    email: ConfigCapone.emailTest[i],
                    subject: subject,
                    message: emailMessage
                };
                //const responseSendEmail = await httpPostForma(dataSend);
                //console.info("Email Send", responseSendEmail);
            }
        }
    }

    const postSaleAndUpdateMatricola = async (data, magazzino) => {

        const updateMatricola = {
            idMatricola: data?.idMatricola,
            idReferenzia: Number(data?.idReference),
            idNegozio: Number(magazzino?.negozio),
            Matricola: data?.Matricola,
            PutAside: false,
            PutMoveBusiness: false,
            PutSale: true,
            PutSaleNewCustomer: false,
            PutSaleExistingCustomer: false,
            PutOnline: false,
            idClient: data.idClient,
        };

        let postSale = {
            idBussinessSeller: magazzino?.negozio,
            idClient: data.idClient,
            sellDate: new Date(),
            idMatricola: data?.idMatricola,
            idUser: userInLogin.idUser,
        }

        await httpPostSaleAtelier(postSale)
        await httpPutMatricolaAtelier(updateMatricola)
        await httpDeleteMatricolaAtelier(data.idMatricola)

    }

    const postSendFormaVendita = async (data, magazzino) => {
        let dateCurrent = new Date()
        let date = `${FormatDDMMYY(dateCurrent)}, ore ${FormatHHSS(dateCurrent)}`
        let desc_brand = brand.find(x => Number(x.value) == Number(data.idBrand))
        let desc_bussines = bussines.find(x => Number(x.value) == Number(magazzino.negozio))
        let dataSend = {
            type: "notice",
            email: `${ConfigCapone.emailMarketing}`,
            subject: "Nuova Vendita",
            message: `<p>Operazione: Nuova Vendita</p>
                            <p>Operatore: ${userInLogin.userName}</p>
                            <p> Referenza: ${data?.Referenza} </p>
                            <p> Brand: ${desc_brand?.label}</p>
                            <p> Negozio: ${desc_bussines?.label} </p>
                            <p> Matricola: ${data?.Matricola}</p> 
                            <p>  Prezzo: ${data?.Prezzo}</p>
                            <p>  Data: ${date}</p>`,
        };
        await httpPostFormaCustom(dataSend);
    }

    const postSendFormaVenditaTemplate = async (data) => {
        let dataSend = {
            from: "customerservice@sergiocapone.com",
            type: "notice",
            email: ConfigCapone.emailTest,
            subject: "Grazie per il suo acquisto",
            message: data
        };
        await httpPostFormaNoSignature(dataSend);
    }

    //*Peticiones put
    const putData = async (data: IFormCrmAtelier) => {
        setLoading(true);
        const response = await httpPutModifyClientCrm(data);
        if (response.id) {
            try {
                if (ConfigCapone.configTest && data.invioAuguri && data.sendEmailBirthday == "0") {
                    const titleClientSend = title.find(element => element.value == data.idTitle)
                    const emailMessage = formatMessageEmailBirthDay(titleClientSend?.label, clientSearch.name, clientSearch.country);
                    // postSendForma("", "", "", "", "", "Tanti auguri dalla Gioielleria Capone", "notice", emailMessage);
                }
                data.hobby.length > 0 && await putUpdateHobby(data.hobby, data.IdClient);
                data.prodottiPosseduti.length > 0 && await putProdduttoProsseduti(data.prodottiPosseduti, data.idTitle, data.IdClient);
                data.prodottoOsservati.length > 0 && await putProdottasservati(data.prodottoOsservati, data.IdClient);
                data.relazioni.length > 0 && await putRelazioni(data.relazioni, data.IdClient);
                await handleChangeOptionSelect(response.id);
                await postStatusCLient(data.IdClient, userInLogin.idUser);
                await refreshData(data.IdClient)
                setModalMessageWhitUrl({ active: true, message: "Modifica salvata con successo", route: "", title: "Informazione" })
            } catch (error) {
                setModalMessageWhitUrl({ active: true, message: error, route: "", title: "Si è verificato un errore, contatta l'assistenza" })
            } finally {
                setLoading(false);
            }
        }
    }

    const putUpdateHobby = async (hobby: string[], IdClient: string | number,) => {
        const hobbiesMap: Record<string, DataGetClienHobby> = {};
        console.log(hobby)
        for (const hobbyData of hobbyClient) {
            hobbiesMap[hobbyData.idHobby] = hobbyData;
        }
        for (const idHobby of hobby) {
            if (!hobbiesMap[idHobby]) {
                const dataSend: IFormHobby = {
                    IdClient: IdClient,
                    IdHobby: idHobby,
                }
                await httpPostHobby(dataSend);
            }
        }
        for (const hobbyData of hobbyClient) {
            if (!hobby.includes(hobbyData.idHobby)) {
                await httpDeletHobby(hobbyData.idClient_Hobby);
            }
        }

    }

    const putProdduttoProsseduti = async (data: IFormProdottiPosseduti[], IdTitle: string | undefined, IdClient: string) => {
        const titleClient = title.find(x => x.value == IdTitle);
        console.log('data', data)
        for (const item of data) {
            if (Array.isArray(item.SendEmail)) {
                item.SendEmail = true;
            }
            item.idClient = IdClient;
            if (item.idClientProdPosseduti != undefined) {
                item.Prezzo = Number(item.Prezzo);
                item.PrezzoApplicato = Number(item.PrezzoApplicato);
                await httpPutProduttoProsseduti(item);
            } else {
                console.log('item', item)
                item.Prezzo = Number(item.Prezzo);
                item.PrezzoApplicato = Number(item.PrezzoApplicato);

                if (item.isDiscount) {
                    let { response } = await httpGetMagazzinoByID(item.idMagazzino)
                    let updateMagazzino = {
                        idMagazzino: Number(response?.idMagazzino),
                        quantity: Number(response?.quantity) - 1,
                        negozio: Number(response?.idBussines),
                    }
                    await httpPutMagazzinoQuantity(updateMagazzino)
                    await postSendFormaVendita(item, updateMagazzino)
                    await postSaleAndUpdateMatricola(item, updateMagazzino)
                }
                await httpPostProduttoProciduti(item);
                /*if (ConfigCapone.configTest) {
                   await postSendForma(titleClient?.label, clientSearch?.name, item.Prodotto, "Atelier", clientSearch?.country, "Grazie per il suo acquisto");
                }*/
            }
            if (item.SendEmail) {
                let descBrand = brand?.find(x => Number(x.value) == Number(item.idBrand))
                const emailMessage = formatMessageEmailAtelier(titleClient?.label, clientSearch.name, descBrand.label, 'Atelier')
                await postSendFormaVenditaTemplate(emailMessage)
            }
        }
    }

    const putProdottasservati = async (data: IFormProdottoOsservati[], IdClient: string) => {
        for (const item of data) {
            item.idClient = IdClient;
            if (item.idClientProdAsservati != undefined) {
                await httpPutProdottoOsservati(item);
            } else {
                await httpPostClientProdasservati(item);
            }
        }
    }

    const putRelazioni = async (data: IFormRelazioni[], IdClient: string) => {
        for (const item of data) {
            item.idClient = IdClient;
            if (item.idClientRelationship != undefined) {
                await httpPutClientRelationship(item);
            } else {
                if (item.idRelationship != "") {
                    await httpPostClientRelationship(item);
                }
            }
        }
    }

    //*Peticiones Delete
    const deleteProdduttoProsseduti = async (idClientProdPosseduti: string | number) => {
        try {
            await httpDeleteProduttoProsseduti(idClientProdPosseduti);
        } catch (error) {
            throw new Error(error);
        }
    }

    const deleteProdottoOsservati = async (idClientProdAsservati: number | string) => {
        try {
            await httpDeleteProdottoOsservati(idClientProdAsservati);
        } catch (error) {
            throw new Error(error);
        }
    }

    const deleteClientRelationship = async (idClientRelationship: number | string) => {
        try {
            await httpDeleteClientRelationship(idClientRelationship);
        } catch (error) {
            throw new Error(error);
        }
    }

    //*helpers
    const formatOptions = (data: any[] = [], Id: string, text: string, icon?: string, concatText?: string) => {
        const response = data.map(item => {
            const option: optionValues = {
                value: item[Id],
                label: concatText ? `${item[text]} - ${item[concatText]}` : item[text],
                icon: item[icon],
            }
            return option;
        })
        return response;
    }

    const optionRelationShip = async (id: any) => {
        let option: number = 0
        switch (Number(id)) {
            // Moglie
            case 1:
                option = 2
                break;
            // Marito   
            case 2:
                option = 1
                break;
            // Madre - Padre
            case 3:
                option = 13
                break;
            // Zio-a
            case 4:
                option = 6
                break;
            // Fratello - Sorella
            case 5:
                option = 5
                break;
            // Nipote
            case 6:
                option = 4
                break;
            // Cugini
            case 7:
                option = 7
                break;
            // Suocero-a
            case 8:
                option = 10
                break;
            // Cognato-a
            case 9:
                option = 9
                break;
            // Genero - Nuora
            case 10:
                option = 8
                break;
            // Collega
            case 11:
                option = 11
                break;
            // Amico-a
            case 12:
                option = 12
                break;
            // Figlio-a
            case 13:
                option = 3
                break;
            // Compagno-a
            case 14:
                option = 14
                break;
        }
        return option
    }

    const verifyPhone = async (phone: string) => {
        const phoneNumber = getValues("Phone");
        if (phoneNumber) {
            const response = await FindClient(phoneNumber);
            const existPhone = response.some(item => item.phone == phoneNumber)
            if (existPhone) {

            }
        }
    }

    const addProductPosseduti = async (data: RouteProduttoProciduti) => {
        let product = {
            Data: FormatYYMMDD(new Date()),
            idBrand: data.idBrand,
            Matricola: data.matricola.split("-")[1],
            Prezzo: data.prezzo,
            PrezzoApplicato: data.prezzo,
            Referenza: data.referencia,
            Prodotto: data.desc_brand + " " + data.referencia,
            idMagazzino: data.idMagazzino,
            idMatricola: data.matricola.split("-")[0],
            idReference: data.idReference,
            isDiscount: true
        }
        append(product)
        if (clientSearch?.idClient) {
            let dataProd = [...fields, product]
            await putProdduttoProsseduti(dataProd, clientSearch?.idTitle, clientSearch?.idClient)
            await refreshProductPosseduti(clientSearch?.idClient)
        }
    }

    const refreshProductPosseduti = async (idClient: number | string) => {
        const { response } = await httpGetProduttoProciduti(idClient);
        console.log(response);
        const dataProdotti = response?.map((item) => {
            const data: IFormProdottiPosseduti = {
                Data: FormatYYMMDD(item.data),
                idBrand: item.idBrand,
                idClient: item.idClient,
                Matricola: item.matricola,
                Prezzo: item.prezzo,
                PrezzoApplicato: item.priceApplied,
                Prodotto: item.prodotto,
                Referenza: item.referenza,
                SendEmail: item.sendEmail,
                idClientProdPosseduti: item.idClientProdPosseduti,
                idMatricola: item.idMatricola,
                isDiscount: false
            }
            return data;
        })
        remove()
        prepend(dataProdotti, { shouldFocus: false });
    }

    const handleProceduti = async (data: RouteProduttoProciduti, opt: enJoinMagazzinoCrm) => {
        console.log(data)
        console.log(opt)
        switch (opt) {
            case enJoinMagazzinoCrm.produttoProceduti:
                await addProductPosseduti(data)
                break;
            case enJoinMagazzinoCrm.produtoOsservati:
                appendPO({
                    description: data.referencia + " - " + data.desc_brand + " - " + data.desc_bussines,
                    idMatricola: data.matricola.split("-")[0],
                })
                break;
            case enJoinMagazzinoCrm.newClientProduttoProceduti:
                await addProductPosseduti(data)
                break;
            default:
                break;
        }

    }


    const refreshData = async (idClient: number | string) => {
        await handleChangeOptionSelect(idClient);
    }

    useEffect(() => {
        if(fields && fields.length > 0){
          let totalPrice = 0;
          fields.forEach(item => {
              const prezzo = (item.PrezzoApplicato && Number(item.PrezzoApplicato) > 0)  ? Number(item.PrezzoApplicato) : Number(item.Prezzo);
              totalPrice += prezzo;
          });
          setTotalProductPosseduti(totalPrice)
        }
      }, [fields])

    useEffect(() => {
        handleData();
        if (clear != undefined && clear == enOptonNavigate.clearForm) {
            if(option > 2 ) {
                reset()
            };
        }
        if (IdClientEdit != undefined) {
            handleChangeOptionSelect(IdClientEdit);
        }
        if (dataProciduti != undefined) {
            handleProceduti(dataProciduti, option)
        }
    }, [dataProciduti || IdClientEdit])


    return {
        //*States
        errors, fields, showMano, fieldsPO, showProdottoOsservati, fieldR, title, gender, addressType, phtoneType, bussines, hobby, relationShip, brand, listClients,
        starts, startsSelect, clientSearch, listSearchClientTableCrm, statusList, findByClientLimitList, modalMessageWhitUrl, loading, listClientsR, edit,
        setListSearchClientTableCrm,totalProductPosseduti,
        //*Handlers
        register, handleSubmit, onSubmit, append, handleRemoveProduttoProsseduti, handleShowMano, handleremoveProdottiOsservati, appendPO, handleShowProdottoOsservati,
        appendR, handleRemoveRelazioni, handleChangeOptionSelect, watch, handleSelectStarts, handleConfirm, handleChangeRelazioni, handleSelectRelazioni, setValue,
        handleResetListClient
    }
}

export default useCrmAtelierForm 