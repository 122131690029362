import React, { useState } from 'react'
import { Image, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native'

interface Props {
    placeholder?: string,
    value?: string,
    setValue?: any,
    onChange?: ()=> void,
    onPress?: ()=> void,
    marginRight?: boolean,
    handleSearchFilter?: () => void,
    searchInputClient?:any,
    onChangeText?: () => void
}

export const SearchBox = ({placeholder='Cerca...', value, setValue, marginRight=false, handleSearchFilter, onChangeText}: Props) => {

  return (
    <div className="d-flex justify-content-center px-0">
        <TextInput
            placeholder={placeholder}
            style={[
                stylesRepair.input,
                stylesRepair.searchText
            ]}
            onChange={(e:any)=> setValue && setValue(e.target.value)}
            onChangeText={()=> onChangeText && onChangeText()}
            value={value}
        />
        <TouchableOpacity
            onPress={() => handleSearchFilter && handleSearchFilter()}
            style={{
            margin: marginRight ? "0" : "auto",
            marginRight: marginRight ? -12 : 0
            
            }}
        >
            <View style={[stylesRepair.viewDisable]}>
                <Image
                    style={[stylesRepair.searchIcon]}
                    source={require("../assets/icons/icon-search.png")}
                />
            </View>
        </TouchableOpacity>
    </div>
  )
}

const stylesRepair = StyleSheet.create({
    input: {
      height: 45,
      width: "110%",
      borderWidth: 2,
      fontFamily: "Arsapia Regular font",
      padding: 6,
      borderRadius: 50,
      marginRight: "1%",
    },
    searchText: {
        alignSelf: "flex-end", 
        borderColor: "#A4A4A4", 
        paddingRight: 2, 
        paddingLeft: 15, 
        fontSize: 20
    },
    searchIcon: {
        width: 20,
        height: 20,
        tintColor: "white",
        alignSelf: "center",
    },
    viewDisable: {
        backgroundColor: "black",
        borderRadius: 50,
        justifyContent: "center",
        width: 40,
        height: 40,
        marginTop: "2%",
    },
});
