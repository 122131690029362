import { useAditional } from "../../../hooks/formRepair/useAditional";
import { WrapperSteps } from "./WrapperSteps";

export const Adittional = () => {
  const { formik, image, handleFile } = useAditional();
  return (
    <WrapperSteps>
      <div className="col-lg-4 col-md-4">
        <div className="custom-file-container">
          <label className="custom-file-container__custom-file">
            <input
              type="file"
              className="custom-file-container__custom-file__custom-file-input"
              accept="image/*"
              onChange={handleFile}
            />
            <input type="hidden" name="MAX_FILE_SIZE" defaultValue={10485760} />
            <span className="custom-file-container__custom-file__custom-file-control">
              {formik.values.file && formik.values.file.name}
            </span>
          </label>
          <div
            className="custom-file-container__image-preview"
            style={{
              backgroundImage: `url(${image})`,
            }}
          ></div>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 offset-lg-1">
        <div className="form-group mb-4">
          <label htmlFor="">Note </label>
          <textarea
            rows={10}
            {...formik.getFieldProps("note")}
            className="form-control"
          ></textarea>
        </div>
      </div>
    </WrapperSteps>
  );
};
