import { useNavigation } from "@react-navigation/native";
import { NavigationProps } from "../../navigator/NavigatorConfig";

export const FooterCelender = () => {
  const navigation = useNavigation<NavigationProps>();
  return (
    <div className="d-flex justify-content-end mt-2">
      <button
        onClick={() => navigation.navigate("CalenderDetailScreen")}
        type="button"
        className="px-3 text-white "
        style={{textDecoration:'none',background:'#66d19f',borderRadius:'10px', cursor:'pointer', fontSize:'.8em',marginTop:'.5em',border:'none',outline:'none'}}
      >
        Apri
      </button>
    </div>
  );
};
