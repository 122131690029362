import React from "react";
import { Collapse } from "react-collapse";
import { useShow } from "../../hooks/useShow";
import { NegozioResponse } from "../../interfaces/magazzino";
import { CheckMagazzino } from "./CheckMagazzino";
import { CollapseTitle } from "./CollapseTitle";

interface Props {
  bussinesList: NegozioResponse[];
  handleFilterNegozio: (value: number) => void;
}
export const FilterMagazzinoNegozio = ({
  bussinesList,
  handleFilterNegozio,
}: Props) => {
  const { show, toggle } = useShow();
  return (
    <>
      <CollapseTitle title="Negozio    " toggle={toggle} />
      <Collapse isOpened={show}>
        <div className="col-12">
          {bussinesList.map(({ idBussines, desc_Bussines }) => (
            <div key={idBussines}>
              <CheckMagazzino
                name={desc_Bussines}
                id={idBussines}
                text={desc_Bussines}
                marginleft
                handlechange={handleFilterNegozio}
              />
              <br />
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};
