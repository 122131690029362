import { useEffect, useState } from "react"
import { optionValues } from "../../../components/ComponentsAtelier/inputs/inputComponent"
import { httpGetBrand, httpGetClient, httpGetClientById, httpGetTitle } from "../../CRM/Atelier/services/CrmAtelierFormServices"
import { IClient } from "../../../interfaces/IClient"
import { useForm, useFieldArray, Control } from 'react-hook-form';
import { useAuth } from "../../../hooks/useAuth";

export const useRepairAtelier = () => {
    const { user } = useAuth();
    const [title, setTitle] = useState<optionValues[]>([])
    const [brand, setBrand] = useState<optionValues[]>([]);
    const [listClients, setListClients] = useState<optionValues[]>([])
    const [clients, setClients] = useState<IClient[]>([]);

    const { register, handleSubmit, watch, setValue, formState: { errors }, control, reset } = useForm();

    const formatOptions = (data: any[] = [], Id: string, text: string, icon?: string, concatText?: string) => {
        const response = data.map(item => {
            const option: optionValues = {
                value: item[Id],
                label: concatText ? `${item[text]} - ${item[concatText]}` : item[text],
                icon: item[icon],
            }
            return option;
        })
        return response;
    }

    const handleChangeLimitClient = async (evt: React.ChangeEvent<HTMLInputElement>,) => {
        const { value, } = evt.target;
        if (value !== "") {
            const response = await httpGetClient(value);
            setListClients(formatOptions(response.response, "idClient", "name", undefined, "phone"));
            setClients(response.response);        
        } else {
            reset();
            setListClients([]);
        }
    }

    const deafultValues = () => {
        setValue("business", 'Atelier');
        setValue("operatore", user.name);
    }

    const handleData = async () => {
        try {
            const response = await Promise.all([
                httpGetTitle({}),
                httpGetBrand({}),
            ]);
            const [titleData,brandData] = response;
            setTitle(formatOptions(titleData.response, "idTitle", "desc_Title"));
            let responseBrand = brandData?.response?.filter((data: any) => data.desc_Brand != "Usato");
            setBrand(formatOptions(responseBrand, "idBrand", "desc_Brand"));

            console.log(brandData);

        } catch (error) {
            throw new Error(error);
        } finally {
            console.info("Finalized Petitions");
        }
    }

    const handleChangeOptionSelect = async (id: string | number) => {
        const { response: client } = await httpGetClientById(id);
        console.log(client);
    }

    useEffect(() => {
        deafultValues();
        handleData();
    }, [])

    return {
        title,
        brand,
        register,
        errors,
        handleChangeLimitClient,
        listClients,
        handleChangeOptionSelect
    }

}
