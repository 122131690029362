import React from "react";
import { StyleSheet, Text } from "react-native";

interface Props {
  name: string;
  color: string;
  value: number;
}

export const LabelStatisticheRepairs = ({ name, color, value }: Props) => {
  return (
    <div className="col-12 mb-2 justify-content-center text-center" style={{ paddingBottom: 20 }}>
        <Text style={[styles.value, { color: color }]}>{value}%</Text>
        <div className="d-flex justify-content-center">
          <div
            style={{
              width: 20,
              height: 20,
              backgroundColor: color,
              borderRadius: 100,
              marginRight: 10,
              marginLeft: 2,
              marginTop: 2,
            }}
          ></div>
          <Text style={[styles.valueText]}>{name}</Text>
        </div>
    </div>
  );
};

const styles = StyleSheet.create({
  value: {
    fontWeight: "bold",
    fontSize: 14,
  },
  valueText: {
    fontSize: 12,
  },
});
