import { NativeStackScreenProps } from '@react-navigation/native-stack'
import React, { ButtonHTMLAttributes, ImgHTMLAttributes } from 'react'
//import icono from '../../assets/images/SergioCaponeLogo.jpg'
import '../../styles/BtnCaricaCerca.css'
import { useNavigation } from '@react-navigation/native';
import { NavigationProps } from '../../navigator/NavigatorConfig';


interface Props {
    title: string;
    component: any;
    icono: any;
    propsImage?: ImgHTMLAttributes<HTMLImageElement>;
    propsButton?: ButtonHTMLAttributes<HTMLButtonElement>
    route: any;
}

const AggiungiCercaContainer = ({ component, icono, route, title, propsImage, propsButton }: Props) => {
    const navigation = useNavigation<NavigationProps>();

    return (
        <>
            <div className='row'>
                <div className='col-md-12 d-md-block d-lg-none'>
                    <div className="container-movile-atelier" >
                        <button onClick={() => navigation.navigate(component)} {...propsButton}>
                            <img className='container-movile-atelier-img' src={icono} alt="" {...propsImage} />
                            {title}
                        </button>
                    </div>
                </div>

                <div className="container-btn col-lg d-none d-lg-flex" >
                    <button onClick={() => navigation.navigate(component)} {...propsButton}>
                        <img className='container-btn-img' src={icono} alt="" {...propsImage} />
                        {title}
                    </button>
                </div>
            </div>
        </>
    )
}


export default AggiungiCercaContainer