import React from "react";
import { Notify } from "../../interfaces/Notify";
import { NotifyItem } from "./NotifyItem";
import '../../styles/Notify.css'
import '../../styles/PinTriangular.css'
interface Props {
  notify: Notify[];
  items: Notify[];
}
export const ContentNotify = ({ notify, items }: Props) => {


  console.log(notify)
  return (
    <div className="position-relative">
      <div className="triangulo"></div>
      <div className={"buble-notify d-flex flex-column justify-content-between align-items-center  w-100"} style={{padding:'1rem 1.3rem'}}>
        {notify.length > 0 ? (
          <>
            {notify.map(({ id, subject, user,date }) => (
              <NotifyItem
                key={id}
                title={user}
                detail={subject}
                id={id}
                items={items}
                date={date}
              />
            ))}
          </>
        ) : (
          <h4 className="text-center mt-4 mb-4 fs-6">Nessuna notifica</h4>
        )}
      </div>
    </div>
  );
};
