import React from 'react'
import { FlatList, Pressable, View } from 'react-native'
import { GetItemClientAtelier } from './GetItemClientAtelier';


export const SuggestionsCrmClientAtelierReferenzia = ({ autosuggestInput, setSearchInput, setAutosuggestInput }) => {
  return (
    <div>
      <View
        style={{
          borderWidth: 1,
          marginTop: -15,
          paddingTop: 15,
          marginLeft: 2,
          marginRight: 42,
          borderTopWidth: 0,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        }}
      >
        <FlatList
          data={autosuggestInput}
          showsVerticalScrollIndicator={false}
          renderItem={({ item, index }) => (
            <Pressable
              style={({ pressed }) => [
                { opacity: pressed ? 0.5 : 1 },
              ]}
              onPress={() => {
                setSearchInput(item.referenza),
                  setAutosuggestInput([]);
              }}
            >
              {GetItemClientAtelier(item.referenza)}
            </Pressable>
          )}
          keyExtractor={(item, index) =>
            item.idClientProdPosseduti
          }
        />
      </View>
    </div>

  )
}
