import React from "react";
import { Collapse } from "react-collapse";
import { useShow } from "../../../../../hooks/useShow";
import { CollapseTitleAtelier } from "../../../../Magazzino/Atelier/views/CollapseTitleAtelier";
import { CheckAtelier } from "./CheckAtelier";

interface Props {
  list: any[];
  handleFilter: (value: string) => void;
}
export const FilterCrmCheckboxSpendValue = ({
  list,
  handleFilter,
}: Props) => {
  const { show, toggle } = useShow();
  return (
    <>
      <CollapseTitleAtelier title={`Valore della\nspesa                  `} toggle={toggle} />
      <Collapse isOpened={show}>
        <div className="col-12">
          {list?.map(({ id, text }) => (
            <div key={id}>
              <CheckAtelier
                name={text}
                id={'spend-'+id}
                text={text}
                marginleft
                handlechange={handleFilter}
              />
              <br />
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};
