import React, { useState, useEffect } from 'react'
import { Collapse } from "react-collapse";
import { BiSquareRounded } from "react-icons/bi";
import { BsCheck2Circle, BsFillBellFill } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import useNews from '../../../components/News/hooks/useNews';

interface Props {
  title: string;
  text: string;
  setIsOpen;
  isOpen: boolean;
  isChecked: any;
  handleClick;
  id: string;
  idNewBussinesUsers: number;
  idUser: number;
  idNewBussines: number;
  GetListByIdNewBussines
  idNewUser: number;
  defaultListNews;
  setIsOpenFormEdit;
  isOpenFormEdit;
  setDataEdit;
  nameFile;
  getIdRole;
  setIsOpenFormEditDisabled;
  isOpenFormEditDisabled;
  nameImage
}
export const NewsDetails = ({
  text,
  title,
  setIsOpen,
  isOpen,
  isChecked,
  handleClick,
  id,
  idNewBussinesUsers,
  idUser,
  idNewBussines,
  GetListByIdNewBussines,
  idNewUser,
  setIsOpenFormEdit,
  isOpenFormEdit,
  setDataEdit,
  nameFile,
  defaultListNews,
  getIdRole,
  nameImage,
  setIsOpenFormEditDisabled,
  isOpenFormEditDisabled
}: Props) => {

  const [idRole, setIdRole] = useState()

  const formEditOpen = async (data) => {
    console.log("data", data)

    await defaultListNews(data);

    setIsOpenFormEdit(!isOpenFormEdit)
    let newEdit = {
      id: id,
      title: title,
      detail: text,
      nameFile: nameFile,
      nameImage: nameImage
    }
    setDataEdit(newEdit)
    //GetListByIdTaskBussines(data)
  }

  const formEditOpenDisabled = async (data) => {
    console.log("data", data)

    await defaultListNews(data);

    setIsOpenFormEditDisabled(!isOpenFormEditDisabled)
    let newEdit = {
      id: id,
      title: title,
      detail: text,
      nameFile: nameFile,
      nameImage: nameImage
    }
    setDataEdit(newEdit)
    //GetListByIdTaskBussines(data)
  }



  useEffect(() => {
    getIdRole().then(e => {
      console.log(e)
      setIdRole(e)

    })
  }, [])



  const viewIsChecked = async (idNewBussines, idNewBussinesUsers) => {

    let data = {
      idNew: id,
      idNewBussines: id,
      idNewBussinesUsers: idNewBussinesUsers,
      idNewUser: idNewUser
    }


    setIsOpen(!isOpen)
    GetListByIdNewBussines(data)

    setIsOpen(!isOpen)

  }

  return (
    <div className="card mb-2">
      <div className="card-body">
        <div className="row">
          <div style={{ cursor: "pointer" }} className="col-6 notify-item" onClick={() =>
            formEditOpenDisabled(id)
          }>
            <p>
              <strong>{title}</strong>
            </p>
            <p>{text}</p>
          </div>
          <div className="col-6 d-flex justify-content-end">
            {(idRole == 2 || idRole == 6 || idRole == 5 || idRole == 9) && (
              <div className="d-flex justify-content-center mt-2">
                <FaRegEdit size={28} style={{ 'marginRight': '1em', cursor: "pointer" }} onClick={() => formEditOpen(id)} />
              </div>
            )}
            {/* { (idRole == 3 || idRole == 4 || idRole == 7 || idRole == 8 || idRole == 9) && (
          <div className="d-flex justify-content-center mt-2">
            <FaRegEdit size={28} style={{ 'marginRight': '1em', cursor: "pointer" }} onClick={() => formEditOpenDisabled (id)} />
          </div>
        )} */}
            <div className="mt-2">
              {isChecked == 0 ? (
                <BiSquareRounded
                  size={28}
                  style={{ marginRight: "1em", cursor: "pointer" }}
                  onClick={() =>
                    handleClick(
                      idNewBussinesUsers,
                      idUser,
                      idNewBussines,
                      idNewUser
                    )
                  }
                />
              ) : null}

              {/* <input  type="checkbox" id={id} value={id} style={{transform: 'scale(1.6)', 'margin-right':'1em' }} className="mt-3"  /> */}
            </div>
            <div className="d-flex justify-content-center mt-2">
              <BsCheck2Circle
                size={28}
                style={{ cursor: "pointer" }}
                onClick={() => viewIsChecked(idNewBussines, idNewBussinesUsers)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
