import { StyleSheet } from "react-native";

export const headerNewRepairStyle = StyleSheet.create({
    container: {
        height: 180,
        paddingTop: 50,
        paddingBottom: 30,
        marginBottom: 10,
        paddingHorizontal: 20,
        flexDirection: 'row',
        alignItems: 'center',
    },
    leftButtonContainer : {        
        flex: 0.6,
        left: 10,
        justifyContent: 'center',
    },
    leftButton: {
        width: 30,
        height: 30,
        alignSelf: 'flex-start',
        tintColor: 'black',
    },

    container2: {
        flex: 3, 
        
        flexDirection: 'row'
    },
    sectionIconContainer: {
        backgroundColor: 'black',
        borderRadius: 50,
        justifyContent: 'center',
        width: 55,
        height: 55,
    },
    sectionIcon: {
        width: 55,
        height: 55,
        //tintColor: 'white',
        // // alignSelf: 'center',
        // alignItems:'center'
    },
    sectionTextContainer: {
        paddingLeft: 12,
        width: "100%",
        marginTop: 8,
    },
    sectionText: {
        fontSize: 22,
        fontWeight: '500'
    },

    container3: { flex: 2, 
        backgroundColor: "white", 
        // justifyContent: 'flex-end'
    },
    sectionLogo: {
        width: 150,
        height: 50,
        // tintColor: 'black',
        right: 0,
        alignSelf: 'flex-end',
    }
});