import React, { useEffect, useState, useContext } from "react";
import { Navbar } from "../../../../components/Navbar";
import NavProfile from "../../../../components/NavProfile/NavProfile";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useProfile } from "../../../../components/NavProfile/hook/useProfile";
import ShowContentSidebar from "../../../../components/NavProfile/ShowContentSidebar";
import { useIsFocused } from "@react-navigation/native";
import { useAuth } from "../../../../hooks/useAuth";
import { SearchCrmAtelier } from './SearchCrmAtelier';
import { FilterAtelierOptions } from "../../../Magazzino/Atelier/views/FilterAtelierOptions";
import { TableCrmAtelier } from '../table/TableCrmAtelier';
import { useCrmAtelierList } from "../hook/useCrmAtelierList";
import { FilterCrmCheckboxGender } from './filters/FilterCrmCheckboxGender';
import { FilterCrmCheckboxStar } from "./filters/FilterCrmCheckboxStart";
import { FilterCrmCheckboxBrand } from "./filters/FilterCrmCheckboxBrand";
import { FilterCrmCheckboxSpendValue } from "./filters/FilterCrmCheckboxSpendValue";
import { FilterCrmCheckboxAge } from "./filters/FilterCrmCheckboxAge";
import { FilterCrmCheckboxHobby } from "./filters/FilterCrmCheckboxHobby";
import { FilterCrmCheckboxEntryDate } from "./filters/FilterCrmCheckboxEntryDate";
import { FilterCrmCheckboxUpdateDate } from "./filters/FilterCrmCheckboxUpdateDate";
import { FilterCrmCheckboxReferenza } from './filters/FilterCrmCheckboxReferenza';
import { FilterCrmAtelierDescrizione } from "./filters/FilterCrmAtelierDescrizione";
import { FilterCrmCheckboxOthers } from "./filters/FilterCrmAtelierOthers";
import PopUpMessageWithRoute from "../../../../components/PopUpMessageWithRoute";
import useExportExcelClientiAtelier from "../hook/useExportExcelClientiAtelier";
import { SuggestionsCrmClientAtelier } from "./SuggestionsCrmClientAtelier";
import { SuggestionsMagazzinoAtelierReferenzia } from "../../../Magazzino/Atelier/views/SuggestionsMagazzinoAtelierReferenzia";
import { SuggestionsCrmClientAtelierReferenzia } from "./SuggestionsCrmClientAtelierReferenzia";
import { FilterMagazzinoReferenza } from "../../../../components/MagazzinoRefactor/FilterMagazzinoReferenza";
import Loading from "../../../../components/Loading";
import PopupConfirmation from "../../../../components/PopupConfirmation";
import { useUserPermission } from "../../../../hooks/useUserPermission";


interface Props extends NativeStackScreenProps<any, any> { }

const ClientListAtelierScreen = ({ navigation, route }: Props) => {

    const { sidebarVisible, toggleSidebar } = useProfile()
    const isFocused = useIsFocused();

    const {
        listClient,
        listGender,
        listStar,
        listBrand,
        listSpendValue,
        listAge,
        listAllHobby,
        listEntryDate,
        listUpdateDate,
        listOthers,
        matricola,
        setMatricola,
        profession,
        setProfession,
        note,
        setNote,
        handleFilterGender,
        handleFilterStar,
        handleFilterBrand,
        handleFilterSpendValue,
        handleFilterAge,
        handleFilterHobby,
        handleFilterEntryDate,
        handleFilterUpdateDate,
        searchInputReferenza,
        onChangeTextReferenza,
        handleSearch,
        handleSearchDescrizione,
        handleFilterOthers,
        paginationList,
        currentPage,
        setcurrentPage,
        setResetCurrentPage,
        limit,
        modaleMessaggeWithUrl,
        setmodaleMessaggeWithUrl, 
        confirmAction,
        dataExcelClient,
        setLoading,
        onChangeTextClient,
        searchInputClient,
        activeSuggestion,
        autosuggestInputClient,
        setSearchInputClient,
        setAutosuggestInputClient,
        handleSearchFilter,
        activeSuggestionReferenza,
        autosuggestInputReferenza,
        setAutosuggestInputReferenza,
        setSearchInputReferenza,
        loading,
        confirmToDelete,
        modal,
        setModal,
        idClientDelete,
        confirmToDeleteTrue,
        emailModal,
        updatePage,
    } = useCrmAtelierList();

    const { donwnloadExcel, DataSet, handleDataExport, ExcelFile, ExcelSheet, nameFileExport } = useExportExcelClientiAtelier(dataExcelClient, setLoading)
    const { isAdmin, isMarketing } = useUserPermission()


    return (
        <>
            {loading && <Loading activeSpinner={loading} />}
            <ShowContentSidebar
                sidebarVisible={sidebarVisible}
                toggleSidebar={toggleSidebar}
            />
            <div className="container-layout">
                <div className="row row-layout">
                    <div className={` ${sidebarVisible ? 'col-lg-3 col-sm-12 d-block' : 'col-lg-3 col-md-none d-none d-md-non d-lg-block'} sidebar-container-layout sidebar-transition`}>
                        <Navbar activeNav={isFocused} componentName={route.name} componentDad="CrmAtelier" />
                    </div>
                    <div
                        className="row col-lg-9 col-md-12 col-sm-12 content-container-layout"
                        style={{ overflowY: 'auto', scrollbarWidth: 'thin', display: 'unset' }}
                    >
                        <div className="col-lg-12 p-0">
                            <NavProfile
                                showMenuBack={route.name !== 'CrmAtelier'}
                                sidebarVisible={sidebarVisible}
                                toggleSidebar={toggleSidebar}
                                goBack="CrmAtelier"
                                goBackName="Crm Atelier"

                            />
                        </div>
                        <div className="col-lg-12 p-0">
                            <SearchCrmAtelier
                                searchInputClient={searchInputClient}
                                onChangeTextClient={onChangeTextClient}
                                handleSearchFilter={handleSearchFilter}
                            />
                            {activeSuggestion &&
                                searchInputClient &&
                                autosuggestInputClient.length > 0 ? (
                                <SuggestionsCrmClientAtelier
                                    autosuggestInput={autosuggestInputClient}
                                    setSearchInput={setSearchInputClient}
                                    setAutosuggestInput={setAutosuggestInputClient}
                                />
                            ) : null}
                        </div>
                        {(isAdmin || isMarketing) && 
                            <div className="col-lg-12 p-0 gap-2 d-flex mt-2 justify-content-end" >
                                <button className="button-container text-truncate m-0" style={{ minWidth: 160, }} onClick={() => { navigation.navigate("PrintLabelScreen", dataExcelClient) }}>Stampa etichette</button>
                                {
                                    (donwnloadExcel &&
                                        DataSet[0].data.length > 0) && (
                                        <ExcelFile filename={nameFileExport} hideElement>
                                            <ExcelSheet dataSet={DataSet} name="Lista clienti atelier" />
                                        </ExcelFile>
                                    )
                                }
                                <button className="button-container text-truncate m-0" type="button" style={{ minWidth: 150, }} onClick={() => { handleDataExport() }}>Esporta lista</button>
                            </div>
                        }
                        <div className="col-lg-12 p-0 mt-2" style={{ overflowX: 'hidden' }}>
                            <div className="row g-0" style={{}}>

                                { (isAdmin || isMarketing) && 
                                    <div className="col-lg-3 col-md-3 col-sm-12 p-0 d-flex flex-column align-items-end">
                                        <div className="d-flex w-100 justify-content-center ">
                                            <div className="" style={{ width: '90%', marginTop: 16 }}>
                                                <FilterAtelierOptions />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column w-100 align-items-center">
                                            <div className="w-md-100 w-sm-50" >
                                                <FilterCrmCheckboxGender
                                                    list={listGender}
                                                    handleFilter={handleFilterGender}
                                                />
                                                <FilterCrmCheckboxStar
                                                    list={listStar}
                                                    handleFilter={handleFilterStar}
                                                />

                                                <FilterCrmCheckboxBrand
                                                    list={listBrand}
                                                    handleFilter={handleFilterBrand}
                                                />

                                                <FilterCrmCheckboxSpendValue
                                                    list={listSpendValue}
                                                    handleFilter={handleFilterSpendValue}
                                                />

                                                <FilterCrmCheckboxAge
                                                    list={listAge}
                                                    handleFilter={handleFilterAge}
                                                />

                                                <FilterCrmCheckboxHobby
                                                    list={listAllHobby}
                                                    handleFilter={handleFilterHobby}
                                                />

                                                <FilterCrmCheckboxEntryDate
                                                    list={listEntryDate}
                                                    handleFilter={handleFilterEntryDate}
                                                />

                                                <FilterCrmCheckboxUpdateDate
                                                    list={listUpdateDate}
                                                    handleFilter={handleFilterUpdateDate}
                                                />

                                                <FilterCrmCheckboxReferenza
                                                    searchInputReferenza={searchInputReferenza}
                                                    onChangeTextReferenza={onChangeTextReferenza}
                                                    handleSearch={handleSearch}
                                                />

                                                <div className="col-12 " >
                                                    {activeSuggestionReferenza &&
                                                        searchInputReferenza &&
                                                        autosuggestInputReferenza.length > 0 ? (
                                                        <SuggestionsCrmClientAtelierReferenzia
                                                            autosuggestInput={autosuggestInputReferenza}
                                                            setSearchInput={setSearchInputReferenza}
                                                            setAutosuggestInput={setAutosuggestInputReferenza}
                                                        />
                                                    ) : null}
                                                </div>

                                                <FilterCrmAtelierDescrizione
                                                    searchInput={matricola}
                                                    onChange={setMatricola}
                                                    handleSearch={handleSearchDescrizione}
                                                    title={"Matricola            "}
                                                />

                                                <FilterCrmAtelierDescrizione
                                                    searchInput={profession}
                                                    onChange={setProfession}
                                                    handleSearch={handleSearchDescrizione}
                                                    title={"Professione         "}
                                                />

                                                <FilterCrmAtelierDescrizione
                                                    searchInput={note}
                                                    onChange={setNote}
                                                    handleSearch={handleSearchDescrizione}
                                                    title={"Note                    "}
                                                />

                                                <FilterCrmCheckboxOthers
                                                    list={listOthers}
                                                    handleFilter={handleFilterOthers}
                                                />
                                            </div>

                                        </div>

                                    </div>
                                }
                                <div  className={(isAdmin || isMarketing) ? "col-lg-9 col-md-9 col-sm-12 p-0" : "col-12 p-0"} style={{ overflowX: 'auto' }}>
                                    <TableCrmAtelier
                                        data={listClient}
                                        paginationList={paginationList}
                                        currentPage={currentPage}
                                        updatePage={updatePage}
                                        limit={limit}
                                        confirmToDelete={confirmToDelete}
                                        emailModal={emailModal}
                                        navigation={navigation}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {modal && (
                <PopupConfirmation
                    title={""}
                    message={"Sei sicuro di voler rimuovere il cliente?"}
                    confirmAction={() =>
                        confirmToDeleteTrue(idClientDelete)
                    }
                    stateModal={modal}
                    setStateModal={setModal}
                />
            )}
            {modaleMessaggeWithUrl.active && (
                <PopUpMessageWithRoute
                    dataNav={[]}
                    title={modaleMessaggeWithUrl.title}
                    message={modaleMessaggeWithUrl.message}
                    stateModal={modaleMessaggeWithUrl.active}
                    setStateModal={setmodaleMessaggeWithUrl}
                    confirmAction={confirmAction}
                />
            )}

        </>

    );
};

export default ClientListAtelierScreen;
