import axios from "axios";
import ConfigCapone from "../utils/ConfigCapone";
import Cookies from "universal-cookie";
import aplicationConnect from "../api/applicationConnect";
//cookie
const cookies = new Cookies();
const apiInteressiClientDetails = `${ConfigCapone.urlapi}/api/ListInteressiClient`;
const limit = 21;
export const getAllInteressiClientDetails = async (parametros: any) => {
  try {
    ///api/ListInteressiClient/8?page=1&limit=5&name=s&isImportant=1
    const response = await axios.get(
      `${apiInteressiClientDetails}/${parametros.idList}?page=${parametros.page}&limit=${limit}&name=${parametros.name}&isImportant=${parametros.isImportant}`
    );
    const { data } = response;
    if (data.status === "success") {
      return response;
    }
  } catch (error) {
    throw new Error();
  }
};
///api/ListInteressiClient/8?limit=10&name=prueba
export const getAllSuggestionListInteressiClient = async (idList: number, name: string) => {
  try {
    const response = await axios.get(
      `${apiInteressiClientDetails}/${idList}?limit=10&name=${name}`
    );
    const { data } = response;
    if (data.status === "success") {
      return response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const deleteInteressiClientDetails = async (idList: number, idClient: number) => {
  try {
    const response = await axios.delete(
      `${ConfigCapone.urlapi}/api/ListInteressiClient/${idList}/${idClient}`, {
      headers: {
        'Authorization': `Bearer ${cookies.get('token')}`
      }
    }
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const interessiClientDetailsImportant = async (idClient: number, isImportant: number, userId: number) => {
  try {
    let datos = {
      idClient: idClient,
      isImportant: isImportant,
      userId: userId
    }
    const response = await aplicationConnect.put(`ListInteressiClient/ChangeImportant`, datos);
    const { data } = response;
    if (data.status === "success") {
      return response;
    }

  } catch (error) {
    throw new Error();
  }
};
export const interessiClientListReminder = async (dataId) => {
  try {

    const response = await aplicationConnect.get(`ListInteressiClient/FindByIdClient/` + dataId);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};
export const interessiClientListReminderByID = async () => {
  try {

    const response = await aplicationConnect.get(`ListInteressiClient/ListInteressiReminderClientEmail?parameter=1`);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};
//api/ListInteressiClient/FindByIdClient/456
export const getInteressiByClient = async (idClient: number) => {
  try {
    const response = await axios.get(
      `${apiInteressiClientDetails}/FindByIdClient/${idClient}`
    );
    const { data } = response;
    if (data.status === "success") {
      return response;
    }
  } catch (error) {
    throw new Error();
  }
};

export const updateListInteressiClientFiledNote = async (id, idClient, newNote) => {
  try {
    console.log("UpdateListInteressiClientFiledNote: ", { id: id, idClient: idClient, note: newNote });
    const response = await aplicationConnect.post(
      'ListInteressiClient/UpdateListInteressiClientFiledNote',
      { id: id, idClient: idClient, note: newNote } // Ajusta la estructura del objeto según lo que espera tu servidor
    );
    const { data } = response;
    if (data.status === 'success') {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};