import { useEffect, useState,useContext, useRef } from "react";
import { UserContext } from "../context/UserContext";
import { IClient } from "../interfaces/IClient";
import { totals } from '../components/paymentsComponents/PayTable';
import ResizeObserver from 'resize-observer-polyfill';

import {
  AllList,
  BrandResponse,
  IMagazzino,
  MagazzinoResponse,
  NegozioResponse,
} from "../interfaces/magazzino";
import {
  listAllBrand,
  listAllMagazzino,
  listAllMagazzinoWhitSearch,
  listAllNegozio,
  DeleteReferenza,
  DeleteMagazzino,
  AllDeletematricolaByReference,
  FindByReference,
  SearchClient,
  GetDataClient,
  SearchMagazzinoSuggestions,
  SearchMagazzinoProdottoSuggestions,
  ExportListMagazzino
} from "../services/MagazzinoRefactor";

export const useMagazzino = () => {

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeSuggestion,setActiveSuggestion]=useState(true);
  const [activeSuggestionReferenza,setActiveSuggestionReferenza]=useState(true)
  const [reload, setReload] = useState(false)
  const [firstLoad, setFirstLoad] = useState(false);
  const [modal, setModal] = useState(false);
  const [IdStateProduct, setIdStateProduct] = useState(0);
  const [IdStateMagazzino, setIdStateMagazzino] = useState(0);
  const [activeBottom, setActiveBottom] = useState<boolean>(true);
  const [searchModalInputClient, setSearchModalInputClient] = useState<string>();
  const [
    autosuggestModalClientEsistenteInput,
    setAutosuggestModalClientEsistenteInput,
  ] = useState<IClient[]>([]);
  const [searchInputReferenza, setSearchInputReferenza] = useState<string>();
  const [autosuggestInputReferenza, setAutosuggestInputReferenza] = useState<IMagazzino[]>([]);
  const [searchInputDescrizione, setSearchInputDescrizione] = useState<string>();
  const [searchInputMisura, setSearchInputMisura] = useState<string>();
  const [searchInputColore, setSearchInputColore] = useState<string>();
  const [searchInputMovimento, setSearchInputMovimento] = useState<string>();
  const [searchInputProdotto, setSearchInputProdotto] = useState<string>();
  const [autosuggestInputProdotto, setAutosuggestInputProdotto] = useState<any>([]);
  const [griglia, setgriglia] = useState<boolean>(false)
  const [modalVisibleMatricolaCard, setModalVisibleMatricolaCard] = useState(false);
  const [modalVisibleMatricolaAtelierCard, setModalVisibleMatricolaAtelierCard] = useState(false);
  //
  const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({
    title: "Informazione",
    message: "",
    active: false,
  });
  const { userInLogin } = useContext(UserContext);
  //Esporta Excel
  const [tableFilterCsv, setTableFilterCsv] = useState<any>([]);
  const [tableCsv, setTableCsv] = useState<any>([]);
  const [donwnloadExcel, setDonwnloadExcel] = useState<boolean>(false);
  const [dataset, setDataset] = useState<any>([]);
  const [dataExcelMagazzino,setDataExcelMagazzino]=useState<any>([]);
  //STATE FILTER
  const [filterBrand, setFilterBrand] = useState<number[]>([]);
  const [filterNegozio, setFilterNegozio] = useState<number[]>([]);
  const [filterQuantita, setFilterQuantita] = useState<number[]>([]);
  const [currentPage, setcurrentPage] = useState(1)
  const [modalVisibleCard, setModalVisibleCard] = useState(false);
  const [modalVisibleCardAtelier, setModalVisibleCardAtelier] = useState(false);
  const [statePagination, setStatePagination] = useState(false);
  const [dataMagazzino, setDataMagazzino] = useState<any[]>([]);
  const [listMatricola, setListMatricola] = useState<any[]>();
  const [listMagazzino, setListMagazzino] = useState<any[]>();
  const [listClient, setListClient] = useState<any[]>();
  const [findReference, setFindReference] = useState<any[]>();
  const [crmSend, setCrmSend] = useState("");

  const divRef = useRef(null);
  const [height, setHeight] = useState(0);

  const [resetCurrentPage, setResetCurrentPage] = useState(false);
  const [modalQuadrante, setModalQuadrante] = useState({
    title: "Informazione",
    Misura: "",
    Colore:"",
    Movimento:"",
    active: false,
  });

  const limit:number = 52;

  const [paginationList, setPaginationList] = useState({
    currentPage: 1,
    total: 0,
    totalPages: 0,
  });

  //STATE DATA
  const [data, setData] = useState<AllList>({
    brandList: [],
    magazzinoList: [],
    negozioList: [],
  });
  const handleFilter = () => {
  };
  const handleChange = (value: string) => {
    setSearch(value);
  };
//ShowPopPup
const showSuccessPopup = (mess: string,tiTle:string) => {
  setmodaleMessaggeWithUrl({
    active: true,
    message: mess,
    title: tiTle,
  })
};
const confirmAction = (Landing: any, data: any) => {
  setmodaleMessaggeWithUrl({
    title: "Informazione",
    message: "",
    active: false,
  });
};
  //get info
  const handleData = async () => {

    let brandListFilter=[];
    // setReload(true);
    try {
      const response:any = await Promise.all([
        listAllMagazzino(currentPage,limit),
        listAllNegozio(),
        listAllBrand(),
      ]);

      const magazzinoList:any = response[0]?.response;
      const negozioList: NegozioResponse[] = response[1];
      const brandList: BrandResponse[] = response[2];
      setPaginationList({
        currentPage: response[0]?.currentPage,
        total:response[0]?.total,
        totalPages:response[0]?.totalPages
      })
      dataExcel(false,false)
      brandListFilter=brandList.filter((x)=>x.desc_Brand !='Rolex');
      setData({
        brandList: userInLogin.idRole=='9'?brandListFilter:brandList,
        magazzinoList: magazzinoList,
        negozioList: negozioList,
      });
      setListMagazzino(magazzinoList)
      setFirstLoad(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      // setReload(false);
    }
    
  };
  // ///

  const handleFilterBrand = (value: number) => {
    if (!value) return;
    const brand = Number(value);
    const isExits = filterBrand.find((elem) => elem === brand);
    if (!isExits) {
      setFilterBrand([...filterBrand, brand]);
    } else {
      setFilterBrand([...filterBrand.filter((elem) => elem !== brand)]);
    }
  };

  const handleFilterNegozio = (value: number) => {
    if (!value) return;
    const negozio = Number(value);
    const isExits = filterNegozio.find((elem) => elem === negozio);
    if (!isExits) {
      setFilterNegozio([...filterNegozio, negozio]);
    } else {
      setFilterNegozio([...filterNegozio.filter((elem) => elem !== negozio)]);
    }
  };
  
  const handleFilterQuantita = (value: number) => {
    if (!value === undefined) return;
    console.log(value)
    const quantita = Number(value);
    const isExits = filterQuantita.length > 0 ? [] : [quantita];
    setFilterQuantita(isExits);
    console.log("aqui",isExits)
  };

  //
  const confirmToDeleteTrue =async (idReference: number,idMagazzino :number) => {
    try {
      const response = await Promise.all([
        DeleteReferenza(idReference),
        DeleteMagazzino(idMagazzino),
        AllDeletematricolaByReference(idReference),
      ]);
      setFirstLoad(true);
      setModal(false);
      filtered();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const confirmToDelete = async (idReference: number,idMagazzino :number) =>{
    setIdStateProduct(idReference);
    setIdStateMagazzino(idMagazzino)
    setModal(true);
  }

  const goToSchedaProdotto = async (data: any, openModal: string) => {
    console.log('datavv',data);
    try {
      const response = await Promise.all([
        FindByReference(data?.idReference)
      ]);
      
      setListMatricola(response[0]);
      setDataMagazzino(data);
      if(openModal == "open"){
          Number(data.idBussines) != 9 
          ? setModalVisibleCard(true)
          : setModalVisibleCardAtelier(true)

      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  
  const goToSchedaQuadrante = async (data: any, openModal: string) => {
    try {
      console.log(data);
      console.log(openModal);

      setModalQuadrante({
        active: true,
        Misura: data.misuraQuadrante,
        Colore: data.coloreQuadrante,
        Movimento: data.tipoDiMovimento,
        title: "Informazione",
      })

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const closePopupInfo = () => {
    setModalQuadrante({
      active: false,
      Misura: "",
      Colore:"",
      Movimento:"",
      title: "",
    })
  };

  const handleSearchModalFilter = async () => {
    if (searchModalInputClient) {
      if (searchModalInputClient.length > 0) {
        try {
          const response = await Promise.all([
            SearchClient(searchModalInputClient)
          ]);
          setAutosuggestModalClientEsistenteInput(response[0]);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };
  const getDataClient = async () => { 
        try {
          const response = await Promise.all([
            GetDataClient()
          ]);
          setListClient(response[0]);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
  };
  const goToMatricola = async (data: any) => {
    console.log('data',data);
    try {
      const response = await Promise.all([
        FindByReference(data?.idReference)
      ]);
      setListMatricola(response[0]);
      setDataMagazzino(data);
      setModalVisibleMatricolaCard(true);

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeTextReferenza = async (name: string) => {
    if(name){
    try {
      setSearchInputReferenza(name)
      //setSearchInputReferenza(name)
      const response = await Promise.all([
        SearchMagazzinoSuggestions(name,"referencia")
      ]);
      setActiveSuggestionReferenza(true)
      let referenza = response[0]

      console.log("aqui dataY",referenza)
      if(referenza?.length > 0) {
        let resultData = referenza.reduce((elements, obj, index) => {
          let existingData = elements.find(
            (element) => element.referencia === obj.referencia
          );
          if (!existingData) {
            elements.push(obj);
          }
          return elements;
        }, []);

        setAutosuggestInputReferenza(resultData);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }else{
    setSearchInputReferenza("")
  }
  };
  //SearchMagazzinoProdottoSuggestions
  const onChangeTextProdotto = async (name: string) => {
    if(name){
    try {
      setSearchInputProdotto(name)
      const response = await Promise.all([
        SearchMagazzinoProdottoSuggestions(name)
      ]);
      setActiveSuggestion(true)
      let prodotto = response[0]

      
      // if(referenza?.length > 0) {
      //   let resultData = referenza.reduce((elements, obj, index) => {
      //     let existingData = elements.find(
      //       (element) => element.referencia === obj.referencia
      //     );
      //     if (!existingData) {
      //       elements.push(obj);
      //     }
      //     return elements;
      //   }, []);

       
      // }
      setAutosuggestInputProdotto(prodotto);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }else{
    setSearchInputProdotto("")
  }
  };
  //functionSearchProdotto
  const handleSearchFilter = async()=>{
    await filtered()
    setActiveSuggestion(false)
  }
  //functionSearchRefernza
  const handleSearch = async()=>{
    //if(searchInputReferenza){
      await filtered()
      setActiveSuggestionReferenza(false)
    //}
  }
  //Function search Descrizione/Misura/Colore/Movimento
  const handleSearchDescrizione = async()=>{
      await filtered()
  }
  //ExcelEsporta
  const dataExcel = async (selectedFilters: any, isfilter:boolean) => {

    if(isfilter){
      let mensaje = "Sequence contains no elements";
      let msg: any;
      selectedFilters.limit = 0
      let result:any = await listAllMagazzinoWhitSearch(selectedFilters)
      setDataExcelMagazzino(result?.response)
      selectedFilters.limit = 50
    }else{
      let results:any = await listAllMagazzino(1,0)
      setDataExcelMagazzino(results?.response)
    }
   
}
  //function filter
  const filtered = async () => {
    try {

      setReload(true);

      const filterActive =
        filterBrand.length > 0 ||
        filterNegozio.length > 0 ||
        filterQuantita.length > 0 || 
        searchInputReferenza ||
        searchInputProdotto || 
        searchInputDescrizione ||
        searchInputColore ||
        searchInputMisura ||
        searchInputMovimento ;
      
      if (filterActive) {
        if (resetCurrentPage){
          setResetCurrentPage(false)
        }else{
          setcurrentPage(1)
        }

        const results:any = await listAllMagazzinoWhitSearch({
          filterBrand: filterBrand,
          filterNegozio: filterNegozio,
          filterQuantita: filterQuantita.length>0?[0]:[1],
          Referenza:searchInputReferenza?searchInputReferenza:'',
          Descrizione:searchInputDescrizione?searchInputDescrizione:'',
          Colore:searchInputColore?searchInputColore:'',
          Misura:searchInputMisura?searchInputMisura:'',
          Movimento:searchInputMovimento?searchInputMovimento:'',
          Prodotto:searchInputProdotto?searchInputProdotto:'',
          limit:limit,
          page:(searchInputProdotto || searchInputReferenza)?1:currentPage,
        });
               
        console.log("results0",results);
        dataExcel({
          filterBrand: filterBrand,
          filterNegozio: filterNegozio,
          filterQuantita: filterQuantita.length>0?[0]:[1],
          Referenza:searchInputReferenza?searchInputReferenza:'',
          Descrizione:searchInputDescrizione?searchInputDescrizione:'',
          Colore:searchInputColore?searchInputColore:'',
          Misura:searchInputMisura?searchInputMisura:'',
          Movimento:searchInputMovimento?searchInputMovimento:'',
          Prodotto:searchInputProdotto?searchInputProdotto:'',
          limit:limit,
          page:currentPage
        },true)
        if(results?.response?.length > 0){

          setData({
            ...data,
            magazzinoList: [],
          });

          setData({
            ...data,
            magazzinoList: results?.response,
          })

          setTableCsv(results?.response)
          //change new
          setListMagazzino(results?.response)
          //change new end
          setPaginationList({
            currentPage: results?.currentPage,
            total:results?.total,
            totalPages:results?.totalPages
          })
        }else{
          showSuccessPopup("Non ci sono record.","Informazione")
          console.log("Non ci sono record.");     
        }

      } else {
        
        if (firstLoad) {
          console.log("activofalse")
          const results:any = await listAllMagazzino(currentPage,limit);
          dataExcel(false,false)

          console.log("results",results);
          
          setPaginationList({
            currentPage: results?.currentPage,
            total:results?.total,
            totalPages:results?.totalPages
          })
          
          setData({
            ...data,
            magazzinoList: [],
          });


          setData({
            ...data,
            magazzinoList: results?.response,
          })

          setTableCsv(results?.response)
          //changes new
          setListMagazzino(results?.response)
          //changes new end
          setResetCurrentPage(false)
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setReload(false);
    }
  };


  useEffect(() => {
    handleData();
    getDataClient();
  }, []);

  useEffect(() => {
    resetCurrentPage && filtered()
  },[currentPage])

  useEffect(() => {
    filtered();
  }, [filterBrand, filterNegozio, filterQuantita]);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          setHeight(entry.contentRect.height);
        }
      }
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }
    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);
 
  return {
    magazzinoList: data.magazzinoList,
    negozioList: data.negozioList,
    brandList: data.brandList,
    loading: loading || reload,
    handleFilter,
    handleFilterBrand,
    handleFilterNegozio,
    handleFilterQuantita,
    modal,
    handleSearch,
    setModal,
    IdStateProduct,
    IdStateMagazzino,
    confirmToDeleteTrue,
    confirmToDelete,
    goToSchedaProdotto,
    goToSchedaQuadrante,
    modalQuadrante,
    setModalQuadrante,
    modalVisibleCard,
    setModalVisibleCard,
    modalVisibleCardAtelier,
    setModalVisibleCardAtelier,
    dataMagazzino,
    listMatricola,
    listMagazzino,
    listClient,
    setListClient,
    activeBottom,
    searchModalInputClient,
    setSearchModalInputClient,
    handleSearchModalFilter,
    autosuggestModalClientEsistenteInput,
    setAutosuggestModalClientEsistenteInput,
    findReference,
    setFindReference,
    handleData,
    modalVisibleMatricolaCard,
    setModalVisibleMatricolaCard,
    goToMatricola,
    crmSend,
    paginationList,
    currentPage,
    setcurrentPage,
    limit,
    setResetCurrentPage,
    searchInputReferenza,
    setSearchInputReferenza,
    onChangeTextReferenza,
    onChangeTextProdotto,
    autosuggestInputReferenza,
    setAutosuggestInputReferenza,
    autosuggestInputProdotto,
    setAutosuggestInputProdotto,
    searchInputProdotto,
    setSearchInputProdotto,
    handleSearchFilter,
    setLoading,
    dataExcelMagazzino,
    filtered,
    showSuccessPopup,
    modaleMessaggeWithUrl,
    setmodaleMessaggeWithUrl,
    confirmAction,
    activeSuggestion,
    activeSuggestionReferenza,
    searchInputDescrizione,
    handleSearchDescrizione,
    searchInputMisura, 
    setSearchInputDescrizione,
    setSearchInputMisura,
    searchInputColore, 
    setSearchInputColore,
    searchInputMovimento, 
    setSearchInputMovimento,
    setgriglia,
    griglia,
    heightResize: height,
    divRef
  };
};


