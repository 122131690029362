import React, { useState, useEffect } from 'react'
import { Collapse } from "react-collapse";
import { BiSquareRounded } from "react-icons/bi";
import { BsCheck2Circle, BsFillBellFill } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

interface Props {
  title: string;
  text: string;
  setIsOpen;
  isOpen: boolean;
  isChecked: any;
  handleClick;
  id: string;
  idTaskBussinesUsers: number;
  idUser: number;
  idTaskBussines: number;
  GetListByIdTaskBussines
  viewIsChecked;
  idTaskUser: number;
  setIsOpenFormEdit;
  isOpenFormEdit;
  defaultListTask;
  setDataEdit;
  defaultListNews;
  nameFile;
  getIdRole;
  isOpenFormEditDisabled;
  setIsOpenFormEditDisabled;
  nameImage
}
export const NewsDetails = ({
  text,
  title,
  GetListByIdTaskBussines,
  setIsOpen,
  isOpen,
  isChecked,
  handleClick,
  id,
  idTaskBussinesUsers,
  idUser,
  idTaskBussines,
  idTaskUser,
  setIsOpenFormEdit,
  isOpenFormEdit,
  setDataEdit,
  nameFile,
  isOpenFormEditDisabled,
  setIsOpenFormEditDisabled,
  defaultListTask,
  getIdRole,
  nameImage
}: Props) => {

  const [idRole, setIdRole] = useState()

  const formEditOpen = async (data) => {
    setIsOpenFormEdit(!isOpenFormEdit)
    await defaultListTask(data)
    //GetListByIdTaskBussines(data)
    let newEdit = {
      id: id,
      title: title,
      detail: text,
      nameFile: nameFile,
      nameImage: nameImage
    }
    setDataEdit(newEdit)

  }

  const formEditOpenDisabled = async (data) => {
    setIsOpenFormEditDisabled(!isOpenFormEditDisabled)
    await defaultListTask(data)
    //GetListByIdTaskBussines(data)
    let newEdit = {
      id: id,
      title: title,
      detail: text,
      nameFile: nameFile,
      nameImage: nameImage
    }
    setDataEdit(newEdit)

  }

  const viewIsChecked = async (idTaskBussines, idTaskBussinesUsers) => {
    let data = {
      idTask: id,
      idTaskBussines: id,
      idTaskBussinesUsers: idTaskBussinesUsers,
      idTaskUser: idTaskUser
    }


    setIsOpen(!isOpen)
    GetListByIdTaskBussines(data)

  }

  useEffect(() => {
    getIdRole().then(e => {
      setIdRole(e)

    })
  }, [])




  return (
    <div className="card mb-2">
      <div className="card-body">
        <div className="row">
          <div style={{ cursor: "pointer" }} onClick={() =>
            formEditOpenDisabled(id)
          } className="col-6">
            <p>
              <strong>{title}</strong>
            </p>
            <p>{text}</p>
          </div>
          <div className="col-6 d-flex justify-content-end">
            {(idRole == 2 || idRole == 6 || idRole == 5 || idRole == 9) && (
              <div className="d-flex justify-content-center mt-2">
                <FaRegEdit size={28} style={{ 'marginRight': '1em', cursor: "pointer" }} onClick={() => formEditOpen(id)} />
              </div>
            )}
            {/* { (idRole == 3 || idRole == 4 || idRole == 7 || idRole == 8 || idRole == 9) && (
            <div className="d-flex justify-content-center mt-2">
              <FaRegEdit size={28} style={{ 'marginRight': '1em', cursor: "pointer" }} onClick={() => formEditOpenDisabled (id)} />
            </div>
            )} */}
            <div className="mt-2">
              {isChecked == 0 ? (
                <BiSquareRounded
                  size={28}
                  style={{ marginRight: "1em", cursor: "pointer" }}
                  onClick={() =>
                    handleClick(
                      idTaskBussinesUsers,
                      idUser,
                      idTaskBussines,
                      idTaskUser
                    )
                  }
                />
              ) : null}

              {/* <input  type="checkbox" id={id} value={id} style={{transform: 'scale(1.6)', 'margin-right':'1em' }} className="mt-3"  /> */}
            </div>
            <div className="d-flex justify-content-center mt-2">
              <BsCheck2Circle
                size={28}
                style={{ cursor: "pointer" }}
                onClick={() => viewIsChecked(idTaskBussines, idTaskBussinesUsers)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
