import React from "react";
import { TouchableOpacity, Text, View, Image, TextInput } from "react-native";
import { Collapse } from "react-collapse";

import { stylesMagazzino } from "../../GeneralStyles/MagazzinoStyle";
import { useShow } from "../../hooks/useShow";
import { CollapseTitle } from "./CollapseTitle";
import { Suggestions } from "./Suggestions";

interface Props {
  handleSearch:any;
  searchInputReferenza: any;
  onChangeTextReferenza: any;
}

export const FilterMagazzinoReferenza = ({ searchInputReferenza, onChangeTextReferenza,handleSearch }: Props) => {

  const { show, toggle } = useShow();

  return (
    <>
      <CollapseTitle title="Referenza  " toggle={toggle} />
      <Collapse isOpened={show}>
        <div className="col-12 d-flex">
          <TextInput
            placeholder="Cerca..."

            style={[stylesMagazzino.input, stylesMagazzino.subSearch]}
            onChangeText={onChangeTextReferenza}
            value={searchInputReferenza}
          />
          <TouchableOpacity
            onPress={() => { handleSearch()}}
            style={{
              margin: "auto",
            }}
          >
            <View style={[stylesMagazzino.viewDisable, stylesMagazzino.search]}>
              <Image
                style={stylesMagazzino.subIconSearch}
                source={require("../../assets/icons/icon-search.png")}
              />
            </View>
          </TouchableOpacity>
        </div>
      </Collapse>
    </>
  );
};
