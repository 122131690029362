import React, { useState, useEffect, useContext } from "react";
import {
  SafeAreaView,
  StatusBar,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
  BackHandler,
} from "react-native";
import { HeaderBar } from "../components/HeaderBar";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParams } from "../navigator/NavigatorConfig";
import { IRepairData } from "../api/RepairData";
import { styles } from "../theme/GlobalStyle";
import { SendNotificationScreen } from "./SendNotificationScreen";
import PopupConfirmation from "../components/PopupConfirmation";
import { SendEmailScreen } from "./SendEmailScreen";
import axios from "axios";
import { useIsFocused } from "@react-navigation/native";
import ConfigCapone from "../utils/ConfigCapone";
import Loading from "../components/Loading";
import PopupMessage from "../components/PopupMessage";
import { Controller, useForm } from "react-hook-form";
import "../theme/styleBackground.css";
import { Icon } from "react-native-elements";
import RepairCardScreen from "./RepairCardScreen";
import {
  getUserFromId,
  IClient,
  IServiceClient,
  IUser,
} from "../api/ClientService";
import { UserContext } from "../context/UserContext";
// @ts-ignore
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// @ts-ignorepage
import ReactExport from "react-data-export";
import Cookies from 'universal-cookie';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

import "../utils/styles.css";
import { todayFormatDate } from "../utils/helpers";
import { postNotify } from "../services/Notify";
import { useNotifyCrud } from "../hooks/useNotifyCrud";
import aplicationConnect from "../api/applicationConnect";
import { SendEmailScreenTecnic } from "./SendEmailScreenTecnic";

interface Props
  extends NativeStackScreenProps<RootStackParams, "StateRepairScreen"> {

  isAtelier?: boolean;
}

export const StateRepairScreen = ({ route, navigation, isAtelier = false }: Props) => {
  const { userInLogin } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [activeBottom, setActiveBottom] = useState<boolean>(true);
  const [isEmployee, setIsEmployee] = useState<boolean>(
    userInLogin?.idRole === "4" ? true : false
  );
  const [isAdmin, setIsAdmin] = useState<boolean>(
    userInLogin?.idRole === "2" ? true : false
  );
  const [isTechnique, setIsTechnique] = useState<boolean>(
    (userInLogin?.idRole === "3" || userInLogin?.idRole === "7" || userInLogin?.idRole === "8") ? true : false
  );

  const [isTechniqueViareggio] = useState<boolean>(
    (userInLogin?.idRole === "3" || userInLogin?.idRole === "7" || userInLogin?.idRole === "8") && userInLogin?.idBussines === '3' ? true : false
  )

  const [isTechniquePisa] = useState<boolean>(
    (userInLogin?.idRole === "3" || userInLogin?.idRole === "7" || userInLogin?.idRole === "8") && userInLogin?.idBussines === '1' ? true : false
  )
  const [isDirectAtelier] = useState<boolean>(
    userInLogin?.idRole === "5"  && userInLogin?.idBussines === '9' ? true : false
  )

  const cookies = new Cookies();
  const [title, setTitle] = useState<string>("");
  const [arrayData, setArrayData] = useState<any>([]);
  const [stateRepair, setStateRepair] = useState<any>([]);
  const [number, setNumber] = useState(5);
  const [searchInput, setSearchInput] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });
  const [idStateRepair, setIdStateRepair] = useState(0);
  const [listUsers, setListUsers] = useState<any[]>();
  const [blockRight, setBlockRight] = useState<boolean>(false);
  const [blockLeft, setBlockLeft] = useState<boolean>(true);
  const [viewPagination, setViewPagination] = useState<boolean>(true);
  const [modalCorreo, setModalCorreo] = useState<boolean>(false);
  const [modalCorreoTecnic, setModalCorreoTecnic] = useState<boolean>(false);
  const [totalListUsers, setTotalListUsers] = useState<any[]>();
  const [userFromAPI, setUserFromAPI] = useState([]);
  const [navs, setNavs] = useState([]);
  const [apiBusiness, setApiBusiness] = useState([]);
  const [defaultBusiness, setDefaultBusiness] = useState("");
  const [apiState, setApiState] = useState([]);
  const [idTransform, setidTransform] = useState<number>(1);
  const [dataBussines, setDataBussines] = useState([]);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [activeSearch, setActiveSearch] = useState({ tipo: 0 });
  const [modalVisibleCard, setModalVisibleCard] = useState(false);
  const [operatore, setOperatore] = useState<IUser>();
  const [dataRepair, setDataRepair] = useState<IServiceClient>();
  const [dataClient, setDataClient] = useState<any>();
  const [userList, setUserList] = useState<any>();
  const [brandList, setBrandList] = useState<any>();
  const [dataTableSend, setDataTableSend] = useState<any>();
  const [nameFileExport, setNameFileExport] = useState<any>(
    "Tabela Stato riparazioni " + todayFormatDate
  );
  const [tableCsv, setTableCsv] = useState<any>([]);
  const [valueNegozio, setValueNegozio] = useState<string | undefined>();
  const [valueBrand, setValueBrand] = useState<string | undefined>();
  const { createdPosted } = useNotifyCrud();


  const getBrandList = async () => {
    const { data } = await axios(`${ConfigCapone.urlapi}/api/Brand?parameter=1`);
    const { response } = data
    setBrandList(response);
  }

  const {
    control,
    formState: { errors },
  } = useForm();

  const onBackPress = () => {
    navigation.navigate("HomeScreen");
    return true;
  };
  BackHandler.addEventListener("hardwareBackPress", onBackPress);
  const ListBrandFilter = async () => {
    const rolexTudorList = ["Rolex", "Tudor"];
    let noRolexTudorList = [];
    await axios(`${ConfigCapone.urlapi}/api/Brand?parameter=1`)
      .then((response) => {
        response.data.response.map(({ desc_Brand }) => {
          desc_Brand != 'Rolex' && desc_Brand != 'Tudor' && noRolexTudorList.push(desc_Brand);
        })
      })
      .catch((error) => console.log(error));

    let descProduct = "";
    if (userInLogin?.idRole === "7") {
      descProduct = `&descProduct=${rolexTudorList}`;
    } else if (userInLogin?.idRole === "8") {
      descProduct = `&descProduct=${noRolexTudorList}`;
    }
    return descProduct;
  }
  const serviceList = async () => {
    setActiveSpinner(true);
    //s.idBussines
    if (isEmployee || isDirectAtelier) {
      await axios
        .get(
          `${ConfigCapone.urlapi}/api/Client/GetClientTableV2?idBussines=${userInLogin.idBussines}`
        )
        .then((response) => {
          let result = response.data.response;

          isTechniquePisa && (result = result.filter((data: any) => ['1', '5', '2', '6'].includes(data.idBussines)));
          isTechniqueViareggio && (result = result.filter((data: any) => ['3', '9', '4'].includes(data.idBussines)));

          let dataFilter = filterBy72Hours(result);
          console.log(dataFilter)
          let dataFilterNoAccettato = dataFilter.filter((data) => data.autoSwitchNoAccettato === "0");
          console.log(dataFilterNoAccettato)
          restartPagination(dataFilterNoAccettato);
          setTableCsv(dataFilterNoAccettato);
        });
    } else {
      let descProductResult = await ListBrandFilter();
      await axios
        .get(`${ConfigCapone.urlapi}/api/Client/GetClientTable?parameter=1${descProductResult}`)
        .then((response) => {

          let result = response.data.response;

          isTechniquePisa && (result = result.filter((data: any) => ['1', '5', '2', '6'].includes(data.idBussines)));
          isTechniqueViareggio && (result = result.filter((data: any) => ['3', '9', '4'].includes(data.idBussines)));

          let dataFilter = filterBy72Hours(result);

          let dataFilterNoAccettato = dataFilter.filter((data) => data.autoSwitchNoAccettato === "0");
          restartPagination(dataFilterNoAccettato);
          setTableCsv(dataFilterNoAccettato);
        });
    }
    setActiveSpinner(false);
  };
  const selectRepair = async () => {
    setActiveSpinner(true);
    await axios
      .get(`${ConfigCapone.urlapi}/api/ServiceType?parameter=1`)
      .then(async (response) => {
        await setStateRepair(response.data.response);
      });
    setActiveSpinner(false);
  };
  const sendLogg = async (mesaggeN: string) => {
    let selectedMessage = {
      message: mesaggeN,
    };
    await axios
      .post(`${ConfigCapone.urlapi}/api/Notify/Logg/`, selectedMessage, {
        headers: {
          'Authorization': `Bearer ${cookies.get('token')}`
        }
      })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => console.log(error));
  };
  const selectBussiness = async () => {
    setActiveSpinner(true);
    await axios
      .get(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`)
      .then((response) => {
        let resp = response.data.response
        setApiBusiness(response.data.response);
        if (isEmployee || isDirectAtelier) {
          let defaultBusiness = response.data.response.filter(
            (data: any) => data.idBussines == userInLogin.idBussines
          );
          setDefaultBusiness(defaultBusiness[0].desc_Bussines);
        }
        if (isTechniquePisa) {
          let newresp = resp.filter((data: any) => ['1', '5', '2', '6'].includes(data.idBussines));
          setApiBusiness(newresp);
        }
        if (isTechniqueViareggio) {
          let newresp = resp.filter((data: any) => ['3', '9', '4'].includes(data.idBussines));
          setApiBusiness(newresp);
        }
      })
      .catch((err) => console.log(err));
    setActiveSpinner(false);
  };
  const selectState = async () => {
    setActiveSpinner(true);
    await axios
      .get(`${ConfigCapone.urlapi}/api/Status?parameter=1`)
      .then((response) => setApiState(response.data.response))//.filter(x=>x.idStatus !=6)
      .catch((err) => console.log(err));
    setActiveSpinner(false);
  };
  const sendLoggAll = async () => {
    createdPosted({
      actionLog: "Esportare",
      message:
        "Esportazione dei dati del modulo di Assistenza - Stato della riparazione",
    })
    sendLogg("Esportazione dei dati del modulo di Assistenza - Stato della riparazione")
  }
  const isFocused = useIsFocused();
  useEffect(() => {
    selectRepair();
    serviceList();
    selectBussiness();
    selectState();
    getBrandList();
  }, [isFocused]);
  useEffect(() => {
    loadNumberPages(Math.ceil((arrayData ? arrayData.length : 0) / 5));
  }, [number]);
  const handleDelete = async (id: number) => {
    setActiveSpinner(true);
    await axios
      .get(`${ConfigCapone.urlapi}/api/Service/${id}`)
      .then((response) => response.data.response.idClient)
      .catch((error) =>
        setModalMessage({
          active: true,
          message: "Si è verificato un errore durante la rimozione.",
        })
      );
    await axios
      .delete(`${ConfigCapone.urlapi}/api/Service/${id}`, {
        headers: {
          'Authorization': `Bearer ${cookies.get('token')}`
        }
      })
      .then((response) => {
        serviceList();
      });

    setModal(false);
    setActiveSpinner(false);
  };
  const handleSearchBySelect = (selectedItem: string, brand: boolean = false) => {
    if (selectedItem === "") {
      setValueNegozio(undefined);
      if (valueBrand != "" && valueBrand) {
        handleSearchByBrand(valueBrand, true);
      } else {
        restartPaginationSearch(userFromAPI);
      }

    } else {
      setValueNegozio(selectedItem)
      let newData = userFromAPI.filter(
        (data: any) => (data.desc_Bussines === selectedItem)
      );

      if (searchInput) {
        console.log(searchInput);
        newData = newData.filter((data: any) =>
          data.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
        );
      }

      if (valueBrand && !brand) {
        newData = newData.filter(x => x.desc_Product == valueBrand);
      }

      if (newData.length === 0) {
        restartPaginationSearch(userFromAPI);
        setModalMessage({ active: true, message: "Dati non trovati" });
      } else {
        restartPaginationSearch(newData);
      }

    }
    setActiveSearch({ tipo: 1 });
  };


  const handleSearchByBrand = (selectedItem: string, negozio: boolean = false) => {
    console.log(selectedItem);
    if (selectedItem === "") {
      setValueBrand(undefined);
      if (valueNegozio != "" && valueNegozio) {
        handleSearchBySelect(valueNegozio, true);
      } else {
        restartPaginationSearch(userFromAPI);
      }
    } else {
      setValueBrand(selectedItem)
      let newData;
      if (selectedItem === "Rolex") {
        newData = userFromAPI.filter(
          (data: any) => data.desc_Product === "Rolex"
        );
      } else if (selectedItem === "Tudor") {
        newData = userFromAPI.filter(
          (data: any) => data.desc_Product === "Tudor"
        );
      } else if (selectedItem === "No-Rolex-&-Tudor") {
        newData = userFromAPI.filter(
          (data: any) => data.desc_Product !== "Rolex" && data.desc_Product !== "Tudor"
        );
      } else if (selectedItem === "Rolex-&-Tudor") {
        newData = userFromAPI.filter(
          (data: any) => data.desc_Product === "Tudor" || data.desc_Product === "Rolex"
        );
      } else {
        newData = userFromAPI;
      }
      console.log(valueNegozio);
      if (valueNegozio && !negozio) {
        newData = newData.filter(x => x.desc_Bussines == valueNegozio);
      }

      if (searchInput) {
        newData = newData.filter((data: any) =>
          data.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
        );
      }

      if (newData.length === 0) {
        restartPaginationSearch(userFromAPI);
        setModalMessage({ active: true, message: "Dati non trovati" });
      } else {
        restartPaginationSearch(newData);
      }

    }
    setActiveSearch({ tipo: 1 });
  };

  const handleSearch = () => {
    if (searchInput) {
      sendLogg(` ha cercatto ${searchInput} il assistenza-Stato riparazioni`)
      let client = arrayData.filter((data: any) =>
        data.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
      );
      let product = arrayData.filter((data: any) =>
        data.desc_Product
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase())
      );
      let dateClient = arrayData.filter((data: any) =>
        data.dateService
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase())
      );
      let finalConcat = client.concat(dateClient.concat(product));
      let uniqueArray = [...new Set(finalConcat)];
      if (uniqueArray.length === 0) {
        restartPaginationSearch(userFromAPI);
        setModalMessage({ active: true, message: "Dati non trovati" });
      } else {
        console.log(valueBrand);
        console.log(valueNegozio);
        if (valueBrand != "" && valueBrand) {
          if (valueBrand === "Rolex") {
            uniqueArray = uniqueArray.filter(
              (data: any) => data.desc_Product === "Rolex"
            );
          } else if (valueBrand === "Tudor") {
            uniqueArray = uniqueArray.filter(
              (data: any) => data.desc_Product === "Tudor"
            );
          } else if (valueBrand === "No-Rolex-&-Tudor") {
            uniqueArray = uniqueArray.filter(
              (data: any) => data.desc_Product !== "Rolex" && data.desc_Product !== "Tudor"
            );
          } else if (valueBrand === "Rolex-&-Tudor") {
            uniqueArray = uniqueArray.filter(
              (data: any) => data.desc_Product === "Tudor" || data.desc_Product === "Rolex"
            );
          }
        }
        if (valueNegozio != "" && valueNegozio) {
          uniqueArray = uniqueArray.filter((x: any) => x.desc_Bussines == valueNegozio);
        }

        if (uniqueArray.length === 0) {
          restartPaginationSearch(userFromAPI);
          setModalMessage({ active: true, message: "Dati non trovati" });
        } else {
          restartPaginationSearch(uniqueArray);
        }
      }
    } else {
      console.log(userFromAPI);
      if (valueBrand != "" && valueBrand) {
        handleSearchByBrand(valueBrand);
      } else if (valueNegozio != "" && valueNegozio) {
        handleSearchBySelect(valueNegozio);
      } else {
        restartPaginationSearch(userFromAPI);
      }
    }

  };
  const restartPaginationSearch = (resp: any) => {
    setTableCsv(resp)
    dataAux = arrayData;
    setTotalListUsers(resp);
    setArrayData(resp);
    setListUsers(resp.slice(0, 5));
    const numberPages = Math.ceil(resp.length / 5);
    loadNumberPagesSearch(numberPages, resp);
    setViewPagination(true);
    setBlockLeft(true);
    setBlockRight(false);
    setNumber(5);
    if (resp.length <= 5) {
      setViewPagination(false);
    } else {
      setViewPagination(true);
    }
  };
  let numPages = 0;
  const loadNumberPages = (pages: number) => {
    numPages = pages;
    let navsAux: any = [];
    for (let i = 0; i < pages; i++) {
      navsAux.push(
        <TouchableOpacity key={i} onPress={() => handleViewPage(i + 1)}>
          <View>
            <Text
              style={{
                fontWeight: "400",
                color: "black",
                fontSize: 14,
                paddingHorizontal: 10,
              }}
            >
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>»</Text>
              )}
              {i + 1}
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>«</Text>
              )}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }
    setNavs(navsAux);
  };
  const handleViewPageSearch = (page: number, resp: []) => {
    const newPage = page * 5;
    if (resp.length > 0) {
      setListUsers(resp?.slice(page * 5 - 5, page * 5));
    } else {
      setListUsers(resp?.slice(page * 5 - 5, page * 5));
    }
    setNumber(newPage);
    setBlockRight(false);
    setBlockLeft(true);
    if (page > 1) {
      setBlockLeft(false);
    }
    if (navs.length == 0) {
      if (page == numPages) {
        setBlockRight(true);
      }
    } else {
      if (page == navs.length) {
        setBlockRight(true);
      }
    }
  };
  const confirmDelete = (id: number) => {
    setIdStateRepair(id);
    setModal(true);
  };

  const handleNextUsers = () => {
    let data: IRepairData[] = arrayData.slice(number, number + 5);
    setListUsers(data);
    setNumber(number + 5);
    setBlockLeft(false);
    if (number + 5 >= arrayData.length) {
      setBlockRight(true);
    }
  };
  const handlePreviousUsers = () => {
    let data: IRepairData[] = arrayData.slice(number - 10, number - 5);
    setNumber(number - 5);
    setListUsers(data);
    setBlockRight(false);
    if (number - 5 <= 5) {
      setBlockLeft(true);
    }
  };
  let dataAux: any = [];
  const restartPagination = (resp: any) => {
    dataAux = resp;
    setTotalListUsers(resp);
    setUserFromAPI(resp);
    setArrayData(resp);
    setListUsers(resp.slice(0, 5));
    const numberPages = Math.ceil(resp.length / 5);
    loadNumberPages(numberPages);
    setViewPagination(true);
    setBlockLeft(true);
    setBlockRight(false);
    setNumber(5);
    if (resp.length <= 5) {
      setViewPagination(false);
    } else {
      setViewPagination(true);
    }
  };
  const handleViewPage = (page: number) => {
    setPage(page);
    const newPage = page * 5;
    if (arrayData.length > 0) {
      setListUsers(arrayData?.slice(page * 5 - 5, page * 5));
    } else {
      setListUsers(dataAux?.slice(page * 5 - 5, page * 5));
    }
    setNumber(newPage);
    setBlockRight(false);
    setBlockLeft(true);
    if (page > 1) {
      setBlockLeft(false);
    }
    if (navs.length == 0) {
      if (page == numPages) {
        setBlockRight(true);
      }
    } else {
      if (page == navs.length) {
        setBlockRight(true);
      }
    }
  };

  const handleViewPageFilter = (page: number, response: any) => {
    setPage(page);
    let resp = response;

    const newPage = page * 5;
    if (resp) {
      if (resp.length > 0) {
        setListUsers(resp?.slice(page * 5 - 5, page * 5));
      } else {
        setListUsers(resp.slice(page * 5 - 5, page * 5));
      }
    }
    setNumber(newPage);
    setBlockRight(false);
    setBlockLeft(true);
    if (page > 1) {
      setBlockLeft(false);
    }
    if (navs.length == 0) {
      if (page == numPages) {
        setBlockRight(true);
      }
    } else {
      if (page == navs.length) {
        setBlockRight(true);
      }
    }
  };

  const goToRepairTab = async (data: any) => {
    setActiveSpinner(true);

    setDataTableSend(data);

    let dataService = await axios
      .get(`${ConfigCapone.urlapi}/api/Service/${data.idService}`)
      .then((response) => {
        return response.data.response;
      })
      .catch((err) => console.log(err));

    let dataClient: IClient = await axios
      .get(
        `${ConfigCapone.urlapi}/api/Client?value=${data.idClient}&parameter=idClient`
      )
      .then((response) => {
        return response.data.response[0];
      })
      .catch((err) => console.log(err));

    console.log('state repair', dataService.idUser);

    await getUserFromId(dataService.idUser).then((result: IUser) =>
      setOperatore(result)
    );

    console.log("data es: ", data);

    let dataToNewRepair = await loadDataForUpdate(data);
    console.log("new data7: ", dataToNewRepair);
    setDataRepair(dataService);
    setDataClient(dataToNewRepair);
    setModalVisibleCard(true);
    setActiveSpinner(false);
  };

  const loadDataForUpdate = async (data: any) => {
    let dataService = await axios
      .get(`${ConfigCapone.urlapi}/api/Service/${data.idService}`)
      .then((response) => {
        return response.data.response;
      })
      .catch((err) => console.log(err));
    let dataClient = await axios
      .get(
        `${ConfigCapone.urlapi}/api/Client?value=${data.idClient}&parameter=idClient`
      )
      .then((response) => {
        return response.data.response[0];
      })
      .catch((err) => console.log(err));
    console.log('dataClient', dataClient)
    let brandDefault = await axios
      .get(`${ConfigCapone.urlapi}/api/Brand/${dataService.idBrand}`)
      .then((response) => response.data.response);
    let bussinesDefault = await axios
      .get(`${ConfigCapone.urlapi}/api/Bussines/${dataService.idBussines}`)
      .then((response) => response.data.response);
    let clientResult = await axios
      .get(`${ConfigCapone.urlapi}/api/Client/${data.idClient}`)
      .then((response) => response.data.response.idTitle);
    let titleDefault = await axios
      .get(`${ConfigCapone.urlapi}/api/Title/${clientResult}`)
      .then((response) => response.data.response);
    let dataJoin = await {
      ...dataClient,
      ...dataService,
      brandDefault,
      bussinesDefault,
      titleDefault,
      tipo: "StateRepair",
    };

    return dataJoin;
    // await navigation.navigate("FormRepairScreen", dataJoin);
  };

  const updateStateOfService = async (id: number, idService: number) => {
    console.log("id: ", number, " service: ", idService);

    if (isTechnique) {
      console.log("post1");

      let techniqueId = userInLogin.idUser;

      await axios
        .post(
          `${ConfigCapone.urlapi}/api/Service/ChangeStatusService/${idService}/${id}/${techniqueId}`, {}, {
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        })
        .then(async (res) => {
          if (isEmployee || isDirectAtelier) {
            axios
              .get(
                `${ConfigCapone.urlapi}/api/Client/GetClientTableV2?idBussines=${userInLogin.idBussines}`
              )
              .then((resp) => {

                let result = resp.data.response;

                isTechniquePisa && (result = result.filter((data: any) => ['1', '5', '2', '6'].includes(data.idBussines)));
                isTechniqueViareggio && (result = result.filter((data: any) => ['3', '9', '4'].includes(data.idBussines)));

                let dataFilter = filterBy72Hours(result);
                let dataFilterNoAccettato = dataFilter.filter((data) => data.autoSwitchNoAccettato === "0");
                restartPagination(dataFilterNoAccettato);
                handleViewPageFilter(page, dataFilterNoAccettato);
              });
          } else {
            let descProductResult = await ListBrandFilter();
            axios
              .get(
                `${ConfigCapone.urlapi}/api/Client/GetClientTable?parameter=1${descProductResult}`
              )
              .then((resp) => {

                let result = resp.data.response;

                isTechniquePisa && (result = result.filter((data: any) => ['1', '5', '2', '6'].includes(data.idBussines)));
                isTechniqueViareggio && (result = result.filter((data: any) => ['3', '9', '4'].includes(data.idBussines)));

                let dataFilter = filterBy72Hours(result);
                let dataFilterNoAccettato = dataFilter.filter((data) => data.autoSwitchNoAccettato === "0");
                restartPagination(dataFilterNoAccettato);
                handleViewPageFilter(page, dataFilterNoAccettato);
              });
          }
        });
    } else {
      console.log("post2");

      await axios
        .post(
          `${ConfigCapone.urlapi}/api/Service/ChangeStatusService/${idService}/${id}/0`, {}, {
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        }
        )
        .then(async (res) => {
          if (isEmployee || isDirectAtelier) {
            axios
              .get(
                `${ConfigCapone.urlapi}/api/Client/GetClientTableV2?idBussines=${userInLogin.idBussines}`
              )
              .then((resp) => {

                let result = resp.data.response;

                isTechniquePisa && (result = result.filter((data: any) => ['1', '5', '2', '6'].includes(data.idBussines)));
                isTechniqueViareggio && (result = result.filter((data: any) => ['3', '9', '4'].includes(data.idBussines)));

                let dataFilter = filterBy72Hours(result);
                let dataFilterNoAccettato = dataFilter.filter((data) => data.autoSwitchNoAccettato === "0");
                console.log(dataFilterNoAccettato)
                restartPagination(dataFilterNoAccettato);
                handleViewPageFilter(page, dataFilterNoAccettato);
              });
          } else {
            axios
              .get(
                `${ConfigCapone.urlapi}/api/Client/GetClientTable?parameter=1`
              )
              .then((resp) => {

                let result = resp.data.response;

                isTechniquePisa && (result = result.filter((data: any) => ['1', '5', '2', '6'].includes(data.idBussines)));
                isTechniqueViareggio && (result = result.filter((data: any) => ['3', '9', '4'].includes(data.idBussines)));

                let dataFilter = filterBy72Hours(result);
                let dataFilterNoAccettato = dataFilter.filter((data) => data.autoSwitchNoAccettato === "0");
                restartPagination(dataFilterNoAccettato);
                console.log(dataFilterNoAccettato)
                handleViewPageFilter(page, dataFilterNoAccettato);
              });
          }
        });
    }
  };
  const filterBy72Hours = (data: any) => {

    let dataBy72Hours = data.filter(
      (element: any) => !(element.isMore72Hours > 72 && element.idStatus == "5")
    );
    return dataBy72Hours;
  };
  const loadNumberPagesSearch = (pages: number, resp: []) => {
    numPages = pages;
    let navsAux: any = [];
    for (let i = 0; i < pages; i++) {
      navsAux.push(
        <TouchableOpacity
          key={i}
          onPress={() => handleViewPageSearch(i + 1, resp)}
        >
          <View>
            <Text
              style={{
                fontWeight: "400",
                color: "black",
                fontSize: 14,
                paddingHorizontal: 10,
              }}
            >
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>»</Text>
              )}
              {i + 1}
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>«</Text>
              )}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }
    setNavs(navsAux);
  };
  const modalNotice = async (data: any, description: string) => {
    setDataBussines(data);
    description === "Avvisa" && setModalVisible(true);

    if (description === "ring") {
      setModalCorreo(true);
      await axios
        .get(`${ConfigCapone.urlapi}/api/User?parameter=1`)
        .then((response) => {
          setUserList(response.data.response);
        });
    }
  };

  const modalEmailTechnician = async (data: any, description: string) => {
    setDataBussines(data);
    description === "Avvisa" && setModalVisible(true);

    if (description === "ring") {
      setModalCorreoTecnic(true);
      await axios
        .get(`${ConfigCapone.urlapi}/api/User?parameter=1`)
        .then((response) => {
          setUserList(response.data.response);
        });
    }
  };

  const DataSet = [
    {
      columns: [
        { title: "Cliente", style: { font: { sz: "18", bold: true } } },
        { title: "Orologio", style: { font: { sz: "18", bold: true } } },
        { title: "Data", style: { font: { sz: "18", bold: true } } },
        { title: "Negozio", style: { font: { sz: "18", bold: true } } },
        { title: "Stato", style: { font: { sz: "18", bold: true } } },
      ],
      data:
        tableCsv &&
        tableCsv.map((data: any) => [
          { value: data.name, style: { font: { sz: "14", bold: false } } },
          {
            value: data.desc_Product ? data.desc_Product : "N/D",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.dateService
              ? data.dateService
                .split("T")[0]
                .toString()
                .split("-")
                .reverse()
                .join("/")
              : "N/D",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.desc_Bussines ? data.desc_Bussines : "N/D",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.status.desc_Status ? data.status.desc_Status : "N/D",
            style: { font: { sz: "14", bold: false } },
          },
        ]),
    },
  ];

  return (
    <div className="square" style={{ position: !isAtelier ? 'absolute' : 'relative',overflowY:'auto' }}>
      <ScrollView style={styles.scroll}>
        {activeSpinner && <Loading activeSpinner={activeSpinner} />}
        <SafeAreaView
          style={{
            flex: 1,
            paddingTop: StatusBar.currentHeight,
            backgroundColor: "white",
          }}
        >
          {!isAtelier &&
            <View
              style={{
                paddingHorizontal: 12,
              }}
            >
              <HeaderBar
                title={"Stato riparazioni"}
                onBack={() => navigation.navigate("HomeScreen")}
                iconHeader={require("../assets/icons/statoRiparazioni.png")}
                hasRoundedIcon={true}
              />
            </View>
          }
          <View
            style={{
              flex: 1,
              paddingHorizontal: 30,
              paddingVertical: 20,
            }}
          >
            <div
              className="row"
              style={{
                paddingRight: 12,
                paddingLeft: 12,
              }}
            >
              <div className="col-xl-6 col-lg-5 col-md-2 col-sm-0 p-0"></div>
              <div className="col-xl-3 col-lg-4 col-md-5 pb-2 col-sm-7 p-0">
                <div className="mt-2">
                  <Text
                    style={[
                      {
                        fontWeight: "500",
                        fontSize: 20,
                        alignSelf: "center",
                      },
                    ]}
                  >
                    Riparazioni prese in carico
                  </Text>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 p-0 d-flex justify-content-center">
                <TextInput
                  placeholder="Cerca cliente..."
                  style={[
                    stylesRepair.input,
                    {
                      alignSelf: "flex-end",
                      borderColor: "#A4A4A4",
                      paddingRight: 2,
                      paddingLeft: 15,
                      fontSize: 20,
                    },
                  ]}
                  onChangeText={(value) => setSearchInput(value)}
                />

                <TouchableOpacity
                  onPress={() => handleSearch()}
                  style={{
                    margin: "auto",
                  }}
                >
                  <View style={stylesRepair.viewDisable}>
                    <Image
                      style={{
                        width: 20,
                        height: 20,
                        tintColor: "white",
                        alignSelf: "center",
                      }}
                      source={require("../assets/icons/icon-search.png")}
                    />
                  </View>
                </TouchableOpacity>
              </div>
            </div>
            <div
              className="row mt-4"
              style={{
                paddingRight: 12,
                paddingLeft: 12,
              }}
            >
              <div className="col-xl-6 col-lg-5 col-md-2 col-sm-0 p-0"></div>
              <div className="col-xl-3 col-lg-4 col-md-5 pb-2 col-sm-7 p-0">
                <div className="mt-3">
                  <Text
                    style={[
                      {
                        alignContent: "center",
                        marginTop: 8,
                        fontWeight: "500",
                        fontSize: 20,
                      },
                    ]}
                  >
                    Negozio
                  </Text>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 p-0">
                {isEmployee || isDirectAtelier ? (
                  <select
                    className=" mt-2"
                    disabled={isEmployee || isDirectAtelier}
                    value={defaultBusiness}
                    style={{
                      alignSelf: "flex-end",
                      borderColor: "#A4A4A4",
                      paddingRight: 2,
                      paddingLeft: 15,
                      fontSize: 20,
                      width: "100%",
                      backgroundColor: "#EAEAEA",
                      height: 43,
                      margin: 4,
                      borderWidth: 1,
                      borderRadius: 50,
                      fontFamily: "Arsapia Regular font",
                      color: "#757575",
                    }}
                    aria-label=".form-select "
                  >
                    {apiBusiness.map((d: any) => (
                      <option
                        key={Number(d.idBussines)}
                        value={d.desc_Bussines}
                      >
                        {d.desc_Bussines}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    className=" mt-2"
                    style={{
                      alignSelf: "flex-end",
                      borderColor: "#A4A4A4",
                      paddingRight: 2,
                      paddingLeft: 15,
                      fontSize: 20,
                      width: "100%",
                      backgroundColor: "transparent",
                      height: 43,
                      margin: 4,
                      borderWidth: 1,
                      borderRadius: 50,
                      fontFamily: "Arsapia Regular font",
                      color: "#757575",
                    }}
                    onChange={(e) => {
                      handleSearchBySelect(e.target.value)
                    }}
                    aria-label=".form-select "
                  >
                    <option value={""} selected>
                      Seleziona
                    </option>
                    {apiBusiness.map((d: any) => (
                      <option
                        key={Number(d.idBussines)}
                        value={d.desc_Bussines}
                      >
                        {d.desc_Bussines}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>


            {/* */}

            <div
              className="row mt-4"
              style={{
                paddingRight: 12,
                paddingLeft: 12,
              }}
            >
              <div className="col-xl-6 col-lg-5 col-md-2 col-sm-0 p-0"></div>
              <div className="col-xl-3 col-lg-4 col-md-5 pb-2 col-sm-7 p-0">
                <div className="mt-3">
                  <Text
                    style={[
                      {
                        alignContent: "center",
                        marginTop: 8,
                        fontWeight: "500",
                        fontSize: 20,
                      },
                    ]}
                  >
                    Brand
                  </Text>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 p-0">

                {
                  brandList
                    ?
                    <select
                      className=" mt-2"
                      style={{
                        alignSelf: "flex-end",
                        borderColor: "#A4A4A4",
                        paddingRight: 2,
                        paddingLeft: 15,
                        fontSize: 20,
                        width: "100%",
                        backgroundColor: "#EAEAEA",
                        height: 43,
                        margin: 4,
                        borderWidth: 1,
                        borderRadius: 50,
                        fontFamily: "Arsapia Regular font",
                        color: "#757575",
                      }}
                      aria-label=".form-select "
                      onChange={(e) => {
                        handleSearchByBrand(e.target.value)
                        console.log(e.target.value)
                      }}
                    >
                      <option value={""} selected>
                        Seleziona
                      </option>
                      <option value="Rolex">
                        Rolex
                      </option>
                      <option value="Tudor">
                        Tudor
                      </option>
                      <option value="No-Rolex-&-Tudor">
                        No Rolex & Tudor
                      </option>
                      <option value="Rolex-&-Tudor">
                        Rolex & Tudor
                      </option>
                      <option value="Tutti">
                        Tutti
                      </option>
                    </select>
                    :
                    <></>
                }

              </div>
            </div>
            <div className="table-responsive">
              <ExcelFile
                onClick={(evt) => console.log(evt)}
                filename={nameFileExport}
                element={
                  <button
                    onClick={() => sendLoggAll()}
                    type="button"
                    className="dowloandPdf"
                  >
                    Esporta
                  </button>
                }
              >
                <ExcelSheet dataSet={DataSet} name="pruen 1 reporte" />
              </ExcelFile>

              <table
                className="table table-borderless mt-3 mx-0"
                id="table-to-xls"
              >
                <thead
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    height: 50,
                  }}
                >
                  <tr className="mr-4">
                    <th
                      style={{
                        borderTopLeftRadius: 50,
                        borderBottomLeftRadius: 50,
                      }}
                      scope="col"
                      className="align-self-center mr-4 px-4"
                    ></th>
                    <th
                      scope="col"
                      className="align-self-center mr-4 px-4"
                    ></th>
                    <th
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        verticalAlign: "middle",
                      }}
                      scope="col"
                      className="align-self-center mr-4 px-4"
                    >
                      Cliente
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        verticalAlign: "middle",
                      }}
                      scope="col"
                      className="align-self-center mr-4 px-4"
                    >
                      Orologio
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        verticalAlign: "middle",
                      }}
                      scope="col"
                      className="align-self-center mr-4 px-4"
                    >
                      Matricola
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        verticalAlign: "middle",
                      }}
                      scope="col"
                      className="align-self-center mr-4 px-4"
                    >
                      Data
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        verticalAlign: "middle",
                      }}
                      scope="col"
                      className="align-self-center mr-4 px-4"
                    >
                      Negozio
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        verticalAlign: "middle",
                      }}
                      scope="col"
                      className="align-self-center mr-4 px-4"
                    >
                      Stato
                    </th>

                    <th
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        verticalAlign: "middle",
                      }}
                      scope="col"
                      className="align-self-center mr-4 px-4"
                    ></th>
                    {!isTechnique && (
                      <th
                        style={{
                          textAlign: "center",
                          fontWeight: "400",
                          verticalAlign: "middle",
                        }}
                        scope="col"
                        className="align-self-center mr-4 px-4"
                      >
                      </th>
                    )}

                    <th
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        verticalAlign: "middle",
                      }}
                      scope="col"
                      className="align-self-center mr-4 px-4"
                    ></th>
                    <th
                      style={{
                        borderTopRightRadius: 50,
                        borderBottomRightRadius: 50,
                      }}
                      scope="col"
                      className="align-self-center mr-4 px-4"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {listUsers &&
                    listUsers.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          style={{ borderBottom: "1px solid 	gray" }}
                        >
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {data.isEqualOrMore45Days && (
                              <Icon
                                name="exclamation-circle"
                                type="font-awesome"
                                color="#f50"
                                containerStyle={{
                                  marginLeft: "-3%",
                                  justifyContent: "center",
                                }}
                                tvParallaxProperties={undefined}
                              />
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                            className="align-self-center mr-4 px-4"
                          >
                            <TouchableOpacity
                              style={{
                                flex: 1,
                                paddingHorizontal: 5,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onPress={() => goToRepairTab(data)}
                            >
                              <Image
                                style={{ width: 30, height: 30 }}
                                source={require("../assets/icons/icon-note.png")}
                              />
                            </TouchableOpacity>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                            className="align-self-center"
                          >
                            {data.name}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                            className="align-self-center"
                          >
                            {data.desc_Product
                              ? data.desc_Product
                              : "Nessun dato"}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                            className="align-self-center"
                          >
                            {data.enrollment}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                            className="align-self-center mr-4 px-4"
                          >
                            {data.dateService
                              .split("T")[0]
                              .toString()
                              .split("-")
                              .reverse()
                              .join("/")}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                            className="align-self-center mr-4 px-4"
                          >
                            {data.desc_Bussines
                              ? data.desc_Bussines
                              : "Nessun dato"}
                          </td>
                          <td>
                            <Controller
                              control={control}
                              name="business"
                              render={({
                                field: { onChange, value, onBlur },
                              }) => (
                                <select
                                  className="form-select selectedState"
                                  style={{
                                    width: "auto",
                                    border: 0,
                                    margin: "auto",
                                    pointerEvents: isEmployee ? "none" : "all",
                                  }}
                                  onChange={(e) =>
                                    updateStateOfService(
                                      Number(e.target.value),
                                      Number(data.idService)
                                    )
                                  }
                                  aria-label=".form-select-lg "
                                >
                                  {apiState.map((d: any) => (
                                    <option
                                      key={d.idStatus}
                                      selected={
                                        d.idStatus === data.status.idStatus
                                          ? true
                                          : false
                                      }
                                      value={d.idStatus}
                                    >
                                      {d.desc_Status}
                                    </option>
                                  ))}
                                </select>
                              )}
                            />
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                            className="align-self-center mr-4 px-4"
                          >
                            <button
                              style={{
                                flex: 1,
                                width: 100,
                                height: 40,
                                alignSelf: "center",
                                backgroundColor: "transparent",
                                borderRadius: 50,
                                alignItems: "center",
                                justifyContent: "center",
                                pointerEvents: (isEmployee || isDirectAtelier) ? "none" : "all",
                              }}
                              onClick={() => {
                                modalNotice(data, "Avvisa");
                              }}
                            >
                              Avvisa
                            </button>
                          </td>

                          {!isTechnique && (
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                              className="align-self-center mr-4 px-4"
                            >
                              <TouchableOpacity
                                style={{
                                  flex: 1,
                                  paddingHorizontal: 5,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  pointerEvents: (isEmployee || isDirectAtelier) ? "none" : "all",
                                }}
                                onPress={() => {
                                  modalEmailTechnician(data, "ring");
                                }}
                              >
                                <Image
                                  style={{ width: 30, height: 30 }}
                                  source={require("../assets/icons/tecnico1.png")}
                                />
                              </TouchableOpacity>
                            </td>
                          )}

                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            <TouchableOpacity
                              style={{
                                flex: 1,
                                paddingHorizontal: 5,
                                alignItems: "center",
                                justifyContent: "center",
                                pointerEvents: (isEmployee || isDirectAtelier) ? "none" : "all",
                              }}
                              onPress={() => {
                                modalNotice(data, "ring");
                              }}
                            >
                              <Image
                                style={{ width: 20, height: 20 }}
                                source={require("../assets/icons/Campana-resize.png")}
                              />
                            </TouchableOpacity>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            <TouchableOpacity
                              style={{
                                flex: 1,
                                paddingHorizontal: 5,
                                alignItems: "center",
                                justifyContent: "center",
                                pointerEvents: isAdmin ? "all" : "none",
                              }}
                              onPress={() => confirmDelete(data.idService)}
                            >
                              <Image
                                style={{ width: 20, height: 20 }}
                                source={require("../assets/icons/icon-delete.png")}
                              />
                            </TouchableOpacity>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {viewPagination && (
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="table-responsive">
                    <View style={{ flexDirection: "row", marginTop: 15 }}>
                      {!blockLeft && (
                        <TouchableOpacity onPress={handlePreviousUsers}>
                          <View>
                            <Image
                              style={{
                                width: 15,
                                marginRight: 30,
                                height: 15,
                                tintColor: "rgb(200,200,200)",
                              }}
                              source={require("../assets/icons/icon-left.png")}
                            />
                          </View>
                        </TouchableOpacity>
                      )}
                      {navs}
                      {!blockRight && (
                        <TouchableOpacity onPress={handleNextUsers}>
                          <View>
                            <Image
                              style={stylesRepair.imageWithMargin}
                              source={require("../assets/icons/icon-right.png")}
                            />
                          </View>
                        </TouchableOpacity>
                      )}
                    </View>
                  </div>
                </div>
              </div>
            )}
            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  backgroundColor: "white",
                  padding: 10,
                  paddingHorizontal: 80,
                  justifyContent: "center",
                  borderRadius: 50,
                }}
              ></View>
            </View>
          </View>
        </SafeAreaView>
        {modalVisible && (
          <SendNotificationScreen
            currentPage="state_repair"
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            clientData={dataBussines}
          />
        )}
        {modal && (
          <PopupConfirmation
            title={""}
            message={"Sei sicuro di eliminare la riparazione?"}
            confirmAction={() => handleDelete(idStateRepair)}
            stateModal={modal}
            setStateModal={setModal}
          />
        )}
        {modalCorreo && (
          <SendEmailScreen
            modalVisible={modalCorreo}
            setModalVisible={setModalCorreo}
            clientData={dataBussines}
            userList={userList}
          />
        )}
        {modalCorreoTecnic && (
          <SendEmailScreenTecnic
            modalVisible={modalCorreoTecnic}
            setModalVisible={setModalCorreoTecnic}
            clientData={dataBussines}
            userList={userList}
          />
        )}

        {modalMessage.active && (
          <PopupMessage
            title={"Informazione"}
            message={modalMessage.message}
            stateModal={modalMessage.active}
            setStateModal={setModalMessage}
          />
        )}
        <RepairCardScreen
          modalVisible={modalVisibleCard}
          setModalVisible={setModalVisibleCard}
          dataClient={dataClient}
          dataService={dataRepair}
          operatore={operatore}
          navigation={navigation}
          activeButton={activeBottom}
          dataTableSend={dataTableSend}
          isAtelier={isAtelier}
        />
      </ScrollView>
    </div>
  );
};
const stylesRepair = StyleSheet.create({
  input: {
    height: 45,
    width: "100%",
    borderWidth: 2,
    fontFamily: "Arsapia Regular font",
    padding: 6,
    borderRadius: 50,
    marginRight: "1%",
  },
  picker: {
    height: 40,
    cursor: "pointer",
    width: "100%",
    alignSelf: "flex-end",
    fontFamily: "Arsapia Regular font",
    borderWidth: 2,
    paddingHorizontal: 10,
    borderRadius: 50,
    marginVertical: 6,
  },
  statePicker: {
    height: 20,
    width: 85,
    margin: 12,
    fontSize: 10,
    alignSelf: "center",
    borderWidth: 2,
    paddingHorizontal: 10,
    borderRadius: 50,
    marginVertical: 6,
    borderColor: "white",
  },
  dropdown1BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontSize: 16,
    fontFamily: "Arsapia Regular font",
  },
  dropdown1RowStyle: {
    backgroundColor: "#EFEFEF",
    borderBottomColor: "#C5C5C5",
  },
  dropdown1RowTxtStyle: {
    color: "#444",
    fontSize: 16,
    fontFamily: "Arsapia Regular font",
    paddingVertical: 8,
    paddingTop: 12,
    textAlign: "left",
  },
  viewText: { flex: 3, backgroundColor: "white" },
  viewDisable: {
    backgroundColor: "black",
    borderRadius: 50,
    justifyContent: "center",
    width: 40,
    height: 40,
    marginTop: "2%",
  },
  subtitle: {
    marginTop: 10,
    fontWeight: "600",
    fontSize: 20,
    fontFamily: "Arsapia Regular font",
  },
  imageStyle: {
    width: 14,
    height: 14,
    tintColor: "black",
  },
  imageWithMargin: {
    width: 15,
    marginLeft: 30,
    height: 15,
    tintColor: "rgb(200,200,200)",
  },
});
