import {
    Modal,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    FlatList,
    Pressable
} from "react-native";

import { useContext, useEffect, useState } from "react";
import axios from "axios";

import Cookies from 'universal-cookie';
import ConfigCapone from "../../../../../utils/ConfigCapone";
import { formatMessageEmail } from "../../../../CRM/FormatMessageEmail/formatMessageEmail";
import { UserContext } from "../../../../../context/UserContext";
import Loading from "../../../../../components/Loading";
import { stylesModal } from "../../../../../theme/ModalStyles";
import { HeaderModal } from "../../../../../components/HeaderModal";
import { SearchBox } from "../../../../../components/SearchBox";

interface Client {
    idClient: string;
    idTitle: string;
    name: string;
    lastName: string;
    address: null;
    email: string;
    phone: string;
    dateInsert: Date;
    idUser: string;
    dateModification: Date;
    active: boolean;
    total: string;
    hasNotification: boolean;
}

interface IModalClientExitsAtelier {
    idMatricola: string,
    idBussinessSeller: any,
    typeMatricola: string,
    goToSchedaProdotto: any,
    updateStateMatricole: any,
    modalVisible: boolean;
    setModalVisible: any;
    setModalSchedaProdotto?: any;
    activeButton?: boolean;
    searchModalInputClient?: any;
    setSearchModalInputClient?: any;
    handleSearchModalFilter?: () => void,
    dataMagazzino: any | undefined;
    autosuggest?: any,
    setAutosuggest?: any
}

const ModalClientExitsAtelier = ({
    idMatricola,
    idBussinessSeller,
    typeMatricola,
    goToSchedaProdotto,
    updateStateMatricole,
    modalVisible = false,
    setModalVisible,
    setModalSchedaProdotto,
    searchModalInputClient,
    setSearchModalInputClient,
    handleSearchModalFilter,
    dataMagazzino,
    autosuggest,
    setAutosuggest,
}: IModalClientExitsAtelier) => {

    
    const { userInLogin } = useContext(UserContext);
    const [titleType, setTitleType] = useState([]);
    const [dataBussinesList, setDataBussinesList] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [stateSend, setStateSend] = useState<boolean>(false);

    const [selectedClient, setSelectedClient] = useState<any>()
    const [errorUser, setErrorUser] = useState(false)
    const [activeSpinner, setActiveSpinner] = useState(false);
    const cookies = new Cookies();
    const errorMessageRequired = "Seleziona un cliente dal suggerimento";

    const getItemText = (item: Client) => {
        let mainText = item.name + " - " + item.phone;
        return (
            <div>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ marginLeft: 10, flexShrink: 1 }}>
                        <Text style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}>{mainText}</Text>
                    </View>
                </View>
            </div>
        );
    };

    const getBussinesList = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`)
            .then((response) => setDataBussinesList(response.data.response))
            .catch((error) => console.log(error));
    };


    const getTitle = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Title?parameter=1`)
            .then((response) => setTitleType(response.data.response))
            .catch((error) => console.log(error));
    };

    const obtenerDataClientProdoto = async (id: string) => {
        let prodoto: any;
        await axios(`${ConfigCapone.urlapi}/api/Matricola/${id}`)
            .then((response) => prodoto = response.data.response)
            .catch((error) => console.log(error));
        return prodoto;
    };

    useEffect(() => {
        getTitle();
        getBussinesList();
    }, []);


    const sendEmail = async (idclienteprocedute?: any, dataClientSend?: any) => {
        if (dataClientSend?.email?.length > 0) {
            if (stateSend) {
                console.log("sss", idMatricola)
                let getprodoto: any = await obtenerDataClientProdoto(idMatricola);
                console.log("sss", getprodoto)
                const titleClientSend = titleType.find(element => element.idTitle == dataClientSend.idTitle)
                let idBussinesMatricola;
                await axios
                    .get(`${ConfigCapone.urlapi}/api/Matricola/AllRegister/${idMatricola}`)
                    .then((response) => {
                        idBussinesMatricola = response.data.response?.idNegozio;
                    })
                    .catch((error) => {
                        console.log("error -> ", error);

                    });
                const bussinesData = dataBussinesList.find(element => element.idBussines == idBussinesMatricola)
                const emailMessage = formatMessageEmail(titleClientSend?.desc_Title, dataClientSend.name, getprodoto.marca, bussinesData?.desc_Bussines, dataClientSend.country)
                if (ConfigCapone.configTest) {
                    for (let i = 0; i < ConfigCapone.emailTest.length; i++) {
                        let dataSend = {
                            from: "customerservice@sergiocapone.com",
                            type: "notice",
                            email: ConfigCapone.emailTest[i],
                            subject: "Grazie per il suo acquisto",
                            message: emailMessage
                        };
                        await axios
                            .post(
                                `${ConfigCapone.apicapone}/api/forma`,
                                dataSend
                            )
                            .then((res) => {
                                console.log("email send");
                            })
                            .catch((err) => {
                                console.log("there was an error: " + err)
                            });

                    }
                }

                await axios
                    .put(`${ConfigCapone.urlapi}/api/ClientProdposseduti/ModifyEmail/${idclienteprocedute}/${stateSend}`)
                    .then((response) => {
                        console.log("email update")
                    })
                    .catch((err) => {
                        console.log("there was an error: " + err)
                    });
            }
        }

    }


    const sellToClientEsistente = async () => {
        setActiveSpinner(true)
        if (selectedClient) {
            setErrorUser(false);
            await updateStateMatricole(idMatricola, true, typeMatricola, selectedClient.idClient);

            let data = {
                idBussinessSeller: idBussinessSeller.idBussines,
                idClient: selectedClient.idClient,
                sellDate: new Date(),
                idMatricola: idMatricola,
                idUser: userInLogin.idUser,
            }

            await axios.post(`${ConfigCapone.urlapi}/api/Sales`, data, {
                headers: {
                    'Authorization': `Bearer ${cookies.get('token')}`
                }
            })
                .then(async (res) => console.log("request update", res))

            let matricola: any;

            await axios
                .get(`${ConfigCapone.urlapi}/api/Matricola/${idMatricola}`)
                .then((response) => {
                    if (response.data.status != "empty") {
                        matricola = response.data.response.matricola
                    }
                })
                .catch((error) => {
                    console.log("error");
                })

            const newOwnProduct = {
                idClient: selectedClient.idClient,
                idBrand: dataMagazzino?.idBrand,
                Prodotto: dataMagazzino?.desc_brand + " " + dataMagazzino?.referencia,
                Referenza: dataMagazzino?.referencia,
                idMatricola: idMatricola,
                Matricola: matricola,
                Data: new Date(),
                Prezzo: dataMagazzino?.prezzo,
            };

            let idData: any;

            await axios
                .post(`${ConfigCapone.urlapi}/api/ClientProdposseduti`, newOwnProduct)
                .then((response) => {
                    console.log("response prueba", response);
                    idData = response.data.id;
                })
                .catch((error) => {
                    console.log("error");
                });
            await sendEmail(idData, selectedClient);
            setTimeout(() => {
                setSearchModalInputClient("");
                setModalVisible(false);
                setActiveSpinner(false)
                goToSchedaProdotto();
                setModalSchedaProdotto(false);
            }, 1000);


        } else {
            setErrorUser(true);
            setActiveSpinner(false)
        }


    }
    return (
        <View >
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <ScrollView style={{ width: "100%", height: "100%" }}>
                    {activeSpinner && <Loading activeSpinner={activeSpinner} />}
                    <View
                        style={[
                            stylesModal.centeredView,
                            { paddingTop: 150, paddingBottom: 150 },
                        ]}
                    >
                        <View style={[stylesModal.modalView, { width: 400 }]}>
                            <HeaderModal
                                setModalVisible={setModalVisible}
                                modalVisible={modalVisible}
                                title={"Cerca cliente"}
                                activatedPadLeft={true}
                            />
                            <div className="row w-100">
                                <div className="col-12">

                                    <div className="row">

                                        <div className="col-12 mt-5" style={{ paddingLeft: 40, paddingRight: 40 }}>
                                            <SearchBox value={searchModalInputClient} setValue={setSearchModalInputClient} onChangeText={handleSearchModalFilter} />
                                        </div>

                                        <div className="col-12">
                                            {searchModalInputClient && autosuggest.length > 0 ? (
                                                <View style={{
                                                    borderWidth: 1,
                                                    width: 262,
                                                    marginTop: -15,
                                                    paddingTop: 15,
                                                    marginLeft: 30,
                                                    borderTopWidth: 0,
                                                    borderBottomLeftRadius: 20,
                                                    borderBottomRightRadius: 20,
                                                }}>
                                                    <FlatList
                                                        data={autosuggest}
                                                        showsVerticalScrollIndicator={false}
                                                        renderItem={({ item, index }) => (
                                                            <Pressable
                                                                style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }]}
                                                                onPress={() => {
                                                                    setSearchModalInputClient(item.name),
                                                                        setAutosuggest([]),
                                                                        setSelectedClient(item)
                                                                }
                                                                }
                                                            >
                                                                {getItemText(item)}
                                                            </Pressable>
                                                        )}
                                                        keyExtractor={(item, index) => item.idUser + index}
                                                    />
                                                </View>
                                            ) : null}
                                        </div>


                                        {errorUser === true && (
                                            <div className="mt-3">
                                                <Text style={stylesModalOptionClienteEsistente.labelError}>
                                                    {errorMessageRequired}
                                                </Text>
                                            </div>
                                        )}


                                    </div>


                                    <div className="row mt-4">
                                        <label style={{ fontSize: 18, marginBottom: 5, fontFamily: "Arsapia Regular font" }} >
                                            <input
                                                type="checkbox"
                                                style={{
                                                    margin: 10,
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    fontSize: 100
                                                }}
                                                name="send_email"
                                                disabled={selectedClient != undefined ? false : true}
                                                checked={stateSend}
                                                onChange={(e) => { setStateSend(!stateSend) }}
                                            />
                                            Invia e-mail
                                        </label>
                                    </div>



                                    <div className="row">
                                        <div className="col-12  d-flex justify-content-center my-5">
                                            <TouchableOpacity
                                                style={{ marginRight: 15 }}
                                                onPress={() => setModalVisible(!modalVisible)}
                                            >
                                                <View style={[stylesModalOptionClienteEsistente.buttonWhite, { width: 125 }]}>
                                                    <Text style={stylesModalOptionClienteEsistente.buttonTextWhite}>
                                                        {"Anulla"}
                                                    </Text>
                                                </View>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => sellToClientEsistente()}
                                            >
                                                <View style={[stylesModalOptionClienteEsistente.buttonWhite, { width: 125 }]}>
                                                    <Text style={stylesModalOptionClienteEsistente.buttonTextWhite}>
                                                        {"Conferma"}
                                                    </Text>
                                                </View>
                                            </TouchableOpacity>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </View>
                    </View>
                </ScrollView>
            </Modal>
        </View>
    )
}

export default ModalClientExitsAtelier;

const stylesModalOptionClienteEsistente = StyleSheet.create({
    buttonTextWhite: {
        color: "black",
        alignSelf: "center",
        fontWeight: "500",
        fontSize: 18,
    },
    buttonWhite: {
        backgroundColor: "white",
        justifyContent: "center",
        borderRadius: 50,
        width: 130,
        height: 40,
        borderWidth: 1,
        borderColor: "black",
    },
    labelError: {
        fontSize: 18,
        marginLeft: 35,
        marginTop: 15,
        fontFamily: "Arsapia Regular font",
        color: "red",
    }
});



