import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react'
import { Pressable, SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { stylesRepair } from '../../GeneralStyles/SCDue/Labels';
import { BsStarFill } from "react-icons/bs";
import { styles } from '../../theme/GlobalStyle';
import ConfigCapone from '../../utils/ConfigCapone';
import * as Print from "expo-print";
import axios from "axios";
import ShowContentSidebar from '../../components/NavProfile/ShowContentSidebar';
import { useProfile } from '../../components/NavProfile/hook/useProfile';
import { useIsFocused } from '@react-navigation/native';
import { Navbar } from '../../components/Navbar';
import NavProfile from '../../components/NavProfile/NavProfile';

interface Props extends NativeStackScreenProps<any, any> { }

export const LabelsScreenListDetails = ({ route, navigation }: Props) => {

    let arrayData: any = route.params || [];
    let ListGender: any = [];
    const [titleType, setTitleType] = useState([]);
    const [listClient, setListClient] = useState([]);

    // pdf
    const [buttomHidden, setButtomHidden] = useState(false);
    const [occult, setOccult] = useState(false)
    const apiInteressiClientDetails = `${ConfigCapone.urlapi}/api/ListInteressiClient`;
    let datainput = arrayData ? arrayData.searchInputClient : "";
    let datainputImport = arrayData ? arrayData.isImportFilter : 0;
    let dname = arrayData.dname ?? '';
    let idListRoute = arrayData.idListRoute ?? 0;
    const [isAtelier, setIsAtelier] = useState(arrayData?.isAtelier ? true : false)
    let newDataClient: any = [];
    const stars = Array(3).fill(0)
    const colors = {
        orange: "black",
        grey: "#EAECEF",
    };

    const descriptionTitle = async (data: any) => {
        let name: any = []
        name = await ListGender?.find((item: any) => item.idTitle == data)
        return name?.desc_Title;
    };
    const getAllInteressiStampa = async () => {
        try {

            const response = await axios.get(
                `${apiInteressiClientDetails}/Stampa/${arrayData.idListRoute}?name=${datainput ? datainput : ""}&isImportant=${datainputImport ? 1 : 11}`
            );
            const { data } = response;
            if (data.status === "success") {
                return data.response;
            }
        } catch (error) {
            throw new Error();
        }
    };
    const client = async () => {
        let data = await getAllInteressiStampa()
        console.log(data)
        for (let index = 0; index < data.length; index++) {
            let title = await descriptionTitle(data[index].idTitle)
            await newDataClient.push({ ...data[index], idTitle: title })
        }
        setListClient(newDataClient)
    }

    const getTitle = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Title?parameter=1`)
            .then((response) => {
                setTitleType(response.data.response)
                ListGender = response.data.response
            })
            .catch((error) => console.log(error));

        await client();
    }

    console.log("arrayData", isAtelier)

    useEffect(() => {
        getTitle();
    }, [])

    const occultBottons = () => {
        printToFile()
    }

    const html: any = `
<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
          integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
      <title>Document</title>
      <style>
          p {
              / font-size:  !important; /
              font-weight: bold;
          }
          .firm{
              border-top-color: transparent;
              border-left-color: transparent;
              border-right-color: transparent;
          }
          hr {
              width: '30%';
              border-width: 2px;
          }
          input {
              / font-size: medium !important; /
              font-weight: bold;
              border: solid 2px
          }
          label {
              font-weight: bold;
          }
          h6 {
              font-size: medium;
          }
          h5 {
              font-size: medium;
              font-weight: bold;
          }
          span {
              font-weight: bold;
          }
          .printButton{
                display: none !important;
            }
      </style>
  </head>
  <body>
      <div class="container mt-3">
            
      </div>
  </body>
  </html>
  `;

    const printToFile = async () => {

        try {

            const { uri } = await Print.printToFileAsync({
                html,
            });

            setOccult(false)

        } catch (error) {
            setButtomHidden(!buttomHidden);
            setTimeout(() => {
                setOccult(false)
            }, 100);
            //setOccult(true)
        }
    };

    const { sidebarVisible, toggleSidebar } = useProfile()
    const isFocused = useIsFocused();
    console.log("as", route.params[0]?.idList ? "exist" : "no", route)
    console.log("sss", arrayData)
    return (
        !isAtelier
            ?
            <div className="container">
                <ScrollView style={styles.scroll}>
                    <SafeAreaView style={[stylesRepair.areaView]}>
                        <View style={{ paddingHorizontal: 12 }}
                        >
                            <TouchableOpacity onPress={() => {
                                //navigation.navigate("ListeScreen")
                                navigation.navigate("DetailsListeScreen", [idListRoute, dname])
                            }}>
                                <div className="backButton">
                                    <style>
                                        {`@media print {.backButton{display: none;}}`}
                                    </style>
                                    <img
                                        hidden={occult}
                                        src="https://i.postimg.cc/sXkYBB7R/icon-left.png"
                                        width="40"
                                        height="50"
                                        className="mr-4 pr-4 mt-5 mb-2"
                                        alt="Sergio Capone left "
                                    />
                                </div>
                            </TouchableOpacity>
                        </View>
                        <View style={[stylesRepair.areaContent]}>

                            <div className="printButton mb-5" style={{ float: 'right' }} >
                                <style>
                                    {`@media print {.printButton{display: none;}}`}
                                </style>
                                <Pressable
                                    style={{
                                        width: 200,
                                        marginTop: 3,
                                        marginLeft: 40,
                                        height: 35,
                                        paddingTop: 1,
                                        backgroundColor: "#09ABF7",
                                        alignItems: "center",
                                    }}
                                    onPress={occultBottons}
                                >
                                    <Text style={{ color: "white", fontSize: 20 }}>Stampa PDF </Text>
                                </Pressable>
                            </div>

                            <div className="row px-5" style={{ marginTop: -50 }}>

                                {listClient && listClient.map((data, index) => {
                                    return (
                                        <div className="col-12 col-md-6 mt-5">
                                            <div className="d-flex">
                                                <input
                                                    style={{
                                                        marginTop: '-6px',
                                                        marginRight: '20px',
                                                        pointerEvents: 'none',
                                                        visibility: data.isImportant === "0" ? 'hidden' : 'visible'
                                                    }}
                                                    type="checkbox"
                                                    checked={data.isImportant === "1"} />
                                                <h5>{data.name}</h5>
                                            </div>
                                            <div style={{ marginLeft: '34px' }}>
                                                {stars.map((_, index) => {
                                                    return (
                                                        <BsStarFill
                                                            key={index}
                                                            size={20}
                                                            color={(data.starValuation) > index ? colors.orange : colors.grey}
                                                            style={{
                                                                marginRight: 10,
                                                            }}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </View>
                    </SafeAreaView>
                </ScrollView>
            </div>
            :
            <ScrollView style={styles.scroll}>
                <div className='printButton' >
                    <ShowContentSidebar
                        sidebarVisible={sidebarVisible}
                        toggleSidebar={toggleSidebar}
                    />
                </div>
                <div className="container-layout">
                    <div className="row row-layout">
                        <div className={` ${sidebarVisible ? 'col-12 d-block' : 'col-lg-3 col-md-3 d-none d-md-block'} sidebar-container-layout sidebar-transition`}>
                            <Navbar activeNav={isFocused} componentName={'OrdiniAtelier'} componentDad="OrdiniAtelier" />
                        </div>
                        <div
                            className="col-lg-9 col-md-9 col-sm-12 content-container-layout"
                            style={{ paddingLeft: "1.5rem", overflowY: 'auto', height: '100%', scrollbarWidth: 'thin' }}
                        >
                            <div className='printButton'>
                                <NavProfile
                                    showMenuBack={route.name !== 'CrmAtelier'}
                                    sidebarVisible={sidebarVisible}
                                    toggleSidebar={toggleSidebar}
                                    goBack="ListeScreen"
                                    goBackName="Dettaglio Lista Ordini Atelier"
                                />
                            </div>

                            <div className='row'>
                                <div className="col-12">
                                    <div className="printButton mb-1" >
                                        <style>
                                            {`@media print {.printButton{display: none;}}`}
                                        </style>
                                        <Pressable
                                            style={{
                                                width: 200,
                                                marginTop: 3,
                                                height: 35,
                                                paddingTop: 1,
                                                backgroundColor: "#09ABF7",
                                                alignItems: "center",
                                            }}
                                            onPress={occultBottons}
                                        >
                                            <Text style={{ color: "white", fontSize: 20 }}>Stampa PDF </Text>
                                        </Pressable>
                                    </div>
                                </div>
                                <div className="row px-5" style={{ marginTop: -50 }}>

                                    {listClient && listClient.map((data, index) => {
                                        return (
                                            <div className="col-12 col-md-6 mt-5">
                                                <div className="d-flex">
                                                    <input
                                                        style={{
                                                            marginTop: '-6px',
                                                            marginRight: '20px',
                                                            pointerEvents: 'none',
                                                            visibility: data.isImportant === "0" ? 'hidden' : 'visible'
                                                        }}
                                                        type="checkbox"
                                                        checked={data.isImportant === "1"} />
                                                    <h5>{data.name}</h5>
                                                </div>
                                                <div style={{ marginLeft: '34px' }}>
                                                    {stars.map((_, index) => {
                                                        return (
                                                            <BsStarFill
                                                                key={index}
                                                                size={20}
                                                                color={(data.starValuation) > index ? colors.orange : colors.grey}
                                                                style={{
                                                                    marginRight: 10,
                                                                }}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollView>

    )
}