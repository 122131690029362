import { useEffect, useState } from "react";
import {
  linkNav,
  linkNavActive,
  linkNavInactive
} from "../../GeneralStyles/SCDue/HomeSCDue";
import { useNavigationWhitProps } from "../../hooks/useNavigationWhitProps";
import { Collapse } from "react-collapse";
interface Props {
  item?,
  level?, onCollapseToggle?, activeClasss?, setCollapsed?, collapsed?, index?,
  IdRole:string;
  IdBusines:string;
  activeNav?:boolean;
  componentName?:string;
}
 
export const ListItem = ({ item, level, onCollapseToggle, activeClasss, setCollapsed, collapsed, index,IdRole,IdBusines,activeNav,componentName }: Props) => {
  const { handleNavigate, } = useNavigationWhitProps();
  const classDefault = item.active ? "active" : "";
  
  const handleToggleCollapse = (index: number) => {
    setCollapsed(index);
  };

  const handleOnNavigate = (item) => {
    handleNavigate(item.component, item?.section)
  }
  

  return (
    <>
      {(item.permited.includes(IdRole) && item.bussinesPermited.includes(IdBusines)) &&
        <>
          <li
            className={(activeClasss ?? classDefault) }
            onClick={() =>
              item.subItems
                ? handleToggleCollapse(index == collapsed ? -1 : index)
                : handleOnNavigate(item)
            }
          >
            <a
            // style={{
            //   ...linkNav,
            //   ...classDefault,
            //   paddingLeft: level * 40
            // }}
            >
              <div className="icon">
                {item.icon}
              </div>
              <div className="text">{item.name}</div>
            </a>
          </li>
          <Collapse isOpened={collapsed == index} >
            {item.subItems &&
              item.subItems.map((subItem) => (              
                <ListItem
                  key={subItem.component}
                  item={subItem}
                  level={level + 1}
                  onCollapseToggle={onCollapseToggle}
                  activeClasss={`activeChildren ${(activeNav && componentName == subItem.component) ? "activeNav " :""}`}
                  IdRole={IdRole}
                  IdBusines={IdBusines}
                />
              ))
            }
          </Collapse>
        </>

      }
    </>
  );
};

