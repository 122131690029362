import { FieldErrors, UseFormRegisterReturn } from "react-hook-form";
type Props = {
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
    divProps?: React.HTMLAttributes<HTMLDivElement>;
    extraClassDiv?:string;
    extraClassLabel?:string;
    extraClassInput?:string;
    text: string;
    errors?: FieldErrors;
    name: string;
    register?: UseFormRegisterReturn;
    type:"checkbox" | "radio";
    icon?:any;
}

const InputCheck = ({ inputProps, labelProps, text, errors = {}, name, register,divProps,extraClassDiv,extraClassInput,extraClassLabel,type,icon }: Props) => {
    return (
        <div className={`form-check ${extraClassDiv}`} {...divProps} >
            <label className={`form-check-label ${extraClassLabel}`} style={{cursor:'pointer'}} {...labelProps}>
                {icon && <img src={icon} alt="" className="" style={{width:'30px',marginRight:'.3em'}} />}
                <input className={`form-check-input ${extraClassInput}`} style={{cursor:'pointer'}} type={type}  {...inputProps} {...register} />
                {text}
            </label>
            {errors[name] && (
                <div className="form-text help is-danger" role="alert">{errors[name].message}</div>
            )}
        </div>
    )
}

export default InputCheck