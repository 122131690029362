import { FaRegEdit } from "react-icons/fa";
import { HeaderBack } from "../../../components/Calender/HeaderBack";
import Loading from "../../../components/Loading";
import { NotifyDetails } from "../../../components/Notify/NotifyDetails";
import { useNewsCrud } from "../Hooks/useNewsCrud";
import { useNotifyMain } from "../../../hooks/useNotifyMain";
import { BsCheck2Circle } from "react-icons/bs";
import { useState } from "react";
import PopupConfirmationCustom from "../../../components/PopupConfirmationCustom";
import { NewsDetails } from "../Components/NewsDetails";
import { NewList } from "../../../components/News/interfaces/NewList";
import ModalNewsChecked from "../Components/ModalNewsChecked";
import ModalFormEdit from "../../../components/ModalForm/ModalFormEdit";
import useNews from '../../../components/News/hooks/useNews';
import ModalFormEditDisable from "../../../components/ModalForm/ModalFormEditDisable";

export const NewsScreen = () => {

  const {
    BussinesList,
    stateOptionNew,
    defaultListNews,
    defaultBussinesList,
    defaultUserList,
    defaultUserTecnicoList,
    UserList,
    UserTecnicoList,
    PostNews,
    DeleteNews,
    ListNewBussinesById,
    listIdNewBussinesView,
    GetListByIdNewBussines,
    confirmviewNew,
    GetListNewBussinesById,
    getPDFNews,
    getIdRole,
    PutNews
  } = useNews();

  const { view, handleView, myNotify, isLoading } = useNewsCrud();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpen, setIsOpen] = useState(false)

  const [isOpenFormEditDisabled, setIsOpenFormEditDisabled] = useState(false);

  const [isOpenFormEdit, setIsOpenFormEdit] = useState(false);

  const [activeSpinner, setActiveSpinner] = useState(false);

  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });

  const [dataEdit, setDataEdit] = useState({
    id: null,
    title: "",
    detail: "",
  });

  const [ConfirmOpen, setConfirmOpen] = useState(false)
  const [valueEditing, setValueEditing] = useState({})
  //const [isChecked, setIsChecked] = useState();
  const actionMod = async () => {
    await confirmviewNew(valueEditing)
    setConfirmOpen(!ConfirmOpen)
  }
  const cancelMod = () => {
    setValueEditing({})
  }


  const handleClick = (idNewBussinesUsers, idUser, idNewBussines, idNewUser) => {
    setValueEditing({ idNewBussinesUsers, idUser, idNewBussines, idNewUser })
    console.log("aprendatoCheckbox", idNewBussinesUsers, idUser, idNewBussines, idNewUser)
    //setIsChecked({ ...isChecked, [idNew]: checked });
    setConfirmOpen(!ConfirmOpen)
    //setIsChecked(!isChecked);
  }


  const functionPost = async (data: any) => {
    setActiveSpinner(true);
    await PostNews(data);
    setActiveSpinner(false);
    setIsOpenForm(!isOpenForm);
  };
  const functionPut = async (data: any) => {

    setActiveSpinner(true);

    await PutNews(data).then(e => {

      if (e) {
        setModalMessage({
          active: true,
          message: "Notizie modificate"
        })
      }
    });

    setActiveSpinner(false);
    setIsOpenFormEdit(false);
  };
  const deleteNew = async (id) => {
    setActiveSpinner(true);
    await DeleteNews(id).then((e) => {
      console.log(e);
      if (e.status == 'success') {
        setModalMessage({
          active: true,
          message: ""
        })
      }

      GetListNewBussinesById();
      setIsOpenFormEdit(false);
    });
    setActiveSpinner(false);
  };




  if (isLoading) return <Loading activeSpinner={isLoading} />;
  console.log("werewrewrw", ListNewBussinesById)
  //onClick={()=>setIsOpen(!isOpen)}
  // checked={isChecked}  onClick={() => handleClick()}
  console.log("valuee", valueEditing)
  return (
    <div className="square notify-main">
      <HeaderBack />
      <div className="row">
        <div className="col-12 col-md-8 mx-auto">
          <div className="notify-area card">
            <div className="card-header bg-transparent border-0">
              <h3>News</h3>
            </div>
            <div className="card-body">
              {ListNewBussinesById.normalizedResults.map(({ title, text, idNew, isChecked, idNewBussinesUsers, idUser, idNewBussines, idNewUser, nameFile, nameImage }) => {
                return (
                  <NewsDetails
                    setDataEdit={setDataEdit}
                    setIsOpenFormEdit={setIsOpenFormEdit}
                    isOpenFormEdit={isOpenFormEdit}
                    GetListByIdNewBussines={GetListByIdNewBussines}
                    idNewBussinesUsers={idNewBussinesUsers}
                    idUser={idUser}
                    idNewBussines={idNewBussines}
                    key={idNew}
                    id={idNew}
                    defaultListNews={defaultListNews}
                    text={text}
                    title={title}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    idNewUser={idNewUser}
                    isChecked={isChecked}
                    nameFile={nameFile}
                    nameImage={nameImage}
                    handleClick={handleClick}
                    getIdRole={getIdRole}
                    setIsOpenFormEditDisabled={setIsOpenFormEditDisabled}
                    isOpenFormEditDisabled={isOpenFormEditDisabled}
                  />
                );
              })}
            </div>
          </div>
          <ModalNewsChecked isOpen={isOpen} setIsOpen={setIsOpen} listIdNewBussinesView={listIdNewBussinesView} />
          <ModalFormEdit
            deleteNew={deleteNew}
            dataEdit={dataEdit}
            actionSave={functionPut}
            title={"Modifica news"}
            BussinesList={BussinesList}
            isOpen={isOpenFormEdit}
            setIsOpen={setIsOpenFormEdit}
            UserList={UserList}
            UserTecnicoList={UserTecnicoList}
            stateOptionNew={stateOptionNew}
            defaultBussinesList={defaultBussinesList}
            defaultUserList={defaultUserList}
            getPDFNews={getPDFNews}
            defaultUserTecnicoList={defaultUserTecnicoList}
          />

          <ModalFormEditDisable
            deleteNew={deleteNew}
            dataEdit={dataEdit}
            actionSave={functionPut}
            title={"Modifica news"}
            BussinesList={BussinesList}
            isOpen={isOpenFormEditDisabled}
            setIsOpen={setIsOpenFormEditDisabled}
            UserList={UserList}
            UserTecnicoList={UserTecnicoList}
            stateOptionNew={stateOptionNew}
            defaultBussinesList={defaultBussinesList}
            defaultUserList={defaultUserList}
            getPDFNews={getPDFNews}
            defaultUserTecnicoList={defaultUserTecnicoList}
          />

          {ConfirmOpen && (
            <PopupConfirmationCustom
              cancelAction={() => cancelMod()}
              title={""}
              message={"Cliccando sulla spunta confermi di aver letto la news"}
              confirmAction={() =>
                actionMod()
              }
              stateModal={ConfirmOpen}
              setStateModal={setConfirmOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
};
