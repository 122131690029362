import { View, dateFnsLocalizer } from "react-big-calendar";
import itCH from "date-fns/locale/it-CH";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { EventCalender } from "../../interfaces/calender";

export const messagesCalender = {
  next: "Prossima",
  previous: "Precedente",
  today: "Oggi",
  month: "Mese",
  week: "Settimana",
  day: "Giorno",
  agenda: "Diary",
  date: "Data",
  time: "Volta",
  event: "Evento",
  noEventsInRange: "Non ci sono eventi in questo intervallo.",
  showMore: () => "Vedi tutto",
};

export const myEventsList: EventCalender[] = [
  {
    title: "reunion",
    details: "Operational",
    id: 1,
    start: new Date("2022-07-29 12:22:00"),
    end: new Date("2022-07-29 13:42:00"),
  },
  {
    title: "reunion",
    details: "Operational",
    id: 1,
    start: new Date("2022-07-29 12:22:00"),
    end: new Date("2022-07-29 13:42:00"),
  },
  {
    title: "reunion",
    details: "Operational",
    id: 1,
    start: new Date("2022-07-29 12:22:00"),
    end: new Date("2022-07-29 13:42:00"),
  },
  {
    title: "reunion",
    details: "Operational",
    id: 1,
    start: new Date("2022-07-29 12:22:00"),
    end: new Date("2022-07-29 13:42:00"),
  },
  {
    title: "reunion",
    details: "Operational",
    id: 1,
    start: new Date("2022-07-29 12:22:00"),
    end: new Date("2022-07-29 13:42:00"),
  },
  {
    title: "reunion",
    details: "Operational",
    id: 1,
    start: new Date("2022-07-28 12:22:00"),
    end: new Date("2022-07-28 13:42:00"),
  },
  {
    title: "proyecto",
    details: "Operational",
    id: 1,
    start: new Date("2022-07-29 13:42:01"),
    end: new Date("2022-07-29 13:55:00"),
  },
  {
    title: "almuerzo",
    details: "Operational",
    id: 1,
    start: new Date("2022-07-29 14:22:00"),
    end: new Date("2022-07-29 18:42:00"),
  },
];

export const allViews: View[] = ["month", "day", "agenda"];

const locales = {
  "it-CH": itCH,
};
export const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});


