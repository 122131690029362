import { navBar } from "../../GeneralStyles/SCDue/HomeSCDue";
import { List } from "./List";
import '../../styles/NavBar.css'
type NavProps = {
  activeNav:boolean;
  componentName:string;
  componentDad?:string;
} 
export const Navbar = ({activeNav,componentName,componentDad}:NavProps) => {
  return (
    // <nav
    //   className="d-none d-md-block col-5 col-sm-4 col-md-3 pt-5"
    //   style={navBar}
    //   id="sidebar-menu"
    // >
    //   <List /> 
    // </nav>
    <nav className="navContainer" >
      <List activeNav={activeNav} componentName={componentName} componentDad={componentDad}/>
    </nav>
  );
};
