import React from "react";
import { Collapse } from "react-collapse";

import { useShow } from "../../hooks/useShow";
import { CollapseTitle } from "./CollapseTitle";
import { CheckMagazzino } from "./CheckMagazzino";

interface Props {
  handleFilterQuantita: (value: number) => void;
}
export const FilterMagazzinoQuantita = ({ handleFilterQuantita }: Props) => {
  const { show, toggle } = useShow();

  return (
    <>
      <CollapseTitle title="Quantità     " toggle={toggle} />
      <Collapse isOpened={show}>
        <div>
          <CheckMagazzino
            id="0"
            marginleft
            name="ugualezero"
            text="Uguale a 0"
            handlechange={handleFilterQuantita}
          />
          <br />
        </div>
      </Collapse>
    </>
  );
};
