import { Image, SafeAreaView, ScrollView, StatusBar, StyleSheet, Text, TouchableOpacity, View, FlatList, Pressable, TextInput } from 'react-native'
export const stylesRepair = StyleSheet.create({
    areaView: {
        flex: 1,
        paddingTop: StatusBar.currentHeight,
        backgroundColor: "white",
    },
    areaContent: {
        flex: 1,
        paddingHorizontal: 30,
        paddingVertical: 20,
    },
    sectionTitle: {
        fontWeight: "500",
        fontSize: 20,
        alignSelf: "center",
    },
    arrowDown: {
        width: 20,
        height: 20,
        marginTop: 18,
        tintColor: "#808080",
        alignSelf: "center",
    },
    subSearch: {
        alignSelf: "flex-end",
        borderColor: "#A4A4A4",
        paddingRight: 2,
        paddingLeft: 15,
        fontSize: 20,
    },
    input: {
        height: 45,
        width: "100%",
        borderWidth: 2,
        fontFamily: "Arsapia Regular font",
        padding: 6,
        borderRadius: 50,
        marginRight: "1%",
    },
    viewDisable: {
        backgroundColor: "black",
        borderRadius: 50,
        justifyContent: "center",
        width: 40,
        height: 40,
        marginTop: "2%",
    },
    subIconSearch: {
        width: 20,
        height: 20,
        tintColor: "white",
        alignSelf: "center",
    },
    search: {
        marginLeft: "-100%"
    },
    imageWithMargin: {
        width: 15,
        marginLeft: 30,
        height: 15,
        tintColor: "rgb(200,200,200)",
    },
});

export const styleSearch = {
    styleSearchInput: {
        borderWidth: 1,
        marginTop: -15,
        paddingTop: 15,
        marginLeft: 2,
        marginRight: 35,
        borderTopWidth: 0,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    }
}