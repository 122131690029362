import React, { useEffect, useState } from "react";
import {
  SafeAreaView,
  StatusBar,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
} from "react-native";
import { HeaderBar } from "../components/HeaderBar";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RepairsScreen } from "./RepairsScreen";
import {
  getClients,
  getServicesClient,
  getTitlesFromId,
  IClient,
  IServiceClient,
  ITitle,
} from "../api/ClientService";
import "bootstrap/dist/css/bootstrap.min.css";
import { RootStackParams } from "../navigator/NavigatorConfig";
import { useIsFocused } from "@react-navigation/native";
import Loading from "../components/Loading";
import PopupMessage from "../components/PopupMessage";
import { styles } from "../theme/GlobalStyle";
// @ts-ignore
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "../theme/styleBackground.css";
import { todayFormatDate } from "../utils/helpers";
// @ts-ignore
import ReactExport from "react-data-export";
import { useNotifyCrud } from "../hooks/useNotifyCrud";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

interface Props
  extends NativeStackScreenProps<RootStackParams, "ContactScreen"> {
  isAtelier?: boolean;
}
export const ContactScreen = ({ route, navigation, isAtelier = false }: Props) => {
  const { createdPosted } = useNotifyCrud();
  const [number, setNumber] = useState(5);
  const [viewPagination, setViewPagination] = useState<boolean>(true);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [serviceSelected, setServiceSelected] = useState<IServiceClient[]>();
  const [clientSelected, setClientSelected] = useState<IClient>();
  const [blockRight, setBlockRight] = useState<boolean>(false);
  const [blockLeft, setBlockLeft] = useState<boolean>(true);
  const [toSearch, setToSearch] = useState<string>("");
  const [navs, setNavs] = useState([]);
  const [totalListUsers, setTotalListUsers] = useState<IClient[]>([]);
  const [userFromAPI, setUserFromAPI] = useState<IClient[]>([]);
  const [arrayData, setArrayData] = useState<any[]>([]);
  const [listUsers, setListUsers] = useState<IClient[]>([]);
  const [title, setTitle] = useState<string>("");
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [nameFileExport, setNameFileExport] = useState<any>(
    "Tabela Contati" + todayFormatDate
  );
  const [tableCsv, setTableCsv] = useState<any>([]);
  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });
  const handleSubmitVisualizza = async (data: IClient) => {
    setActiveSpinner(true);
    setClientSelected(data);
    await getTitlesFromId(data.idTitle).then((resp: ITitle) =>
      setTitle(resp.desc_Title)
    );
    await getServicesClient(data.idClient).then((resp: IServiceClient[]) => {
      resp.map(async (c: IServiceClient) => {
        c.title = title;
        c.dateInsert = c.dateInsert
          .split("T")[0]
          .toString()
          .split("-")
          .reverse()
          .join("/");
      });
      if (resp.length > 0) {
        setModalVisible(true);
        setServiceSelected(resp);
        setActiveSpinner(false);
      } else {
        setActiveSpinner(false);
        setModalMessage({
          active: true,
          message: "Non ci sono informazioni da mostrare",
        });
      }
    });
    setActiveSpinner(false);
  };
  const handleNextUsers = () => {
    let dataPagination: IClient[] = arrayData.slice(number, number + 5);
    setListUsers(dataPagination);
    setNumber(number + 5);
    setBlockLeft(false);
    if (number + 5 >= arrayData.length) {
      setBlockRight(true);
    }
  };
  const handlePreviousUsers = () => {
    let data: IClient[] = arrayData.slice(number - 10, number - 5);
    setNumber(number - 5);
    setListUsers(data);
    setBlockRight(false);
    if (number - 5 <= 5) {
      setBlockLeft(true);
    }
  };
  const handleSearch = async () => {
    if (toSearch) {
      let client = arrayData.filter(
        (data: any) =>
          data.name &&
          data.name.toLocaleLowerCase().includes(toSearch.toLocaleLowerCase())
      );
      let emailArray = arrayData.filter(
        (data: any) =>
          data.email &&
          data.email.toLocaleLowerCase().includes(toSearch.toLocaleLowerCase())
      );
      let phoneArray = arrayData.filter(
        (data: any) =>
          data.phone &&
          data.phone.toLocaleLowerCase().includes(toSearch.toLocaleLowerCase())
      );
      let finalConcat = client.concat(emailArray.concat(phoneArray));
      const uniqueArray = [...new Set(finalConcat)];
      if (uniqueArray.length === 0) {
        restartPaginationSearch(userFromAPI);
        setModalMessage({ active: true, message: "Dati non trovati" });
      } else {
        restartPaginationSearch(uniqueArray);
      }
    } else {
      restartPaginationSearch(userFromAPI);
    }
  };
  const handleViewPageSearch = (page: number, resp: []) => {
    const newPage = page * 5;
    if (resp.length > 0) {
      setListUsers(resp?.slice(page * 5 - 5, page * 5));
    } else {
      setListUsers(resp?.slice(page * 5 - 5, page * 5));
    }
    setNumber(newPage);
    setBlockRight(false);
    setBlockLeft(true);
    if (page > 1) {
      setBlockLeft(false);
    }
    if (navs.length == 0) {
      if (page == numPages) {
        setBlockRight(true);
      }
    } else {
      if (page == navs.length) {
        setBlockRight(true);
      }
    }
  };
  const loadNumberPagesSearch = (pages: number, resp: []) => {
    numPages = pages;
    let navsAux: any = [];
    for (let i = 0; i < pages; i++) {
      navsAux.push(
        <TouchableOpacity
          key={i}
          onPress={() => handleViewPageSearch(i + 1, resp)}
        >
          <View>
            <Text
              style={{
                fontWeight: "400",
                color: "black",
                fontSize: 14,
                paddingHorizontal: 10,
              }}
            >
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>»</Text>
              )}
              {i + 1}
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>«</Text>
              )}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }
    setNavs(navsAux);
  };
  let numPages = 0;
  const loadNumberPages = (pages: number = 1) => {
    numPages = pages;
    let navsAux: any = [];
    for (let i = 0; i < pages; i++) {
      navsAux.push(
        <TouchableOpacity key={i} onPress={() => handleViewPage(i + 1)}>
          <View>
            <Text
              style={{
                fontWeight: "400",
                color: "black",
                fontSize: 14,
                paddingHorizontal: 10,
              }}
            >
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>»</Text>
              )}
              {i + 1}
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>«</Text>
              )}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }
    setNavs(navsAux);
  };
  const handleViewPage = (page: number) => {
    const newPage = page * 5;
    if (arrayData.length > 0) {
      setListUsers(arrayData?.slice(page * 5 - 5, page * 5));
    } else {
      setListUsers(dataAux?.slice(page * 5 - 5, page * 5));
    }
    setNumber(newPage);
    setBlockRight(false);
    setBlockLeft(true);
    if (page > 1) {
      setBlockLeft(false);
    }
    if (navs.length == 0) {
      if (page == numPages) {
        setBlockRight(true);
      }
    } else {
      if (page == navs.length) {
        setBlockRight(true);
      }
    }
  };
  let dataAux: any = [];
  const restartPagination = (response: IClient[]) => {
    dataAux = response;

    let resp = response.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    setTotalListUsers(resp);
    setArrayData(resp);
    setUserFromAPI(resp);
    setListUsers(resp.slice(0, 5));
    const numberPages = Math.ceil(resp.length / 5);
    loadNumberPages(numberPages);
    setViewPagination(true);
    setBlockLeft(true);
    setBlockRight(false);
    setNumber(5);
    if (resp.length <= 5) {
      setViewPagination(false);
    } else {
      setViewPagination(true);
    }
  };
  const restartPaginationSearch = (response: any) => {
    dataAux = response;
    let resp = response.sort(function (a: any, b: any) {
      return a.name.localeCompare(b.name);
    });
    setTotalListUsers(resp);
    setArrayData(resp);
    setListUsers(resp.slice(0, 5));
    const numberPages = Math.ceil(resp.length / 5);
    loadNumberPagesSearch(numberPages, resp);
    setViewPagination(true);
    setBlockLeft(true);
    setBlockRight(false);
    setNumber(5);
    if (resp.length <= 5) {
      setViewPagination(false);
    } else {
      setViewPagination(true);
    }
  };
  useEffect(() => {
    loadNumberPages(
      Math.ceil((totalListUsers ? totalListUsers.length : 0) / 5)
    );
  }, [number]);
  useEffect(() => {
    getClients().then((resp: IClient[]) => {
      restartPagination(resp);

      setTableCsv(resp);
    });
  }, [useIsFocused]);

  const DataSet = [
    {
      columns: [
        { title: "Cliente", style: { font: { sz: "18", bold: true } } },
        { title: "E-mail", style: { font: { sz: "18", bold: true } } },
        { title: "Telefono", style: { font: { sz: "18", bold: true } } },
      ],
      data:
        tableCsv &&
        tableCsv.map((data: any) => [
          { value: data.name, style: { font: { sz: "14", bold: false } } },
          {
            value: data.email ? data.email : "N/D",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.phone ? data.phone : "N/D",
            style: { font: { sz: "14", bold: false } },
          },
        ]),
    },
  ];
  return (
    <ScrollView style={styles.scroll}>
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: StatusBar.currentHeight,
          backgroundColor: "white",
        }}
      >
        {activeSpinner && <Loading activeSpinner={activeSpinner} />}
        {!isAtelier &&
          <View
            style={{
              paddingHorizontal: 12,
            }}
          >
            <HeaderBar
              title={"Contatti"}
              onBack={() => navigation.navigate("HomeScreen")}
              iconHeader={require("../assets/iconoskubitsoft/user.png")}
            />
          </View>
        }
        <View
          style={{
            flex: 1,
            paddingHorizontal: 20,
            paddingVertical: 20,
          }}
        >
          <div
            className="row"
            style={{
              paddingRight: 12,
              paddingLeft: 12,
            }}
          >
            <div className="col-xl-6 col-lg-5 col-md-2 col-sm-0 col-0 p-0"></div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-7 col-6 p-0">
              <div className="mt-2">
                <Text
                  style={{
                    marginTop: 10,
                    fontWeight: "600",
                    fontSize: 20,
                    marginRight: 30,
                    alignSelf: "center",
                    marginBottom: "1%",
                  }}
                >
                  Anagrafica clienti
                </Text>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-6 p-0 d-flex justify-content-center">
              <TextInput
                placeholder="Cerca cliente..."
                style={[
                  stylesRepair.input,
                  {
                    alignSelf: "flex-end",
                    paddingRight: 2,
                    paddingLeft: 15,
                    fontSize: 20,
                  },
                ]}
                onChangeText={(text) => setToSearch(text)}
              />
              <TouchableOpacity
                onPress={() => handleSearch()}
                style={{
                  margin: "auto",
                }}
              >
                <View
                  style={{
                    backgroundColor: "black",
                    borderRadius: 50,
                    justifyContent: "center",
                    width: 40,
                    height: 40,
                  }}
                >
                  <Image
                    style={{
                      width: 20,
                      height: 20,
                      tintColor: "white",
                      alignSelf: "center",
                    }}
                    source={require("../assets/icons/icon-search.png")}
                  />
                </View>
              </TouchableOpacity>
            </div>
          </div>
          <div className="table-responsive">
            <ExcelFile
              filename={nameFileExport}
              element={
                <button
                  onClick={() => createdPosted({
                    message: 'Esportazione dei dati del modulo di Clienti - Contatti',
                    actionLog: 'Esportare'
                  })}
                  type="button"
                  className="dowloandPdf">
                  Esporta
                </button>
              }
            >
              <ExcelSheet dataSet={DataSet} name="pruen 1 reporte" />
            </ExcelFile>

            <table className="table table-borderless mt-5 ">
              <thead
                style={{ backgroundColor: "black", color: "white", height: 50 }}
              >
                <tr className="mr-4">
                  <th
                    style={{
                      borderTopLeftRadius: 50,
                      borderBottomLeftRadius: 50,
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  ></th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      verticalAlign: "middle",
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  >
                    Cliente
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      verticalAlign: "middle",
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  >
                    E-mail
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      verticalAlign: "middle",
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  >
                    Telefono
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      verticalAlign: "middle",
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  >
                    Riparazioni
                  </th>
                  <th
                    style={{
                      borderTopRightRadius: 50,
                      borderBottomRightRadius: 50,
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {listUsers &&
                  listUsers.map((data, key) => {
                    return (
                      <tr
                        key={data.idClient}
                        style={{ borderBottom: "1px solid gray" }}
                      >
                        <td></td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="align-self-center mr-4 p-0"
                        >
                          {data.name}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="align-self-center mr-4 px-4"
                        >
                          {data.email}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="align-self-center mr-4 px-4"
                        >
                          {data.phone}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="align-self-center px-4"
                        >
                          <button
                            style={{
                              flex: 1,
                              width: 110,
                              height: 40,
                              alignSelf: "center",
                              backgroundColor: "transparent",
                              borderRadius: 50,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              handleSubmitVisualizza(data);
                            }}
                          >
                            Visualizza
                          </button>
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {viewPagination && (
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="table-responsive">
                  <View style={{ flexDirection: "row", marginTop: 15 }}>
                    {!blockLeft && (
                      <TouchableOpacity onPress={handlePreviousUsers}>
                        <View>
                          <Image
                            style={{
                              width: 15,
                              marginRight: 30,
                              height: 15,
                              tintColor: "rgb(200,200,200)",
                            }}
                            source={require("../assets/icons/icon-left.png")}
                          />
                        </View>
                      </TouchableOpacity>
                    )}
                    {navs}
                    {!blockRight && (
                      <TouchableOpacity onPress={handleNextUsers}>
                        <View>
                          <Image
                            style={{
                              width: 15,
                              marginLeft: 30,
                              height: 15,
                              tintColor: "rgb(200,200,200)",
                            }}
                            source={require("../assets/icons/icon-right.png")}
                          />
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                </div>
              </div>
            </div>
          )}

          {modalMessage.active && (
            <PopupMessage
              title={"Informazione"}
              message={modalMessage.message}
              stateModal={modalMessage.active}
              setStateModal={setModalMessage}
            />
          )}
          <div className="row">
            <RepairsScreen
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              dataClient={clientSelected}
              dataService={serviceSelected}
            />
          </div>
        </View>
      </SafeAreaView>
    </ScrollView>
  );
};
const stylesRepair = StyleSheet.create({
  input: {
    height: 45,
    width: "100%",
    borderWidth: 2,
    fontFamily: "Arsapia Regular font",
    padding: 6,
    borderRadius: 50,
    marginRight: "1%",
  },
  picker: {
    height: 40,
    width: 283,
    alignSelf: "flex-end",
    fontFamily: "Arsapia Regular font",
    borderWidth: 2,
    paddingHorizontal: 10,
    borderRadius: 50,
    marginVertical: 6,
  },
  statePicker: {
    height: 20,
    width: 85,
    margin: 12,
    fontSize: 10,
    alignSelf: "center",
    borderWidth: 2,
    paddingHorizontal: 10,
    borderRadius: 50,
    marginVertical: 6,
    borderColor: "white",
  },
});
