import { useIsFocused } from "@react-navigation/native";
import ShowContentSidebar from "../../../../components/NavProfile/ShowContentSidebar"
import { useProfile } from "../../../../components/NavProfile/hook/useProfile";
import { Navbar } from "../../../../components/Navbar";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import NavProfile from "../../../../components/NavProfile/NavProfile";
import InputComponent from "../../../../components/ComponentsAtelier/inputs/inputComponent";
import useCrmAtelierForm, { valuesProdotto, valuesProdottoOsservato, valuesRelazione } from "../hook/useCrmAtelierForm";
import InputCheck from "../../components/InputChek";
import ButtonComponent from "../../../../components/ComponentsAtelier/buttoms/buttonComponent";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { BsStarFill } from "react-icons/bs";
import { IoMdAlert } from "react-icons/io";
import { FormatDDMMYY, FormatHHSS, FormatYYMMDD, formatDateItalianFromDB, valdiateYear } from "../../../../utils/FormatingDate";
import PopUpMessageWithRoute from "../../../../components/PopUpMessageWithRoute";
import Loading from "../../../../components/Loading";
import { useEffect } from "react";
import { enJoinMagazzinoCrm } from "../schemas/Schemas";
import PopupConfirmation from "../../../../components/PopupConfirmation";
import { SendNotificationScreen } from "../../../SendNotificationScreen";
import { SendEmailScreen } from "../../../SendEmailScreen";
import RepairCardScreen from "../../../RepairCardScreen";
import { useCrmAtelierAssistenza } from "../hook/useCrmAtelierAssistenza";
import { TouchableOpacity, View, Image } from "react-native";
import '../styles/mano.css'

interface Props extends NativeStackScreenProps<any, any> {

}
const AggiungiCrmAtelier = ({ route, navigation }: Props) => {

    const isFocused = useIsFocused();
    const { sidebarVisible, toggleSidebar } = useProfile();
    const { handleSubmit, onSubmit, register, errors, append, fields, handleRemoveProduttoProsseduti, handleShowMano, showMano, appendPO, fieldsPO,
        handleremoveProdottiOsservati, handleShowProdottoOsservati, showProdottoOsservati, appendR, fieldR, handleRemoveRelazioni, bussines, addressType,
        gender, hobby, phtoneType, relationShip, title, brand, listClients, handleChangeOptionSelect, watch, handleSelectStarts, starts, startsSelect,
        listSearchClientTableCrm, statusList, findByClientLimitList, modalMessageWhitUrl, loading, clientSearch, handleConfirm, handleChangeRelazioni,
        handleSelectRelazioni, listClientsR, setListSearchClientTableCrm, edit, setValue, totalProductPosseduti
    } = useCrmAtelierForm(
        {
            dataProciduti: route.params?.dataMagazzino,
            option: route.params?.option?.option,
            IdClientEdit: route.params?.clientList,
            clear: route.params?.clear,
        }
    );

    const { handleChangeStatoTableCrm, confirmDeleteCrm, modalRepair, setModalRepair,
        handleDeleteCrm, idStateRepair, handleNotice, dataClientRepair, modalNoticeVisible, setModalNoticeVisible, modalEmail, setModalEmail, userListAssistenza,
        goToRepairTab, modalVisibleTabRepair, setModalVisibleTabRepair, clientAssistenzia, dataRepair, operatore, dataTableSend,
        viewPagination, blockLeft, handlePreviousUsers, navs, blockRight, handleNextUsers, stylesCrmRepair, listUsers
    } = useCrmAtelierAssistenza({ listSearchClientTableCrm, setListSearchClientTableCrm })

    const alert = require('../../../../assets/icons/icon-note.png');
    const iconDelete = require('../../../../assets/icons/icon-delete.png');
    const manoSinistra = require('../../../../assets/icons-step-due/sagoma-mano-sinistra.png');
    const manoDestra = require('../../../../assets/icons-step-due/sagoma-mano-destra.png');
    const bell = require("../../../../assets/icons/Campana-resize.png")
    const deleteIcon = require("../../../../assets/icons/icon-delete.png")

    return (
        <>
            {loading && <Loading activeSpinner={loading} />}
            <ShowContentSidebar
                sidebarVisible={sidebarVisible}
                toggleSidebar={toggleSidebar}
            />
            <div className="container-layout">
                <div className="row row-layout">
                    <div className={` ${sidebarVisible ? 'col-12 d-block' : 'col-lg-3 col-md-none d-none d-md-non d-lg-block'} sidebar-container-layout sidebar-transition`}  >
                        <Navbar activeNav={isFocused} componentName={route.name} componentDad="CrmAtelier" />
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12 content-container-layout" style={{ paddingLeft: '1.5rem' }}>
                        <NavProfile
                            showMenuBack={route.name !== 'CrmAtelier'}
                            sidebarVisible={sidebarVisible}
                            toggleSidebar={toggleSidebar}
                            goBack="CrmAtelier"
                            goBackName="Crm Atelier"
                        />
                        <div className='magazzino-atelier-form-container'>
                            <div className='form-magazzino-atelier'>
                                <form onSubmit={handleSubmit(onSubmit)} style={{ overflowX: 'hidden' }}>
                                    <div className="row m-0 p-0 ">
                                        <h5 className="col-lg-4 col-md-4 col-sm-12">Aggiungi cliente</h5>
                                        <div className="col-lg-8 col-md-8 col-sm-12 d-flex gap-2 justify-content-end ">
                                            <InputCheck
                                                text="Importante"
                                                name="IsImportant"
                                                register={register('IsImportant',)}
                                                type="checkbox"
                                            />
                                            <InputCheck
                                                text="Presente in lista"
                                                name="presenteLista"
                                                register={register('presenteLista',)}
                                                type="checkbox"
                                                inputProps={{ disabled: true, style: { cursor: 'text' } }}
                                                labelProps={{ style: { cursor: 'text' } }}
                                            />
                                            <InputCheck
                                                text="Black List"
                                                name="BlackList"
                                                register={register('BlackList',)}
                                                type="checkbox"
                                            />
                                        </div>
                                    </div>
                                    <InputComponent
                                        name="idTitle"
                                        label="Titolo *"
                                        type="select"
                                        register={register('idTitle', { required: 'Il campo è obbligatorio' })}
                                        errors={errors}
                                        options={title}

                                    />
                                    <InputComponent
                                        name="Name"
                                        label="Nome e Cognome *"
                                        type="text"
                                        register={register('Name', { required: 'Il campo è obbligatorio', onChange: (evt) => handleChangeRelazioni(evt) })}
                                        errors={errors}
                                        optionsSelect={listClients}
                                        onOptionSelect={handleChangeOptionSelect}
                                    />
                                    <InputCheck
                                        text="Invia catalogo a casa"
                                        name="EnviaCatalogo"
                                        register={register('EnviaCatalogo',)}
                                        type="checkbox"
                                    />
                                    <div className="d-flex flex-column gap-2 mt-1">
                                        <label style={{ 'fontWeight': 500 }}>Genere</label>
                                        <div className="row m-0 p-0" >
                                            {gender.map((item, index) => (
                                                <InputCheck
                                                    key={index}
                                                    text={item.label}
                                                    name="idGender"
                                                    inputProps={{ value: item.value }}
                                                    register={register('idGender',)}
                                                    extraClassDiv="col-3"
                                                    type="radio"
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column gap-2 mt-1">
                                        <label style={{ 'fontWeight': 500 }}>Indirizzo</label>
                                        <div className="row m-0 p-0" >
                                            {addressType.map((item, index) => (
                                                <InputCheck
                                                    key={index}
                                                    text={item.label}
                                                    name="idAddressType"
                                                    inputProps={{ value: item.value }}
                                                    register={register('idAddressType',)}
                                                    extraClassDiv="col-3"
                                                    type="radio"
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="row p-0 m-0 g-3 justify-content-between">
                                        <div className="col-lg-7 p-0 ">
                                            <InputComponent
                                                name="AddressStreet"
                                                label="Via/Piazza"
                                                type="text"
                                                register={register('AddressStreet', { /*required: 'Il campo è obbligatorio' */ })}

                                                errors={errors}
                                            />
                                        </div>
                                        <div className="col-lg-4 p-0 ">
                                            <InputComponent
                                                name="AddressNumber"
                                                label="N°"
                                                type="text"
                                                register={register('AddressNumber', { /*required: 'Il campo è obbligatorio' */ })}
                                                errors={errors}
                                            />
                                        </div>
                                    </div>
                                    <InputComponent
                                        name="AddressCity"
                                        label="Cittá"
                                        type="text"
                                        register={register('AddressCity', { /*required: 'Il campo è obbligatorio' */ })}
                                        errors={errors}
                                    />
                                    <div className="row p-0 m-0 justify-content-between">
                                        <div className="col-lg-4 p-0">
                                            <InputComponent
                                                name="ZipCode"
                                                label="CAP"
                                                type="text"
                                                register={register('ZipCode', { /*required: 'Il campo è obbligatorio' */ })}
                                                errors={errors}
                                            />
                                        </div>
                                        <div className="col-7 p-0">
                                            <InputComponent
                                                name="Province"
                                                label="Provincia"
                                                type="text"
                                                register={register('Province', { /*required: 'Il campo è obbligatorio' */ })}
                                                errors={errors}
                                            />
                                        </div>
                                    </div>
                                    <InputComponent
                                        name="Country"
                                        label="Nazione"
                                        type="text"
                                        register={register('Country', { /*required: 'Il campo è obbligatorio' */ })}
                                        errors={errors}
                                    />
                                    <div className="d-flex flex-column gap-2 mt-1">
                                        <label style={{ 'fontWeight': 500 }}>Telefono</label>
                                        <div className="row m-0 p-0" >
                                            {phtoneType.map((item, index) => (
                                                <InputCheck
                                                    key={index}
                                                    text={item.label}
                                                    name="PhoneType"
                                                    inputProps={{ value: item.value }}
                                                    register={register('PhoneType',)}
                                                    extraClassDiv="col-3"
                                                    type="radio"
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <InputComponent
                                        name="Phone"
                                        label="Numero *"
                                        type="text"
                                        register={register('Phone', { required: 'Il campo è obbligatorio' })}
                                        errors={errors}
                                    />
                                    <InputComponent
                                        name="email"
                                        label="E-mail"
                                        type="email"
                                        register={register('Email', { /*required: 'Il campo è obbligatorio' */ })}
                                        errors={errors}
                                    />
                                    <InputComponent
                                        name="Profession"
                                        label="Professione"
                                        type="text"
                                        register={register('Profession', { /*required: 'Il campo è obbligatorio' */ })}
                                        errors={errors}
                                    />
                                    <InputComponent
                                        name="DateBirth"
                                        label="Data di nascita"
                                        type="date"
                                        register={register('DateBirth', {
                                            onChange: (evt) => {
                                                const { value } = evt.target;
                                                const dateValue = new Date(value)
                                                const currentDate = new Date();
                                                const selectedYear = dateValue.getFullYear();
                                                const currentYear = currentDate.getFullYear();
                                                (selectedYear > currentYear) ? setValue("DateBirth", FormatYYMMDD(new Date(dateValue.setFullYear(currentYear)))) : "";
                                            }
                                        })}
                                        errors={errors}
                                    />
                                    <InputComponent
                                        name="OrigenIdBussines"
                                        label="Negozio"
                                        type="text"
                                        register={register('OrigenIdBussines', { /*required: 'Il campo è obbligatorio' */ })}
                                        errors={errors}
                                        disabledInput
                                        inputProps={{ style: { zIndex: 0 } }}

                                    />
                                    <div className="d-flex flex-column gap-2 mt-1">
                                        <label style={{ 'fontWeight': 500 }}>Prodotti posseduti</label>
                                        <div className="w-100">
                                            {fields.map((item, index) => (
                                                <>
                                                    <div className="row p-0 m-0 justify-content-between" key={`${item.id}-${index}`}>
                                                        <div className="col-6 p-0">
                                                            <InputComponent
                                                                name={`prodottiPosseduti.${index}.prodotto`}
                                                                label="Prodotto"
                                                                type="text"
                                                                register={register(`prodottiPosseduti.${index}.Prodotto`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                disabledInput={item.idMatricola ? true : false}
                                                            />
                                                        </div>
                                                        <div className="col-5 p-0 d-flex ">

                                                            <InputComponent
                                                                name={`prodottiPosseduti.${index}.brand`}
                                                                label="Brand"
                                                                type="select"
                                                                register={register(`prodottiPosseduti.${index}.idBrand`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                options={brand}
                                                                selectProps={{ disabled: item.idMatricola ? true : false }}
                                                            />
                                                            <button className="mt-2" type="button" style={{ background: 'none', border: 'none', }} onClick={() => handleRemoveProduttoProsseduti(index, item.idClientProdPosseduti)}><img className='img-magazzino-form' src={iconDelete} /></button>
                                                        </div>
                                                    </div>
                                                    <div className="row p-0 m-0 justify-content-between">
                                                        <div className="col-6 p-0 m-0"></div>
                                                        <div className="col-5 p-0 m-0">
                                                            <InputComponent
                                                                name={`prodottiPosseduti.${index}.referenza`}
                                                                label="Referenza"
                                                                type="text"
                                                                register={register(`prodottiPosseduti.${index}.Referenza`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                width="92%"
                                                                disabledInput={item.idMatricola ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row p-0 m-0 justify-content-between">
                                                        <div className="col-6 p-0 m-0"></div>
                                                        <div className="col-5 p-0 m-0">
                                                            <InputComponent
                                                                name={`prodottiPosseduti.${index}.matricola`}
                                                                label="Matricola"
                                                                type="text"
                                                                register={register(`prodottiPosseduti.${index}.Matricola`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                width="92%"
                                                                disabledInput={item.idMatricola ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row p-0 m-0 justify-content-between">
                                                        <div className="col-6 p-0 m-0"></div>
                                                        <div className="col-5 p-0 m-0">
                                                            <InputComponent
                                                                name={`prodottiPosseduti.${index}.data`}
                                                                label="Data"
                                                                type="date"
                                                                register={register(`prodottiPosseduti.${index}.Data`, {
                                                                    onChange: (evt) => {
                                                                        const { value } = evt.target;
                                                                        const dateValue = new Date(value)
                                                                        const currentDate = new Date();
                                                                        const selectedYear = dateValue.getFullYear();
                                                                        const currentYear = currentDate.getFullYear();
                                                                        (selectedYear > currentYear) ? setValue(`prodottiPosseduti.${index}.Data`, FormatYYMMDD(new Date(dateValue.setFullYear(currentYear)))) : "";
                                                                    }
                                                                })}
                                                                errors={errors}
                                                                width="92%"
                                                                disabledInput={item.idMatricola ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row p-0 m-0 justify-content-between">
                                                        <div className="col-6 p-0 m-0"></div>
                                                        <div className="col-5 p-0 m-0">
                                                            <InputComponent
                                                                name={`prodottiPosseduti.${index}.prezzo`}
                                                                label="Prezzo"
                                                                type="number"
                                                                register={register(`prodottiPosseduti.${index}.Prezzo`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                width="92%"
                                                                disabledInput={item.idMatricola ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row p-0 m-0 justify-content-between">
                                                        <div className="col-6 p-0 m-0"></div>
                                                        <div className="col-5 p-0 m-0">
                                                            <InputComponent
                                                                name={`prodottiPosseduti.${index}.prezzoApplicato`}
                                                                label="Prezzo Applicato"
                                                                type="number"
                                                                register={register(`prodottiPosseduti.${index}.PrezzoApplicato`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                width="92%"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row p-0 m-0 justify-content-between">
                                                        <div className="col-6 p-0 m-0"></div>
                                                        <div className="col-5 p-0 m-0 d-flex justify-content-end " style={{ width: '95%' }}>
                                                            <InputCheck
                                                                text="Invia e-mail"
                                                                name={`prodottiPosseduti.${index}.inviaEmail`}
                                                                register={register(`prodottiPosseduti.${index}.SendEmail`,)}
                                                                extraClassDiv=""
                                                                type="checkbox"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ))}

                                            {
                                                fields.length > 0 &&
                                                <div className="row mb-2 mt-2">
                                                    <div className="col-7" ></div>
                                                    <div className="col-5 p-0" style={{ fontSize: 18 }}>
                                                        <strong>Totale spesa: {totalProductPosseduti} €</strong>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>


                                    <a className="d-flex gap-3 align-items-center" style={{ fontWeight: '900', textDecoration: 'none', color: "inherit", cursor: 'pointer' }} onClick={() =>
                                        append(valuesProdotto)
                                    }>
                                        <span style={{ fontSize: 35, }}>+</span>
                                        <span >Aggiungi prodotto</span>
                                    </a>
                                    <a className="d-flex gap-3 align-items-center" style={{ fontWeight: '900', textDecoration: 'none', color: "inherit", cursor: 'pointer' }} onClick={() =>
                                        navigation.navigate("MagazzinoCercaAtelierScreen", { option: enJoinMagazzinoCrm.produttoProceduti })
                                    }>
                                        <span style={{ fontSize: 35, }}>+</span>
                                        <span >Aggiungi prodotto da magazzino</span>
                                    </a>
                                    <div className="d-flex flex-column gap-2 mt-1">
                                        <label style={{ 'fontWeight': 500 }}>Hobby, sport, interessi</label>
                                        <div className="row p-0 m-0">
                                            {hobby.map((item, index) => (
                                                <div className="col-lg-3 col-md-3 col-sm-6 p-0 mx-0 mt-2">
                                                    <InputCheck
                                                        key={index}
                                                        text={item.label}
                                                        name={`hobby`}
                                                        inputProps={{ value: item.value }}
                                                        register={register(`hobby`,)}
                                                        extraClassDiv=""
                                                        type="checkbox"
                                                        icon={require(`../../.././../assets/icons-step-due/${item.icon}`)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column gap-2 mt-1">
                                        <label style={{ 'fontWeight': 500 }}>Note</label>
                                        <textarea name="" id="" className="w-100 p-4" style={{ border: '2px solid #000', minHeight: '10em', resize: 'vertical', borderRadius: '.5em' }} {...register('Note')}>

                                        </textarea>
                                    </div>
                                    <div className="d-flex flex-column gap-2 mt-2">
                                        <label style={{ 'fontWeight': 500 }} onClick={() => { handleShowMano() }}>Misure mano {showMano && 'sinistra'}
                                            {!showMano ? <IoIosArrowDown className='' style={{ fontSize: '1.5em', cursor: 'pointer', transition: 'all 1s ease' }} /> : <IoIosArrowUp style={{ fontSize: '1.5em', cursor: 'pointer', transition: 'all 1s ease' }} />}</label>
                                        {showMano &&
                                            <>
                                                <div className="row justify-content-center px-0  m-0 gap-5 position-relative" style={{ paddingTop: '5em', left: '15px' }}>
                                                    <div className=" p-0 m-0 d-flex justify-content-center position-relative " >
                                                        <div className="position-absolute MeasureLittleFingerLH" style={{

                                                        }}>
                                                            <InputComponent
                                                                name={`MeasureLittleFingerLH`}
                                                                label="Mignolo"
                                                                type="number"
                                                                register={register(`MeasureLittleFingerLH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                inputProps={{ style: { background: 'transparent' } }}
                                                            />
                                                        </div>
                                                        <div className="position-absolute MeasureRingFingerLH" >
                                                            <InputComponent
                                                                name={`MeasureRingFingerLH`}
                                                                label="Anulare"
                                                                type="number"
                                                                register={register(`MeasureRingFingerLH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                inputProps={{ style: { background: 'transparent' } }}
                                                            />
                                                        </div>
                                                        <div className="position-absolute MeasureMiddleFingerLH" >
                                                            <InputComponent
                                                                name={`MeasureMiddleFingerLH`}
                                                                label="Medio"
                                                                type="number"
                                                                register={register(`MeasureMiddleFingerLH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                inputProps={{ style: { background: 'transparent' } }}
                                                            />
                                                        </div>
                                                        <div className="position-absolute MeasureIndexFingerLH" >
                                                            <InputComponent
                                                                name={`MeasureIndexFingerLH`}
                                                                label="Indice"
                                                                type="number"
                                                                register={register(`MeasureIndexFingerLH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                inputProps={{ style: { background: 'transparent' } }}
                                                            />
                                                        </div>
                                                        <div className="position-absolute MeasureThumbLH" >
                                                            <InputComponent
                                                                name={`MeasureThumbLH`}
                                                                label="Pollice"
                                                                type="number"
                                                                register={register(`MeasureThumbLH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                inputProps={{ style: { background: 'transparent' } }}
                                                            />
                                                        </div>
                                                        <div className="position-absolute MeasurePulseLH text-center">
                                                            <InputComponent
                                                                name={`MeasurePulseLH`}
                                                                label="Polso"
                                                                type="number"
                                                                register={register(`MeasurePulseLH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                inputProps={{ style: { background: 'transparent' } }}
                                                            />
                                                        </div>
                                                        <img src={manoSinistra} alt="" className="" style={{ width: '380px', height: '520' }} />
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column gap-2 mt-2">
                                                    <label style={{ 'fontWeight': 500 }} onClick={() => { }}>Misure mano destra <IoIosArrowDown className='' style={{ fontSize: '1.5em' }} /></label>
                                                    <div className="row justify-content-center px-0  m-0 gap-5 position-relative" style={{ paddingTop: '5em', right: '13px' }}>
                                                        <div className="col-12 p-0 m-0 d-flex justify-content-center" style={{ position: 'relative', }}>
                                                            <div className="position-absolute MeasureLittleFingerRH">
                                                                <InputComponent
                                                                    name={`MeasureLittleFingerRH`}
                                                                    label="Mignolo"
                                                                    type="number"
                                                                    register={register(`MeasureLittleFingerRH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                    errors={errors}
                                                                    inputProps={{ style: { background: 'transparent' } }}
                                                                />
                                                            </div>
                                                            <div className="position-absolute MeasureRingFingerRH">
                                                                <InputComponent
                                                                    name={`MeasureRingFingerRH`}
                                                                    label="Anulare"
                                                                    type="number"
                                                                    register={register(`MeasureRingFingerRH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                    errors={errors}
                                                                    inputProps={{ style: { background: 'transparent' } }}
                                                                />
                                                            </div>
                                                            <div className="position-absolute MeasureMiddleFingerRH " >
                                                                <InputComponent
                                                                    name={`MeasureMiddleFingerRH`}
                                                                    label="Medio"
                                                                    type="number"
                                                                    register={register(`MeasureMiddleFingerRH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                    errors={errors}
                                                                    inputProps={{ style: { background: 'transparent' } }}
                                                                />
                                                            </div>
                                                            <div className="position-absolute MeasureIndexFingerRH">
                                                                <InputComponent
                                                                    name={`MeasureIndexFingerRH`}
                                                                    label="Indice"
                                                                    type="number"
                                                                    register={register(`MeasureIndexFingerRH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                    errors={errors}
                                                                    inputProps={{ style: { background: 'transparent' } }}
                                                                />
                                                            </div>
                                                            <div className="position-absolute MeasureThumbRH" >
                                                                <InputComponent
                                                                    name={`MeasureThumbRH`}
                                                                    label="Pollice"
                                                                    type="number"
                                                                    register={register(`MeasureThumbRH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                    errors={errors}
                                                                    inputProps={{ style: { background: 'transparent' } }}
                                                                />
                                                            </div>
                                                            <div className="position-absolute MeasurePulseRH text-center" >
                                                                <InputComponent
                                                                    name={`MeasurePulseRH`}
                                                                    label="Polso"
                                                                    type="number"
                                                                    register={register(`MeasurePulseRH`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                    errors={errors}
                                                                    inputProps={{ style: { background: 'transparent' } }}
                                                                />
                                                            </div>
                                                            <img src={manoDestra} alt="" className="" style={{ width: '380px', height: '520' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {(watch().dataAggiornamentoCliente || watch().dataInserimentoCliente) &&
                                        <div className="w-100 d-flex flex-column gap-2 mt-2">
                                            <InputComponent
                                                name={`dataInserimentoCliente`}
                                                label="Data di inserimento cliente"
                                                type="text"
                                                register={register(`dataInserimentoCliente`, { /*required: 'Il campo è obbligatorio' */ })}
                                                errors={errors}
                                                disabledInput
                                            />
                                            <InputComponent
                                                name={`dataAggiornamentoCliente`}
                                                label="Data di aggiornamento cliente"
                                                type="text"
                                                register={register(`dataAggiornamentoCliente`, { /*required: 'Il campo è obbligatorio' */ })}
                                                errors={errors}
                                                disabledInput
                                            />
                                        </div>
                                    }

                                    <div className="d-flex flex-column gap-2 mt-2">
                                        <label style={{ 'fontWeight': 500 }} onClick={() => { handleShowProdottoOsservati() }}>Prodotti osservati
                                            {!showProdottoOsservati ? <IoIosArrowDown className='' style={{ fontSize: '1.5em', cursor: 'pointer', transition: 'all 1s ease' }} /> : <IoIosArrowUp style={{ fontSize: '1.5em', cursor: 'pointer', transition: 'all 1s ease' }} />}</label>
                                        {
                                            (showProdottoOsservati || fieldsPO.length > 0) &&
                                            <>
                                                <div className="w-100">
                                                    {fieldsPO.map((field, index) => (
                                                        <div className="d-flex align-items-center" key={`${field.id}-${index}`}>
                                                            <InputComponent
                                                                name={`prodottoOsservati.${index}.description`}
                                                                label=""
                                                                type="text"
                                                                register={register(`prodottoOsservati.${index}.description`, { /*required: 'Il campo è obbligatorio' */ })}
                                                                errors={errors}
                                                                width="92%"
                                                                disabledInput={field.idMatricola ? true : false}
                                                            />
                                                            <button className="" type="button" style={{ background: 'none', border: 'none', }} onClick={() => handleremoveProdottiOsservati(index, field.idClientProdAsservati)}><img className='img-magazzino-form' src={iconDelete} /></button>
                                                        </div>

                                                    ))}
                                                </div>
                                                <div className="w-100">
                                                    <a className="d-flex gap-3 align-items-center" style={{ fontWeight: '900', textDecoration: 'none', color: "inherit", cursor: 'pointer' }} onClick={() =>
                                                        appendPO(valuesProdottoOsservato)
                                                    }>
                                                        <span style={{ fontSize: 35, }}>+</span>
                                                        <span >Aggiungi prodotto</span>
                                                    </a>
                                                    <a className="d-flex gap-3 align-items-center" style={{ fontWeight: '900', textDecoration: 'none', color: "inherit", cursor: 'pointer' }} onClick={() =>
                                                        navigation.navigate("MagazzinoCercaAtelierScreen", { option: enJoinMagazzinoCrm.produtoOsservati })
                                                    }>
                                                        <span style={{ fontSize: 35, }}>+</span>
                                                        <span >Aggiungi prodotto da magazzino</span>
                                                    </a>
                                                </div>
                                            </>

                                        }

                                    </div>
                                    {clientSearch &&
                                        <div className="d-flex flex-column gap-2 mt-1">
                                            <label style={{ 'fontWeight': 500 }}>Stelle valutazione cliente</label>
                                            <div className="w-100">
                                                {Array(3).fill(0).map((_, index) => {
                                                    return (
                                                        <BsStarFill
                                                            key={index}
                                                            size={40}
                                                            onClick={() => handleSelectStarts(index + 1, false)}
                                                            onMouseOver={() => handleSelectStarts(index + 1)}
                                                            onMouseLeave={() => handleSelectStarts(0)}
                                                            color={(starts || startsSelect > index) ? "#000" : "#EAECEF"}
                                                            style={{
                                                                marginRight: 50,
                                                                cursor: "pointer",
                                                                marginTop: 10
                                                            }}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    }

                                    <div className="d-flex flex-column gap-2 mt-1">
                                        <label style={{ 'fontWeight': 500 }}>Relazioni (familiari, amici, colleghi)</label>
                                        <div className="w-100 ">
                                            {fieldR.map((field, index) => (
                                                <div className="w-100 d-flex align-items-center">
                                                    <div className="w-100">
                                                        <InputComponent
                                                            name={`relazioni.${index}.Name`}
                                                            label="Nome e cognome"
                                                            type="text"
                                                            focus={false}
                                                            register={register(`relazioni.${index}.Name`, { onChange: (evt) => handleChangeRelazioni(evt, index) })}
                                                            optionsSelect={listClientsR?.index == index ? listClientsR.listClientsR : []}
                                                            onOptionSelect={(id) => { handleSelectRelazioni(id, index) }}
                                                        />
                                                        <InputComponent
                                                            name={`relazioni.${index}.idRelationship`}
                                                            label="Relazione"
                                                            type="select"
                                                            register={register(`relazioni.${index}.idRelationship`, { /*required: 'Il campo è obbligatorio' */ })}
                                                            errors={errors}
                                                            options={relationShip}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <button className="" type="button" style={{ background: 'none', border: 'none', }} onClick={() => handleRemoveRelazioni(index, field.idClientRelationship)}><img className='img-magazzino-form' src={iconDelete} /></button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <a className="d-flex gap-3 align-items-center" style={{ fontWeight: '900', textDecoration: 'none', color: "inherit", cursor: 'pointer' }} onClick={() =>
                                            appendR(valuesRelazione)
                                        }>
                                            <span style={{ fontSize: 35, }}>+</span>
                                            <span >Aggiungi</span>
                                        </a>
                                    </div>
                                    {(listSearchClientTableCrm.length > 0) &&
                                        <div className="table-responsive" >
                                            <table className="table table-borderless mt-3 mx-0" id="table-to-xls"  >
                                                <thead style={{ backgroundColor: "black", color: "white", height: 50 }}>
                                                    <tr className="mr-4">
                                                        <th
                                                            style={{
                                                                borderTopLeftRadius: 50,
                                                                borderBottomLeftRadius: 50,
                                                            }}
                                                            scope="col"
                                                            className="align-self-center mr-4 px-4"
                                                        ></th>
                                                        <th
                                                            scope="col"
                                                            className="align-self-center mr-4 px-4"
                                                        ></th>
                                                        <th
                                                            scope="col"
                                                            className="align-self-center mr-4 px-4 align-middle text-center fw-normal"
                                                        >
                                                            Cliente
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="align-self-center mr-4 px-4 align-middle text-center fw-normal"
                                                        >
                                                            Orologio
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="align-self-center mr-4 px-4 align-middle text-center fw-normal"
                                                        >
                                                            Data
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="align-self-center mr-4 px-4 align-middle text-center fw-normal"
                                                        >
                                                            Negozio
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="align-self-center mr-4 px-4 align-middle text-center fw-normal"
                                                        >
                                                            Stato
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="align-self-center mr-4 px-4 align-middle text-center fw-normal"
                                                        ></th>
                                                        <th
                                                            scope="col"
                                                            className="align-self-center mr-4 px-4 align-middle text-center fw-normal"
                                                        ></th>
                                                        <th style={{ borderTopRightRadius: 50, borderBottomRightRadius: 50, }}
                                                            scope="col"
                                                            className="align-self-center mr-4 px-4"
                                                        ></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listUsers?.map((item, index) => (
                                                        <tr
                                                            key={index}
                                                            style={{ borderBottom: "1px solid 	gray" }}
                                                        >
                                                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                {(item.isEqualOrMore45Days && item.idStatus != '5') && (
                                                                    <IoMdAlert
                                                                        cursor={'pointer'}
                                                                        color="#f50"
                                                                        size={30}
                                                                    />
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                className="align-self-center mr-4 px-4"
                                                            >
                                                                <button
                                                                    style={{ background: 'none', border: 'none' }}
                                                                    type="button"
                                                                    onClick={() => {
                                                                        goToRepairTab(item)
                                                                    }}
                                                                >
                                                                    <img
                                                                        style={{ width: 30, height: 30 }}
                                                                        src={alert}
                                                                    />
                                                                </button>
                                                            </td>
                                                            <td
                                                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                className="align-self-center"
                                                            >
                                                                {item.name}
                                                            </td>
                                                            <td
                                                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                className="align-self-center"
                                                            >
                                                                {item.desc_Product
                                                                    ? item.desc_Product
                                                                    : "Nessun dato"}
                                                            </td>
                                                            <td
                                                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                className="align-self-center mr-4 px-4"
                                                            >
                                                                {FormatDDMMYY(item.dateService)}
                                                            </td>
                                                            <td
                                                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                className="align-self-center mr-4 px-4"
                                                            >
                                                                {item.desc_Bussines
                                                                    ? item.desc_Bussines
                                                                    : "Nessun dato"}
                                                            </td>
                                                            <td>
                                                                {(item.desc_Status == "Concluso" && item.isMore72Hours > '72')
                                                                    ? <div style={{ textAlign: "center", verticalAlign: "middle", marginTop: 7.5 }}
                                                                        className="align-self-center mr-4 px-4">{item.desc_Status}</div>
                                                                    :
                                                                    <div className="w-100">
                                                                        <select
                                                                            className="form-select selectedState m-auto w-auto border-0"
                                                                            onChange={(evt) => handleChangeStatoTableCrm(evt, item.idClient, item.idService)}

                                                                        >
                                                                            <option value="">seleccione</option>
                                                                            {statusList.map((d) => (
                                                                                <option
                                                                                    selected={
                                                                                        d.value === item.status.idStatus
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    value={d.value}
                                                                                >
                                                                                    {d.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td
                                                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                className="align-self-center mr-4 px-4"
                                                            >
                                                                <button
                                                                    style={{
                                                                        flex: 1,
                                                                        width: 100,
                                                                        height: 40,
                                                                        alignSelf: "center",
                                                                        backgroundColor: "transparent",
                                                                        borderRadius: 50,
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        //pointerEvents: isEmployee ? 'none' : 'all'
                                                                    }}
                                                                    onClick={() => {
                                                                        handleNotice(item, "Avvisa");
                                                                    }}
                                                                    type="button"
                                                                >
                                                                    Avvisa
                                                                </button>
                                                            </td>
                                                            <td
                                                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                                            >
                                                                <button
                                                                    className="border-0 bg-transparent"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        handleNotice(item, "ring");
                                                                    }}
                                                                >
                                                                    <img src={bell} alt="" style={{ width: 20, height: 20 }} />
                                                                </button>
                                                            </td>
                                                            <td
                                                                style={{ textAlign: "center", verticalAlign: "middle" }}
                                                            >
                                                                <button className="border-0 bg-transparent" type="button"
                                                                    onClick={(e) => { confirmDeleteCrm(item.idService) }} >
                                                                    <img src={deleteIcon} alt="" style={{ width: 20, height: 20, cursor: 'pointer' }} />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {viewPagination && (
                                                <div className="row" style={{ width: '90%' }}>
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <div className="table-responsive">
                                                            <View style={{ flexDirection: "row", marginTop: 15 }}>
                                                                {!blockLeft && (
                                                                    <TouchableOpacity onPress={handlePreviousUsers}>
                                                                        <View>
                                                                            <Image
                                                                                style={{
                                                                                    width: 15,
                                                                                    marginRight: 30,
                                                                                    height: 15,
                                                                                    tintColor: "rgb(200,200,200)",
                                                                                }}
                                                                                source={require("../../../../assets/icons/icon-left.png")}
                                                                            />
                                                                        </View>
                                                                    </TouchableOpacity>
                                                                )}
                                                                {navs}
                                                                {!blockRight && (
                                                                    <TouchableOpacity onPress={handleNextUsers}>
                                                                        <View>
                                                                            <Image
                                                                                style={stylesCrmRepair.imageWithMargin}
                                                                                source={require("../../../../assets/icons/icon-right.png")}
                                                                            />
                                                                        </View>
                                                                    </TouchableOpacity>
                                                                )}
                                                            </View>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <a className="d-flex gap-3 align-items-center"
                                                style={{ fontWeight: '900', textDecoration: 'none', color: "inherit", cursor: 'pointer', width: 300 }}
                                                onClick={() => navigation.navigate('NuovaRiparazione', { dataCrm: clientSearch })}
                                            >
                                                <span style={{ fontSize: 35, }}>+</span>
                                                <span >Aggiungi</span>
                                            </a>
                                        </div>
                                    }

                                    {(watch().schedaCreata) &&
                                        <div className="d-flex flex-column gap-2 mt-2">
                                            <div className="w-100">
                                                <InputComponent
                                                    label="Scheda creata da"
                                                    name="schedaCreata"
                                                    register={register(`schedaCreata`)}
                                                    type="text"
                                                    disabledInput
                                                />
                                            </div>
                                        </div>
                                    }
                                    {findByClientLimitList &&
                                        <div className="d-flex flex-column gap-2 mt-1">
                                            <label style={{ 'fontWeight': 500 }}>Aggiornamenti</label>
                                            {findByClientLimitList.map((item, index) => (
                                                <div className="w-100">
                                                    <InputComponent
                                                        label={`${formatDateItalianFromDB(item.dateInsert)}`}
                                                        name=""
                                                        type="text"
                                                        inputProps={{ defaultValue: item.name + " - " + item.desc_Role }}
                                                        disabledInput
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    <InputCheck
                                        text="Deprecati"
                                        name={`Deprecated`}
                                        register={register(`Deprecated`,)}
                                        extraClassDiv=""
                                        type="checkbox"
                                    />
                                    <div className='footer-form-magazzino '>
                                        <ButtonComponent text={`Salva ${edit ? "Modifiche" : ""}`} type="submit" />
                                    </div>
                                </form>
                                <PopUpMessageWithRoute
                                    routeNav={modalMessageWhitUrl.route}
                                    dataNav={[]}
                                    title={modalMessageWhitUrl.title}
                                    message={modalMessageWhitUrl.message}
                                    stateModal={modalMessageWhitUrl.active}
                                    // setStateModal={setmodaleMessaggeWithUrl}
                                    confirmAction={handleConfirm}
                                />

                                {modalRepair && (
                                    <PopupConfirmation
                                        title={""}
                                        message={"Sei sicuro di eliminare la riparazione?"}
                                        confirmAction={() => handleDeleteCrm(clientSearch.idClient)}
                                        stateModal={modalRepair}
                                        setStateModal={setModalRepair}
                                    />
                                )}

                                {modalNoticeVisible && (
                                    <SendNotificationScreen
                                        currentPage='state_repair'
                                        modalVisible={modalNoticeVisible}
                                        setModalVisible={setModalNoticeVisible}
                                        clientData={dataClientRepair}
                                    />
                                )}

                                {modalEmail && (
                                    <SendEmailScreen
                                        modalVisible={modalEmail}
                                        setModalVisible={setModalEmail}
                                        clientData={dataClientRepair}
                                        userList={userListAssistenza}
                                    />
                                )}

                                <RepairCardScreen
                                    modalVisible={modalVisibleTabRepair}
                                    setModalVisible={setModalVisibleTabRepair}
                                    dataClient={clientAssistenzia}
                                    dataService={dataRepair}
                                    operatore={operatore}
                                    navigation={navigation}
                                    activeButton={true}
                                    dataTableSend={dataTableSend}
                                    isAtelier
                                />
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default AggiungiCrmAtelier