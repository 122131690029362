import React from 'react'
import { Image, View } from 'react-native';

import { RoundedButtonStyle } from '../theme/RoundedButtonStyle';

interface Props{
    style?: {
        button: Object,
        icon: Object,
    },
    icon: any,
    size?: number,
    styleIcon?: number,
    position?: {
        top?: number,
        bottom?: number,
        right?: number,
        left?: number
    }
}

export const RoundedButton = ({style=RoundedButtonStyle, icon, size=50, position, styleIcon}:Props) => {
    return (
        <View
            style={[style.button, position, {
                width: size,
                height: size,  
                zIndex:1
            }]}
        >
          
            <Image 
                style={[{
                    width: (styleIcon?styleIcon:180),
                    height: (styleIcon?styleIcon:180),                               
                }]}
                source={icon}
            />
        </View>
    )
}