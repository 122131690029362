import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Axios } from 'axios';
import React, { useEffect, useState } from 'react'
import { Pressable, SafeAreaView, ScrollView, StatusBar, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { HeaderBar } from '../../components/HeaderBar'
import { stylesRepair } from '../../GeneralStyles/SCDue/Labels';
import { styles} from '../../theme/GlobalStyle';
import ConfigCapone from '../../utils/ConfigCapone';
import * as Print from "expo-print";
import axios from "axios";

interface Props extends NativeStackScreenProps<any, any> {}

export const LabelsScreen = ({ route, navigation }: Props) => {

  let arrayData: any = route.params || [];
  let ListGender:any=[];
  const [titleType, setTitleType] = useState([]);
  const [listClient, setListClient] = useState([]);

  // pdf
  const [buttomHidden, setButtomHidden] = useState(false);
  const [occult, setOccult] = useState(false)

  let dataClient = arrayData ? arrayData : "";
  let newDataClient:any=[];

  const descriptionTitle = async (data:any) => {
    let name:any=[]
    name = await ListGender?.find( (item:any) => item.idTitle == data) 
    return name?.desc_Title;
  };

  const client = async() => { 
    console.log("dataclient: ",dataClient);
    
    let newData:any;
    dataClient.limit = 0;
    await axios
    .get(`${ConfigCapone.urlapi}/api/Client/SearchListClientFilterCrm`, {params:dataClient})
    .then((response) => {    
        newData = response.data.response;
    }).catch((err) => console.log(err));

    for (let index = 0; index < newData?.length; index++) {      
        let title = await descriptionTitle(newData[index].idTitle)
        await newDataClient.push({...newData[index], idTitle: title})           
    }

    console.log("new data  : ", newData );
    console.log("new data 1 : ", newDataClient );
    
    setListClient(newDataClient)
  }

  const getTitle = async() => {
    await axios(`${ConfigCapone.urlapi}/api/Title?parameter=1`)
    .then((response) => {
        setTitleType(response.data.response)
        ListGender = response.data.response
    })
    .catch((error) => console.log(error));

    await client();
  }

  useEffect(() => {
    getTitle();
  }, []) 

  const occultBottons = () => {
    printToFile()
  }

  const html: any = `
<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
          integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
      <title>Document</title>
      <style>
          p {
              / font-size:  !important; /
              font-weight: bold;
          }
          .firm{
              border-top-color: transparent;
              border-left-color: transparent;
              border-right-color: transparent;
          }
          hr {
              width: '30%';
              border-width: 2px;
          }
          input {
              / font-size: medium !important; /
              font-weight: bold;
              border: solid 2px
          }
          label {
              font-weight: bold;
          }
          h6 {
              font-size: medium;
          }
          h5 {
              font-size: medium;
              font-weight: bold;
          }
          span {
              font-weight: bold;
          }
          .printButton{
                display: none !important;
            }
      </style>
  </head>
  <body>
      <div class="container mt-3">
            
      </div>
  </body>
  </html>
  `;

  const printToFile = async () => {

        try {

            const { uri } = await Print.printToFileAsync({
                html,
            });

            setOccult(false)

        } catch (error) {
            setButtomHidden(!buttomHidden);
            setTimeout(() => {
                setOccult(false)
            }, 100);
            //setOccult(true)
        }
  };
console.log("as",route.params[0]?.idList?"exist":"no",route)
  return (
    <div className="container">
        <ScrollView style={styles.scroll}>
            <SafeAreaView style={[stylesRepair.areaView]}>
                <View style={{paddingHorizontal: 12}}
                >
                    <TouchableOpacity onPress={() =>route.params[0]?.idList?navigation.navigate("ListeScreen"):navigation.navigate("ClientListScreen")}>
                    <div className="backButton">
                        <style>
                            {`@media print {.backButton{display: none;}}`}
                        </style>
                            <img
                                hidden={occult}
                                src="https://i.postimg.cc/sXkYBB7R/icon-left.png"
                                width="40"
                                height="50"
                                className="mr-4 pr-4 mt-5 mb-2"
                                alt="Sergio Capone left "
                            />
                    </div>
                    </TouchableOpacity>
                </View>
                <View style={[stylesRepair.areaContent]}>

                    <div className="printButton mb-5" style={{float:'right'}} >
                        <style>
                            {`@media print {.printButton{display: none;}}`}
                        </style>
                        <Pressable
                            style={{
                                width: 200,
                                marginTop: 3,
                                marginLeft: 40,
                                height:35,
                                paddingTop:1,
                                backgroundColor: "#09ABF7",
                                alignItems: "center",
                            }}
                            onPress={occultBottons}
                        >
                        <Text style={{ color: "white", fontSize:20 }}>Stampa PDF </Text>
                        </Pressable>
                    </div>

                    <div className="row px-5" style={{marginTop:-50}}> 

                    {listClient && listClient.map((data,index) => {
                        return (
                            <div className="col-12 col-md-6 mt-5">
                                <h5>
                                    {data.idTitle} {data.name}
                                </h5>
                                <h5>
                                    {data.address} <br />
                                </h5>
                            </div>    
                        )
                    })}
                        
                    </div>
                </View>
            </SafeAreaView>
        </ScrollView>
    </div>
  )
}