import { Image } from "react-native";
import { stylesDueHome } from "../../GeneralStyles/SCDue/HomeSCDue";

export const Logo = () => {

  const logo = require("../../assets/images/logo-scapone.png")
  return (
    // <div className="row py-4 m-0">
    //   <div className="col-12 py-5 d-flex justify-content-center">
    //     <Image
    //       style={stylesDueHome.logoCapone}
    //       source={require("../../assets/images/logo-scapone.png")}
    //     />
    //   </div>
    // </div>
    <li className="logo">
      <img src={logo} alt="" className="" />
    </li>
  );
};
