export const formatMessageEmailInteressi = (
    title: string,
    name: string,
    refDesc:string,
    desc_bussines: string,
    country
  ) => {
    let VariableMessageEmail;
    console.log(country);
    const titleVariable = title ? title : "";
    let countryVerify =""

    return VariableMessageEmail = `
        
        <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
            <head>
                <meta http-equiv="Content-Type" content="text/html; " />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="format-detection" content="telephone=no" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Auguri di buon compleanno!</title>
                <style type="text/css" emogrify="no">
                    #outlook a {
                        padding: 0;
                    }
                    .ExternalClass {
                        width: 100%;
                    }
                    .ExternalClass,
                    .ExternalClass p,
                    .ExternalClass span,
                    .ExternalClass font,
                    .ExternalClass td,
                    .ExternalClass div {
                        line-height: 100%;
                    }
                    table td {
                        border-collapse: collapse;
                        mso-line-height-rule: exactly;
                    }
                    .editable.image {
                        font-size: 0 !important;
                        line-height: 0 !important;
                    }
                    .nl2go_preheader {
                        display: none !important;
                        mso-hide: all !important;
                        mso-line-height-rule: exactly;
                        visibility: hidden !important;
                        line-height: 0px !important;
                        font-size: 0px !important;
                    }
                    body {
                        width: 100% !important;
                        -webkit-text-size-adjust: 100%;
                        -ms-text-size-adjust: 100%;
                        margin: 0;
                        padding: 0;
                    }
                    img {
                        outline: none;
                        text-decoration: none;
                        -ms-interpolation-mode: bicubic;
                    }
                    a img {
                        border: none;
                    }
                    table {
                        border-collapse: collapse;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                    }
                    th {
                        font-weight: normal;
                        text-align: left;
                    }
                    *[class="gmail-fix"] {
                        display: none !important;
                    }
                </style>
                <style type="text/css" emogrify="no"></style>
                <style type="text/css" emogrify="no"></style>
                <!--[if !mso]><!-->
                <style type="text/css" emogrify="no"></style>
                <!--<![endif]-->
                <style type="text/css">
                    p,
                    h1,
                    h2,
                    h3,
                    h4,
                    ol,
                    ul,
                    li {
                        margin: 0;
                    }
                    a,
                    a:link {
                        color: #0092ff;
                        text-decoration: underline;
                    }
                    .nl2go-default-textstyle {
                        color: #3b3f44;
                        font-family: arial, helvetica, sans-serif;
                        font-size: 16px;
                        line-height: 1.5;
                        word-break: break-word;
                    }
                    .default-button {
                        color: #ffffff;
                        font-family: arial, helvetica, sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: bold;
                        line-height: 1.15;
                        text-decoration: none;
                        word-break: break-word;
                    }
                    .default-heading1 {
                        color: #1f2d3d;
                        font-family: arial, helvetica, sans-serif;
                        font-size: 36px;
                        word-break: break-word;
                    }
                    .default-heading2 {
                        color: #1f2d3d;
                        font-family: arial, helvetica, sans-serif;
                        font-size: 32px;
                        word-break: break-word;
                    }
                    .default-heading3 {
                        color: #1f2d3d;
                        font-family: arial, helvetica, sans-serif;
                        font-size: 24px;
                        word-break: break-word;
                    }
                    .default-heading4 {
                        color: #1f2d3d;
                        font-family: arial, helvetica, sans-serif;
                        font-size: 18px;
                        word-break: break-word;
                    }
                    a[x-apple-data-detectors] {
                        color: inherit !important;
                        text-decoration: inherit !important;
                        font-size: inherit !important;
                        font-family: inherit !important;
                        font-weight: inherit !important;
                        line-height: inherit !important;
                    }
                    .no-show-for-you {
                        border: none;
                        display: none;
                        float: none;
                        font-size: 0;
                        height: 0;
                        line-height: 0;
                        max-height: 0;
                        mso-hide: all;
                        overflow: hidden;
                        table-layout: fixed;
                        visibility: hidden;
                        width: 0;
                    }
                </style>
                <!--[if mso]>
                    <xml>
                        <o:OfficeDocumentSettings> <o:AllowPNG /> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings>
                    </xml>
                <![endif]-->
                <style type="text/css">
                    a:link {
                        color: #0092ff;
                        text-decoration: underline;
                    }
                </style>
            </head>
            <body yahoo="fix" text="#3b3f44" link="#0092ff">
                <div class="moz-text-html" lang="x-unicode">
                    <!--[if mso]> <img width="1" height="1" src="https://9f4lr.r.bh.d.sendibt3.com/mk/op/sh/1f8JAEiNUvxLDIiLD2Jbnxbwbl/XiB2IqidWFEQ" /> <![endif]-->
                    <!--[if !mso]> <!---->
                    <img style="display: none;" src="https://9f4lr.r.bh.d.sendibt3.com/mk/op/sh/1f8JAEiNUvxLDIiLD2Jbnxbwbl/XiB2IqidWFEQ" />
                    <!-- <![endif]-->
                    <table role="presentation" class="nl2go-body-table" style="width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                        <tbody>
                            <tr>
                                <td>
                                    <table role="presentation" class="r0-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                        <tbody>
                                            <tr>
                                                <td class="r1-i" style="background-color: #ffffff;" valign="top">
                                                    <table role="presentation" class="r3-o" style="table-layout: fixed; width: 600px;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
                                                        <tbody>
                                                            <tr>
                                                                <td class="r4-i">
                                                                    <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th class="r5-c" style="font-weight: normal;" width="100%" valign="middle">
                                                                                    <table role="presentation" class="r6-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="r7-i" style="padding-left: 10px; padding-right: 10px;" valign="top">
                                                                                                    <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td class="r8-c" align="center">
                                                                                                                    <table
                                                                                                                        role="presentation"
                                                                                                                        class="r9-o"
                                                                                                                        style="table-layout: fixed; width: 570px;"
                                                                                                                        width="570"
                                                                                                                        cellspacing="0"
                                                                                                                        cellpadding="0"
                                                                                                                        border="0"
                                                                                                                    >
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td class="r10-i" style="font-size: 0px; line-height: 0px; padding-bottom: 15px; padding-top: 15px;">
                                                                                                                                    <img
                                                                                                                                        src="https://9f4lr.img.bh.d.sendibt3.com/im/sh/wUuJd1KeZp0g.png?u=7126MWSP0tEIB8aZ3EY01qppgPd"
                                                                                                                                        style="display: block; width: 100%;"
                                                                                                                                        sib_img_id="2"
                                                                                                                                        width="570"
                                                                                                                                        border="0"
                                                                                                                                    />
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table role="presentation" class="r3-o" style="table-layout: fixed; width: 600px;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
                                        <tbody>
                                            <tr>
                                                <td valign="top">
                                                    <table role="presentation" class="r12-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                                        <tbody>
                                                            <tr>
                                                                <td class="r13-i" style="background-color: #ffffff; padding-bottom: 20px; padding-top: 20px;">
                                                                    <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th class="r14-c" style="font-weight: normal;" width="100%" valign="top">
                                                                                    <table role="presentation" class="r6-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="r7-i" style="padding-left: 15px; padding-right: 15px;" valign="top">
                                                                                                    <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td class="r11-c" align="center">
                                                                                                                    <table
                                                                                                                        role="presentation"
                                                                                                                        class="r0-o"
                                                                                                                        style="table-layout: fixed; width: 570px;"
                                                                                                                        width="570"
                                                                                                                        cellspacing="0"
                                                                                                                        cellpadding="0"
                                                                                                                        border="0"
                                                                                                                    >
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td class="r10-i" style="font-size: 0px; line-height: 0px; padding-bottom: 15px; padding-top: 15px;">
                                                                                                                                    <img
                                                                                                                                        src="https://9f4lr.img.bh.d.sendibt3.com/im/sh/haPYplm4DZ4K.jpg?u=7126MWSP0tEIBEdHI3dbw0LPII9"
                                                                                                                                        style="display: block; width: 100%;"
                                                                                                                                        sib_img_id="3"
                                                                                                                                        width="570"
                                                                                                                                        border="0"
                                                                                                                                    />
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td class="r11-c" align="center">
                                                                                                                    <table
                                                                                                                        role="presentation"
                                                                                                                        class="r0-o"
                                                                                                                        style="table-layout: fixed; width: 104px;"
                                                                                                                        width="104"
                                                                                                                        cellspacing="0"
                                                                                                                        cellpadding="0"
                                                                                                                        border="0"
                                                                                                                    >
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td class="r10-i" style="font-size: 0px; line-height: 0px; padding-bottom: 15px; padding-top: 15px;">
                                                                                                                                    <img
                                                                                                                                        src="https://9f4lr.img.bh.d.sendibt3.com/im/sh/nl0s1RgTODFo.png?u=7126MWSP0tEIBKfzWsjDq9qyuAf"
                                                                                                                                        style="display: block; width: 100%;"
                                                                                                                                        sib_img_id="4"
                                                                                                                                        width="104"
                                                                                                                                        border="0"
                                                                                                                                    />
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table role="presentation" class="r12-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                                        <tbody>
                                                            <tr>
                                                                <td class="r13-i" style="background-color: #ffffff; padding-bottom: 20px; padding-top: 20px;">
                                                                    <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th class="r14-c" style="font-weight: normal;" width="100%" valign="top">
                                                                                    <table role="presentation" class="r6-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="r7-i" style="padding-left: 15px; padding-right: 15px;" valign="top">
                                                                                                    <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td class="r15-c" align="left">
                                                                                                                    <table
                                                                                                                        role="presentation"
                                                                                                                        class="r16-o"
                                                                                                                        style="table-layout: fixed; width: 100%;"
                                                                                                                        width="100%"
                                                                                                                        cellspacing="0"
                                                                                                                        cellpadding="0"
                                                                                                                        border="0"
                                                                                                                    >
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td
                                                                                                                                    class="r17-i nl2go-default-textstyle"
                                                                                                                                    style="
                                                                                                                                        color: #3b3f44;
                                                                                                                                        font-family: arial, helvetica, sans-serif;
                                                                                                                                        font-size: 16px;
                                                                                                                                        word-break: break-word;
                                                                                                                                        line-height: 1.5;
                                                                                                                                        padding-bottom: 15px;
                                                                                                                                        padding-top: 15px;
                                                                                                                                        text-align: center;
                                                                                                                                    "
                                                                                                                                    valign="top"
                                                                                                                                    align="center"
                                                                                                                                >
                                                                                                                                    <div>
                                                                                                                                        <p style="margin: 0;">
                                                                                                                                            <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                                <i>Gentile ${titleVariable} ${name},</i>
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        <p style="margin: 0;">
                                                                                                                                            <br />
                                                                                                                                            <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                                <i>
                                                                                                                                                    è stato un piacere accoglierLa nella nostra boutique Sergio Capone. Questa mail per
                                                                                                                                                    confermarLe di aver acquisito la Sua manifestazione di interesse per
                                                                                                                                                </i>
                                                                                                                                            </span>
                                                                                                                                            <br />
                                                                                                                                            <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                                <i>la ref. ${refDesc}.</i>
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        <p style="margin: 0;">
                                                                                                                                            <br />
                                                                                                                                            <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                                <i>
                                                                                                                                                    I termini di attesa per l’acquisto del prodotto sono dovuti agli altissimi standard
                                                                                                                                                    qualitativi del Brand che ne limitano la produzione.
                                                                                                                                                </i>
                                                                                                                                            </span>
                                                                                                                                            <br />
                                                                                                                                            <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                                <i>
                                                                                                                                                    Ci pregiamo del fatto di voler comunque dare l’opportunità ai nostri Clienti di esprimere il
                                                                                                                                                    proprio personale desiderio di acquisto con l’obiettivo di cercare di soddisfare il più alto
                                                                                                                                                    numero di domande. Tuttavia non abbiamo la possibilità di dare garanzie in merito alla
                                                                                                                                                    futura disponibilità e ai tempi di consegna dell’orologio da Lei indicato.
                                                                                                                                                </i>
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        <p style="margin: 0;">
                                                                                                                                            <br />
                                                                                                                                            <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                                <i>Sarà nostra premura contattarLa ai recapiti che ci ha gentilemente lasciato</i>
                                                                                                                                            </span>
                                                                                                                                            <br />
                                                                                                                                            <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                                <i>non appena avremo aggiornamenti in merito alla Sua richiesta.</i>
                                                                                                                                            </span>
                                                                                                                                            <br />
                                                                                                                                            <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                                <i>Cordialmente,</i>
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        <p style="margin: 0;">
                                                                                                                                            <br />
                                                                                                                                            <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                                <strong><i>Capone Gioiellerie</i></strong>
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table role="presentation" class="r12-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                        <tbody>
                                            <tr>
                                                <td class="r1-i" style="background-color: #ffffff;" valign="top">
                                                    <table role="presentation" class="r3-o" style="table-layout: fixed; width: 600px;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
                                                        <tbody>
                                                            <tr>
                                                                <td class="r19-i" style="padding-bottom: 20px; padding-top: 20px;">
                                                                    <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th class="r14-c" style="font-weight: normal;" width="100%" valign="top">
                                                                                    <table role="presentation" class="r6-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="r7-i" style="padding-left: 15px; padding-right: 15px;" valign="top">
                                                                                                    <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td class="r20-c" style="padding-bottom: 30px; padding-top: 30px;" align="center">
                                                                                                                    <table
                                                                                                                        role="presentation"
                                                                                                                        style="
                                                                                                                            border-top-style: solid;
                                                                                                                            background-clip: border-box;
                                                                                                                            border-top-color: #4a4a4a;
                                                                                                                            border-top-width: 1px;
                                                                                                                            font-size: 1px;
                                                                                                                            line-height: 1px;
                                                                                                                        "
                                                                                                                        width="100%"
                                                                                                                        height="1"
                                                                                                                        cellspacing="0"
                                                                                                                        cellpadding="0"
                                                                                                                        border="0"
                                                                                                                    >
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td style="font-size: 0px; line-height: 0px;" height="0">­</td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table role="presentation" class="r21-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                        <tbody>
                                            <tr>
                                                <td class="r1-i" style="background-color: #ffffff;" valign="top">
                                                    <table role="presentation" class="r3-o" style="table-layout: fixed; width: 600px;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
                                                        <tbody>
                                                            <tr>
                                                                <td class="r19-i" style="padding-bottom: 20px; padding-top: 20px;">
                                                                    <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th class="r14-c" style="font-weight: normal;" width="100%" valign="top">
                                                                                    <table role="presentation" class="r6-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="r7-i" style="padding-left: 15px; padding-right: 15px;" valign="top">
                                                                                                    <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td class="r15-c" align="left">
                                                                                                                    <table
                                                                                                                        role="presentation"
                                                                                                                        class="r22-o"
                                                                                                                        style="table-layout: fixed; width: 100%;"
                                                                                                                        width="100%"
                                                                                                                        cellspacing="0"
                                                                                                                        cellpadding="0"
                                                                                                                        border="0"
                                                                                                                    >
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td
                                                                                                                                    class="r23-i nl2go-default-textstyle"
                                                                                                                                    style="
                                                                                                                                        font-family: arial, helvetica, sans-serif;
                                                                                                                                        word-break: break-word;
                                                                                                                                        color: #3b3f44;
                                                                                                                                        font-size: 18px;
                                                                                                                                        line-height: 1.5;
                                                                                                                                        text-align: center;
                                                                                                                                    "
                                                                                                                                    valign="top"
                                                                                                                                    align="center"
                                                                                                                                >
                                                                                                                                    <div>
                                                                                                                                        <p style="margin: 0; font-size: 14px;">
                                                                                                                                            PISA - VIAREGGIO - FORTE DEI MARMI - PONTEDERA - LIVORNO - VICOPISANO
                                                                                                                                        </p>
                                                                                                                                        <p style="margin: 0; font-size: 14px;">&nbsp;</p>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </body>
        </html>
    `;

  
  //   return {
  //     VariableMessageEmail,
  //   };
  };
  