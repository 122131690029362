import axios from 'axios';
import ConfigCapone from '../utils/ConfigCapone';

export interface IClient {
  idClient: string;
  idTitle: string;
  name: string;
  lastName: string;
  address: string;
  email: string;
  phone: string;
  dateInsert: string;
  idUser: string;
  dateModification: string;
  active: boolean;
  total: string; 
}
export interface ITitle {
  idTitle: string;
  desc_Title: string;
  active: boolean;
  total: string;
}
export interface IServiceClient {
  idService: string;
  idClient:string;
  clientName: string;
  idServiceType:string;
  desc_ServiceType: string;
  dateService:string;
  idBrand:string;
  desc_Brand: string;
  idProduct:string;
  desc_product:string;
  idBussines:string;
  desc_Bussines:string;
  description:string;
  reference: string;
  enrollment: string;
  dateInsert: string;
  idUser: string;
  dateModification: string;
  active: boolean;
  total:string;
  idStatus: string;
  desc_status:string;
  title:string;
  envelopeNumber:string;
}

export interface IClientTable {
  idClient: string
      idService: string
      name: string
      lastName: string
      idProduct: string,
      desc_Product: string
      dateService: string
      idBussines: string
      idStatus: string
      desc_Bussines: string
      desc_Status:string
      total: string
      autoSwitchNoAccettato:string;
}

export interface IUser {
  idUser: string;
  name: string;
  lastName: string;
  userName: string;
  pass: string;
  idBussines: string;
  idRole: string;
  active: boolean;

}
  
export const getClients = async () => {
  const baseUrl = `${ConfigCapone.urlapi}/api/Client?parameter=1`;
  const response = await axios.get(baseUrl);
  return response.data.response;
};

export const getServicesClient = async (idClient: string) => {
  const baseUrl = `${ConfigCapone.urlapi}/api/Service/GetServiciosCliente/${idClient}`;
  const response = await axios.get(baseUrl);
  return response.data.response;
};

export const getAllServices = async () => {
  const baseUrl = `${ConfigCapone.urlapi}/api/Client/GetClientTable?parameter=1`;
  const response = await axios.get(baseUrl);
  return response.data.response;
};

export const getBussiness = async () => {
  const baseUrl = `${ConfigCapone.urlapi}/api/Bussines?parameter=1`;
  const response = await axios.get(baseUrl);
  return response.data.response;
}
export const getTitlesFromId = async (idTitle: string) => {
  const baseUrl = `${ConfigCapone.urlapi}/api/Title/${idTitle}`;
  const response = await axios.get(baseUrl);
  return response.data.response;
};
export const getBussinessFromId = async (idBussiness: string) => {
  const baseUrl = `${ConfigCapone.urlapi}/api/Bussines/${idBussiness}`;
  const response = await axios.get(baseUrl);
  return response.data.response;
};
export const getProductFromId = async (idProduct: string) => {
  const baseUrl = `${ConfigCapone.urlapi}/api/Product/${idProduct}`;
  const response = await axios.get(baseUrl);
  return response.data.response;
};
export const getUserFromId = async (idUser: string) => {
  const baseUrl = `${ConfigCapone.urlapi}/api/User/${idUser}`;
  const response = await axios.get(baseUrl);
  return response.data.response;
};

export const getServiceTypeFromId = async (idServiceType: string) => {
  const baseUrl = `${ConfigCapone.urlapi}/api/ServiceType/${idServiceType}`;
  const response = await axios.get(baseUrl);
  return response.data.response;
};
export const getStatusFromId = async (idStatus: string) => {
  const baseUrl = `${ConfigCapone.urlapi}/api/Status/${idStatus}`;
  const response = await axios.get(baseUrl);
  return response.data.response;
};

export const getBrandFromId = async (idBrand: string) => {
  const baseUrl = `${ConfigCapone.urlapi}/api/Brand/${idBrand}`;
  const response = await axios.get(baseUrl);
  return response.data.response;
};

export interface IStatus {
  idStatus: string;
  desc_Status: string;
  active: boolean;
}
export interface IBrand {
  idBrand: string;
  desc_Brand: string;
  active: boolean;
}
export interface IBussiness {
  idBussines: string;
  desc_Bussines: string;
  active: boolean;
}
export interface IServiceType {
  idServiceType: string;
  desc_ServiceType: string;
  active: boolean;
}
export interface IProduct {
  idProduct: string;
  idBrand: string;
  desc_Product: string;
  active: boolean;
}