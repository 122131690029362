import axios from "axios";
import aplicationConnect from "../api/applicationConnect";
import { ResponseApi, ResponseApiPagination } from "../interfaces/response";
import ConfigCapone from "../utils/ConfigCapone";
import { ResponseGetBussinesCount } from "./interfaces/GetBussinesCount";
export const getRowSales = async () => {
  try {
    const resp = await axios.get<ResponseApi>(`${ConfigCapone.urlapi}/api/Sales/ListSale`);
    console.log("API SOLICITADA: " + `${ConfigCapone.urlapi}/api/Sales/ListSale`)
    console.log("Datos traidos por la API", resp)
    if (resp.data.status === "success") {
      return resp.data.response;
    }
  } catch (error) {
    throw new Error(error as any);
  }
};

export const getRowSalesFilter = async ({ bussinesFilter,
  dateStart,
  userAndBusinesFilter,
  dateEnd,
  userFilter }) => {
  try {
    const resp = await axios.get<ResponseApi>(`${ConfigCapone.urlapi}/api/Sales/ListSaleFilter`, {
      params: {
        bussinesFilter: bussinesFilter,
        dateStart: dateStart,
        dateEnd: dateEnd,
        userFilter: userFilter,
        userAndBusinesFilter: userAndBusinesFilter
      },
    });
    if (resp.data.status === "success") {
      return resp.data.response;
    }
  } catch (error) {
    throw new Error(error as any);
  }
};
export const getRowVenditeProdottiFilter = async ({ bussinesFilter,
  brandFilter,
  referenzaFilter,
  limit,
  page,
  dateStart,
  dateEnd
}) => {
  try {
    const resp = await axios.get<ResponseApiPagination>(`${ConfigCapone.urlapi}/api/Sales/VenditeProdottiFilter`, {
      params: {
        bussinesFilter: bussinesFilter,
        brandFilter: brandFilter,
        referenzaFilter: referenzaFilter,
        limit: limit,
        page: page,
        dateStart: dateStart,
        dateEnd: dateEnd,
      },
    });
    if (resp.data.status === "success") {
      return resp.data;
    }
  } catch (error) {
    throw new Error(error as any);
  }
};
//VenditePerCittaFilter
export const getRowVenditePerCittaFilter = async ({
  city,
  limit,
  page,
}) => {
  try {
    const resp = await axios.get<ResponseApiPagination>(`${ConfigCapone.urlapi}/api/Sales/VenditePerCittaFilter`, {
      params: {
        city,
        limit: limit,
        page: page
      },
    });
    if (resp.data.status === "success") {
      return resp.data;
    }
  } catch (error) {
    throw new Error(error as any);
  }
};
//RepairsStatistics
export const getRowVRepairsStatisticsFilter = async ({
  brandFilter,
  limit,
  page,
  dateStart,
  dateEnd,
}) => {
  try {
    const resp = await aplicationConnect.get<ResponseApiPagination>(`/Sales/RepairsStatisticsFilter`, {
      params: {
        brandFilter: brandFilter,
        limit: limit,
        page: page,
        dateStart: dateStart,
        dateEnd: dateEnd,
      },
    });
    if (resp.data.status === "success") {
      return resp.data;
    }
  } catch (error) {
    throw new Error(error as any);
  }
};
export const selectBussiness = async () => {
  try {
    const resp = await axios.get<ResponseApi>(
      `${ConfigCapone.urlapi}/api/Bussines?parameter=1`
    );
    if (resp.data.status === "success") {
      return resp.data.response;
    }
  } catch (error) {
    throw new Error(error as any);
  }
};
export const selectBrand = async () => {
  try {
    const resp = await axios.get<ResponseApi>(
      `${ConfigCapone.urlapi}/api/Brand?parameter=1`
    );
    if (resp.data.status === "success") {
      return resp.data.response;
    }
  } catch (error) {
    throw new Error(error as any);
  }
};

export const httpGetCountBussinnes = async (dateStart?:string,dateEnd?:string) => {
  try {
    const response = await aplicationConnect.get<ResponseGetBussinesCount>('Sales/GetCountBussines',{params:{
      dateStart,
      dateEnd,
    }});
    console.log(response)
    return response.data;
  } catch (error) {
    throw new Error(error as any);
  }
}