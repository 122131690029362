import {
    Modal,
    ScrollView,
    StyleSheet,
    TouchableOpacity,
    Text,
    View,
} from "react-native";
import { HeaderModal } from "../HeaderModal";
import { stylesModal } from "../../theme/ModalStyles";
import { CheckLabel } from "../CheckLabel";
import { useState } from "react";

interface IModalOptionMatricola {
    modalVisible: boolean;
    setModalVisible: any;
    activeButton: boolean;
    navigation?: any;
    goToMatricola: any;
    dataMatricola: any | undefined;
    dataMagazzino: any;
    goToCrm: any;
    dataCrm: any;
}

const ModalOptionMatricola = ({
    modalVisible = false,
    setModalVisible,
    activeButton = true,
    navigation,
    goToMatricola,
    dataMatricola,
    dataMagazzino,
    goToCrm,
    dataCrm,
}: IModalOptionMatricola) => {

    const [checked, setCheckBoxChecked] = useState("");

    const changeListMatricola = (option: number, value: any, id: any, matricola?: any) => {

        setModalVisible(false);
        let magazzino = { ...dataMagazzino, matricola: id + "-" + matricola }
        console.log("datos", option)
        setCheckBoxChecked(value);

        if (value) {
            if (checked == value) {
                setCheckBoxChecked(null)
            }
            if (option) {
                goToCrm(magazzino, option)
            }
        }

    }
    return (
        <View >
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <ScrollView style={{ width: "100%", height: "100%" }}>
                    <View
                        style={[
                            stylesModal.centeredView,
                            { paddingTop: 150, paddingBottom: 150 },
                        ]}
                    >
                        <View style={[stylesModal.modalView, { width: 480 }]}>
                            <HeaderModal
                                setModalVisible={setModalVisible}
                                modalVisible={modalVisible}
                                title={"Seleziona il Matricola"}
                                paddingRight={40}
                            />
                            <div className="row w-100">
                                <div className="col-12 d-flex justify-content-center mb-4 mt-3">
                                    <div className="row">
                                        <div className="col-12">
                                            {(dataMatricola)
                                                ?
                                                (dataMatricola.map((data, index) => {
                                                    return (
                                                        <div className="row" key={index}>

                                                            <div className="col-12">

                                                                <TouchableOpacity>
                                                                    <CheckLabel
                                                                        name="Spostamento"
                                                                        id={'Spostamento-' + data.idMatricola}
                                                                        type="text"
                                                                        checked={checked}
                                                                        onChange={(value) => changeListMatricola(dataCrm, value, data.idMatricola, data?.matricola)}
                                                                        text={data?.matricola ? data?.matricola : "Nessun dato"}
                                                                        marginleft={true}
                                                                    />
                                                                </TouchableOpacity>

                                                            </div>
                                                        </div>
                                                    )
                                                }))
                                                : ""
                                            }
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </View>
                    </View>
                </ScrollView>
            </Modal>
        </View>
    )

}

const styleSchemaProdottoCard = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        flex: 1,
    },
    inputNotEdit: {
        width: 390,
        height: 40,
        margin: 12,
        borderRadius: 50,
        marginVertical: 6,
        backgroundColor: "rgb(235, 235, 235)",
        fontFamily: "Arsapia Regular font",
        color: "#a2a2a2",
        paddingLeft: 20,
    },
    button: {
        width: 200,
        marginTop: 30,
        height: 40,
        alignSelf: "auto",
        backgroundColor: "transparent",
        borderRadius: 50,
        alignItems: "center",
        justifyContent: "center",
    },
    buttonOutline: {
        height: 45,
        width: "100%",
        borderWidth: 2,
        fontFamily: "Arsapia Regular font",
        color: "#ADADAC",
        fontWeight: "400",
        paddingLeft: 26,
        paddingRight: 26,
        paddingBottom: 8,
        paddingTop: 8,
        marginTop: '50%',
        textAlign: 'center',
        borderRadius: 50,
        marginRight: "1%",
    }
});


export default ModalOptionMatricola;
