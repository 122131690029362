import { RouteProp, useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { myNotify as myNotifyElements } from "../components/Calender/elements";
import { sortWhitCampoDesc } from "../helpers/sort";
import { Notify } from "../interfaces/Notify";
import { RootStackParams } from "../navigator/NavigatorConfig";
import { putRead } from "../services/Notify";

export const useNotifyMain = () => {
  const { params } = useRoute<RouteProp<RootStackParams, "NotifyScreen">>();
  const [isLoading, setIsLoading] = useState(false);
  const [myNotify, setMyNotify] = useState<Notify[]>(params.items);
  const [view, setView] = useState<number | undefined>(undefined);
  const handleView = async (value: number) => {
    try {
      const notify = myNotify.find((item) => item.id === value);
      if (!notify) {
        throw new Error();
      }
      if(notify.read === 0){
        await putRead(value,notify.coduser)
      }      
      setMyNotify([
        ...myNotify.filter((item) => item.id !== value),
        {
          ...notify,
          read: 1,
        },
      ]);
      setView(view === value ? undefined : value);
    } catch (error) {
      console.log(error)
    }

  };

  const filtered = () => {
    return {
      data: myNotify.sort((x, y) => sortWhitCampoDesc(x, y, "id")),
      total: myNotify.length,
    };
  };

  useEffect(() => {
    if(params && params.id !== undefined){
      handleView(Number(params.id))
    }
  }, []);

  return { handleView, view, myNotify: filtered().data, isLoading };
};
