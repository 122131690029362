import { StatusBar, StyleSheet } from "react-native";

export const stylesMagazzino = StyleSheet.create({
  row: {
    flex: 1,
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
  safeAreaView: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
    backgroundColor: "white",
  },
  iconFilterPer: {
    marginTop: 3,
    width: 42,
    height: 42,
    marginLeft: -7,
    tintColor: "black",
    alignSelf: "center",
  },
  labelFilterPer: {
    marginTop: 12,
    fontSize: 20,
    marginLeft: 15,
    fontFamily: "Arsapia Regular font",
    color: "black",
  },
  labelFilter: {
    alignSelf: "flex-start",
    marginTop: 25,
    marginBottom: 10,
    fontSize: 18,
    fontWeight: "500",
    fontFamily: "Arsapia Regular font",
  },
  arrowDown: {
    width: 20,
    height: 20,
    marginTop: 18,
    tintColor: "#808080",
    alignSelf: "center",
  },
  subSearch: {
    alignSelf: "flex-end",
    borderColor: "#A4A4A4",
    paddingRight: 2,
    paddingLeft: 15,
    fontSize: 20,
  },
  input: {
    height: 45,
    width: "100%",
    borderWidth: 2,
    fontFamily: "Arsapia Regular font",
    padding: 6,
    borderRadius: 50,
    marginRight: "1%",
  },
  viewDisable: {
    backgroundColor: "black",
    borderRadius: 50,
    justifyContent: "center",
    width: 40,
    height: 40,
    marginTop: "2%",
  },
  search: {
    marginLeft: "-100%",
  },  subIconSearch: {
    width: 20,
    height: 20,
    tintColor: "white",
    alignSelf: "center",
  },
  noteIcon: {
    flex: 1,
    paddingHorizontal: 5,
    alignItems: "center",
    justifyContent: "center",
  },
});
