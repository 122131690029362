import React, { useEffect, useRef, useState } from 'react';
import InputComponent from '../../../../components/ComponentsAtelier/inputs/inputComponent';
import './css/magazzinoAtelierForm.css';
import { useForm, useFieldArray, Control } from 'react-hook-form';
import ButtonComponent from '../../../../components/ComponentsAtelier/buttoms/buttonComponent';
import { FormDataMagazzinoAtelier } from './interfaces/magazzinoAtellierForm';
import NavProfile from '../../../../components/NavProfile/NavProfile';
import { Navbar } from '../../../../components/Navbar';
// @ts-ignore
import iconDelete from '../../../../assets/icons/icon-delete.png';
import { IoIosAddCircle } from 'react-icons/io';
import { useMagazzinoAtelierForm } from './hooks/useMagazzinoAtelierForm';
import { gu } from 'date-fns/locale';
import { useIsFocused } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import PopUpMessageWithRoute from '../../../../components/PopUpMessageWithRoute';
import ConfigCapone from '../../../../utils/ConfigCapone';
import axios from 'axios';
import { useProfile } from '../../../../components/NavProfile/hook/useProfile';
import ShowContentSidebar from '../../../../components/NavProfile/ShowContentSidebar';
interface MagazzinoAtelierFormProps {
    // Propiedades del componente
}
interface Props extends NativeStackScreenProps<any, any> { }
const MagazzinoAtelierForm = ({ navigation, route }: Props) => {
    const { brandGet,
        user,
        brandList,
        brandOptions,
        magazzinoAtelierPost,
        famigliaGet, handlefamigliaOptions,
        famigliaOptions,
        searchReference,
        searchReferenceOptions,
        searchReferenceList,
        resetReferenceOptions,
        magazzinoAtelierPut,
        MagazzinoPost,
        showSuccessPopup,
        setmodaleMessaggeWithUrl,
        modaleMessaggeWithUrl,
        closePopup,
        imageArrayList,
        removeFiles,
        loadFiles,
        uploadFiles,
        saveFiles
    } = useMagazzinoAtelierForm();
    const { register, handleSubmit, watch, setValue, formState: { errors }, control } = useForm<FormDataMagazzinoAtelier>({
        defaultValues: {
            matricola: [{ name: '', online: false }],
            idBussines: 9,
            idReference: null,
            idFamiglia: null,
            box: 0,
            guarantees: 0,
            year: new Date().getFullYear()
        }
    });
    const { fields, append, remove } = useFieldArray({
        name: "matricola",
        control
    });
    const brandValue = watch('idBrand');
    const referenciaValue = watch('referencia');

    const dateTimeNow = (fecha: string = '') => {
        let date
        if (fecha !== '') {
            date = new Date(fecha)
        } else {
            date = new Date()
        }
        const year = date.getFullYear()
        const month =
            date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1
        const day = date.getDate().toString().padStart(2, "0")
        const hour = date.getHours().toString().padStart(2, "0")
        const minute = date.getMinutes().toString().padStart(2, "0")
        const seconds = date.getSeconds().toString().padStart(2, "0")
        const dateNow =
            day +
            '/' +
            month +
            '/' +
            year +
            ', ore ' +
            hour +
            ':' +
            minute +
            ':' +
            seconds

        return dateNow
    }
    const sendEmailOnlineMatricola = async (data: FormDataMagazzinoAtelier) => {
        if (data.matricola.length === 0) return;

        let date = dateTimeNow();
        let mat = []
        for (let index = 0; index < data.matricola.length; index++) {
            if (data.matricola[index].online === true) {
                mat.push(`<li>${data.matricola[index].name}</li>`);
            }

        }
        if (mat.length === 0) return;
        let emailData = {
            type: "notice",
            email: `${ConfigCapone.emailMarketing}`,
            subject: "Nuovo acquisto",
            message: `<p>Operazione: Nuovo acquisto:</p>
                   <p>Operatore: ${user.userName}</p>
                   <p> Referenza: ${data.referencia} </p>
                   <p> Negozio: Atelier </p>
                   Matricola:<ul> ${mat.join(" ")}</ul> 
                   <p>  Prezzo: ${data.prezzo}</p>
                   <p>  Data: ${date}</p>`,
        };

        await axios
            .post(`${ConfigCapone.apicapone}/api/forma`, emailData)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => console.log("there was an error: " + err));
    }


    const onSubmit = async (data) => {
        const searchReferenceExist = searchReferenceList.find((element) => element.referencia === data.referencia);
        if (searchReferenceExist) {
            if (data.idReference === null) {
                showSuccessPopup("Errore.", "La referenza esiste, deve essere selezionato.")
                return;
            }
        }
        let json = await saveFiles()
        if (data.idReference === null) {
            const { idReference, ...valueSend } = data;
            if (idReference !== undefined) {
                valueSend.prezzo = parseFloat(data.prezzo);
                valueSend.box = parseInt(data.box);
                valueSend.guarantees = parseInt(data.guarantees);
                valueSend.fileName = json;
                valueSend.idFamiglia = data.idFamiglia !== '' ? data.idFamiglia : null;
                await magazzinoAtelierPost(valueSend);
            }
        } else {
            const valueSend = {
                ...data,
                prezzo: parseFloat(data.prezzo),
                box: parseInt(data.box),
                guarantees: parseInt(data.guarantees),
                fileName: json,
            }
            await magazzinoAtelierPut(valueSend)
        }
        sendEmailOnlineMatricola(data)
        showSuccessPopup("Informazione", "Salvata con successo.")
        setTimeout(() => {
            navigation.navigate("MagazzinoAtelier")
        }, 2000);
    };
    const peticionInitial = async () => {
        await brandGet()
        await famigliaGet()
    }
    const fileInputRef = useRef(null);

    const handleFileSelect = () => {
        fileInputRef.current.click();
    };

    const selectSearchReference = (id: string | number) => {
        const search = searchReferenceList.find((element) => element.idReference === id);

        if (search) {
            setValue("idFamiglia", search.idFamiglia);
            setValue("prezzo", search.prezzo);
            setValue("idReference", search.idReference);
            setValue("idBrand", search.idBrand);
            setValue("referencia", search.referencia);
            setValue("year", search.year);
            setValue("informationRequest", search.informationRequest);
            setValue("diameter", search.diameter);
            setValue("collection", search.collection);
            setValue("house", search.house);
            setValue("glass", search.glass);
            setValue("estate", search.estate);
            setValue("movement", search.movement);
            setValue("caliber", search.caliber);
            setValue("powerReserve", search.powerReserve);
            setValue("bracelet", search.bracelet);
            setValue("coloreQuadrante", search.coloreQuadrante);
            setValue("misuraQuadrante", search.misuraQuadrante);
            setValue("box", search.box);
            setValue("guarantees", search.guarantees);
            loadFiles(search.fileName)
            //setSelectedImageName(search.fileName);
            resetReferenceOptions()
        }
    }
    useEffect(() => {
        if (brandValue) {
            handlefamigliaOptions(brandValue)
        }
    }, [brandValue]);
    useEffect(() => {
        if (referenciaValue) {
            searchReference(referenciaValue)
        }
    }, [referenciaValue]);
    useEffect(() => {
        peticionInitial()
    }, [])
    const isFocused = useIsFocused();
    const { sidebarVisible, toggleSidebar } = useProfile()
    return (
        <>
            <ShowContentSidebar
                sidebarVisible={sidebarVisible}
                toggleSidebar={toggleSidebar}
            />
            <div className=" container-layout" >
                <div className="row row-layout" >
                    <div className={` ${sidebarVisible ? 'col-12 d-block' : 'col-lg-3 col-md-none d-none d-md-non d-lg-block'} sidebar-container-layout sidebar-transition`} >
                        <Navbar activeNav={isFocused} componentName={route.name} componentDad="MagazzinoAtelier" />
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12 content-container-layout" style={{ paddingLeft: '1.5rem' }}>
                        {modaleMessaggeWithUrl.active && (
                            <PopUpMessageWithRoute
                                routeNav={modaleMessaggeWithUrl.route}
                                dataNav={[]}
                                title={modaleMessaggeWithUrl.title}
                                message={modaleMessaggeWithUrl.message}
                                stateModal={modaleMessaggeWithUrl.active}
                                setStateModal={setmodaleMessaggeWithUrl}
                                confirmAction={() => closePopup()}
                            />
                        )}
                        <NavProfile
                            showMenuBack={route.name !== 'MagazzinoAtelier'}
                            sidebarVisible={sidebarVisible}
                            toggleSidebar={toggleSidebar}
                            goBack="MagazzinoAtelier"
                            goBackName="Magazzino Atelier"
                        />
                        <div className='magazzino-atelier-form-container'>
                            <div className='form-magazzino-atelier'>
                                <h5>Carica prodotto</h5>
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <InputComponent
                                        name="idBrand"
                                        label="Brand *"
                                        type="select"
                                        register={register('idBrand', { required: 'Il campo è obbligatorio' })}
                                        errors={errors}
                                        options={brandOptions}
                                    />
                                    <InputComponent
                                        name="prezzo"
                                        type="number"
                                        label="Prezzo *"
                                        width='30%'
                                        errors={errors}
                                        register={register('prezzo', { required: 'Il campo è obbligatorio' })}
                                    />
                                    <InputComponent
                                        name="referencia"
                                        type="text"
                                        label="Referenza *"
                                        errors={errors}
                                        register={register('referencia', { required: 'Il campo è obbligatorio' })}
                                        optionsSelect={searchReferenceOptions}
                                        onOptionSelect={(id) => selectSearchReference(id)}

                                    />

                                    <InputComponent
                                        name="descrizione"
                                        type="select"
                                        label="Descrizione"
                                        register={register('idFamiglia')}
                                        options={famigliaOptions}
                                    />
                                    <InputComponent
                                        name="Anno"
                                        type="number"
                                        label="Anno"
                                        width='30%'
                                        register={register('year')}
                                    />
                                    {fields.map((field, index) => (
                                        <div key={`${field.id}-${index}`} className='group-matricola-atelier'>
                                            <InputComponent
                                                name={`matricola.${index}.name`}
                                                type="text"
                                                label="Matricola"
                                                width='30%'
                                                register={register(`matricola.${index}.name`, {
                                                    onChange: (e) => {
                                                        const isNameFilled = e.target.value !== '';
                                                        if (isNameFilled) {
                                                            setValue(`matricola.${index}.online`, true);
                                                        } else {
                                                            setValue(`matricola.${index}.online`, false);
                                                        }
                                                    }
                                                })}
                                            />
                                            <div className='checbox-magazzino-atelier' >
                                                <div className='checbox-magazzino-atelier'>
                                                    <input type="checkbox" id={`matricola.${index}.online`} {...register(`matricola.${index}.online`)} />
                                                    <label htmlFor={`matricola.${index}.online`}>Online</label>
                                                </div>
                                                {fields.length > 1 && <img className='img-magazzino-form' src={iconDelete} alt='icono' onClick={() => remove(index)} />}
                                            </div>
                                        </div>
                                    ))}
                                    <div className='button-add-matricola'>
                                        <label style={{ cursor: 'pointer' }} onClick={() =>
                                            append({
                                                name: "",
                                                online: false
                                            })}> <IoIosAddCircle
                                                color="#359ffc"
                                                size={30}
                                                className="ms-4"
                                                style={{ cursor: "pointer" }}
                                            /> Aggiungi matricola</label>
                                    </div>


                                    <InputComponent
                                        name="InfRichiesta"
                                        type="text"
                                        label="Informazione su richiesta"
                                        width='30%'
                                        register={register('informationRequest')}
                                    />
                                    <InputComponent
                                        name="diametro"
                                        type="text"
                                        label="Diametro"
                                        width='30%'
                                        register={register('diameter')}
                                    />
                                    <InputComponent
                                        name="collezione"
                                        type="text"
                                        label="Collezione"
                                        register={register('collection')}
                                    />
                                    <InputComponent
                                        name="cassa"
                                        type="text"
                                        label="Cassa"
                                        register={register('house')}
                                    />
                                    <InputComponent
                                        name="vetro"
                                        type="text"
                                        label="Vetro"
                                        register={register('glass')}
                                    />
                                    <InputComponent
                                        name="tenuta"
                                        type="text"
                                        label="Tenuta"
                                        register={register('estate')}
                                    />
                                    <InputComponent
                                        name="movimento"
                                        type="text"
                                        label="Movimento"
                                        register={register('movement')}
                                    />
                                    <InputComponent
                                        name="calibro"
                                        type="text"
                                        label="Calibro"
                                        register={register('caliber')}
                                    />
                                    <InputComponent
                                        name="riservaDiCarica"
                                        type="text"
                                        label="Riserva di carica"
                                        register={register('powerReserve')}
                                    />
                                    <InputComponent
                                        name="bracciale"
                                        type="text"
                                        label="Bracciale"
                                        register={register('bracelet')}
                                    />
                                    <InputComponent
                                        name="coloreQuadrante"
                                        type="text"
                                        label="Colore quadrante"
                                        register={register('coloreQuadrante')}
                                    />
                                    
                                    <div className="container-custom-magazzino">
                                        <div className="div1">
                                            <div className='allegate-button'>
                                                <label >Foto allegate</label>
                                                <div style={{ marginTop: '20px' }}>
                                                    <input
                                                        type="file"
                                                        name="image-files"
                                                        id="image-files"
                                                        ref={fileInputRef}
                                                        accept="image/png, image/gif, image/jpeg"
                                                        multiple
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => uploadFiles(e.target.files)}
                                                    />
                                                    <div className='button-import-img' >
                                                        <ButtonComponent onClick={handleFileSelect} text='Scegli il file' type="button" />
                                                        {imageArrayList.length < 1 && <label>Nessun file selezionato</label>}
                                                    </div>

                                                    <label style={{ fontSize: '10px' }}>Importa file (max 1MB)</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row' style={{ margin: 0 }}>
                                        {imageArrayList.map((image, index) => (
                                            <div key={index} className={`col-8 col-sm-6 col-md-4 col-lg-4 m-auto text-center`}>
                                                {image?.id
                                                    ? <img src={`${ConfigCapone.apicapone}/image/${image.name}`} className="img-fluid" alt="Image" style={{ maxWidth: '60%', maxHeight: '60%', border: '1px solid black' }} />
                                                    : <img src={URL.createObjectURL(image)} className="img-fluid" alt="Image" style={{ maxWidth: '60%', maxHeight: '60%', border: '1px solid black' }} />
                                                }
                                                <br />
                                                <a onClick={() => removeFiles(index)} style={{ cursor: 'pointer' }} className="text-danger">
                                                    Rimuovere {image.name}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                    <InputComponent
                                        name="scatola"
                                        label="Scatola"
                                        type="select"
                                        register={register('box')}
                                        errors={errors}
                                        options={[
                                            { value: 1, label: "Si" },
                                            { value: 0, label: "No" },
                                        ]}
                                    />
                                    <InputComponent
                                        name="garanzia"
                                        label="Garanzia"
                                        type="select"
                                        register={register('guarantees')}
                                        errors={errors}
                                        options={[
                                            { value: 1, label: "Si" },
                                            { value: 0, label: "No" },
                                        ]}
                                    />
                                    <div className='footer-form-magazzino'>
                                        <ButtonComponent text='Salva' type="submit" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default MagazzinoAtelierForm;