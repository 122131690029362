import { useIsFocused } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react'
import { useProfile } from '../../../../components/NavProfile/hook/useProfile';
import ShowContentSidebar from '../../../../components/NavProfile/ShowContentSidebar';
import { Navbar } from '../../../../components/Navbar';
import NavProfile from '../../../../components/NavProfile/NavProfile';
import { ContactScreen } from '../../../ContactScreen';
import { RootStackParams } from '../../../../navigator/NavigatorConfig';
import BudgetScreen from '../../../BudgetScreen';
interface Props extends NativeStackScreenProps<RootStackParams, "ContactScreen"> {

}
const PreventivoRiparazioni = ({navigation,route}:Props) => {
    const isFocused = useIsFocused();
    const { sidebarVisible, toggleSidebar } = useProfile()
    const back = () => {
        console.log('back');
        navigation.navigate("HomeSCDueScreen", { refresh: true })
    }

    return (
        <>
            <ShowContentSidebar
                sidebarVisible={sidebarVisible}
                toggleSidebar={toggleSidebar}
            />
            <div className=" container-layout" >
                <div className="row row-layout" >
                    <div className={` ${sidebarVisible ? 'col-12 d-block' : 'col-lg-3 col-md-none d-none d-md-non d-lg-block'} sidebar-container-layout sidebar-transition`}  >
                        <Navbar activeNav={isFocused} componentName={'ControlPanelRiparazione'}  componentDad="ControlPanelRiparazione"/>
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12 content-container-layout" style={{ paddingLeft: '1.5rem' }}>
                        <NavProfile
                            showMenuBack={true}
                            sidebarVisible={sidebarVisible}
                            toggleSidebar={toggleSidebar}
                            goBack={() => back()}
                            goBackName="Riparazione Atelier"
                        />
                         <div className='magazzino-atelier-form-container'>
                            <div className='form-magazzino-atelier'>
                                <BudgetScreen navigation={navigation} route={route} isAtelier/>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreventivoRiparazioni