import React from "react";
import { Collapse } from "react-collapse";
import { useShow } from "../../../../../hooks/useShow";
import { CollapseTitleAtelier } from "../../../../Magazzino/Atelier/views/CollapseTitleAtelier";
import { BrandResponse } from "../../../../../interfaces/magazzino";
import { CheckAtelier } from "./CheckAtelier";

interface Props {
  list: any[];
  handleFilter: (value: string) => void;
}
export const FilterCrmCheckboxOthers = ({
  list,
  handleFilter,
}: Props) => {
  return (
    <>
        <div className="col-12 mt-3">
          {list.map(({ id, text }) => (
            <div key={id}>
              <CheckAtelier
                name={text}
                id={id}
                text={text}
                marginleft
                handlechange={handleFilter}
              />
              <br />
            </div>
          ))}
        </div>
    </>
  );
};
