import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { AlertCustomProps } from "../components/AlertCustom";
import { AddEvent } from "../interfaces/calender";
import { normalizedDateTime } from "../helpers/date";
import { deleteEvent, updateEvent } from "../services/calender";
import { useAuth } from "./useAuth";
import moment from "moment";
import axios from "axios";
import ConfigCapone from "../utils/ConfigCapone";

interface Props {
  handleEditEvent: (event: AddEvent) => void;
  handleDeleteEvent: (codigo: string) => void;
  item: AddEvent;
  closeModal: () => void;
}
export type ResponseMsg = Omit<AlertCustomProps, "handleClear">;

export const useEditEvent = ({handleEditEvent,item,handleDeleteEvent,closeModal}: Props) => {
  
  const { id, permitedFilter } = useAuth();
  const [response, setResponse] = useState<ResponseMsg | undefined>(undefined);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);
  const editEventSchema = Yup.object().shape({
    date: Yup.string().required("Campo obbligatorio"),
    start: Yup.string().required("Campo obbligatorio"),
    end: Yup.string().required("Campo obbligatorio"),
    title: Yup.string().required("Campo obbligatorio"),
    bussines: Yup.string().required("Campo obbligatorio"),
  });

  const [nameImgSelected, setNameImgSelected] = useState<string>();
  const [nameImgUpdate, setNameImgUpdate] = useState<string>()
  const [nameFileUpdate, setNameFileUpdate] = useState<string>()

  const [nameFileSelected, setNameFileSelected] = useState<string>();

  const [defultfFileSelected, setDefultfFileSelected] = useState<string>()
  const [defultfImageSelected, setDefultfImageSelected] = useState<string>()
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [typeFile, setTypeFile] = useState<any>();
  const [typeImage, setTypeImage] = useState<any>()

  const uploadFiles = (e: any, type: string) => {
    
    setNameFileSelected(e ? e[0].name : null);

    const formatDate = "YYYY_MM_DD_HH_mm_ss";
    let fileDate: any = new Date();
    const currentDate = moment(fileDate).format(formatDate);

    const extension: string = e[0].name?.substr(
      e[0].name?.lastIndexOf(".") + 1
    );

    const name: string = e[0].name?.split(".")[0]

    
    if (type == "image") {
      let valueImageCalendar = e ? name + '_' + currentDate + '.' + extension : null;
      formik.setFieldValue("selectedImage", valueImageCalendar);
      setSelectedImage(e ? name + '_' + currentDate + '.' + extension : null);
      setTypeImage(type)
      
    }

    if (type == "document") {
      let valueFileCalendar = e ? name + '_' + currentDate + '.' + extension : null;
      formik.setFieldValue("selectedFile", valueFileCalendar);
      setSelectedFile(e ? name + '_' + currentDate + '.' + extension : null);
      setTypeFile(type);
      
    }
    
      
  };

  const saveFile = (nameFile, nameImage) => {
    

    var formData = new FormData();

    var documentFile: any = document.querySelector("#document-files");
    var imageFile: any = document.querySelector("#image-files");


    if (nameFile) {
    
      var originalFileName: string = nameFile;

      const extension: string = originalFileName?.substr(
        originalFileName?.lastIndexOf(".") + 1
      );

      const name: string = originalFileName?.split(".")[0]
      const fileName: string = name + "." + extension;

      let myNewFile: any = null;

      if (extension.length > 0) {
        if (typeFile == "document") {
          
          myNewFile = new File([documentFile.files[0]], fileName, {
            type: documentFile.files[0].type,
          });
  
          formData.append("files", myNewFile);
          formData.append("type", typeFile);
          formData.append("name", fileName);
          formData.append("extension", extension);
  
          axios
            .post(`${ConfigCapone.apicapone}/upload/calendar`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
  
        }

      }

      
    }

    if (nameImage) {
      
      var originalImageName: string = nameImage;

      const extension: string = originalImageName?.substr(
        originalImageName?.lastIndexOf(".") + 1
      );

      const name: string = originalImageName?.split(".")[0]
      const imageName: string = name + "." + extension;
      
      let myNewImage: any = null;

      if (extension.length > 0) {
        
        
        if (typeImage == "image") {
          
          myNewImage = new File([imageFile.files[0]], imageName, {
            type: imageFile.files[0].type,
          });
  
          formData.append("files", myNewImage);
          formData.append("type", typeImage);
          formData.append("name", imageName);
          formData.append("extension", extension);
  
          axios
            .post(`${ConfigCapone.apicapone}/upload/calendar`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            
        }
      }
    }

  };

  const removeFile = () => {
    var documentFile: any = document.getElementById("document-files");
    documentFile.value = null;
    setSelectedFile(null)
    setSelectedImage(null)
    setDefultfFileSelected(null)
    setDefultfImageSelected(null)
    setNameImgSelected("");
  }


  const initialState: any = {
    ...item,
    start: normalizedDateTime(item.start),
    end: normalizedDateTime(item.end),
    selectedFile: null,
    selectedImage: null

  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: editEventSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      enableLoading();
      setTimeout(async () => {
        try {
          if (selectedFile || selectedImage) {

            await saveFile(selectedFile, selectedImage);
            values.fileName = selectedFile;
            values.imageName = selectedImage;
            

          } else {
            values.fileName = undefined;
            values.imageName = undefined;
          }
          await updateEvent({
            ...values,
            idCalendar: values.codigo,
            idUser: id,
            idBussines: values.bussines,
            bussines: undefined,
            codigo: undefined,
            start: `${values.date} ${values.start}`,
            end: `${values.date} ${values.end}`,
            fileName: selectedFile ? selectedFile : defultfFileSelected,
            imageName: selectedImage ? selectedImage : defultfImageSelected 
          });
          handleEditEvent({
            ...values,
            start: `${values.date}T${values.start}`,
            end: `${values.date}T${values.end}`,
            fileName: selectedFile ? selectedFile : defultfFileSelected,
            imageName: selectedImage ? selectedImage : defultfImageSelected 
          });
          setResponse({
            title: "Aggiornato",
            detail: "Evento aggiornato correttamente",
            status: "success",
          });
          closeModal();
        } catch (error) {
          console.log(error);
          setResponse({
            title: "Errore",
            detail: "si è verificato un errore durante la registrazione",
            status: "danger",
          });
         
        } finally {
          disableLoading();
          setSubmitting(false);
        }
      }, 500);
    },
  });

  useEffect(() => {
    console.log('item.fileName',item.fileName)
    setNameImgSelected(item.fileName)
    setNameImgUpdate(item.imageName)
    setNameFileUpdate(item.fileName)
    setDefultfFileSelected(item.fileName)
    setDefultfImageSelected(item.imageName)
  }, [item])
  

  const handleClear = () => {
    setResponse(undefined);
  };
  const handleDelete = () => {
    enableLoading();
    setTimeout(async () => {
      try {
        await deleteEvent(item.codigo);
        handleDeleteEvent(item.codigo);
        setResponse({
          detail: "Rimozione riuscita",
          title: "Evento rimosso con successo",
          status: "sucess",
        });
      } catch (error) {
        setResponse({
          detail: "Errore",
          title: "Si è verificato un errore durante l'eliminazione dell'evento",
          status: "danger",
        });
      } finally {
        disableLoading();
      }
    }, 500);
  };
  const handleConfirmDelete = () => {
    handleClear();
    setConfirmDelete(true);
  };
  const handleCancelDelete = () => {
    handleClear();
    setConfirmDelete(false);
  };

  useEffect(() => {
    if (formik.values.start && formik.values.start !== "") {
      let endEvent = new Date(`${formik.values.date} ${formik.values.start}`);
      const sumarsesion = 30;
      const minutes = endEvent.getMinutes();
      endEvent.setMinutes(minutes + sumarsesion);
      formik.setFieldValue(
        "end",
        endEvent.getHours().toString().padStart(2, "0") +
          ":" +
          endEvent.getMinutes().toString().padStart(2, "0")
      );
    }
  }, [formik.values.start]);

  return {
    formik,
    loading,
    response,
    confirmDelete,
    isAdmin: permitedFilter,
    handleConfirmDelete,
    handleCancelDelete,
    handleClear,
    handleDelete,
    selectedFile,
    defultfFileSelected,
    permitedActions: permitedFilter || Number(id) === item.idUser,
    nameImgSelected,
    selectedImage,
    nameImgUpdate,
    uploadFiles,
    nameFileSelected,
    removeFile,
    nameFileUpdate
  };
};