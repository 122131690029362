import React from "react";
import { View, Image, Text, StatusBar, TouchableOpacity, StyleSheet } from "react-native";
import { styles } from "../../theme/GlobalStyle";
import { SafeAreaView } from "react-native-safe-area-context";
import { RoundedButton } from "../../components/RoundedButton";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import "../../theme/styleBackground.css";
import { stylesCRM } from "../../GeneralStyles/SCDue/CrmHome";

interface Props extends NativeStackScreenProps<any, any> {}
export const CRMHomeScreen = ({ navigation }: Props) => {
  return (
    <div className="square">
      <SafeAreaView
        style={stylesCRM.safeArea}
      >
        <div className="container pt-5 roitateDiv">
          <View style={[styles.containerHome]}>
            <div
              className="row text-center"
              style={{ paddingTop: 10, paddingLeft: 45 }}
            >
              <div className="col-12 d-flex justify-content-center">
                <View style={stylesCRM.backButtonContainer}>
                  <TouchableOpacity onPress={() => navigation.navigate("HomeSCDueScreen", {  refresh  : true })}>
                    <View>
                      <Image
                        style={stylesCRM.backButton}
                        source={require("../../assets/iconoskubitsoft/Atras-01.png")}
                      />
                    </View>
                  </TouchableOpacity>
                </View>

                <Image
                  style={stylesCRM.logoCapone}
                  source={require("../../assets/images/SergioCaponeLogo.jpg")}
                />
              </div>
            </div>
            <View style={{ justifyContent: "center", flex: 1, marginTop: 50 }}>
              <View style={{ justifyContent: "center", flex: 1, padding: 25 }}>
                <div className="row">
                  <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-12 m-auto mt-3">
                    <TouchableOpacity
                      onPress={() => navigation.navigate("ControlPanelScreen")}
                    >
                      <RoundedButton
                        icon={require("../../assets/icons-step-due/Icona-account2.png")}
                        size={48}
                        position={{ top: -10, left: -24 }}
                        styleIcon={78}
                      />
                      <View style={styles.largeButton}>
                        <Text
                          style={stylesCRM.textButton}
                        >
                          Aggiungi cliente
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-12 m-auto mt-3">
                    <TouchableOpacity
                      onPress={() => navigation.navigate("ClientListScreen")}
                    >
                      <RoundedButton
                        icon={require("../../assets/iconoskubitsoft/Prospetto-02-01.png")}
                        size={48}
                        position={{ top: -10, left: -75 }}
                      />
                      <View style={styles.largeButton}>
                        <Text
                          style={stylesCRM.textButton}
                        >
                          Cerca cliente
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </div>
                </div>
              </View>
            </View>
          </View>
        </div>
      </SafeAreaView>
    </div>
  );
};