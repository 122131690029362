import React, { useContext, useEffect, useState } from "react";
import { View, Image, Text, StatusBar, TouchableOpacity } from "react-native";
import { styles } from "../theme/GlobalStyle";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { SafeAreaView } from "react-native-safe-area-context";
import { RoundedButton } from "../components/RoundedButton";
import { RepairData } from "../api/RepairData";
import "../theme/styleBackground.css";
import { redirectHome } from "../components/redirectHome";
import { UserContext } from "../context/UserContext";
import { RoundedButtonStyle } from "../theme/RoundedButtonStyle";
import { stylesCRM } from "../GeneralStyles/SCDue/CrmHome";
interface Props extends NativeStackScreenProps<any, any> {
  isAtelierr?: boolean;
}
export const HomeScreen = ({ route, navigation, isAtelierr = false }: Props) => {
  const { userInLogin } = useContext(UserContext)
  const [section, setSection] = useState('');
  const [isAtelier, setIsAtelier] = useState(false);

  redirectHome(navigation);

  let dataReturn: any = route.params || []
  let inserts: any = dataReturn.idRole || userInLogin?.idRole

  let arrayData: any = route.params;

  const handleNewRepair = () => {
    navigation.navigate(!isAtelierr ? "FormRepairScreen" : "NuovaRiparazione")
  }

  useEffect(() => {
    setSection(arrayData?.section ? arrayData.section : 'store')
  }, [arrayData])

  useEffect(() => {
    setIsAtelier(section === 'atelier' ? true : false);
  }, [section])

  return (
    <div className="square">
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: StatusBar.currentHeight,
          backgroundColor: "white",
        }}
      >
        <div className={`container ${!isAtelierr && "pt-5"} roitateDiv`}>
          <View style={[styles.containerHome]}>
            {!isAtelierr &&
              <div
                className="row text-center"
                style={{ paddingTop: 10, paddingLeft: 45 }}
              >

                <div className="col-12 d-flex justify-content-center">
                  <View style={stylesCRM.backButtonContainer}>
                    <TouchableOpacity onPress={() => navigation.navigate("HomeSCDueScreen", { refresh: true })}>
                      <View>
                        <Image
                          style={stylesCRM.backButton}
                          source={require("../assets/iconoskubitsoft/Atras-01.png")}
                        />
                      </View>
                    </TouchableOpacity>
                  </View>

                  <Image
                    style={stylesCRM.logoCapone}
                    source={require("../assets/images/SergioCaponeLogo.jpg")}
                  />
                </div>
              </div>
            }
            <View style={{ justifyContent: "center", flex: 1, marginTop: 50 }}>
              <View style={{ justifyContent: "center", flex: 1, padding: 25 }}>
                <div className="row">
                  <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-12 m-auto mt-3">

                    {(inserts != 3 && inserts != 7 && inserts != 8) ?

                      <TouchableOpacity
                        onPress={handleNewRepair}

                      >
                        <RoundedButton
                          icon={require("../assets/iconoskubitsoft/Herramientas-02-01.png")}
                          size={48}
                          position={{ top: -10, left: -75 }}
                        />
                        <View style={styles.largeButton}>
                          <Text style={{ fontSize: 20, fontWeight: "500", textAlign: "center" }}>
                            Nuova riparazione 
                          </Text>
                        </View>
                      </TouchableOpacity> :

                      <TouchableOpacity
                        onPress={() => navigation.navigate(!isAtelierr ? "FormRepairScreen" : "NuovaRiparazione")}
                        disabled={true}
                      >
                        <View
                          style={[RoundedButtonStyle.button, {
                            width: 50, height: 50, zIndex: 1, top: -10, left: -111
                          }]}>
                          <Image
                            style={[{
                              width: 290,
                              height: 290,
                            }]}
                            source={require("../assets/iconoskubitsoft/HerramientasGris-01-01.png")}
                          />
                        </View>
                        <View style={[styles.largeButton, { backgroundColor: "#F6F6F6" }]}>
                          <Text style={{ fontSize: 20, fontWeight: "500", textAlign: "center" }}>
                            Nuova riparazione 
                          </Text>
                        </View>
                      </TouchableOpacity>
                    }


                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-12 m-auto mt-3">
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate(!isAtelierr ? "StateRepairScreen" : "StatoRiparazioni", RepairData)
                      }
                    >
                      <RoundedButton
                        icon={require("../assets/iconoskubitsoft/Estado-02-01.png")}
                        size={48}
                        position={{ top: -10, left: -75 }}
                      />
                      <View style={styles.largeButton}>
                        <Text style={{ fontSize: 20, fontWeight: "500", textAlign: "center" }}>
                          Stato riparazioni
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-12 m-auto mt-3"

                  >
                    <p hidden={true} >{inserts}</p>




                    {(inserts != 3 && inserts != 7 && inserts != 8) ?

                      <TouchableOpacity
                        onPress={() =>
                          navigation.navigate(!isAtelierr ? "ProspectusRepairScreen" : "Prospectusreparacione")
                        }

                      >
                        <RoundedButton
                          icon={require("../assets/iconoskubitsoft/Prospetto-02-01.png")}
                          size={48}
                          position={{ top: -10, left: -75 }}
                        />
                        <View style={styles.largeButton}>
                          <Text style={{ fontSize: 20, fontWeight: "500", textAlign: "center" }}>
                            Prospetto riparazioni 
                          </Text>
                        </View>
                      </TouchableOpacity> :
                      <TouchableOpacity
                        disabled={true}
                      >
                        <View
                          style={[RoundedButtonStyle.button, {
                            width: 50, height: 50, zIndex: 1, top: -10, left: -76
                          }]}>
                          <Image
                            style={[{
                              width: 220,
                              height: 220,
                            }]}
                            source={require("../assets/iconoskubitsoft/Prospetto-03-01.png")}
                          />
                        </View>
                        <View style={[styles.largeButton, { backgroundColor: "#F6F6F6" }]}>
                          <Text style={{ fontSize: 20, fontWeight: "500", textAlign: "center" }}>
                            Prospetto riparazioni 
                          </Text>
                        </View>
                      </TouchableOpacity>


                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-10 col-md-11 col-12">
                    <div
                      className="d-flex justify-content-end"
                      style={{ height: 70 }}
                    >
                      {(inserts == 2) &&
                        <TouchableOpacity
                          onPress={() => navigation.navigate(!isAtelierr ? "ContactScreen" : "ContattiRiparazioni")}
                        >
                          <RoundedButton
                            icon={require("../assets/iconoskubitsoft/user.png")}
                            size={58}
                            position={{}}
                            styleIcon={55}
                          />
                        </TouchableOpacity>}
                    </div>
                  </div>
                </div>
              </View>
            </View>
          </View>
        </div>
      </SafeAreaView>
    </div>
  );
};
