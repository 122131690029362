import { useNavigation } from "@react-navigation/native";
import { Notify } from "../../interfaces/Notify";
import { NavigationProps } from "../../navigator/NavigatorConfig";

interface Props{
  items:Notify[]
}
export const FooterNotify = ({ items }:Props) => {
  const navigation = useNavigation<NavigationProps>();
  return (
    <div className="d-flex justify-content-end">
      <a
        onClick={() => navigation.navigate("NotifyScreen",{
          items
        })}
        className="px-3 text-white"
        style={{textDecoration:'none',background:'#db4444',borderRadius:'10px', cursor:'pointer', fontSize:'.8em',marginTop:'.5em'}}
      >
        <span>Vedi tutto</span> 
      </a>
    </div>
  );
};
