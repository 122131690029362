import axios from "axios";
import Cookies from "universal-cookie";
import ConfigCapone from "../utils/ConfigCapone";
import { ResponseGetListInteressiByIdClient } from "./interfaces/GetListInteressiByIdClient";
//cookie
const cookies = new Cookies();
const apiInteressi = `${ConfigCapone.urlapi}/api/ListInteressi`;
const limit = 21;
let pagina: Number;
let name: string;
let idNegozzio: Number;
let isImportant: Number;
let isListeVuote: Number;
let clientFilter: string;
let isAtelier: boolean;
export const getAllInteressi = async (limit: number, page: number, isAtelier = false) => {

  //?page=1&limit=4&name=Rolex&idBussines=1
  console.log("arrayData335", isAtelier)
  try {
    if (limit == 0 && page == 0) {
      const response = await axios.get(
        `${apiInteressi}?isAtelier=${isAtelier}`
      );
      const { data } = response;
      if (data.status === "success") {
        return response;
      }
    }
    const response = await axios.get(
      `${apiInteressi}?page=${page}&limit=${limit}`
    );
    console.log()
    const { data } = response;
    if (data.status === "success") {
      return response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const getAllInteressiFilter = async (paramaters: any) => {

  pagina = paramaters.page
  name = paramaters.name
  idNegozzio = paramaters.idBussines
  isImportant = paramaters.isImportant
  isListeVuote = paramaters.isListeVuote
  clientFilter = paramaters.clientFilter
  isAtelier = paramaters.isAtelier
  try {

    const response = await axios.get(
      `${apiInteressi}?page=${pagina}&limit=${limit}&name=${name}&idBussines=${idNegozzio}&isImportant=${isImportant}&isListeVuote=${isListeVuote}&clientFilter=${clientFilter}&isAtelier=${isAtelier}`
    );
    const { data } = response;
    if (data.status === "success") {
      return response;
    }
  } catch (error) {
    throw new Error();
  }
};
///api/ListInteressi?limit=10&name=rolex
export const getAllInteressiFilterSuggestion = async (name: string, isAtelier?: boolean) => {
  try {

    const response = await axios.get(
      `${apiInteressi}?limit=10&name=${name}&isAtelier=${isAtelier}`
    );
    const { data } = response;
    if (data.status === "success") {
      return response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const getAllInteressiFilterClientSuggestion = async (name: string) => {
  try {

    const response = await axios.get(
      `${ConfigCapone.urlapi}/api/Client/${name}/10`
    );
    const { data } = response;
    if (data.status === "success") {
      return response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const getAllBussines = async () => {
  try {
    const response = await axios.get(
      `${ConfigCapone.urlapi}/api/Bussines?parameter=1`
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const getAllBracciale = async () => {
  try {
    const response = await axios.get(
      `${ConfigCapone.urlapi}/api/Bracciale`
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const getAllQuadrante = async () => {
  try {
    const response = await axios.get(
      `${ConfigCapone.urlapi}/api/Quadrante`
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const postInteressi = async (datos: any) => {
  try {
    const response = await axios.post(
      `${ConfigCapone.urlapi}/api/ListInteressi`, datos, {
      headers: {
        'Authorization': `Bearer ${cookies.get('token')}`
      }
    }
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const postBracciale = async (datos: any) => {
  try {
    const response = await axios.post(
      `${ConfigCapone.urlapi}/api/Bracciale`, datos, {
      headers: {
        'Authorization': `Bearer ${cookies.get('token')}`
      }
    }
    );
    const { data } = response;
    if (data.status === "success") {
      return data.id;
    }
  } catch (error) {
    throw new Error();
  }
};
export const postQuadrante = async (datos: any) => {
  try {
    const response = await axios.post(
      `${ConfigCapone.urlapi}/api/Quadrante`, datos, {
      headers: {
        'Authorization': `Bearer ${cookies.get('token')}`
      }
    }
    );
    const { data } = response;
    if (data.status === "success") {
      return data.id;
    }
  } catch (error) {
    throw new Error();
  }
};
export const deleteInteressi = async (idList: number, idBussines: number) => {
  try {
    const response = await axios.delete(
      `${ConfigCapone.urlapi}/api/ListInteressi/${idList}/${idBussines}`, {
      headers: {
        'Authorization': `Bearer ${cookies.get('token')}`
      }
    }
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const editInteressi = async (idList: number, newName: string, idBussines: number) => {
  const dataSend = {
    name: newName,
    idBussines: idBussines
  }
  try {
    const response = await axios.put(
      `${ConfigCapone.urlapi}/api/ListInteressi/${idList}`, dataSend, {
      headers: {
        'Authorization': `Bearer ${cookies.get('token')}`
      }
    }
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const searchListeName = async (name: string) => {
  try {
    if (name != "") {
      const response = await axios.get(
        `${ConfigCapone.urlapi}/api/ListInteressi?name=${name}`
      );
      const { data } = response;
      if (data.status === "success") {
        return data.response;
      }
    }
    getAllInteressi(0, 0)

  } catch (error) {
    throw new Error();
  }
};
export const searchListeBussines = async (id: Number) => {
  try {
    if (id !== 0) {
      const response = await axios.get(
        `${ConfigCapone.urlapi}/api/ListInteressi?idBussines=${id}`
      );
      const { data } = response;
      if (data.status === "success") {
        return response;
      }
    }
    getAllInteressi(0, 0)

  } catch (error) {
    throw new Error();
  }
};


export const postInteressiClient = async (datos: any) => {
  try {
    const response = await axios.post(
      `${ConfigCapone.urlapi}/api/ListInteressiClient`, datos
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};

export const ListInteressiByClient = async (id: number) => {
  try {
    const response = await axios.get<ResponseGetListInteressiByIdClient>(
      `${ConfigCapone.urlapi}/api/ListInteressiClient/FindByIdClient/${id}`
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const getTitle = async () => {
  try {

    const response = await axios.get(
      `${ConfigCapone.urlapi}/api/Title?parameter=1`
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const postNewInteressiClient = async (datos: any) => {
  try {
    const response = await axios.post(
      `${ConfigCapone.urlapi}/api/Client/SaveClientManifestazione`, datos, {
      headers: {
        'Authorization': `Bearer ${cookies.get('token')}`
      }
    }
    );
    const { data } = response;
    if (data.status === "success") {
      return data.id;
    }
  } catch (error) {
    throw new Error();
  }
};

export const FindByIdClientCrm = async (idClient: number) => {
  let client: any = [];
  await axios
    .get(`${ConfigCapone.urlapi}/api/Client/FindByIdClientCrm/${idClient}`)
    .then((response) => {
      client = response.data.response
    })

  return client
}

export const ModifyClientManifestazione = async (datos: any) => {
  try {
    const response = await axios
      .put(`${ConfigCapone.urlapi}/api/Client/ModifyClientManifestazione`, datos, {
        headers: {
          'Authorization': `Bearer ${cookies.get('token')}`
        }
      })
    const { data } = response;
    if (data.status === "success") {
      return data.id;
    }
  } catch (error) {
    throw new Error();
  }
};

export const dateTimeNow = (fecha: string = '') => {
  let date
  if (fecha !== '') {
    date = new Date(fecha)
  } else {
    date = new Date()
  }
  const year = date.getFullYear()
  const month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : date.getMonth() + 1
  const day = date.getDate().toString().padStart(2, "0")
  const hour = date.getHours().toString().padStart(2, "0")
  const minute = date.getMinutes().toString().padStart(2, "0")
  const seconds = date.getSeconds().toString().padStart(2, "0")
  const dateNow =
    year +
    '-' +
    month +
    '-' +
    day +
    'T' +
    hour +
    ':' +
    minute +
    ':' +
    seconds

  return dateNow
}
export const dateTimeNowNotify = (fecha: string = '') => {
  let date
  if (fecha !== '') {
    date = new Date(fecha)
  } else {
    date = new Date()
  }
  const year = date.getFullYear()
  const month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : date.getMonth() + 1
  const day = date.getDate().toString().padStart(2, "0")
  const hour = date.getHours().toString().padStart(2, "0")
  const minute = date.getMinutes().toString().padStart(2, "0")
  const seconds = date.getSeconds().toString().padStart(2, "0")
  const dateNow =
    day +
    '/' +
    month +
    '/' +
    year +
    ' ' +
    hour +
    ':' +
    minute
  return dateNow
}
export const dateTimeNowFormatDDMMYYY = (fecha: string = '') => {
  let date
  if (fecha !== '') {
    date = new Date(fecha)
  } else {
    date = new Date()
  }
  const year = date.getFullYear()
  const month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : date.getMonth() + 1
  const day = date.getDate().toString().padStart(2, "0")
  const dateNow =
    day +
    '-' +
    month +
    '-' +
    year
  return dateNow
}
export const formatDDMMYYY = (fecha: string = '') => {
  let date
  if (fecha !== '') {
    date = new Date(fecha)
  } else {
    date = new Date()
  }
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate().toString().padStart(2, "0")
  const dateNow =
    year +
    '-' +
    month +
    '-' +
    day
  return dateNow
}

export const formatYYYMMDD = (fecha: string = '') => {
  var d = new Date(fecha),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  const date = [year, month, day].join('-')
  return date;
}

export const FindClient = async (phone: string) => {
  try {
    const response = await axios.get(
      `${ConfigCapone.urlapi}/api/Client/SearchListClientCrm?value=${phone}&parameter=phone`
    );
    const { data } = response;
    if (data.status === "success") {
      if (data.response?.length > 0) {
        return data.response;
      }
      return true;
    }
  } catch (error) {
    throw new Error();
  }
};
