import React from "react";
import { useNavigation } from "@react-navigation/native";
import { View } from "react-native";
import { NavigationProps } from "../../navigator/NavigatorConfig";
import { HeaderNewRepair } from "../HeaderNewRepair";


export const Header = (controlPanel:any) => {
const navigation = useNavigation<NavigationProps>();
  console.log("HeaderControlPanel: ", controlPanel.controlPanel)
  return (   
    <View style={{ paddingHorizontal: 12 }}>
    {controlPanel.controlPanel==true ? (
      <HeaderNewRepair
        hasRoundedIcon={false}
        title="Aggiungi cliente"
        hasPadding={true}
        onBack={() => navigation.navigate("ControlPanelScreen")}
      />
    ) : (
      <HeaderNewRepair
        hasRoundedIcon={false}
        title="Magazzino"
        hasPadding={true}
        onBack={() => navigation.navigate("MagazzinoHomeScreen")}
      />
    )}
  </View>  

  );
};
