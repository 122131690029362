import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Axios } from 'axios';
import React, { useEffect, useState } from 'react'
import { Pressable, SafeAreaView, ScrollView, StatusBar, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import * as Print from "expo-print";
import axios from "axios";
import ConfigCapone from '../../../../utils/ConfigCapone';
import { styles } from '../../../../theme/GlobalStyle';
import { stylesRepair } from '../../../../theme/CRM/ClientListS';
import { useAuth } from '../../../../hooks/useAuth';
import { useIsFocused } from '@react-navigation/native';
import { useProfile } from '../../../../components/NavProfile/hook/useProfile';
import ShowContentSidebar from '../../../../components/NavProfile/ShowContentSidebar';
import { Navbar } from '../../../../components/Navbar';
import NavProfile from '../../../../components/NavProfile/NavProfile';

interface Props extends NativeStackScreenProps<any, any> { }

export const PrintLabelScreen = ({ route, navigation }: Props) => {

    let arrayData: any = route.params || [];
    let ListGender: any = [];
    const [titleType, setTitleType] = useState([]);
    const [listClient, setListClient] = useState([]);
    const isFocused = useIsFocused();
    const { sidebarVisible, toggleSidebar } = useProfile()

    // pdf
    const [buttomHidden, setButtomHidden] = useState(false);
    const [occult, setOccult] = useState(false)

    let dataClient = arrayData ? arrayData : "";
    let newDataClient: any = [];

    const descriptionTitle = async (data: any) => {
        let name: any = []
        name = await ListGender?.find((item: any) => item.idTitle == data)
        return name?.desc_Title;
    };

    const client = async () => {
        for (let index = 0; index < dataClient?.length; index++) {
            let title = await descriptionTitle(dataClient[index].idTitle)
            await newDataClient.push({ ...dataClient[index], idTitle: title })
        }
        setListClient(newDataClient)
    }

    const getTitle = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Title?parameter=1`)
            .then((response) => {
                setTitleType(response.data.response)
                ListGender = response.data.response
            })
            .catch((error) => console.log(error));

        await client();
    }

    useEffect(() => {
        getTitle();
    }, [])

    const occultBottons = () => {
        printToFile()
    }

    const html: any = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                    integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
                <title>Document</title>
                <style>
                    p {
                        / font-size:  !important; /
                        font-weight: bold;
                    }
                    .firm{
                        border-top-color: transparent;
                        border-left-color: transparent;
                        border-right-color: transparent;
                    }
                    hr {
                        width: '30%';
                        border-width: 2px;
                    }
                    input {
                        / font-size: medium !important; /
                        font-weight: bold;
                        border: solid 2px
                    }
                    label {
                        font-weight: bold;
                    }
                    h6 {
                        font-size: medium;
                    }
                    h5 {
                        font-size: medium;
                        font-weight: bold;
                    }
                    span {
                        font-weight: bold;
                    }
                    .printButton{
                            display: none !important;
                        }
                </style>
            </head>
            <body>
                <div class="container mt-3">
                        
                </div>
            </body>
            </html>
  `;

    const printToFile = async () => {

        try {

            const { uri } = await Print.printToFileAsync({
                html,
            });

            setOccult(false)

        } catch (error) {
            setButtomHidden(!buttomHidden);
            setTimeout(() => {
                setOccult(false)
            }, 100);
            //setOccult(true)
        }
    };

    return (
        <>
            <div className='printButton' >
                <ShowContentSidebar
                    sidebarVisible={sidebarVisible}
                    toggleSidebar={toggleSidebar}
                />
            </div>

            <div className="container-layout">
                <div className="row row-layout">
                    <div className={` ${sidebarVisible ? 'col-12 d-block' : 'col-lg-3 col-md-3 d-none d-md-block'} sidebar-container-layout sidebar-transition`}>
                        <Navbar activeNav={isFocused} componentName={route.name} componentDad="CrmAtelier" />
                    </div>
                    <div
                        className="col-lg-9 col-md-9 col-sm-12 content-container-layout"
                        style={{ paddingLeft: "1.5rem", overflowY: 'auto', height: '100%', scrollbarWidth: 'thin' }}
                    >
                        <div className='printButton'>
                            <NavProfile
                                showMenuBack={route.name !== 'CrmAtelier'}
                                sidebarVisible={sidebarVisible}
                                toggleSidebar={toggleSidebar}
                                goBack="ClientListAtelierScreen"
                                goBackName="Lista Clienti Atelier"
                            />
                        </div>

                        <div className='row'>
                            <div className="col-12">
                                <div className="printButton mb-1" >
                                    <style>
                                        {`@media print {.printButton{display: none;}}`}
                                    </style>
                                    <Pressable
                                        style={{
                                            width: 200,
                                            marginTop: 3,
                                            height: 35,
                                            paddingTop: 1,
                                            backgroundColor: "#09ABF7",
                                            alignItems: "center",
                                        }}
                                        onPress={occultBottons}
                                    >
                                        <Text style={{ color: "white", fontSize: 20 }}>Stampa PDF </Text>
                                    </Pressable>
                                </div>
                            </div>
                            <div className="row col-12">
                                {listClient && listClient.map((data, index) => {
                                    return (
                                        <div className="col-12 col-md-6 mt-5">
                                            <h5>
                                                {data.idTitle} {data.name}
                                            </h5>
                                            <h5>
                                                {data.address} <br />
                                            </h5>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}