import {
    Modal,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import { HeaderModal } from "../HeaderModal";
import { stylesModal } from "../../theme/ModalStyles";
import axios from "axios";
import ConfigCapone from "../../utils/ConfigCapone";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import Loading from "../Loading";
import Cookies from 'universal-cookie';
interface IModalOptionSchemaProdotto {
    modalVisible: boolean;
    setModalVisible: any;
    setModalSchedaProdotto?: any;
    activeButton:boolean;
    idMatricola?: string;
    idBussinessSeller?: any;
    typeMatricola?: string;
    goToSchedaProdotto?: any;
    updateStateMatricole?: any;
    referencia?:any;
    prezzo?:string;
}

const ModalOptionSchemaProdotto = ({
    modalVisible = false,
    setModalVisible,
    setModalSchedaProdotto,
    activeButton = true,
    idMatricola,
    idBussinessSeller,
    typeMatricola,
    goToSchedaProdotto,
    updateStateMatricole,
    prezzo,
    referencia
}: IModalOptionSchemaProdotto) => { 

    const { userInLogin } = useContext(UserContext);
    const [activeSpinner, setActiveSpinner] = useState(false);
    const [apiBusiness, setApiBusiness] = useState([]);
    const cookies = new Cookies();
    // const selectBussiness = async () => {
    //     setActiveSpinner(true);
    //     await axios
    //       .get(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`)
    //       .then((response) => {
    //         setApiBusiness(response.data.response)            
    //       })
    //       .catch((err) => console.log(err));
    //     setActiveSpinner(false);
    // };

    const sellToClient = async() => {

        setActiveSpinner(true)

        await updateStateMatricole(idMatricola, true, typeMatricola, 0);

        let data = {
            idBussinessSeller: idBussinessSeller.idBussines,
            idClient: 0,
            sellDate: new Date(),
            idMatricola: idMatricola,
            idUser: userInLogin.idUser,
        }
        
        await axios.post(`${ConfigCapone.urlapi}/api/Sales`, data,{
            headers: {
              'Authorization': `Bearer ${cookies.get('token')}`
            }
          })
            .then(async (res) => console.log("request update", res))


        // await sendEmail("Nuova Vendita",idMatricola)      
        setTimeout(() => {
            setActiveSpinner(false)
            setModalVisible(false)
            goToSchedaProdotto();
            setModalSchedaProdotto(false);
        }, 1000);
    }

    // const sendEmail = async(type:string, id:any) => {
    //     let mat :any;
    //     let date = dateTimeNow();
    //     let descriptionNegozio = apiBusiness.find( data => data.idBussines == idBussinessSeller.idBussines )
    //     await axios
    //     .get(`${ConfigCapone.urlapi}/api/Matricola/${id}`)
    //     .then((response) => {
    //         mat = response.data.response?.matricola;   
    //         console.log("online: ", response.data.response);                      
    //     })
    //     .catch((error) => {
    //         console.log("error -> ", error);
            
    //     });
  
    //     let emailData = {
    //       type: "notice",
    //       email: "yasmani1604@gmail.com",
    //       // email: "marketing@sergiocapone.com",
    //       subject: `${type}`,
    //       message: `<p> Operazione: ${type}</p>
    //                <p> Operatore: ${userInLogin.userName}</p>
    //                <p> Referenza: ${referencia} </p>
    //                <p> Negozio: ${descriptionNegozio?.desc_Bussines} </p>
    //                <p> Matricola: ${mat}</p>
    //                <p> Prezzo: ${prezzo}</p>
    //                <p> Data: ${date}</p>`,
    //     };
  
    //     await axios
    //       .post(`${ConfigCapone.apicapone}/api/forma`, emailData)
    //       .then((res) => {
    //         console.log(res);
    //         console.log("info : ", emailData);
    //       })
    //       .catch((err) => console.log("there was an error: " + err));
  
    //   }
  
    //   const dateTimeNow = (fecha: string = '') => {
    //     let date
    //     if (fecha !== '') {
    //       date = new Date(fecha)
    //     } else {
    //       date = new Date()
    //     }
    //     const year = date.getFullYear()
    //     const month =
    //       date.getMonth() + 1 < 10
    //         ? `0${date.getMonth() + 1}`
    //         : date.getMonth() + 1
    //     const day = date.getDate().toString().padStart(2, "0")
    //     const hour = date.getHours().toString().padStart(2, "0")
    //     const minute = date.getMinutes().toString().padStart(2, "0")
    //     const seconds = date.getSeconds().toString().padStart(2, "0")
    //     const dateNow =
    //       day +
    //       '/' +
    //       month +
    //       '/' +
    //       year +
    //       ', ore ' +
    //       hour +
    //       ':' +
    //       minute +
    //       ':' +
    //       seconds
    
    //     return dateNow
    //   }

    // useEffect(() => {     
    //     selectBussiness();
    // }, [])

    return (
        <View >
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <ScrollView style={{width: "100%", height: "100%"}}> 
                {activeSpinner && <Loading activeSpinner={activeSpinner} />}
                    <View
                    style={[
                        stylesModal.centeredView,
                        { paddingTop: 150, paddingBottom: 150 },
                    ]}
                    >
                        <View style={stylesModal.modalView}>
                            <HeaderModal
                            setModalVisible={setModalVisible}
                            modalVisible={modalVisible}
                            title={"Sei sicuro di vendere questo articolo?"}
                            activatedPadLeft={true}
                            />
                            <div className="row w-100">
                                 <div className="col-12 d-flex justify-content-center my-5">
                                    <TouchableOpacity
                                        style={{marginRight:15}}
                                        onPress={() => sellToClient()} 
                                        >
                                        <View style={[stylesModalOptionSchemaProdotto.buttonWhite, {width: 150}]}>
                                            <Text style={stylesModalOptionSchemaProdotto.buttonTextWhite}>
                                            {"Si"}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={()=>setModalVisible(!modalVisible)}
                                        >
                                        <View style={[stylesModalOptionSchemaProdotto.buttonWhite, {width: 150}]}>
                                            <Text style={stylesModalOptionSchemaProdotto.buttonTextWhite}>
                                            {"No"}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                 </div>
                            </div>
                        </View>
                    </View>
                </ScrollView>
            </Modal>
        </View>
    )
}

export default ModalOptionSchemaProdotto;

const stylesModalOptionSchemaProdotto = StyleSheet.create({
    buttonTextWhite: {
        color: "black",
        alignSelf: "center",
        fontWeight: "500",
        fontSize: 18,
    },
    buttonWhite: {
        backgroundColor: "white",
        justifyContent: "center",
        borderRadius: 50,
        width: 130,
        height: 40,
        borderWidth: 1,
        borderColor: "black",
    },
});

