import React from "react";
import { Text} from "react-native";
import { FilterMagazzinoAtelier } from "./FilterMagazzinoAtelier";



interface Props {
  onChangeTextProdotto:any;
  searchInputProdotto:any;
  handleSearchFilter:any;
  dataExcelMagazzino:any;
  setLoading:any;
  setgriglia:any;
  griglia:any;
  donwnloadExcel:any;
  DataSet:any;
  ExcelFile:any;
  nameFileExport:any;
  ExcelSheet:any;
  handleDataExport:any;
  width:any;
}
export const SearchMagazzinoAtelier = ({onChangeTextProdotto ,searchInputProdotto,handleSearchFilter,dataExcelMagazzino,setLoading,setgriglia,griglia,donwnloadExcel,DataSet,ExcelFile,nameFileExport,ExcelSheet,handleDataExport,width}: Props) => {
  
  return (
    <>
      <div className="row" >
        <div className="col-12 col-md-12 col-lg-5">
          <div className="mt-2" style={{ marginLeft: -13 }}>
            <Text
              style={{
                fontWeight: "700",
                fontSize: 20,
                alignSelf: "center",
                paddingLeft: 10,
              }}
            >
              Cerca prodotto
            </Text>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-7" style={{float: 'right'}}>
          <FilterMagazzinoAtelier
            placeholder="Cerca..."
            marginRight
            handleSearchFilter={handleSearchFilter}
            onChangeTextProdotto={onChangeTextProdotto}
            searchInputProdotto={searchInputProdotto}
            dataExcelMagazzino ={dataExcelMagazzino}   
            setLoading={setLoading}    
            griglia={griglia}  
            setgriglia={setgriglia} 
            donwnloadExcel={donwnloadExcel}
            DataSet={DataSet}
            ExcelFile={ExcelFile}
            nameFileExport={nameFileExport}
            ExcelSheet={ExcelSheet}
            handleDataExport={handleDataExport}
            width={width}
          />
        </div>
      </div>
    </>
  );
};
