export const normalizedDate = (date: string | Date) => {
  const record = new Date(date);
  const year = record.getFullYear();
  const month = record.getMonth() + 1;
  const day = record.getDate();
  const hour = record.getHours();
  const minute = record.getMinutes();
  const dateNow =
    year +
    "-" +
    month.toString().padStart(2, "0") +
    "-" +
    day.toString().padStart(2, "0") +
    " " +
    hour.toString().padStart(2, "0") +
    ":" +
    minute.toString().padStart(2, "0");

  return dateNow;
};

export const normalizedDateTime = (date: Date) => {
  const hour = date.getHours();
  const minute = date.getMinutes();
  const dateNow =
    hour.toString().padStart(2, "0") + ":" + minute.toString().padStart(2, "0");

  return dateNow;
};
