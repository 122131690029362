import { useFormRepair } from "../../hooks/formRepair/useFormRepair";
import { TabList } from "./TabList";

export const Wrapper = () => {
  const { currentStep, steps } = useFormRepair();
  return (
    <div className="widget-content widget-content-area">
      <div className="wizard-steps" role="application">
        <TabList />
      </div>
      <section className="widget-content-body">{steps[currentStep]}</section>
    </div>
  );
};
