import React from "react";
import { Collapse } from "react-collapse";
import { useShow } from "../../../../../hooks/useShow";
import { CollapseTitleAtelier } from "../../../../Magazzino/Atelier/views/CollapseTitleAtelier";
import { BrandResponse } from "../../../../../interfaces/magazzino";
import { CheckAtelier } from "./CheckAtelier";

interface Props {
  list: BrandResponse[];
  handleFilter: (value: string) => void;
}
export const FilterCrmCheckboxBrand = ({
  list,
  handleFilter,
}: Props) => {
  const { show, toggle } = useShow();
  return (
    <>
      <CollapseTitleAtelier title="Brand acquistati" toggle={toggle} />
      <Collapse isOpened={show}>
        <div className="col-12">
          {list.map(({ idBrand, desc_Brand }) => (
            <div key={idBrand}>
              <CheckAtelier
                name={desc_Brand}
                id={'brand-'+idBrand}
                text={desc_Brand}
                marginleft
                handlechange={handleFilter}
              />
              <br />
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};
