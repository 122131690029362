export const type_action = [
    {
        id: 1,
        Desc_type: 'richiesti'
    },
    {
        id: 2,
        Desc_type: 'consigliati'
    }
]

export const work_required = [
    {
        idWork: 1,
        Desc_work: 'lavoro richiesto I',
        idTypeAction: 1
    },
    {
        idWork: 2,
        Desc_work: 'lavoro richiesto II',
        idTypeAction: 1
    },
    {
        idWork: 3,
        Desc_work: 'lavoro richiesto III',
        idTypeAction: 1
    },
    {
        idWork: 4,
        Desc_work: 'lavoro richiesto IV',
        idTypeAction: 1
    },
    {
        idWork: 5,
        Desc_work: 'lavoro richiesto V',
        idTypeAction: 1
    },
    {
        idWork: 6,
        Desc_work: 'lavoro consigliati I',
        idTypeAction: 2
    },
    {
        idWork: 7,
        Desc_work: 'lavoro consigliati II',
        idTypeAction: 2
    },
    {
        idWork: 8,
        Desc_work: 'lavoro consigliati III',
        idTypeAction: 2
    },
    {
        idWork: 9,
        Desc_work: 'lavoro consigliati IV',
        idTypeAction: 2
    },
    {
        idWork: 10,
        Desc_work: 'lavoro consigliati V',
        idTypeAction: 2
    },
]

export let work_detail = [
    {
        id: 1,
        idWork: 1,
        price: '22,40',
        fkPayment: 1
    },
    {
        id: 2,
        idWork: 2,
        price: '20,40',
        fkPayment: 1
    },
    {
        id: 3,
        idWork: 6,
        price: '10,50',
        fkPayment: 1
    },
    {
        id: 4,
        idWork: 7,
        price: '5,40',
        fkPayment: 1
    },
    {
        id: 5,
        idWork: 8,
        price: '26,40',
        fkPayment: 1
    },
]