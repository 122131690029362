import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Image, SafeAreaView, ScrollView, StatusBar, StyleSheet, Text, TouchableOpacity, View, FlatList, Pressable, TextInput } from 'react-native'
import { age, clientData, entryDate, filterGenders, filterStar, others, others2, spentValue, updateDate } from '../../api/FilterClientData';
import { filterBrand, filterBusiness } from '../../api/FilterStockData';
import { hobbyData } from '../../api/HobbyData';
import { CheckLabel } from '../../components/CheckLabel';
import { HeaderBar } from '../../components/HeaderBar';
import moment from 'moment'
import { SearchBox } from '../../components/SearchBox';
import { styles, tableHeader, tableRow } from '../../theme/GlobalStyle';
import { UserContext } from "../../context/UserContext";

import { IClient } from "../../interfaces/IClient";
import ConfigCapone from "../../utils/ConfigCapone";
import axios from "axios";

import ReactExport from 'react-data-export';
import { todayFormatDate } from "../../utils/helpers";
import { useNotifyCrud } from "../../hooks/useNotifyCrud";
//Styles
import { stylesRepair, styleSearch } from '../../theme/CRM/ClientListS';
import PopupConfirmation from "../../components/PopupConfirmation";
import Loading from '../../components/Loading';
import Cookies from 'universal-cookie';
import { bool } from 'yup';
import data from './data/mock-data.json';
import Pagination from '../../components/Pagination/pagination';
import './data/style.css';
import { Collapse } from "react-collapse";
import PopUpMessageWithRoute from "../../components/PopUpMessageWithRoute";
import { SendEmailCRMScreen } from '../../components/CRM/SendEmailCRMScreen';
import { referenciaSearch } from './services/CrmService';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

interface Props extends NativeStackScreenProps<any, any> { }
export const ClientListScreen = ({ navigation }: Props) => {

    const { userInLogin } = useContext(UserContext);
    const { createdPosted } = useNotifyCrud();
    const [activeSpinner, setActiveSpinner] = useState(false);

    const [isAdmin, setIsAdmin] = useState<boolean>(userInLogin?.idRole === '2' ? true : false);
    const [isMarketing, setIsMarketing] = useState<boolean>(userInLogin?.idRole === '6' ? true : false);
    const [isDipendente, setIsDipendente] = useState<boolean>(userInLogin?.idRole === '4' ? true : false);
    const [isDirettore, setIsDirettore] = useState<boolean>(userInLogin?.idRole === '5' ? true : false);
    const [modalVisibleSendEmail,setModalVisibleSendEmail]=useState(false)
    const [dataClientSendEmail,setDataClientSendEmail]=useState();
    const [dataGender, setDataGender] = useState([]);
    const [dataBrand, setDataBrand] = useState([]);
    const [dataBussines, setDataBussines] = useState([]);
    const [dataHobby, setDataHobby] = useState([]);
    const [listFilters, setListFilters] = useState([]);
    const [dataClientHobby, setDataClientHobby] = useState([]);
    const [dataClientProdposseduti, setDataClientProdposseduti] = useState([]);
    const [dataMatricola, setDataMatricola] = useState([]);
    const [dataReferenzia, setDataReferenzia] = useState([]);
    const [dataClient, setDataClient] = useState<any[]>();
    const [ListFilterClient, setListFilterClient] = useState<any[]>();
    const [ListFilterClientExcel, setListFilterClientExcel] = useState<any[]>();
    const [ListFilterDirectore, setListFilterDirectore] = useState<any[]>();
    const [ListFilDipendenteF, setListFilDipendenteF] = useState<any[]>();
 
    // search
    const [searchInputClient, setSearchInputClient] = useState<string>();
    const [autosuggestClientInput, setAutosuggestClientInput] = useState<IClient[]>([]);
    const [stateClient, setStateClient] = useState(false);
    const [listFilterActive, setListFilterActive] = useState<boolean>(false);

    const [activeSuggestionReferenza, setActiveSuggestionReferenza] = useState(true)
    const [autosuggestInputReferenza, setAutosuggestInputReferenza] = useState<any[]>([]);

    //modal delete
    const [modal, setModal] = useState(false);
    const [idDeleteUser, setIdDeleteUser] = useState(0);
    //Menu Filter Open Close
    const [menufilterOpenClose, setMenufilterOpenClose] = useState<boolean>(false);
    //cookie
    const cookies = new Cookies();
    //Menu OpenClose
    //DropDown
    const [show, setShow] = useState({
        menuGenere: false,
        menuStelle: false,
        menuBrand: false,
        menuValore: false,
        menuEta: false,
        menuInteressi: false,
        menuNegozio: false,
        menuData: false,
        menuAggiornamento: false,
        menuReferenza: false,
        menuProfessione: false,
        menuNote: false,
        menuMatricola: false
    });
    const handleShow = (val: string) => {
        setShow({
            ...show,
            [val]: !show[val],
        });
    };
    // export
    const [nameFileExport, setNameFileExport] = useState<any>("Tabella Clienti " + todayFormatDate);

    //checkBox
    const [checkBoxGenderChecked, setcheckBoxGenderChecked] = useState<any>([]);
    const [checkBoxBrandChecked, setcheckBoxBrandChecked] = useState<any>([]);
    const [checkBoxHobbyChecked, setCheckBoxHobbyChecked] = useState<any>([]);
    const [checkBoxStartChecked, setCheckBoxStartChecked] = useState<any>([]);
    const [checkBoxSpentValueChecked, setCheckBoxSpentValueChecked] = useState<any>([]);
    const [checkBoxAgeChecked, setCheckBoxAgeChecked] = useState<any>([]);
    const [checkBoxNegozzioChecked, setCheckBoxNegozzioChecked] = useState<any>([]);
    const [checkBoxDateInsertChecked, setCheckBoxDateInsertChecked] = useState<any>([]);
    const [checkBoxDateUpdateChecked, setCheckBoxDateUpdateChecked] = useState<any>([]);
    const [checkBoxOthersChecked, setCheckBoxOthersChecked] = useState<any>([]);
    const [inputReference, setInputReference] = useState<string>();
    const [inputMatricola, setInputMatricola] = useState<string>();
    const [isActiveinputReference, setIsActiveInputReference] = useState<boolean>(false);
    const [isActiveinputMatricola, setIsActiveInputMatricola] = useState<boolean>(false);
    const [inputProfession, setInputProfession] = useState<string>();
    const [isActiveinputProfession, setIsActiveInputProfession] = useState<boolean>(false);
    const [inputNote, setInputNote] = useState<string>();
    const [isActiveInputNote, setIsActiveInputNote] = useState<boolean>(false);
    const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({ 
        title: "Informazione",
        message: "",
        active: false,
        route: "",
    });
    //paginacion
    const [paginationAlls, setPaginationAlls] = useState([]);
    const [paginaActual, setPaginaActual] = useState(1);
    const [reloadPage, setReloadPage] = useState(false)
    const [visiblePagination, setVisiblePagination] = useState<boolean>(false);
    let PageSize = 50;
    // change checkbox
    const changeGender = (item: any) => {
        setcheckBoxGenderChecked((state: any) => ({
            ...state,
            ["gender-" + item.idGender]: state["gender-" + item.idGender]
                ? null
                :
                {
                    idGender: "gender-" + item.idGender ? "gender-" + item.idGender : '',
                    gender: item.gender
                }
        }))
    }
    const sendLogg = async (mesaggeN: string) => {
        let selectedMessage = {
            message: mesaggeN,
        };
        await axios
            .post(`${ConfigCapone.urlapi}/api/Notify/Logg/`, selectedMessage, {
                headers: {
                    'Authorization': `Bearer ${cookies.get('token')}`
                }
            })
            .then((response) => {
            })
            .catch((error) => console.log(error));
    };
    const sendLoggAll = async () => {
        sendLogg(` Esportazione dei dati del modulo di CRM - Cerca cliente`)
        createdPosted({
            actionLog: 'Esportare',
            message: 'Esportazione dei dati del modulo di CRM - Cerca cliente'
        })
    }
    const changeBrand = (item: any) => {
        setcheckBoxBrandChecked((state: any) => ({
            ...state,
            ["brand-" + item.idBrand]: state["brand-" + item.idBrand]
                ? null
                :
                {
                    idBrand: "brand-" + item.idBrand ? "brand-" + item.idBrand : '',
                    desc_Brand: item.desc_Brand
                }
        }))
    }

    const changeHobby = (item: any) => {
        setCheckBoxHobbyChecked((state: any) => ({
            ...state,
            [`hobby-${item.idHobby}`]: state[`hobby-${item.idHobby}`]
                ? null
                :
                {
                    idHobby: `hobby-${item.idHobby}`,
                }
        }))
    }

    const changeStart = (item: any) => {
        setCheckBoxStartChecked((state: any) => ({
            ...state,
            [item.id]: state[item.id]
                ? null
                :
                {
                    id: item.id ? item.id : '',
                }
        }))
    }

    const changeSpentValue = (item: any) => {
        setCheckBoxSpentValueChecked((state: any) => ({
            ...state,
            [item.id]: state[item.id]
                ? null
                :
                {
                    id: item.id ? item.id : '',
                    text: item.text ? item.text : ''
                }
        }))
    }

    const changeAge = (item: any) => {
        setCheckBoxAgeChecked((state: any) => ({
            ...state,
            [item.id]: state[item.id]
                ? null
                :
                {
                    id: item.id ? item.id : '',
                    text: item.text ? item.text : ''
                }
        }))
    }

    const changeNegozio = (value: any) => {

        if (value) {
            setCheckBoxNegozzioChecked((state: any) => ({
                ...state,
                [`business-${value.idBussines}`]: state[`business-${value.idBussines}`]
                    ? null
                    :
                    {
                        idBussines: `business-${value.idBussines}`,
                        desc_Bussines: value.desc_Bussines
                    }
            }))
        }

    };

    const changeDateInsert = (item: any) => {
        setCheckBoxDateInsertChecked((state: any) => ({
            ...state,
            [item.id]: state[item.id]
                ? null
                :
                {
                    id: item.id ? item.id : '',
                    text: item.text
                }
        }))
    }

    const changeDateUpdate = (item: any) => {
        setCheckBoxDateUpdateChecked((state: any) => ({
            ...state,
            [item.id]: state[item.id]
                ? null
                :
                {
                    id: item.id ? item.id : '',
                    text: item.text
                }
        }))
    }

    const changeOthers = (item: any) => {
        setCheckBoxOthersChecked((state: any) => ({
            ...state,
            [item.id]: state[item.id]
                ? null
                :
                {
                    id: item.id ? item.id : '',
                    text: item.text
                }
        }))
    }
    //paginacion
    const [paginationListe, setpaginatcionListe] = useState({
        currentPage: 0,
        total: 0,
        totalPages: 0,
    });
    const paginationAll = () => {
        const numerodepaginas = Array(Number(paginationListe.totalPages)).fill(0)
        setPaginationAlls(numerodepaginas)
    }
    useEffect(() => {
        paginationAll()
    }, [paginationListe])
    //fin paginacion
    const headers = [
        '',
        'Cliente',
        'E-mail',
        'Telefono'
    ];
    const confirmAction = (Landing: any, data: any) => {
        navigation.navigate(Landing, data);
        setmodaleMessaggeWithUrl({
            title: "Informazione",
            message: "",
            active: false,
            route: "",
        });
    };
    const showSuccessPopup = (mess: string, tiTle: string) => {
        setmodaleMessaggeWithUrl({
            active: true,
            message: mess,
            title: tiTle,
            route: "",
        })
    };
    const getGender = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Gender?parameter=1`)
            .then((response) => setDataGender(response.data.response))
            .catch((error) => console.log(error));
    };

    const getBrand = async () => {

        let brand: any = [];
        let brandFilter: any = [];

        await axios(`${ConfigCapone.urlapi}/api/Brand?parameter=1`)
            .then((response) => brand = response.data.response)
            .catch((error) => console.log(error));

        brandFilter = brand.filter((data: any) => data.desc_Brand != "Altro")
        setDataBrand(brandFilter)

    };

    const getHobby = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Hobby?parameter=1`)
            .then((response) => {
                setDataHobby(response.data.response)
                let data = response.data.response;
            })
            .catch((error) => console.log(error));
    };

    const getBussines = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`)
            .then((response) => {
                let data = response.data.response.filter( x => Number(x.idBussines) != 9);
                setDataBussines(data)      
            })
            .catch((error) => console.log(error));
    };
    const emailModal = (data)=>{
        var mail = document.createElement("a");
        mail.href = `mailto:${data.email}`;
        mail.click();
        // setDataClientSendEmail(data)
        // setModalVisibleSendEmail(true)
    }
    const getClient = async () => {

        let listDirectore: any = []
        let stateDirectore: boolean = false;
        let listDipendenteF: any = []
        let stateDipendente: boolean = false;

        await axios(`${ConfigCapone.urlapi}/api/Client/SearchListClientCrm?parameter=1`)
            .then((response) => {

                if (isDirettore || isDipendente) {
                    if (isDirettore) {
                        stateDirectore = true;
                        listDirectore = response.data.response;
                        setListFilterDirectore(response.data.response)
                    }
                    if (isDipendente) {
                        stateDipendente = true;
                        listDipendenteF = response.data.response;
                        setListFilDipendenteF(response.data.response)
                    }
                } else {
                }

            })
            .catch((error) => console.log(error));

        stateDirectore && await getClientDirectoreF();
        stateDipendente && await getUserDipendente();
    };
    //MODIFICADO SEARCH CLIENTE BUSSINES
    const getClientDirectoreF = async () => {
        let mensaje = "Sequence contains no elements";
        let msg: any;
        let newSelectedFilters = {
            bussinesFilter: searchInputClient ? "" : [userInLogin.idBussines],
            page: searchInputClient ? 1 : paginaActual,
            limit: 50,
            clientFilter: searchInputClient ? searchInputClient : ''
        };

        await axios(`${ConfigCapone.urlapi}/api/Client/SearchListClientFilterCrm`, { params: newSelectedFilters })
            .then((response) => {
                msg = response.data.response;
                if (msg !== mensaje) {
                    setDataClient(response.data.response)
                    setListFilterClient(response.data.response)
                    dataExcel(newSelectedFilters)
                    setpaginatcionListe({
                        currentPage: response.data.currentPage,
                        total: response.data.total,
                        totalPages: response.data.totalPages,
                    });
                } else {
                    searchInputClient && showSuccessPopup("Il cliente non esiste.", "Errore.")
                }


            })
            .catch((error) => console.log(error));


    }

    const getUserDipendente = async () => {

        let mensaje = "Sequence contains no elements";
        let msg: any;
        let newSelectedFilters = {
            genderFilter: [],
            startFilter: [],
            brandFilter: [],
            valoreSpesaFilter: [],
            etaFilter: [],
            hobbyFilter: [],
            bussinesFilter: [userInLogin.idBussines],
            dataInserimentoFilter: [],
            dataAggiornamentoFilter: [],
            depricatedBackListEnviaCatalogoFilter: [],
            referenzaFilter: '',
            professionFilter: '',
            noteFilter: '',
            page: 1,
            limit: 50,
            clientFilter: searchInputClient ? searchInputClient : ''
        };

        await axios(`${ConfigCapone.urlapi}/api/Client/SearchListClientFilterCrm`, { params: newSelectedFilters })
            .then((response) => {
                msg = response.data.response;
                if (msg !== mensaje) {
                    searchInputClient ? setListFilterClient(response.data.response) : setListFilterClient([])
                } else {
                    searchInputClient && showSuccessPopup("Il cliente non trovato per questo utente.", "Errore.")
                }


            })
            .catch((error) => console.log(error));
    }

    const getClientHobby = async () => {
        await axios(`${ConfigCapone.urlapi}/api/ClientHobby?parameter=1`)
            .then((response) => {
                setDataClientHobby(response.data.response)
            })
            .catch((error) => console.log(error));
    };

    const getClientProdposseduti = async () => {
        await axios(`${ConfigCapone.urlapi}/api/ClientProdposseduti?parameter=1`)
            .then((response) => {
                setDataClientProdposseduti(response.data.response)
            })
            .catch((error) => console.log(error));
    };

    const getMatricola = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Matricola/FindByFilterCrm`)
            .then((response) => {
                setDataMatricola(response.data.response)
            })
            .catch((error) => console.log(error));
    };

    const getReferenzia = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Referenzia?parameter=1`)
            .then((response) => {
                setDataReferenzia(response.data.response)
            })
            .catch((error) => console.log(error));
    };
    //DELETE CLIENT
    const confirmToDelete = async (idUser: number) => {
        setIdDeleteUser(idUser)
        setModal(true);
    }
    const confirmToDeleteTrue = async (idUser: number) => {
        await axios
            .delete(`${ConfigCapone.urlapi}/api/Client/${idUser}`, {
                headers: {
                    'Authorization': `Bearer ${cookies.get('token')}`
                }
            })
            .then((response) => {
                filterAllData()
            });

        setModal(false);
    }
    const onChangeClientText = async () => {
        if (searchInputClient.length > 0) {
            let value: any;

            if (isDipendente) {
                await axios
                    .get(`${ConfigCapone.urlapi}/api/Client/SearchByLimitClientBussines/${searchInputClient}/${userInLogin.idBussines}/10/`, {
                        headers: {
                            'Authorization': `Bearer ${cookies.get('token')}`
                        }
                    })
                    .then((response) => {
                        setListFilDipendenteF(response.data.response)
                        for (let i = 0; i < response.data.response.length; i++) {
                            if (response.data.response[i].name == searchInputClient) {
                                value = response.data.response[i].name
                            }
                        }
                    })
                    .catch((err) => console.log(err));
            }
            if (isDirettore) {
                await axios
                    .get(`${ConfigCapone.urlapi}/api/Client/${searchInputClient}/10/`, {
                        headers: {
                            'Authorization': `Bearer ${cookies.get('token')}`
                        }
                    })
                    .then((response) => {
                        setAutosuggestClientInput(response.data.response)
                        value = response.data.response[0].name
                        setListFilterDirectore(response.data.response)
                    })
                    .catch((err) => console.log(err));
            }
            if (!isDipendente && !isDirettore) {
                await axios
                    .get(`${ConfigCapone.urlapi}/api/Client/${searchInputClient}/10/`, {
                        headers: {
                            'Authorization': `Bearer ${cookies.get('token')}`
                        }
                    })
                    .then((response) => {
                        setAutosuggestClientInput(response.data.response)
                        value = response.data.response[0].name
                    })
                    .catch((err) => console.log(err));
            }
            if (searchInputClient === value && isDipendente) {
            }
            if (searchInputClient === value) {
                setAutosuggestClientInput([])
            }
        } else {
            setAutosuggestClientInput([]);
        }
    };
    const getItemText = (item: IClient) => {
        let phone = item.phone ? " - " + item.phone : "";
        let mainText = item.name + phone;
        return (
            <div>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ marginLeft: 10, flexShrink: 1 }}>
                        <Text style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}>{mainText}</Text>
                    </View>
                </View>
            </div>
        );
    };
    const getItemTextReferencia = (item) => {
        console.log(item)
        let mainText = item.referenza;
        return (
            <div>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ marginLeft: 10, flexShrink: 1 }}>
                        <Text style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}>{mainText}</Text>
                    </View>
                </View>
            </div>
        );
    };

    const DataSet = [
        {
            columns: [
                { title: "Cliente", style: { font: { sz: "18", bold: true } } },
                { title: "E-mail", style: { font: { sz: "18", bold: true } } },
                { title: "Telefono", style: { font: { sz: "18", bold: true } } },
                { title: "Indirizzo", style: { font: { sz: "18", bold: true } } },
                { title: "Nazione", style: { font: { sz: "18", bold: true } } },//addressStreet
            ],
            data: ListFilterClientExcel && ListFilterClientExcel?.map((data: any) => [
                { value: data.name ? data.name : "N/D", style: { font: { sz: "14", bold: false } } },
                { value: data.email ? data.email : "N/D", style: { font: { sz: "14", bold: false } } },
                { value: data.phone ? data.phone : "N/D", style: { font: { sz: "14", bold: false } } },
                { value: data.address ? data.address : "N/D", style: { font: { sz: "14", bold: false } } },
                { value: data.country ? data.country : "N/D", style: { font: { sz: "14", bold: false } } },
            ])
        }
    ]
    useEffect(() => {
        if (searchInputClient) {
            onChangeClientText()
        }
    }, [searchInputClient])

    // filters
    const genderFilter = () => {
        let filter: any = checkBoxGenderChecked;
        let dataFilter: any = [];
        let dataResultFilter: any = [];
        for (const item in filter) {
            for (let data in filter[item]) {
                dataFilter.push(filter[item][data])
            }
        }
        for (let index = 0; index < dataFilter.length; index++) {
            let id = dataFilter[index].split('-')
            dataResultFilter.push(id[1])
            index++
        }
        return dataResultFilter
    }

    const hobbyFilter = () => {

        let filter: any = checkBoxHobbyChecked;
        let dataFilter: any = [];
        let dataResultFilter: any = [];

        for (const item in filter) {
            for (let data in filter[item]) {
                dataFilter.push(filter[item][data])
            }
        }

        for (let index = 0; index < dataFilter.length; index++) {
            let id = dataFilter[index].split('-')
            dataResultFilter.push(id[1])
        }

        return dataResultFilter
    }

    const startFilter = () => {

        let filter: any = checkBoxStartChecked;

        let dataFilter: any = [];
        let dataResultFilter: any = [];

        let data = ListFilterClient;

        for (const item in filter) {
            for (let data in filter[item]) {
                dataFilter.push(filter[item][data])
            }
        }

        for (let index = 0; index < dataFilter.length; index++) {
            let id = dataFilter[index].split('-')
            dataResultFilter.push(id[1])
        }

        return dataResultFilter

    }
    const brandFilter = () => {

        let filter: any = checkBoxBrandChecked;

        let dataFilter: any = [];
        let dataResultFilter: any = [];

        for (const item in filter) {
            for (let data in filter[item]) {
                dataFilter.push(filter[item][data])
            }
        }
        for (let index = 0; index < dataFilter.length; index++) {
            let id = dataFilter[index].split('-')
            dataResultFilter.push(id[1])
            index++
        }
        return dataResultFilter
    }
    const spentValueFilter = () => {
        let filter: any = checkBoxSpentValueChecked;
        let dataFilter: any = [];
        let dataResultFilter: any = [];
        for (const item in filter) {
            for (let data in filter[item]) {
                dataFilter.push(filter[item][data])
            }
        }
        for (let index = 0; index < dataFilter.length; index++) {
            let id = dataFilter[index].split('-')
            dataResultFilter.push(id[1])
            index++
        }
        return dataResultFilter
    }

    const ageFilter = () => {
        let filter: any = checkBoxAgeChecked;
        let dataFilter: any = [];
        let dataResultFilter: any = [];
        let data = ListFilterClient;
        for (const item in filter) {
            for (let data in filter[item]) {
                dataFilter.push(filter[item][data])
            }
        }
        for (let index = 0; index < dataFilter.length; index++) {
            let id = dataFilter[index].split('-')
            dataResultFilter.push(id[1])
            index++
        }
        return dataResultFilter
    }
    const othersFilter = () => {
        let filter: any = checkBoxOthersChecked;
        let dataFilter: any = [];
        let dataResultFilter: any = [];
        let data = ListFilterClient;
        for (const item in filter) {
            for (let data in filter[item]) {
                dataFilter.push(filter[item][data])
            }
        }
        for (let index = 0; index < dataFilter.length; index++) {
            let id = dataFilter[index].split('-')
            dataResultFilter.push(id[1])
            index++
        }
        return dataResultFilter
    }
    const dateInsertFilter = () => {
        let filter: any = checkBoxDateInsertChecked;
        let dataFilter: any = [];
        let dataResultFilter: any = [];
        let data = ListFilterClient;
        for (const item in filter) {
            for (let data in filter[item]) {
                dataFilter.push(filter[item][data])
            }
        }
        for (let index = 0; index < dataFilter.length; index++) {
            let id = dataFilter[index].split('-')
            dataResultFilter.push(id[1])
            index++
        }
        return dataResultFilter
    }
    const dateUpdateFilter = () => {
        let filter: any = checkBoxDateUpdateChecked;
        let dataFilter: any = [];
        let dataResultFilter: any = [];
        let data = ListFilterClient;
        for (const item in filter) {
            for (let data in filter[item]) {
                dataFilter.push(filter[item][data])
            }
        }
        for (let index = 0; index < dataFilter.length; index++) {
            let id = dataFilter[index].split('-')
            dataResultFilter.push(id[1])
            index++
        }
        return dataResultFilter
    }
    const negozioFilter = () => {
        let filter: any = checkBoxNegozzioChecked;
        let dataFilter: any = [];
        let dataResultFilter: any = [];
        for (const item in filter) {
            for (let data in filter[item]) {
                dataFilter.push(filter[item][data])
            }
        }
        for (let index = 0; index < dataFilter.length; index++) {
            let id = dataFilter[index].split('-')
            dataResultFilter.push(id[1])
            index++
        }
        return dataResultFilter
    }
    const onChangeTextReferenza = async (name: string) => {
        if (name) {
            try {
                setInputReference(name)
                const response = await Promise.all([
                    referenciaSearch(name)
                ]);
                setActiveSuggestionReferenza(true)
                let referenza = response[0]
                if (referenza?.length > 0) {
                    let resultData = referenza.reduce((elements, obj: any, index) => {
                        let existingData = elements.find(
                            (element) => element.referenza === obj.referenza
                        );
                        if (!existingData) {
                            elements.push(obj);
                        }
                        return elements;
                    }, []);
                    setAutosuggestInputReferenza(resultData);
                }
            } catch (error) {
                console.log(error);
            } finally {
                //setLoading(false);
            }
        } else {
            setInputReference("")
            setAutosuggestInputReferenza([]);
        }
    };
    const onChangeInputReference = async (text: string) => {
        console.log('string',text)
        setInputReference(text)
    }
    const onChangeInputMatricola = async (text: string) => {
        setInputMatricola(text)
    }

    const handleSearchInputReference = () => {
        setAutosuggestInputReferenza([])
        setIsActiveInputReference(!isActiveinputReference);
    }
    const handleSearchInputMatricola = () => {
        setIsActiveInputMatricola(!isActiveinputMatricola);
    }

    const onChangeInputProfession = async (text: string) => {
        setInputProfession(text)
    }

    const handleSearchInputProfession = () => {
        setIsActiveInputProfession(!isActiveinputProfession);
    }

    const onChangeInputNote = async (text: string) => {
        setInputNote(text)
    }

    const handleSearchInputNote = () => {
        setIsActiveInputNote(!isActiveInputNote);
    }
    //Filter client CRM
    useEffect(() => {
        isDirettore && getClientDirectoreF();
    }, [paginaActual])
    useEffect(() => {

        filterAllData();

        setStateClient(false);

    }, [checkBoxGenderChecked, checkBoxStartChecked, checkBoxBrandChecked, checkBoxSpentValueChecked, checkBoxAgeChecked,
        checkBoxHobbyChecked, checkBoxNegozzioChecked, checkBoxDateInsertChecked, checkBoxDateUpdateChecked, checkBoxOthersChecked,
        isActiveinputReference, isActiveinputMatricola, isActiveinputProfession, isActiveInputNote, paginaActual])

    let active: boolean = false;

    const [selectedFilters, setSelectedFilters] = useState({
        genderFilter: [],
        startFilter: [],
        brandFilter: [],
        valoreSpesaFilter: [],
        etaFilter: [],
        hobbyFilter: [],
        bussinesFilter: [],
        dataInserimentoFilter: [],
        dataAggiornamentoFilter: [],
        depricatedBackListEnviaCatalogoFilter: [],
        referenzaFilter: '',
        professionFilter: '',
        noteFilter: '',
        page: searchInputClient ? 1 : paginaActual,
        limit: 50,
        clientFilter: ''
    });
    const dataExcel = async (selectedFilters: any) => {
        let mensaje = "Sequence contains no elements";
        let msg: any;
        selectedFilters.limit = 0
        await axios
            .get(`${ConfigCapone.urlapi}/api/Client/SearchListClientFilterCrm`, { params: selectedFilters })
            .then((response) => {
                msg = response.data.response
                if (msg !== mensaje) {
                    setListFilterClientExcel(response.data.response)
                } else {
                    setListFilterClientExcel([])
                }
            })
            .catch((err) => console.log(err));
        selectedFilters.limit = 50
    }
    const updatePage = (page, update = true) => {
        update && setReloadPage(true)
        setPaginaActual(page)
        return page
    }
    const filterAllData = async () => {
        console.log(reloadPage)
        setActiveSpinner(true)
        console.log(searchInputClient)
        let newSelectedFilters = {
            genderFilter: [],
            startFilter: [],
            brandFilter: [],
            valoreSpesaFilter: [],
            etaFilter: [],
            hobbyFilter: [],
            bussinesFilter: [],
            dataInserimentoFilter: [],
            dataAggiornamentoFilter: [],
            depricatedBackListEnviaCatalogoFilter: [],
            referenzaFilter: '',
            MatricolaFilter: '',
            professionFilter: '',
            noteFilter: '',
            page: reloadPage ? paginaActual : updatePage(1),
            limit: 50,
            clientFilter: searchInputClient ? searchInputClient : ''
        };

        let genderFilters = genderFilter();
        let starFilters = startFilter();
        let brandFilters = brandFilter();
        let spentValueFilters = spentValueFilter();
        let ageFilters = ageFilter();
        let hobbyFilters = hobbyFilter();
        let negozioFilters = negozioFilter();
        let dateInsert = dateInsertFilter();
        let dateUpdateCrmFilters = dateUpdateFilter();
        let othersFilters = othersFilter();

        console.log(genderFilters)

        for (let i = 0; i < genderFilters.length; i++) {
            newSelectedFilters.genderFilter.push(genderFilters[i])
            active = true
        }
        for (let i = 0; i < starFilters.length; i++) {
            newSelectedFilters.startFilter.push(starFilters[i])
            active = true
        }
        for (let i = 0; i < brandFilters.length; i++) {
            newSelectedFilters.brandFilter.push(brandFilters[i])
            active = true
        }
        for (let i = 0; i < spentValueFilters.length; i++) {
            newSelectedFilters.valoreSpesaFilter.push(spentValueFilters[i])
            active = true
        }
        for (let i = 0; i < ageFilters.length; i++) {
            newSelectedFilters.etaFilter.push(ageFilters[i])
            active = true
        }
        for (let i = 0; i < hobbyFilters.length; i++) {
            newSelectedFilters.hobbyFilter.push(hobbyFilters[i])
            active = true
        }
        for (let i = 0; i < negozioFilters.length; i++) {
            newSelectedFilters.bussinesFilter.push(negozioFilters[i])
            active = true
        }
        for (let i = 0; i < dateInsert.length; i++) {
            newSelectedFilters.dataInserimentoFilter.push(dateInsert[i])
            active = true
        }
        for (let i = 0; i < dateUpdateCrmFilters.length; i++) {
            newSelectedFilters.dataAggiornamentoFilter.push(dateUpdateCrmFilters[i])
            active = true
        }
        for (let i = 0; i < othersFilters.length; i++) {
            newSelectedFilters.depricatedBackListEnviaCatalogoFilter.push(othersFilters[i])
            active = true
        }

        if (inputReference?.length > 0) {
            newSelectedFilters.referenzaFilter = inputReference
            active = true
            sendLogg(` ricercato per Referenza ${inputReference} nel CRM - Cerca cliente`)

        }
        if (inputMatricola?.length > 0) {
            newSelectedFilters.MatricolaFilter = inputMatricola
            active = true
            sendLogg(` ricercato per Matricola ${inputMatricola} nel CRM - Cerca cliente`)

        }

        if (inputProfession?.length > 0) {
            newSelectedFilters.professionFilter = inputProfession
            active = true
            sendLogg(` ricercato per professione ${inputProfession} nel CRM - Cerca cliente`)
        }

        if (inputNote?.length > 0) {
            newSelectedFilters.noteFilter = inputNote
            active = true
            sendLogg(` ricercato per Note ${inputNote} nel CRM - Cerca cliente`)
        }

        setReloadPage(false)

        //if(active) newSelectedFilters.page = 1

        let mensaje = "Sequence contains no elements";
        let msg: any;
        
        if (!isDirettore && !isDipendente) {
            if (!stateClient) {
                await axios
                    .get(`${ConfigCapone.urlapi}/api/Client/SearchListClientFilterCrm`, { params: newSelectedFilters })
                    .then((response) => {
                        msg = response.data.response
                        if (msg !== mensaje) {
                            setListFilterClient(response.data.response)
                            dataExcel(newSelectedFilters)
                            setpaginatcionListe({
                                currentPage: response.data.currentPage,
                                total: response.data.total,
                                totalPages: response.data.totalPages,
                            });
                        }
                    })
                    .catch((err) => console.log(err));

                if (msg === mensaje) {

                    if (!searchInputClient) {
                        newSelectedFilters.page = 1;
                        setPaginaActual(1)
                        await axios
                            .get(`${ConfigCapone.urlapi}/api/Client/SearchListClientFilterCrm`, { params: newSelectedFilters })
                            .then((response) => {
                                msg = response.data.response
                                if (msg !== mensaje) {
                                    setListFilterClient(response.data.response)
                                    dataExcel(newSelectedFilters)
                                    msg = response.data.response
                                    setpaginatcionListe({
                                        currentPage: response.data.currentPage,
                                        total: response.data.total,
                                        totalPages: response.data.totalPages,
                                    });
                                } else {
                                    showSuccessPopup("Non c'è nessun record.", "Errore.")
                                }

                            })
                            .catch((err) => console.log(err));
                    } else {
                        searchInputClient && showSuccessPopup("Il cliente non esiste.", "Errore.")
                    }

                }
            }
        } else {
            isDirettore && await getClientDirectoreF()
            isDipendente && await getUserDipendente()
        }
        setSelectedFilters(newSelectedFilters);
        setActiveSpinner(false)
    }
    useEffect(() => {
        getGender();
        getBrand();
        getHobby();
        getBussines();
        getClient();
        getClientHobby();
        getClientProdposseduti();
        getMatricola();
        getReferenzia();
    }, [])

    return (
        <div className="square">
            <ScrollView style={styles.scroll}>
                {activeSpinner && <Loading activeSpinner={activeSpinner} />}
                <SafeAreaView style={[stylesRepair.areaView]}>
                    <View style={{ paddingHorizontal: 12 }}
                    >
                        <HeaderBar
                            hasRoundedIcon={false}
                            title="Pannello di controllo"
                            onBack={() => navigation.navigate("CRMHomeScreen")}
                        />
                    </View>
                    <View style={[stylesRepair.areaContent]}>
                        <div className="row px-2">
                            <div className="col-sm-0 p-0"></div>
                            <div className="col-md-7 pb-2 col-sm-7 p-0">
                                <div className="mt-2">
                                    <Text
                                        style={[stylesRepair.sectionTitle]}
                                    >
                                        Lista clienti
                                    </Text>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-5 p-0">
                                <SearchBox
                                    placeholder="Cerca cliente..."
                                    marginRight={true}
                                    value={searchInputClient}
                                    setValue={setSearchInputClient}
                                    handleSearchFilter={filterAllData}
                                // onChangeText={onChangeBrandText} 
                                />
                                {searchInputClient && autosuggestClientInput.length > 0 ? (
                                    <View style={styleSearch.styleSearchInput}>
                                        <FlatList
                                            data={autosuggestClientInput}
                                            showsVerticalScrollIndicator={false}
                                            renderItem={({ item, index }) => (
                                                <Pressable
                                                    style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }]}
                                                    onPress={() => {
                                                        setSearchInputClient(item.name)
                                                        // if(item.name?.length>0){
                                                        //     sendLogg(` cerco ${item.name} nel CRM - Cerca cliente`)
                                                        // }

                                                    }
                                                    }
                                                >
                                                    {getItemText(item)}
                                                </Pressable>
                                            )}
                                            keyExtractor={(item, index) => item.idClient + index}
                                        />
                                    </View>
                                ) : null}


                            </div>
                        </div>
                        {(isAdmin || isMarketing) &&
                            <div className="row mt-5">
                                <div className="col-5"></div>
                                <div className="col-7 d-flex justify-content-end">
                                    <TouchableOpacity
                                        onPress={() => navigation.navigate("LabelsScreen", selectedFilters)}
                                        style={{ marginRight: 10 }}
                                    >
                                        <View style={[styles.buttonWhite, { width: 170,  }]}  >
                                            <Text style={styles.buttonTextWhite}>
                                                {"Stampa etichette"}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>

                                    <ExcelFile
                                        filename={nameFileExport}
                                        element={<button type="button" onClick={() => sendLoggAll()} className="dowloandPdf" 
                                        style={{ width: 170, fontSize: 18, paddingTop: 5, paddingBottom: 5, borderWidth: 2, borderBlockColor: "black", fontWeight: "500", marginTop: -2 }}
                                        >Esporta lista</button>}
                                    >
                                        <ExcelSheet dataSet={DataSet} name="Lista clienti" />
                                    </ExcelFile>


                                </div>
                            </div>
                        }
                        <div className="row mt-1 px-2">
                            {(isAdmin || isMarketing) &&
                                <div className="col-12 col-sm-5 col-md-4 col-lg-2">
                                    <div className="row">
                                        <div className="d-flex px-0">
                                            <Image
                                                source={require('../../assets/icons-step-due/Icona-filtri.png')}
                                                style={{ marginTop: 3, width: 42, height: 42, marginLeft: -7, tintColor: "black", alignSelf: "center" }}
                                            />
                                            <h6 className="pt-2" style={{ marginTop: 2, fontSize: 20, marginLeft: 15, fontFamily: "Arsapia Regular font", color: "black" }}>Filtra per</h6>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuGenere")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">

                                                    <h6>Genere</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 10, marginTop: 4 }}

                                                    />

                                                </div>
                                            </div>
                                        </TouchableOpacity> 
                                        <Collapse isOpened={show.menuGenere}>
                                            <div className="px-0" style={{ marginLeft: -8 }}>
                                                {
                                                    dataGender.map((item, index) => {
                                                        return (
                                                            <div key={index} className="col-lg-12">
                                                                <CheckLabel
                                                                    name={item.gender}
                                                                    id={`gender-${item.idGender}`}
                                                                    type="text"
                                                                    text={item.gender}
                                                                    multiSelected={checkBoxGenderChecked[`gender-${item.idGender}`]}
                                                                    onChange={() => changeGender(item)}
                                                                />
                                                                <br />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuStelle")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Stelle</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 10, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuStelle}>
                                            <div className="px-0" style={{ marginLeft: -8 }}>
                                                {
                                                    filterStar.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                    <CheckLabel
                                                                        name={item.name}
                                                                        id={item.id}
                                                                        type={item.type}
                                                                        text={item.text}
                                                                        styleIcon={25}
                                                                        height={20}
                                                                        top={5}
                                                                        multiSelected={checkBoxStartChecked[item.id]}
                                                                        onChange={() => changeStart(item)}
                                                                        icon={require(`../../assets/icons-step-due/Icona-stella-2.png`)}
                                                                    />
                                                                    <Image
                                                                        style={[{
                                                                            top: 5,
                                                                            marginLeft: 2,
                                                                            width: 25,
                                                                            height: 20,
                                                                            position: 'relative'
                                                                        }]}
                                                                        source={item.id == "star-3" || item.id == "star-2" ? require(`../../assets/icons-step-due/Icona-stella-2.png`) : require(`../../assets/icons-step-due/Icona-stella1.png`)}
                                                                    />

                                                                    <Image
                                                                        style={[{
                                                                            top: 5,
                                                                            marginLeft: 2,
                                                                            width: 25,
                                                                            height: 20,
                                                                            position: 'relative'
                                                                        }]}
                                                                        source={item.id == "star-3" ? require(`../../assets/icons-step-due/Icona-stella-2.png`) : require(`../../assets/icons-step-due/Icona-stella1.png`)}
                                                                    />
                                                                    <br />
                                                                </View>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuBrand")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Brand acquistati</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 5, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuBrand}>
                                            <div className="px-0" style={{ marginLeft: -8 }} >
                                                {
                                                    dataBrand.map((item, index) => {
                                                        return (
                                                            <div key={index} className="col-lg-12">
                                                                <CheckLabel
                                                                    name={item.desc_Brand}
                                                                    id={`brand-${item.idBrand}`}
                                                                    type="text"
                                                                    text={item.desc_Brand}
                                                                    multiSelected={checkBoxBrandChecked[`brand-${item.idBrand}`]}
                                                                    onChange={() => changeBrand(item)}
                                                                />
                                                                <br />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuValore")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Valore della spesa</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 5, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuValore}>
                                            <div className="px-0" style={{ marginLeft: -8 }} >
                                                {
                                                    spentValue.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <CheckLabel
                                                                    name={item.name}
                                                                    id={item.id}
                                                                    type={item.type}
                                                                    text={item.text}
                                                                    multiSelected={checkBoxSpentValueChecked[item.id]}
                                                                    onChange={() => changeSpentValue(item)}
                                                                />
                                                                <br />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuEta")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Età</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 10, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuEta}>
                                            <div className="px-0" style={{ marginLeft: -8 }}>
                                                {
                                                    age.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <CheckLabel
                                                                    name={item.name}
                                                                    id={item.id}
                                                                    type={item.type}
                                                                    text={item.text}
                                                                    multiSelected={checkBoxAgeChecked[item.id]}
                                                                    onChange={() => changeAge(item)}
                                                                />
                                                                <br />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuInteressi")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Interessi</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 10, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuInteressi}>
                                            <div className="px-0" style={{ marginLeft: -8 }} >
                                                {
                                                    dataHobby.map((item, index) => {
                                                        return (
                                                            <div key={index} className="col-6 col-sm-3 col-md-2 col-lg-2 mb-3">
                                                                <CheckLabel
                                                                    name={item.hobby}
                                                                    id={`hobby-${item.idHobby}`}
                                                                    type="icon"
                                                                    icon={require(`../../assets/icons-step-due/${item.image}`)}
                                                                    text={item.hobby}
                                                                    multiSelected={checkBoxHobbyChecked[`hobby-${item.idHobby}`]}
                                                                    onChange={() => changeHobby(item)}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuNegozio")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Negozio di riferimento</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 10, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuNegozio}>
                                            <div className="px-0" style={{ marginLeft: -8 }}>
                                                {
                                                    dataBussines.map((item, index) => {
                                                        return (
                                                            <div key={index} className="col-12">
                                                                <CheckLabel
                                                                    name={item.desc_Bussines}
                                                                    id={`business-${item.idBussines}`}
                                                                    type="text"
                                                                    text={item.desc_Bussines}
                                                                    multiSelected={checkBoxNegozzioChecked[`business-${item.idBussines}`]}
                                                                    onChange={() => changeNegozio(item)}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuData")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Data di inserimento</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 10, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuData}>
                                            <div className="px-0" style={{ marginLeft: -8 }} >
                                                {
                                                    entryDate.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <CheckLabel
                                                                    name={item.name}
                                                                    id={item.id}
                                                                    type={item.type}
                                                                    text={item.text}
                                                                    multiSelected={checkBoxDateInsertChecked[item.id]}
                                                                    onChange={() => changeDateInsert(item)}
                                                                />
                                                                <br />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuAggiornamento")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Data di aggiornamento</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 10, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuAggiornamento}>
                                            <div className="px-0" style={{ marginLeft: -8 }} >
                                                {
                                                    updateDate.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <CheckLabel
                                                                    name={item.name}
                                                                    id={item.id}
                                                                    type={item.type}
                                                                    text={item.text}
                                                                    multiSelected={checkBoxDateUpdateChecked[item.id]}
                                                                    onChange={() => changeDateUpdate(item)}
                                                                />
                                                                <br />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuReferenza")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Referenza</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 10, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuReferenza}>
                                            <div className="px-0 d-flex col-12" style={{ marginLeft: -8 }}>
                                                
                                                <TextInput
                                                    style={show.menuReferenza ? [
                                                        stylesRepair.input,
                                                        stylesRepair.subSearch,
                                                    ] : { width: 0, height: 0 }}
                                                    onChangeText={onChangeTextReferenza}
                                                    value={inputReference}
                                                />
                                               
                                                <TouchableOpacity
                                                    onPress={() => handleSearchInputReference()}
                                                    style={{
                                                        margin: "auto"
                                                    }}
                                                >
                                                    <View style={show.menuReferenza ? [stylesRepair.viewDisable, stylesRepair.search] : { width: 0, height: 0 }}>
                                                        <Image
                                                            style={stylesRepair.subIconSearch}
                                                            source={require("../../assets/icons/icon-search.png")}
                                                        />
                                                    </View>
                                                </TouchableOpacity>
   
                                            </div>
                                            <div className='col-10' style={{position: 'relative', left : '-9px', width: '96%'}}>
                                            {activeSuggestionReferenza && autosuggestInputReferenza.length > 0 ? (
                                                    <View style={styleSearch.styleSearchInput}>
                                                        <FlatList
                                                            data={autosuggestInputReferenza}
                                                            showsVerticalScrollIndicator={false}
                                                            renderItem={({ item, index }) => (
                                                                <Pressable
                                                                    style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }]}
                                                                    onPress={() => {
                                                                        setInputReference(item.referenza),
                                                                        setAutosuggestInputReferenza([])
                                                                    }
                                                                    }
                                                                >
                                                                    {getItemTextReferencia(item)}
                                                                </Pressable>
                                                            )}
                                                            keyExtractor={(item, index) => item.idClientProdPossedut + index}
                                                        />
                                                    </View>
                                                ) : null}
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuMatricola")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Matricola</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 10, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuMatricola}>
                                            <div className="px-0 d-flex col-12" style={{ marginLeft: -8 }}>
                                                <TextInput
                                                    style={show.menuMatricola ? [
                                                        stylesRepair.input,
                                                        stylesRepair.subSearch,
                                                    ] : { width: 0, height: 0 }}
                                                    onChangeText={onChangeInputMatricola}
                                                    value={inputMatricola}
                                                />
                                                <TouchableOpacity
                                                    onPress={() => handleSearchInputMatricola()}
                                                    style={{
                                                        margin: "auto"
                                                    }}
                                                >
                                                    <View style={show.menuMatricola ? [stylesRepair.viewDisable, stylesRepair.search] : { width: 0, height: 0 }}>
                                                        <Image
                                                            style={stylesRepair.subIconSearch}
                                                            source={require("../../assets/icons/icon-search.png")}
                                                        />
                                                    </View>
                                                </TouchableOpacity>
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuProfessione")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Professione</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 10, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuProfessione}>
                                            <div className="px-0 d-flex col-12" style={{ marginLeft: -8 }}>
                                                <TextInput
                                                    style={show.menuProfessione ? [
                                                        stylesRepair.input,
                                                        stylesRepair.subSearch,
                                                    ] : { width: 0, height: 0 }}
                                                    onChangeText={onChangeInputProfession}
                                                    value={inputProfession}
                                                />
                                                <TouchableOpacity
                                                    onPress={() => handleSearchInputProfession()}
                                                    style={{
                                                        margin: "auto"
                                                    }}
                                                >
                                                    <View style={show.menuProfessione ? [stylesRepair.viewDisable, stylesRepair.search] : { width: 0, height: 0 }}>
                                                        <Image
                                                            style={stylesRepair.subIconSearch}
                                                            source={require("../../assets/icons/icon-search.png")}
                                                        />
                                                    </View>
                                                </TouchableOpacity>
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <TouchableOpacity activeOpacity={.5} onPress={() => handleShow("menuNote")}>
                                            <div className="row mt-4">
                                                <div className="d-flex px-0">
                                                    <h6>Note</h6>
                                                    <Image
                                                        source={require('../../assets/icons/arrow_down.png')}
                                                        style={{ width: 10, height: 10, marginLeft: 10, marginTop: 4 }}
                                                    />
                                                </div>
                                            </div>
                                        </TouchableOpacity>
                                        <Collapse isOpened={show.menuNote}>
                                            <div className="px-0 d-flex col-12" style={{ marginLeft: -8 }}>
                                                <TextInput
                                                    style={show.menuNote ? [
                                                        stylesRepair.input,
                                                        stylesRepair.subSearch,
                                                    ] : { width: 0, height: 0 }}
                                                    onChangeText={onChangeInputNote}
                                                    value={inputNote}
                                                />
                                                <TouchableOpacity
                                                    onPress={() => handleSearchInputNote()}
                                                    style={{
                                                        margin: "auto"
                                                    }}
                                                >
                                                    <View style={show.menuNote ? [stylesRepair.viewDisable, stylesRepair.search] : { width: 0, height: 0 }}>
                                                        <Image
                                                            style={stylesRepair.subIconSearch}
                                                            source={require("../../assets/icons/icon-search.png")}
                                                        />
                                                    </View>
                                                </TouchableOpacity>
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="px-0" style={{ marginLeft: -8 }}>
                                            {isAdmin?
                                                others.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <CheckLabel
                                                                name={item.name}
                                                                id={item.id}
                                                                type={item.type}
                                                                text={item.text}
                                                                multiSelected={checkBoxOthersChecked[item.id]}
                                                                onChange={() => changeOthers(item)}
                                                            />
                                                            <br />
                                                        </div>
                                                    )
                                                }):others2.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <CheckLabel
                                                                name={item.name}
                                                                id={item.id}
                                                                type={item.type}
                                                                text={item.text}
                                                                multiSelected={checkBoxOthersChecked[item.id]}
                                                                onChange={() => changeOthers(item)}
                                                            />
                                                            <br />
                                                        </div>
                                                    )
                                                })

                                            }
                                        </div>
                                    </div>

                                </div>
                            }

                            <div className={(isAdmin || isMarketing) ? "col-12 col-sm-7 col-md-8 col-lg-10" : "col-12 mt-3"} style={(isAdmin || isMarketing) ? { paddingLeft: 0, paddingRight: 0 } : { paddingLeft: 0, paddingRight: 0 }}>
                                {(ListFilterClient?.length > 0) &&

                                    <div className="table-responsive">
                                        <table className="table table-borderless mt-3">
                                            <thead style={{ backgroundColor: "black", color: "white" }}>
                                                <tr className="mr-4">
                                                    <th
                                                        style={{ borderTopLeftRadius: 50, borderBottomLeftRadius: 50 }}
                                                        scope="col"
                                                        className="align-self-center"
                                                    ></th>
                                                    {
                                                        headers.map((item, index) => {
                                                            return (
                                                                <th
                                                                    key={index}
                                                                    style={tableHeader}
                                                                    scope="col"
                                                                    className="align-self-center mr-4 px-4"
                                                                >
                                                                    {item}
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                    <th
                                                        style={{
                                                            borderTopRightRadius: 50,
                                                            borderBottomRightRadius: 50,
                                                        }}
                                                        scope="col"
                                                        className="align-self-center"
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {ListFilterClient && ListFilterClient?.map((data, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            style={{ borderBottom: "1px solid gray" }}
                                                        >
                                                            <td></td>
                                                            <td
                                                                style={tableRow}
                                                                className="align-self-center py-3"
                                                            >

                                                                <TouchableOpacity
                                                                    style={{
                                                                        flex: 1,
                                                                        paddingHorizontal: 5,
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                    }}
                                                                    onPress={() => navigation.navigate('ControlPanelScreen', { clientList: data.idClient })}
                                                                >
                                                                    <Image
                                                                        style={{ width: 30, height: 30 }}
                                                                        source={require("../../assets/icons/icon-note.png")}
                                                                    />
                                                                </TouchableOpacity>
                                                            </td>
                                                            <td
                                                                style={tableRow}
                                                                className="align-self-center py-3"
                                                            >
                                                                {data.name}
                                                            </td>
                                                            <td
                                                                style={tableRow}
                                                                className="align-self-center mr-4 px-4 py-3"
                                                            >
                                                                {data.email}
                                                            </td>
                                                            <td
                                                                style={tableRow}
                                                                className="align-self-center mr-4 px-4 py-3"
                                                            >
                                                                {data.phone}
                                                            </td>
                                                            <td
                                                                style={{ ...tableRow, fontSize: 12 }}
                                                                className="d-flex justify-content-center mr-4 px-4 py-4"
                                                            >
                                                                {userInLogin?.idRole === "2" ?
                                                                    <TouchableOpacity
                                                                        style={{
                                                                            flex: 1,
                                                                            paddingHorizontal: 5,
                                                                            alignItems: "center",
                                                                            justifyContent: "center"
                                                                        }}
                                                                        onPress={() => confirmToDelete(data.idClient)}
                                                                    >
                                                                        <Image
                                                                            style={{ width: 20, height: 20 }}
                                                                            source={require("../../assets/icons/icon-delete.png")}
                                                                        />
                                                                    </TouchableOpacity> : null


                                                                }
                                                                {
                                                                    data.email ?
                                                                    <TouchableOpacity
                                                                            style={{
                                                                                flex: 1,
                                                                                paddingHorizontal: 5,
                                                                                alignItems: "center",
                                                                                justifyContent: "center"
                                                                            }}
                                                                            onPress={() => emailModal(data)}
                                                                        >
                                                                            <Image
                                                                                style={{ width: 20, height: 20 }}
                                                                                source={require("../../assets/icons/mail.png")}
                                                                            />
                                                                    </TouchableOpacity>:
                                                                    <div style={{
                                                                        flex: 1,
                                                                        padding: "5",
                                                                        alignItems: "center",
                                                                        justifyContent: "center"
                                                                    }}>
                                                                    </div>

                                                                }
                                                               
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                {(!visiblePagination) &&
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={paginaActual}
                                        totalCount={paginationListe.total}
                                        pageSize={PageSize}
                                        onPageChange={page => updatePage(page)}
                                    />}
                            </div>
                        </div>

                    </View>
                </SafeAreaView>
                {modal && <PopupConfirmation
                    title={""}
                    message={"Sei sicuro di voler rimuovere il cliente?"}
                    confirmAction={() => confirmToDeleteTrue(idDeleteUser)}
                    stateModal={modal}
                    setStateModal={setModal}
                />}
                {modaleMessaggeWithUrl.active && (
                    <PopUpMessageWithRoute
                        routeNav={modaleMessaggeWithUrl.route}
                        dataNav={[]}
                        title={modaleMessaggeWithUrl.title}
                        message={modaleMessaggeWithUrl.message}
                        stateModal={modaleMessaggeWithUrl.active}
                        setStateModal={setmodaleMessaggeWithUrl}
                        confirmAction={confirmAction}
                    />
                )}
                {modalVisibleSendEmail && (
                <SendEmailCRMScreen
                    currentPage='state_repair'
                    modalVisible={modalVisibleSendEmail}
                    setModalVisible={setModalVisibleSendEmail}
                    clientData={dataClientSendEmail}
                />
            )}
            </ScrollView>
        </div>
    )
}

