import { FormikProvider } from 'formik'
import { useRepair } from '../../../hooks/formRepair/useRepair'
import { InputCustomStep } from '../InputCustomStep'
import { SelectCustomStep } from '../SelectCustomStep'
import { WrapperSteps } from './WrapperSteps'

export const Repair = () => {
  const { formik,optionsBrand } = useRepair()
  return (
    <FormikProvider value={formik}>
    <form onSubmit={formik.handleSubmit}>
      <WrapperSteps>
        <SelectCustomStep
          id="brandOfProduct"
          campo={'brandOfProduct'}
          label={'Orologio marcato *'}
          {...formik.getFieldProps('brandOfProduct')}
          options={optionsBrand}
        />
        <InputCustomStep
          id="referens"
          campo={'referens'}
          label={'Referenza'}
          placeholder={'Referenza'}
          {...formik.getFieldProps('referens')}
        />
        <InputCustomStep
          id="enrollment"
          campo={'enrollment'}
          placeholder={'Matricola'}
          label={'Matricola'}
          {...formik.getFieldProps('enrollment')}
        />
        <InputCustomStep
          id="envelopeNumber"
          campo={'envelopeNumber'}
          placeholder={'Numero Busta'}
          label={'Numero Busta'}
          {...formik.getFieldProps('envelopeNumber')}
        />
      </WrapperSteps>
    </form>
  </FormikProvider>
  )
}
