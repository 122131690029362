import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Pressable, TouchableOpacity } from "react-native";
import * as Print from "expo-print";
import { Text } from "react-native-elements";
import { UserContext } from "../context/UserContext";
import { ContextImagenPreventivo } from "../context/ContextImagenPreventivo";
import Cookies from 'universal-cookie';
import * as FileSystem from 'expo-file-system';
import html2pdf from 'html2pdf.js';
import { renderToStaticMarkup } from 'react-dom/server';
import PdfScreenStampaConvertFoto from "./PdfScreenStampaConvertFoto";
import ConfigCapone from "../utils/ConfigCapone";
import { SectionLoading } from "../components/SectionLoading";
import Loading from "../components/Loading";


export default function PdfScreenStampaFoto({ route, navigation }: any) {
  const [occult, setOccult] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const today = Date.now();
  const event = new Date(today);
  const optionsDate: any = { year: "numeric", month: "long", day: "numeric" };
  const { userInLogin } = useContext(UserContext);
  const [isRolexTudor, setIsRolexTudor] = useState<boolean>(
    userInLogin?.idRole === "8" ? true : false
  );
  let dateNow = event.toLocaleDateString("it-IT", optionsDate).toString();
  let information = route.params;
  console.log("information: ", information)
  let DatosEnviados = information[7];
  console.log("information: ", information[5])
  const [buttomHidden, setButtomHidden] = useState(false);
  const styles = {
    containerWhite: {
      backgroundColor: "white",
      fontWeight: "bold",
    },
    pWrite: {
      fontWeight: "bold",
      fontSize: "small",
    },
    input: {
      fontWeight: "bold",
      border: "solid 2px",
    },
    label: {
      fontWeight: "bold",
    },
    header: {
      fontSize: "small",
      fontWeight: "bold",
    },
    h5: {
      fontSize: "x-small",
      fontWeight: "bold",
    },
    fw400: {
      fontWeight: 400,
    },
    fw400Title: {
      fontWeight: 400,
      fontSize: '14px',
    },
    fw400Custom: {
      fontWeight: 400,
      fontSize: '14px',
    },
    fwBoldCustom: {
      fontSize: '14px',
    },
    divider: {
      borderTop: "1px solid black",
    },
    newPage: {
      '@media print': {
        pageBreakBefore: 'always',
      },
    },
  };

  let names: any = information[0].name.split(" ");
  let lenName: any = "";
  for (let i = 0; i < names.length; i++) {
    if (names[i] != "") {
      lenName = i;
      break;
    }
  }

  names = names.slice(lenName);

   const [activeSpinner, setActiveSpinner] = useState(false);
   const [selectedOption, setSelectedOption] = useState('');
   
  const [componentKey, setComponentKey] = useState(0);

   const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const generateAndDownloadPdf = async () => {
    setActiveSpinner(true)
    try {
      let htmlContent = renderToStaticMarkup(<PdfScreenStampaConvertFoto data={{...route.params, selectedOption: selectedOption ? selectedOption : ''  }} />);
      const options = {
        margin: 10,
        filename: 'pdf_document.pdf',
        image: { type: 'png', quality: 0.98 },
        html2canvas: {
          scale: 2,
          dpi: 300,
          letterRendering: true,
          useCORS: true
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      const pdfBlob = await html2pdf().from(htmlContent).set(options).outputPdf('blob');

      const arrayBuffer = await new Response(pdfBlob).arrayBuffer();
      const byteArray = new Uint8Array(arrayBuffer);

      const pdfBlobToSend = new Blob([byteArray], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlobToSend);

      setDownloadLink(pdfUrl);

      const downloadLinkElement = document.createElement('a');
      downloadLinkElement.href = pdfUrl;
      downloadLinkElement.download = 'pdf_document.pdf';
      document.body.appendChild(downloadLinkElement);
      downloadLinkElement.click();
      document.body.removeChild(downloadLinkElement);
    } catch (error) {
      console.error('Error al generar el PDF:', error);
      throw error;
    } finally {
      setComponentKey((prevKey) => prevKey + 1);
      setActiveSpinner(false);
    }
  };

  return (

    <div key={componentKey} className="container mt-1 " style={styles.containerWhite}>
       {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      <div className="row mt-1">
        <div className="col">
          <TouchableOpacity
            onPress={() => navigation.goBack()}
          >
            <img
              hidden={occult}
              src="https://i.postimg.cc/sXkYBB7R/icon-left.png"
              width="40"
              height="40"
              className="mr-4 pr-4"
              alt="Sergio Capone left "
            />
          </TouchableOpacity>
        </div>
        <div className="col"></div>
        <div className="col"></div>
      </div>

      <div className="row mt-1 pt-1">
        <div className="col">
          <h5 className="header" style={styles.header}>
            SERGIO CAPONE SRL
          </h5>
          <h6 className="header" style={styles.h5}>
            Centro Assistenza
          </h6>
          <h6 className="header" style={styles.h5}>
            Via Borgo Stretto 6, PISA{" "}
          </h6>
          <h6 className="header" style={styles.h5}>
            Tel: 0509711408{" "}
          </h6>
          <h6 className="header" style={styles.h5}>
            Email : assistenza@sergiocapone.com{" "}
          </h6>
        </div>
        <div className="col">
          <div className="row">
            <div className="col"></div>
            {
              (information[5].desc_Brand === "Rolex" || information[5].desc_Brand === "Tudor") &&
              <div className="col mr-4 pr-4">
                <img
                  src="http://195.231.22.91:3001/api-scapone/image/rlx_world_service.png"
                  width="175"
                  height="170"
                  style={{ position: 'relative', top: -20 }}
                  className="ml-4"
                  alt="Sergio Capone"
                />
              </div>
            }
          </div>
        </div>
      </div>

      <div className="row mt-1" style={{...styles.pWrite  }}>
      {(information[5].desc_Brand !== "Rolex" && information[5].desc_Brand !== "Tudor") &&
          <div className="mt-5"></div>
        }
        
        <div className="col-3">
          <p>PREVENTIVO N°: {information[6] || 0} </p>
          <p className="mt-3">COGNOME : </p>
          <p className="mt-3 pb-4"> NOME: </p>
          <p className="mt-3">OROLOGIO: </p>
          <p className="mt-3">REFERENZA: </p>
          <p className="mt-3">MATRICOLA: </p>
          <p className="mt-3">NUMERO BUSTA: </p>
          <p>QUADRANTE: </p>
          <p className="mb-3 pb-3">BRACCIALE: </p>
          <p className="mb-3 pb-3">Condizione al ricevimento : </p>
          <p className="">Lavori richesti : </p>
        </div>
        <div className="col-5">
          <p>Riferimento</p>
          <p className="mt-3">
            {" "}
            {names.toString().replace(/,/g, "").slice(names[0].length).length >
              0
              ? names.toString().replace(/,/g, " ").slice(names[0].length)
              : "-"}{" "}
          </p>
          <p className="mt-3 pb-4"> {names[0].length >= 1 ? names[0] : "-"}</p>
          <p className="mt-3">{""}</p>
          <p className="mt-3">
            {" "}
            {information[5] ? information[5].desc_Brand : "-"}
          </p>
          <p className="mt-3">
            {" "}
            {information[4].reference ? information[4].reference : "-"}
          </p>
          <p className="mt-3">
            {" "}
            {information[4].enrollment ? information[4].enrollment : "-"}
          </p>
          <p className="mt-3">
            {" "}
            {information[4].envelopeNumber
              ? information[4].envelopeNumber
              : "-"}
          </p>
          <p> {information[8] ? information[8] : "-"} </p>
          <p className="mb-3 pb-3">{information[9] ? information[9] : "-"}</p>

          <p className="mb-3 pb-3">
            {information[10] ? information[10] : "-"}{" "}
          </p>
          <p className="">
            {information[11]
              ? information[11].map((data, index) => (
                <ul className="list-unstyled" key={index}>
                  <li>
                    {data.job}: €{" "}
                    {data.price.toString().includes(",")
                      ? data.price
                      : Number(data.price).toFixed(2).replace(".", ",")}
                  </li>
                </ul>
              ))
              : "-"}{" "}
          </p>
        </div>

        <div className="col-4">
          <p>
            <span style={{ fontWeight: "bold" }}>
              {information[13] ? information[13] : "-"} ,
            </span>{" "}
            {dateNow}
          </p>
        </div>
      </div>

      <div className="row mt-2 pt-1  mb-4" style={styles.pWrite}>
        <div className="col"></div>
        <div className="col">
          {/* <p className="d-flex justify-content-center"><span>    {information[11] ? information[11] : ""}  </span></p> */}
        </div>

        <div className="col"></div>

        <div className="col mt-2">
          <p className="d-flex justify-content-end"></p>
          <p className="d-flex justify-content-end"></p>
          <p className="d-flex justify-content-end mb-0 mr-4 pr-4">
            Totale iva esclusa
          </p>
          <p className="d-flex justify-content-end mt-0 mr-4 pr-4">IVA 22%</p>
          <p className="d-flex justify-content-end mr-4 pr-4">
            Totale iva inclusa
          </p>
        </div>
        <div className="col">
          <p className="d-flex justify-content-start ml-4 pl-4">Prezzi</p>
          <p className="d-flex justify-content-start mb-1">
            <span>
              € {String(Number(information[1]).toFixed(2)).replace(".", ",")}
            </span>{" "}
          </p>
          <p className="d-flex justify-content-start ">
            <span>
              € {String(Number(information[3]).toFixed(2)).replace(".", ",")}
            </span>{" "}
          </p>
          <p className="d-flex justify-content-start ">
            <span>
              € {String(Number(information[2]).toFixed(2)).replace(".", ",")}
            </span>{" "}
          </p>
        </div>
      </div>

      <div className="row mt-3 pt-2" style={styles.pWrite}>
        <div className="col mr-3 ">
          <div className="d-flex justify-content-center">
            <div className="form-check ">
              <input
                name="accept"
                type="radio"
                 value="opcion1"
                className="form-check-input"
                 checked={selectedOption === 'opcion1'}
                onChange={handleOptionChange}
              />
              <label className="form-check-label ml-3"> ACCETTO</label>
            </div>
          </div>
        </div>
        <div className="col mr-4 pl-2">
          <div className="d-flex justify-content-start">
            <div className="form-check">
              <input
                name="accept"
                type="radio"
                value="opcion2"
                className="form-check-input"
                checked={selectedOption === 'opcion2'}
                onChange={handleOptionChange}
              />
              <label className="form-check-label ml-3">NON ACCETTO</label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row" style={styles.pWrite}>
        <div className="col">
          <p>Lavori consigliati (prezzi iva inclusa)</p>
          <div className="form-check ml-4 ">
            <label className="form-check-label">
              {information[12].length > 0
                ? information[12].map(
                  (data, index) =>
                    data.job != "" &&
                    data.job != undefined && (
                      <ul className="list-unstyled" key={index}>
                        <li>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`check-job-${index}`}
                          />
                          {data.job}: €{" "}
                          {data.price.toString().includes(",")
                            ? data.price
                            : Number(data.price).toFixed(2).replace(".", ",")}
                        </li>
                      </ul>
                    )
                )
                : ""}
            </label>
          </div>
        </div>
        <div className="col ml-4 mt-4">
          <p></p>
          <p className="ml-4">
            <span>€</span>{" "}
          </p>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 bd-highlight">
              <p>Firma</p>
            </div>
            <input
              style={{
                borderTopColor: "transparent",
                borderLeftColor: "transparent",
                borderRightColor: " transparent",
                backgroundColor: "white",
              }}
              disabled={true}
              type="password"
              className="d-flex flex-row bd-highlight mb-4 firm"
              id="exampleInputPassword1"
            />
          </div>
        </div>
      </div>

      <div className="row " style={styles.pWrite} >
        <div className="col"></div>
        <div className="col mr-4">
          <div className="printButton">
            <style>{`@media print {.printButton{display: none;}}`}</style>
            <Pressable
              style={{
                width: "40%",
                marginTop: 3,
                backgroundColor: "#09ABF7",
                alignItems: "center",
              }}
              onPress={generateAndDownloadPdf}
            >
              <Text style={{ color: "white" }}>Stampa PDF </Text>
            </Pressable>
          </div>
        </div>
      </div>

      <div className="row mt-5" style={styles.pWrite}>
        <div className="col-12">
          {DatosEnviados.imageArrayList ? (
            DatosEnviados.imageArrayList.map((image, index) => (
              <div key={index} className={`row mt-3 pt-2 ${index > 0 ? 'new-page' : ''} 
                 col-12 d-flex align-items-center justify-content-center`}>
                {!image.name.includes('.pdf') &&
                  <img
                    src={`${ConfigCapone.apicapone}/image/${image.name}`}
                    className="img-fluid"
                    alt="Image"
                    style={{
                      maxWidth: '50%', maxHeight: '50%', border: '1px solid black', marginTop: '160px',
                      marginBottom: '160px',
                    }}
                  />
                }
                <br />
                <hr />
              </div>
            ))
          ) : null}
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12">
          <img
            src="http://assistenza.sergiocapone.com/static/media/SergioCaponeLogo.5652d20a.jpg"
            width="150"
            alt="Sergi Capone"
          />
          <br />
          <br />
          <h6 style={styles.fw400Title}>
            SERGIO CAPONE SRL
            <br />
            Sede Legale: Via Borgo Stretto, 6 - 56127 Pisa (PI)
            <br />
            Tel: +39 050 9711408
            <br />
            Email: assistenza@sergiocapone.com
            <br />
            N. Preventivo : {information[6] || 0}
          </h6>
        </div>
        <div className="col-12 mt-3">
          <h6 className="text-center mb-2">
            <b>Informativa legale - Riparazione</b>
          </h6>
          <hr style={styles.divider} />
        </div>
        <div className="col-12 mt-2">
          <h6>
            <b style={styles.fwBoldCustom}>Servizio di Manutenzione</b>
          </h6>
          <h6 style={styles.fw400Custom}>
            Prestiamo particolare cura ed attenzione durante le manipolazioni e
            gli interventi eseguiti sugli orologi che ci vengono affidati.
            Tuttavia, un rischio di danneggiamento sussiste. In tal caso, Sergio
            Capone srl non mancherà di contattarVi immediatamente al fine di
            assumere una decisione condivisa sul seguito da dare al servizio.
            <br />
            Sergio Capone srl si riserva il diritto di interrompere un servizio
            dopo l'accettazione del preventivo in caso di constatazione di
            componenti modificati o contraffatti.
          </h6>
          <h6>
            <b style={styles.fwBoldCustom}>Prezzo dei componenti e pezzi sostituiti</b>
          </h6>
          <h6 style={styles.fw400Custom}>
            I prezzi dei componenti vengono calcolati "su base di scambio": i
            componenti sostituti non saranno restituiti.
          </h6>
          <h6>
            <b style={styles.fwBoldCustom}>Rapporto contrattuale</b>
          </h6>
          <h6 style={styles.fw400Custom}>
            Il rapporto contrattuale per l'esecuzione dell'intervento è
            instaurato tra il Cliente (Rivenditore Autorizzato) e Sergio Capone.
            Il Proprietario dell'orologio non è parte del rapporto contrattuale.
            Il Cliente garantisce, esonerando totalmente Sergio Capone srl da
            ogni responsabilità, di essere in possesso di ogni autorizzazione e
            potere necessario per agire per conto del proprietario
            dell'orologio.
          </h6>
          <h6>
            <b style={styles.fwBoldCustom}>Validità del preventivo</b>
          </h6>
          <h6 style={styles.fw400Custom}>
            Il presente preventivo ha una validità di 30 giorni a partire dalla
            data di emissione.
          </h6>
          <h6>
            <b style={styles.fwBoldCustom}>Condizioni di pagamento</b>
          </h6>
          <h6 style={styles.fw400Custom}>
            La fattura dovrà essere regolata secondo le condizioni economiche in
            corso.
          </h6>
          <h6>
            <b style={styles.fwBoldCustom}>Termini di consegna</b>
          </h6>
          <h6 style={styles.fw400Custom}>
            Il termine di consegna indicato sul preventivo non è garantito. Se
            tuttavia il Servizio Assistenza di Sergio Capone srl dovesse
            prevedere una proroga supplementare durante il servizio, il Cliente
            ne verrà prontamente informato.
          </h6>
          <h6>
            <b style={styles.fwBoldCustom}>Garanzia</b>
          </h6>
          <h6 style={styles.fw400Custom}>
            Salvo esclusioni di garanzia precisate per iscritto sul preventivo,
            i lavori e le componenti sono garantite 24 mesi dalla fine dei
            lavori. La garanzia copre la sostituzione dei componenti e la mano
            d'opera. La garanzia non copre eventuali danni o deterioramenti
            risultanti da incidenti o uso inappropriato dell'orologio e da
            normale usura dei suoi componenti. Tutti gli interventi effettuati
            da terzi non autorizzati da Sergio Capone srl o l'aggiunta di pezzi
            o accessori non fabbricati da Rolex SA fanno decadere la garanzia.
          </h6>
          <h6>
            <b style={styles.fwBoldCustom}>Legge applicabile e Foro competente</b>
          </h6>
          <h6 style={styles.fw400Custom}>
            Il presente rapporto contrattuale è regolato dalla legge italiana.
            Per ogni controversia derivante dalla predisposizione del preventivo
            e dai lavori di riparazione sarà competente in via esclusiva il
            Tribunale di Milano.
          </h6>
          <h6>
            <b style={styles.fwBoldCustom}>Note</b>
          </h6>
          <h6 style={styles.fw400Custom}>
            Il preventivo è stato formulato per la riparazione di un orologio e
            non ha valore di certificato di autenticità. Sergio Capone declina
            tutte le responsabilità in caso di uso improprio del preventivo.
          </h6>
        </div>
        <div className="col-12 mt-3 text-center">
          <h6 style={styles.fw400Custom}>
            Pisa - Viareggio - Livorno
            <br />
            <br />
            Sergio Capone srl - P.IVA 01164520502 - C.S. € 50.000,00
          </h6>
        </div>
      </div>

    </div>
  );
}
