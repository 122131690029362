import { Image, SafeAreaView, ScrollView, StatusBar, StyleSheet, Text, TouchableOpacity, View, FlatList, Pressable, TextInput } from 'react-native'
export const stylesRepair = StyleSheet.create({
  areaView: {
      flex: 1,
      paddingTop: StatusBar.currentHeight,
      backgroundColor: "white",
  },
  areaContent: {
      flex: 1,
      paddingHorizontal: 30,
      paddingVertical: 20,
  },
  sectionTitle: {
      fontWeight: "500",
      fontSize: 20,
      alignSelf: "center",
      marginRight: "1%",
  },
  sectionTitle2: {
      fontWeight: "500",
      fontSize: 20,
      color: "#939393",
      alignSelf: "center",
  },
  arrowDown: {
      width: 20,
      height: 20,
      marginTop: 18,
      tintColor: "#808080",
      alignSelf: "center",
  },
  subSearch: {
      alignSelf: "flex-end",
      borderColor: "#A4A4A4",
      paddingRight: 2,
      paddingLeft: 15,
      fontSize: 20,
  },
  input: {
      height: 45,
      width: "100%",
      borderWidth: 2,
      fontFamily: "Arsapia Regular font",
      padding: 6,
      borderRadius: 50,
      marginRight: "1%",
  },
  viewDisable: {
      backgroundColor: "black",
      borderRadius: 50,
      justifyContent: "center",
      width: 40,
      height: 40,
      marginTop: "2%",
  },
  subIconSearch: {
      width: 20,
      height: 20,
      tintColor: "white",
      alignSelf: "center",
  },
  search: {
      marginLeft: "-100%"
  },
  imageWithMargin: {
      width: 15,
      marginLeft: 30,
      height: 15,
      tintColor: "rgb(200,200,200)",
  },
});


export const selectStyle = {
    styleSelectInteressi: { 
    width: "100%",
    height: 45,
    margin: 0,
    borderWidth: 2,
    borderRadius: 50,
    borderColor: "#A4A4A4",
    paddingLeft: 20,
    fontFamily: "Arsapia Regular font",},
    styleSelectOptionFilter: { 
        width: "100%",
        height: 45,
        margin: 0,
        borderWidth: 0,
        borderRadius: 50,
        borderColor: "#A4A4A4",
        paddingLeft: 20,
        fontWeight: "500",
        fontSize: 20,
        alignSelf: "center",
        marginRight: "1%", }
}

export const styleSearchDetailsListe={
    styleDetailSearch:{borderWidth: 1,
        marginTop: -15,
        paddingTop: 15,
        marginLeft: 2,
        marginRight: 35,
        borderTopWidth: 0,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,}
}
