import { tableHeader } from "../../GeneralStyles/Statistiche";

export const TableStatischeHeader = () => {
  return (
    <thead style={{ backgroundColor: "black", color: "white" }}>
      <tr className="mr-4">
        <th
          style={{
            borderTopLeftRadius: 50,
            borderBottomLeftRadius: 50,
            textAlign: "center",
            fontWeight: "400",
            verticalAlign: "middle",
          }}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Direttore/Dipendente
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Negozio
        </th>
        <th
          style={{
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
            textAlign: "center",
            fontWeight: "400",
            verticalAlign: "middle",
          }}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Prodotti venduti
        </th>
      </tr>
    </thead>
  );
};
