import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../context/UserContext";
import axios from "axios";
import ConfigCapone from "../../../../../utils/ConfigCapone";
import { SearchClientAtelier } from "../../services/magazzinoAtelierService";
import { IClient } from "../../../../../interfaces/IClient";
import Cookies from "universal-cookie";
import { enOptonNavigate } from "../../../../../schemas/Schemas";

export const useMagazzinoAtelierModal = ({ navigation, setModalVisible, listMagazzino, dataMagazzino, goToSchedaProdotto, goToCrm , dataMatricola, getDataMagazzino}: any) => {

    const { userInLogin } = useContext(UserContext);
    const [modalVisibleCardSell, setModalVisibleCardSell] = useState(false);
    const [activeSpinner, setActiveSpinner] = useState(false);
    const [modalSpostamentoNegozioVisibleCard, setModalSpostamentoNegozioVisibleCard] = useState(false);
    const [modalOptionClienteEsistente, setModalOptionClienteEsistente] = useState(false);
    const [activeBottom, setActiveBottom] = useState<boolean>(true);
    const [search, setSearch] = useState<string>();
    const [checked, setCheckBoxChecked] = useState("");
    const [typeMatricola, setTypeMatricola] = useState("");
    const [matricola, setMatricola] = useState("");
    const [idMatricola, setIdMatricola] = useState("");
    const [idBussinessSeller, setIdBussinessSeller] = useState("");
    const [idR, setIdR] = useState<any>();
    const [searchModalInputClient, setSearchModalInputClient] = useState<string>();
    const [imageArrayList, setImageArrayList] = useState<any>([]);
    const [modaleMessaggeInfo, setmodaleMessaggeInfo] = useState({
        title: "Informazione",
        Misura: "",
        Colore: "",
        Movimento: "",
        active: false,
    });

    const [
        autosuggestModalClientEsistenteInput,
        setAutosuggestModalClientEsistenteInput,
    ] = useState<IClient[]>([]);

    let existsIncrement: any;

    const goToEdit = async (dataMagazzino: any, dataMatricola: any) => {
        setModalVisible(false);
        await navigation.navigate("MagazzinoAtelierFormEdit", { dataMagazzino, dataMatricola });
    }

    const handleSearchModalFilter = async () => {
        if (searchModalInputClient) {
        if (searchModalInputClient.length > 0) {
            try {
            const response = await Promise.all([
                SearchClientAtelier(searchModalInputClient)
            ]);
            setAutosuggestModalClientEsistenteInput(response[0]);
            } catch (error) {
            console.log(error);
            } finally {
            // setLoading(false);
            }
        }
        }
    };

    const changeListMatricola = (type: string, value: any, id: any, matricola?: any) => {
        console.log(value)
        setCheckBoxChecked(value);
        setTypeMatricola(type);
        setIdMatricola(id);

        let checkMagazzino: any = document.getElementsByClassName('checksMagazzino');
        let checkDobles: any = document.getElementsByClassName('checkDoble');
        let countOne = 0;

        //check for only one
        for (let i = 0; i < checkMagazzino.length; i++) {
            if (checkMagazzino[i].checked) {
                countOne++;
            }
        }

        if (matricola) {
            setMatricola(matricola)
        }

        if (value) {
            if (checked == value) {
                setCheckBoxChecked(null)
            }
        }

    }

    const changeListOnline = (type: string, value: any, id: any, matricola?: any) => {

        let checkDobles: any = document.getElementsByClassName('checkDoble');
        let checkMagazzino: any = document.getElementsByClassName('checksMagazzino');

        //check for double
        let count = 0;

        for (let i = 0; i < checkDobles.length; i++) {
            if (checkDobles[i].checked) {
                count++;
            }
        }
        setCheckBoxChecked(value);
        setTypeMatricola(type);
        setIdMatricola(id);

        if (matricola) {
            setMatricola(matricola)
        }

        if (value) {
            if (checked == value) {
                setCheckBoxChecked(null)
            }
        }

    }

    const valueOldReference: any = (id: any) => {
        setIdR(id)
        existsIncrement = id
        return id
    }

    useEffect(() => {
        valueOldReference(idR)
    }, [idR])


    const updateStateMatricole = async (id: any, value: any, type: any, idClient: any) => {

        await axios.put(`${ConfigCapone.urlapi}/api/Matricola/UpdateOptions/${id}/${value}/${type}/${idClient}`)
            .then((response) => {
                console.log(response);
            })

        if (type == "PutMoveBusiness" || type == "PutSale" || type == "PutSaleNewCustomer" || type == "PutSaleExistingCustomer") {

            if (type == "PutSale" || type == "PutSaleExistingCustomer") {
                console.log("vender producto");
                let online: any = false;
                let brand: any;
                let mat: any;
                await axios
                    .get(`${ConfigCapone.urlapi}/api/Matricola/${id}`)
                    .then((response) => {
                        online = response.data.response?.putOnline;
                        brand = response.data.response?.marca;
                        mat = response.data.response?.matricola;
                    })
                    .catch((error) => {
                        console.log("error -> ", error);

                    });

                if (online) {
                    sendEmail("Nuova Vendita", id)
                }

            }

            if (existsIncrement) {

                let data: any = listMagazzino.find((data: any) => data.idReference === existsIncrement)

                let quantityDecrement = dataMagazzino?.quantity ? dataMagazzino?.quantity - 1 : 0;
                let quantityIncrement = parseInt(data?.quantity) + 1;

                await axios.put(`${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${dataMagazzino.idMagazzino}/${quantityDecrement}/0`)
                    .then((response) => {
                        console.log("data:", response);
                    })
                await axios.put(`${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${data.idMagazzino}/${quantityIncrement}/0`)
                    .then((response) => {
                        getDataMagazzino();
                        dataMagazzino.quantity = quantityDecrement;
                        // goToSchedaProdotto(dataMagazzino);
                    })
            } else {
                let quantity = dataMagazzino?.quantity ? dataMagazzino?.quantity - 1 : 0;
                await axios.put(`${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${dataMagazzino.idMagazzino}/${quantity}/0`)
                    .then((response) => {
                        getDataMagazzino();
                        dataMagazzino.quantity = quantity;
                        // goToSchedaProdotto(dataMagazzino);
                    })
            }
        } else {
            await goToSchedaProdotto();
        }
    }

    const navegationMatricola = async (option: number, value: any, id: any) => {

        let matricola: any;

        setModalVisible(false)

        await axios
        .get(`${ConfigCapone.urlapi}/api/Matricola/${idMatricola}`)
        .then((response) => {
            if (response.data.status != "empty") {
            matricola = response.data.response.matricola
            }
        })
        .catch((error) => {
            console.log("error");
        })


        let magazzino = { ...dataMagazzino, matricola: id + "-" + matricola }

        // console.log("magazzino : ", magazzino);

        setCheckBoxChecked(value);

        if (value) {
            if (checked == value) {
                setCheckBoxChecked(null)
            }
            if (option) {
                goToCrm(magazzino, option,enOptonNavigate.clearForm)
            }
        }

    }

    useEffect(() => {
        setIdBussinessSeller(dataMagazzino)
        switch (typeMatricola) {
            case 'PutMoveBusiness':
                setModalSpostamentoNegozioVisibleCard(checked ? true : false);
                break;
            case 'PutAside':
                if (checked) {
                    sendEmail("Messo da parte", idMatricola)   
                    dataMatricola.find((data: any) => data.idMatricola === idMatricola).putAside = true;
                    dataMagazzino.putAsideCount = dataMatricola.filter((data: any) => data.putAside === true).length;
                    
                } else {
                    sendEmail("Rimosso da: Messo da parte", idMatricola, true)
                    dataMatricola.find((data: any) => data.idMatricola === idMatricola).putAside = false;
                    dataMagazzino.putAsideCount = dataMatricola.filter((data: any) => data.putAside === true).length;
                }
                updateStateMatricole(idMatricola, checked ? true : false, typeMatricola, 0);
                break;
            case 'PutSale':
                setModalVisibleCardSell(checked ? true : false);
                break;
            case 'PutSaleNewCustomer':
                navegationMatricola(3, checked ? true : false, idMatricola)
                break;
            case 'PutSaleExistingCustomer':
                setModalOptionClienteEsistente(checked ? true : false);
                break;
            case 'PutOnline':
                updateStateMatricole(idMatricola, checked ? true : false, typeMatricola, 0);
                if (!checked) {
                    sendEmail("Rimosso online", idMatricola)
                } else {
                    sendEmail("Messo online", idMatricola)
                }
                break;
        }
    }, [checked]);

    const sendEmail = async (type: string, id: any, product?: any) => {
        let mat: any;
        let brand: any;
        let date = dateTimeNow();
        await axios
            .get(`${ConfigCapone.urlapi}/api/Matricola/${id}`)
            .then((response) => {
                mat = response.data.response?.matricola;
                brand = response.data.response?.marca;
                console.log("online: ", response.data.response);
            })
            .catch((error) => {
                console.log("error -> ", error);

            });

        let emailData = {
            type: "notice",
            email: `${ConfigCapone.emailMarketing}`,
            subject: `${type}`,
            message: `<p> Operazione: ${type}${product ? `,  Il prodotto ${dataMagazzino?.referencia} non è stato acquistato dal cliente` : ''}</p>
                 <p> Operatore: ${userInLogin.userName}</p>
                 <p> Referenza: ${dataMagazzino?.referencia} </p>
                 <p> Brand: ${brand}</p>
                 <p> Descrizione: ${dataMagazzino?.desc_famiglia ? dataMagazzino?.desc_famiglia : 'Nessun dato'} </p>
                 <p> Negozio: ${dataMagazzino?.desc_bussines} </p>
                 <p> Matricola: ${mat}</p>
                 <p> Prezzo: ${dataMagazzino?.prezzo}</p>
                 <p> Data: ${date}</p>`,
        };

        await axios
            .post(`${ConfigCapone.apicapone}/api/forma`, emailData)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => console.log("there was an error: " + err));
    }

    const loadFiles = (files) => {
        if(files){
          if(files.startsWith("[")){
            const jsonFiles = JSON.parse(files);
            setImageArrayList(jsonFiles)
          }else{
            let  filesName = [];
            const finishIndex = files.lastIndexOf(".");
            const extension = files.slice(finishIndex + 1);
    
            let currentFile = ""
    
            if(extension.toLowerCase() == 'png' || extension.toLowerCase() == 'gif' || extension.toLowerCase() == 'jfif' 
               || extension.toLowerCase() == 'pjp' || extension.toLowerCase() == 'jpg' || extension.toLowerCase() == 'pjpeg' || extension.toLowerCase() == 'jpeg'){
              currentFile = 'image'
            }else if (extension.toLowerCase() == 'pdf'){
              currentFile = 'application'
            }else{
              currentFile = 'application'
            }
            filesName.push({ id: 1, name: files, type: currentFile + '/' + extension });
            setImageArrayList(filesName)
          }
        }
    }

    const dateTimeNow = (fecha: string = '') => {
        let date
        if (fecha !== '') {
            date = new Date(fecha)
        } else {
            date = new Date()
        }
        const year = date.getFullYear()
        const month =
            date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1
        const day = date.getDate().toString().padStart(2, "0")
        const hour = date.getHours().toString().padStart(2, "0")
        const minute = date.getMinutes().toString().padStart(2, "0")
        const seconds = date.getSeconds().toString().padStart(2, "0")
        const dateNow =
            day +
            '/' +
            month +
            '/' +
            year +
            ', ore ' +
            hour +
            ':' +
            minute +
            ':' +
            seconds

        return dateNow
    }

    useEffect(() => {
       if(dataMagazzino && dataMagazzino.fileName){
         loadFiles(dataMagazzino.fileName)
       }
    }, [dataMagazzino]);

    return {
        activeSpinner,
        changeListOnline,
        checked,
        changeListMatricola,
        goToEdit,
        idMatricola,
        idBussinessSeller,
        typeMatricola,
        updateStateMatricole,
        modalOptionClienteEsistente, 
        setModalOptionClienteEsistente,
        autosuggestModalClientEsistenteInput,
        setAutosuggestModalClientEsistenteInput,
        searchModalInputClient, 
        setSearchModalInputClient,
        handleSearchModalFilter,
        modalVisibleCardSell, 
        setModalVisibleCardSell,
        modalSpostamentoNegozioVisibleCard,
        setModalSpostamentoNegozioVisibleCard,
        imageArrayList
    }

};