import aplicationConnect from "../../../../api/applicationConnect";
import { ResponseBackendClient } from "../../../../interfaces/magazzino";
import { MagazzinoAtelier, ResponseBackend, ResponseBackendBrand, ResponseBackendFamiglia, ResponseBackendMagazzinoAtelier, ResponseBackendQuantityMagazzino } from "../interfaces/IMagazzinoAtelier";

export const listAllBrand = async () => {
    try {
      const results = await aplicationConnect.get<ResponseBackendBrand>(
        "/Brand/GetBranchByVisible/atelier"
      );
      const { response, status } = results.data;
      if (status === "success") return response;
      return [];
    } catch (error) {}
  };
export const listAllFamiglia = async () => {
    try {
      const results = await aplicationConnect.get<ResponseBackendFamiglia>(
        "/FamigliaBrand?parameter=1"
      );
      const { response, status } = results.data;
      if (status === "success") return response;
      return [];
    } catch (error) {}
  };
  export const searchReferenzaByLimit = async (searchValue:string) => {
    try {
      const results = await aplicationConnect.get<ResponseBackend>(
        `/Referenzia/FindAtelierByLimit/${searchValue}/${5}`
      );
      const { response, status } = results.data;
      if (status === "success") return response;
      return [];
    } catch (error) {}
  };
export const postReferenza = async (data) => {
    try {
      const results = await aplicationConnect.post(
        "/Referenzia/Atelier",
        data
      );
      const { status,response,id } = results.data;
      if (status === "success") return id;
      return false;
    } catch (error) {}
}
export const putReferenza = async (data) => {

  try {
    const results = await aplicationConnect.put(
      `/Referenzia/Atelier/${data.idReference}`,
      data
    );
    const { status,response,id } = results.data;
    if (status === "success") return id;
    return false;
  } catch (error) {}
}
export const postMatricola = async (data) => {
    try {
      const results = await aplicationConnect.post(
        "/Matricola",
        data
      );
      const { status,response,id } = results.data;
      if (status === "success") return id;
      return false;
    } catch (error) {}
}
export const postMagazzino = async (data) => {
  try {
    const results = await aplicationConnect.post(
      "/Magazzino",
      data
    );
    const { status,response,id } = results.data;
    if (status === "success") return id;
    return false;
  } catch (error) {}
}
export const putMagazzinoAtelierQuantity = async (idMagazzino:string | number,quantity:number) => {
  try {
    const results = await aplicationConnect.put(
      `/Magazzino/ModifyQuantity/${idMagazzino}/${quantity}/${9}`
    );
    const { status,response,id } = results.data;
    if (status === "success") return id;
    return false;
  } catch (error) {}
}
export const magazzinoAtelierByIdReference = async (id:string | number) : Promise<MagazzinoAtelier | null>  => {
  try {
    const results = await aplicationConnect.get<ResponseBackendQuantityMagazzino>(
      `/Magazzino/ByIdReference/${id}`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
    return null;
  } catch (error) {}
};

export const putNameMatricolaAtelier = async (idMatricola: number,name:string) => {
  try {
    const results = await aplicationConnect.put(
      `/Matricola/PutNameMatricola/${idMatricola}/${name}`
    );
    const { status,response,id } = results.data;
    if (status === "success") return id;
    return false;
  } catch (error) {}
}

export const deleteMatricolaAtelier = async (idMatricola: number) => {
  try {
    const results = await aplicationConnect.delete(
      `/Matricola/${idMatricola}`
    );
    const { status,response,id } = results.data;
    if (status === "success") return id;
    return false;
  } catch (error) {}
}


// List

export const listAllMagazzinoAtelier = async (page: number, limit = 50) => {
  try {
    const results = await aplicationConnect.get<ResponseBackendMagazzinoAtelier>(
      "/Magazzino/SearchListMagazzinoAtelierFilter", {
        params: {
          page: page,
          limit: limit,
          brandFilter: [],
          quantityFilter: ['1'],
          referenza: '',
          prodotto: ''
        }
      }
    );
    const { response, status, total, currentPage, totalPages } = results.data;
    let newData = {
      response,
      status,
      total,
      currentPage,
      totalPages
    }
    if (status === "success") return newData;
    return [];
  } catch (error) {
    console.log(error);
  }
};

interface SearchMagazzinoAtelier {
  filterBrand: number[];
  price: string;
  descrizione: string;
  referenza: string;
  year: string,
  matricole: string,
  diameter: string,
  collection: string,
  house: string,
  glass: string,
  estate: string,
  movement: string,
  caliber: string,
  powerReserve: string,
  bracelet: string,
  color: string,
  misura: string,
  fileName: string,
  box: number | null,
  guarantees: number | null,
  prodotto:string;
  page?: number;
  limit?: number;
  filterQuantita?: number[];
}
export const listAllMagazzinoAtelierWhitSearch = async ({
  filterBrand,
  filterQuantita,
  price,
  descrizione,
  referenza,
  year,
  matricole,
  diameter,
  collection,
  house,
  glass,
  estate,
  movement,
  caliber,
  powerReserve,
  bracelet,
  color,
  misura,
  fileName,
  box,
  guarantees,
  prodotto,
  limit,
  page,
}: SearchMagazzinoAtelier) => {
  try {
    const results = await aplicationConnect.get<ResponseBackendMagazzinoAtelier>(
      "/Magazzino/SearchListMagazzinoAtelierFilter",
      {
        params: {
          limit,
          page,
          brandFilter: filterBrand,
          quantityFilter: filterQuantita,
          price: price,
          year: year,
          matricole: matricole,
          diameter: diameter,
          collection: collection,
          house: house,
          glass: glass,
          estate: estate,
          movement: movement,
          caliber: caliber,
          powerReserve: powerReserve,
          bracelet: bracelet,
          color: color,
          misura: misura,
          fileName: fileName,
          box: box,
          guarantees: guarantees,
          descrizione: descrizione,
          referenza: referenza,
          prodotto:prodotto
        },
      }
    );
    const { response, status, total, currentPage, totalPages } = results.data;
    let newData = {
      response,
      status,
      total,
      currentPage,
      totalPages
    }
    if (status === "success") return newData;
    return [];
  } catch (error) {
    console.log(error);
  }
};

export const SearchMagazzinoAtelierProdottoSuggestions = async (name:string) => {
  try {
    const results = await aplicationConnect.get<ResponseBackendMagazzinoAtelier>(
      `/Magazzino/SearchListMagazzinoAtelierFilter?limit=10&prodotto=${name}`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
    return []
  } catch (error) {}
}

export const SearchClientAtelier = async(searchModalInputClient)=>{
  try {
    const results = await aplicationConnect.get<ResponseBackendClient>(
      `/Client/FindAtelierByLimit/${searchModalInputClient}/10`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
  } catch (error) {}
}