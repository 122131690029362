export const headers = [
  '',
  'Cliente',
  'Stelle',
  'Data iscrizione',
  'Telefono',
  'Note',
  '',
  'Totale spesa',
  'N acquisti effettuati'
];