import { useNavigation } from "@react-navigation/native";
import { Notify } from "../../interfaces/Notify";
import { NavigationProps } from "../../navigator/NavigatorConfig";
import { BsCheck2Circle } from "react-icons/bs";
import ModalNewsChecked from "./ModalNewsChecked";
import { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import PopupConfirmationCustom from "../PopupConfirmationCustom";
import { NewList } from "./interfaces/NewList";

interface Props {
  title: string;
  detail: String;
  nameFile: String;
  id: number;
  items: NewList[]
  listIdNewBussinesView: []
  GetListByIdNewBussines
  idNewBussines: number
  idNewBussinesUsers: number
  idUser: number
  confirmviewNew
  isCheckedRegister,
  idNewUser: number,
  setIsOpenFormEdit,
  setDataEdit,
  isOpenFormEdit: boolean,
  defaultListNews;
  idRole;
  setIsOpenFormEditDisabled;
  isOpenFormEditDisabled;
  nameImage
}
export const NewsItem = ({ detail, title, id, items, nameFile, nameImage, listIdNewBussinesView, idRole, GetListByIdNewBussines, idNewUser, idNewBussines, idNewBussinesUsers, idUser, confirmviewNew, isCheckedRegister, setIsOpenFormEdit, setDataEdit, isOpenFormEdit, defaultListNews, setIsOpenFormEditDisabled, isOpenFormEditDisabled }: Props) => {

  const navigation = useNavigation<NavigationProps>();
  const [isOpen, setIsOpen] = useState(false)
  const [valueEditing, setValueEditing] = useState({})
  const [ConfirmOpen, setConfirmOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false);

  const actionMod = async () => {

    await confirmviewNew(valueEditing)
    setConfirmOpen(!ConfirmOpen)
  }

  const cancelMod = () => {
    setIsChecked(!isChecked);
    setValueEditing({})
  }

  const viewIsChecked = async (idNewBussines, idNewBussinesUsers) => {

    let data = {
      idNew: id,
      idNewBussines: idNewBussines,
      idNewBussinesUsers: idNewBussinesUsers,
      idNewUser: idNewUser
    }


    setIsOpen(!isOpen)
    GetListByIdNewBussines(data)

  }

  const formEditOpen = async (data) => {

    let result = await defaultListNews(data);

    setIsOpenFormEdit(!isOpenFormEdit)
    let newEdit = {
      id: id,
      title: title,
      detail: detail,
      nameFile: nameFile,
      nameImage: nameImage
    }
    setDataEdit(newEdit)
    //GetListByIdTaskBussines(data)
  }

  const formEditOpenDisabled = async (data) => {

    await defaultListNews(data);

    setIsOpenFormEditDisabled(!isOpenFormEditDisabled)
    let newEdit = {
      id: id,
      title: title,
      detail: detail,
      nameFile: nameFile,
      nameImage: nameImage
    }
    setDataEdit(newEdit)
    //GetListByIdTaskBussines(data)
  }





  function handleClick(idNewBussinesUsers, idUser, idNewBussines, idNewUser) {
    setValueEditing({ idNewBussinesUsers, idUser, idNewBussines, idNewUser })

    //setIsChecked({ ...isChecked, [idNew]: checked });
    setConfirmOpen(!ConfirmOpen)
    setConfirmOpen(!ConfirmOpen)
    setIsChecked(!isChecked);
  }



  return (
    <div className="row p-2 justify-content-between w-100 g-1" key={id}>
      <div className="col-9 ">
        <div className="d-flex gap-3">
          {isCheckedRegister == 0 &&
            <div className="" >
              <input type="checkbox" className=" mt-2" checked={isChecked} style={{ transform: 'scale(1.6)', }} onClick={() => handleClick(idNewBussinesUsers, idUser, idNewBussines, idNewUser)} />
            </div>
          }
          <div
            onClick={() =>
              formEditOpenDisabled(id)
            }
            className="d-flex justify-content-start flex-column mb-2 "
          >
            <h3 className="mb-0 fs-6 text-break">{title}</h3>
            <span className="text-break" style={{ fontSize: '.8em' }}>{detail}</span>
          </div>
        </div>

      </div>
      <div className="col-2 d-flex justify-content-center p-0 gap-2">
        {(idRole == 2 || idRole == 6 || idRole == 5 || idRole == 9) && (
          <div className=" ">
            <FaRegEdit size={20} style={{ cursor: "pointer" }} onClick={() => formEditOpen(id)} />
          </div>
        )}
        {/* 
        { (idRole == 3 || idRole == 4 || idRole == 7 || idRole == 8 || idRole == 9 ) && (
          <div className="d-flex justify-content-center mt-2">
            <FaRegEdit size={28} style={{ 'marginRight': '1em', cursor: "pointer" }} onClick={() => formEditOpenDisabled (id)} />
          </div>
        )} */}


        <div className=" ">
          <BsCheck2Circle size={20} onClick={() => viewIsChecked(idNewBussines, idNewBussinesUsers)} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <ModalNewsChecked isOpen={isOpen} setIsOpen={setIsOpen} listIdNewBussinesView={listIdNewBussinesView} />
      {ConfirmOpen && (
        <PopupConfirmationCustom
          cancelAction={() => cancelMod()}
          title={""}
          message={"Cliccando sulla spunta confermi di aver letto la news"}
          confirmAction={() =>
            actionMod()
          }
          stateModal={ConfirmOpen}
          setStateModal={setConfirmOpen}
        />
      )}
    </div>

  );
};
