export interface AlertCustomProps {
  title: string;
  detail: string;
  status: string;
  handleClear: () => void;
}
export const AlertCustom = ({ detail, status, title, handleClear }: AlertCustomProps) => {
  return (
    <div
      className={`alert alert-${status} alert-dismissible fade show`}
      role="alert"
    >
      <strong>{title}</strong> {detail}
      <button
        onClick={() => handleClear()}
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      />
    </div>
  );
};
