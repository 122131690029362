import React, { useEffect, useState } from "react";

export const FilterAtelierOptions = () => {
  
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const styleFilter = {
    backgroundColor: '#ececec',
    fontWeight: 700,
    borderRadius: '1.2rem',
    paddingLeft: 11,
    marginLeft: '-8px',
    width: width <= 768 ? '105%' : '85%', 
  };

  return (
    <div className="col-12 d-flex justify-content-start align-items-center" style={styleFilter}>
      <img src={require("../../../../assets/icons-step-due/Icona-filtri.png")} alt="" />
      <label className="text-truncate">Filtra per</label>
    </div>
  );
};

