import React, { useEffect, useState } from "react";
import { items } from "../components/FormRepair/elements";
import {
  AddEvent,
  ResponseCalenderDetails,
  ResponseEvent,
  ResponseNegozio,
} from "../interfaces/calender";
import { getAllEvents, getNegozio } from "../services/calender";
import { useAuth } from "./useAuth";
import { useModal } from "./useModal";
export const useCalenderDetails = () => {
  const { user, permitedFilter } = useAuth();
  const [privatoAdmin, setPrivatoAdmin] = useState(false);
  const { closeModal, isOpen, openModalAdd } = useModal();
  const {
    closeModal: closeModalEdit,
    isOpen: isOpenEdit,
    openModalEdit,
    items: event,
  } = useModal();
  const [isLoading, setIsLoading] = useState(true);
  const [negozio, setNegozio] = useState("");
  const [response, setResponse] = useState<ResponseCalenderDetails>({
    events: [],
    options: [],
  });
  const handleData = async () => {
    const rol = permitedFilter ? 2 : user.idRole;
    try {
      if (rol == 2) {
        setPrivatoAdmin(true)
      }
      const response = await Promise.all([
        getNegozio(),
        getAllEvents(rol, user.idBussines),
      ]);
      const resp: ResponseNegozio[] = response[0];
      const events: ResponseEvent[] = response[1];
      console.log(resp);
      console.log(events);
      const normalizedNegozio = resp.map(({ desc_Bussines, idBussines }) => {
        return {
          label: desc_Bussines,
          value: idBussines,
        };
      });

      const normalizedEvents = events.map(

        ({
          details,
          fileName,
          end,
          idBussines,
          idCalendar,
          start,
          title,
          imageName,
          idUser,
        }): AddEvent => {
          const date = start.split(" ");
          const normalizedDateStart = start.split(" ");
          const normalizedDateEnd = end.split(" ");
          return {
            bussines: idBussines,
            codigo: idCalendar,
            details,
            imageName,
            fileName,
            end: new Date(
              `${normalizedDateEnd[0]}T${normalizedDateEnd[1]}`
            ),
            start: new Date(
              `${normalizedDateStart[0]}T${normalizedDateStart[1]}`
            ),
            title,
            date: date[0],
            idUser: Number(idUser),
          };
        }
      );
      setResponse({

        events: normalizedEvents,
        options: permitedFilter
          ? normalizedNegozio
          : normalizedNegozio.filter((item) => item.value === user.idBussines),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = evt.target;
    console.log(value);
    setNegozio(value);
  };

  const handleChangeDefaultNegozio = (value: string) => {
    console.log(value);
    setNegozio(value);
  };

  const handleAddEvent = (event: AddEvent) => {
    const normalizedEvent: AddEvent = {
      ...event,
      start: new Date(event.start),
      end: new Date(event.end),
    };
    setResponse({
      ...response,
      events: [...response.events, normalizedEvent],
    });
  };
  const handleEditEvent = (event: AddEvent) => {
    const selectedEvent = response.events.find(
      (item) => item.codigo === event.codigo
    );
    if (!selectedEvent) {
      throw new Error();
    }
    const { bussines, details, fileName, end, start, title, imageName } = event;
    const eventEdit: AddEvent = {
      ...selectedEvent,
      bussines,
      details,
      fileName,
      imageName,
      title,
      start: new Date(start),
      end: new Date(end),
    };

    setResponse({
      ...response,
      events: [
        ...response.events.filter((item) => item.codigo !== event.codigo),
        eventEdit,
      ],
    });
  };
  const handleDeleteEvent = (codigo: string) => {
    const selectedEvent = response.events.find(
      (item) => item.codigo === codigo
    );
    if (!selectedEvent) {
      throw new Error();
    }
    setResponse({
      ...response,
      events: [
        ...response.events.filter((item) => item.codigo !== event.codigo),
      ],
    });
  };
  const filtered = () => {
    if (negozio.length === 0) {
      if (privatoAdmin) {
        const eventosAdin = response.events.filter((item) => item.bussines != "8")
        return {
          data: eventosAdin,
          total: eventosAdin.length,
        };

      } else {
        return {
          data: response.events,
          total: response.events.length,
        };
      }

    }
    if (negozio == '8') {
      const filterData = response.events.filter((element) =>
        element.bussines.toLocaleLowerCase().includes(negozio) && element.idUser == Number(user.idUser)

      );
      return {
        data: filterData,
        total: filterData.length,
      };
    } else {
      let filterData;
      if(user?.idRole === '2' || user?.idRole === '6') {
       filterData = response.events.filter((element) =>
          element.bussines.toLocaleLowerCase().includes(negozio) 
        );
      }else {
       filterData = response.events.filter((element) =>
        element.bussines.toLocaleLowerCase().includes(negozio) || element.bussines.includes("7")
      );
      }
      return {
        data: filterData,
        total: filterData.length,
      };
    }

  };

  useEffect(() => {
    handleData();
    handleChangeDefaultNegozio(user?.idRole === '2' ? '8' : user.idBussines)
  }, []);

  return {
    options: response.options,
    isLoading,
    negozio,
    isOpen,
    isOpenEdit,
    myEvents: filtered().data,
    event,
    handleChange,
    closeModal,
    closeModalEdit,
    openModalAdd,
    openModalEdit,
    handleAddEvent,
    handleEditEvent,
    handleDeleteEvent,
  };
};