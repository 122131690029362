import React from 'react'
import { Image, SafeAreaView, ScrollView, StatusBar, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { HeaderBar } from '../../components/HeaderBar';
import { styles, tableHeader, tableRow } from '../../theme/GlobalStyle';
import { CheckLabel } from '../../components/CheckLabel';
import { SearchBox } from '../../components/SearchBox';
import { stockData, filterBrand, filterBusiness } from '../../api/FilterStockData'
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { stylesRepair } from '../../GeneralStyles/SCDue/AddFromStock';

interface Props extends NativeStackScreenProps<any, any> {}
export const AddFromStockScreen = ({navigation}: Props) => {

    const headers = [
        '',
        'Referenza',
        'Brand',
        'Quantitá',
        'Negozio'
    ]

    return (
        <div className="square">
            <ScrollView style={styles.scroll}>
                <SafeAreaView style={[stylesRepair.areaView]}>
                    <View style={{paddingHorizontal: 12}}
                    >
                        <HeaderBar
                            onBack={() => navigation.navigate('ControlPanelScreen')}
                            hasTitle={false}
                        />
                    </View>
                    <View style={[stylesRepair.areaContent]}>
                        <div className="row px-2"> 
                            <div className="col-sm-0 p-0"></div>
                            <div className="col-md-7 pb-2 col-sm-7 p-0"> 
                                <div className="mt-2">
                                    <Text
                                        style={[stylesRepair.sectionTitle]}
                                        >
                                        Aggiungi da magazzino
                                    </Text>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-5 p-0">
                                <SearchBox />
                            </div>
                        </div>
                        <div className="row mt-5 px-2">
                            <div className="col-3">
                                <div className="row">
                                    <div className="d-flex px-0">
                                        <Image
                                            source={require('../../assets/iconoskubitsoft/Prospetto-02-01-Refact.png')}
                                            style={{width: 40, height: 40}}
                                        />
                                        <h6 className="pt-2" style={{marginLeft: 10}}>Filtra per</h6>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="d-flex px-0">
                                        <h6>Referenza</h6>
                                        <Image
                                            source={require('../../assets/icons/arrow_down.png')}
                                            style={{width: 10, height: 10, marginLeft: 20, marginTop: 4}}
                                        />
                                    </div>
                                    <SearchBox />
                                </div>
                                <div className="row mt-4">
                                    <div className="d-flex px-0">
                                        <h6>Brand</h6>
                                        <Image
                                            source={require('../../assets/icons/arrow_down.png')}
                                            style={{width: 10, height: 10, marginLeft: 10, marginTop: 4}}
                                        />
                                    </div>
                                    <div className="px-0" style={{marginLeft: -8}}>
                                        {
                                            filterBrand.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <CheckLabel
                                                            name={item.name}
                                                            id={item.id}
                                                            type={item.type}
                                                            text={item.text}
                                                        />
                                                        <br />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="d-flex px-0">
                                        <h6>Negozio</h6>
                                        <Image
                                            source={require('../../assets/icons/arrow_down.png')}
                                            style={{width: 10, height: 10, marginLeft: 20, marginTop: 4}}
                                        />
                                    </div>
                                    <div className="px-0" style={{marginLeft: -8}}>
                                    {
                                            filterBusiness.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <CheckLabel
                                                            name={item.name}
                                                            id={item.id}
                                                            type={item.type}
                                                            text={item.text}
                                                        />
                                                        <br />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-9" style={{paddingLeft: 40, paddingRight: 0}}>
                                <div className="table-responsive">
                                    <table className="table table-borderless mt-3">
                                        <thead style={{ backgroundColor: "black", color: "white" }}>
                                            <tr className="mr-4">
                                                <th
                                                    style={{ borderTopLeftRadius: 50, borderBottomLeftRadius: 50 }}
                                                    scope="col"
                                                    className="align-self-center"
                                                ></th>
                                                {
                                                    headers.map((item, index) => {
                                                        return (
                                                            <th
                                                                key={index}
                                                                style={tableHeader}
                                                                scope="col"
                                                                className="align-self-center mr-4 px-4"
                                                            >
                                                                {item}
                                                            </th>
                                                        )
                                                    })
                                                }
                                                <th
                                                    style={{
                                                        borderTopRightRadius: 50,
                                                        borderBottomRightRadius: 50,
                                                    }}
                                                    scope="col"
                                                    className="align-self-center"
                                                ></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                stockData.map((item, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            style={{ borderBottom: "1px solid gray" }}
                                                        >
                                                            <td></td>
                                                            <td
                                                                style={tableRow}
                                                                className="align-self-center py-3"
                                                            >
                                                                {item.check}
                                                            </td>
                                                            <td
                                                                style={tableRow}
                                                                className="align-self-center mr-4 px-4 py-3"
                                                            >
                                                                {item.reference}
                                                            </td>
                                                            <td
                                                                style={tableRow}
                                                                className="align-self-center mr-4 px-4 py-3"
                                                            >
                                                                {item.brand}
                                                            </td>
                                                            <td
                                                                style={tableRow}
                                                                className="align-self-center mr-4 px-4 py-3"
                                                            >
                                                                {item.quantity}
                                                            </td>
                                                            <td
                                                                style={tableRow}
                                                                className="align-self-center mr-4 px-4 py-3"
                                                            >
                                                                {item.business}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </View>
                </SafeAreaView>
            </ScrollView>
        </div>
    )
}