import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
interface Props {
  label: string;
  onNavigate: any;
}
export const Tolbar = ({ label, onNavigate }: Props) => {
  return (
    <div className="d-flex justify-content-center align-items-center py-2 fw-bold">
      <FiChevronLeft
        className="me-2"
        role="button"
        onClick={() => {
          onNavigate('PREV')
        }}
      />
      <span className="text-capitalize">{label}</span>
      <FiChevronRight
        className="ms-2"
        role="button"
        onClick={() => {
          onNavigate('NEXT')
        }}
      />
    </div>
  )
}
