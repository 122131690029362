interface Props {
  loading: boolean;
  disabled: boolean;
  btnSubmit: string;
  btnCancel: string;
  classBtn?: string;
  closeModal: () => void;
  deleteEvent?: () => void;
  actions?: boolean;
}

export const FooterForm = ({
  btnCancel,
  btnSubmit,
  disabled,
  loading,
  classBtn = "dark",
  actions = true,
  closeModal,
  deleteEvent,
}: Props) => {
  return (
    <div className={`modal-footer ${loading ? "justify-content-center" : ""}`}>
      {actions && (
        <>
          {loading && (
            <button
              className="btn btn-info align-self-center"
              type="button"
              disabled
            >
              <span
                className="spinner-grow spinner-grow-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
              Caricamento in corso...
            </button>
          )}
          {!loading && (
            <>
              <button
                disabled={disabled}
                type="submit"
                className="btn btn-success mb-2 mt-2"
              >
                {btnSubmit}
              </button>
              <button
                onClick={
                  classBtn !== "dark" ? () => deleteEvent() : () => closeModal()
                }
                type="button"
                className={`${
                  !classBtn ? "btn-dark" : `btn-${classBtn}`
                } btn mb-2 mt-2`}
              >
                {btnCancel}
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};
