import { useEffect, useState } from "react";
import { colorsBussines } from "../GeneralStyles/Statistiche";
import { aleatorio } from "../helpers/sort";
import { ResponseNegozio } from "../interfaces/calender";
import {
  DataSalesOfMonth,
  ResponseSale,
  SalesOfMonth,
  Statistiche,
} from "../interfaces/statistiche";
import {
  getRowSales,
  getRowSalesFilter,
  selectBussiness,
  selectBrand,
  getRowVenditeProdottiFilter,
  getRowVenditePerCittaFilter,
  getRowVRepairsStatisticsFilter,
  httpGetCountBussinnes
} from "../services/MagazzinoStatistiche";
import { formatDDMMYYY, formatYYYMMDD } from '../services/interessiServices';
import { CharPieFormat, DataGetBussinesCount } from "../services/interfaces/GetBussinesCount";
export const useMagazzinoStatistiche = () => {
  const [paginaActual, setPaginaActual] = useState(1);
  const [paginaActualCitta, setPaginaActualCitta] = useState(1);
  const [paginaActualRepairs, setPaginaActualRepairs] = useState(1);

  //STATE GLOBAL
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<Statistiche>({
    negozio: [],
    sale: [],
    salesOfMonth: [],
    brand: [],
  });
  // STATE FILTER
  const [negozio, setNegozio] = useState<number[]>([]);
  const [dipendiente, setDipendiente] = useState("");

  const [userAndBusinesFilter, setUserAndBusinesFilter] = useState("")

  const [countBussines, setCountBussines] = useState<CharPieFormat[]>([])


  const [dateFilter, setDateFilter] = useState([]);
  const [dateGraphicFilter, setDateGraphicFilter] = useState([]);
  const [dateRepairStatisticsFilter, setRepairStatisticsFilter] = useState([]);
  const [dateVenditeProdottiFilter, setDateVenditeProdottiFilter] = useState([]);
  //STATE FILTER PRODOTTO VENDITE
  const [negozioVenditeProdotti, setNegozioVenditeProdotti] = useState<number[]>([]);
  const [brandVenditeProdotti, setBrandVenditeProdotti] = useState<number[]>([]);
  const [brandRepairStatistics, setBrandRepairStatistics] = useState<number[]>([]);
  const [referenza, setReferenza] = useState("");
  const [city, setCity] = useState("");
  const [statisticDate, setStatisticDate] = useState([]);
  const [statisticDateRepairs, setStatisticDateRepairs] = useState([]);
  const [statisticDateVenditeProdotti, setStatisticDateVenditeProdotti] = useState([]);

  //STATE FILTER VENDITE PER CITTE
  const [negozioVenditeCitta, setNegozioVenditeCitta] = useState<number[]>([]);
  const limit: number = 5;
  const [currentPage, setcurrentPage] = useState(1)
  const [dataFilter, setDataFilter] = useState([]);
  const [dataGraphicFilter, setDataGraphicFilter] = useState([]);
  const [dataVenditePerCittaFilter, setDataVenditePerCittaFilter] = useState([]);
  const [dataRepairStatisticsFilter, setDataRepairStatisticsFilter] = useState([]);
  const [dataRepairStatisticsPercentageFilter, setDataRepairStatisticsPercentageFilter] = useState([]);
  const [statisticData, setStatisticData] = useState([]);
  const [dataVenditeProdottiFilter, setDataVenditeProdottiFilter] = useState([]);
  // STATE SHOW FILTER
  const [showD, setShowD] = useState(false);
  const [showN, setShowN] = useState(false);
  const [showNP, setShowNP] = useState(false);
  const [showBP, setShowBP] = useState(false);
  const [showBRS, setShowRS] = useState(false);
  const [showRP, setShowRP] = useState(false);
  const [showVC, setShowVC] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showDateGraphic, setDateGraphic] = useState(false);
  const [showDateRepairs, setDateRepairs] = useState(false);
  const [showDateVenditeProdotti, setDateVenditeProdotti] = useState(false);


  const [paginationListe, setpaginatcionListe] = useState({
    currentPage: 0,
    total: 0,
    totalPages: 0,
  });
  const [paginationListeCitta, setpaginatcionListeCitta] = useState({
    currentPage: 0,
    total: 0,
    totalPages: 0,
  });
  const [paginationRipairs, setpaginatcionRipairs] = useState({
    currentPage: 0,
    total: 0,
    totalPages: 0,
  });

  const fechaActual = new Date();
  const primerDiaDelMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
  const formatoPrimerDiaDelMes = primerDiaDelMes.toISOString().split('T')[0]; // '2024-04-01'
  const ultimoDiaDelMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);
  const formatoUltimoDiaDelMes = ultimoDiaDelMes.toISOString().split('T')[0];

  const handleData = async () => {
    try {
      const results = await Promise.all([selectBussiness(), getRowSales(), selectBrand(), httpGetCountBussinnes(formatoPrimerDiaDelMes,formatoUltimoDiaDelMes)]);
      const bussines: ResponseNegozio[] = results[0];
      const sales: ResponseSale[] = results[1];
      const brand = results[2];
      const valueAppearances: number[] = [];
      const [, , , responseGetCountBussiness] = results;

      console.log(responseGetCountBussiness)

      const mapedData = responseGetCountBussiness.response.map((item, index) => {

        const data: CharPieFormat = {
          color: colorsBussines[index],
          title: item.desc_Bussines,
          value: Number(item.contador),
          total: Number(item.total)
        }
        return data;
      })
      setCountBussines(mapedData);

      let totalOfSale = 0;
      const normalizedBussines = bussines.map(
        ({ desc_Bussines, idBussines }) => {
          return {
            label: desc_Bussines,
            value: idBussines,
          };
        }
      );
      const normalizedBrand = brand.map(
        ({ desc_Brand, idBrand }) => {
          return {
            label: desc_Brand,
            value: idBrand,
          };
        }
      );
      const normalizedSales = sales.map(
        ({
          desc_bussines,
          idBussines,
          idClient,
          idSale,
          idUser,
          name,
          prodottiVenduti,
          month,
          year,
          day,
          date
        }) => {
          return {
            desc_bussines,
            idBussines,
            idClient,
            idSale,
            idUser,
            name: name.toLowerCase(),
            prodottiVenduti,
            month: Number(month),
            year: Number(year),
            day: Number(day),
            date: formatDate(new Date(year, month - 1, day)),
          };
        }
      );

      for (let index = 0; index < 1000; index++) {
        const number = aleatorio(0, colorsBussines.length - 1);
        if (!valueAppearances.includes(number)) {
          valueAppearances.push(number);
        }
        if (valueAppearances.length === colorsBussines.length) {
          break;
        }
      }

      if (sales.length > 0) {
        sales.forEach((buss) => {
          totalOfSale = totalOfSale + Number(buss.prodottiVenduti);
        });
      }
      const normalizedSalesOfMonth = bussines.map(
        (item, index): SalesOfMonth => {
          let value = 0;
          const dataPush: DataSalesOfMonth[] = [];
          const result = sales.filter(
            ({ idBussines }) => idBussines === item.idBussines
          );
          if (result.length > 0) {
            result.forEach((e) => {
              value = value + Number(e.prodottiVenduti);
              dataPush.push({
                month: Number(e.month),
                year: Number(e.year),
                quantity: Number(e.prodottiVenduti),
              });
            });
          }
          return {
            color: colorsBussines[valueAppearances[index]],
            name: item.desc_Bussines,
            value,
            totalSale: totalOfSale,
            data: dataPush,
          };
        }
      );
      setResponse({
        negozio: normalizedBussines,
        sale: normalizedSales,
        salesOfMonth: normalizedSalesOfMonth,
        brand: normalizedBrand,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeCheck = (filter: string) => {
    const id = Number(filter);
    const findById = negozio.find((item) => item === id);
    if (!findById) {
      setNegozio([...negozio, id]);
      return;
    }
    setNegozio([...negozio.filter((item) => item !== id)]);
  };

  const handleCheck = (id: string) => {
    const value = Number(id);
    const findById = negozio.find((item) => item === value);
    if (!findById) return false;
    return true;
  };
  const handleChangeCheckBrand = (filter: string) => {
    const id = Number(filter);
    const findById = brandVenditeProdotti.find((item) => item === id);
    if (!findById) {
      setBrandVenditeProdotti([...brandVenditeProdotti, id]);
      return;
    }
    setBrandVenditeProdotti([...brandVenditeProdotti.filter((item) => item !== id)]);
  };

  const handleCheckBrand = (id: string) => {
    const value = Number(id);
    const findById = brandVenditeProdotti.find((item) => item === value);
    if (!findById) return false;
    return true;
  };

  const handleChangeCheckBrandRepairStatistics = (filter: string) => {
    const id = Number(filter);
    const findById = brandRepairStatistics.find((item) => item === id);
    if (id === 999 && !findById) {
      setBrandRepairStatistics([id]);
      return
    }
    let selectedOthers = brandRepairStatistics.find((item) => item === 999)
    if (!findById) {
      let data = [...brandRepairStatistics, id]
      setBrandRepairStatistics(data);
      if (selectedOthers) {
        setBrandRepairStatistics([...brandRepairStatistics.filter((item) => item !== selectedOthers), id]);
      } else {
        setBrandRepairStatistics(data);
      }
      return;
    }
    setBrandRepairStatistics([...brandRepairStatistics.filter((item) => item !== id)]);
  };

  const handleCheckBrandRepairStatistics = (id: string) => {
    const value = Number(id);
    const findById = brandRepairStatistics.find((item) => item === value);
    if (!findById) return false;
    return true;
  };

  const handleChangeCheckVenditeCitta = (filter: string) => {
    const id = Number(filter);
    const findById = negozioVenditeCitta.find((item) => item === id);
    if (!findById) {
      setNegozioVenditeCitta([...negozioVenditeCitta, id]);
      return;
    }
    setNegozioVenditeCitta([...negozioVenditeCitta.filter((item) => item !== id)]);
  };

  const handleCheckVenditeCitta = (id: string) => {
    const value = Number(id);
    const findById = negozioVenditeCitta.find((item) => item === value);
    if (!findById) return false;
    return true;
  };
  const handleChangeCheckNegozioProdotti = (filter: string) => {
    const id = Number(filter);
    const findById = negozioVenditeProdotti.find((item) => item === id);
    if (!findById) {
      setNegozioVenditeProdotti([...negozioVenditeProdotti, id]);
      return;
    }
    setNegozioVenditeProdotti([...negozioVenditeProdotti.filter((item) => item !== id)]);
  };

  const handleCheckNegozioProdotti = (id: string) => {
    const value = Number(id);
    const findById = negozioVenditeProdotti.find((item) => item === value);
    if (!findById) return false;
    return true;
  };
  const filtered = async () => {
    let dataEnd;
    let dataStart;
    if (dateFilter.length > 0) {
      const array = dateFilter[0].split("/");
      const arrayEnd = dateFilter[1]?.split("/");
      dataEnd = arrayEnd[2] + '/' + arrayEnd[1] + '/' + arrayEnd[0]
      dataStart = array[2] + '/' + array[1] + '/' + array[0]
    }
    const results = await getRowSalesFilter({
      bussinesFilter: negozio,
      dateStart: dateFilter[0] ? formatYYYMMDD(dataStart) != '1969-12-31' ? formatYYYMMDD(dataStart) : "" : "",
      dateEnd: dateFilter[0] ? formatYYYMMDD(dataEnd) != '1969-12-31' ? formatYYYMMDD(dataEnd) : "" : "",
      userFilter: dipendiente ? dipendiente : '',
      userAndBusinesFilter: userAndBusinesFilter ? userAndBusinesFilter : '',
    }).then((data) => {
      setDataFilter([])
      setDataFilter(data)
      console.log(data)
    })
  };
  const filteredGraphic = async () => {
    let dataFilterGraphic: any;
    let dataEnd;
    let dataStart;
    if (dateGraphicFilter.length > 0) {
      const array = dateGraphicFilter[0].split("/");
      const arrayEnd = dateGraphicFilter[1]?.split("/");
      dataEnd = arrayEnd[2] + '/' + arrayEnd[1] + '/' + arrayEnd[0]
      dataStart = array[2] + '/' + array[1] + '/' + array[0]
    }
    const resultsBussines = await Promise.all([selectBussiness()]);
    const bussines: ResponseNegozio[] = resultsBussines[0];
    const valueAppearances: number[] = [];
    let totalOfSale = 0;
    const results = await getRowSalesFilter({
      bussinesFilter: null,
      dateStart: dateGraphicFilter[0] ? formatYYYMMDD(dataStart) != '1969-12-31' ? formatYYYMMDD(dataStart) : "" : "",
      dateEnd: dateGraphicFilter[0] ? formatYYYMMDD(dataEnd) != '1969-12-31' ? formatYYYMMDD(dataEnd) : "" : "",
      userFilter: null,
      userAndBusinesFilter: null
    }).then((data) => {
      if (data) {
        dataFilterGraphic = data
      }
    })
    for (let index = 0; index < 1000; index++) {
      const number = aleatorio(0, colorsBussines.length - 1);
      if (!valueAppearances.includes(number)) {
        valueAppearances.push(number);
      }
      if (valueAppearances.length === colorsBussines.length) {
        break;
      }
    }
    if (dataFilterGraphic?.length > 0) {
      dataFilterGraphic.forEach((buss) => {
        totalOfSale = totalOfSale + Number(buss.prodottiVenduti);
      });
    }
    const normalizedSalesOfMonth = bussines.map(
      (item, index): SalesOfMonth => {
        let value = 0;
        const dataPush: DataSalesOfMonth[] = [];
        const result = dataFilterGraphic?.filter(
          (e) => e.idBussinessSeller === item.idBussines
        );
        if (dataFilterGraphic?.length > 0) {
          dataFilterGraphic.forEach((e) => {
            value = value + Number(e.prodottiVenduti);
            dataPush.push({
              month: Number(e.month),
              year: Number(e.year),
              quantity: Number(e.prodottiVenduti),
            });
          });
        }
        return {
          color: colorsBussines[valueAppearances[index]],
          name: item.desc_Bussines,
          value,
          totalSale: totalOfSale,
          data: dataPush,
        };
      }
    );
    //console.log(normalizedSalesOfMonth)
    //setDataGraphicFilter([...normalizedSalesOfMonth])

  };

  const filteredStatisticByDates = async () => {
    const resultsBussines = await Promise.all([selectBussiness()]);
    const bussines: ResponseNegozio[] = resultsBussines[0];
    console.log(bussines);
    const valueAppearances: number[] = [];
    let dataFilterGraphic: any;
    let totalOfSale = 0;

    for (let index = 0; index < 1000; index++) {
      const number = aleatorio(0, colorsBussines.length - 1);
      if (!valueAppearances.includes(number)) {
        valueAppearances.push(number);
      }
      if (valueAppearances.length === colorsBussines.length) {
        break;
      }
    }

    let statisticData: any = [];

    console.log(statisticDate)

    const results = await getRowSalesFilter({
      bussinesFilter: '',
      dateStart: statisticDate.length > 0 ? (statisticDate[0] !== '1969-12-31' ? statisticDate[0] : '') : "",
      dateEnd: statisticDate.length > 0 ? (statisticDate[1] !== '1969-12-31' ? statisticDate[1] : '') : "",
      userFilter: '',
      userAndBusinesFilter: ''
    }).then((data) => {
      console.log(data);
      statisticData = data;
    })

    if (statisticData !== undefined) {
      if (statisticData.length > 0) {

        statisticData.map((sales): any => {
          totalOfSale += Number(sales.prodottiVenduti)
        })

        const normalizedSalesOfMonth = bussines.map(
          (item, index): SalesOfMonth => {
            let value = 0;
            const dataPush: DataSalesOfMonth[] = [];
            const result = statisticData.filter(
              ({ idBussinessSeller }) => idBussinessSeller === item.idBussines
            );
            if (result.length > 0) {
              result.forEach((e) => {
                value = value + Number(e.prodottiVenduti);
                dataPush.push({
                  month: Number(e.month),
                  year: Number(e.year),
                  quantity: Number(e.prodottiVenduti),
                });
              });
            }
            return {
              color: colorsBussines[valueAppearances[index]],
              name: item.desc_Bussines,
              value,
              totalSale: totalOfSale,
              data: dataPush,
            };
          }
        );
        setStatisticData(normalizedSalesOfMonth);
        return;
      }
    }
    setStatisticData([]);

  }

  const filteredProdotti = async () => {
    let dataEnd;
    let dataStart;
    if (dateVenditeProdottiFilter != undefined) {
      if (dateVenditeProdottiFilter.length > 0) {
        const array = dateVenditeProdottiFilter[0].split("/");
        const arrayEnd = dateVenditeProdottiFilter[1]?.split("/");
        // dataEnd = new Date(arrayEnd[2], arrayEnd[1], arrayEnd[0])
        // dataStart = new Date(array[2], array[1], array[0])

        dataEnd = arrayEnd[2] + '/' + arrayEnd[1] + '/' + arrayEnd[0]
        dataStart = array[2] + '/' + array[1] + '/' + array[0]
      }

    }
    const results = await getRowVenditeProdottiFilter({
      bussinesFilter: negozioVenditeProdotti,
      brandFilter: brandVenditeProdotti,
      referenzaFilter: referenza,
      limit: 5,
      page: paginaActual,
      dateStart: dateVenditeProdottiFilter[0] ? formatYYYMMDD(dataStart) != '1969-12-31' ? formatYYYMMDD(dataStart) : "" : "",
      dateEnd: dateVenditeProdottiFilter[0] ? formatYYYMMDD(dataEnd) != '1969-12-31' ? formatYYYMMDD(dataEnd) : "" : "",
    }).then((data) => {
      if (data) {
        setDataVenditeProdottiFilter(data?.response)
        setpaginatcionListe({
          currentPage: Number(data?.currentPage),
          total: Number(data?.total),
          totalPages: Number(data?.totalPages),
        });

      } else {
        setDataVenditeProdottiFilter([])
      }



    })
  }
  const filteredVenditePerCitta = async () => {
    const results = await getRowVenditePerCittaFilter({
      city: city,
      limit: limit,
      page: paginaActualCitta,
    }).then((data) => {
      if (data) {
        setDataVenditePerCittaFilter(data?.response)
        setpaginatcionListeCitta({
          currentPage: Number(data?.currentPage),
          total: Number(data?.total),
          totalPages: Number(data?.totalPages),
        });
      } else { setDataVenditePerCittaFilter([]) }

    })
  }
  const filteredRepairStatistics = async () => {
    let dataEnd;
    let dataStart;
    if (dateRepairStatisticsFilter != undefined) {
      if (dateRepairStatisticsFilter.length > 0) {
        const array = dateRepairStatisticsFilter[0].split("/");
        const arrayEnd = dateRepairStatisticsFilter[1]?.split("/");
        // dataEnd = new Date(arrayEnd[2], arrayEnd[1], arrayEnd[0])
        // dataStart = new Date(array[2], array[1], array[0])

        dataEnd = arrayEnd[2] + '/' + arrayEnd[1] + '/' + arrayEnd[0]
        dataStart = array[2] + '/' + array[1] + '/' + array[0]
      }

    }

    const results = await getRowVRepairsStatisticsFilter({
      brandFilter: brandRepairStatistics,
      limit: limit,
      page: paginaActualCitta,
      dateStart: dateRepairStatisticsFilter[0] ? formatYYYMMDD(dataStart) != '1969-12-31' ? formatYYYMMDD(dataStart) : "" : "",
      dateEnd: dateRepairStatisticsFilter[0] ? formatYYYMMDD(dataEnd) != '1969-12-31' ? formatYYYMMDD(dataEnd) : "" : "",
    }).then((data) => {
      if (data) {
        setDataRepairStatisticsFilter(data?.response)
        // setDataRepairStatisticsPercentageFilter(...dataRepairStatisticsPercentageFilter,{

        // })
        setpaginatcionRipairs({
          currentPage: Number(data?.currentPage),
          total: Number(data?.total),
          totalPages: Number(data?.totalPages),
        });
      } else { setDataRepairStatisticsFilter([]) }

    })
  }
  const filteredSales = () => {
    if (dateGraphicFilter?.length === 0) {
      let now = new Date();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();
      const salesOfMonth: SalesOfMonth[] = [];
      let totalSaleGlobal = 0;
      response.salesOfMonth.forEach((elem) => {
        let totalSellBussines = 0;
        if (Array.isArray(elem.data)) {
          elem.data.filter((item) => {
            if (item.month === month && year === item.year) {
              totalSellBussines = totalSellBussines + item.quantity;
              totalSaleGlobal += item.quantity;
            }
          });
        }
        salesOfMonth.push({
          color: elem.color,
          data: [],
          name: elem.name,
          totalSale: 0,
          value: totalSellBussines,
        });
      });

      const normalized = salesOfMonth.map(({ color, data, name, value }) => {
        return {
          color,
          data,
          name,
          totalSale: totalSaleGlobal,
          value,
        };
      });
      return normalized;
    }
    const { month: monthNrm, year: yearNrm } = normalizedDateGraphic();
    const salesOfMonth: SalesOfMonth[] = [];
    let totalSaleGlobal = 0;
    response.salesOfMonth.forEach((elem) => {
      let totalSellBussines = 0;
      if (Array.isArray(elem.data)) {
        elem.data.filter((item) => {
          if (item.month === monthNrm && yearNrm === item.year) {
            totalSellBussines = totalSellBussines + item.quantity;
            totalSaleGlobal = totalSaleGlobal + item.quantity;
          }
        });
      }
      salesOfMonth.push({
        color: elem.color,
        data: [],
        name: elem.name,
        totalSale: totalSellBussines,
        value: totalSellBussines,
      });
    });
    const normalized = salesOfMonth.map(({ color, data, name, value }) => {
      return {
        color,
        data,
        name,
        totalSale: totalSaleGlobal,
        value,
      };
    });
    return normalized;
  };

  const handleChangeReferenza = (value: string) => {
    setReferenza(value.toLowerCase());
  };
  const handleChangeCity = (value: string) => {
    setCity(value.toLowerCase());
  };
  const handleChangeStatistic = async (value: any) => {
    let dataEnd;
    let dataStart;

    if (value.length > 0) {
      if (value[0] === '31/12/1969' && value[1] === '31/12/1969') {
        setStatisticDate([]);
        return;
      }
      const array = value[0].split("/");
      const arrayEnd = value[1]?.split("/");
      dataEnd = arrayEnd[2] + '/' + arrayEnd[1] + '/' + arrayEnd[0]
      dataStart = array[2] + '/' + array[1] + '/' + array[0]
      const response = await httpGetCountBussinnes(dataStart, dataEnd);
      const mapedData = response.response.map((item, index) => {

        const data: CharPieFormat = {
          color: colorsBussines[index],
          title: item.desc_Bussines,
          value: Number(item.contador),
          total: Number(item.total)
        }
        return data;
      })
      setCountBussines(mapedData);

      dataStart = formatYYYMMDD(dataStart);
      dataEnd = formatYYYMMDD(dataEnd);
      setStatisticDate([dataStart, dataEnd]);

    } else {
      setStatisticDate([]);
    }
  };
  const handleChangeRepairs = (value: any) => {
    let dataEnd;
    let dataStart;

    if (value.length > 0) {
      if (value[0] === '31/12/1969' && value[1] === '31/12/1969') {
        setStatisticDateRepairs([]);
        return;
      }
      const array = value[0].split("/");
      const arrayEnd = value[1]?.split("/");
      dataEnd = arrayEnd[2] + '/' + arrayEnd[1] + '/' + arrayEnd[0]
      dataStart = array[2] + '/' + array[1] + '/' + array[0]

      dataStart = formatYYYMMDD(dataStart);
      dataEnd = formatYYYMMDD(dataEnd);
      setStatisticDateRepairs([dataStart, dataEnd]);
    } else {
      setStatisticDateRepairs([]);
    }
  };
  const handleChangeDipendiente = (value: string) => {
    setDipendiente(value.toLowerCase());
  };

  const handleChangeUserBusines = (value: string) => {
    console.log('Vendite dipendenti', value)
    setUserAndBusinesFilter(value.toLowerCase());
  };

  const handleChangeDateFilter = (value: []) => setDateFilter(value);
  const handleChangeDateGraphicFilter = (value: []) => setDateGraphicFilter(value);
  const handleChangeDateRepairStatistics = (value: []) => setRepairStatisticsFilter(value);
  const handleChangeDateVenditeProdotti = (value: []) => setDateVenditeProdottiFilter(value);

  const handleShowDipendiente = () => setShowD((prev) => !prev);
  const handleShowNegozio = () => setShowN((prev) => !prev);
  const handleShowVenditeCitta = () => setShowVC((prev) => !prev);
  const handleShowNegozioProdotti = () => setShowNP((prev) => !prev);
  const handleShowBrandProdotti = () => setShowBP((prev) => !prev);
  const handleShowReferenzaProdotti = () => setShowRP((prev) => !prev);
  const handleShowMeseAnno = () => setShowDate((prev) => !prev);
  const handleShowMeseAnnoGraphic = () => setDateGraphic((prev) => !prev);
  const handleShowMeseAnnoRepairs = () => setDateRepairs((prev) => !prev);
  const handleShowMeseAnnoVenditeProdotti = () => setDateVenditeProdotti((prev) => !prev);

  const handleShowBrandRepairStatistics = () => setShowRS((prev) => !prev);



  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }
  const normalizedDate = () => {
    const array = dateFilter[0]?.split("/");
    const arrayEnd = dateFilter[1]?.split("/");
    return {
      dateStart: new Date(dateFilter[0]),
      dateEnds: new Date(arrayEnd[2], arrayEnd[1], arrayEnd[0]),
      dayEnd: Number(arrayEnd[0]),
      monthEnd: Number(arrayEnd[1]),
      yearEnd: Number(arrayEnd[2]),
      day: Number(array[0]),
      month: Number(array[1]),
      year: Number(array[2]),
    };
  };
  const normalizedDateGraphic = () => {
    const array = dateGraphicFilter[0]?.split("/");
    const arrayEnd = dateGraphicFilter[1]?.split("/");
    return {
      dateStart: new Date(dateGraphicFilter[0]),
      dateEnds: new Date(arrayEnd[2], arrayEnd[1], arrayEnd[0]),
      dayEnd: Number(arrayEnd[0]),
      monthEnd: Number(arrayEnd[1]),
      yearEnd: Number(arrayEnd[2]),
      day: Number(array[0]),
      month: Number(array[1]),
      year: Number(array[2]),
    };
  };
  const venditeTotali = () => {
    let total = 0;
    const data = dataFilter
    if (data?.length > 0) {
      console.log(data)
      data.forEach((buss) => {
        total = total + Number(buss.prodottiVenduti);
      });
    }
    return total;
  }
  // useEffect(()=>{
  //   filteredGraphic();
  //   filteredSales();
  // },[dateGraphicFilter]);
  useEffect(() => {
    filtered();
  }, [negozio, dipendiente, dateFilter, userAndBusinesFilter]);
  useEffect(() => {
    filteredVenditePerCitta();
  }, [negozioVenditeCitta, paginaActualCitta]);
  useEffect(() => {
    filteredStatisticByDates();
  }, [statisticDate]);
  useEffect(() => {
    filteredRepairStatistics();
  }, [dateRepairStatisticsFilter, brandRepairStatistics])
  useEffect(() => {
    filteredVenditePerCitta();
  }, [city]);
  useEffect(() => {
    filteredProdotti();
  }, [negozioVenditeProdotti, brandVenditeProdotti, referenza, paginaActual, dateVenditeProdottiFilter]);
  useEffect(() => {
    handleData();
    filteredGraphic();
    filteredRepairStatistics();
  }, []);
  // console.log("dateFilter",filteredSales(),dataGraphicFilter,dataFilter,filteredGraphic())
  //console.log("dateVenditeProdottiFilter",dateVenditeProdottiFilter)
  return {
    bussines: response.negozio,
    brand: response.brand,
    isLoading,
    dipendiente,
    showD,
    showN,
    showNP,
    showBP,
    showRP,
    showVC,
    showBRS,
    showDate,
    showDateGraphic,
    showDateRepairs,
    dateFilter,
    dateGraphicFilter,
    paginaActual,
    referenza,
    paginaActualCitta,
    setPaginaActualCitta,
    paginationListeCitta,
    paginationRipairs,
    paginationListe,
    repairStatistics: dataRepairStatisticsFilter,
    venditePerCitta: dataVenditePerCittaFilter,
    statisticData: statisticData,
    totalVendite: venditeTotali(),
    sales: dataFilter,
    venditeProdotti: dataVenditeProdottiFilter,
    dataGraphic: dateGraphicFilter,
    salesOfMonth: filteredSales(),
    handleChangeCheck,
    handleCheck,
    handleChangeDipendiente,
    handleChangeReferenza,
    handleShowDipendiente,
    handleShowNegozio,
    handleShowNegozioProdotti,
    handleShowMeseAnno,
    handleShowMeseAnnoGraphic,
    handleChangeDateFilter,
    handleChangeDateGraphicFilter,
    handleChangeDateRepairStatistics,
    handleShowBrandProdotti,
    handleShowBrandRepairStatistics,
    handleShowReferenzaProdotti,
    handleChangeCheckBrand,
    handleChangeCity,
    city,
    handleChangeStatistic,
    statisticDate,
    handleCheckBrand,
    handleCheckBrandRepairStatistics,
    handleChangeCheckBrandRepairStatistics,
    handleChangeCheckNegozioProdotti,
    handleCheckNegozioProdotti,
    setPaginaActual,
    handleShowVenditeCitta,
    handleChangeCheckVenditeCitta,
    handleCheckVenditeCitta,
    userAndBusinesFilter,
    handleChangeUserBusines,
    handleChangeRepairs,
    handleShowMeseAnnoRepairs,
    statisticDateRepairs,
    paginaActualRepairs,
    setPaginaActualRepairs,
    handleShowMeseAnnoVenditeProdotti,
    showDateVenditeProdotti,
    handleChangeDateVenditeProdotti,
    statisticDateVenditeProdotti,
    countBussines,
  };
};