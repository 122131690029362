import { Controller } from "react-hook-form"
import { Text, TouchableOpacity } from "react-native";
import { styles } from "../../theme/GlobalStyle";
import { styleInputBlock } from "../../GeneralStyles/SCDue/ControlPanel";

export const GridListInteressiByClientUpdate = ({ control, dataListInteressiByClient}) => {
    return (
        <>
            <Text style={styles.label}>Liste Presenti</Text>
            {
                dataListInteressiByClient?.map((item, index) => {
                    return (
                        <div key={index} className="col-12">
                                                <Controller
                                control={control}
                                name="ListPresentiByClient"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <input
                                        className="form-control"
                                        value={item?.name + " - " + item?.desc_Bussines}
                                        style={styleInputBlock}
                                        disabled={true}
                                    />
                                )}
                            />
                             <div style={{
                                            position: "relative",                                           
                                            top:-35,
                                            left:30,
                                            zIndex:-1
                                        }}> 
                            </div> 
                                              
                            <br />
                        </div>
                    )
                })
            }
        </>

    )

}