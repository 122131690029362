import React from "react";
import CSS from "csstype";
import { TouchableOpacity, Image } from "react-native";

import { MagazzinoResponse } from "../../interfaces/magazzino";
import { stylesMagazzino } from "../../GeneralStyles/MagazzinoStyle";
import { formatNumber } from "../../services/MagazzinoRefactor";
import { useUserPermission } from "../../hooks/useUserPermission";
import { CheckLabel } from "../CheckLabel";
import SchemaProdottoCard from "../magazzino/SchemaProdottoCard";
import Pagination from "../Pagination/pagination";


interface Props {
  data: MagazzinoResponse[];
  confirmToDelete: any;
  goToSchedaProdotto: any;
  goToSchedaQuadrante: any;
  dataCrm: any;
  goToMatricola: any;
  crmSend: any;
}
export const TableMagazzinoBody = ({ data, confirmToDelete, goToSchedaProdotto, goToSchedaQuadrante, dataCrm, goToMatricola, crmSend }: Props) => {

  const { isAdmin, isMarketing } = useUserPermission()
  return (
    <>
      <tbody>
        {data.map(
          ({
            idMagazzino,
            idReference,
            referencia,
            desc_brand,
            desc_famiglia,
            quantity,
            putAsideCount,
            desc_bussines,
            prezzo
          }, index) => (

            <tr key={idMagazzino} style={{ borderBottom: "1px solid gray" }}>
              <td
                style={tableRow}
                className="align-self-center mr-4 px-4 text-center"
              >
                {dataCrm
                  ?
                  dataCrm == 1
                    ?
                    <CheckLabel
                      name="Invia e-mail" 
                      id={"inviaEmail-" + 1}
                      type="text"
                      text={""}
                      checked={crmSend}
                      onChange={() => goToMatricola(data[index])}
                    /> 
                    :
                     dataCrm == 'addMagazzino'
                     ?
                     <TouchableOpacity
                        style={stylesMagazzino.noteIcon}
                        onPress={() => goToSchedaProdotto(data[index], 'open')}
                      >
                        <Image
                          style={{ width: 30, height: 30 }}
                          source={require("../../assets/icons/icon-note.png")}
                        />
                      </TouchableOpacity>
                     :
                      <CheckLabel
                        name="Invia e-mail"
                        id={"inviaEmail-" + 1}
                        type="text"
                        text={""}
                        checked={crmSend}
                        onChange={() => goToMatricola(data[index])}
                      />
                  :
                  <TouchableOpacity
                    style={stylesMagazzino.noteIcon}
                    onPress={() => goToSchedaProdotto(data[index], 'open')}
                  >
                    <Image
                      style={{ width: 30, height: 30 }}
                      source={require("../../assets/icons/icon-note.png")}
                    />
                  </TouchableOpacity>
                }
              </td>
              <td style={tableRow} className="align-self-center mr-4 px-4">
                {referencia}
              </td>
              <td style={tableRow} className="align-self-center mr-4 px-4">
                {desc_famiglia}
              </td>
              <td style={tableRow} className="align-self-center mr-4 px-4">
                {desc_brand}
              </td>
              <td
                style={{ ...tableRow, fontSize: 12 }}
                className="align-self-center mr-4 px-4"
              >
                {quantity}
              </td>
              <td
                style={{ ...tableRow, fontSize: 12 }}
                className="align-self-center mr-4 px-4"
              >
                {putAsideCount}
              </td>
              
              <td
                style={{ ...tableRow, fontSize: 12 }}
                className="align-self-center mr-4 px-4"
              >
                {desc_bussines !== "NULL" && desc_bussines}
              </td>
              <td
                style={{ ...tableRow, fontSize: 12 }}
                className="align-self-center mr-4 px-4"
              >
                {formatNumber(prezzo)}
              </td>
              <td
                style={{ ...tableRow, fontSize: 12 }}
                className="align-self-center mr-4 px-4"
              ></td>
              <td>
                {isAdmin || isMarketing ? (
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      paddingHorizontal: 5,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() =>
                      confirmToDelete(
                        idReference,
                        idMagazzino
                      )
                    }
                  >
                    <Image
                      style={{ width: 20, height: 20 }}
                      source={require("../../assets/icons/icon-delete.png")}
                    />
                  </TouchableOpacity>
                ) : null}
              </td>
              <TouchableOpacity
                style={stylesMagazzino.noteIcon}
                onPress={() => goToSchedaQuadrante(data[index], 'open')}
              >
                <Image
                  style={{ width: 30, height: 30 }}
                  source={require("../../assets/icons/icon-note.png")}
                />
              </TouchableOpacity>
              <td></td>
            </tr>

          )
        )}

      </tbody>

    </>
  );
};
export const tableRow: CSS.Properties = {
  textAlign: "center",
  fontWeight: "400",
  verticalAlign: "middle",
};
