import React, { useEffect, useState } from "react";
import { MagazzinoResponse } from "../../../../interfaces/magazzino";
import Pagination from "../../../../components/Pagination/pagination";
import { TableHeaderAtelier } from "./table/TableHeaderAtelier";
import { TableBodyAtelier } from "./table/TableBodyAtelier";
import ConfigCapone from "../../../../utils/ConfigCapone";
import "./css/grigliaStyle.css";
import { ModalMagazzinoGrigliaAtelier } from "./ModalMagazzinoGrigliaAtelier";
import { TouchableOpacity } from "react-native";
import { Image } from "react-native-elements";
import { stylesMagazzino } from "../../../../GeneralStyles/MagazzinoStyle";
import { useUserPermission } from "../../../../hooks/useUserPermission";
import { useWidth } from "../../../../core/hook/useWidth";
import { MagazzinoAtelierResponse } from "../interfaces/IMagazzinoAtelier";

interface Props {
  data: MagazzinoAtelierResponse[];
  confirmToDelete: any;
  goToSchedaProdotto: any;
  goToSchedaQuadrante: any;
  dataCrm: any;
  goToMatricola: any;
  crmSend: any;
  paginationList: any;
  currentPage: any;
  setcurrentPage: any;
  limit: any;
  setResetCurrentPage: any;
  griglia: boolean;
  heightRisize: string;
}

export const TableMagazzinoAtelier = ({
  data,
  confirmToDelete,
  goToSchedaProdotto,
  goToSchedaQuadrante,
  dataCrm,
  goToMatricola,
  crmSend,
  paginationList,
  currentPage,
  setcurrentPage,
  limit,
  setResetCurrentPage,
  griglia,
  heightRisize
}: Props) => {
  const [modalOpen, setmodalOpen] = useState(false);
  const [uri, seturi] = useState(``);
  const [title, settitle] = useState(``);
  const [imageArrayList, setImageArrayList] = useState<any>([]);
  const [newData, setNewData] = useState<any>([]);

  const loadFiles = (files) => {
    if(files){
      if(files.startsWith("[")){
        const jsonFiles = JSON.parse(files);
        setImageArrayList(jsonFiles)
      }else{
        let  filesName = [];
        const finishIndex = files.lastIndexOf(".");
        const extension = files.slice(finishIndex + 1);

        let currentFile = ""

        if(extension.toLowerCase() == 'png' || extension.toLowerCase() == 'gif' || extension.toLowerCase() == 'jfif' 
           || extension.toLowerCase() == 'pjp' || extension.toLowerCase() == 'jpg' || extension.toLowerCase() == 'pjpeg' || extension.toLowerCase() == 'jpeg'){
          currentFile = 'image'
        }else if (extension.toLowerCase() == 'pdf'){
          currentFile = 'application'
        }else{
          currentFile = 'application'
        }
        filesName.push({ id: 1, name: files, type: currentFile + '/' + extension });
        setImageArrayList(filesName)
      }
    }
}

  const { isAdmin, isMarketing } = useUserPermission()

  function handleImageError(event) {
    event.currentTarget.src = require("../../../../assets/icons/defaultImage2.png");
  }

  const { width } = useWidth()

  const customData = (data: any) => {
    const result = data.map((element: any) => {
      if(element.fileName && element.fileName.startsWith("[")){
        const imageFiles = JSON.parse(element.fileName);
        let img = imageFiles.find((x: any) => x.type.includes("image"));
        if (img) {
          element.fileNameCustom = img.name;
        }
      } 
      return { ...element }; 
    });
    setNewData(result);
  };
  

  useEffect(() => {
    if(data){
      customData(data)
    }
  }, [data])
  

  return (
    <div className="col-12">

      {griglia ?
        <div className="card border-0" style={{ overflowX: 'hidden', maxHeight: heightRisize , scrollbarWidth: 'thin'}}>
          <div className="row d-flex justify-content-start " style={{ padding: '.5rem .8rem', }}>
            <ModalMagazzinoGrigliaAtelier
              setmodalOpen={setmodalOpen}
              modalOpen={modalOpen}
              uri={uri}
              seturi={seturi}
              settitle={settitle}
              title={title}
            />
            {newData
              ? newData.map((D, index) => {
                return (
                  <div className="card border-0 my-3 text-center headC col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3" style={{ overflowX: 'hidden' }}>
                    <TouchableOpacity>
                      <div className="row d-flex justify-content-center " style={{ overflowX: 'hidden' }}>
                        <img
                          src={`${ConfigCapone.apicapone}/image/${D.fileNameCustom}`} 
                          alt="..."
                          style={{width:'180px'}}
                          className="col-11 img"
                          onError={handleImageError}
                          onClick={() => {
                            setmodalOpen(true);
                            seturi(
                              `${ConfigCapone.apicapone}/image/${D.fileNameCustom}`
                            );
                            settitle(`${D.referencia} ${D.desc_brand}`);
                          }}
                        />
                        <div className="col-1 icons" id="icons" style={{ marginRight: width < 1652 ? 10 : 0, }} >
                          {(isAdmin || isMarketing) && (
                            <img
                              src={require("../../../../assets/icons/icon-delete.png")}
                              onClick={() =>
                                confirmToDelete(D.idReference, D.idMagazzino)
                              }
                              alt="..."
                              className="icon"
                            />
                          )}
                          <img
                            src={require("../../../../assets/icons/sticky-note.png")}
                            alt="..."
                            className="icon"
                            onClick={() =>
                              goToSchedaProdotto(data[index], "open")
                            }
                          />
                          <img
                            src={require("../../../../assets/icons/info-black.png")}
                            alt="..."
                            className="icon"
                            onClick={() =>
                              goToSchedaQuadrante(data[index], "open")
                            }
                          />
                        </div>
                      </div>
                    </TouchableOpacity>
                    <div className="card-body card2">
                      <p>
                        {D.referencia} {D.desc_brand}
                      </p>
                      <p>{D.desc_famiglia}</p>
                      <p>
                        <b>Qt:</b> {D.quantity}
                      </p>
                      <p>
                        <b>Messi da parte:</b> {D.putAsideCount}
                      </p>
                      <p>{D.desc_bussines}</p>
                      <p>€{D.prezzo}</p>
                    </div>
                  </div>
                );
              })
              : null}
          </div> 
        </div> :
        <div className="table-responsive" style={{maxHeight: heightRisize , scrollbarWidth: 'thin'}}>
          <table className="table table-borderless mt-3">

            <TableHeaderAtelier />

            {data?.length > 0 &&
              <TableBodyAtelier
                data={data}
                confirmToDelete={confirmToDelete}
                goToSchedaProdotto={goToSchedaProdotto}
                goToSchedaQuadrante={goToSchedaQuadrante}
                dataCrm={dataCrm}
                goToMatricola={goToMatricola}
                crmSend={crmSend}
              />
            }


          </table>
         

        </div>}

    </div>
  );
};
