import {
    Modal,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import { HeaderModal } from "../HeaderModal";
import { stylesModal } from "../../theme/ModalStyles";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import ConfigCapone from "../../utils/ConfigCapone";
import { CheckLabel } from "../CheckLabel";
import { UserContext } from "../../context/UserContext";
import Loading from '../Loading';
import Cookies from 'universal-cookie';
interface IModalOptionSpostamentoNegozio {
    modalVisible: boolean;
    idReference?: string;
    dataMatricola?: any;
    idMatricola?: string;
    matricola?: string;
    idBrand?: string;
    idFamiglia?:string;
    misuraQuadrante?:string;
    coloreQuadrante?:string;
    tipoDiMovimento?:string;
    idNegozio?: string;
    prezzo?:string;
    fileName?:string;
    typeMatricola?: string;
    setModalVisible: any;
    setModalSchedaProdotto?: any;
    activeButton:boolean;
    updateStateMatricole?: any;
    goToSchedaProdotto?: any;
    referencia?:any;
    findReference?:any;
    setFindReference?:any;
    idR?:any;
    setIdR?:any;
    valueOldReference?:any;
    brand?:string;
    desc_famiglia?:string;
}

const ModalOptionSpostamentoNegozio = ({
    modalVisible = false,
    idReference,
    dataMatricola,
    idMatricola,
    matricola,
    idBrand,
    idNegozio,
    prezzo,
    idFamiglia,
    misuraQuadrante,
    coloreQuadrante,
    tipoDiMovimento,
    fileName,
    desc_famiglia,
    typeMatricola,
    setModalVisible,
    setModalSchedaProdotto,
    activeButton = true,
    updateStateMatricole,
    goToSchedaProdotto,
    referencia,
    findReference,
    setFindReference,
    idR,
    setIdR,
    valueOldReference,
    brand
}: IModalOptionSpostamentoNegozio) => { 
    const [apiBusiness, setApiBusiness] = useState([]);
    const [activeSpinner, setActiveSpinner] = useState(false);
    const [checked, setCheckBoxChecked] = useState(false);
    const [selectedBusiness, setSelectedBusiness] = useState({});
    const { userInLogin } = useContext(UserContext)
    const [first, setfirst] = useState({})
    const [neg, setNeg] = useState<string>();
    const [newIdRecerence, setNewIdRecerence] = useState<any>();
    const [resultIdRecerence, setResultNewIdRecerence] = useState<any>();
    const [bussinesOrigen, setBussinesOrigen] = useState<number>();
    let origenBuss :any;
    //cookie
    const cookies = new Cookies();
    const selectBussiness = async () => {
        // setActiveSpinner(true);
        await axios
          .get(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`)
          .then((response) => {
            let data = response.data.response?.filter( x => x.idBussines != 9);
            setApiBusiness(data)            
          })
          .catch((err) => console.log(err));
        // setActiveSpinner(false);
    };

    const changeListBusiness = (value: any) => {
        console.log("e",value);
        console.log("id", idReference);
        console.log("IdMatricola", idMatricola);
        console.log("matricola", matricola);
        
        
        setCheckBoxChecked(value);
        setNeg(value);
    }

    let idReferen:any;

    const TransferNegozzio = async(idMatricola: string,sender:string, receiver:any, idUser:any) =>{
        
        const idReceiver = receiver.split("Business");

        const newTransferNegozzio = {                
            idBussinessSender:  sender,
            idBussinessReceiver : idReceiver[1] ,
            idMatricola: idMatricola,
            DataTransferimento: new Date(),
            idUser : idUser,
        };
        console.log("aaa",newTransferNegozzio.idBussinessSender)
        console.log("aaaReceiver",newTransferNegozzio.idBussinessReceiver)
        origenBuss=Number(newTransferNegozzio.idBussinessSender)
        await axios
        .post(`${ConfigCapone.urlapi}/api/Transfer`, newTransferNegozzio)
        .then((response) => {   
            console.log("resp:",response);                                 
                   
        })
        .catch((error) =>{    
  
        })
    }


    const addReferenzia = async(receiver:any) => {

        const idReceiver = receiver.split("Business");

        const newReferenzia:any = {                
            idBrand: idBrand ,
            idBussines: idReceiver[1] ,
            Referencia: referencia,
            Prezzo: prezzo,
            idFamiglia:idFamiglia,
            MisuraQuadrante:misuraQuadrante,
            ColoreQuadrante:coloreQuadrante,
            tipoDiMovimento:tipoDiMovimento,
            fileName:fileName,
            DateInsert : new Date(),
            DateUdate : new Date()
        };
        console.log("referenza",newReferenzia)
        await axios
            .post(`${ConfigCapone.urlapi}/api/Referenzia`, newReferenzia,{
                headers: {
                  'Authorization': `Bearer ${cookies.get('token')}`
                }
              })
            .then((response) => {                          
                const id = response.data.id  
                addMatricola(id, idReceiver[1] , false);                
            })
            .catch((error) => {
                // setActiveSpinner(false);
                console.log("error1");      
            }); 
        

    }
   

    const addMatricola:any = async (idReference:any, idNegozio:any, transfer:boolean) =>{

        let newArray = dataMatricola.filter( (data:any) => data.idMatricola = idMatricola);
       
        let state = false;

        console.log("idM:", idMatricola);
        console.log("idR", idReference);
        
        
        console.log("rr", newArray);
        
        console.log("matricola", dataMatricola);
        
        

        if(transfer){

            let negozio = idNegozio.split("Business");
           
            let newMatricola:any = {
                idReferenzia: idReference,
                idNegozio : negozio[1],
                Matricola : matricola           
            }

            await axios
            .post(`${ConfigCapone.urlapi}/api/Matricola/`, newMatricola,{
                headers: {
                  'Authorization': `Bearer ${cookies.get('token')}`
                }
              })
            .then((response) => {                                
                 console.log(response);
                 state= true;                                               
            })
            .catch((error) =>{    
                console.log(error);                            
            })

        }else{
   
            let newMatricolaCreate:any = {
                idReferenzia: idReference,
                idNegozio : idNegozio,
                Matricola : matricola   
            }

            await axios
            .post(`${ConfigCapone.urlapi}/api/Matricola/`, newMatricolaCreate,{
                headers: {
                  'Authorization': `Bearer ${cookies.get('token')}`
                }
              })
            .then((response) => {                 
                state = false;               
                                                            
            })
            .catch((error) =>{  
                console.log("No");
                  
                console.log("error2");            
            })

        }

        if(state){
            let data = "yes-"+idReference
            return data
        }else{
            addMagazzino(idReference, idNegozio)     
        }
        
    }

    const addMagazzino = async (idReferenzia:number, idNegozzio:number)=>{
        
        const newMagazzino = { 
            idUser: userInLogin.idUser,    
            idBussines: idNegozzio,
            idReferenzia: idReferenzia,
            Quantity : 1
        };
    
        await axios
        .post(`${ConfigCapone.urlapi}/api/Magazzino`, newMagazzino,{
            headers: {
              'Authorization': `Bearer ${cookies.get('token')}`
            }
          })
          .then((response) => {
              console.log("success");            
          })
          .catch((error) => {
              console.log("error3");             
            //   setActiveSpinner(false);          
          });

    }

    const checkExistingReferenza:any = async () =>{

        let idReferenzia:any;
        if(checked){
            let idNegozio:any = neg?.split("Business");
            let state = "no";

            if(idNegozio){
                await axios
                // .get(`${ConfigCapone.urlapi}/api/Referenzia/FindByReferencia/${referencia}/${idReference}/${idNegozio[1]}`,{
                //     headers: {
                //       'Authorization': `Bearer ${cookies.get('token')}`
                //     }
                //   })
                .get(`${ConfigCapone.urlapi}/api/Referenzia/FindByReferencia`,{
                    params: {
                        name: referencia,
                        id: idReference,
                        idNegozio: idNegozio[1]
                    }
                  })
                .then((response) => {       
                    if(response.data.status!="empty"){
                        setfirst(response.data.response);   
                        idReferenzia = response.data.response.idReference;
                        
                        state = "yes";
                        console.log(state);
                    }else{   
                        state = "no";
                        console.log(state);
                    }
                })
                .catch((error) =>{    
                    console.log("error");
                })
            }

            let data = state + "-" + idReferenzia;

            console.log("newData:",data);
            

            if(state){               
                sellToBusiness(data);
            }else{
                sellToBusiness(data);
            }


        }
    }

    const sellToBusiness = async(state:string) => {

        setActiveSpinner(true)

        let data:any = state?.split("-")

        if(data[0]=="yes"){    
            console.log("Transfer a negozio")          

            let value:any = await addMatricola(data[1], checked, true)
            let idOldReference = value?.split("-")

            let result:any = await valueOldReference(idOldReference[1])
            console.log("old:", idOldReference[1]);

            if(TransferNegozzio(idMatricola,idNegozio, checked, userInLogin.idUser)){
             
                await updateStateMatricole(idMatricola, true, typeMatricola, 0, idOldReference[1]);
                 
            }

    
        }else{

            console.log("Create negozio 2"); 
            let result:any = await valueOldReference(null)

            await addReferenzia(checked)
           
            if(TransferNegozzio(idMatricola,idNegozio, checked, userInLogin.idUser)){
                await updateStateMatricole(idMatricola, true, typeMatricola, 0);
               
            }
            
        }

        await sendEmail("Spostamento negozio",idMatricola)
        setModalVisible(false);
        setActiveSpinner(false)
        goToSchedaProdotto();
        //redirect to cerca prodotto
        setModalSchedaProdotto(false);
    }

    const sendEmail = async(type:string, id:any) => {
        let mat :any;
        console.log("negOrigen",origenBuss)
        let negociOrigen =apiBusiness.find( data => data.idBussines == origenBuss)
        let date = dateTimeNow();
        let idNegozio:any = neg?.split("Business");
        let descriptionNegozio = apiBusiness.find( data => data.idBussines == idNegozio[1] )
        await axios
        .get(`${ConfigCapone.urlapi}/api/Matricola/${id}`,{
            headers: {
              'Authorization': `Bearer ${cookies.get('token')}`
            }
          })
        .then((response) => {
            mat = response.data.response?.matricola;   
            console.log("online: ", response.data.response);                      
        })
        .catch((error) => {
            console.log("error -> ", error);
            
        });
  
        let emailData = {
          type: "notice",
          email: `${ConfigCapone.emailMarketing}`,
          subject: `${type}`,
          message: `<p> Operazione: ${type}</p>
                   <p> Operatore: ${userInLogin.userName}</p>
                   <p> Referenza: ${referencia} </p>
                   <p> Brand: ${brand}</p>
                   <p> Descrizione:${desc_famiglia}</p>
                   <p> Negozio:  Spostato da ${negociOrigen?.desc_Bussines} a ${descriptionNegozio?.desc_Bussines} </p>
                   <p> Matricola: ${mat}</p>
                   <p> Prezzo: ${prezzo}</p>
                   <p> Data: ${date}</p>`,
        };
   
        await axios
          .post(`${ConfigCapone.apicapone}/api/forma`, emailData)
          .then((res) => {
            console.log(res);
            console.log("info : ", emailData);
          })
          .catch((err) => console.log("there was an error: " + err));
  
      }
  
      const dateTimeNow = (fecha: string = '') => {
        let date
        if (fecha !== '') {
          date = new Date(fecha)
        } else {
          date = new Date()
        }
        const year = date.getFullYear()
        const month =
          date.getMonth() + 1 < 10
            ? `0${date.getMonth() + 1}`
            : date.getMonth() + 1
        const day = date.getDate().toString().padStart(2, "0")
        const hour = date.getHours().toString().padStart(2, "0")
        const minute = date.getMinutes().toString().padStart(2, "0")
        const seconds = date.getSeconds().toString().padStart(2, "0")
        const dateNow =
          day +
          '/' +
          month +
          '/' +
          year +
          ', ore ' +
          hour +
          ':' +
          minute +
          ':' +
          seconds
    
        return dateNow
      }

    useEffect(() => {     
        selectBussiness();
        setCheckBoxChecked(false);
    }, [])

    useEffect(() => {     
        setCheckBoxChecked(false);
    }, [modalVisible])

    return (
        <View >
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <ScrollView style={{width: "100%", height: "100%"}}> 
                    {activeSpinner && <Loading activeSpinner={activeSpinner} />}
                    <View
                    style={[
                        stylesModal.centeredView,
                        { paddingTop: 150, paddingBottom: 150 },
                    ]}
                    >
                        <View style={stylesModal.modalView}>
                            <HeaderModal
                            setModalVisible={setModalVisible}
                            modalVisible={modalVisible}
                            title={"Seleziona il negozio di destinazione"}
                            activatedPadLeft={true}
                            />
                            <div className="row w-100">
                                 <div className="col-12 d-flex justify-content-center my-5">
                                    <div className="row">
                                        <div className="col-12 px-5">
                                            {apiBusiness.map((d: any, index) => ( 
                                                (d.idBussines != idNegozio &&
                                                    <div key={index}>                                                                    
                                                        <CheckLabel                                                    
                                                            name={d.desc_Bussines}
                                                            id={'Business'+d.idBussines}
                                                            checked={checked} 
                                                            onSelected={()=>setSelectedBusiness(d)}
                                                            onChange={(value:any) => changeListBusiness(value)}    
                                                            type="text"
                                                            text={d.desc_Bussines}
                                                            marginleft={true}
                                                        />  
                                                        <br />                                               
                                                    </div>  
                                                )                                                                     
                                            ))}    
                                        </div>  
                                        <div className="col-12 d-flex justify-content-center mt-4" style={{textAlign:"center"}}>
                                                <TouchableOpacity
                                                    onPress={() => checkExistingReferenza()}
                                                    disabled={!checked}
                                                >
                                                <View style={[stylesModalOptionSpostamentoNegozio.buttonWhite]}>
                                                    <Text style={stylesModalOptionSpostamentoNegozio.buttonTextWhite}>
                                                    {"Conferma"}
                                                    </Text>
                                                </View>
                                            </TouchableOpacity>
                                        </div>           
                                    </div>

                                   
                                 </div>
                            </div>
                        </View>
                    </View>
                </ScrollView>
            </Modal>
        </View>
    )
}

export default ModalOptionSpostamentoNegozio;

const stylesModalOptionSpostamentoNegozio = StyleSheet.create({
    buttonTextWhite: {
        color: "black",
        alignSelf: "center",
        fontWeight: "500",
        fontSize: 18,
    },
    buttonWhite: {
        backgroundColor: "white",
        justifyContent: "center",
        borderRadius: 50,
        width: 130,
        height: 40,
        borderWidth: 1,
        borderColor: "black",
    },
});

