import { useNavigation } from "@react-navigation/native";
import { NavigationProps } from "../../navigator/NavigatorConfig";
import { BsCheck2Circle } from "react-icons/bs";
import { useState } from "react";
import ModalTaskChecked from "./ModalTaskChecked";
import PopupConfirmation from "../PopupConfirmation";
import { FaRegEdit } from "react-icons/fa";
import ModalTaskForm from "./ModalTaskForm";
import PopupConfirmationCustom from "../PopupConfirmationCustom";
import { TaskList } from "./interfaces/TaskList";
import "../../styles/ChecBoxCustom.css";
interface Props {
  title: string;
  detail: String;
  nameFile: String;
  id: number;
  items: TaskList[];
  listIdTaskBussinesView: [];
  GetListByIdTaskBussines;
  GetListByIdTaskBussinesCheck;
  idTaskBussines: number;
  idTaskBussinesUsers: number;
  idUser: number;
  confirmviewTask;
  isCheckedRegister;
  idTaskUser: number;
  setIsOpenFormEdit;
  isOpenFormEdit: boolean;
  setDataEdit;
  defaultListTask;
  idRole;
  setIsOpenFormEditDisabled;
  isOpenFormEditDisabled;
  nameImage;
  dateInsert: Date;
}
export const TasksItem = ({
  detail,
  idRole,
  defaultListTask,
  title,
  id,
  nameFile,
  items,
  listIdTaskBussinesView,
  GetListByIdTaskBussines,
  GetListByIdTaskBussinesCheck,
  idTaskUser,
  setDataEdit,
  idTaskBussines,
  idTaskBussinesUsers,
  idUser,
  confirmviewTask,
  isCheckedRegister,
  setIsOpenFormEdit,
  isOpenFormEdit,
  setIsOpenFormEditDisabled,
  isOpenFormEditDisabled,
  nameImage,
  dateInsert
}: Props) => {
  const navigation = useNavigation<NavigationProps>();
  const [isOpen, setIsOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [valueEditing, setValueEditing] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const actionMod = async () => {
    await confirmviewTask(valueEditing);
    setConfirmOpen(!ConfirmOpen);
  };
  const cancelMod = () => {
    setIsChecked(!isChecked);
    setValueEditing({});
  };

  const viewIsChecked = async (idTaskBussines, idTaskBussinesUsers) => {
    let data = {
      idTask: id,
      idTaskBussines: id,
      idTaskBussinesUsers: idTaskBussinesUsers,
      idTaskUser: idTaskUser
    };

    setIsOpen(!isOpen);
    GetListByIdTaskBussines(data);
  };
  const formEditOpen = async (data) => {
    await defaultListTask(data);
    setIsOpenFormEdit(!isOpenFormEdit);
    let newEdit = {
      id: id,
      title: title,
      detail: detail,
      nameFile: nameFile,
      nameImage: nameImage
    };
    setDataEdit(newEdit);
  };

  const formEditOpenDisabled = async (data) => {
    await defaultListTask(data);
    setIsOpenFormEditDisabled(!isOpenFormEditDisabled);
    let newEdit = {
      id: id,
      title: title,
      detail: detail,
      nameFile: nameFile,
      nameImage: nameImage
    };
    setDataEdit(newEdit);
  };

  const handleClick = (
    idTaskBussinesUsers,
    idUser,
    idTaskBussines,
    idTaskUser
  ) => {
    setValueEditing({
      idTaskBussinesUsers,
      idUser,
      idTaskBussines,
      idTaskUser
    });
    //setIsChecked({ ...isChecked, [idNew]: checked });
    setConfirmOpen(!ConfirmOpen);
    //setIsChecked(!isChecked);
    setConfirmOpen(!ConfirmOpen);
    setIsChecked(!isChecked);
  };

  return (
    <div key={id} className="row w-100">
      <div className="col-12 ">
        <div className="d-flex align-items-center justify-content-between flex-row text-capitalize gap-3 mb-2">
          <div className="d-flex align-items-center gap-3">
            <div>
              {isCheckedRegister == 0 && (
                <div className="checkbox-wrapper-33">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      style={{ transform: "scale(1.6)", cursor: "pointer" }}
                      className=""
                      checked={isChecked}
                      onClick={() =>
                        handleClick(
                          idTaskBussinesUsers,
                          idUser,
                          idTaskBussines,
                          idTaskUser
                        )
                      }
                    />
                  </label>
                </div>
              )}
            </div>
            <h3
              className="mb-0 "
              style={{ cursor: "pointer", fontSize: ".7em" }}
              onClick={() => formEditOpenDisabled(id)}
            >
              {title}
            </h3>
          </div>
          <div className="d-flex">
            {/* <span className="fs-6">{detail}</span> */}
            {(idRole == 3 || idRole == 4 || idRole == 7 || idRole == 8 || idRole == 9) && (
              <div className="">
                <FaRegEdit size={20} style={{ 'marginRight': '1em', cursor: "pointer" }} onClick={() => formEditOpenDisabled(id)} />
              </div>
            )}
            <div className="">
              <BsCheck2Circle size={20} onClick={() => viewIsChecked(idTaskBussines, idTaskBussinesUsers)} style={{ cursor: "pointer" }} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-3 d-flex justify-content-end ">
        {(idRole == 2 || idRole == 6 || idRole == 5 || idRole == 9) && (
          <div className="d-flex justify-content-center mt-2">
            <FaRegEdit size={28} style={{ 'marginRight': '1em', cursor: "pointer" }} onClick={() => formEditOpen(id)} />
          </div>
        )} */}

      {/* { (idRole == 3 || idRole == 4 || idRole == 7 || idRole == 8 || idRole == 9) && (
          <div className="d-flex justify-content-center mt-2">
            <FaRegEdit size={28} style={{ 'marginRight': '1em', cursor: "pointer" }} onClick={() => formEditOpenDisabled (id)} />
          </div>
        )} */}

      {/* 
        <div className="d-flex justify-content-center mt-2">
          <BsCheck2Circle size={28} onClick={() => viewIsChecked(idTaskBussines, idTaskBussinesUsers)} style={{ cursor: "pointer" }} />
        </div>
      </div> */}

      <ModalTaskChecked
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        listIdTaskBussinesView={listIdTaskBussinesView}
      />
      {
        ConfirmOpen && (
          <PopupConfirmationCustom
            cancelAction={() => cancelMod()}
            title={""}
            message={"Cliccando sulla spunta confermi di aver eseguito il task"}
            confirmAction={() => actionMod()}
            stateModal={ConfirmOpen}
            setStateModal={setConfirmOpen}
          />
        )
      }
    </div >
  );
};
