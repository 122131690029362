import React from 'react'
import { FlatList, Pressable, View } from 'react-native'
import getCustomerSuggestion from './CustomerSuggestion'

const AutosuggestInputName = (
    { autosuggestInputName, setAutosuggestInputName, setSearchInputName, resultSearch }
) => {
    return (
        <View style={{
            borderWidth: 1,
            marginTop: -15,
            paddingTop: 15,
            marginLeft: 2,
            marginRight: 2,
            borderTopWidth: 0,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
        }}>
            <FlatList
                data={autosuggestInputName}
                showsVerticalScrollIndicator={false}
                renderItem={({ item, index }) => (
                    <Pressable
                        style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }]}
                        onPress={() => {
                            setSearchInputName(item.name),
                                setAutosuggestInputName([]),
                                resultSearch(item)
                        }
                        }
                    >
                        {getCustomerSuggestion(item)}
                    </Pressable>
                )}
                keyExtractor={(item, index) => item.idClient + index}
            />
        </View>
    )
}

export default AutosuggestInputName