import { FormikProvider } from "formik";
import { useEditEvent } from "../../hooks/useEditEvent";
import { AddEvent, SelectValues } from "../../interfaces/calender";
import { AlertCustom } from "../AlertCustom";
import { ConfirmDelete } from "./ConfirmDelete";
import { FooterForm } from "./FooterForm";
import { InputCustom } from "./InputCustom";
import { SelectCustom } from "./SelectCustom";
import { TextTareaCustom } from "./TextTareaCustom";
import { useState, useEffect } from "react";
import ConfigCapone from "../../utils/ConfigCapone";
import { getImage } from "../../services/calender";

import './calendar.css'

interface Props {
  closeModal: () => void;
  handleEditEvent: (event: AddEvent) => void;
  handleDeleteEvent: (codigo: string) => void;
  options: SelectValues[];
  event: AddEvent;
}
export const EditEvent = ({
  closeModal,
  handleEditEvent,
  handleDeleteEvent,
  event,
  options,
}: Props) => {
  const {
    formik,
    isAdmin,
    loading,
    response,
    confirmDelete,
    permitedActions,
    handleDelete,
    handleClear,
    handleCancelDelete,
    handleConfirmDelete,
    defultfFileSelected,
    selectedFile,
    nameImgSelected,
    selectedImage,
    nameImgUpdate,
    uploadFiles,
    nameFileSelected,
    nameFileUpdate,
    removeFile
  } = useEditEvent({
    handleEditEvent,
    handleDeleteEvent,
    item: event,
    closeModal
  });

  const [imgUrl, setImgUrl] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [namePdf, setNamePdf] = useState("")
  

  
const changeImage = async () =>{
  if (nameImgUpdate != null) {
   
    const parts = nameImgUpdate.split('.');
    const name = parts[0]; // Nombre de la imagen
    const extension = parts[1]; // Extensión
    var url = await getImage(name,extension)
      
      if(url?.status == 200){
        
        if (extension !== "pdf") {
          setImgUrl(`${ConfigCapone.urlapi}/api/UploadImage/${name}/${extension}`)
        }
        
      } else {
        setImgUrl("")
      }
    }

    if (nameFileUpdate != null) {
        
        const parts = nameFileUpdate.split('.');
        const name = parts[0]; // Nombre de la imagen
        const extension = parts[1]; // Extensión
        var url = await getImage(name,extension)
       
        if(url?.status == 200){
          
          if (extension == "pdf") {
            setNamePdf(`${name}.${extension}`)
            setPdfUrl(`${ConfigCapone.urlapi}/api/UploadImage/${name}/${extension}`)
          }
          
        } else {
          setPdfUrl("")
        }

      }


}
  useEffect(() => {
    changeImage();
    console.log(nameImgUpdate)
  }, [nameImgUpdate, nameFileUpdate])
  

  console.log("nameImgSelected",nameImgSelected)
  

  return (
    <>
      {response && (
        <AlertCustom
          detail={response.detail}
          handleClear={handleClear}
          status={response.status}
          title={response.title}
        />
      )}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <InputCustom
            {...formik.getFieldProps("date")}
            id="date"
            label="Data dell'evento"
            placeholder="Data dell'evento"
            type="date"
          />
          <InputCustom
            {...formik.getFieldProps("start")}
            id="init"
            label="Inizio dell'evento"
            placeholder="Inizio dell'evento"
            type="time"
            disabled={formik.values.date === ""}
          />
          <InputCustom
            {...formik.getFieldProps("end")}
            id="init"
            label="Fine dell'evento"
            placeholder="Fine dell'evento"
            type="time"
            disabled={formik.values.date === ""}
          />
          <InputCustom
            {...formik.getFieldProps("title")}
            id="aggiungi"
            label="Aggiungi un titolo"
            placeholder="Aggiungi un titolo"
            type="text"
          />
          <SelectCustom
            {...formik.getFieldProps("bussines")}
            id="negozio"
            label="Negozio"
            placeholder="Negozio"
            options={options}
            disabled={!isAdmin}
          />
          <TextTareaCustom
            {...formik.getFieldProps("details")}
            id="particolari"
            label="Note"
            placeholder="Note"
            rows={5}
          />

          <div className='form-group mb-4'>
            {imgUrl &&  <img className="container-img-calendar" src={imgUrl} alt="" />}
           
            
            <label htmlFor="document-files" style={{ cursor: "pointer" }}>
              <img src={require("../../assets/icons/link.png")} style={
                {
                  width: 25,
                  cursor: "pointer",
                  height: 25,
                  position: "absolute",
                  right: 30
                }
              } alt="" />
            </label>
            <input
              type="file"
              id="document-files"
              accept="application/pdf"
              style={{ display: "none" }}
              // {...formik.getFieldProps("fileName")}
              onChange={(e) => uploadFiles(e.target.files, "document")}
            />
            <label htmlFor="image-files" style={{ cursor: "pointer" }}>
              <img src={require("../../assets/icons/camera2.png")} style={
                {
                  width: 25,
                  cursor: "pointer",
                  height: 25,
                  //marginHorizontal: 20,
                  //tintColor: "#b3b3b3",
                  position: "absolute",
                  right: 70
                }
              } alt="" />
            </label>
            <input
              type="file"
              name="image-files"
              id="image-files"
              accept="image/png, image/gif, image/jpeg"
              style={{ display: "none" }}
              {...formik.getFieldProps("fileCalendar")}
              onChange={(e) => uploadFiles(e.target.files, "image")}
            />
          </div>
          {pdfUrl && (
            <div className='form-group mb-2 w-100 text-center'>
              <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download="archivo.pdf"> 
                {namePdf}
              </a>
            </div>)}
          <div className='form-group mb-2 w-100 text-center'>
            {selectedImage ? (
              <p>
                <b>· Selezionato:</b> {selectedImage}
              </p>
            ) : null}
            {nameFileSelected ? (
              <p>
                <b>· Selezionato:</b> {nameFileSelected}
              </p>
            ) : null}
            {/* {defultfFileSelected && (
              <a
                onClick={() => removeFile()}
                style={{ cursor: "pointer" }}
                className="text-danger"
              >
                rimuovere {defultfFileSelected}
              </a>
            )} */}
          </div>

          <FooterForm
            loading={loading}
            disabled={loading || formik.isSubmitting}
            btnCancel="Rimuovere"
            btnSubmit="Aggiornare"
            closeModal={closeModal}
            classBtn="danger"
            deleteEvent={handleConfirmDelete}
            actions={permitedActions}
          />
        </form>
      </FormikProvider>
      <ConfirmDelete
        show={confirmDelete}
        handleDelete={handleDelete}
        handleCancelDelete={handleCancelDelete}
        response={response}
        closeModal={closeModal}
      />
    </>
  );
};