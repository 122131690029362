import { IconType } from 'react-icons'
import { AiOutlineCheck } from 'react-icons/ai'
import { BiCommentDetail } from 'react-icons/bi'
import { FaUserCheck } from 'react-icons/fa'
import { IoSettingsSharp } from 'react-icons/io5'

interface Item {
  title: string;
  Icon: IconType;
  id: number;
}
export const items: Item[] = [
  {
    title: 'Dati anagrafici cliente',
    Icon: FaUserCheck,
    id: 0
  },
  {
    title: 'Informazioni riparazione',
    Icon: IoSettingsSharp,
    id: 1
  },
  {
    title: 'Aggiuntivo',
    Icon: BiCommentDetail,
    id: 2
  },
  {
    title: 'Per finalizzare',
    Icon: AiOutlineCheck,
    id: 3
  }
]
