import { BsBellFill, BsBellSlashFill } from "react-icons/bs";

interface Props {
  notify: number;
}
export const HeaderNotify = ({ notify }: Props) => {
  return (
    <div className="d-flex justify-content-start align-items-center fw-bold w-100 gap-2">
      {notify !== 0 && (
        <div
          className=""
          style={{
            position: "relative"
          }}
        >
          <BsBellFill className="" size={25} color="#db4444" />
          <span
            style={{
              background: "#db4444",
              color: "#fff",
              padding: "0",            
              borderRadius: "50px",
              display: "block",
              position: "absolute",
              top: -19,
              right: -16,
              lineHeight: "1rem",
              textAlign: "center",
              width: 'auto',
              height: 23,
              paddingTop: 3,
              paddingLeft: 3,
              paddingRight: 3,
              paddingBottom:4
            }}
          >
            {notify.toString().padStart(2, "0")}
          </span>
        </div>
      )}
      {notify === 0 && (
        <BsBellSlashFill className="me-2" size={25} color="#db4444" />
      )}
      <span>Notifiche</span>
    </div>
  );
};
