import { IconType } from "react-icons";

interface Props {
  Icon: IconType;
  title: string;
  current: boolean;
  done: boolean;
}
export const ListItem = ({ Icon, title, current, done }: Props) => {
  return (
    <li
      role="tab"
      className={`${current ? "current" : done ? "done" : "disabled"} `}
    >
      <a href="javascript:void(0);">
        <i className="icon-react-custom">
          <Icon />
        </i>
        {title}
      </a>
    </li>
  );
};
