import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useEffect, useState } from "react";
import {
  Modal,
  Text,
  View,
  TextInput,
  Image,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { HeaderModal } from "../components/HeaderModal";
import { styles } from "../theme/GlobalStyle";
import { stylesModal } from "../theme/ModalStyles";
import { useIsFocused } from "@react-navigation/native";
import axios from "axios";
import { SectionLoading } from "../components/SectionLoading";
import { Controller, useForm } from "react-hook-form";
import PopupMessage from "../components/PopupMessage";
import { getUserFromId, IUser, IClient } from "../api/ClientService";
import ConfigCapone from "../utils/ConfigCapone";
import { CheckLabel } from "../components/CheckLabel";
interface Props extends NativeStackScreenProps<any, any> { }
export const SendEmailScreen = ({
  modalVisible,
  setModalVisible,
  clientData,
  userList,
}: any) => {
  const [backColorSelect, setBackColorSelect] = useState("white");
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [hasPreventive, setHasPreventive] = useState(true);
  const [fReader, setFReader] = useState(null);
  const [nameImgSelected, setNameImgSelected] = useState("");
  const [apiBussiness, setApiBussiness] = useState([]);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [typeFile, setTypeFile] = useState<any>();
  const [dataTecnico, setDataTecnico] = useState<any>();
  const [isEmptyBussinesTecnico, setIsEmptyBussinesTecnico] = useState(false);

  async function getPreventive() {
    await axios
      .get(
        `${ConfigCapone.urlapi}/api/Payment?value=${clientData.idService}&parameter=idService`
      )
      .then((resp) => {
        if (resp.data.response.length > 0) {
          setHasPreventive(false);
          setValue(
            "subject",
            `Messaggio da Assistenza N. preventivo: ${resp.data.response[0].nroPreventive} - N. busta: ${clientData.envelopeNumber == null ? '' : clientData.envelopeNumber}`
          );
        } else {
          setValue(
            "subject",
            `Messaggio da Assistenza N. preventivo:   - N. busta: ${clientData.envelopeNumber == null ? '' : clientData.envelopeNumber}`
          );
        }

        axios.get(
          `${ConfigCapone.urlapi}/api/Client?value=${clientData.idClient}&parameter=idClient`
        )
          .then((response) => {
            let idUser = response.data.response[0].idUser;
            getUserFromId(idUser).then((result: IUser) => {
              let technique = clientData.tecnico != null ? `Tecnico: ${clientData.tecnico}\n` : ''
              let operator = result != null ? `Dipendente: ${result.name}\n` : '';

              setValue("message", `Nome del cliente: ${clientData.name}` +
                `\n` +
                `Data di inserimento: ${clientData.dateService.split("T")[0]
                  .toString()
                  .split("-")
                  .reverse()
                  .join("/")}` +
                `\n` +
                operator +
                technique +
                `Numero busta: ${clientData.envelopeNumber == null ? '' : clientData.envelopeNumber} `);
            });
          }
          );
      })
      .catch((err) => console.log(err));
  }

  const {
    getValues,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      subject: "",
      message: "",
      business: "",
      tecnico: ""
    },
  });
  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });

  const [images, setImages] = useState<any>();

  const uploadFiles = (e: any, type: string) => {
    setImages(e);
    setNameImgSelected(e ? e[0].name : null);
    setTypeFile(type);
    if (type == "image") {
      let image: any = document.querySelector("#image-files-send");
      let imageContainer: any = document.getElementById("img-container-send");
      imageContainer.classList.remove("d-none");

      imageContainer.src = URL.createObjectURL(image.files[0]);
    } else if (type == "document") {
      let imageContainer: any = document.getElementById("img-container-send");
      imageContainer.classList.add("d-none");
    }

    let _fReader = new FileReader();
    _fReader.readAsDataURL(e[0]);

    setFReader(_fReader);
  };
  const selectBussiness = async () => {
    setActiveSpinner(true);
    await axios
      .get(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`)
      .then((response) => {
        setApiBussiness(response.data.response);
      })
      .catch((err) => console.log(err));
    setActiveSpinner(false);
  };

  const selectTencnics = async () => {
    await axios
      .get(`${ConfigCapone.urlapi}/api/User?parameter=1`)
      .then((response) => {
        console.log("selectTencnics: ", response)
        const apiTecnico = response.data.response?.filter((t: any) => parseInt(t.idRole) == 3 || parseInt(t.idRole) == 7 || parseInt(t.idRole) == 8);
        setDataTecnico(apiTecnico);
      });
  }


  const isFocused = useIsFocused();
  useEffect(() => {
    getPreventive();
    selectTencnics();
    selectBussiness();
  }, [isFocused]);

  const sendEmail = async () => {

    let tecnic = getValues("tecnico")
    let bussines = getValues("business")
    let isError: boolean = false;
    let emptyBussinesTecnico: boolean = false;
    let message: string = "";

    if (!tecnic && !bussines) {
      emptyBussinesTecnico = true;
      setIsEmptyBussinesTecnico(true);
      // message 3
    }

    let _uploadFile = true;

    if (nameImgSelected.length == 0) {
      _uploadFile = false;
    }

    if (!emptyBussinesTecnico) {

      setLoading(true);
      setMessageSent(false);

      let users = userList.filter((data: any) => {

        if (parseInt(data.idBussines) == parseInt(getValues("business"))) {
          if (parseInt(data.idRole) != 2) {
            return data;
          }
        }

      });

      if (users.length > 0) {

        for (let i = 0; i < users.length; i++) {

          if (_uploadFile && images && users[i].emailUser) {

            let dataSend = {
              type: "business",
              email: users[i].emailUser == "annamaria@sergiocapone.com" ? "marketing@sergiocapone.com" : users[i].emailUser,
              subject: getValues("subject").replace(/\n/g, '<br>\n'),
              message: getValues("message").replace(/\n/g, '<br>\n'),
              nameImg: images ? images[0].name : null,
              files: fReader.result || null,
            };
            await axios

              .post(
                `${ConfigCapone.apicapone}/api/forma`,
                dataSend
              )
              .then((res) => {
                // setLoading(false);
                // setMessageSent(true);
                console.log(res);
              })
              .catch((err) => {
                // setLoading(false);
                // setModalMessage({
                //   active: true,
                //   message: "E-mail non inviata. Il file non deve essere superiore a 50MB.",
                // });
                isError = true;
                message = "E-mail non inviata. Il file non deve essere superiore a 50MB.";
                console.log("there was an error: " + err);
              });
          } else if (users[i].emailUser) {
            let dataSend = {
              type: "business",
              email: users[i].emailUser == "annamaria@sergiocapone.com" ? "marketing@sergiocapone.com" : users[i].emailUser,
              subject: getValues("subject").replace(/\n/g, '<br>\n'),
              message: getValues("message").replace(/\n/g, '<br>\n'),
            };
            await axios
              .post(
                `${ConfigCapone.apicapone}/api/forma`,
                dataSend
              )
              .then((res) => {
                // setLoading(false);
                // setMessageSent(true);
                console.log(res);
              })
              .catch((err) => {
                isError = true;
                message = "there was an error: " + err;
                console.log("there was an error: " + err)
              });
          } else {
            isError = true;
            message = "Email errata";
            // setLoading(false);
            // setModalMessage({
            //   active: true,
            //   message: "Email errata",
            // });
          }
        }
      } else {
        // setLoading(false);
        // setModalMessage({
        //   active: true,
        //   message: "Non ci sono dipendenti o utenti associati a questo negozio.",
        // });
        if (!tecnic) {
          isError = true;
          message = "Non ci sono dipendenti o utenti associati a questo negozio.";
        }

      }

      if (tecnic) {
        let data = userList?.find((t: any) => t.idUser == tecnic)

        if (_uploadFile && images) {

          let dataSend = {
            type: "business",
            email: data.emailUser == "annamaria@sergiocapone.com" ? "marketing@sergiocapone.com" : data.emailUser,
            subject: getValues("subject").replace(/\n/g, '<br>\n'),
            message: getValues("message").replace(/\n/g, '<br>\n'),
            nameImg: images ? images[0].name : null,
            files: fReader.result || null,
          };
          await axios
            .post(
              `${ConfigCapone.apicapone}/api/forma`,
              dataSend
            )
            .then((res) => {
              // setLoading(false);
              // setMessageSent(true);
              console.log(res);
            })
            .catch((err) => {
              isError = false;
              message = "E-mail non inviata. Il file non deve essere superiore a 50MB.";
              console.log("there was an error: " + err)
            });

        } else {

          let dataSend = {
            type: "business",
            email: data.emailUser == "annamaria@sergiocapone.com" ? "marketing@sergiocapone.com" : data.emailUser,
            subject: getValues("subject").replace(/\n/g, '<br>\n'),
            message: getValues("message").replace(/\n/g, '<br>\n'),
          };
          await axios
            .post(
              `${ConfigCapone.apicapone}/api/forma`,
              dataSend
            )
            .then((res) => {
              // setLoading(false);
              // setMessageSent(true);
              console.log(res);
            })
            .catch((err) => {
              isError = false;
              message = "there was an error: " + err;
              console.log("there was an error: " + err)
            });
        }

      }

      if (!isError) {
        setLoading(false);
        setMessageSent(true);
      } else {
        setLoading(false);
        setModalMessage({
          active: true,
          message: message,
        });
      }
    }

  };
  const removeFile = () => {
    setNameImgSelected("");

    var imageFile: any = document.getElementById("image-files-send");
    var documentFile: any = document.getElementById("document-files-send");

    imageFile.value = null;
    documentFile.value = null;

    let imageContainer: any = document.getElementById("img-container-send");
    imageContainer.classList.add("d-none");
  };
  return (
    <View style={stylesModal.centeredView}>
      <Modal animationType="slide" propagateSwipe={true} transparent={true} visible={modalVisible}>
        <ScrollView style={{ width: "100%", height: "100%" }}>
          <View
            style={[
              stylesModal.centeredView,
              { backgroundColor: "rgba(0,0,0,.32)", paddingTop: 130, paddingBottom: 130 },
            ]}
          >
            <View style={[stylesModal.modalView]}>
              <HeaderModal
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                title={`Invia e-mail ai dipendenti del negozio`}
                activatedPadLeft={true}
              />

              {
                hasPreventive &&
                <span className="mt-3" style={{ color: 'red', marginLeft: -40 }}>Per questa azione è richiesta il preventivo</span>
              }

              {loading && (
                <div style={{ alignSelf: "center" }}>
                  <SectionLoading />
                </div>
              )}
              <div className="row w-100">

                <div className="col-12">
                  <Controller
                    control={control}
                    name="business"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        className="mt-3"
                        style={{
                          width: "100%",
                          backgroundColor: backColorSelect,
                          height: 45,
                          cursor: "pointer",
                          margin: 4,
                          borderWidth: 2,
                          borderRadius: 50,
                          borderColor: "rgb(235,235,235)",
                          paddingLeft: 10,
                          fontSize: 16,
                          marginLeft: -3,
                        }}
                        //onMouseEnter={() => setBackColorSelect("#EAEAEA")}
                        // onMouseLeave={() => setBackColorSelect("white")}
                        onChange={(e) => {
                          onChange(e.target.value)
                          setIsEmptyBussinesTecnico(false)
                        }}
                      //aria-label=".form-select "
                      >
                        <option selected value={""} style={{ display: "none" }}>
                          Seleziona Negozio
                        </option>
                        {apiBussiness.map((d: any) => (
                          <option key={Number(d.idBussines)} value={d.idBussines}>
                            {d.desc_Bussines}
                          </option>
                        ))}

                      </select>
                    )}
                    rules={{ required: false }}
                  />
                  <br />
                  {/* {errors.business?.type === "required" && (
                    <Text style={styles2.labelError}>
                      Questo campo è obbligatorio
                    </Text>
                  )} */}
                </div>

                <div className="col-12 mb-2">
                  <Controller
                    control={control}
                    name="tecnico"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        className="mt-3"
                        style={{
                          width: "100%",
                          backgroundColor: backColorSelect,
                          height: 45,
                          cursor: "pointer",
                          margin: 4,
                          borderWidth: 2,
                          borderRadius: 50,
                          borderColor: "rgb(235,235,235)",
                          paddingLeft: 10,
                          fontSize: 16,
                          marginLeft: -3,
                        }}
                        //onMouseEnter={() => setBackColorSelect("#EAEAEA")}
                        // onMouseLeave={() => setBackColorSelect("white")}
                        onChange={(e) => {
                          onChange(e.target.value)
                          setIsEmptyBussinesTecnico(false)
                        }}
                        aria-label=".form-select "
                      >
                        <option selected value={""} style={{ display: "none" }}>
                          Selezionare il tecnico
                        </option>
                        {dataTecnico?.map((t: any) => (
                          <option key={Number(t.idUser)} value={t.idUser}>
                            {t.name}
                          </option>
                        ))}

                      </select>
                    )}
                    rules={{ required: false }}
                  />
                  <br />
                  {/* {errors.tecnico?.type === "required" && (
                    <Text style={styles2.labelError}>
                      Questo campo è obbligatorio
                    </Text>
                  )} */}


                </div>
              </div>

              {(isEmptyBussinesTecnico) && (
                <div className="col-12 mb-2">
                  <Text style={{ fontSize: 16, marginLeft: 20, fontFamily: "Arsapia Regular font", color: "red" }}>
                    Errore, selezionare un business o un tecnico
                  </Text>
                </div>
              )}

              <div className="row">
                <div className="col-12">
                  <Controller
                    control={control}
                    name="subject"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        style={[styles.input, styles.inputSubjectModal, { paddingRight: 20, marginBottom: 10 }]}
                        value={value}
                        placeholder="Oggetto"
                        onChangeText={(e: any) => onChange(e)}
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <br />
                  {errors.subject?.type === "required" && (
                    <div className="mb-2">
                      <Text style={[styles2.labelError]}>
                        Questo campo è obbligatorio
                      </Text>
                    </div>
                  )}
                  <Controller
                    control={control}
                    name="message"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        style={[
                          styles.inputContentModal,
                          {
                            fontSize: 16,
                          },
                        ]}
                        multiline={true}
                        numberOfLines={11}
                        editable={true}
                        value={value}
                        onBlur={onBlur}
                        onChangeText={(value) => onChange(value)}
                        placeholder="Scrivi messaggio..."
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <br />
                  {errors.message?.type === "required" && (
                    <Text style={styles2.labelError}>
                      Questo campo è obbligatorio
                    </Text>
                  )}
                </div>
              </div>



              <div className="row mt-1 mb-2">
                <div className="col-12 text-center">
                  {nameImgSelected ? (
                    <p>
                      <b>· Selezionato:</b> {nameImgSelected}
                    </p>
                  ) : null}

                  <img
                    src="https://i.postimg.cc/y8BSJnHY/rolex1.png"
                    className="d-none"
                    style={{ height: 120 }}
                    id="img-container-send"
                    alt=""
                  />
                  <br />
                  {nameImgSelected && (
                    <a
                      onClick={() => removeFile()}
                      style={{ cursor: "pointer" }}
                      className="text-danger"
                    >
                      rimuovere {nameImgSelected}
                    </a>
                  )}
                </div>
              </div>

              {messageSent && (
                <div className="row w-100 mb-3">
                  <div className="col-12">
                    <div className="alert mb-0 mt-2 alert-success" role="alert">
                      <p className="mb-0">Messaggio inviato con successo</p>
                    </div>
                  </div>
                </div>
              )}

              <div className="row w-50 mb-4">
                <div
                  className="col-12 d-flex justify-content-between"
                  style={{ marginLeft: 28 }}
                >
                  <TouchableOpacity>
                    <label
                      htmlFor="image-files-send"
                      style={{ cursor: "pointer" }}
                    >
                      <Image
                        style={[
                          styles.iconModal,
                          {
                            tintColor: "#b3b3b3",
                            marginTop: 10,
                          },
                        ]}
                        source={require("../assets/icons/camera2.png")}
                      />
                    </label>
                    <input
                      type="file"
                      name="image-files-send"
                      id="image-files-send"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ display: "none" }}
                      onChange={(e) => uploadFiles(e.target.files, "image")}
                    />
                  </TouchableOpacity>

                  <TouchableOpacity>
                    <label
                      htmlFor="document-files-send"
                      style={{ cursor: "pointer" }}
                    >
                      <Image
                        style={[
                          styles.iconModal,
                          {
                            marginHorizontal: 20,
                            tintColor: "#b3b3b3",
                            marginTop: 10,
                          },
                        ]}
                        source={require("../assets/icons/link.png")}
                      />
                    </label>
                    <input
                      type="file"
                      name="document-files-send"
                      id="document-files-send"
                      accept="application/pdf"
                      style={{ display: "none" }}
                      onChange={(e) => uploadFiles(e.target.files, "document")}
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={handleSubmit(sendEmail)}
                    disabled={hasPreventive}
                    style={{
                      width: 100,
                      alignSelf: "center",
                    }}
                  >
                    <View
                      style={[
                        styles.button,
                        { paddingHorizontal: 4, width: 140, backgroundColor: hasPreventive ? "gray" : "black" },
                      ]}
                    >
                      <Text style={[styles.buttonText, { paddingHorizontal: 4 }]}>
                        {"Invia e-mail"}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </div>
              </div>
              {modalMessage.active && (
                <PopupMessage
                  title={"Informazione"}
                  message={modalMessage.message}
                  stateModal={modalMessage.active}
                  setStateModal={setModalMessage}
                />
              )}
            </View>
          </View>
        </ScrollView>
      </Modal>
    </View>
  );
};
const statesRepair = ["Seleziona il negozio", "Negozio 1", "Negozio 2"];
const styles2 = StyleSheet.create({
  labelError: {
    fontSize: 16,
    marginLeft: 10,
    fontFamily: "Arsapia Regular font",
    color: "red",
  },
});