import { ModalCustom } from "../Modal/ModalCustom";
import ButtonWhite from "../Button/ButtonWhite";
import Select, { StylesConfig } from "react-select";
import { useEffect, useState } from "react";
import { View } from "react-native";
import { TouchableOpacity } from "react-native";
import { Image } from "react-native-elements";
import { styles } from "../../theme/GlobalStyle";
import { Controller, useForm } from "react-hook-form";
import Loading from "../Loading";
import PopupConfirmationCustom from "../PopupConfirmationCustom";
import { ConfirmDelete } from "../Calender/ConfirmDelete";
import axios from "axios";
import moment from "moment";
import ConfigCapone from "../../utils/ConfigCapone";
interface Props {
  isOpen: boolean;
  setIsOpen;
  BussinesList: [];
  stateOptionNew: any;
  defaultBussinesList: any;
  defaultUserList: any;
  defaultUserTecnicoList: any;
  UserList: [];
  UserTecnicoList: [];
  title: string;
  actionSave;
  deleteNew;
  deleteTask;
  dataEdit;
  getPDFNews;
  getPDFTask;
}
const styleInput = {
  width: "100%",
  backgroundColor: "transparent",
  height: 50,
  margin: 0,
  borderWidth: 2,
  borderRadius: 50,
  borderColor: "black",
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
};
const styleLabel = {
  fontSize: 18,
  marginLeft: 5,
  fontFamily: "Arsapia Regular font",
  color: "black",
};
const styleInputNoEdit = {
  width: "90%",
  height: 40,
  margin: 12,
  borderRadius: 50,
  marginVertical: 6,
  backgroundColor: "rgb(235, 235, 235)",
  fontFamily: "Arsapia Regular font",
  paddingLeft: 20,
};

const styleInputTextArea = {
  width: "90%",
  backgroundColor: "rgb(234, 234, 234)",
  height: "auto",
  borderRadius: 25,
  paddingLeft: 10,
  fontFamily: "Arsapia Regular font",
  textAlignVertical: "auto",
  padding: 30,
  margin: "12px",
};
const colourStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "rgb(235, 235, 235)",
    width: "90%",

    margin: 12,
    borderRadius: 50,
    marginVertical: 6,
    fontFamily: "Arsapia Regular font",
    color: "#a2a2a2",
    paddingLeft: 20,
  }),
};
const ModalFormEditDisable = ({
  isOpen,
  setIsOpen,
  BussinesList,
  stateOptionNew,
  defaultBussinesList,
  defaultUserList,
  defaultUserTecnicoList,
  UserList,
  UserTecnicoList,
  title,
  deleteNew,
  deleteTask,
  actionSave,
  getPDFNews,
  getPDFTask,
  dataEdit,
}: Props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    setNameFileSelected(dataEdit.nameFile)
  }, [dataEdit]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [nameFileSelected, setNameFileSelected] = useState<string>();
  const [nameImageSelected, setNameImageSelected] = useState<string>();

  const [urlPdf, setUrlPdf] = useState('')
  const [namePdf, setNamePdf] = useState('')

  const [imgUrlDownload, setImgUrlDownload] = useState('')
  const [urlFileDownload, setUrlFileDownload] = useState('')

  const [imgUrl, setImgUrl] = useState('')
  const [nameImageView, setNameImageView] = useState('')


  const [idUpdate, setIdUpdate] = useState(0);
  const [moduleUpdate, setModuleUpdate] = useState("");

  const [ConfirmOpen, setConfirmOpen] = useState(false);

  const [errorMsgCheck, seterrorMsgCheck] = useState<boolean>(false);
  const [errorMsgInputCheck, seterrorMsgInputCheck] = useState<boolean>(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };


  const uploadFile = async (file: any, type: string, name: string, extension: string, typeFile) => {

    if (typeFile == "document") {

      let myNewFile: any = null;
      let formData = new FormData();

      myNewFile = new File([file[0]], name, {
        type: file[0].type,
      });



      formData.append("files", myNewFile);
      formData.append("type", typeFile);
      formData.append("name", name);
      formData.append("extension", extension);
      await axios
        .post(`${ConfigCapone.apicapone}/upload/` + type, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("res", res);
        });

    }

    if (typeFile == "image") {

      let myNewFile: any = null;
      let formData = new FormData();

      myNewFile = new File([file[0]], name, {
        type: file[0].type,
      });



      formData.append("files", myNewFile);
      formData.append("type", typeFile);
      formData.append("name", name);
      formData.append("extension", extension);
      await axios
        .post(`${ConfigCapone.apicapone}/upload/` + type, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("res", res);
        });

    }
  }



  const uploadFiles = (e: any, type: string) => {
    setNameFileSelected(e ? e[0].name : null);
  };

  // acortar tessto
  const acortarTexto = (text: string) => {
    if (text == null) return
    if (text.length <= 34) {
      return text;
    } else {
      return text.slice(0, 34) + "...";
    }
  }

  const getFileName = () => {
    const formatDate = "YYYY_MM_DD_HH_mm_ss";
    let fileDate: any = new Date();
    const currentDate = moment(fileDate).format(formatDate);
    return currentDate;
  };


  useEffect(() => {
    setNameFileSelected(dataEdit.nameFile);
  }, [dataEdit]);

  const removeFile = () => {
    setValue('document-files', '')
    setValue("nameFile", '');
    setNameFileSelected("");
  };

  const changeValuesSelected = () => {
    if (selectedOption == "option1") {
      setValue("Dipendenti", "");
      setValue("Tecnici", "");
    }
    if (selectedOption == "option2") {
      setValue("Store", "");
      setValue("Tecnici", "");
    }
    if (selectedOption == "option3") {
      setValue("Store", "");
      setValue("Dipendenti", "");
    }
  };


  const onSubmit = async (data) => {
    let file: any = getValues("document-files");
    let image: any = getValues("image-files");


    let firstValidation = validateChecks();
    let secondValidation = validateInputsChecks();
    if (!firstValidation && !secondValidation) {

      let newDataEdit = {
        ...data,
        id: dataEdit.id,
      };

      if (file.length > 0) {

        let extension: string = file[0].name.substr(
          file[0].name.lastIndexOf(".") + 1
        );
        let name: string = file[0].name?.split(".")[0];
        let fileName = name + "_" + getFileName() + "." + extension;
        let typeFile = "document"
        await uploadFile(file, "tasknews", fileName, extension, typeFile);
        setValue("nameFile", fileName);
        newDataEdit.nameFile = fileName

      }

      if (image.length > 0) {

        let extension: string = image[0].name.substr(
          image[0].name.lastIndexOf(".") + 1
        );
        let name: string = image[0].name?.split(".")[0];
        let imageName = name + "_" + getFileName() + "." + extension;
        let typeFile = "image"
        await uploadFile(image, "tasknews", imageName, extension, typeFile);
        setValue("nameImage", imageName);
        newDataEdit.nameImage = imageName

      }


      actionSave(newDataEdit);
      reset();



    }
  }


  const validateChecks = () => {
    if (selectedOption) {
      seterrorMsgCheck(false);
      return false;
    } else {
      seterrorMsgCheck(true);
      return true;
    }
  };
  const validateInputsChecks = () => {
    if (
      getValues("Dipendenti")?.length > 0 ||
      getValues("Store")?.length > 0 ||
      getValues("Tecnici")?.length > 0
    ) {
      seterrorMsgInputCheck(false);
      return false;
    } else {
      seterrorMsgInputCheck(true);
      return true;
    }
  };
  useEffect(() => {
    changeValuesSelected();
  }, [selectedOption]);

  useEffect(() => {
    if (defaultBussinesList?.length > 0) setValue("Store", defaultBussinesList);
    if (defaultUserList?.length > 0) setValue("Dipendenti", defaultUserList);
    if (defaultUserTecnicoList?.length > 0)
      setValue("Tecnici", defaultUserTecnicoList);
  }, [defaultBussinesList]);

  useEffect(() => {
    setSelectedOption(stateOptionNew);
  }, [stateOptionNew]);

  const cancelMod = () => {
    console.log("cancel");
  };

  const actionMod = () => {
    if (title == "Modifica news") {
      deleteNew(idUpdate);
    } else {
      deleteTask(idUpdate);
    }

    setConfirmOpen(!ConfirmOpen);
  };

  const confirmDataDelete = (id) => {
    setIdUpdate(id);
    setConfirmOpen(true);
  };

  console.log("dataEdit", dataEdit)

  // const changeImage = async () =>{

  //   if (dataEdit.nameFile != null) {

  //     const parts = dataEdit.nameFile.split('.');
  //     const name = parts[0]; // Nombre de la imagen
  //     const extension = parts[1]; // Extensión

  //     if (title == "Modifica news") {
  //       var url = await getPDFNews(name,extension)

  //       if(url?.status == 200){

  //         if (extension == "pdf") {
  //           setUrlPdf(`${ConfigCapone.urlapi}/api/UploadImage/${name}/${extension}`)
  //           setNamePdf(`${name}.${extension}`)
  //         }

  //       } else {
  //         setUrlPdf(null)
  //         setNamePdf(null)
  //       }
  //     }

  //     if (title == "Modifica tasks") {
  //       var url = await getPDFTask(name,extension)

  //       if(url?.status == 200){

  //         if (extension == "pdf") {
  //           setUrlPdf(`${ConfigCapone.urlapi}/api/UploadImage/${name}/${extension}`)
  //           setNamePdf(`${name}.${extension}`)
  //         }

  //       } else {
  //         setUrlPdf(null)
  //         setNamePdf(null)
  //       }
  //     }

  //     }
  // }

  const changeImage = async () => {

    if (dataEdit.nameFile != null) {

      const parts = dataEdit.nameFile.split('.');
      const name = parts[0]; // Nombre de la imagen
      const extension = parts[1]; // Extensión

      if (title == "Modifica news") {
        var url = await getPDFNews(name, extension)

        if (url?.status == 200) {

          if (extension == "pdf") {
            setUrlPdf(`${ConfigCapone.urlapi}/api/UploadImage/${name}/${extension}`)
            setNamePdf(`${name}.${extension}`)
            setUrlFileDownload(`${ConfigCapone.urlapi}/api/UploadImage/${name}.${extension}`)
          }

        } else {
          setUrlPdf(null)
          setNamePdf(null)
          setUrlFileDownload(null)
        }
      }

      if (title == "Modifica tasks") {
        var url = await getPDFTask(name, extension)
        if (url?.status == 200) {
          if (extension == "pdf") {
            setUrlPdf(`${ConfigCapone.urlapi}/api/UploadImage/${name}/${extension}`)
            setUrlFileDownload(`${ConfigCapone.urlapi}/api/UploadImage/${name}.${extension}`)
            setNamePdf(`${name}.${extension}`)
          }

        } else {
          setUrlPdf(null)
          setNamePdf(null)
          setUrlFileDownload(null)
        }
      }

    }

    if (dataEdit.nameImage != null) {

      const parts = dataEdit.nameImage.split('.');
      const name = parts[0]; // Nombre de la imagen
      const extension = parts[1]; // Extensión

      if (title == "Modifica news") {

        var url = await getPDFNews(name, extension)

        if (url?.status == 200) {

          if (url) {
            setImgUrl(`${ConfigCapone.urlapi}/api/UploadImage/${name}/${extension}`)
            setImgUrlDownload(`${ConfigCapone.urlapi}/api/UploadImage/${name}.${extension}`)
            setNameImageView(`${name}.${extension}`)
          }

        } else {
          setUrlPdf(null)
          setNamePdf(null)
          setImgUrlDownload(null)
          setNameImageView("")
        }
      }

      if (title == "Modifica tasks") {

        var url = await getPDFTask(name, extension)

        if (url?.status == 200) {

          if (url) {
            setImgUrl(`${ConfigCapone.urlapi}/api/UploadImage/${name}/${extension}`)
            setImgUrlDownload(`${ConfigCapone.urlapi}/api/UploadImage/${name}.${extension}`)
            setNameImageView(`${name}.${extension}`)
          }

        } else {
          setUrlPdf(null)
          setNamePdf(null)
          setImgUrlDownload(null)
          setNameImageView("")
        }
      }

    }
  }



  useEffect(() => {
    //if (!dataEdit.nameFile) setUrlPdf('')
    //if (!dataEdit.nameImage) setImgUrl('')
    if (!dataEdit.nameFile) {
      setUrlPdf('')
      setNamePdf(null)
      setUrlFileDownload(null)
    }
    if (!dataEdit.nameImage) {
      setImgUrl('')
      setImgUrlDownload(null)
      setNameImageView("")
    }
    changeImage();

  }, [dataEdit])




  useEffect(() => {
    if (defaultBussinesList?.length > 0) setValue("Store", defaultBussinesList);
    if (defaultUserList?.length > 0) setValue("Dipendenti", defaultUserList);
    if (defaultUserTecnicoList?.length > 0)
      setValue("Tecnici", defaultUserTecnicoList);
    validateChecks();
    validateInputsChecks();
  }, [defaultBussinesList]);

  useEffect(() => {
    setSelectedOption(stateOptionNew);
  }, [stateOptionNew]);

  useEffect(() => {
    //console.log("useEfecct");
    setValue("Titolo", dataEdit.title);
  }, []);








  if (dataEdit) {
    setValue("Titolo", dataEdit.title)
    setValue("Testo", dataEdit.detail)
    setValue("nameFile", dataEdit.nameFile)
    setValue("nameImage", dataEdit.nameImage)

    if (defaultBussinesList?.length > 0) {
      setValue("Store", defaultBussinesList);
      setValue("Dipendenti", "");
      setValue("Tecnici", "");
    }

    if (defaultUserList?.length > 0) {
      setValue("Store", "");
      setValue("Dipendenti", defaultUserList);
      setValue("Tecnici", "");
    }

    if (defaultUserTecnicoList?.length > 0) {
      setValue("Store", "");
      setValue("Dipendenti", "");
      setValue("Tecnici", defaultUserTecnicoList);
    }
  }


  return (
    <>
      {isOpen && (
        <ModalCustom title={title} isOpen={isOpen} closeModal={setIsOpen}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group pt-0">
              <label htmlFor="" style={styleLabel}>
                <span>Titolo</span>
              </label>
              <input
                disabled
                type="text"
                className="form-control mt-0"
                {...register("Titolo", {
                  required: "Per favore, inserisci un titolo valido.",
                })}
              />
              {errors.Titolo && (
                <p style={{ color: "red" }}>{errors.Titolo.message}</p>
              )}
            </div>

            <div className="form-group mt-2">
              <label htmlFor="" className="mb-3 pt-2">
                <strong>Filtri</strong>
              </label>
            </div>
            <div className="form-group">
              <input
                disabled
                type="checkbox"
                id="option1"
                name="option1"
                value="option1"
                className="me-2"
                style={{ transform: "scale(1.6)" }}
                checked={selectedOption === "option1"}
                onChange={handleOptionChange}
              />
              <label htmlFor="option1" style={styleLabel}>
                Store
              </label>
            </div>
            <div className="form-group">
              <Controller

                name="Store"
                control={control}
                render={({ field }) => (
                  <Select

                    styles={colourStyles}
                    // defaultValue={[BussinesList[2], BussinesList[3]]}
                    isMulti
                    isDisabled={selectedOption !== "option4"}
                    options={BussinesList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="form-group pt-4">
              <input
                disabled
                type="checkbox"
                id="option2"
                name="option2"
                value="option2"
                className="me-2"
                style={{ transform: "scale(1.6)" }}
                checked={selectedOption === "option2"}
                onChange={handleOptionChange}
              />
              <label htmlFor="option2" style={styleLabel}>
                Dipendenti
              </label>
            </div>
            <div className="form-group mb-0">
              <Controller
                name="Dipendenti"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    styles={colourStyles}
                    //defaultValue={[UserList[2], optionBussines[3]]}
                    isMulti
                    options={UserList}
                    isDisabled={selectedOption !== "option4"}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="form-group pt-4">
              <input
                disabled
                type="checkbox"
                id="option3"
                name="option3"
                value="option3"
                className="me-2"
                style={{ transform: "scale(1.6)" }}
                checked={selectedOption === "option3"}
                onChange={handleOptionChange}
              />
              <label htmlFor="option3" style={styleLabel}>
                Tecnici
              </label>
            </div>
            <div className="form-group">
              <Controller
                name="Tecnici"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    styles={colourStyles}
                    //defaultValue={[UserList[2], optionBussines[3]]}
                    isMulti
                    options={UserTecnicoList}
                    isDisabled={selectedOption !== "option4"}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    {...field}
                  />
                )}
              />
            </div>
            {errorMsgCheck && (
              <p style={{ color: "red" }}>
                Per favore, seleziona almeno un'opzione.
              </p>
            )}
            {errorMsgInputCheck && (
              <p style={{ color: "red" }}>
                Per favore, seleziona almeno un contenuto dalle opzioni.
              </p>
            )}
            <div className="form-group mb-2">
              <label htmlFor="textArea" className="mb-2" style={styleLabel}>
                <span>Testo</span>
              </label>
              <textarea
                disabled
                name="textArea"
                id=""
                cols={30}
                rows={5}
                className="form-control mt-0"
                style={styleInputTextArea}
                {...register("Testo")}
              ></textarea>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="d-flex justify-content-center">
                    <div className="text-center">
                      {imgUrl && <img className="container-img-calendar" src={imgUrl} alt="" />}
                    </div>
                  </div>

                </div>
                <div className="col-12 mb-2">
                  {(imgUrlDownload || nameImageSelected) && (
                    <div>
                      <a href={imgUrlDownload ? imgUrlDownload : nameImageSelected} download>
                        {nameImageView != "" && `Immagine: ${acortarTexto(nameImageView)}`}
                      </a>
                    </div>)}

                </div>
                <div className="col-12 mb-2">
                  {(urlFileDownload || nameFileSelected) ? (
                    <a href={urlFileDownload ? urlFileDownload : nameFileSelected} download>
                      {namePdf != "" && `Archivio: ${acortarTexto(nameFileSelected)}`}
                    </a>
                  ) : null}
                </div>

              </div>
            </div>





            <div className="d-flex">
              <div className="col-10 d-flex justify-content-center">
                <div className=" w-100 text-center">
                  <div
                    style={{
                      flex: 1,
                      width: 150,
                      marginLeft: "14px",
                      height: 40,
                      alignSelf: "center",
                      backgroundColor: "transparent",
                      borderRadius: 50,
                      paddingTop: "4px",
                      border: "solid black 2px",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}

                  >
                    Anulla
                  </div>
                </div>
                <div className=" w-100 text-center">
                  <ButtonWhite title="Salva" ModalAction={() => { }} disabled={true} />
                </div>
                <div className="d-flex"></div>
              </div>
              <div className="col-2" style={{ marginLeft: '5px', marginTop: '3px' }}>
                <label htmlFor="image-files" style={{ cursor: "pointer", marginRight: '5px' }}>
                  <img src={require("../../assets/icons/camera2.png")} style={
                    {
                      width: 25,
                      cursor: "pointer",
                      height: 25,
                      //marginHorizontal: 20,
                      //tintColor: "#b3b3b3",
                      marginLeft: -10,
                      right: 70
                    }
                  } alt="" />
                </label>
                <input
                  disabled
                  type="file"
                  name="image-files"
                  id="image-files"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                  {...register("image-files", {
                    onChange: (e) => uploadFiles(e.target.files, "document"),
                  })}
                />
                <label htmlFor="document-files" style={{ cursor: "pointer" }}>
                  <img
                    src={require("../../assets/icons/link.png")}
                    style={{
                      width: 25,
                      cursor: "pointer",
                      height: 25,
                      //marginHorizontal: 20,
                      //tintColor: "#b3b3b3",
                      marginTop: 5,
                    }}
                    alt=""
                  />
                </label>
                <input
                  disabled
                  type="file"
                  id="document-files"
                  accept="application/pdf, image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                  //onChange={(e) => uploadFiles(e.target.files, "document")}
                  {...register("document-files", {
                    onChange: (e) => uploadFiles(e.target.files, "document"),
                  })}
                />
              </div>
              {ConfirmOpen && (
                <PopupConfirmationCustom
                  cancelAction={() => cancelMod()}
                  title={""}
                  message={"Sei sicuro di voler eliminare la notizia?"}
                  confirmAction={() => actionMod()}
                  stateModal={ConfirmOpen}
                  setStateModal={setConfirmOpen}
                />
              )}
            </div>
            <div className="col-2"></div>
          </form>

          {/* <div className="form-group mb-2 w-100 text-center">
            {nameImageSelected ? (
              <p>
                <b>· Selezionato:</b> {nameImageSelected}
              </p>
            ) : null}

            {nameImageSelected && (
              <a
                onClick={() => removeFile()}
                style={{ cursor: "pointer" }}
                className="text-danger"
              >
                Rimuovere: {nameImageSelected}
              </a>
            )}
          </div> */}

          <div className="form-group mb-2 w-100 text-center">
            {/* {nameFileSelected ? (
              <p>
                <b>· Selezionato:</b> {nameFileSelected}
              </p>
            ) : null} */}


          </div>
        </ModalCustom>
      )}
    </>
  );
};

export default ModalFormEditDisable;
