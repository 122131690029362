const ConfigCapone = {

  //urlapi:'http://195.231.22.91:5050'
  // rlapi: 'http://localhost:5050',
  // urlapi:'http://192.168.100.6:5020' 

  //backend server
  //local
  //urlapi: 'http://localhost:5050',
  //production - local

  //urlapi: 'http://assistenza.sergiocapone.com:5050',
  //production
  urlapi: '',
  /* ------------------------------------------------ */

  //api-scapone
  //local
  //apicapone: 'http://localhost:3001/api-scapone',
  //production
  apicapone: 'http://assistenza.sergiocapone.com:3001/api-scapone',
  //staging
  //apicapone: 'http://assistenza.sergiocapone.com:3002/api-scapone',

  /* ------------------------------------------------- */

  //emails
  emailMarketing: 'marketing@sergiocapone.com',
  //emailMarketing: 'jhonatanllamuca@gmail.com'
  //emailMarketing: 'mauricio.arnez@abisoft.it',
  //emailMarketing: 'fernando.maraza@gmail.com',
  //emailMarketing: 'yasmani.vera@abisoft.it',

  //config custom permissions assistenza and contacti
  emailPermited: ["fabrcapone@gmail.com", "caponenrico@gmail.com", "p.grimaldi@qbitsoft.it"],


  //Config staging test true=>staging , false =>produccion
  configTest: false,
  //configTest:false,
  //emailTest:["mauricio.arnez@abisoft.it"],
  //emailTest: ["yasmani.vera@abisoft.it"],
  //emailTest: ["fernando.maraza@gmail.com"],
  //emailTest: ["michel.desantis@hotmail.com", "desantismichel@gmail.com", "michel.desantis@abisoft.it"],
  emailTest: ["p.grimaldi@qbitsoft.it", "info@sergiocapone.com"],

}

export default ConfigCapone;              