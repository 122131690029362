import React, { ChangeEvent, FC } from "react";
import './css/inputComponent.css';
import { UseFormRegister, FieldErrors, UseFormRegisterReturn } from "react-hook-form";
export interface optionValues {
  value: string | number,
  label: string;
  icon?: string;
}

interface InputProps {
  register?: UseFormRegisterReturn;
  errors?: FieldErrors;
  name: string;
  label: string;
  type: "text" | "email" | "number" | "select" | "date" | "textarea";
  width?: string;
  options?: optionValues[];
  onChange?: (event: any) => void;
  optionsSelect?: { value: string | number, label: string }[];
  onOptionSelect?: (id: string | number) => void;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  alertProps?: React.InputHTMLAttributes<HTMLParagraphElement>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  selectProps?: React.InputHTMLAttributes<HTMLSelectElement>;
  optionSelectProps?: React.InputHTMLAttributes<HTMLOptionElement>;
  disabledInput?: boolean;
  rows?: number;
  focus?: boolean;
}

const Input: FC<InputProps> = ({
  name,
  type,
  label,
  register,
  errors = {},
  width = "100%",
  options = [],
  optionsSelect = [],
  onChange,
  onOptionSelect,
  containerProps,
  alertProps,
  inputProps,
  disabledInput,
  selectProps,
  optionSelectProps,
  rows,
  focus,
  ...rest
}) => {

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {

    if (onChange) {
      onChange(event); // <- Propaga el evento onChange si está definido
    }
  }
  return (
    <div className="input-container" style={{ width }} {...containerProps}>
      <label htmlFor={name}>{label}</label>
      {type === "select" ? (
        <select
          className={`input ${errors[name] && 'is-danger'}`}
          aria-invalid={errors[name] ? "true" : "false"}
          name={name}
          id={name}
          //defaultValue=""
          {...selectProps}
          {...register}
          {...rest}
        >
          <option value="">Seleziona</option>
          {options.map(option => (
            <option key={option.value} value={option.value} {...optionSelectProps}>{option.label}</option>
          ))}
        </select>
      ) : (
        <>
          {type == 'textarea'
            ?
            <>
              <textarea
                style={{ zIndex: 100 }}
                className={`input ${errors[name] && 'is-danger'}`}
                aria-invalid={errors[name] ? "true" : "false"}
                name={name}
                id={name}
                disabled={disabledInput}
                rows={rows ? rows : 3}
                {...rest}
              />
            </>
            :
            <>
              <input
                style={{ zIndex: 100 }}
                className={`input ${errors[name] && 'is-danger'}`}
                aria-invalid={errors[name] ? "true" : "false"}
                type={type}
                name={name}
                id={name}
                disabled={disabledInput}
                autoFocus={focus}
                {...inputProps}
                {...register}
                {...rest}
              />
              {optionsSelect.length > 0 && (
                <div className="option-select" >
                  {optionsSelect.map((option, index) =>

                    <div
                      className="option-children"
                      key={index}
                      onClick={() => onOptionSelect && onOptionSelect(option.value)}
                    >

                      {option.label}

                    </div>
                  )}
                </div>
              )}
            </>

          }

        </>

      )}
      {errors[name] && (
        <p className="help is-danger" role="alert" {...alertProps}>
          {errors[name].message}
        </p>
      )}
    </div>
  );
};

export default Input;
