import { useContext, useEffect, useRef, useState } from "react";
import { SearchMagazzinoAtelierProdottoSuggestions, listAllBrand, listAllMagazzinoAtelier, listAllMagazzinoAtelierWhitSearch, searchReferenzaByLimit } from "../../services/magazzinoAtelierService";
import { AllListAtelier, BrandResponse } from "../../interfaces/IMagazzinoAtelier";
import { UserContext } from "../../../../../context/UserContext";
import { AllDeletematricolaByReference, DeleteMagazzino, DeleteReferenza, FindByReference, GetDataClient } from "../../../../../services/MagazzinoRefactor";
import { IMagazzino } from "../../../../../interfaces/magazzino";
import ResizeObserver from 'resize-observer-polyfill';

export const useMagazzinoAtelierTable = () => {
  const [searchInputProdotto, setSearchInputProdotto] = useState<string>();
  const [activeSuggestion, setActiveSuggestion] = useState(true);
  const [autosuggestInputProdotto, setAutosuggestInputProdotto] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false)
  const [currentPage, setcurrentPage] = useState(1)
  const [resetCurrentPage, setResetCurrentPage] = useState(false);
  const { userInLogin } = useContext(UserContext);
  const [listMagazzino, setListMagazzino] = useState<any[]>();
  const [firstLoad, setFirstLoad] = useState(false);
  const [listClient, setListClient] = useState<any[]>();
  const [IdStateProduct, setIdStateProduct] = useState(0);
  const [IdStateMagazzino, setIdStateMagazzino] = useState(0);
  const [modal, setModal] = useState(false);
  const [listMatricola, setListMatricola] = useState<any[]>();
  const [dataMagazzino, setDataMagazzino] = useState<any[]>([]);
  const [modalVisibleCard, setModalVisibleCard] = useState(false);
  const [modalVisibleMatricolaCard, setModalVisibleMatricolaCard] = useState(false);
  const [activeSuggestionReferenza, setActiveSuggestionReferenza] = useState(true)
  const [autosuggestInputReferenza, setAutosuggestInputReferenza] = useState<IMagazzino[]>([]);
  const [crmSend, setCrmSend] = useState("");

  const [filterBrand, setFilterBrand] = useState<number[]>([]);
  const [searchInputReferenza, setSearchInputReferenza] = useState<string>();
  const [searchInputPrezzo, setSearchInputPrezzo] = useState<string>();
  const [searchInputDescription, setSearchInputDescription] = useState<string>();
  const [year, setYear] = useState<string>();
  const [matricole, setMatricole] = useState<string>();
  const [diameter, setDiameter] = useState<string>();
  const [collection, setCollection] = useState<string>();
  const [house, setHouse] = useState<string>();
  const [glass, setGlass] = useState<string>();
  const [estate, setEstate] = useState<string>();
  const [movement, setMovement] = useState<string>();
  const [caliber, setCaliber] = useState<string>();
  const [powerReserve, setPowerReserve] = useState<string>();
  const [bracelet, setBracelet] = useState<string>();
  const [color, setColor] = useState<string>();
  const [misura, setMisura] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [box, setBox] = useState<number>();
  const [guarantees, setGuarantees] = useState<number>();
  const [filterQuantita, setFilterQuantita] = useState<number[]>([]);

  const divRef = useRef(null);
  const [height, setHeight] = useState(0);

  const [data, setData] = useState<AllListAtelier>({
    brandList: [],
    magazzinoList: [],
  });

  const [paginationList, setPaginationList] = useState({
    currentPage: 1,
    total: 0,
    totalPages: 0,
  });

  const limit: number = 52;

  const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({
    title: "Informazione",
    message: "",
    active: false,
  });

  const [modalQuadrante, setModalQuadrante] = useState({
    title: "Informazione",
    Misura: "",
    Colore: "",
    Movimento: "",
    active: false,
  });

  //export
  const [dataExcelMagazzino, setDataExcelMagazzino] = useState<any>([]);
  const [griglia, setgriglia] = useState<boolean>(false)
  const [tableCsv, setTableCsv] = useState<any>([]);

  //ExcelEsporta
  const dataExcel = async (selectedFilters: any, isfilter: boolean) => {

    if (isfilter) {
      let mensaje = "Sequence contains no elements";
      let msg: any;
      selectedFilters.limit = 0
      let result: any = await listAllMagazzinoAtelierWhitSearch(selectedFilters)
      setDataExcelMagazzino(result?.response)
      selectedFilters.limit = 50
    } else {
      let results: any = await listAllMagazzinoAtelier(1, 0)
      setDataExcelMagazzino(results?.response)
    }

  }

  const onChangeTextProdotto = async (name: string) => {
    if (name) {
      try {
        setSearchInputProdotto(name)
        const response = await Promise.all([
          SearchMagazzinoAtelierProdottoSuggestions(name)
        ]);
        setActiveSuggestion(true)
        let prodotto = response[0]
        setAutosuggestInputProdotto(prodotto);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchInputProdotto("")
    }

  }

  const onChangeTextReferenza = async (name: string) => {
    if (name) {
      try {
        setSearchInputReferenza(name)
        //setSearchInputReferenza(name)
        const response = await Promise.all([
          searchReferenzaByLimit(name)
        ]);
        setActiveSuggestionReferenza(true)
        let referenza = response[0]

        if (referenza?.length > 0) {
          let resultData = referenza.reduce((elements, obj, index) => {
            let existingData = elements.find(
              (element) => element.referencia === obj.referencia
            );
            if (!existingData) {
              elements.push(obj);
            }
            return elements;
          }, []);

          setAutosuggestInputReferenza(resultData);
        }

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchInputReferenza("")
    }
  };

  
  const handleData = async () => {
    let brandListFilter = [];
    // setReload(true);
    try {
      const response: any = await Promise.all([
        listAllMagazzinoAtelier(currentPage, limit),
        listAllBrand(),
      ]);

      const magazzinoList: any = response[0]?.response;
      const brandList: BrandResponse[] = response[1];

      setPaginationList({
        currentPage: response[0]?.currentPage,
        total: response[0]?.total,
        totalPages: response[0]?.totalPages
      })
      dataExcel(false, false)
      brandListFilter = brandList.filter((x) => x.desc_Brand != 'Rolex');
      setData({
        brandList: userInLogin.idRole == '9' ? brandListFilter : brandList,
        magazzinoList: magazzinoList,
      });
      setListMagazzino(magazzinoList)
      setFirstLoad(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      // setReload(false);
    }

  };

  const getDataClient = async () => {
    try {
      const response = await Promise.all([
        GetDataClient()
      ]);
      setListClient(response[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const filtered = async () => {
    try {

      setReload(true);

      console.log('box', box);
      console.log('guarantees', guarantees);

      const filterActive =
        filterBrand.length > 0 ||
        filterQuantita.length > 0 || 
        searchInputPrezzo ||
        searchInputDescription ||
        searchInputReferenza ||
        year ||
        matricole ||
        diameter ||
        collection ||
        house ||
        glass ||
        estate ||
        movement ||
        caliber ||
        powerReserve ||
        bracelet ||
        color ||
        misura ||
        fileName ||
        box ||
        guarantees ||
        searchInputProdotto;

      console.log(filterActive);
      if (filterActive) {
        if (resetCurrentPage) {
          setResetCurrentPage(false)
        } else {
          setcurrentPage(1)
        }

        console.log(filterQuantita);

        const results: any = await listAllMagazzinoAtelierWhitSearch({
          filterBrand: filterBrand,
          filterQuantita: filterQuantita.length>0?[0]:[1],
          price: searchInputPrezzo ? searchInputPrezzo : '',
          descrizione: searchInputDescription ? searchInputDescription : '',
          referenza: searchInputReferenza ? searchInputReferenza : '',
          year: year ? year : '',
          matricole: matricole ? matricole : '',
          diameter: diameter ? diameter : '',
          collection: collection ? collection : '',
          house: house ? house : '',
          glass: glass ? glass : '',
          estate: estate ? estate : '',
          movement: movement ? movement : '',
          caliber: caliber ? caliber : '',
          powerReserve: powerReserve ? powerReserve : '',
          bracelet: bracelet ? bracelet : '',
          color: color ? color : '',
          misura: misura ? misura : '',
          fileName: fileName ? fileName : '',
          box: box ? box : null,
          guarantees: guarantees ? guarantees : null,
          prodotto: searchInputProdotto ? searchInputProdotto : '',
          limit: limit,
          page: (searchInputProdotto || searchInputReferenza) ? 1 : currentPage,
        });

        console.log("results0", results);
        dataExcel({
          filterBrand: filterBrand,
          filterQuantita: filterQuantita.length>0?[0]:[1],
          price: searchInputPrezzo ? searchInputPrezzo : '',
          descrizione: searchInputDescription ? searchInputDescription : '',
          referenza: searchInputReferenza ? searchInputReferenza : '',
          year: year ? year : '',
          matricole: matricole ? matricole : '',
          diameter: diameter ? diameter : '',
          collection: collection ? collection : '',
          house: house ? house : '',
          glass: glass ? glass : '',
          estate: estate ? estate : '',
          movement: movement ? movement : '',
          caliber: caliber ? caliber : '',
          powerReserve: powerReserve ? powerReserve : '',
          bracelet: bracelet ? bracelet : '',
          color: color ? color : '',
          misura: misura ? misura : '',
          fileName: fileName ? fileName : '',
          box: box ? box : null,
          guarantees: guarantees ? guarantees : null,
          prodotto: searchInputProdotto ? searchInputProdotto : '',
          limit: limit,
          page: currentPage,
        }, true)
        if (results?.response?.length > 0) {

          setData({
            ...data,
            magazzinoList: [],
          });

          setData({
            ...data,
            magazzinoList: results?.response,
          })

          setTableCsv(results?.response)
          //change new
          setListMagazzino(results?.response)
          //change new end
          setPaginationList({
            currentPage: results?.currentPage,
            total: results?.total,
            totalPages: results?.totalPages
          })
        } else {
          showSuccessPopup("Non ci sono record.", "Informazione")
          console.log("Non ci sono record.");
        }

      } else {

        if (firstLoad) {
          const results: any = await listAllMagazzinoAtelier(currentPage, limit);
          dataExcel(false, false)

          setPaginationList({
            currentPage: results?.currentPage,
            total: results?.total,
            totalPages: results?.totalPages
          })

          setData({
            ...data,
            magazzinoList: [],
          });


          setData({
            ...data,
            magazzinoList: results?.response,
          })

          setTableCsv(results?.response)
          //changes new
          setListMagazzino(results?.response)
          //changes new end
          setResetCurrentPage(false)
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setReload(false);
    }
  };

  //functionSearchProdotto
  const handleSearchFilter = async () => {
    await filtered()
    setActiveSuggestion(false)
  }
  //functionSearchRefernza
  const handleSearch = async () => {
    //if(searchInputReferenza){
    await filtered()
    setActiveSuggestionReferenza(false)
    //}
  }

  const handleFilterBrand = (value: number) => {
    if (!value) return;
    const brand = Number(value);
    const isExits = filterBrand.find((elem) => elem === brand);
    if (!isExits) {
      setFilterBrand([...filterBrand, brand]);
    } else {
      setFilterBrand([...filterBrand.filter((elem) => elem !== brand)]);
    }
  };

  const handleFilterQuantita = (value: number) => {
    if (!value === undefined) return;
    const quantita = Number(value);
    const isExits = filterQuantita.length > 0 ? [] : [quantita];
    setFilterQuantita(isExits);
  };

  const goToSchedaProdotto = async (data: any, openModal: string) => {
    try {
      const response = await Promise.all([
        FindByReference(data?.idReference)
      ]);

      setListMatricola(response[0]);
      setDataMagazzino(data);
      openModal == "open" && setModalVisibleCard(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const goToSchedaQuadrante = async (data: any, openModal: string) => {
    try {
      setModalQuadrante({
        active: true,
        Misura: data.misuraQuadrante,
        Colore: data.coloreQuadrante,
        Movimento: data.movement,
        title: "Informazione",
      })

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const goToMatricola = async (data: any) => {
    try {
      const response = await Promise.all([
        FindByReference(data?.idReference)
      ]);
      setListMatricola(response[0]);
      setDataMagazzino(data);
      setModalVisibleMatricolaCard(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchDescrizione = async () => {
    await filtered()
  }

  const confirmToDelete = async (idReference: number, idMagazzino: number) => {
    setIdStateProduct(idReference);
    setIdStateMagazzino(idMagazzino)
    setModal(true);
  }

  const confirmToDeleteTrue = async (idReference: number, idMagazzino: number) => {
    try {
      const response = await Promise.all([
        DeleteReferenza(idReference),
        DeleteMagazzino(idMagazzino),
        AllDeletematricolaByReference(idReference),
      ]);
      setFirstLoad(true);
      setModal(false);
      filtered();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const showSuccessPopup = (mess: string, tiTle: string) => {
    setmodaleMessaggeWithUrl({
      active: true,
      message: mess,
      title: tiTle,
    })
  };

  const confirmAction = (Landing: any, data: any) => {
    setmodaleMessaggeWithUrl({
      title: "Informazione",
      message: "",
      active: false,
    });
  };


  useEffect(() => {
    handleData();
    getDataClient();
  }, []);

  useEffect(() => {
    resetCurrentPage && filtered()
  }, [currentPage])

  useEffect(() => {
    filtered();
  }, [filterBrand, box, guarantees, filterQuantita]);


  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          setHeight(entry.contentRect.height);
        }
      }
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }
    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  return {
    searchInputProdotto,
    onChangeTextProdotto, 
    handleSearchFilter,

    dataExcelMagazzino,
    griglia,
    setgriglia,

    magazzinoList: data.magazzinoList,
    brandList: data.brandList,
    loading: loading || reload,

    handleFilterBrand,
    handleFilterQuantita,
    searchInputPrezzo,
    setSearchInputPrezzo,
    searchInputDescription,
    setSearchInputDescription,
    year,
    setYear,
    matricole,
    setMatricole,
    diameter,
    setDiameter,
    collection,
    setCollection,
    house,
    setHouse,
    glass,
    setGlass,
    estate,
    setEstate,
    movement,
    setMovement,
    caliber,
    setCaliber,
    powerReserve,
    setPowerReserve,
    bracelet,
    setBracelet,
    color,
    setColor,
    misura,
    setMisura,
    fileName,
    setFileName,
    box,
    setBox,
    guarantees,
    setGuarantees,
    goToSchedaProdotto,
    goToSchedaQuadrante,
    goToMatricola,
    crmSend,
    paginationList,
    currentPage,
    setcurrentPage,
    limit,
    setResetCurrentPage,

    modaleMessaggeWithUrl,
    setmodaleMessaggeWithUrl,
    confirmAction,
    confirmToDelete,
    modalQuadrante,
    setModalQuadrante,
    modal,
    setModal,
    confirmToDeleteTrue,

    searchInputReferenza,

    IdStateProduct,
    IdStateMagazzino,

    handleSearchDescrizione,
    onChangeTextReferenza,
    handleSearch,
    activeSuggestionReferenza,
    autosuggestInputReferenza,
    setAutosuggestInputReferenza,
    setSearchInputReferenza,

    setLoading,

    modalVisibleCard,
    setModalVisibleCard,
    dataMagazzino,
    listMagazzino,
    listMatricola,


    activeSuggestion,
    autosuggestInputProdotto,
    setSearchInputProdotto,
    setAutosuggestInputProdotto,
    modalVisibleMatricolaCard,
    setModalVisibleMatricolaCard,
    handleData,
    filtered,

    heightResize: height,
    divRef
  };

};
