import Modal from "react-modal";
import "../../GeneralStyles/styleModal.css";
interface Props {
  isOpen: boolean;
  children: any;
  title: string;
  size?: string;
  closeModal: () => void;
}
Modal.setAppElement("#root");

export const ModalAplication = ({
  isOpen,
  children,
  title,
  size,
  closeModal,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={200}
      className="modal fade show d-block overflow-auto"
      id="modal-root"
    >
      <div
        className={`modal-dialog modal-dialog-centered ${size} `}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              onClick={() => closeModal()}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="card-body">{children}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const ModalAplicationClose = ({
  children,
  closeModal,
  isOpen,
  title,
  size,
}: Props) => {
  return (
    <ModalAplication closeModal={closeModal} isOpen={isOpen} title={title}>
      {children}
      <div className="modal-footer">
        <button
          onClick={() => closeModal()}
          type="button"
          className="btn btn-dark mb-2 mt-2"
        >
          chiudere
        </button>
      </div>
    </ModalAplication>
  );
};
export const ModalAplicationBtn = ({
  children,
  closeModal,
  isOpen,
  title,
  size,
}: Props) => {
  return (
    <ModalAplication
      closeModal={closeModal}
      isOpen={isOpen}
      title={title}
      size={size}
    >
      {children}
      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-success mb-2 mt-2"
        >
          Registrare
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="btn btn-dark mb-2 mt-2"
        >
          Chiudere
        </button>
      </div>
    </ModalAplication>
  );
};
