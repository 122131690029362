import { useContext, useEffect } from "react";
import {
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import { IClient, IServiceClient, IUser } from "../api/ClientService";
import { HeaderModal } from "../components/HeaderModal";
import { UserContext } from "../context/UserContext";
import { styles } from "../theme/GlobalStyle";
interface IRepairCardScreen {
  modalVisible: boolean;
  setModalVisible: any;
  dataClient: any | undefined;
  dataService: IServiceClient | undefined;
  operatore: IUser | undefined;
  navigation?: any
  activeButton: boolean
  dataTableSend?: any,
  isRepairs?: any,
  isAtelier?: boolean,
}
const RepairCardScreen = ({
  modalVisible = false,
  setModalVisible,
  dataClient,
  dataService,
  operatore,
  navigation,
  activeButton = true,
  dataTableSend,
  isRepairs = false, 
  isAtelier
}: IRepairCardScreen) => {

  const goToEdit = (dataJoin: any) => {
    setModalVisible(false);
    navigation.navigate(!isAtelier ? "FormRepairScreen" : "NuovaRiparazione", dataJoin);
  }
  useEffect(() => {
    //console.log('dataClient', dataClient)
  }, [dataClient])

  return (
    <View style={styles2.centeredView}>
      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <View
          style={[styles2.centeredView, { backgroundColor: "rgba(0,0,0,.32)" }]}
        >
          <View style={styles2.modalView}>
            <HeaderModal
              setModalVisible={setModalVisible}
              modalVisible={modalVisible}
              title={"Scheda riparazioni"}
            />
            <ScrollView>
              <Text style={styles.label}>Titolo</Text>
              {
                isRepairs
                  ?
                  <TextInput
                    style={styles2.inputNotEdit}
                    value={dataService?.title ? dataService.title : "Nessun dato"}
                    editable={false}
                  />
                  :
                  <TextInput
                    style={styles2.inputNotEdit}
                    value={dataTableSend?.desc_Title ? dataTableSend.desc_Title : "Nessun dato"}
                    editable={false}
                  />
              }

              <Text style={styles.label}>Nome e cognome</Text>
              <TextInput
                style={styles2.inputNotEdit}
                value={dataClient?.name}
                editable={false}
              />
              <Text style={styles.label}>Indirizzo</Text>
              <TextInput
                style={styles2.inputNotEdit}
                value={
                  dataClient?.address ? dataClient?.address : "Nessun dato"
                }
                editable={false}
              />
              <Text style={styles.label}>E-mail</Text>
              <TextInput
                style={styles2.inputNotEdit}
                value={dataClient?.email ? dataClient?.email : "Nessun dato"}
                editable={false}
              />
              <Text style={styles.label}>Numero di telefono</Text>
              <TextInput
                style={styles2.inputNotEdit}
                keyboardType='numeric'
                value={dataClient?.phone ? dataClient?.phone : "Nessun dato"}
                editable={false}
              />
              <Text style={styles.label}>Negozio di riferimento</Text>
              {
                isRepairs
                  ?
                  <TextInput
                    style={styles2.inputNotEdit}
                    value={
                      dataService?.desc_Bussines
                        ? dataService?.desc_Bussines
                        : "Nessun dato"
                    }
                    editable={false}
                  />
                  :
                  <TextInput
                    style={styles2.inputNotEdit}
                    value={
                      dataTableSend?.desc_Bussines
                        ? dataTableSend?.desc_Bussines
                        : "Nessun dato"
                    }
                    editable={false}
                  />
              }
              <Text style={styles.label}>Operatore</Text>
              <TextInput
                style={styles2.inputNotEdit}
                value={
                  operatore?.userName ? operatore?.userName : "Nessun dato"
                }
                editable={false}
              />
              {
                dataTableSend != undefined
                &&
                dataTableSend.tecnico != null
                &&
                <>
                  <Text style={styles.label}>Tecnico</Text>
                  <TextInput
                    style={styles2.inputNotEdit}
                    value={dataTableSend.tecnico}
                    editable={false}
                  />
                </>
              }
              <Text style={{
                ...styles.label,
                marginTop: 40,
                fontWeight: 500,
                marginLeft: 10,
                fontSize: 20,
              }}
              >
                Informazioni riparazione
              </Text>
              <Text style={styles.label}>Orologio marcato</Text>
              {
                isRepairs
                  ?
                  <TextInput
                    style={styles2.inputNotEdit}
                    value={
                      dataService?.desc_Brand
                        ? dataService?.desc_Brand
                        : "Nessun dato"
                    }
                    editable={false}
                  />
                  :
                  <TextInput
                    style={styles2.inputNotEdit}
                    value={
                      dataClient?.brandDefault
                        ? dataClient?.brandDefault.desc_Brand
                        : "Nessun dato"
                    }
                    editable={false}
                  />
              }
              <Text style={styles.label}>Referenza</Text>
              <TextInput
                style={styles2.inputNotEdit}
                editable={false}
                value={
                  dataService?.reference
                    ? dataService?.reference
                    : "Nessun dato"
                }
              />
              <Text style={styles.label}>Matricola</Text>
              <TextInput
                style={styles2.inputNotEdit}
                editable={false}
                value={
                  dataService?.enrollment
                    ? dataService?.enrollment
                    : "Nessun dato"
                }
              />
              <Text style={styles.label}>Numero Busta</Text>
              {
                isRepairs
                  ?
                  <TextInput
                    style={styles2.inputNotEdit}
                    editable={false}
                    value={
                      dataService?.envelopeNumber
                        ? dataService?.envelopeNumber
                        : "Nessun dato"
                    }
                  />
                  :
                  <TextInput
                    style={styles2.inputNotEdit}
                    editable={false}
                    value={
                      dataClient?.envelopeNumber
                        ? dataClient?.envelopeNumber
                        : "Nessun dato"
                    }
                  />
              }
              <Text style={styles.label}>Note</Text>
              <TextInput
                style={styles2.inputNotEdit}
                value={
                  dataService?.description
                    ? dataService?.description
                    : "Nessun dato"
                }
                editable={false}
              />
              <button
                style={{
                  width: 200,
                  marginTop: 30,
                  height: 40,
                  alignSelf: "end",
                  backgroundColor: "transparent",
                  borderRadius: 50,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => goToEdit(dataClient)}
                disabled={!activeButton}
              >
                Modifica Scheda
              </button>
            </ScrollView>
          </View>
        </View>
      </Modal>
    </View>
  );
};
const styles2 = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    flex: 1,
  },
  inputNotEdit: {
    width: 350,
    height: 40,
    margin: 12,
    borderRadius: 50,
    marginVertical: 6,
    backgroundColor: "rgb(235, 235, 235)",
    fontFamily: "Arsapia Regular font",
    color: "#a2a2a2",
    paddingLeft: 20,
  },
});
export default RepairCardScreen;