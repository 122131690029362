import React from "react";
import { TouchableOpacity, Text, View, Image, Picker } from "react-native";
import { Collapse } from "react-collapse";

import { stylesMagazzino } from "../../../../GeneralStyles/MagazzinoStyle";
import { useShow } from "../../../../hooks/useShow";
import { CollapseTitleAtelier } from "./CollapseTitleAtelier";

interface Props {
    searchInput: any;
    onChange: any;
    title: any;
}

export const FilterSelectMagazzinoAtelier = ({ searchInput, onChange, title }: Props) => {

    const { show, toggle } = useShow();

    return (
        <>
            <CollapseTitleAtelier title={title} toggle={toggle} />
            <Collapse isOpened={show}>
                <View style={{ flexDirection: 'row', width: '85%' }}>
                    <Picker
                        selectedValue={searchInput}
                        style={[stylesMagazzino.input, stylesMagazzino.subSearch]}
                        onValueChange={(itemValue) => onChange(itemValue)}>
                        <Picker.Item label="Seleziona" value="" />
                        <Picker.Item label="Si" value="1" />
                        <Picker.Item label="No" value="0" />
                    </Picker>
                </View>
            </Collapse>
        </>
    );
};
