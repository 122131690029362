import { NativeStackScreenProps } from "@react-navigation/native-stack";
import axios from "axios";
import React, { useEffect } from "react";
///import Cookies from "universal-cookie/es6";
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Input } from "react-native-elements";
import { useForms } from "../hooks/useForm";
import { styles } from "../theme/GlobalStyle";
import { LoginData } from "../interfaces/ILogin";
import ConfigCapone from "../utils/ConfigCapone";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useState } from "react";
import Loading from "../components/Loading";
import PopupMessage from "../components/PopupMessage";
//universal cookie
import Cookies from 'universal-cookie';
interface Props extends NativeStackScreenProps<any, any> { }
export const AssistenzaSergioCapone = ({ navigation }: Props) => {
  let dataLogin: LoginData = {
    name_User: "",
    password_User: "",
  };
  const { userName, password_User, onChange } = useForms({
    userName: dataLogin.name_User,
    password_User: dataLogin.password_User,
  });
  const { userInLogin, setUserInLogin } = useContext(UserContext);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });
  const [showPassword, setShowPassword] = useState(true);
  // cookies
  const cookies = new Cookies();
  const saveUserLoginData = (userData) =>{
    setUserInLogin(userData);  
    cookies.set('userInLogin', userData);
  }
  
  const login = async () => {
    setActiveSpinner(true);
    if (!userName || !password_User) {
      setModalMessage({
        active: true,
        message: "Inserisci nome utente o password",
      });
      setActiveSpinner(false);
      return;
    }
    const res = await axios(
      ConfigCapone.urlapi +
      "/api/Login?userName=" +
      userName.trim() +
      "&pwd=" +
      password_User.trim()
    )
      .then((response) => {
        setActiveSpinner(false);
        onChange("Pepito", "userName");
        onChange("", "password_User");
        if (response.data.status == "success") {
          {/*/
          (response.data.response.user[0].idRole == 3 || response.data.response.user[0].idRole == 7 || response.data.response.user[0].idRole == 8) ?
            navigation.navigate("HomeScreen", response.data.response.user[0]) : navigation.navigate("HomeSCDueScreen",response.data.response.user[0]);
          //*/
          }
          navigation.navigate("Login2FactorScreen", response.data.response.user[0]);
          return (response.data.response);
        } else if (response.data.status == "error" && response.data.response == "User blocked") {
          setModalMessage({ active: true, message: "Utente bloccato" });
        } else {
          setModalMessage({ active: true, message: "Utente non trovato" });
        }
      })
      .catch((error) => setModalMessage({
        active: true,
        message: "Si è verificato un errore.",
      }));
    setActiveSpinner(false);
    
    setUserInLogin ? saveUserLoginData(res?.user[0]): null;
    /*
    if(res != undefined){
      cookies.set('token', res.token);
    }
    */
  };
  const checkIsLogged = () => {
    let token = cookies.get('token');
    let cookiesUserInLogin = cookies.get('userInLogin');
    
    if(cookiesUserInLogin){
      setUserInLogin(cookiesUserInLogin);
    }
    if(token && cookiesUserInLogin){
      const permitedEmailCustom = ConfigCapone.emailPermited.includes(cookiesUserInLogin.emailUser)
      if(permitedEmailCustom){
        navigation.navigate("HomeScreen", cookiesUserInLogin)
      }else{
         (cookiesUserInLogin.idRole == 3 || cookiesUserInLogin.idRole == 7 || cookiesUserInLogin.idRole == 8) ?
            navigation.navigate("HomeScreen", cookiesUserInLogin) : navigation.navigate("HomeSCDueScreen",cookiesUserInLogin); 
      }
     
    }
  }

  useEffect(()=>checkIsLogged(),[]);
  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={styles.scroll}>
        {activeSpinner && <Loading activeSpinner={activeSpinner} />}
        <View
          style={[
            styles.container,
            { paddingHorizontal: 0, marginRight: 0, marginTop: -100 },
          ]}
        >
          <div className="row">
            <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 m-auto">
              <Image
                style={[
                  styles.logo,
                  styles.center,
                  { margin: "auto", left: 12 },
                ]}
                source={require("../assets/images/SergioCaponeLogo.jpg")}
              />
              <View
                style={{ justifyContent: "center", flex: 1, marginTop: 30 }}
              >
                <Text
                  style={[
                    styles.center,
                    {
                      marginTop: 30,
                      fontWeight: "500",
                      fontSize: 30,
                      marginLeft: 20,
                    },
                  ]}
                >
                  Accedi
                </Text>
                <Text style={[styles.label, { marginTop: 35, marginLeft: 25 }]}>Nome utente</Text>
                <Input
                  autoCompleteType="username"
                  inputContainerStyle={[stylesRepair.container, { height: 45 }]}
                  style={{
                    WebkitBoxShadow: "0 0 0 1000px white inset",
                    outlineStyle: "none",
                    maxWidth: "85%",
                  }}
                  underlineColorAndroid="transparent"
                  value={userName}
                  onChangeText={(value) => onChange(value, "userName")}
                  leftIcon={
                    <Image
                      source={require("../assets/iconoskubitsoft/user-01.png")}
                      style={stylesRepair.iconImage}
                    />
                  }
                />
                <Text style={[styles.label, { marginLeft: 25, marginTop: 20 }]}>Password</Text>
                <Input
                  autoCompleteType="password"
                  inputContainerStyle={[stylesRepair.container, { height: 45 }]}
                  style={{
                    WebkitBoxShadow: "0 0 0 1000px white inset",
                    outlineStyle: "none",
                    width: "20%",
                  }}
                  underlineColorAndroid="transparent"
                  secureTextEntry={showPassword}
                  value={password_User}
                  onChangeText={(value) => onChange(value, "password_User")}
                  leftIcon={
                    <Image
                      source={require("../assets/iconoskubitsoft/Security-01.png")}
                      style={stylesRepair.iconImage}
                    />
                  }
                  rightIcon={
                    <TouchableOpacity
                      onPress={() => setShowPassword(!showPassword)}
                    >
                      <Image
                        source={require(showPassword
                          ? "../assets/iconoskubitsoft/Oculto-01.png"
                          : "../assets/iconoskubitsoft/Oculto-01.png")}
                        style={stylesRepair.iconImage}
                      />
                    </TouchableOpacity>
                  }
                  rightIconContainerStyle={{ marginRight: "2%" }}
                />
                <View>
                  <TouchableOpacity
                    activeOpacity={0.6}
                    style={{
                      width: 100,
                      height: 30,
                      alignSelf: "center",
                      marginTop: 15,
                    }}
                    onPress={() => login()}
                  >
                    <View style={styles.button}>
                      <Text style={styles.buttonText}>Accedi</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </div>
          </div>
          {modalMessage.active && (
            <PopupMessage
              title={"Informazione"}
              message={modalMessage.message}
              stateModal={modalMessage.active}
              setStateModal={setModalMessage}
            />
          )}
        </View>
        <Text
          style={{
            alignSelf: "center",
            position: "absolute",
            fontSize: 20,
            color: "rgb(186,186,186)",
            bottom: -200,
            paddingBottom: 70
          }}
        >
          Powered by Qbitsoft
        </Text>
      </ScrollView>
    </View>
  );
};
export const stylesRepair = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  textInput: {
    paddingVertical: 6,
    fontSize: 18,
    width: "80%",
  },
  container: {
    width: "100%",
    height: 40,
    borderWidth: 2,
    borderBottomWidth: 2,
    borderRadius: 50,
    marginVertical: 6,
    paddingLeft: 20,
    fontFamily: "Arsapia Regular font",
    flexDirection: "row",
    alignItems: "center",
    borderColor: "black",
  },
  icon: {
    paddingLeft: 10,
    fontSize: 16,
  },
  iconImage: {
    paddingLeft: -10,
    margin: -40,
    height: 100,
    width: 100,
    resizeMode: "stretch",
  },
  right: {
    alignSelf: "flex-end",
    padding: 20,
  },
  iconRight: {
    paddingRight: 5,
    fontSize: 16,
  },
  button: {
    borderRadius: 20,
    elevation: 2,
    with: "10%",
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    marginBottom: "5%",
  },
  iconImageRigth: {
    margin: "7%",
    height: 110,
    width: 110,
    resizeMode: "stretch",
  },
});
export const inputAutoFill = StyleSheet.create({
  "&:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0 1000px black inset",
  },
});
