export default class ServicesModel {
      idService?: number;
      idClient?: number;
      clientName?: string;
      idServiceType?: number;
      desc_ServiceType?: string;
      dateService: Date=new Date();
      idBrand?: number;
      desc_Brand?: string;
      idProduct?: number;
      desc_product?: string;
      idBussines?: number;
      desc_Bussines?: string;
      description?: string;
      reference?: string;
      enrollment?: string;
      dateInsert?: Date;
      idUser?: number;
      dateModification?: Date;
      active?: boolean;
      total?: number;
      idStatus?:number;
      desc_status?: string;
      envelopeNumber?: string;
      hasPreventive?: boolean;

  }

  