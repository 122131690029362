import React from "react";
import { Collapse } from "react-collapse";

import { useShow } from "../../hooks/useShow";
import { BrandResponse } from "../../interfaces/magazzino";
import { CheckMagazzino } from "./CheckMagazzino";
import { CollapseTitle } from "./CollapseTitle";

interface Props {
  brandList: BrandResponse[];
  handleFilterBrand: (value: number) => void;
}
export const FilterMagazzinoBrand = ({
  brandList,
  handleFilterBrand,
}: Props) => {
  const { show, toggle } = useShow();
  return (
    <>
      <CollapseTitle title="Brand        " toggle={toggle} />
      <Collapse isOpened={show}>
        <div className="col-12">
          {brandList.map(({ idBrand, desc_Brand }) => (
            <div key={idBrand}>
              <CheckMagazzino
                name={desc_Brand}
                id={idBrand}
                text={desc_Brand}
                marginleft
                handlechange={handleFilterBrand}
              />
              <br />
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};
