import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

export const SectionLoading = () => {
  return(
    <View style={[styles.container, styles.horizontal]}>
        <ActivityIndicator size="large" color="#000000" />
    </View>
  )
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: 'absolute',
      top: '45%',
      left: '43%',
      justifyContent: "center"
    },
    horizontal: {
      flexDirection: "row",
      justifyContent: "space-around",
      padding: 10
    }
});