/* eslint-disable multiline-ternary */

import { useField } from 'formik'

export const SelectCustomStep = ({
  campo,
  id,
  placeholder,
  label,
  completed = 'col-md-6',
  options,
  ...props
}: any) => {
  const [field, meta] = useField(props)

  return (
    <div className={completed}>
      <div className="form-group mb-4">
        <label htmlFor={id}>{label}</label>
        <select
          {...field}
          className="form-control"
          id={campo}
          placeholder={placeholder}
          name={campo}
          aria-describedby={placeholder}
          {...props}
        >
          <option value="">Seleziona</option>
          {options.map(({ value, label }: any) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
        {meta.touched && Boolean(meta.error) ? (
          <div className="message-container mb-3">
            <div className="help-block">{meta.error}</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
