import React, { useState } from "react";
import { View, ScrollView } from "react-native";
import { HeaderNewRepair as Header } from "../components/HeaderNewRepair";
import { StackScreenProps } from "@react-navigation/stack";

import Loading from "../components/Loading";
import { Wrapper } from "../components/FormRepair/Wrapper";
import { FormRepairProvider } from "../context/formRepair";

import '../GeneralStyles/FormRepairStyle.css'

interface Props extends StackScreenProps<any, any> {}

export default function FormRepairScreenCopy({ route, navigation }: Props) {
  let titleScreen = route.params
    ? !route.params?.dataCrm
      ? "Modifica riparazione"
      : "Nuova riparazione"
    : "Nuova riparazione";

  const [title, setTitle] = useState(titleScreen);
  const [activeSpinner, setActiveSpinner] = useState(false);

  return (
    <ScrollView>
      {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      <View
        style={{
          paddingHorizontal: 12,
          backgroundColor: "white",
        }}
      >
        <Header
          title={title}
          onBack={() => navigation.navigate("HomeScreen")}
        />
      </View>
      <AppState>
        <div className="row">
          <div className="col-12 layout-spacing mx-auto">
            <div className="statbox widget box box-shadow">
              <Wrapper />
            </div>
          </div>
        </div>
      </AppState>
    </ScrollView>
  );
}

const AppState = ({ children }: any) => {
  return <FormRepairProvider>{children}</FormRepairProvider>;
};
