import { StyleSheet } from "react-native";
export const stylesModal = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 22,
  },
  modalViewTecnic: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 5,
    paddingBottom: 0,
    paddingTop: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: 387,
    minHeight: 610
    // height:663
  },

  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 5,
    paddingBottom: 0,
    paddingTop: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  pickerStyles: {
    borderColor: "#000000",
    height: 30,
    margin: 12,
    borderWidth: 2,
    padding: 15,
    borderRadius: 50,
    marginVertical: 6,
  },
  pickerStylesDisabled: {
    color: "#868686",
    width: 300,
  },
  viewModalVolt: {
    backgroundColor: "rgb(235, 235, 235)",
    borderColor: "rgb(235, 235, 235)",
  },
  dropdown1BtnStyle: {
    width: "95%",
    height: "10%",
    backgroundColor: "rgb(235, 235, 235)",
    borderRadius: 100,
    margin: 18,
    paddingLeft: 10,
    fontFamily: "Arsapia Regular font",
  },
  dropdown1BtnTxtStyle: { color: "#868686", textAlign: "left", fontSize: 16 },
  dropdown1DropdownStyle: { backgroundColor: "#EFEFEF" },
  dropdown1RowStyle: {
    paddingVertical: 8,
    backgroundColor: "#EFEFEF",
    borderBottomColor: "#C5C5C5",
  },
  dropdown1RowTxtStyle: { color: "#444", textAlign: "left" },
  container: {
    width: "89.5%",
    height: 50,
    borderRadius: 50,
    marginVertical: 6,
    fontFamily: "Arsapia Regular font",
    borderColor: "black",
  },
  dropdown1BtnStyleBusiness: {
    width: 350,
    height: "auto",
    minHeight: 50,
    backgroundColor: "rgb(235, 235, 235)",
    borderRadius: 100,
    marginLeft: 10,
    marginBottom: 8,
    marginTop: 18,
    fontFamily: "Arsapia Regular font",
  },
});