import { Calendar } from "react-big-calendar";
import { RouteProp, useRoute } from "@react-navigation/native";

//COMPONENTS
import { HeaderBack } from "../components/Calender/HeaderBack";
import { TolbarDetail } from "../components/Calender/TolbarDetail";
import { HeaderDetail } from "../components/Calender/HeaderDetail";
import { localizer } from "../components/Calender/elements";
import { FilterCalender } from "../components/Calender/FilterCalender";
import { ModalAplication } from "../components/Modal/ModalAplication";
import { AddEvent } from "../components/Calender/AddEvent";
import { EditEvent } from "../components/Calender/EditEvent";
import Loading from "../components/Loading";

//HOOKS
import { useCalenderDetails } from "../hooks/useCalenderDetails";

//CSS
import "react-big-calendar/lib/css/react-big-calendar.css";


import { RootStackParams } from "../navigator/NavigatorConfig";
import { useWidth } from "../core/hook/useWidth";

export const CalenderDetailScreen = () => {
  const { params } =
    useRoute<RouteProp<RootStackParams, "CalenderDetailScreen">>();
  const {
    isLoading,
    negozio,
    options,
    isOpen,
    myEvents,
    event,
    isOpenEdit,
    closeModal,
    handleChange,
    openModalAdd,
    handleAddEvent,
    closeModalEdit,
    openModalEdit,
    handleEditEvent,
    handleDeleteEvent,
  } = useCalenderDetails();

  const { width } = useWidth()

  if (isLoading) return <Loading activeSpinner={isLoading} />;

  return (
    <div className="square calender-screen">
      <HeaderBack />
      <FilterCalender
        negozio={negozio}
        options={options}
        handleChange={handleChange}
        openModalAdd={openModalAdd}
      />
      <div className={width > 900 ? "container" : "container-fluid ml-4 mr-5"} style={{overflow: 'auto'}}>
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-11 mx-auto" style={{minWidth: 400, marginLeft: 5}}>
            <Calendar
              defaultDate={params ? new Date(Number(params.date)) : undefined}
              events={myEvents}
              onSelectEvent={(evt) => openModalEdit(evt)}
              className="calender-details mb-4"
              culture="it-CH"
              localizer={localizer}
              startAccessor="start"
              endAccessor="end"
              style={{ height: "700px" }}
              views={["week"]}
              defaultView="week"
              components={{
                toolbar: TolbarDetail,
                header: HeaderDetail,
              }}
            />
          </div>
        </div>
      </div>

      {isOpen && (
        <ModalAplication
          title="Crea evento"
          isOpen={isOpen}
          closeModal={closeModal}
        >
          <AddEvent
            options={options}
            closeModal={closeModal}
            handleAddEvent={handleAddEvent}
          />
        </ModalAplication>
      )}
      {isOpenEdit && (
        <ModalAplication
          title="Dettagli dell'evento"
          isOpen={isOpenEdit}
          closeModal={closeModalEdit}
        >
          <EditEvent
            options={options}
            closeModal={closeModalEdit}
            handleEditEvent={handleEditEvent}
            event={event}
            handleDeleteEvent={handleDeleteEvent}
          />
        </ModalAplication>
      )}
    </div>
  );
};
