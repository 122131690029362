import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ScrollView,
  Image,
  FlatList,
  Pressable,
} from "react-native";
import "bootstrap/dist/css/bootstrap.min.css";
import { styles } from "../theme/GlobalStyle";
import { useForm, Controller } from "react-hook-form";
import { HeaderNewRepair as Header } from "../components/HeaderNewRepair";
import axios from "axios";
import { StackScreenProps } from "@react-navigation/stack";
import { RepairData } from "../api/RepairData";
import { useIsFocused } from "@react-navigation/native";
import ConfigCapone from "../utils/ConfigCapone";
import { UserContext } from "../context/UserContext";
import Loading from "../components/Loading";
import PopupMessage from "../components/PopUpMessage";
import PopUpMessageWithRoute from "../components/PopUpMessageWithRoute";
import SelectDropdown from "react-native-select-dropdown";
import Icon from "react-native-vector-icons/Ionicons";
import { IClient } from "../interfaces/IClient";
import Cookies from 'universal-cookie';
import { validateEmail } from "../helpers/patterns";
import { FindClient } from "../services/interessiServices";
import Log from "../pages/security/Log";
import { IImagenPreventivo } from "../interfaces/IImagenPreventivo";
import { ContextImagenPreventivo } from "../context/ContextImagenPreventivo";
import format from 'date-fns/format';
interface Props extends StackScreenProps<any, any> {
  isAtelierr?: boolean

}
const styleInput = {
  width: "100%",
  backgroundColor: "transparent",
  height: 50,
  margin: 0,
  borderWidth: 2,
  borderRadius: 50,
  borderColor: "black",
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
};
const styleInputTextArea = {
  width: "100%",
  backgroundColor: "rgb(234, 234, 234)",
  height: "auto",
  borderRadius: 25,
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
  textAlignVertical: "auto",
  padding: 30,
};
export default function FormRepairScreen({ route, navigation, isAtelierr = false }: Props) {

  let arrayData: any = route.params || [];
  let arrayDataCrm: any = route.params || [];

  let titleScreen = route.params ? !route.params?.dataCrm ? "Modifica riparazione" : "Nuova riparazione" : "Nuova riparazione";

  const [title, setTitle] = useState(titleScreen);

  const [searchInputName, setSearchInputName] = useState<string>();
  const [autosuggestInputName, setAutosuggestInputName] = useState<IClient[]>([]);
  // const [imagenes, setImagenes] = useState<IImagenPreventivo[]>([]);
  const { imagenes, setImagenes } = useContext(ContextImagenPreventivo);
  //verify number
  const [idClientExist, setIdClientExist] = useState<number>(0)
  const [backSelectTitle, setBackSelectTitle] = useState("white");
  const [backSelectStore, setBackSelectStore] = useState("white");
  const [backSelectWatch, setBackSelectWatch] = useState("white");
  const [bussinnessType, setBussinnessType] = useState([]);
  const [brandType, setBrandType] = useState([]);
  const [titleType, setTitleType] = useState([]);
  const [productType, setProductType] = useState([]);
  const [images, setImages] = useState<any>();
  const [imageArray, setImageArray] = useState('');
  const [imageArrayList, setImageArrayList] = useState<any>([]);
  const [nameImgSelectedList, setNameImgSelectedList] = useState([]);
  const [typeFile, setTypeFile] = useState<any>();
  const [idService, setIdService] = useState(null);
  const [idClient, setIdClient] = useState(null);
  const [arrayDataClient, setArrayDataClient] = useState<any>();
  const [filesJsonString, setFilesJsonString] = useState<string>("");
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });
  const cookies = new Cookies();
  const { userInLogin } = useContext(UserContext);
  const [isEmployee, setIsEmployee] = useState<boolean>(userInLogin?.idRole === '4' ? true : false);
  const [isEmployeeAtelier] = useState<boolean>(userInLogin?.idRole === "4" && userInLogin?.idBussines === '9' ? true : false)
  const [isDirectorAtelier] = useState<boolean>(userInLogin?.idRole === "5" && userInLogin?.idBussines === '9' ? true : false)

  const [isTechniqueViareggio] = useState<boolean>(
    (userInLogin?.idRole === "3" || userInLogin?.idRole === "7" || userInLogin?.idRole === "8") && userInLogin?.idBussines === '3' ? true : false
  )

  const [isTechniquePisa] = useState<boolean>(
    (userInLogin?.idRole === "3" || userInLogin?.idRole === "7" || userInLogin?.idRole === "8") && userInLogin?.idBussines === '1' ? true : false
  )

  const [botonDisabled, setBotonDisabled] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [nameImgSelected, setNameImgSelected] = useState("");
  const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({
    title: "Informazione",
    message: "",
    active: false,
    route: "", 
  });
  const confirmAction = (Landing: any, data: any) => {
    navigation.navigate(Landing, data);
    setmodaleMessaggeWithUrl({
      title: "Informazione",
      message: "",
      active: false,
      route: "",
    });
  };
  const sendLogg = async (mesaggeN: string) => {
    let selectedMessage = {
      message: mesaggeN,
    };
    await axios
      .post(`${ConfigCapone.urlapi}/api/Notify/Logg/`, selectedMessage, {
        headers: {
          'Authorization': `Bearer ${cookies.get('token')}`
        }
      })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => console.log(error));
  };
  const errorMessageRequired = "Il campo è obbligatorio";
  const getBussiness = async () => {
    setActiveSpinner(true);
    const res = await axios(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`)
      .then((response) => {
        let resp = response.data.response;
        setBussinnessType(resp)
        if (isTechniquePisa) {
          let newresp = resp.filter((data: any) => ['1', '5', '2', '6'].includes(data.idBussines));
          setBussinnessType(newresp);
        }
        if (isTechniqueViareggio) {
          let newresp = resp.filter((data: any) => ['3', '9', '4'].includes(data.idBussines));
          setBussinnessType(newresp);
        }
      })
      .catch((error) => console.log(error));
    setActiveSpinner(false);
  };
  const getBrand = async () => {
    setActiveSpinner(true);
    let brand: any = [];
    let brandFilter: any = [];
    const rep = await axios(`${ConfigCapone.urlapi}/api/Brand?parameter=1`)
      .then((response) => brand = response.data.response)
      .catch((error) => console.log(error));
    brandFilter = brand.filter((data: any) => data.desc_Brand != "Usato")  //data.desc_Brand != "Altro" &&
    setBrandType(brandFilter)
    setActiveSpinner(false);
  };
  const getTitle = async () => {
    setActiveSpinner(true);
    const rep = await axios(`${ConfigCapone.urlapi}/api/Title?parameter=1`)
      .then((response) => setTitleType(response.data.response))
      .catch((error) => console.log(error));
    setActiveSpinner(false);
  };
  const getProduct = async () => {
    setActiveSpinner(true);
    const rep = await axios(`${ConfigCapone.urlapi}/api/Product?parameter=1`)
      .then((response) => setProductType(response.data.response))
      .catch((error) => console.log(error));
    setActiveSpinner(false);
  };

  let dataCrm = arrayDataCrm ? arrayDataCrm?.dataCrm ? arrayDataCrm?.dataCrm : "" : "";

  let clientId: number = 0;

  const [clientExists, setClientExists] = useState<boolean>(false);
  const [existingClientId, setExistingClientId] = useState<number>(0);

  //verify number
  const verifyPhone = async () => {
    let verifyPhone = getValues("telephone") ? getValues("telephone") : null;
    if (verifyPhone) {
      let result = await FindClient(verifyPhone);
      if (title == "Modifica riparazione") {
        // edit
        if (Object.keys(result)?.length > 0) {
          let data = result.find(x => Number(x.idClient) !== route.params?.idClient ? route.params?.idClient : idClientExist)
          if (data) {
            if (Object.keys(data)?.length > 0) {
              let numberExist = result?.find(x => Number(x.idClient) == route.params?.idClient ? route.params?.idClient : idClientExist)
              if (Number(numberExist?.idClient) == route.params?.idClient ? route.params?.idClient : idClientExist) {
                return true;
              } else {
                return false;
              }
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        // add
        if (Object.keys(result)?.length > 0) {
          let data = result?.find(x => Number(x.phone) == getValues("telephone"))
          if (data) {
            if (Number(data?.idClient) == route.params?.idClient ? route.params?.idClient : dataCrm != "" ? dataCrm.idClient : idClientExist) {
              return true;
            } else {
              return false;
            }

          } else {
            return true;
          }

        } else {
          return true;
        }

      }
    }
    return false;
  }

  const autocompleteClientData = (data: any) => {
    setValue('title', parseInt(data.idTitle), { shouldValidate: true });
    setValue('name', data.name, { shouldValidate: true });
    setValue('address', data.address, { shouldValidate: true });
    setValue('email', data.email, { shouldValidate: true });
    setValue('telephone', data.phone, { shouldValidate: true });
    setValue('business', data.origenIdBussines, { shouldValidate: true });
  }

  const searchClient = async (data?: IClient) => {
    setIdClientExist(Number(data?.idClient))
    if (title != "Modifica riparazione") {
      let names = getValues("name");
      let phone = getValues("telephone");

      let removeClientData = () => {
        setValue('address', '');
        setValue('email', '');
      }

      let checkExists: boolean = false;

      autocompleteClientData(data);

      setExistingClientId(parseInt(data.idClient));

      if (!clientExists) {
        setmodaleMessaggeWithUrl({
          active: true,
          message: "il cliente è già esistente",
          title: "Informazione",
          route: "",
        });
      }

      checkExists = true

      if (checkExists) {
        setClientExists(true);
      } else {
        setClientExists(false);
        //removeClientData();
      }

    }
  }

  let dataBussines = arrayData ? arrayData.idBussines : "";
  if (isEmployee || isDirectorAtelier) {
    dataBussines = userInLogin.idBussines;
  }

  let dataBussinesCrm = dataCrm ? dataCrm.origenIdBussines : "";

  let valueDefect: any;

  if (arrayData) {
    valueDefect = {
      title: arrayData ? arrayData.idTitle : "",
      name: arrayData ? arrayData.name : "",
      address: arrayData ? arrayData.address : "",
      email: arrayData ? arrayData.email : "",
      telephone: arrayData ? arrayData.phone : "",
      business: dataBussines,
      brandOfProduct: arrayData ? arrayData.idBrand : "",
      referens: arrayData ? arrayData.reference : "",
      enrollment: arrayData ? arrayData.enrollment : "",
      envelopeNumber: arrayData ? arrayData.envelopeNumber : "",
      note: arrayData ? arrayData.description : "",
      product: arrayData ? arrayData.idProduct : "",
    };

  }

  const updateArrayData = () => {

  }

  const loadFiles = (files) => {
    if (files) {
      if (files.startsWith("[")) {
        const jsonFiles = JSON.parse(files);
        setImageArrayList(jsonFiles)
      } else {
        let filesName = [];
        const finishIndex = files.lastIndexOf(".");
        const extension = files.slice(finishIndex + 1);

        let currentFile = ""

        if (extension.toLowerCase() == 'png' || extension.toLowerCase() == 'gif' || extension.toLowerCase() == 'jfif'
          || extension.toLowerCase() == 'pjp' || extension.toLowerCase() == 'jpg' || extension.toLowerCase() == 'pjpeg' || extension.toLowerCase() == 'jpeg') {
          currentFile = 'image'
        } else if (extension.toLowerCase() == 'pdf') {
          currentFile = 'application'
        } else {
          currentFile = 'application'
        }
        filesName.push({ id: 1, name: files, type: currentFile + '/' + extension });
        setImageArrayList(filesName)
      }
    }
  }

  if (Object.keys(dataCrm).length > 0) {
    console.log('arrayDataCrm', arrayDataCrm);
    valueDefect = {
      title: arrayDataCrm ? arrayDataCrm?.dataCrm?.idTitle : "",
      name: arrayDataCrm ? arrayDataCrm?.dataCrm?.name : "",
      address: arrayDataCrm ? arrayDataCrm?.dataCrm?.address : "",
      email: arrayDataCrm ? arrayDataCrm?.dataCrm?.email : "",
      telephone: arrayDataCrm ? arrayDataCrm?.dataCrm?.phone : "",
      business: dataBussinesCrm,
      brandOfProduct: arrayDataCrm ? arrayDataCrm?.dataCrm?.idBrand : "",
      referens: arrayDataCrm ? arrayDataCrm?.dataCrm?.reference : "",
      enrollment: arrayDataCrm ? arrayDataCrm?.dataCrm?.enrollment : "",
      envelopeNumber: arrayDataCrm ? arrayDataCrm?.dataCrm?.envelopeNumber : "",
      note: arrayDataCrm ? arrayDataCrm?.dataCrm?.description : "",
      product: arrayDataCrm ? arrayDataCrm?.dataCrm?.idProduct : "",
    };

    clientId = arrayDataCrm?.dataCrm?.idClient
  }



  useEffect(() => {

    if (Object.keys(dataCrm).length > 0) {
      setClientExists(true)
      setExistingClientId(arrayDataCrm?.dataCrm?.idClient)
    }

  }, [dataCrm])



  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: valueDefect,
  });
  const [dataRepair, setDataRepair] = useState<any>(RepairData);

  const handleDataEditRepair = async (activeNavigation: boolean = false) => {
    setActiveSpinner(true);
    let verifyN = await verifyPhone()
    if (verifyN) {
      const verifyService = idService
        ? idService
        : setIdService(arrayData.idService);

      const verifyClient = idClient ? idClient : arrayData.idClient;
      const clientEdit = {
        idClient: verifyClient,
        idTitle: Number(getValues("title")),
        name: getValues("name"),
        lastName: getValues("name"),
        address: getValues("address"),
        email: getValues("email"),
        phone: getValues("telephone"),
        dateInsert: new Date(),
        idUser: userInLogin?.idUser,
        dateModification: new Date(),
        origenIdBussines: Number(getValues("business")),
        active: true,
      };

      let date = new Date().toLocaleDateString();
      date = date.replaceAll('/', '-');
      let hour = new Date().toLocaleTimeString();
      hour = hour.replaceAll(':', '-');
      date += '_' + hour;
      date = date.replace(/\s/g, '');
      sendLogg(` ha modificato al cliente ${getValues("name")} il assistenza`)
      await axios
        .put(`${ConfigCapone.urlapi}/api/Client`, clientEdit)
        .then(async (response) => {

          let originalFileName: string = nameImgSelected;
          let extension: string = originalFileName.substr(
            originalFileName.lastIndexOf(".") + 1
          );

          let fileName: string = response.data.id + '_' + date + "." + extension;

          let serviceEdit = {};
          let clientId: number = response.data.id;
          let json = await saveFiles(clientId);

          if (extension.length > 0) {
            serviceEdit = {
              idService: verifyService,
              idClient: verifyClient,
              idServiceType: 1,
              dateService: new Date(),
              idBrand: Number(getValues("brandOfProduct")),
              idProduct: 1,
              idBussines: Number(getValues("business")),
              description: getValues("note"),
              reference: getValues("referens"),
              enrollment: getValues("enrollment"),
              envelopeNumber: getValues("envelopeNumber"),
              dateInsert: new Date(),
              idUser: userInLogin?.idUser,
              dateModification: new Date(),
              active: true,
              total: 0,
              idStatus: 1,
              fileName: json
            };
          } else {

            serviceEdit = {
              idService: verifyService,
              idClient: verifyClient,
              idServiceType: 1,
              dateService: new Date(),
              idBrand: Number(getValues("brandOfProduct")),
              idProduct: 1,
              idBussines: Number(getValues("business")),
              description: getValues("note"),
              reference: getValues("referens"),
              enrollment: getValues("enrollment"),
              envelopeNumber: getValues("envelopeNumber"),
              dateInsert: new Date(),
              idUser: userInLogin?.idUser,
              dateModification: new Date(),
              active: true,
              total: 0,
              idStatus: 1,
              fileName: json,
            };

          }

          if(arrayData){
              arrayData.idTitle = clientEdit.idTitle,
              arrayData.name = clientEdit.name,
              arrayData.address = clientEdit.address,
              arrayData.email = clientEdit.email,
              arrayData.phone = clientEdit.phone,
              //business= dataBussines,
              arrayData.idBrand = Number(getValues("brandOfProduct")),
              arrayData.reference = getValues("referens"),
              arrayData.enrollment = getValues("enrollment"),
              arrayData.envelopeNumber = getValues("envelopeNumber"),
              arrayData.description = getValues("note")
          }


          sendLogg(` ha modificato riparazione Referenza ${getValues("referens")} Matricola ${getValues("enrollment")} del cliente  ${getValues("name")} il assistenza`)
          await axios
            .put(`${ConfigCapone.urlapi}/api/Service`, serviceEdit)
            .then((response) => {
              if (!activeNavigation) {
                setModalMessage({
                  active: true,
                  message: "Modificato il servizio con successo.",
                });
              }
            })
            .catch((error) =>
              setModalMessage({
                active: true,
                message: "Si è verificato un errore durante il salvataggio.",
              })
            );
          if (activeNavigation) {
            isAtelierr 
            ?
            navigation.navigate("PreventivoRiparazioni", {
              id: idService ? idService : null, imageArrayList: json ? JSON.parse(json) : null
            })
            :
            navigation.navigate("BudgetScreen", {
              id: idService ? idService : null, imageArrayList: json ? JSON.parse(json) : null
            });
          }
        })
        .catch((error) =>
          setModalMessage({
            active: true,
            message: "Si è verificato un errore durante il salvataggio 1.",
          })
        );
    } else {
      setmodaleMessaggeWithUrl({
        active: true,
        message: "Numero di telefono già registrato",
        title: "Errore",
        route: "",
      });
    }

    setActiveSpinner(false);
  };

  const getItemText = (item: IClient) => {
    let mainText = item.name + " - " + item.phone;
    return (
      <div>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ marginLeft: 10, flexShrink: 1 }}>
            <Text style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}>{mainText}</Text>
          </View>
        </View>
      </div>
    );
  };

  const handleDataRepair = async (activeNavigation: boolean = false) => {
    setBotonDisabled(true);
    setActiveSpinner(true);
    let verifyN = await verifyPhone()
    if (verifyN) {
      const clientNew = {
        idClient: 0,
        idTitle: Number(getValues("title")),
        name: getValues("name"),
        lastName: getValues("name"),
        address: getValues("address"),
        email: getValues("email"),
        phone: getValues("telephone"),
        dateInsert: new Date(),
        idUser: userInLogin?.idUser,
        dateModification: new Date(),
        active: true,
        origenIdBussines: null,
      };

      let originalFileName: string = nameImgSelected;
      let extension: string = originalFileName.substr(
        originalFileName.lastIndexOf(".") + 1
      );

      let date = new Date().toLocaleDateString();
      date = date.replaceAll('/', '-');
      let hour = new Date().toLocaleTimeString();
      hour = hour.replaceAll(':', '-');
      date += '_' + hour;
      date = date.replace(/\s/g, '');

      if (!clientExists) {
        clientNew.origenIdBussines = Number(getValues("business"))
        await axios
          .post(`${ConfigCapone.urlapi}/api/Client`, clientNew, {
            headers: {
              'Authorization': `Bearer ${cookies.get('token')}`
            }
          })
          .then(async (response) => {
            let fileName: string = response.data.id + '_' + date + "." + extension;
            if (extension.length == 0) {
              fileName = '';
            }

            let clientId: number = response.data.id;
            setIdClient(response.data.id);
            let json = await saveFiles(clientId);

            const serviceNew = {
              idService: 0,
              idClient: response.data.id,
              idServiceType: 1,
              dateService: new Date(),
              idBrand: Number(getValues("brandOfProduct")),
              idProduct: 1,
              idBussines: Number(getValues("business")),
              //idBussines: userInLogin.idBussines,
              description: getValues("note"),
              reference: getValues("referens"),
              enrollment: getValues("enrollment"),
              envelopeNumber: getValues("envelopeNumber"),
              dateInsert: new Date(),
              idUser: userInLogin?.idUser,
              dateModification: new Date(),
              active: true,
              total: 0,
              idStatus: 1,
              fileName: json,
            };

            sendLogg(` ha aggiunto al cliente ${clientNew.name}, ha aggiunto nuova riparazione Referenza ${serviceNew.reference} Matricola ${serviceNew.enrollment} il assistenza`)
            await axios
              .post(`${ConfigCapone.urlapi}/api/Service`, serviceNew, {
                headers: {
                  'Authorization': `Bearer ${cookies.get('token')}`
                }
              })
              .then((response) => {
                setIdService(response.data.id);
                setActiveSpinner(false);
                setmodaleMessaggeWithUrl({
                  active: true,
                  message: "Riparazione salvata con successo.",
                  title: "Informazione",
                  route: "",
                });
                if (activeNavigation) {
                  if (response.data.id) {
                    isAtelierr 
                      ?
                      navigation.navigate("PreventivoRiparazioni", {
                        id: idService ? idService : response.data.id, imageArrayList: json ? JSON.parse(json) : null
                      })
                      :
                      navigation.navigate("BudgetScreen", {
                        id: idService ? idService : response.data.id, imageArrayList: json ? JSON.parse(json) : null
                      });
                  }
                }
                setTitle("Modifica riparazione");
              })
              .catch((error) => {
                setActiveSpinner(false);
                setmodaleMessaggeWithUrl({
                  active: true,
                  message: "Si è verificato un errore durante il salvataggio.",
                  title: "Errore",
                  route: "",
                });
              });
          })
          .catch((error) => {
            setActiveSpinner(false);
            setmodaleMessaggeWithUrl({
              active: true,
              message: "Si è verificato un errore durante il salvataggio.",
              title: "Errore",
              route: "",
            });
          });
      } else {

        let fileName: string = existingClientId + '_' + date + "." + extension;

        if (extension.length == 0) {
          fileName = '';
        }

        const clientEdit = {
          idClient: existingClientId,
          idTitle: Number(getValues("title")),
          name: getValues("name"),
          lastName: getValues("name"),
          address: getValues("address"),
          email: getValues("email"),
          phone: getValues("telephone"),
          dateInsert: new Date(),
          idUser: userInLogin?.idUser,
          dateModification: new Date(),
          origenIdBussines: Number(getValues("business")),
          active: true,
        };

        let json = await saveFiles(existingClientId);
        const serviceNew = {
          idService: 0,
          idClient: existingClientId,
          idServiceType: 1,
          dateService: new Date(),
          idBrand: Number(getValues("brandOfProduct")),
          idProduct: 1,
          idBussines: Number(getValues("business")),
          //idBussines: userInLogin.idBussines,
          description: getValues("note"),
          reference: getValues("referens"),
          enrollment: getValues("enrollment"),
          envelopeNumber: getValues("envelopeNumber"),
          dateInsert: new Date(),
          idUser: userInLogin?.idUser,
          dateModification: new Date(),
          active: true,
          total: 0,
          idStatus: 1,
          fileName: json,
        };
        sendLogg(` ha aggiunto nuova riparazione Referenza ${serviceNew.reference} Matricola ${serviceNew.enrollment} del cliente ${getValues("name")} il assistenza`)

        await axios
          .put(`${ConfigCapone.urlapi}/api/Client`, clientEdit)
          .then(async (response) => {
            sendLogg(` ha modificato al cliente ${getValues("name")} il assistenza`)
          })

        await axios
          .post(`${ConfigCapone.urlapi}/api/Service`, serviceNew, {
            headers: {
              'Authorization': `Bearer ${cookies.get('token')}`
            }
          })
          .then((response) => {
            setIdService(response.data.id);
            setActiveSpinner(false);
            setmodaleMessaggeWithUrl({
              active: true,
              message: "Riparazione salvata con successo.",
              title: "Informazione",
              route: "",
            });
            if (activeNavigation) {
              if (response.data.id) {
                isAtelierr 
                ?
                  navigation.navigate("PreventivoRiparazioni", {
                    id: idService ? idService : response.data.id, imageArrayList: json ? JSON.parse(json) : null
                  })
                :
                  navigation.navigate("BudgetScreen", {
                    id: idService ? idService : response.data.id, imageArrayList: json ? JSON.parse(json) : null
                  });
              }
            }
            setTitle("Modifica riparazione");
          })
          .catch((error) => {
            setActiveSpinner(false);
            setmodaleMessaggeWithUrl({
              active: true,
              message: "Si è verificato un errore durante il salvataggio.",
              title: "Errore",
              route: "",
            });
          })
      }
    } else {
      setmodaleMessaggeWithUrl({
        active: true,
        message: "Numero di telefono già registrato",
        title: "Errore",
        route: "",
      });
    }

    setActiveSpinner(false);
  };

  const uploadFiles = (e: any, type: string) => {
    setImages(e);
    setTypeFile(type);

    if (type == 'image') {
      let selectedImages = Array.from(e);
      setImages(selectedImages);

      let imageArrayListCopy = [...imageArrayList];
      selectedImages.forEach((item: any) => {
        imageArrayListCopy.push(item);
        nameImgSelectedList.push(item ? item.name : null);
      });
      setImageArrayList(imageArrayListCopy);
    } else if (type == 'document') {
      let selectedPdf = Array.from(e);
      let imageArrayListCopy = [...imageArrayList];
      selectedPdf.forEach((item: any) => {
        imageArrayListCopy.push(item);
        nameImgSelectedList.push(item ? item.name : null);
      });
      setImageArrayList(imageArrayListCopy);
    }
  };

  const removeFiles = (index: number) => {
    const removedElement = imageArrayList.splice(index, 1)[0];
    setImageArrayList([...imageArrayList]);
  }

  const sendEmail = async () => { };

  if (title == "Nuova riparazione" && (isEmployeeAtelier || isDirectorAtelier)) {
    valueDefect.name = 'Atelier Capone'
    valueDefect.title = '10'
  }

  useEffect(() => {
    reset(valueDefect);
  }, []);

  const isFocused = useIsFocused();

  useEffect(() => {
    reset(valueDefect);
    getBussiness();
    getBrand();
    getTitle();
    getProduct();
    setIdService(arrayData.idService);
    arrayData && loadFiles(arrayData.fileName);
  }, [isFocused]);

  const formatDateFile = () => {
    let date = new Date().toLocaleDateString();
    date = date.replaceAll('/', '-');
    let hour = new Date().toLocaleTimeString();
    hour = hour.replaceAll(':', '-');
    date += '_' + hour;
    date = date.replace(/\s/g, '');
    return date
  }

  const saveFiles = async (clientId: any) => {
    var imageFile: any = document.querySelector("#image-files");
    var documentFile: any = document.querySelector("#document-files");

    let filesName = [];

    setFilesJsonString("");

    if (imageFile.files.length > 0) {
      for (var i = 0; i < imageFile.files.length; i++) {
        var formData = new FormData();
        var currentFile = imageFile.files[i];
        const extension: string = currentFile.name.substr(
          currentFile.name.lastIndexOf(".") + 1
        );
        const fileName: string =
          clientId +
          "_" +
          formatDateFile() +
          "_" +
          i +
          "." +
          extension?.toLowerCase();
        let myNewFile = new File([currentFile], fileName, {
          type: currentFile.type,
        });

        filesName.push({ id: i + 1, name: fileName, type: currentFile.type });

        formData.append("files", myNewFile);
        formData.append("type", "image");
        formData.append("name", fileName);
        formData.append("extension", extension);

        try {
          const res = await axios.post(
            `${ConfigCapone.apicapone}/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("upload success");
        } catch (error) {
          console.log("err", error);
        }
      }

      imageFile.value = '';
    }

    if (documentFile.files.length > 0) {
      for (var i = 0; i < documentFile.files.length; i++) {
        var formData = new FormData(); // Crea un nuevo FormData en cada iteración

        var currentFile = documentFile.files[i];
        const extension: string = currentFile.name.substr(
          currentFile.name.lastIndexOf(".") + 1
        );
        const fileName: string =
          clientId +
          "_" +
          formatDateFile() +
          "_" +
          i +
          "." +
          extension?.toLowerCase();

        let myNewFile = new File([currentFile], fileName, {
          type: currentFile.type,
        });

        filesName.push({ id: i + 1, name: fileName, type: currentFile.type });

        formData.append("files", myNewFile);
        formData.append("type", "application");
        formData.append("name", fileName);
        formData.append("extension", extension);

        try {
          const res = await axios.post(
            `${ConfigCapone.apicapone}/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("upload success");
        } catch (error) {
          console.log("err", error);
        }
      }
      documentFile.value = '';
    }

    const filteredImages = imageArrayList.filter((image) => 'id' in image);

    if (filesName.length > 0) {
      let dataJson = [...filteredImages, ...filesName]
      setImageArrayList([])
      setFilesJsonString(JSON.stringify(filesName));
      setImageArrayList(dataJson)
      return JSON.stringify(dataJson)
    }

    if (filteredImages.length > 0) {
      return JSON.stringify(filteredImages)
    }
    return ""
  };



  const removeFile = () => {
    setNameImgSelected('');
    setImageArray('');
    var imageFile: any = document.getElementById("image-files");
    var documentFile: any = document.getElementById("document-files");
    imageFile.value = null;
    documentFile.value = null;
    let imageContainer: any = document.getElementById("img-container");
    imageContainer.classList.add("d-none");
  }

  const removeFileData = () => {
    setNameImgSelected('');
    setImageArray('');

    var imageFile: any = document.getElementById("image-files");
    var documentFile: any = document.getElementById("document-files");

    imageFile.value = null;
    documentFile.value = null;

    let imageContainer: any = document.getElementById("img-container-2");
    imageContainer.classList.add("d-none");

  }

  const onChangeTextName = async (text: string) => {
    setSearchInputName(text);
    if (text.length > 0) {
      //setActiveSpinner(true);

      await axios
        .get(`${ConfigCapone.urlapi}/api/Client/Assistenza/${text}/10/`)
        .then((response) => {
          setAutosuggestInputName(response.data.response)
        })
        .catch((err) => console.log(err));
      //setActiveSpinner(false);
    }
  };

  return (
    <ScrollView>
      {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      {!isAtelierr &&
        <View
          style={{
            paddingHorizontal: 12,
            backgroundColor: "white",
          }}
        >
          <Header
            title={title}
            onBack={() => navigation.navigate("HomeScreen")}
          />
        </View>
      }

      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="col-md-10 col-sm-10 col-12 m-auto">
          <View style={styles.container}>
            <Text
              style={[
                styles.left,
                {
                  marginTop: 20,
                  marginBottom: 10,
                  fontWeight: "500",
                  fontSize: 20,
                },
              ]}
            >
              Dati anagrafici cliente
            </Text>
            <Text style={styles.label}>Titolo *</Text>
            <Controller
              control={control}
              name="title"
              render={({ field: { onChange, value } }) => (


                <select className="form-control mr-8 ml-8 " autoFocus={true} value={value}
                  onChange={val => onChange(val.target.value)}
                  style={styleInput}
                  aria-label=".form-select-lg example">
                  <option selected>Seleziona</option>
                  {titleType.map((r: any) => (
                    <option key={r.idTitle} value={r.idTitle}>{r.desc_Title}</option>
                  ))}
                </select>
              )}
              rules={{ required: true }}
            />
            {errors.title?.type === "required" && (
              <Text style={stylesNewRepair.labelError}>
                {errorMessageRequired}
              </Text>
            )}
            <Text style={styles.label}>Nome e cognome *</Text>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  className="form-control"
                  value={value}
                  // onBlur={()=>searchClient()}
                  maxLength={25}
                  style={styleInput}
                  onChange={(e) => onChange(e.target.value)}
                  onKeyUp={(e: any) => title != "Modifica riparazione" && onChangeTextName(e.target.value)}
                />
              )}
              rules={{ required: true }}
            />
            {errors.name?.type === "required" && (
              <Text style={stylesNewRepair.labelError}>
                {errorMessageRequired}
              </Text>
            )}

            {searchInputName && autosuggestInputName.length > 0 ? (
              <View style={{
                borderWidth: 1,
                marginTop: -15,
                paddingTop: 15,
                marginLeft: 2,
                marginRight: 2,
                borderTopWidth: 0,
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
              }}>
                <FlatList
                  data={autosuggestInputName}
                  showsVerticalScrollIndicator={false}
                  renderItem={({ item, index }) => (
                    <Pressable
                      style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }]}
                      onPress={() => {
                        setSearchInputName(item.name),
                          setAutosuggestInputName([]),
                          searchClient(item)
                      }
                      }
                    >
                      {getItemText(item)}
                    </Pressable>
                  )}
                  keyExtractor={(item, index) => item.idClient + index}
                />
              </View>
            ) : null}

            {
              clientExists && title != "Modifica riparazione"
              &&
              <div className="alert alert-primary mt-2 p-2 mb-0" role="alert">
                <strong>il cliente è già esistente</strong>
              </div>
            }
            <Text style={styles.label}>Indirizzo</Text>
            <Controller
              control={control}
              name="address"
              render={({ field: { onChange, value, onBlur } }) => (
                <input
                  className="form-control"
                  value={value}
                  maxLength={50}
                  style={styleInput}
                  onChange={(e) => onChange(e.target.value)}
                />
              )}
            />
            <Text style={styles.label}>E-mail </Text>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value, onBlur } }) => (
                <input
                  className="form-control"
                  value={value}
                  maxLength={50}
                  style={styleInput}
                  onChange={(e) => onChange(e.target.value)}
                />
              )}
              rules={{
                // pattern:
                //   /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g,
                pattern: validateEmail
              }}
            />
            {errors.email?.type === "pattern" && (
              <Text style={stylesNewRepair.labelError}>E-mail errata</Text>
            )}
            <Text style={styles.label}>Numero di telefono *</Text>
            <Controller
              control={control}
              name="telephone"
              render={({ field: { onChange, value, onBlur } }) => (
                <input
                  className="form-control"
                  value={value}
                  maxLength={25}
                  //onBlur={()=>searchClient()}
                  type="number"
                  style={styleInput}
                  onChange={(e) => onChange(e.target.value)}
                />
              )}
              rules={{ required: true }}
            />
            {errors.telephone?.type === "required" && (
              <Text style={stylesNewRepair.labelError}>
                {errorMessageRequired}
              </Text>
            )}
            <Text style={styles.label}>Negozio di riferimento *</Text>
            <Controller
              control={control}
              name="business"
              render={({ field: { onChange, value } }) => (
                <select
                  className="form-control mr-8 ml-8 "
                  value={value}
                  disabled={isEmployee || isDirectorAtelier}
                  onChange={(val) => onChange(val.target.value)}
                  style={styleInput}
                  aria-label=".form-select-lg example"
                >
                  <option selected>Seleziona</option>
                  {bussinnessType.map((r: any) => (
                    <option key={r.idBussines} value={r.idBussines}>
                      {r.desc_Bussines}
                    </option>
                  ))}
                </select>
              )}
              rules={{ required: true }}
            />

            {errors.business?.type === "required" && (
              <Text style={stylesNewRepair.labelError}>
                {errorMessageRequired}
              </Text>
            )}

            <Text style={styles.label}>Operatore</Text>
            <TextInput
              style={[
                styles.input,
                {
                  fontWeight: "bold",
                  color: "black",
                  backgroundColor: "rgb(234, 234, 234)",
                },
              ]}
              editable={false}
              value={userInLogin?.name}
            />
            <Text
              style={[
                styles.left,
                {
                  marginTop: 80,
                  marginBottom: 10,
                  fontWeight: "500",
                  fontSize: 20,
                },
              ]}
            >
              Informazioni riparazione
            </Text>
            <Text style={styles.label}>Orologio marcato *</Text>
            <Controller
              control={control}
              name="brandOfProduct"
              render={({ field: { onChange, value, onBlur } }) => (

                <select
                  className="form-control mr-8 ml-8 "
                  value={value}
                  onChange={(val) => onChange(val.target.value)}
                  style={styleInput}
                  aria-label=".form-select-lg example"
                >
                  <option selected>Seleziona</option>
                  {brandType.map((r: any) => (
                    <option key={r.idBrand} value={r.idBrand}>
                      {r.desc_Brand}
                    </option>
                  ))}
                </select>

              )}
              rules={{ required: true }}
            />
            {errors.brandOfProduct?.type === "required" && (
              <Text style={stylesNewRepair.labelError}>
                Il campo è obbligatorio{" "}
              </Text>
            )}
            <Text style={styles.label}>Referenza</Text>
            <Controller
              control={control}
              name="referens"
              render={({ field: { onChange, value, onBlur } }) => (
                <input
                  className="form-control"
                  value={value}
                  maxLength={100}
                  style={styleInput}
                  onChange={(e) => onChange(e.target.value)}
                />
              )}
            />
            <Text style={styles.label}>Matricola</Text>
            <Controller
              control={control}
              name="enrollment"
              render={({ field: { onChange, value, onBlur } }) => (
                <input
                  className="form-control"
                  value={value}
                  maxLength={25}
                  style={styleInput}
                  onChange={(e) => onChange(e.target.value)}
                />
              )}
            />
            <Text style={styles.label}>Numero Busta</Text>
            <Controller
              control={control}
              name="envelopeNumber"
              render={({ field: { onChange, value, onBlur } }) => (
                <input
                  className="form-control"
                  value={value}
                  maxLength={50}
                  style={styleInput}
                  onChange={(e) => onChange(e.target.value)}
                />
              )}
            />
            <View
              style={[
                styles.right,
                {
                  flexDirection: "row",
                  paddingHorizontal: 5,
                  marginTop: 35,
                  marginRight: 0,
                  paddingRight: 0,
                },
              ]}
            >
              <View>
                {nameImgSelected ? (
                  <pre style={{ marginBottom: 0, marginRight: 20 }}>
                    <b>· Selezionato:</b> {nameImgSelected ? nameImgSelected : arrayData.fileName}
                  </pre>
                ) : null}
              </View>
              <View>
                <TouchableOpacity>
                  <label htmlFor="image-files" style={{ cursor: "pointer" }}>
                    <Image
                      style={[
                        styles.iconModal,
                        {
                          tintColor: "#b3b3b3",
                        },
                      ]}
                      source={require("../assets/icons/camera2.png")}
                    />
                  </label>
                  <input
                    type="file"
                    name="image-files"
                    id="image-files"
                    accept="image/png, image/gif, image/jpeg"
                    style={{ display: "none" }}
                    multiple
                    onChange={(e) => uploadFiles(e.target.files, "image")}
                  />
                </TouchableOpacity>
              </View>
              <View>
                <TouchableOpacity>
                  <label htmlFor="document-files" style={{ cursor: "pointer" }}>
                    <Image
                      style={[
                        styles.iconModal,
                        {
                          marginHorizontal: 20,
                          tintColor: "#b3b3b3",
                        },
                      ]}
                      source={require("../assets/icons/link.png")}
                    />
                  </label>
                  <input
                    type="file"
                    name="document-files"
                    id="document-files"
                    accept="application/pdf"
                    multiple
                    style={{ display: "none" }}
                    onChange={(e) => uploadFiles(e.target.files, "document")}
                  />
                </TouchableOpacity>
              </View>
              <View>
                <TouchableOpacity
                  activeOpacity={0.6}
                  onPress={handleSubmit(sendEmail)}
                ></TouchableOpacity>
              </View>
            </View>

            <div className="row mt-3 mb-3">
              {imageArrayList.map((image, index) => (
                <div key={index} className={`col-8 col-sm-6 col-md-4 m-auto text-center ${index > 0 && index % 3 === 0 ? 'mt-3' : ''}`}>
                  {image.name.includes('.pdf')
                    ?
                    <a href={`${ConfigCapone.apicapone}/image/${image.name}`} target="_blank" id="file-container" className="">{image.name}</a>
                    :
                    image?.id
                      ? <img src={`${ConfigCapone.apicapone}/image/${image.name}`} className="img-fluid" alt="Image" style={{ maxWidth: '60%', maxHeight: '60%', border: '1px solid black' }} />
                      : <img src={URL.createObjectURL(image)} className="img-fluid" alt="Image" style={{ maxWidth: '60%', maxHeight: '60%', border: '1px solid black' }} />
                  }

                  <br />
                  <a onClick={() => removeFiles(index)} style={{ cursor: 'pointer' }} className="text-danger">
                    Rimuovere {image.name}
                  </a>
                </div>
              ))}
            </div>

            <Text style={[styles.label, { marginTop: 0 }]}>Note </Text>
            <Controller
              control={control}
              name="note"
              render={({ field: { onChange, value, onBlur } }) => (
                <textarea
                  className="form-control"
                  style={styleInputTextArea}
                  maxLength={1000}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  rows={9}
                ></textarea>
              )}
            />
            <Text style={styles.label}></Text>
            <div hidden={true}>
              <TouchableOpacity>
                <Text style={[styles.label, { fontWeight: "bold" }]}>
                  + Aggiungi
                </Text>
              </TouchableOpacity>
            </div>

            <div className="row mt-5 pt-3">
              <div className="col-6 d-flex justify-content-start">
                <TouchableOpacity
                  onPress={
                    title === "Modifica riparazione"
                      ? handleSubmit(() => handleDataEditRepair(true))
                      : handleSubmit(() => handleDataRepair(true))
                  }

                >
                  <View
                    style={
                      idService ? [styles.buttonWhite] : [styles.buttonWhite]
                    }
                  >
                    <Text style={styles.buttonTextWhite}>Preventivo</Text>
                  </View>
                </TouchableOpacity>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <TouchableOpacity
                  onPress={
                    title === "Modifica riparazione"
                      ? handleSubmit(() => handleDataEditRepair(false))
                      : handleSubmit(() => handleDataRepair(false))
                  }

                >
                  <View style={[styles.button]}>
                    <Text style={styles.buttonText}>
                      {title === "Modifica riparazione" ? "Salva Modifiche" : "Salva"}
                    </Text>
                  </View>
                </TouchableOpacity>
              </div>
            </div>
            {modaleMessaggeWithUrl.active && (
              <PopUpMessageWithRoute
                routeNav={modaleMessaggeWithUrl.route}
                dataNav={[]}
                title={modaleMessaggeWithUrl.title}
                message={modaleMessaggeWithUrl.message}
                stateModal={modaleMessaggeWithUrl.active}
                setStateModal={setmodaleMessaggeWithUrl}
                confirmAction={confirmAction}
              />
            )}
            {modalMessage.active && (
              <PopupMessage
                title={"Informazione"}
                message={modalMessage.message}
                stateModal={modalMessage.active}
                setStateModal={setModalMessage}
              />
            )}
          </View>
        </div>
      </div>
    </ScrollView>
  );
}
const stylesNewRepair = StyleSheet.create({
  dropdown1BtnTxtStyle: { color: "#444", textAlign: "left" },
  dropdown1DropdownStyle: { backgroundColor: "#EFEFEF" },
  dropdown1RowStyle: {
    backgroundColor: "#EFEFEF",
    borderBottomColor: "#C5C5C5",
  },
  labelError: {
    fontSize: 18,
    marginLeft: 35,
    marginTop: 15,
    fontFamily: "Arsapia Regular font",
    color: "red",
  },
  picker: {
    height: 50,
    cursor: "pointer",
    width: "100%",
    alignSelf: "flex-end",
    fontFamily: "Arsapia Regular font",
    borderWidth: 2,
    paddingHorizontal: 10,
    borderRadius: 50,
    marginVertical: 6,
  },
  dropdown1RowTxtStyle: {
    color: "#444",
    fontSize: 16,
    fontFamily: "Arsapia Regular font",
    paddingVertical: 8,
    paddingTop: 12,
    textAlign: "left",
  },
});