import { BiCalendar } from "react-icons/bi";

export const HeaderCalender = () => {
  return (
    <div className="d-flex justify-content-start align-items-center mb-2 fw-bold">
      <BiCalendar className="me-2" size={24} color="#66d19f"/>
      <span>Calendario</span>
    </div>
  );
};
