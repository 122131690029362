import { NativeStackScreenProps } from "@react-navigation/native-stack";
import ShowContentSidebar from "../../../../components/NavProfile/ShowContentSidebar";
import { useProfile } from "../../../../components/NavProfile/hook/useProfile";
import { Navbar } from "../../../../components/Navbar";
import { useIsFocused } from "@react-navigation/native";
import NavProfile from "../../../../components/NavProfile/NavProfile";
import ButtonComponent from "../../../../components/ComponentsAtelier/buttoms/buttonComponent";
import InputCheck from "../../../CRM/components/InputChek";
import InputComponent from "../../../../components/ComponentsAtelier/inputs/inputComponent";
import ButtonOrdini from "../../../../components/ComponentsAtelier/buttoms/ButtonOrdini";
import Pagination from "../../../../components/Pagination/pagination";
import { ModalAplication } from "../../../../components/Modal/ModalAplication";
import NuovaListaOrdini from "../Form/NuovaListaOrdini";
import useFormListOrdiniAtelier from "./hooks/useFormListOrdiniAtelier";
import { ListeScreen } from "../../ListeScreen";

interface Props extends NativeStackScreenProps<any, any> { }

const ListOrdiniAtelier = ({ navigation, route }: Props) => {
    const isFocused = useIsFocused();
    const { sidebarVisible, toggleSidebar } = useProfile();
    const iconSearch = require("../../../../assets/iconoskubitsoft/Lupa-02.png");

    const { openModal, handleCloseModal, handleOperationModal, operationModal } = useFormListOrdiniAtelier();

    return (
        <>
            <ShowContentSidebar
                sidebarVisible={sidebarVisible}
                toggleSidebar={toggleSidebar}
            />
            <div className="container-layout">
                <div className="row row-layout">
                    <div
                        className={` ${sidebarVisible
                            ? "col-12 d-block"
                            : "col-lg-3 col-md-none d-none d-md-non d-lg-block"
                            } sidebar-container-layout sidebar-transition`}
                    >
                        <Navbar
                            activeNav={isFocused}
                            componentName={'OrdiniAtelier'}
                            componentDad="OrdiniAtelier"
                        />
                    </div>
                    <div
                        className="col-lg-9 col-md-12 col-sm-12 content-container-layout "
                        style={{ paddingLeft: "1.5rem" }}
                    >
                        <NavProfile
                            showMenuBack={true}
                            sidebarVisible={sidebarVisible}
                            toggleSidebar={toggleSidebar}
                            goBack="OrdiniAtelier"
                            goBackName="Ordini Atelier"
                        />
                        <ListeScreen navigation={navigation} route={route} isAtelier />

                        {/*<div className="magazzino-atelier-form-container">
                            <div className="form-magazzino-atelier ">
                                
                                 <div className="row justify-content-between">
                                    <div className="col-md-4 col-sm-12 " >
                                        <h4 style={{ fontWeight: 500, fontSize: '1.5em', letterSpacing: '2px' }}>
                                            Liste
                                        </h4>
                                        <ButtonComponent text={`Stampa`} variant={2} style={{ padding: '0.3em 4em', }} />
                                        <div className="mt-4 d-flex flex-column gap-2">
                                            <InputCheck
                                                name=""
                                                text="Filtra importanti"
                                                type="checkbox"
                                            />
                                            <InputCheck
                                                name=""
                                                text="Liste Vuote"
                                                type="checkbox"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="d-flex align-items-center">
                                            <InputComponent
                                                // register={register("listInserire")}
                                                name=""
                                                label=""
                                                type="text"
                                                inputProps={{
                                                    placeholder: 'Cerca...'
                                                }}
                                                optionsSelect={[]}
                                            />
                                            <img src={iconSearch} alt="" className="" style={{ width: '50px', cursor: 'pointer' }} />
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <InputComponent
                                                // register={register("listInserire")}
                                                name=""
                                                label=""
                                                type="text"
                                                inputProps={{
                                                    placeholder: 'Cerca Cliente...'
                                                }}
                                                optionsSelect={[]}
                                            />
                                            <img src={iconSearch} alt="" className="" style={{ width: '50px', cursor: 'pointer' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12 row justify-content-center">
                                        <div className="col-md-3 p-0 d-flex align-items-center justify-content-md-end">
                                            <ButtonComponent text={`Crea nuova lista`} variant={1} onClick={() => handleOperationModal("add")} />
                                        </div>
                                        <div className="col-md-3 p-0 d-flex align-items-center justify-content-md-center">
                                            <ButtonComponent text={`Modifica una lista`} variant={2} onClick={() => handleOperationModal("edit")} />
                                        </div>
                                        <div className="col-md-3 p-0 d-flex align-items-center justify-content-md-start">
                                            <ButtonComponent text={`Elimina una lista`} variant={2} onClick={() => handleOperationModal("delete")} />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="row justify-content-center align-items-center mt-3 gy-3"
                                >
                                    {Array(6).fill(6).map((_, index) =>
                                        <div className="col-md-4" key={index}>
                                            <ButtonOrdini title="TateJust 28 MM brill, ac oro e oro generale" onClick={() => navigation.navigate('ListDetailOrdiniAtelier', ['311', 'DATEJUST 28MM ACC BRILL , ACC ORO E ORO GENERALE'])} />
                                        </div>
                                    )}
                                </div>
                                <div className="w-100 mt-3">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={1}
                                        totalCount={15}
                                        pageSize={1}
                                        onPageChange={(page) => { }}
                                    />
                                </div> 
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
            {openModal &&
                <ModalAplication
                    title={`${operationModal === 'add' ? 'Crea nuova' : operationModal == 'edit' ? "Modifica" : "Elimina"} lista`}
                    isOpen={true}
                    closeModal={() => { handleCloseModal() }}

                >
                    <NuovaListaOrdini operation={operationModal} listOrdini={[{ label: "a", value: 1 }]} onBeforeAnulla={handleCloseModal} onBeforeSubmit={handleCloseModal} />
                </ModalAplication>
            }

        </>
    );
};

export default ListOrdiniAtelier;
