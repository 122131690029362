import React from "react";
import { Collapse } from "react-collapse";
import { useShow } from "../../../../../hooks/useShow";
import { CollapseTitleAtelier } from "../../../../Magazzino/Atelier/views/CollapseTitleAtelier";
import { Image } from "react-native";
import { CheckAtelier } from "./CheckAtelier";

interface Props {
    list: any[];
    handleFilter: (value: string) => void;
}
export const FilterCrmCheckboxStar = ({
    list,
    handleFilter,
}: Props) => {
    const { show, toggle } = useShow();
    return (
        <>
            <CollapseTitleAtelier title="Stelle                 " toggle={toggle} />
            <Collapse isOpened={show}>
                <div className="col-12">
                    {list?.map(({ id }) => (
                        <div key={id} className="d-flex">
                            <CheckAtelier
                                name={''}
                                id={'star-'+id}
                                text={''}
                                marginleft
                                handlechange={handleFilter}
                            />
                            <span className="d-flex" style={{position: 'relative', left: '-7px', top: '-4px'}}>
                                <Image
                                    style={[{
                                        top: 5, 
                                        marginLeft: 2,
                                        width: 25,
                                        height: 20,
                                        position: 'relative'
                                    }]}
                                    source={require(`../../../../../assets/icons-step-due/Icona-stella-2.png`)}
                                />
                                <Image
                                    style={[{
                                        top: 5,
                                        marginLeft: 2,
                                        width: 25,
                                        height: 20,
                                        position: 'relative'
                                    }]}
                                    source={id == 3 || id == 2 ? require(`../../../../../assets/icons-step-due/Icona-stella-2.png`) : require(`../../../../../assets/icons-step-due/Icona-stella1.png`)}
                                />
                                <Image
                                    style={[{
                                        top: 5,
                                        marginLeft: 2,
                                        width: 25,
                                        height: 20,
                                        position: 'relative'
                                    }]}
                                    source={id == 3 ? require(`../../../../../assets/icons-step-due/Icona-stella-2.png`) : require(`../../../../../assets/icons-step-due/Icona-stella1.png`)}
                                />
                            </span>
                            <br />
                        </div>
                    ))}
                </div>
            </Collapse>
        </>
    );
};
