import { FaRegEdit } from "react-icons/fa";
import { HeaderBack } from "../../../components/Calender/HeaderBack";
import Loading from "../../../components/Loading";
import { NotifyDetails } from "../../../components/Notify/NotifyDetails";
import { useTaskCrud } from "../Hooks/useTaskCrud";
import { useNotifyMain } from "../../../hooks/useNotifyMain";
import { BsCheck2Circle } from "react-icons/bs";
import { useState } from "react";
import PopupConfirmationCustom from "../../../components/PopupConfirmationCustom";
import { NewsDetails } from "../Components/TaskDetails";
import { NewList } from "../../../components/News/interfaces/NewList";
import ModalTaskChecked from "../Components/ModalTaskChecked";
import useTasks from '../../../components/Tasks/hooks/useTasks';
import ModalFormEdit from "../../../components/ModalForm/ModalFormEdit";
import ModalFormEditDisable from "../../../components/ModalForm/ModalFormEditDisable";

export const TaskScreen = () => {
  const { view, handleView, myNotify, isLoading} = useTaskCrud();

  const {
    BussinesList,
    UserList,
    defaultListTask,
    UserTecnicoList,
    PostTask,
    ListTaskBussinesById,
    defaultBussinesList,
    GetListTaskBussinesById,
    confirmviewTask,
    GetListByIdTaskBussines,
    GetListTaskUserById,
    listIdTaskBussinesView,
    defaultUserTecnicoList,
    defaultUserList,
    stateOptionNew,
    DeleteTask,
    getPDFTask,
    getIdRole,
    PutTasks
  } = useTasks();


  const [isOpen,setIsOpen] = useState(false)
  const [ConfirmOpen,setConfirmOpen] = useState(false)
  const [valueEditing,setValueEditing] = useState({})
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenFormEdit, setIsOpenFormEdit] = useState(false);

  const [isOpenFormEditDisabled, setIsOpenFormEditDisabled] = useState(false);

  const [activeSpinner, setActiveSpinner] = useState(false);

  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });

  const [dataEdit, setDataEdit] = useState({
    id: null,
    title: "",
    detail: "",
  });

  //const [isChecked, setIsChecked] = useState();
  const actionMod = async () => {
    await confirmviewTask(valueEditing)
    setConfirmOpen(!ConfirmOpen)
  }
  const cancelMod = () => {
    setValueEditing({})
  }
  const viewIsChecked = async (data) => {
    setIsOpen(!isOpen)
    GetListByIdTaskBussines(data)
    
  }

  const handleClick = (idTaskBussinesUsers,idUser,idTaskBussines,idTaskUser) => {
    setValueEditing({idTaskBussinesUsers,idUser,idTaskBussines,idTaskUser})
    console.log("aprendatoCheckbox",idTaskBussinesUsers,idUser,idTaskBussines,idTaskUser)
    //setIsChecked({ ...isChecked, [idNew]: checked });
    setConfirmOpen(!ConfirmOpen)
  //setIsChecked(!isChecked);
  }
  if (isLoading) return <Loading activeSpinner={isLoading} />;
  console.log("werewrewrw",ListTaskBussinesById)
  //onClick={()=>setIsOpen(!isOpen)}
  // checked={isChecked}  onClick={() => handleClick()}
  console.log("valuee",valueEditing)

  const functionPost = async (data: any) => {
    setActiveSpinner(true);
    await PostTask(data);
    setActiveSpinner(false);
    setIsOpenForm(!isOpenForm);
  };
  const functionPut = async (data: any) => {
    
    setActiveSpinner(true);
    
    await PutTasks(data).then(e=> {
      
      if(e){
        setModalMessage({
          active: true,
          message: "Compito modificate"
        })
      }
    });
    
    setActiveSpinner(false);
    setIsOpenFormEdit(false);
  };
  const deleteTask = async (id) => {
    setActiveSpinner(true);
    await DeleteTask(id).then((e) => {
      console.log(e);
      if (e.status == "success") {
        setModalMessage({
          active: true,
          message: "",
        });
      }

      GetListTaskBussinesById();
      setIsOpenFormEdit(false);
    });
    setActiveSpinner(false);
  };

  return (
    <div className="square notify-main">
      <HeaderBack />
      <div className="row">
        <div className="col-12 col-md-8 mx-auto">
          <div className="notify-area card">
            <div className="card-header bg-transparent border-0">
              <h3>Tasks</h3>
            </div>
            <div className="card-body">
              {ListTaskBussinesById.normalizedResults.map(({ title,text,idTask,isChecked,idTaskBussinesUsers,idUser,idTaskBussines,idTaskUser, nameFile, nameImage }) => {
                return (
                  <NewsDetails
                    setIsOpenFormEdit={setIsOpenFormEdit}
                    isOpenFormEdit={isOpenFormEdit}
                    setDataEdit={setDataEdit}
                    GetListByIdTaskBussines={GetListByIdTaskBussines}
                    idTaskBussinesUsers={idTaskBussinesUsers}
                    idUser={idUser}
                    idTaskBussines={idTaskBussines}
                    key={idTask}
                    id={idTask}
                    text={text}
                    title={title}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    idTaskUser={idTaskUser}
                    isChecked={isChecked}
                    handleClick={handleClick}
                    defaultListTask={defaultListTask}
                    nameFile={nameFile}
                    nameImage={nameImage}
                    getIdRole={getIdRole}
                    setIsOpenFormEditDisabled={setIsOpenFormEditDisabled}
                    isOpenFormEditDisabled={isOpenFormEditDisabled}
                   
                  />
                );
              })}
            </div>
          </div>
          <ModalTaskChecked isOpen={isOpen} setIsOpen={setIsOpen} listIdTaskBussinesView={listIdTaskBussinesView}/>
          {ConfirmOpen && (
            <PopupConfirmationCustom
              cancelAction={() => cancelMod()}
              title={""}
              message={"Cliccando sulla spunta confermi di aver eseguito il Task"}
              confirmAction={() =>
                actionMod()
              }
              stateModal={ConfirmOpen}
              setStateModal={setConfirmOpen}
            />
          )}
          <ModalFormEdit
            defaultUserTecnicoList={defaultUserTecnicoList}
            defaultUserList={defaultUserList}
            defaultBussinesList={defaultBussinesList}
            deleteTask={deleteTask}
            dataEdit={dataEdit}
            actionSave={functionPut}
            title={"Modifica tasks"}
            BussinesList={BussinesList}
            isOpen={isOpenFormEdit}
            setIsOpen={setIsOpenFormEdit}
            UserList={UserList}
            UserTecnicoList={UserTecnicoList}
            stateOptionNew={stateOptionNew}
            getPDFTask={getPDFTask}
          />
          <ModalFormEditDisable 
            defaultUserTecnicoList={defaultUserTecnicoList}
            defaultUserList={defaultUserList}
            defaultBussinesList={defaultBussinesList}
            deleteTask={deleteTask}
            dataEdit={dataEdit}
            actionSave={functionPut}
            title={"Modifica tasks"}
            BussinesList={BussinesList}
            isOpen={isOpenFormEditDisabled}
            setIsOpen={setIsOpenFormEditDisabled}
            UserList={UserList}
            UserTecnicoList={UserTecnicoList}
            stateOptionNew={stateOptionNew}
            getPDFTask={getPDFTask}
          />
        </div>
      </div>
    </div>
  );
};
