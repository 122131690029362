import * as Yup from "yup";
import { useFormik } from "formik";
import { useFormRepair } from "./useFormRepair";
import { useEffect, useState } from "react";
import { ResponseClienteData, selectProps } from "../../interfaces/formRepair";
import { getBussiness, getOptionsTitle } from "../../services/FormRepair";

export const useClient = () => {
  const { initialValues, next } = useFormRepair();
  const [response, setResponse] = useState<ResponseClienteData>({
    bussines: [],
    title: [],
  });
  const clientSchema = Yup.object().shape({
    title: Yup.string().required("Il campo è obbligatorio"),
    name: Yup.string().required("Il campo è obbligatorio"),
    email: Yup.string().email("E-mail errata"),
    telephone: Yup.string().required("Il campo è obbligatorio"),
    business: Yup.string().required("Il campo è obbligatorio"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: clientSchema,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        try {
          next(values);
          setSubmitting(false);
        } catch (error) {
          console.log(error);
        }
      }, 500);
    },
  });
  const handleDataOptions = async () => {
    try {
      const response = await Promise.all([getOptionsTitle(), getBussiness()]);
      const title = response[0];
      const bussines = response[1];
      setResponse({
        bussines,
        title,
      });
    } catch (error) {
      setResponse(response);
    }
  };
  useEffect(() => {
    handleDataOptions();
  }, []);

  return { formik, optionsTitle: response.title, bussines: response.bussines };
};
