import axios from "axios";
import Cookies from "universal-cookie/es6";
import ConfigCapone from "../utils/ConfigCapone";

const cookies = new Cookies();

const aplicationConnect = axios.create({
  baseURL: `${ConfigCapone.urlapi}/api`,
});

aplicationConnect.interceptors.request.use(async (config) => {
  const token = cookies.get("token");

  if (!config) {
    config = {};
  }
  if (!config.headers) {
    config.headers = {};
  }
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  config.headers["Content-Type"] = "application/json";

  return config;
});

export default aplicationConnect;
