import { FormikProvider } from 'formik'
import { useClient } from '../../../hooks/formRepair/useClient'
import { InputCustomStep } from '../InputCustomStep'
import { SelectCustomStep } from '../SelectCustomStep'
import { WrapperSteps } from './WrapperSteps'

export const Client = () => {
  const { formik, optionsTitle, bussines } = useClient()
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <WrapperSteps>
          <SelectCustomStep
            id="title"
            campo={'title'}
            label={'Titolo *'}
            {...formik.getFieldProps('title')}
            options={optionsTitle}
          />
          <InputCustomStep
            id="name"
            campo={'name'}
            label={'Nome e cognome *'}
            placeholder={'Nome e cognome'}
            {...formik.getFieldProps('name')}
          />
          <InputCustomStep
            id="Indirizzo"
            campo={'Indirizzo'}
            placeholder={'Indirizzo'}
            label={'Indirizzo'}
            {...formik.getFieldProps('address')}
          />
          <InputCustomStep
            id="email"
            campo={'email'}
            placeholder={'E-mail'}
            label={'E-mail'}
            {...formik.getFieldProps('email')}
          />
          <InputCustomStep
            id="telephone"
            campo={'telephone'}
            label={'Numero di telefono *'}
            placeholder={'Numero di telefono'}
            {...formik.getFieldProps('telephone')}
          />
          <SelectCustomStep
            id="business"
            campo={'business'}
            label={'Negozio di riferimento *'}
            {...formik.getFieldProps('business')}
            options={bussines}
          />
          <InputCustomStep
            id="user"
            campo={'user'}
            placeholder={'Operatore'}
            label={'Operatore'}
            {...formik.getFieldProps('user')}
            disabled
          />
        </WrapperSteps>
      </form>
    </FormikProvider>
  )
}
