import { SafeAreaView, StatusBar } from "react-native";
import { FaPlus } from "react-icons/fa";
import { SelectValues } from "../../interfaces/calender";
import { useAuth } from "../../hooks/useAuth";

interface Props {
  options: SelectValues[];
  negozio: string;
  handleChange: (evt: React.ChangeEvent<HTMLSelectElement>) => void;
  openModalAdd: () => void;
}
export const FilterCalender = ({
  options,
  negozio,
  handleChange,
  openModalAdd,
}: Props) => {
  const { permitedFilter } = useAuth();
  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: StatusBar.currentHeight,
        backgroundColor: "white",
      }}
    >
      <div className="row mb-4 filter-calender">
        <div className="col-12 col-sm-10 mx-auto">
          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
            <div
              className="mb-3 mb-sm-0"
              style={{
                paddingTop: "10px",
              }}
            >
              <button
                className="btn btn-outline-dark btn-lg btn-rounded btn-shadow"
                onClick={() => openModalAdd()}
              >
                <FaPlus className="me-1" />
                Creare
              </button>
            </div>
            <div>
              {permitedFilter && (
                <div className="d-flex justify-content-start align-items-center">
                  <span>Negozio</span>
                  <select
                    className="form-select"
                    value={negozio}
                    onChange={handleChange}
                  >
                    <option value="">Seleziona</option>
                    {options.map(({ label, value }) => (
                      <option value={value} key={value}>
                        {label}
                      </option>
                    ))}
                    <option value="8">Privato</option>
                  </select>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </SafeAreaView>
  );
};
