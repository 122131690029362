import React from "react";
import { View, Image, Text } from "react-native";
import { stylesMagazzino } from "../../GeneralStyles/MagazzinoStyle";

export const FilterOptions = () => {
  return (
    <div className="col-12 d-flex justify-content-start">
      <View>
        <Image
          style={stylesMagazzino.iconFilterPer}
          source={require("../../assets/icons-step-due/Icona-filtri.png")}
        />
      </View>
      <Text style={stylesMagazzino.labelFilterPer}>Filtra per</Text>
    </div>
  );
};
