import React, { useEffect, useState } from "react";
import { Image, TextInput, TouchableOpacity, View } from "react-native";
import { stylesStatisticheMagazzino } from "../../GeneralStyles/Statistiche";

interface Props {
  handleSearch: (search: string) => void;
  value: string;
}

export const CercaStatische = ({ handleSearch, value }: Props) => {
  const { search, setSearch } = useSearch({ handleSearch, value });
  return (
    <div className="col-12 d-flex">
      <TextInput
        placeholder="Cerca..."
        style={[
          stylesStatisticheMagazzino.input,
          stylesStatisticheMagazzino.subSearch,
        ]}
        onChangeText={(evt) => setSearch(evt)}
        value={search}
      />
      <TouchableOpacity
        onPress={() => handleSearch(search)}
        style={{
          margin: "auto",
        }}
      >
        <View
          style={[
            stylesStatisticheMagazzino.viewDisable,
            stylesStatisticheMagazzino.search,
          ]}
        >
          <Image
            style={stylesStatisticheMagazzino.subIconSearch}
            source={require("../../assets/icons/icon-search.png")}
          />
        </View>
      </TouchableOpacity>
    </div>
  );
};


//HOOK PARA EL BUSCADOR
const useSearch = ({ handleSearch, value }: Props) => {
  const [search, setSearch] = useState(value);
  useEffect(() => {
    search.length === 0 && handleSearch("");
  }, [search]);
  return {
    search,
    setSearch,
  };
};