import { HeaderBack } from "../components/Calender/HeaderBack";
import Loading from "../components/Loading";
import { NotifyDetails } from "../components/Notify/NotifyDetails";
import { useNotifyMain } from "../hooks/useNotifyMain";

export const NotifyScreen = () => {
  const { view, handleView, myNotify, isLoading } = useNotifyMain();
  if (isLoading) return <Loading activeSpinner={isLoading} />;
  return (
    <div className="square notify-main">
      <HeaderBack />
      <div className="row">
        <div className="col-12 col-md-8 mx-auto">
          <div className="notify-area card">
            <div className="card-header bg-transparent border-0">
              <h3>Notifiche</h3>
            </div>
            <div className="card-body">
              {myNotify.map(({ id, subject, read, user, message, date }) => {
                return (
                  <NotifyDetails
                    key={id}
                    active={id === view}
                    subject={subject}
                    handleView={() => handleView(id)}
                    read={read === 1}
                    user={user}
                    message={message}
                    dateExport={new Date(date).toLocaleString()}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
