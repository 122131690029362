import { useState } from "react";
import { deleteMatricolaAtelier, listAllBrand, listAllFamiglia, magazzinoAtelierByIdReference, postMagazzino, postMatricola, postReferenza, putMagazzinoAtelierQuantity, putNameMatricolaAtelier, putReferenza, searchReferenzaByLimit } from "../../services/magazzinoAtelierService";
import { BrandResponse, FamigliaResponse, MagazzinoAtelier } from "../../interfaces/IMagazzinoAtelier";
import { FormDataMagazzinoAtelier, OptionsSelect } from "../interfaces/magazzinoAtellierForm";
import { useAuth } from "../../../../../hooks/useAuth";
import ConfigCapone from "../../../../../utils/ConfigCapone";
import axios from "axios";

export const useMagazzinoAtelierForm = () => {
    const { user } = useAuth();
    const [brandList, setBrandList] = useState<BrandResponse[]>([]);
    const [brandOptions, setBrandOptions] = useState<OptionsSelect[]>([]);

    const [famigliaList, setFamigliaList] = useState<FamigliaResponse[]>([]);
    const [famigliaOptions, setFamigliaOptions] = useState<OptionsSelect[]>([]);

    const [searchReferenceList, setSearchReferenceList] = useState<any[]>([]);
    const [searchReferenceOptions, setSearchReferenceOptions] = useState<OptionsSelect[]>([]);

    
    const [imageArrayList, setImageArrayList] = useState<any>([]);

    const formatDateFile = () => {
        let date = new Date().toLocaleDateString();
        date = date.replaceAll('/', '-');
        let hour = new Date().toLocaleTimeString();
        hour = hour.replaceAll(':', '-');
        date += '_' + hour;
        date = date.replace(/\s/g, '');
        return date
    }

    const saveFiles = async () => {
        var imageFile: any = document.querySelector("#image-files");
        let filesName = [];

        if (imageFile.files.length > 0) {
            for (var i = 0; i < imageFile.files.length; i++) {
              var formData = new FormData();
              var currentFile = imageFile.files[i];
              const extension: string = currentFile.name.substr(
                currentFile.name.lastIndexOf(".") + 1
              );
              const fileName: string =
                user.idUser +
                "_" +
                formatDateFile() +
                "_" +
                i +
                "." +
                extension?.toLowerCase();
              let myNewFile = new File([currentFile], fileName, {
                type: currentFile.type,
              });
      
              filesName.push({ id: i + 1, name: fileName, type: currentFile.type });
      
              formData.append("files", myNewFile);
              formData.append("type", "image");
              formData.append("name", fileName);
              formData.append("extension", extension);
      
              try {
                const res = await axios.post(
                  `${ConfigCapone.apicapone}/upload`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
                console.log("upload success");
              } catch (error) {
                console.log("err", error);
              }
            }     
            imageFile.value = '';
        }

        const filteredImages = imageArrayList.filter((image) => 'id' in image);

        if (filesName.length > 0) {
          let dataJson = [...filteredImages, ...filesName]
          setImageArrayList([])
          setImageArrayList(dataJson)
          return JSON.stringify(dataJson)
        }
    
        if(filteredImages.length > 0){  
          return JSON.stringify(filteredImages)
        }

        return ""
    };

    const uploadFiles = (e) => {
        let selectedImages = Array.from(e); 
        let imageArrayListCopy = [...imageArrayList]; 
        selectedImages.forEach((item: any) => {
          imageArrayListCopy.push(item);
        });
        setImageArrayList(imageArrayListCopy);
    }

    const loadFiles = (files) => {
        if(files){
          if(files.startsWith("[")){
            const jsonFiles = JSON.parse(files);
            setImageArrayList(jsonFiles)
          }else{
            let  filesName = [];
            const finishIndex = files.lastIndexOf(".");
            const extension = files.slice(finishIndex + 1);
    
            let currentFile = ""
    
            if(extension.toLowerCase() == 'png' || extension.toLowerCase() == 'gif' || extension.toLowerCase() == 'jfif' 
               || extension.toLowerCase() == 'pjp' || extension.toLowerCase() == 'jpg' || extension.toLowerCase() == 'pjpeg' || extension.toLowerCase() == 'jpeg'){
              currentFile = 'image'
            }else if (extension.toLowerCase() == 'pdf'){
              currentFile = 'application'
            }else{
              currentFile = 'application'
            }
            filesName.push({ id: 1, name: files, type: currentFile + '/' + extension });
            setImageArrayList(filesName)
          }
        }
    }

    const removeFiles = (index: number) => {
        const removedElement = imageArrayList.splice(index, 1)[0];
        setImageArrayList([...imageArrayList]);
    }

    const brandGet = async () => {
        const response = await listAllBrand();
        if (response) {
            setBrandList(response);
            const options = response.map((brand) => {
                return {
                    value: brand.idBrand,
                    label: brand.desc_Brand,
                };
            });
            setBrandOptions(options);
        }
    }
    const famigliaGet = async () => {
        const response = await listAllFamiglia();
        if (response) {
            setFamigliaList(response);
        }
    }
    const handlefamigliaOptions = (idBrand: string | number) => {
        const options = famigliaList.filter(list => list.idBrand === idBrand).map((family) => {
            return {
                value: family.idFamiglia,
                label: family.desc_famiglia,
            };
        });
        setFamigliaOptions(options);
    }
    const countMatricola = (matricola:any[]) => {
        let count = 0;
        for (let index = 0; index < matricola.length; index++) {
            if(matricola[index].name !== ''){
                count++
            }
        }
        return count;
    }
    const postMatricolaByReference = async (dataForm:FormDataMagazzinoAtelier,idReference:string | number) => {
        for (let index = 0; index < dataForm.matricola.length; index++) {
            if(dataForm.matricola[index].name !== '') {
                const element = {
                    idReferenzia: idReference,
                    matricola: dataForm.matricola[index].name,
                    putOnline: dataForm.matricola[index].online,
                    idNegozio: dataForm.idBussines
                }
                await postMatricola(element)
            }
           
        }
    }
    const magazzinoAtelierPost = async (dataForm:FormDataMagazzinoAtelier) => {
        const responseReferenzaId = await postReferenza(dataForm);
        if (responseReferenzaId && dataForm.matricola.length > 0) {
            await postMatricolaByReference(dataForm,responseReferenzaId)
        }
        const sendMagazzino = {
            idUser:user.idUser,
            idBussines:9,
            idReferenzia:responseReferenzaId,
            quantity:countMatricola(dataForm.matricola)
        }
        await postMagazzino(sendMagazzino)
    }
    const magazzinoAtelierPut = async (dataForm:FormDataMagazzinoAtelier) => {
        const responseReferenzaId = await putReferenza(dataForm);

        if (responseReferenzaId && dataForm.matricola.length > 0) {
            await postMatricolaByReference(dataForm,responseReferenzaId)
            const result  = await magazzinoAtelierByIdReference(responseReferenzaId)
            if(result){
                const totalQuantity = Number(result.quantity) + countMatricola(dataForm.matricola)
                await putMagazzinoAtelierQuantity(result.idMagazzino,totalQuantity)
            }
        }
    }
    const magazzinoAtelierPutFormEdit = async (dataForm:FormDataMagazzinoAtelier, idMagazzino: string, matricolasOld: any) => {
        let listMatricolas = matricolaPostUpdate(dataForm.matricola,matricolasOld)
        await putReferenza(dataForm); 

        if(listMatricolas.update.length > 0){
          for (let index = 0; index < listMatricolas.update.length; index++) {
            const element = listMatricolas.update[index];
            await putNameMatricolaAtelier(Number(element.idMatricola), element.name);           
          }
        }

        if(listMatricolas.post.length > 0){
          for (let index = 0; index < listMatricolas.post.length; index++) {
            if(dataForm.matricola[index].name !== '') {
                const data = listMatricolas.post[index];
                const element = {
                    idReferenzia: dataForm.idReference,
                    matricola: data.name,
                    putOnline: data.online,
                    idNegozio: dataForm.idBussines
                }
                await postMatricola(element)
            }        
          }
        }

        if(listMatricolas.delete.length > 0){
          for (let index = 0; index < listMatricolas.delete.length; index++) {
            const element = listMatricolas.delete[index];
            await deleteMatricolaAtelier(Number(element.idMatricola));           
          }
        }

        let totalQuantity = listMatricolas.post.length + listMatricolas.update.length
        await putMagazzinoAtelierQuantity(idMagazzino,totalQuantity)
    }
    const matricolaPostUpdate = (matricolas, matricolasOld) => {
      let listMatricolas = {
        post: [],
        update: [],
        delete: []
      }
      listMatricolas.post = matricolas.filter( x => Number(x.idMatricola) == 0 && x.name.length > 0)
      listMatricolas.update = matricolas.filter( x => Number(x.idMatricola) != 0 && x.name.length > 0)

      listMatricolas.delete = matricolasOld.filter(oldMatricola =>
        !listMatricolas.update.some(newMatricola => newMatricola.idMatricola === oldMatricola.idMatricola)
      );
      return listMatricolas

    }
    const MagazzinoPost = async (data:any) => {
       const result = await  postMagazzino(data)
    }
    const searchReference = async (search:string)=>{
        const response = await searchReferenzaByLimit(search)
        setSearchReferenceList(response)
        const options = response.map((reference) => {
            return {
                value: reference.idReference,
                label: reference.referencia,
            };
        });
        setSearchReferenceOptions(options);
    }
    const resetReferenceOptions = () => {
        setSearchReferenceOptions([]);
    }
    const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({
        title: "Informazione",
        message: "",
        active: false,
        route: "",
      });
    const showSuccessPopup = (titleValue:string,messageValue:string) => {
        setmodaleMessaggeWithUrl({
          active: true,
          message: messageValue,
          title:titleValue,
          route: "",
        })
      };
    const closePopup = () => {
        setmodaleMessaggeWithUrl({
          active: false,
          message: "",
          title: "",
          route: "",
        });
    }
    return{
        modaleMessaggeWithUrl,
        setmodaleMessaggeWithUrl,
        showSuccessPopup,
        closePopup,
        brandGet,
        famigliaGet,
        brandList,
        brandOptions,
        magazzinoAtelierPost,
        famigliaList,
        famigliaOptions,
        handlefamigliaOptions,
        searchReference,
        searchReferenceOptions,
        searchReferenceList,
        setSearchReferenceOptions,
        resetReferenceOptions,
        magazzinoAtelierPut,
        MagazzinoPost,
        user,
        imageArrayList, 
        setImageArrayList,
        removeFiles,
        loadFiles,
        uploadFiles,
        saveFiles,
        magazzinoAtelierPutFormEdit
    }
}