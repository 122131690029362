import React, { useContext, useEffect, useState } from "react";
import { ImageBackground, ScrollView, Text, TextInput, TouchableOpacity, View, FlatList, Pressable, StyleSheet, Modal } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { styleInput, styleInputBlock, stylesCRM, styleTextArea } from "../../GeneralStyles/SCDue/ControlPanel";
import PopUpMessageWithRoute from "../../components/PopUpMessageWithRoute";
import { HeaderBar } from "../../components/HeaderBar";
import Loading from "../../components/Loading";
import '../../utils/styles.css'

import { AddFormListInteressi } from "../../components/Manifestazione/AddFormListInteressi";
import ModalBlackList from "../../components/Manifestazione/ModalBlackList";

interface Props extends NativeStackScreenProps<any, any> {
    isAtelier?: boolean;
}

export const ControlPanelManifestazioneScreen = ({ route, navigation, isAtelier = false }: Props) => {

    let arrayData: any = route.params || [];
    // const [dataClient, setDataClient] = useState<IClient>();
    const [activeSpinner, setActiveSpinner] = useState(false);
    const [modalVisibleCardBlackList, setModalVisibleCardBlackList] = useState(false);
    const [idClientExist, setIdClientExist] = useState<number>(0)

    const [optionAddEdit, setOptionAddEdit] = useState(false);

    let dataListClient = arrayData ? arrayData.clientList : "";

    const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({
        title: "Informazione",
        message: "",
        active: false,
        route: "",
    });

    const confirmAction = (Landing: any, data: any) => {
        navigation.navigate(Landing, data);
        setmodaleMessaggeWithUrl({
            title: "Informazione",
            message: "",
            active: false,
            route: "",
        });
    };

    return (
        <ScrollView>
            {activeSpinner && <Loading activeSpinner={activeSpinner} />}
            {!isAtelier &&
                <View
                    style={stylesCRM.header}
                >
                    <HeaderBar
                        hasRoundedIcon={false}
                        title=""
                        onBack={() => (route.params && dataListClient) ? navigation.navigate("ClientListScreen") : navigation.navigate("HomeManifestazioneScreen")}
                    />
                </View>
            }

            <div className="row pl-2" style={{ backgroundColor: "white" }}>
                <div className="col-md-10 col-sm-10 col-12 m-auto">
                    <AddFormListInteressi
                        optionAddEdit={optionAddEdit}
                        setOptionAddEdit={setOptionAddEdit}
                        setmodaleMessaggeWithUrl={setmodaleMessaggeWithUrl}
                        setActiveSpinner={setActiveSpinner}
                        idClientExist={idClientExist}
                        setIdClientExist={setIdClientExist}
                        setModalVisibleCardBlackList={setModalVisibleCardBlackList}
                        IsAtelier={isAtelier}
                    />
                </div>
            </div>

            {modalVisibleCardBlackList && (
                <ModalBlackList
                    modalVisible={modalVisibleCardBlackList}
                    setModalVisible={setModalVisibleCardBlackList}
                />
            )}

            {modaleMessaggeWithUrl.active && (
                <PopUpMessageWithRoute
                    routeNav={modaleMessaggeWithUrl.route}
                    dataNav={[]}
                    title={modaleMessaggeWithUrl.title}
                    message={modaleMessaggeWithUrl.message}
                    stateModal={modaleMessaggeWithUrl.active}
                    setStateModal={setmodaleMessaggeWithUrl}
                    confirmAction={confirmAction}
                />
            )}

        </ScrollView>
    );
};
