import {
    Modal,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from "react-native";

import Cookies from 'universal-cookie';
import { UserContext } from "../../../../../context/UserContext";
import { useContext, useState } from "react";
import axios from "axios";
import ConfigCapone from "../../../../../utils/ConfigCapone";
import Loading from "../../../../../components/Loading";
import { stylesModal } from "../../../../../theme/ModalStyles";
import { HeaderModal } from "../../../../../components/HeaderModal";

interface IModalSellProdottoAtelier {
    modalVisible: boolean;
    setModalVisible: any;
    setModalSchedaProdotto?: any;
    idMatricola?: string;
    idBussinessSeller?: any;
    typeMatricola?: string;
    goToSchedaProdotto?: any;
    updateStateMatricole?: any;
}

const ModalSellProdottoAtelier = ({
    modalVisible = false,
    setModalVisible,
    setModalSchedaProdotto,
    idMatricola,
    idBussinessSeller,
    typeMatricola,
    goToSchedaProdotto,
    updateStateMatricole,
}: IModalSellProdottoAtelier) => { 

    const { userInLogin } = useContext(UserContext);
    const [activeSpinner, setActiveSpinner] = useState(false);
    const cookies = new Cookies();

    const sellToClient = async() => {

        setActiveSpinner(true)

        await updateStateMatricole(idMatricola, true, typeMatricola, 0);

        let data = {
            idBussinessSeller: idBussinessSeller.idBussines,
            idClient: 0,
            sellDate: new Date(),
            idMatricola: idMatricola,
            idUser: userInLogin.idUser,
        }
        
        await axios.post(`${ConfigCapone.urlapi}/api/Sales`, data,{
            headers: {
              'Authorization': `Bearer ${cookies.get('token')}`
            }
          })
            .then(async (res) => console.log("request update", res))


        // await sendEmail("Nuova Vendita",idMatricola)      
        setTimeout(() => {
            setActiveSpinner(false)
            setModalVisible(false)
            goToSchedaProdotto();
            setModalSchedaProdotto(false);
        }, 1000);
    }

    return (
        <View >
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <ScrollView style={{width: "100%", height: "100%"}}> 
                {activeSpinner && <Loading activeSpinner={activeSpinner} />}
                    <View
                    style={[
                        stylesModal.centeredView,
                        { paddingTop: 150, paddingBottom: 150 },
                    ]}
                    >
                        <View style={stylesModal.modalView}>
                            <HeaderModal
                            setModalVisible={setModalVisible}
                            modalVisible={modalVisible}
                            title={"Sei sicuro di vendere questo articolo?"}
                            activatedPadLeft={true}
                            />
                            <div className="row w-100">
                                 <div className="col-12 d-flex justify-content-center my-5">
                                    <TouchableOpacity
                                        style={{marginRight:15}}
                                        onPress={() => sellToClient()} 
                                        >
                                        <View style={[stylesModalOptionSchemaProdotto.buttonWhite, {width: 150}]}>
                                            <Text style={stylesModalOptionSchemaProdotto.buttonTextWhite}>
                                            {"Si"}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={()=>setModalVisible(!modalVisible)}
                                        >
                                        <View style={[stylesModalOptionSchemaProdotto.buttonWhite, {width: 150}]}>
                                            <Text style={stylesModalOptionSchemaProdotto.buttonTextWhite}>
                                            {"No"}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                 </div>
                            </div>
                        </View>
                    </View>
                </ScrollView>
            </Modal>
        </View>
    )
}

export default ModalSellProdottoAtelier;

const stylesModalOptionSchemaProdotto = StyleSheet.create({
    buttonTextWhite: {
        color: "black",
        alignSelf: "center",
        fontWeight: "500",
        fontSize: 18,
    },
    buttonWhite: {
        backgroundColor: "white",
        justifyContent: "center",
        borderRadius: 50,
        width: 130,
        height: 40,
        borderWidth: 1,
        borderColor: "black",
    },
});

