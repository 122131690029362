import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useContext, useEffect, useState } from "react";
import {
  Image,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  FlatList,
  Pressable,
  TextInput,
} from "react-native";
import { HeaderBar } from "../../components/HeaderBar";
import { SearchBox } from "../../components/SearchBox";
import { styles, tableHeader, tableRow } from "../../theme/GlobalStyle";
import { UserContext } from "../../context/UserContext";
import { useNotifyCrud } from "../../hooks/useNotifyCrud";
import Cookies from "universal-cookie";
import { useModal } from "../../hooks/useModal";
import ConfigCapone from "../../utils/ConfigCapone";
import axios from "axios";
import { useModalDelete } from "../../hooks/useModalDelete";
import { useModalBracciale } from "../../hooks/useModaladdBracciale";
import { useModalQuadrante } from "../../hooks/useModalQuadrante";
import {
  getAllInteressiFilterClientSuggestion,
  getAllInteressiFilterSuggestion,
  getAllBussines,
  getAllInteressi,
  postInteressi,
  deleteInteressi,
  searchListeName,
  searchListeBussines,
  getAllInteressiFilter,
  getAllBracciale,
  getAllQuadrante,
  postBracciale,
  editInteressi,
} from "../../services/interessiServices";
import { ModalAplication } from "../../components/Modal/ModalAplication";
import DatePicker, { registerLocale } from "react-datepicker";
import PopUpMessageWithRoute from "../../components/PopUpMessageWithRoute";
import it from "date-fns/locale/it";
import {
  stylesRepair,
  selectStyle,
} from "../../theme/interessi/interessiStyle";
import {
  IManifestazioneInteressi,
  IManifestazioneInteressiClient,
} from "../../interfaces/IManifestazioneinteressi";
import Pagination from "../../components/Pagination/pagination";
import { useModalEdit } from "../../hooks/useModalEdit";
import { useForm } from "react-hook-form";
import { httpGetClient } from "../CRM/Atelier/services/CrmAtelierFormServices";
registerLocale("it", it);

type ParametersType = {
  page: number;
  limit: any;
  name: string;
  idBussines: number;
  isImportant: number;
  isListeVuote: number;
  clientFilter: string;
  isAtelier: boolean;
}
interface Props extends NativeStackScreenProps<any, any> {
  isAtelier?: boolean;
}
export const ListeScreen = ({ navigation, isAtelier = false }: Props) => {
  const { userInLogin } = useContext(UserContext);
  const { createdPosted } = useNotifyCrud();
  const { closeModal, isOpen, openModalAdd } = useModal();
  const { closeModalDelete, isOpenDelete, openModalDelete } = useModalDelete();
  const { closeModalEdit, isOpenEdit, openModalE } = useModalEdit();
  const { closeModalBracciale, isOpenBracciale, openModalDBracciale } =
    useModalBracciale();
  //isOpenQuadrante
  const { closeModalQuadrante, isOpenQuadrante, openModalQuadrante } =
    useModalQuadrante();
  const [searchInputListe, setSearchInputListe] = useState<string>();
  const [searchInputListeClient, setSearchInputListeClient] =
    useState<string>();
  const [searchInputListeIdClient, setSearchInputListeIdClient] =
    useState<string>();
  const cookies = new Cookies();
  const [dataBussines, setDataBussines] = useState([]);
  const [dataBracciale, setDataBracciale] = useState([]);
  const [dataQuadrante, setDataQuadrante] = useState([]);
  const [verifyListeName, setVerifyListeName] = useState(false);
  const [inputBraccialeAdd, setInputBraccialeAdd] = useState("");
  //setInputQuadranteAdd
  const [inputQuadranteAdd, setInputQuadranteAdd] = useState("");
  const [dataInteressi, setDataInteressi] = useState([]);
  const [dataInteressiFilter, setDataInteressiFilter] = useState([]);
  const [dataInteressiFilterTable, setDataInteressiFilterTable] = useState([]);
  const [inputName, setInputName] = useState("");
  //EDIT LISTE
  const [inputNameEdit, setInputNameEdit] = useState("");
  const [inputBussinesEdit, setInputBussinesEdit] = useState(!isAtelier ? "" : 9);
  const [inputNameIdEdit, setInputNameIdEdit] = useState("");
  const [inputNameBracciale, setInputNameBracciale] = useState("");
  //setInputNameQuadrante
  const [inputNameQuadrante, setInputNameQuadrante] = useState("");
  const [inputBussines, setInputBussines] = useState(!isAtelier ? 0 : 9);
  const [inputQuadrante, setInputQuadrante] = useState(0);
  const [inputBracciale, setInputBracciale] = useState(0);
  const [inputBussinesAdd, setInputBussinesAdd] = useState(!isAtelier ? "" : 9);
  const [inputBussinesDelete, setInputBussinesDelete] = useState(!isAtelier ? "" : 9);
  const [inputNameID, setInputNameID] = useState("");
  const [reloadData, setReloadData] = useState<boolean>(false);
  const [changeFilter, setChangeFilter] = useState<boolean>(false);
  const [paginationAlls, setPaginationAlls] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [idQuadrante, setIdQuadrante] = useState(0);
  const [isAdmin, setIsAdmin] = useState<boolean>(
    userInLogin?.idRole === "2" ? true : false
  );
  const [isDipendente, setIsDipendete] = useState<boolean>(
    userInLogin?.idRole === "4" ? true : false
  );
  const [isDirectore, setIsDirectore] = useState<boolean>(
    userInLogin?.idRole === "5" ? true : false
  );
  const [isImportantFilter, setisImportantFilter] = useState<boolean>(false);
  const [isListeVuoteFilter, setisListeVuoteFilter] = useState<boolean>(false);
  //SEARCH SUGERENCIAS
  const [searchInputName, setSearchInputName] = useState<string>();
  const [autosuggestInputName, setAutosuggestInputName] = useState<
    IManifestazioneInteressi[]
  >([]);
  const [autosuggestInputNameClient, setAutosuggestInputNameClient] = useState<
    IManifestazioneInteressiClient[]
  >([]);

  const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({
    title: "Informazione",
    message: "",
    active: false,
    route: "",
  });
  const { setValue } = useForm();
  const [parametersGet, setparametersGet] = useState<ParametersType>({
    page: 1,
    limit: null,
    name: "",
    idBussines: isDirectore ? Number(userInLogin.idBussines) : 0,
    isImportant: isImportantFilter ? 1 : 0,
    isListeVuote: isListeVuoteFilter ? 1 : 0,
    clientFilter: "",
    isAtelier: isAtelier,
  });
  const [paginationListe, setpaginatcionListe] = useState({
    currentPage: 0,
    total: 0,
    totalPages: 0,
  });
  const showSuccessPopup = (mess: string, tiTle: string) => {
    setmodaleMessaggeWithUrl({
      active: true,
      message: mess,
      title: tiTle,
      route: "",
    });
  };
  const confirmAction = (Landing: any, data: any) => {
    navigation.navigate(Landing, data);
    setmodaleMessaggeWithUrl({
      title: "Informazione",
      message: "",
      active: false,
      route: "",
    });
  };
  const paginationAll = () => {
    const numerodepaginas = Array(Number(paginationListe.totalPages)).fill(0);
    setPaginationAlls(numerodepaginas);
  };
  const getSearch = () => {
    parametersGet.isAtelier = isAtelier
    const inter = getAllInteressiFilter(parametersGet);
    console.log("arrayData33", parametersGet)
    inter.then((response) => {
      if (response != undefined) {
        console.log("arrayData99", response.data.response)
        setDataInteressiFilterTable(response.data.response);
        setDataInteressiFilter(response.data.response);
        setpaginatcionListe({
          currentPage: response.data.currentPage,
          total: response.data.total,
          totalPages: response.data.totalPages,
        });
      } else {
        setDataInteressiFilterTable([]);
      }
      setReloadData(false);
    });
  };
  const getAllInt = () => {
    const inter = getAllInteressi(0, 0, parametersGet.isAtelier);
    inter.then((response) => {
      if (response != undefined) {
        setDataInteressi(response.data.response);
      } else {
        setDataInteressi([]);
      }

      setReloadData(false);
    });
  };
  const getAllBuss = () => {
    const buss = getAllBussines();
    buss.then((response) => {
      if (isAdmin) {
        setDataBussines(response);
      } else {
        if (isDirectore) {
          let result = response?.filter(
            (x: any) => x.idBussines == userInLogin.idBussines
          );
          setDataBussines(result);
        }
      }
    });
  };
  const clearInputName = () => {
    setInputName("");
    setInputBraccialeAdd("default");
    setInputQuadranteAdd("default");
  };
  const validationAdd = () => {
    if (inputName != "" && inputBussinesAdd != "default") {
      savechange();
      showSuccessPopup("Salvata con successo.", "Informazione");
    } else {
      showSuccessPopup("Dati inseriti in modo errato.", "Errore.");
    }
  };
  const handleSearchFilter = () => {
    console.log("arrayData2")
    getSearch();
  };
  const handleSearchClientFilter = () => {
    getSearch();
    console.log("arrayData3")
  };
  const validationDelete = () => {
    if ((inputNameID != "" && inputBussinesDelete != "")) {
      savechangeDelete();
      showSuccessPopup("Rimosso con successo.", "Informazione");
    } else {
      showSuccessPopup("Selezionare correttamente il record.", "Errore.");
    }
  };
  const validationEdit = () => {
    if (inputNameIdEdit != "" && inputNameEdit != "") {
      const parameters: ParametersType = {
        limit: null,
        page: 1,
        name: inputNameEdit,
        idBussines: Number(inputBussinesEdit),
        isImportant: 0,
        isListeVuote: 0,
        clientFilter: "",
        isAtelier: isAtelier
      };
      const inter = getAllInteressiFilter(parameters);
      inter.then((response) => {
        if (response != undefined) {
          if (response.data.status === "success") {
            if (response.data.response[0]?.idList == inputNameIdEdit) {
              savechangeEdit();
              showSuccessPopup("Salvata con successo.", "Informazione");
            } else {
              showSuccessPopup("registro aziendale esistente.", "Informazione");
            }
          }
        } else {
          savechangeEdit();
          showSuccessPopup("Salvata con successo.", "Informazione");
        }
      });
    } else {
      showSuccessPopup("Selezionare correttamente il record.", "Errore.");
    }
    console.log("aaa", verifyListeName);
  };
  const changeParametersFilter = () => {
    setparametersGet({
      page: paginaActual,
      limit: null,
      name: searchInputListe ? searchInputListe : "",
      idBussines: isDirectore
        ? Number(userInLogin.idBussines)
        : Number(inputBussines),
      isImportant: isImportantFilter ? 1 : 0,
      isListeVuote: isListeVuoteFilter ? 1 : 0,
      clientFilter: searchInputListeIdClient
        ? searchInputListeIdClient
        : searchInputListeClient
          ? searchInputListeClient
          : "",
      isAtelier: isAtelier,
    });
    setChangeFilter(!changeFilter);
  };
  const onChangeTextName = async () => {
    const inter = getAllInteressiFilterSuggestion(searchInputListe, isAtelier);
    inter.then((response) => {
      if (response != undefined) {
        setAutosuggestInputName(response.data.response);
      } else {
        setDataInteressiFilterTable([]);
      }
      setReloadData(false);
    });
  };
  const onChangeTextNameClient = async () => {
    if (searchInputListeClient && searchInputListeClient.length > 0) {
      var inter
      if (!isAtelier) {
        inter = getAllInteressiFilterClientSuggestion(
          searchInputListeClient
        );
      } else {
        inter = httpGetClient(searchInputListeClient);
      }

      inter.then((response) => {
        if (response != undefined) {
          setAutosuggestInputNameClient(response.data.response);
        } else {
          setDataInteressiFilterTable([]);
        }
        setReloadData(false);
      });
      setSearchInputListeIdClient(null);
    }
  };
  const getItemText = (item: IManifestazioneInteressi) => {
    let mainText = item.name + " - " + item.desc_Bussines;
    return (
      <div>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ marginLeft: 10, flexShrink: 1 }}>
            <Text
              style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}
            >
              {mainText}
            </Text>
          </View>
        </View>
      </div>
    );
  };
  const getItemTextClient = (item: IManifestazioneInteressiClient) => {
    let mainText = item.name + " - " + item.phone;
    return (
      <div>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ marginLeft: 10, flexShrink: 1 }}>
            <Text
              style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}
            >
              {mainText}
            </Text>
          </View>
        </View>
      </div>
    );
  };
  const changeParametersFilterBussines = () => {
    setparametersGet({
      page: paginaActual,
      limit: null,
      name: searchInputListe ? searchInputListe : "",
      idBussines: isDirectore
        ? Number(userInLogin.idBussines)
        : Number(inputBussines),
      isImportant: isImportantFilter ? 1 : 0,
      isListeVuote: isListeVuoteFilter ? 1 : 0,
      clientFilter: searchInputListeClient ? searchInputListeClient : "",
      isAtelier: isAtelier,
    });
    setChangeFilter(!changeFilter);
  };
  useEffect(() => {
    setDataInteressiFilter(
      dataInteressi.filter((elem) => elem.idBussines == inputBussinesDelete)
    );
  }, [inputBussinesDelete]);
  useEffect(() => {
    setDataInteressiFilter(
      dataInteressi.filter((elem) => elem.idBussines == inputBussinesEdit)
    );
  }, [inputBussinesEdit]);
  useEffect(() => {
    const value = dataInteressi.filter(
      (elem) => elem.idList == inputNameIdEdit
    );
    setInputNameEdit(value[0]?.name ? value[0]?.name : "");
  }, [inputNameIdEdit]);
  useEffect(() => {
    handleSearchFilter();
  }, [changeFilter]);
  useEffect(() => {
    changeParametersFilterBussines();
    setPaginaActual(1);
  }, [inputBussines]);
  useEffect(() => {
    changeParametersFilter();
    setPaginaActual(1);
  }, [searchInputListe]);
  useEffect(() => {
    changeParametersFilter();
    setPaginaActual(1);
  }, [searchInputListeClient]);
  useEffect(() => {
    changeParametersFilter();
    setPaginaActual(1);
  }, [isImportantFilter]);
  useEffect(() => {
    changeParametersFilter();
    setPaginaActual(1);
  }, [isListeVuoteFilter]);
  const savechange = () => {
    let selectedMessage = {
      name: inputName,
      idBussines: !isAtelier ? Number(inputBussinesAdd) : 9,
    };
    postInteressi(selectedMessage);
    closeModal();
    setReloadData(true);
    setInputName("");
    !isAtelier && setInputBussinesAdd("default");
  };
  useEffect(() => {
    console.log("arrayData1")
    getSearch();
    getAllInt();
  }, [reloadData]);
  useEffect(() => {
    changeParametersFilter();
  }, [paginaActual]);
  const savechangeDelete = () => {
    deleteInteressi(Number(inputNameID), Number(inputBussinesDelete));
    closeModalDelete();
    setReloadData(true);
    setInputBussinesDelete("");
    setInputNameID("");
  };
  const savechangeEdit = () => {
    editInteressi(
      Number(inputNameIdEdit),
      inputNameEdit,
      Number(inputBussinesEdit)
    );
    closeModalEdit();
    setReloadData(true);
    setInputBussinesEdit("");
    setInputNameIdEdit("");
    setInputNameEdit("");
  };
  useEffect(() => {
    paginationAll();
  }, [paginationListe]);
  useEffect(() => {
    getAllBuss();
    getSearch();
    paginationAll();
    getAllInt();
  }, []);
  return (
    <div className="container" >
      <div className="square" style={{ overflowY: 'auto', height: isAtelier ? '90%' : "auto" }}>
        <ScrollView style={styles.scroll}>
          <SafeAreaView style={[stylesRepair.areaView]}>
            {!isAtelier &&
              <View style={{ paddingHorizontal: 12 }}>
                <HeaderBar
                  hasRoundedIcon={false}
                  title=""
                  onBack={() => navigation.navigate("HomeManifestazioneScreen")}
                />
              </View>
            }

            <View style={[stylesRepair.areaContent]}>
              <div className="row px-2">
                <div className="col-sm-0 p-0"></div>
                <div className="col-md-7 pb-2 col-sm-7 p-0">
                  <div className="mt-2">
                    <Text style={[stylesRepair.sectionTitle]}>Liste</Text>
                  </div>
                  <br></br>
                  <div className="col-2">
                    <TouchableOpacity
                      onPress={() =>
                        dataInteressiFilterTable.length > 0
                          ? navigation.navigate("LabelsScreenManifestazione", {
                            searchInputListe,
                            inputBussines, 
                            isImportantFilter,
                            isListeVuoteFilter,
                            searchInputListeClient,
                            isAtelier
                          })
                          : showSuccessPopup(
                            "Non ci sono record.",
                            "Informazione"
                          )
                      }
                      style={{ marginRight: 10 }}
                    >
                      <View style={[styles.buttonWhite, { width: 200 }]}>
                        <Text style={styles.buttonTextWhite}>{"Stampa"}</Text>
                      </View>
                    </TouchableOpacity>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-12" style={{ marginTop: 10 }}>
                      <Text style={[stylesRepair.sectionTitle]}>
                        Filtra importanti
                      </Text>
                      <input
                        onChange={() =>
                          setisImportantFilter(!isImportantFilter)
                        }
                        type="checkbox"
                      ></input>
                    </div>
                    <div className="col-12" style={{ marginTop: 10 }}>
                      <Text style={[stylesRepair.sectionTitle]}>
                        Liste vuote
                      </Text>
                      <input
                        onChange={() =>
                          setisListeVuoteFilter(!isListeVuoteFilter)
                        }
                        type="checkbox"
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="col-md-5 col-sm-5 p-0">
                  <SearchBox
                    placeholder="Cerca..."
                    marginRight={true}
                    value={searchInputListe}
                    setValue={setSearchInputListe}
                    handleSearchFilter={handleSearchFilter}
                    onChangeText={onChangeTextName}
                  />

                  {searchInputListe && autosuggestInputName.length > 0 ? (
                    <View
                      style={{
                        borderWidth: 1,
                        marginTop: -15,
                        paddingTop: 15,
                        marginLeft: 2,
                        marginRight: 35,
                        borderTopWidth: 0,
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius: 20,
                      }}
                    >
                      <FlatList
                        data={autosuggestInputName}
                        showsVerticalScrollIndicator={false}
                        renderItem={({ item, index }) => (
                          <Pressable
                            style={({ pressed }) => [
                              { opacity: pressed ? 0.5 : 1 },
                            ]}
                            onPress={() => {
                              setSearchInputListe(item.name),
                                setAutosuggestInputName([]);
                              //resultSearch(item)
                            }}
                          >
                            {getItemText(item)}
                          </Pressable>
                        )}
                      //keyExtractor={(item, index) => item.idList + index}
                      />
                    </View>
                  ) : null}
                  <br />
                  <SearchBox
                    placeholder="Cerca Cliente..."
                    marginRight={true}
                    value={searchInputListeClient}
                    setValue={setSearchInputListeClient}
                    handleSearchFilter={handleSearchClientFilter}
                    onChangeText={onChangeTextNameClient}
                  />
                  {searchInputListeClient &&
                    autosuggestInputNameClient.length > 0 ? (
                    <View
                      style={{
                        borderWidth: 1,
                        marginTop: -15,
                        paddingTop: 15,
                        marginLeft: 2,
                        marginRight: 35,
                        borderTopWidth: 0,
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius: 20,
                      }}
                    >
                      <FlatList
                        data={autosuggestInputNameClient}
                        showsVerticalScrollIndicator={false}
                        renderItem={({ item, index }) => (
                          <Pressable
                            style={({ pressed }) => [
                              { opacity: pressed ? 0.5 : 1 },
                            ]}
                            onPress={() => {
                              setSearchInputListeClient(item.name),
                                setAutosuggestInputNameClient([]),
                                setSearchInputListeIdClient(item.idClient);
                              //resultSearch(item)
                            }}
                          >
                            {getItemTextClient(item)}
                          </Pressable>
                        )}
                      //keyExtractor={(item, index) => item.idList + index}
                      />
                    </View>
                  ) : null}
                  <br></br>

                  {(isAdmin && !isAtelier) && (
                    <div className="d-flex align-items-center">
                      <select
                        className="mr-8 ml-8"
                        style={selectStyle.styleSelectInteressi}
                        onChange={(e) =>
                          setInputBussines(Number(e.target.value))
                        }
                        aria-label=".form-select-lg example"
                      >
                        <option value="0">Seleziona</option>
                        {dataBussines.map((d: any) => {
                          if (d.idBussines != '9') {
                            return (
                              <option
                                key={Number(d.idBussines)}
                                value={d.idBussines}
                              >
                                {d.desc_Bussines}
                              </option>
                            )
                          }
                        }
                        )}
                      </select>
                    </div>
                  )}
                </div>
              </div>
              <br></br>
              <div className="d-flex justify-content-center gap-2">
                <button
                  onClick={() => openModalAdd()}
                  className="btn btn-dark btn-lg rounded-pill"
                >
                  Crea nuova lista
                </button>
                <button
                  onClick={() => openModalE()}
                  className="btn btn-light border border-dark btn-lg rounded-pill"
                >
                  Modifica una lista
                </button>
                <button
                  onClick={() => openModalDelete()}
                  className="btn btn-light border border-dark btn-lg rounded-pill"
                >
                  Elimina una lista
                </button>
              </div>
              <div className="row">
                {dataInteressiFilterTable.length > 0 &&
                  dataInteressiFilterTable?.map((d: any) => (
                    <div className="col-12 col-sm-6 col-md-4  d-flex justify-content-center mt-4 " style={{ minHeight: '100px' }}>
                      <button
                        key={Number(d.idlist)}
                        onClick={() =>
                          navigation.navigate(!isAtelier ? "DetailsListeScreen" : "ListDetailOrdiniAtelier", [
                            d.idList,
                            d.name,
                          ])
                        }
                        className="btn btn-light border border-dark btn-lg rounded-pill w-100 h-100"
                      >
                        {d.name}
                        {!isAtelier &&
                          <>
                            <br></br>
                            <b>-</b>
                            <br></br>
                            <b> {d.desc_Bussines} </b>
                          </>
                        }
                      </button>
                    </div>
                  ))}
              </div>

              <div className="mt-4">
                {/* paginationListe.totalPages > 0 */}
                {dataInteressiFilterTable.length > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={paginaActual}
                    totalCount={paginationListe.totalPages}
                    pageSize={1}
                    onPageChange={(page) => setPaginaActual(page)}
                  />
                )}

                {/* {dataInteressiFilterTable.length > 0 && 
                  <nav aria-label="Page navigation example ">
                    <ul className="pagination justify-content-center">
                      <li className="page-item " onClick={() => (paginaActual > 1) ? setPaginaActual(paginaActual - 1) : console.log("Hasta aqui llego")}>
                        <a className="page-link rounded-circle"><Image
                          style={{
                            width: 25,
                            height: 25,
                            tintColor: "rgb(200,200,200)",
                          }}
                          source={require("../../assets/icons/icon-left.png")}
                        /></a>
                      </li>
                      {paginationAlls?.map((d: any, index) => (
                        <li className="page-item" onClick={() => setPaginaActual(index + 1)}>{paginaActual == index + 1 ? <a className="btn btn-secondary border border-light rounded-circle">{index + 1}</a> : <a className="btn btn-outline-secondary border border-light rounded-circle">{index + 1}</a>}</li>

                      ))
                      }
                      <li className="page-item " onClick={() => (paginaActual < paginationListe.totalPages) ? setPaginaActual(paginaActual + 1) : console.log("Hasta aqui llego")}>
                        <a className="page-link rounded-circle"><Image
                          style={{
                            width: 25,
                            height: 25,
                            tintColor: "rgb(200,200,200)",
                          }}
                          source={require("../../assets/icons/icon-right.png")}
                        /></a>
                      </li>
                    </ul>
                  </nav>
                } */}
              </div>
            </View>
          </SafeAreaView>
          {isOpen && (
            <ModalAplication
              title="Crea nuova lista"
              isOpen={isOpen}
              closeModal={closeModal}
            >
              <div>
                <TextInput
                  style={stylesRepair.input}
                  onChangeText={(text) => setInputName(text)}
                />
              </div>
              <div className="text-left">
                {inputName.length > 98 && (
                  <span className="mt-4 text-danger">
                    Massimo 99 caratteri.
                  </span>
                )}
              </div>
              {!isAtelier &&
                <div className="mt-4">
                  <select
                    className="mr-8 ml-8"
                    style={selectStyle.styleSelectInteressi}
                    onChange={(e) => setInputBussinesAdd(e.target.value)}
                    aria-label=".form-select-lg example"
                  >
                    <option value="default">Seleziona</option>
                    {dataBussines.map((d: any) => (
                      <option key={Number(d.idBussines)} value={d.idBussines}>
                        {d.desc_Bussines}
                      </option>
                    ))}
                  </select>
                </div>
              }
              <div className="modal-footer mt-4">
                <button
                  className="btn btn-light border border-dark btn-lg rounded-pill"
                  onClick={() => {
                    closeModal();
                    clearInputName();
                  }}
                >
                  Annulla
                </button>
                <button
                  disabled={inputName.length < 99 ? false : true}
                  className="btn btn-dark btn-lg rounded-pill"
                  onClick={() => {
                    validationAdd();
                  }}
                >
                  Conferma
                </button>
              </div>
            </ModalAplication>
          )}
          {isOpenDelete && (
            <ModalAplication
              title="Elimina una lista"
              isOpen={isOpenDelete}
              closeModal={closeModalDelete}
            >
              {!isAtelier &&
                <div>
                  <select
                    className="mr-8 ml-8"
                    style={selectStyle.styleSelectInteressi}
                    onChange={(e) => setInputBussinesDelete(e.target.value)}
                    aria-label=".form-select-lg example"
                  >
                    <option value="default">Seleziona negozio</option>
                    {dataBussines.map((d: any) => (
                      <option key={Number(d.idBussines)} value={d.idBussines}>
                        {d.desc_Bussines}
                      </option>
                    ))}
                  </select>
                </div>
              }
              <div className="mt-4">
                <select
                  disabled={(inputBussinesDelete == "" && !isAtelier) ? true : false}
                  className="mr-8 ml-8"
                  style={selectStyle.styleSelectInteressi}
                  onChange={(e) => setInputNameID(e.target.value)}
                  aria-label=".form-select-lg example"
                >
                  <option value="default">Seleziona</option>
                  {dataInteressiFilter.map((d: any) => (
                    <option key={Number(d.idList)} value={d.idList}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="modal-footer mt-4">
                <button
                  className="btn btn-light border border-dark btn-lg rounded-pill"
                  onClick={() => closeModalDelete()}
                >
                  Annulla
                </button>
                <button
                  className="btn btn-dark btn-lg rounded-pill"
                  onClick={() => validationDelete()}
                >
                  Conferma
                </button>
              </div>
            </ModalAplication>
          )}
          {isOpenEdit && (
            <ModalAplication
              title="Modifica una lista"
              isOpen={isOpenEdit}
              closeModal={closeModalEdit}
            >
              {!isAtelier &&
                <div>
                  <select
                    className="mr-8 ml-8"
                    style={selectStyle.styleSelectInteressi}
                    onChange={(e) => setInputBussinesEdit(e.target.value)}
                    aria-label=".form-select-lg example"
                  >
                    <option value="default">Seleziona negozio</option>
                    {dataBussines.map((d: any) => (
                      <option key={Number(d.idBussines)} value={d.idBussines}>
                        {d.desc_Bussines}
                      </option>
                    ))}
                  </select>
                </div>
              }
              <div className="mt-4">
                <select
                  disabled={(inputBussinesEdit == "" && !isAtelier) ? true : false}
                  className="mr-8 ml-8"
                  style={selectStyle.styleSelectInteressi}
                  onChange={(e) => setInputNameIdEdit(e.target.value)}
                  aria-label=".form-select-lg example"
                >
                  <option value="default">Seleziona</option>
                  {dataInteressiFilter.map((d: any) => (
                    <option key={Number(d.idList)} value={d.idList}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-4">
                <TextInput
                  style={stylesRepair.input}
                  value={inputNameEdit}
                  onChangeText={(text) => setInputNameEdit(text)}
                  placeholder={"   Inserisci nuovo nome"}
                />
              </div>
              <div className="modal-footer mt-4">
                <button
                  className="btn btn-light border border-dark btn-lg rounded-pill"
                  onClick={() => closeModalEdit()}
                >
                  Annulla
                </button>
                <button
                  className="btn btn-dark btn-lg rounded-pill"
                  onClick={() => validationEdit()}
                >
                  Conferma
                </button>
              </div>
            </ModalAplication>
          )}
          {modaleMessaggeWithUrl.active && (
            <PopUpMessageWithRoute
              routeNav={modaleMessaggeWithUrl.route}
              dataNav={[]}
              title={modaleMessaggeWithUrl.title}
              message={modaleMessaggeWithUrl.message}
              stateModal={modaleMessaggeWithUrl.active}
              setStateModal={setmodaleMessaggeWithUrl}
              confirmAction={confirmAction}
            />
          )}
        </ScrollView>
      </div>
    </div >
  );
};
