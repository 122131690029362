import { useState, useEffect } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
interface Props {
  notify: number;
  BussinesList: [];
  UserList: [];
  UserTecnicoList: [];
  isOpenForm: boolean;
  setIsOpenForm;
  getIdRole;
}
export const HeaderTasks = ({
  notify,
  BussinesList,
  UserList,
  UserTecnicoList,
  isOpenForm,
  setIsOpenForm,
  getIdRole
}: Props) => {
  const [idRole, setIdRole] = useState();

  useEffect(() => {
    getIdRole().then((e) => {
      setIdRole(e);
    });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center fw-bold ">
        <div className="d-flex gap-2 align-items-center">
          {notify !== 0 && (
            <div className="">
              <BsCheck2Circle
                className=""
                size={25}
                color="#d6b463"
                fontWeight={"bold"}
              />
              {/* <span className="" style={{background:'#d6b463', color:'#fff',borderRadius:'5px' ,padding:'0 .2em'}}>
                {notify.toString().padStart(2, "0")}
              </span> */}
            </div>
          )}
          {notify === 0 && (
            <BsCheck2Circle
              className=""
              size={25}
              color="#d6b463"
              fontWeight={"bold"}
            />
          )}
          <span>Tasks</span>
        </div>

        <div>
          {(idRole == 2 || idRole == 6 || idRole == 5 || idRole == 9) && (
            <IoIosAddCircle
              size={30}
              color="#d6b463"
              className="ms-4 "
              onClick={() => setIsOpenForm(!isOpenForm)}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      </div>
    </>
  );
};
