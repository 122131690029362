export const stockData = [
    {
        check: (
            <input type="checkbox" className="" />
        ),
        reference: 12345,
        brand: 'Cartier',
        quantity: 10, 
        business: 'Pisa'
    },
    {
        check: (
            <input type="checkbox" className="" />
        ),
        reference: 12312,
        brand: 'Citizen',
        quantity: 23, 
        business: 'Pisa'
    },
    {
        check: (
            <input type="checkbox" className="" />
        ),
        reference: 34567,
        brand: 'Rolex',
        quantity: 12, 
        business: 'Viareggio'
    },
    {
        check: (
            <input type="checkbox" className="" />
        ),
        reference: 56789,
        brand: 'Rolex',
        quantity: 28, 
        business: 'Livorno'
    },
    {
        check: (
            <input type="checkbox" className="" />
        ),
        reference: 23456,
        brand: 'Citizen',
        quantity: 22, 
        business: 'Pisa'
    },
    {
        check: (
            <input type="checkbox" className="" />
        ),
        reference: 45678,
        brand: 'Rolex',
        quantity: 17, 
        business: 'Pisa'
    }
]

export const filterBrand = [
    {
        name: "brand",
        id: "field-cartier",
        type: "text",
        text: "Cartier"
    },
    {
        name: "brand",
        id: "field-rolex",
        type: "text",
        text: "Rolex"
    },
    {
        name: "brand",
        id: "field-citizen",
        type: "text",
        text: "Citizen"
    }
]

export const filterBusiness = [
    {
        name: "business",
        id: "field-pisa",
        type: "text",
        text: "Pisa"
    },
    {
        name: "business",
        id: "field-livorno",
        type: "text",
        text: "Livorno"
    },
    {
        name: "business",
        id: "field-viareggio",
        type: "text",
        text: "Viareggio"
    },
    {
        name: "business",
        id: "field-forte",
        type: "text",
        text: "Forte dei Marmi"
    },
    {
        name: "business",
        id: "field-pontedera",
        type: "text",
        text: "Pontedera"
    },
    {
        name: "business",
        id: "field-vicopisano",
        type: "text",
        text: "Vicopisano"
    }
]