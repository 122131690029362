import { ImFilesEmpty } from "react-icons/im";
export const NotResultsRepairs = () => {
  return (
    <div className="col-12 col-sm-8 col-md-9 col-lg-9">
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="text-center pt-5">
          <i><ImFilesEmpty size={80} /></i>
          <h4 className="my-3">Nessun record trovato</h4>
          <p>Non sono state effettuate riparazioni</p>
        </div>
      </div>
    </div>
  );
};
