import axios from "axios";
import ConfigCapone from "../utils/ConfigCapone";

const apiNotify = `${ConfigCapone.urlapi}/api/Notify`;

export const getAllNotify = async (rol: number | string) => {
  try {
    const response = await axios.get(
      `${apiNotify}/SearchListNotify?idRol=${rol}`
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
export const putRead = async (id: number | string, idUser: number) => {
  try {
    const response = await axios.put(
      `${apiNotify}/Read?id=${id}&idUser=${idUser}`
    );
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};

export const postNotify = async (values:any) => {
  try {
    const response = await axios.post(`${apiNotify}/`, values);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }
  } catch (error) {
    throw new Error();
  }
};
