import React from "react";
import { Image, Text, View } from "react-native";
import { stylesStatisticheMagazzino } from "../../GeneralStyles/Statistiche";

export const FilterWhit = () => {
  return (
    <div className="col-12 d-flex justify-content-start">
      <View>
        <Image
          style={stylesStatisticheMagazzino.iconFilterPer}
          source={require("../../assets/icons-step-due/Icona-filtri.png")}
        />
      </View>
      <Text style={stylesStatisticheMagazzino.labelFilterPer}>
        Filtra per
      </Text>
    </div>
  );
};


