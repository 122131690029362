import { useState } from "react"

export const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [items, setItems] = useState({}) as any;
    const closeModal = () => {
      const element = document.querySelector(".modal-backdrop");
      if (element) {
        element.parentNode?.removeChild(element);
      }
      setIsOpen(false);
      setItems(null);
    };
    const openModalEdit = (e: any) => {
      const overlay = document.createElement("div");
      overlay.className = "modal-backdrop fade show";
      document.querySelector("#root")?.insertAdjacentElement("afterend", overlay);
      setIsOpen(true);
      setItems(e);
    };
    const openModalAdd = () => {
      const overlay = document.createElement("div");
      overlay.className = "modal-backdrop fade show";
      document.querySelector("#root")?.insertAdjacentElement("afterend", overlay);
      setIsOpen(true);
    };
  
    return {
      isOpen,
      items,
      setIsOpen,
      closeModal,
      openModalEdit,
      openModalAdd,
    };
  return {}
}
