import React from "react";
import CSS from "csstype";
import { IoSearchOutline } from "react-icons/io5";

import { formatNumber } from "../../../../../services/MagazzinoRefactor";
import { TbPageBreak } from "react-icons/tb";
import { useUserPermission } from "../../../../../hooks/useUserPermission";
import InputCheck from "../../../../CRM/components/InputChek";

interface Props {
  data: any[];
  goToSchedaProdotto: any;
  goToSchedaQuadrante: any;
  confirmToDelete: any;
  dataCrm: any
  goToMatricola: any
  crmSend: any
}

export const TableBodyAtelier = ({ data, goToSchedaProdotto, goToSchedaQuadrante, confirmToDelete, dataCrm, crmSend, goToMatricola, }: Props) => {
  const { isAdmin, isMarketing } = useUserPermission()
  return (
    <>
      <tbody >
        {data.map(
          ({ idMagazzino, idReference, desc_brand, prezzo, referencia, desc_famiglia, year, putAsideCount }, index) => (
            <tr key={idMagazzino} style={{ borderBottom: "1px dashed gray" }}>
              <td style={{ backgroundColor: 'white', textAlign: 'right' }}>
                {dataCrm ?
                  <InputCheck
                    name="CheckCrm"
                    text=""
                    type="checkbox"
                    inputProps={{ onChange: () => goToMatricola(data[index], "open") }}
                  />
                  :
                  <img
                    src={require("../../../../../assets/icons/sticky-note.png")}
                    onClick={() => goToSchedaProdotto(data[index], "open")}
                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                    alt="..."
                    className="icon"
                  />
                }

              </td>
              <td style={tableRow} className="align-self-center mr-4 px-4">
                {desc_brand}
              </td>
              <td style={tableRow} className="align-self-center mr-4 px-4">
                {formatNumber(Number(prezzo))}
              </td>
              <td style={tableRow} className="align-self-center mr-4 px-4">
                {referencia}
              </td>

              <td
                style={{ ...tableRow, fontSize: 12 }}
                className="align-self-center mr-4 px-4"
              >
                {desc_famiglia}
              </td>
              <td
                style={{ ...tableRow, fontSize: 12 }}
                className="align-self-center mr-4 px-4"
              >
                {putAsideCount}
              </td>
              <td
                style={{ ...tableRow, fontSize: 12 }}
                className="align-self-center mr-4 px-4"
              >
                {year}
              </td>
              <td className="d-flex justify-content-center align-items-center" style={{ backgroundColor: 'white' }}>
                <img
                  src={require("../../../../../assets/icons/info-black.png")}
                  onClick={() => goToSchedaQuadrante(data[index], "open")}
                  style={{ width: 20, height: 20, marginRight: 10, marginLeft: 5, cursor: 'pointer' }}
                  alt="..."
                  className="icon"
                />
                {isAdmin || isMarketing ? (
                  <div onClick={() => confirmToDelete(idReference, idMagazzino)}>
                    <img
                      style={{ width: 20, height: 20, marginTop: '-7px', cursor: 'pointer' }}
                      src={require("../../../../../assets/icons/icon-delete.png")}
                      alt="Delete icon"
                    />
                  </div>
                ) : null}
              </td>
            </tr>
          )
        )}
      </tbody>
    </>
  );
};
export const tableRow: CSS.Properties = {
  textAlign: "center",
  fontWeight: "400",
  verticalAlign: "middle",
  backgroundColor: "#ffffff",
};
