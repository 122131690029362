import React, { useState } from "react";
import { ScrollView, Modal, View } from "react-native";
import { HeaderModal } from "../components/HeaderModal";
import { stylesModal } from "../theme/ModalStyles";
import RepairCardScreen from "./RepairCardScreen";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  IServiceClient,
  IClient,
  getUserFromId,
  IUser,
} from "../api/ClientService";
interface IRepairsScreen {
  modalVisible: boolean;
  setModalVisible: any;
  dataClient: IClient | undefined;
  dataService: IServiceClient[] | undefined;
  navigation?: any;
}
export const RepairsScreen = ({
  modalVisible = false,
  setModalVisible,
  dataClient,
  dataService,
}: IRepairsScreen) => {
  const [modalVisibleCard, setModalVisibleCard] = useState(false);
  const [operatore, setOperatore] = useState<IUser>();
  const [dataRepair, setDataRepair] = useState<IServiceClient>();
  const handleViewData = (data: IServiceClient) => {
    getUserFromId(dataClient ? dataClient.idUser : "").then((result: IUser) =>
      setOperatore(result)
    );
    setDataRepair(data);
    setModalVisibleCard(true);
    
  };
  return (
    <>
      <View style={stylesModal.centeredView}>
        <Modal animationType="slide" transparent={true} visible={modalVisible}>
          <View
            style={[
              stylesModal.centeredView,
              { backgroundColor: "rgba(0,0,0,.32)" },
            ]}
          >
            <View style={stylesModal.modalView}>
              <HeaderModal
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                title={"Riparazioni"}
              />
              <ScrollView>
                <div className="row px-4">
                  <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-borderless mt-5 ">
                      <thead style={{ backgroundColor: "black", color: "white" }}>
                        <tr className="mr-4">
                          <th
                            style={{ borderTopLeftRadius: 50, borderBottomLeftRadius: 50 }}
                            scope="col"
                            className="align-self-center mr-4 px-4"
                          ></th>
                          <th
                            style={{
                              textAlign: "center", 
                              fontWeight: "400",
                              verticalAlign: "middle"
                            }}
                            scope="col"
                            className="align-self-center mr-4 px-4"
                          >
                            Data
                          </th>
                          <th
                            style={{
                              textAlign: "center", 
                              fontWeight: "400",
                              verticalAlign: "middle"
                            }}
                            scope="col"
                            className="align-self-center mr-4 px-4"
                          >
                            Stato
                          </th>
                          <th
                            style={{
                              textAlign: "center", 
                              fontWeight: "400",
                              verticalAlign: "middle"
                            }}
                            scope="col"
                            className="align-self-center mr-4 px-4"
                          >
                            Riparazioni
                          </th>
                          <th
                            style={{
                              borderTopRightRadius: 50,
                              borderBottomRightRadius: 50,
                            }}
                            scope="col"
                            className="align-self-center mr-4 px-4"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataService &&
                          dataService.map((data, key) => {
                            return (
                              <tr
                                key={data.idClient}
                                style={{ borderBottom: "1px solid gray" }}
                              >
                                <td></td>
                                <td
                                  style={{ textAlign: "center", verticalAlign: "middle" }}
                                  className="align-self-center mr-4 px-4"
                                >
                                  {data.dateInsert
                                    .split("T")[0]
                                    .toString()
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                                </td>
                                <td
                                  style={{ textAlign: "center", verticalAlign: "middle" }}
                                  className="align-self-center mr-4 px-4"
                                >
                                  {data.desc_status}
                                </td>
                                <td
                                  style={{ textAlign: "center", verticalAlign: "middle" }}
                                  className="align-self-center mr-4 px-4"
                                >
                                  <button
                                    style={{
                                      flex: 1,
                                      width: 100,
                                      height: 30,
                                      alignSelf: "center",
                                      backgroundColor: "transparent",
                                      borderRadius: 50,
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    onClick={() => {
                                      handleViewData(data);
                                    }}
                                  >
                                    Apri
                                  </button>
                                </td>
                                <td></td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                </div>
              </ScrollView>
            </View>
          </View>
        </Modal>
      </View>
      <RepairCardScreen
        modalVisible={modalVisibleCard}
        setModalVisible={setModalVisibleCard}
        dataClient={dataClient}
        dataService={dataRepair}
        operatore={operatore} 
        activeButton={false}
        isRepairs={true}
      />
    </>
  );
};
const headerLabels = ["Prodotto", "Data", "Stato", "Riparazioni"];