import React, {useEffect, useState} from "react";
import { NewsItem } from "./NewsItem";
import { NewList } from "./interfaces/NewList";
import '../../styles/Notify.css'
import '../../styles/PinTriangular.css'
interface Props {
  notify: NewList[];
  items: NewList[];
  listIdNewBussinesView: [];
  GetListByIdNewBussines;
  confirmviewNew;
  setIsOpenFormEdit;
  isOpenFormEdit: boolean;
  defaultListNews: any;
  setDataEdit;
  getIdRole,
  setIsOpenFormEditDisabled;
  isOpenFormEditDisabled;

}
export const ContentNews = ({ notify, setIsOpenFormEditDisabled, items, listIdNewBussinesView, GetListByIdNewBussines, confirmviewNew, setIsOpenFormEdit,setDataEdit, isOpenFormEdit, defaultListNews, getIdRole, isOpenFormEditDisabled}: Props) => {

  const [idRole, setIdRole] = useState()

  useEffect(() => {
    getIdRole().then(e => {
      setIdRole(e)
    })
  }, [])
  

  return (
    <div className="position-relative">
      <div className="triangulo"/>
      <div className="buble-notify d-flex flex-column justify-content-center align-items-center p-2 w-100">
        {notify.length > 0 ? (
          <>
            {notify.map(({ idNew, title, text, nameFile, idNewBussines, idNewBussinesUsers, idUser, isChecked, idNewUser, nameImage }) => (
              <NewsItem
                setIsOpenFormEdit={setIsOpenFormEdit}
                setDataEdit={setDataEdit}
                isOpenFormEdit={isOpenFormEdit}
                isCheckedRegister={isChecked}
                confirmviewNew={confirmviewNew}
                defaultListNews={defaultListNews}
                GetListByIdNewBussines={GetListByIdNewBussines}
                listIdNewBussinesView={listIdNewBussinesView}
                setIsOpenFormEditDisabled={setIsOpenFormEditDisabled}
                key={idNew}
                idNewBussines={idNewBussines}
                idNewBussinesUsers={idNewBussinesUsers}
                idUser={idUser}
                idNewUser={idNewUser}
                title={title}
                detail={text}
                nameFile={nameFile}
                nameImage={nameImage}
                id={idNew}
                items={items}
                idRole={idRole}
                isOpenFormEditDisabled={isOpenFormEditDisabled}
              />
            ))}
          </>
        ) : (
          <h4 className="text-center mt-4 mb-4 fs-6">Nessuna news</h4>
        )}
      </div>
    </div>
  );
};
