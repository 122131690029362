import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import ConfigCapone from "../utils/ConfigCapone";

const notPermitedCalender = ["3","7","8"];
const permitedFilter = ["2", "6"];
export const useAuth = () => {
  const { userInLogin } = useContext(UserContext);
 const [activeNotify,setAtiveNotify]=useState<boolean>(true)
  const [isAdmin, setIsAdmin] = useState<boolean>(
    userInLogin?.idRole === "2" ? true : false
  );

  setTimeout(() => {
    setIsAdmin(userInLogin?.idRole === "2" ? true : false);
  }, 100);
  const id = userInLogin ? userInLogin.idUser : null;
  const permitedCustomEmail=ConfigCapone.emailPermited.includes(userInLogin.emailUser)
  return {
    isAdmin,
    setAtiveNotify,
    activeNotify,
    id,
    user: userInLogin,
    permitedCalender: !notPermitedCalender.includes(userInLogin?.idRole),
    permitedFilter: permitedFilter.includes(userInLogin?.idRole),
    permitedCustomEmail,
    IdRole: userInLogin?.idRole
  };
};
