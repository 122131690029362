import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react'

interface Props extends NativeStackScreenProps<any, any> { }
export const redirectHome = (navigation:any) => {
  
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      navigation.navigate("HomeScreen");
      history.go(1);
    };

}