export const formatMessageEmail = (
    title: string,
    name: string,
    obje: string,
    desc_bussines: string,
    country
  ) => {
    let VariableMessageEmail;
    console.log(country);
    const titleVariable = title ? title : "";
    let countryVerify =""
    if(country != null){
      countryVerify = country.substr(-20,2);
    } else{
      return VariableMessageEmail = `
      <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Bitter&display=swap" rel="stylesheet">
      <title>Auguri di buon compleanno!</title>
      <style type="text/css">
                  #outlook a {
                      padding: 0;
                  }
                  .ExternalClass {
                      width: 100%;
                  }
                  .ExternalClass,
                  .ExternalClass p,
                  .ExternalClass span,
                  .ExternalClass font,
                  .ExternalClass td,
                  .ExternalClass div {
                      line-height: 100%;
                  }
                  table td {
                      border-collapse: collapse;
                      mso-line-height-rule: exactly;
                  }
                  .editable.image {
                      font-size: 0 !important;
                      line-height: 0 !important;
                  }
                  .nl2go_preheader {
                      display: none !important;
                      mso-hide: all !important;
                      mso-line-height-rule: exactly;
                      visibility: hidden !important;
                      line-height: 0px !important;
                      font-size: 0px !important;
                  }
                  body {
                      width: 100% !important;
                      -webkit-text-size-adjust: 100%;
                      -ms-text-size-adjust: 100%;
                      margin: 0;
                      padding: 0;
                  }
                  img {
                      outline: none;
                      text-decoration: none;
                      -ms-interpolation-mode: bicubic;
                  }
                  a img {
                      border: none;
                  }
                  table {
                      border-collapse: collapse;
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                  }
                  th {
                      font-weight: normal;
                      text-align: left;
                  }
                  *[class="gmail-fix"] {
                      display: none !important;
                  }
                  </style>
                  <style type="text/css">
                      @media (max-width: 600px) {
                          .gmx-killpill {
                              content: "03D1";
                          }
                      }
                  </style><style type="text/css">
                  @media (max-width: 600px) {
                      .gmx-killpill {
                          content: "03D1";
                      }
                      .r0-c {
                          box-sizing: border-box !important;
                          width: 100% !important;
                      }
                      .r1-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          width: 100% !important;
                      }
                      .r2-i {
                          background-color: #ffffff !important;
                      }
                      .r3-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: middle !important;
                          width: 320px !important;
                      }
                      .r4-o {
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 320px !important;
                      }
                      .r5-i {
                          padding-bottom: 0px !important;
                          padding-left: 15px !important;
                          padding-right: 15px !important;
                          padding-top: 0px !important;
                      }
                      .r6-c {
                          box-sizing: border-box !important;
                          display: block !important;
                          valign: middle !important;
                          width: 100% !important;
                      }
                      .r7-o {
                          border-style: solid !important;
                          width: 100% !important;
                      }
                      .r8-i {
                          padding-left: 0px !important;
                          padding-right: 0px !important;
                      }
                      .r9-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 200px !important;
                      }
                      .r10-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 200px !important;
                      }
                      .r11-i {
                          padding-bottom: 15px !important;
                          padding-top: 15px !important;
                      }
                      .r12-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 320px !important;
                      }
                      .r13-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 100% !important;
                      }
                      .r14-o {
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 100% !important;
                      }
                      .r15-i {
                          background-color: #ffffff !important;
                          padding-bottom: 20px !important;
                          padding-left: 15px !important;
                          padding-right: 15px !important;
                          padding-top: 20px !important;
                      }
                      .r16-c {
                          box-sizing: border-box !important;
                          display: block !important;
                          valign: top !important;
                          width: 100% !important;
                      }
                      .r17-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 100% !important;
                      }
                      .r18-c {
                          box-sizing: border-box !important;
                          text-align: left !important;
                          valign: top !important;
                          width: 100% !important;
                      }
                      .r19-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          margin: 0 auto 0 0 !important;
                          width: 100% !important;
                      }
                      .r20-i {
                          padding-bottom: 15px !important;
                          padding-top: 15px !important;
                          text-align: center !important;
                      }
                      .r21-i {
                          padding-bottom: 20px !important;
                          padding-left: 15px !important;
                          padding-right: 15px !important;
                          padding-top: 20px !important;
                      }
                      .r22-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          width: 100% !important;
                      }
                      .r23-i {
                          padding-bottom: 30px !important;
                          padding-top: 30px !important;
                      }
                      .r24-o {
                          border-style: solid !important;
                          margin-bottom: 0px !important;
                          margin-top: 0px !important;
                          width: 100% !important;
                      }
                      .r25-o {
                          border-style: solid !important;
                          margin: 0 auto 0 0 !important;
                          width: 100% !important;
                      }
                      .r26-i {
                          color: #3b3f44 !important;
                          padding-bottom: 0px !important;
                          padding-top: 0px !important;
                          text-align: center !important;
                      }
                      .r27-i {
                          color: #3b3f44 !important;
                          padding-bottom: 0px !important;
                          padding-top: 15px !important;
                          text-align: center !important;
                      }
                      .r28-i {
                          color: #3b3f44 !important;
                          padding-bottom: 15px !important;
                          padding-top: 15px !important;
                          text-align: center !important;
                      }
                      .r29-i {
                          padding-bottom: 15px !important;
                          padding-left: 0px !important;
                          padding-right: 0px !important;
                          padding-top: 0px !important;
                      }
                      .r30-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 129px !important;
                      }
                      .r31-o {
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 129px !important;
                      }
                      body {
                          -webkit-text-size-adjust: none;
                      }
                      .nl2go-responsive-hide {
                          display: none;
                      }
                      .nl2go-body-table {
                          min-width: unset !important;
                      }
                      .mobshow {
                          height: auto !important;
                          overflow: visible !important;
                          max-height: unset !important;
                          visibility: visible !important;
                          border: none !important;
                      }
                      .resp-table {
                          display: inline-table !important;
                      }
                      .magic-resp {
                          display: table-cell !important;
                      }
                  }
              </style>
              <style type="text/css"></style>
              <style type="text/css">
                  p,
                  h1,
                  h2,
                  h3,
                  h4,
                  ol,
                  ul {
                      margin: 0;
                  }
                  a,
                  a:link {
                      color: #0092ff;
                      text-decoration: underline;
                  }
                  .nl2go-default-textstyle {
                      color: #3b3f44;
                      font-family: 'Bitter', serif;
                      font-size: 16px;
                      line-height: 1.5;
                  }
                  .default-button {
                      border-radius: 4px;
                      color: #ffffff;
                      font-family: 'Bitter', serif;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: bold;
                      line-height: 1.15;
                      text-decoration: none;
                      width: 50%;
                  }
                  .default-heading1 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 36px;
                  }
                  .default-heading2 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 32px;
                  }
                  .default-heading3 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 24px;
                  }
                  .default-heading4 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 18px;
                  }
                  a[x-apple-data-detectors] {
                      color: inherit !important;
                      text-decoration: inherit !important;
                      font-size: inherit !important;
                      font-family: inherit !important;
                      font-weight: inherit !important;
                      line-height: inherit !important;
                  }
                  .no-show-for-you {
                      border: none;
                      display: none;
                      float: none;
                      font-size: 0;
                      height: 0;
                      line-height: 0;
                      max-height: 0;
                      mso-hide: all;
                      overflow: hidden;
                      table-layout: fixed;
                      visibility: hidden;
                      width: 0;
                  }
              </style>
              <style type="text/css">
                  a:link {
                      color: #0092ff;
                      text-decoration: underline;
                  }
              </style>
   </head>
      <body text="#3b3f44" link="#0092ff" yahoo="fix" style="">
      <table cellspacing="0" cellpadding="0" border="0" role="presentation" class="nl2go-body-table" width="100%" style="width: 100%;">
                  <tbody>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r1-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r3-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr>
                                                                          <td class="r5-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="middle" class="r6-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="10" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r9-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r10-o"
                                                                                                                                  style="table-layout: fixed; width: 570px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/63808ba184ab5334960b3052.png"
                                                                                                                                                  width="570"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="10" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="center" class="r12-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r13-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r14-o" style="table-layout: fixed; width: 100%;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r15-i" style="background-color: #ffffff;">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r13-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r17-o"
                                                                                                                                  style="table-layout: fixed; width: 570px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/63808d8cd6245724f11d83dc.png"
                                                                                                                                                  width="570"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td class="r13-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r14-o" style="table-layout: fixed; width: 100%;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r15-i" style="background-color: #ffffff;">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r13-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="210"
                                                                                                                                  class="r17-o"
                                                                                                                                  style="table-layout: fixed; width: 210px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/6380a44595e63a53277c9aa4.png"
                                                                                                                                                  width="210"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td class="r13-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r14-o" style="table-layout: fixed; width: 100%;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r15-i" style="background-color: #ffffff;">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r18-c" align="left">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="100%"
                                                                                                                                  class="r19-o"
                                                                                                                                  style="table-layout: fixed; width: 100%;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td
                                                                                                                                              align="center"
                                                                                                                                              valign="top"
                                                                                                                                              class="r20-i nl2go-default-textstyle"
                                                                                                                                              style="
                                                                                                                                                  color: #3b3f44;
                                                                                                                                                  font-family: 'Bitter', serif;
                                                                                                                                                  font-size: 16px;
                                                                                                                                                  line-height: 1.5;
                                                                                                                                                  text-align: center;
                                                                                                                                              "
                                                                                                                                          >
                                                                                                                                              <div>
                                                                                                                                                  <p style="margin: 0;">
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>Gentile ${titleVariable} ${name},</i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>
                                                                                                                                                              grazie per aver acquistato l’orologio <strong> ${obje} </strong>presso la nostra Boutique
                                                                                                                                                              di <strong>${desc_bussines}</strong>.
                                                                                                                                                          </i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>
                                                                                                                                                              Il nostro obiettivo è soddisfare ogni giorno le esigenze dei nostri clienti offrendo un
                                                                                                                                                              servizio d’eccellenza oltre che un prodotto di alto livello. Per questo motivo vogliamo
                                                                                                                                                              ringraziarLa nell’aver riposto la fiducia nella nostra realtà e per qualsiasi ulteriore
                                                                                                                                                              informazione siamo a sua disposizione.
                                                                                                                                                          </i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>Cogliamo l’occasione per porgerLe i nostri più Cordiali Saluti,</i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      &nbsp;
                                                                                                                                                  </p>
                                                                                                                                                  <p style="margin: 0;">
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i><strong>Gioielleria Capone</strong></i>
                                                                                                                                                      </span>
                                                                                                                                                  </p>
                                                                                                                                              </div>
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r1-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r12-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r21-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r13-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r17-o"
                                                                                                                                  style="table-layout: fixed; width: 570px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/63808e0909af9f5fa77283bd.png"
                                                                                                                                                  width="570"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r12-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r21-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r22-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r14-o"
                                                                                                                                  style="table-layout: fixed;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="30" style="font-size: 30px; line-height: 30px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r23-i" style="height: 1px;">
                                                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                                                  <tbody>
                                                                                                                                                      <tr>
                                                                                                                                                          <td>
                                                                                                                                                              <table
                                                                                                                                                                  width="100%"
                                                                                                                                                                  cellspacing="0"
                                                                                                                                                                  cellpadding="0"
                                                                                                                                                                  border="0"
                                                                                                                                                                  role="presentation"
                                                                                                                                                                  valign=""
                                                                                                                                                                  class="r23-i"
                                                                                                                                                                  height="1"
                                                                                                                                                                  style="
                                                                                                                                                                      border-top-style: solid;
                                                                                                                                                                      background-clip: border-box;
                                                                                                                                                                      border-top-color: #4a4a4a;
                                                                                                                                                                      border-top-width: 1px;
                                                                                                                                                                      font-size: 1px;
                                                                                                                                                                      line-height: 1px;
                                                                                                                                                                  "
                                                                                                                                                              >
                                                                                                                                                                  <tbody>
                                                                                                                                                                      <tr>
                                                                                                                                                                          <td height="0" style="font-size: 0px; line-height: 0px;">&shy;</td>
                                                                                                                                                                      </tr>
                                                                                                                                                                  </tbody>
                                                                                                                                                              </table>
                                                                                                                                                          </td>
                                                                                                                                                      </tr>
                                                                                                                                                  </tbody>
                                                                                                                                              </table>
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="30" style="font-size: 30px; line-height: 30px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r24-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r12-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r21-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r18-c" align="left">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="100%"
                                                                                                                                  class="r25-o"
                                                                                                                                  style="table-layout: fixed; width: 100%;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr>
                                                                                                                                          <td
                                                                                                                                              align="center"
                                                                                                                                              valign="top"
                                                                                                                                              class="r26-i nl2go-default-textstyle"
                                                                                                                                              style="
                                                                                                                                                  font-family: 'Bitter', serif;
                                                                                                                                                  color: #3b3f44;
                                                                                                                                                  font-size: 18px;
                                                                                                                                                  line-height: 1.5;
                                                                                                                                                  text-align: center;
                                                                                                                                              "
                                                                                                                                          >
                                                                                                                                              <div>
                                                                                                                                                  <p style="margin: 0; font-size: 14px;">
                                                                                                                                                      
                                                                                                                                                  </p>
                                                                                                                                                  <p style="margin: 0; font-size: 14px;">&nbsp;</p>
                                                                                                                                              </div>
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                  </tbody>
              </table>
              </body>
      `;
    }
    let countrys: string[] = [
      "italia",
      "italy",
      "Italy",
      "ITALY",
      "Italia",
      "ITALIA",
      "Italiana",
      "italiana",
      "ITALIANA",
    ];
    let countrysVer: string[] = [
      "it",
      "iT",
      "It",
      "IT",
    ];
    if (countrysVer.includes(countryVerify)) {
     return VariableMessageEmail = `
      <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Bitter&display=swap" rel="stylesheet">
      
      <title>Auguri di buon compleanno!</title>
      <style type="text/css">
                  #outlook a {
                      padding: 0;
                  }
                  .ExternalClass {
                      width: 100%;
                  }
                  .ExternalClass,
                  .ExternalClass p,
                  .ExternalClass span,
                  .ExternalClass font,
                  .ExternalClass td,
                  .ExternalClass div {
                      line-height: 100%;
                  }
                  table td {
                      border-collapse: collapse;
                      mso-line-height-rule: exactly;
                  }
                  .editable.image {
                      font-size: 0 !important;
                      line-height: 0 !important;
                  }
                  .nl2go_preheader {
                      display: none !important;
                      mso-hide: all !important;
                      mso-line-height-rule: exactly;
                      visibility: hidden !important;
                      line-height: 0px !important;
                      font-size: 0px !important;
                  }
                  body {
                      width: 100% !important;
                      -webkit-text-size-adjust: 100%;
                      -ms-text-size-adjust: 100%;
                      margin: 0;
                      padding: 0;
                  }
                  img {
                      outline: none;
                      text-decoration: none;
                      -ms-interpolation-mode: bicubic;
                  }
                  a img {
                      border: none;
                  }
                  table {
                      border-collapse: collapse;
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                  }
                  th {
                      font-weight: normal;
                      text-align: left;
                  }
                  *[class="gmail-fix"] {
                      display: none !important;
                  }
                  </style>
                  <style type="text/css">
                      @media (max-width: 600px) {
                          .gmx-killpill {
                              content: "03D1";
                          }
                      }
                  </style><style type="text/css">
                  @media (max-width: 600px) {
                      .gmx-killpill {
                          content: "03D1";
                      }
                      .r0-c {
                          box-sizing: border-box !important;
                          width: 100% !important;
                      }
                      .r1-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          width: 100% !important;
                      }
                      .r2-i {
                          background-color: #ffffff !important;
                      }
                      .r3-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: middle !important;
                          width: 320px !important;
                      }
                      .r4-o {
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 320px !important;
                      }
                      .r5-i {
                          padding-bottom: 0px !important;
                          padding-left: 15px !important;
                          padding-right: 15px !important;
                          padding-top: 0px !important;
                      }
                      .r6-c {
                          box-sizing: border-box !important;
                          display: block !important;
                          valign: middle !important;
                          width: 100% !important;
                      }
                      .r7-o {
                          border-style: solid !important;
                          width: 100% !important;
                      }
                      .r8-i {
                          padding-left: 0px !important;
                          padding-right: 0px !important;
                      }
                      .r9-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 200px !important;
                      }
                      .r10-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 200px !important;
                      }
                      .r11-i {
                          padding-bottom: 15px !important;
                          padding-top: 15px !important;
                      }
                      .r12-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 320px !important;
                      }
                      .r13-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 100% !important;
                      }
                      .r14-o {
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 100% !important;
                      }
                      .r15-i {
                          background-color: #ffffff !important;
                          padding-bottom: 20px !important;
                          padding-left: 15px !important;
                          padding-right: 15px !important;
                          padding-top: 20px !important;
                      }
                      .r16-c {
                          box-sizing: border-box !important;
                          display: block !important;
                          valign: top !important;
                          width: 100% !important;
                      }
                      .r17-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 100% !important;
                      }
                      .r18-c {
                          box-sizing: border-box !important;
                          text-align: left !important;
                          valign: top !important;
                          width: 100% !important;
                      }
                      .r19-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          margin: 0 auto 0 0 !important;
                          width: 100% !important;
                      }
                      .r20-i {
                          padding-bottom: 15px !important;
                          padding-top: 15px !important;
                          text-align: center !important;
                      }
                      .r21-i {
                          padding-bottom: 20px !important;
                          padding-left: 15px !important;
                          padding-right: 15px !important;
                          padding-top: 20px !important;
                      }
                      .r22-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          width: 100% !important;
                      }
                      .r23-i {
                          padding-bottom: 30px !important;
                          padding-top: 30px !important;
                      }
                      .r24-o {
                          border-style: solid !important;
                          margin-bottom: 0px !important;
                          margin-top: 0px !important;
                          width: 100% !important;
                      }
                      .r25-o {
                          border-style: solid !important;
                          margin: 0 auto 0 0 !important;
                          width: 100% !important;
                      }
                      .r26-i {
                          color: #3b3f44 !important;
                          padding-bottom: 0px !important;
                          padding-top: 0px !important;
                          text-align: center !important;
                      }
                      .r27-i {
                          color: #3b3f44 !important;
                          padding-bottom: 0px !important;
                          padding-top: 15px !important;
                          text-align: center !important;
                      }
                      .r28-i {
                          color: #3b3f44 !important;
                          padding-bottom: 15px !important;
                          padding-top: 15px !important;
                          text-align: center !important;
                      }
                      .r29-i {
                          padding-bottom: 15px !important;
                          padding-left: 0px !important;
                          padding-right: 0px !important;
                          padding-top: 0px !important;
                      }
                      .r30-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 129px !important;
                      }
                      .r31-o {
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 129px !important;
                      }
                      body {
                          -webkit-text-size-adjust: none;
                      }
                      .nl2go-responsive-hide {
                          display: none;
                      }
                      .nl2go-body-table {
                          min-width: unset !important;
                      }
                      .mobshow {
                          height: auto !important;
                          overflow: visible !important;
                          max-height: unset !important;
                          visibility: visible !important;
                          border: none !important;
                      }
                      .resp-table {
                          display: inline-table !important;
                      }
                      .magic-resp {
                          display: table-cell !important;
                      }
                  }
              </style>
              <style type="text/css"></style>
              <style type="text/css">
                  p,
                  h1,
                  h2,
                  h3,
                  h4,
                  ol,
                  ul {
                      margin: 0;
                  }
                  a,
                  a:link {
                      color: #0092ff;
                      text-decoration: underline;
                  }
                  .nl2go-default-textstyle {
                      color: #3b3f44;
                      font-family: 'Bitter', serif;
                      font-size: 16px;
                      line-height: 1.5;
                  }
                  .default-button {
                      border-radius: 4px;
                      color: #ffffff;
                      font-family: 'Bitter', serif;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: bold;
                      line-height: 1.15;
                      text-decoration: none;
                      width: 50%;
                  }
                  .default-heading1 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 36px;
                  }
                  .default-heading2 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 32px;
                  }
                  .default-heading3 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 24px;
                  }
                  .default-heading4 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 18px;
                  }
                  a[x-apple-data-detectors] {
                      color: inherit !important;
                      text-decoration: inherit !important;
                      font-size: inherit !important;
                      font-family: inherit !important;
                      font-weight: inherit !important;
                      line-height: inherit !important;
                  }
                  .no-show-for-you {
                      border: none;
                      display: none;
                      float: none;
                      font-size: 0;
                      height: 0;
                      line-height: 0;
                      max-height: 0;
                      mso-hide: all;
                      overflow: hidden;
                      table-layout: fixed;
                      visibility: hidden;
                      width: 0;
                  }
              </style>
              <style type="text/css">
                  a:link {
                      color: #0092ff;
                      text-decoration: underline;
                  }
              </style>
   </head>
      <body text="#3b3f44" link="#0092ff" yahoo="fix" style="">
      <table cellspacing="0" cellpadding="0" border="0" role="presentation" class="nl2go-body-table" width="100%" style="width: 100%;">
                  <tbody>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r1-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r3-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr>
                                                                          <td class="r5-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="middle" class="r6-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="10" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r9-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r10-o"
                                                                                                                                  style="table-layout: fixed; width: 570px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/63808ba184ab5334960b3052.png"
                                                                                                                                                  width="570"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="10" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="center" class="r12-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r13-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r14-o" style="table-layout: fixed; width: 100%;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r15-i" style="background-color: #ffffff;">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r13-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r17-o"
                                                                                                                                  style="table-layout: fixed; width: 570px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/63808d8cd6245724f11d83dc.png"
                                                                                                                                                  width="570"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td class="r13-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r14-o" style="table-layout: fixed; width: 100%;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r15-i" style="background-color: #ffffff;">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r13-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="210"
                                                                                                                                  class="r17-o"
                                                                                                                                  style="table-layout: fixed; width: 210px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/6380a44595e63a53277c9aa4.png"
                                                                                                                                                  width="210"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td class="r13-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r14-o" style="table-layout: fixed; width: 100%;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r15-i" style="background-color: #ffffff;">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r18-c" align="left">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="100%"
                                                                                                                                  class="r19-o"
                                                                                                                                  style="table-layout: fixed; width: 100%;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td
                                                                                                                                              align="center"
                                                                                                                                              valign="top"
                                                                                                                                              class="r20-i nl2go-default-textstyle"
                                                                                                                                              style="
                                                                                                                                                  color: #3b3f44;
                                                                                                                                                  font-family: 'Bitter', serif;
                                                                                                                                                  font-size: 16px;
                                                                                                                                                  line-height: 1.5;
                                                                                                                                                  text-align: center;
                                                                                                                                              "
                                                                                                                                          >
                                                                                                                                              <div>
                                                                                                                                                  <p style="margin: 0;">
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>Gentile ${titleVariable} ${name},</i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>
                                                                                                                                                              grazie per aver acquistato l’orologio <strong> ${obje} </strong>presso la nostra Boutique
                                                                                                                                                              di <strong>${desc_bussines}</strong>.
                                                                                                                                                          </i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>
                                                                                                                                                              Il nostro obiettivo è soddisfare ogni giorno le esigenze dei nostri clienti offrendo un
                                                                                                                                                              servizio d’eccellenza oltre che un prodotto di alto livello. Per questo motivo vogliamo
                                                                                                                                                              ringraziarLa nell’aver riposto la fiducia nella nostra realtà e per qualsiasi ulteriore
                                                                                                                                                              informazione siamo a sua disposizione.
                                                                                                                                                          </i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>Cogliamo l’occasione per porgerLe i nostri più Cordiali Saluti,</i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      &nbsp;
                                                                                                                                                  </p>
                                                                                                                                                  <p style="margin: 0;">
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i><strong>Gioielleria Capone</strong></i>
                                                                                                                                                      </span>
                                                                                                                                                  </p>
                                                                                                                                              </div>
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r1-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r12-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r21-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r13-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r17-o"
                                                                                                                                  style="table-layout: fixed; width: 570px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/63808e0909af9f5fa77283bd.png"
                                                                                                                                                  width="570"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r12-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r21-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r22-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r14-o"
                                                                                                                                  style="table-layout: fixed;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="30" style="font-size: 30px; line-height: 30px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r23-i" style="height: 1px;">
                                                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                                                  <tbody>
                                                                                                                                                      <tr>
                                                                                                                                                          <td>
                                                                                                                                                              <table
                                                                                                                                                                  width="100%"
                                                                                                                                                                  cellspacing="0"
                                                                                                                                                                  cellpadding="0"
                                                                                                                                                                  border="0"
                                                                                                                                                                  role="presentation"
                                                                                                                                                                  valign=""
                                                                                                                                                                  class="r23-i"
                                                                                                                                                                  height="1"
                                                                                                                                                                  style="
                                                                                                                                                                      border-top-style: solid;
                                                                                                                                                                      background-clip: border-box;
                                                                                                                                                                      border-top-color: #4a4a4a;
                                                                                                                                                                      border-top-width: 1px;
                                                                                                                                                                      font-size: 1px;
                                                                                                                                                                      line-height: 1px;
                                                                                                                                                                  "
                                                                                                                                                              >
                                                                                                                                                                  <tbody>
                                                                                                                                                                      <tr>
                                                                                                                                                                          <td height="0" style="font-size: 0px; line-height: 0px;">&shy;</td>
                                                                                                                                                                      </tr>
                                                                                                                                                                  </tbody>
                                                                                                                                                              </table>
                                                                                                                                                          </td>
                                                                                                                                                      </tr>
                                                                                                                                                  </tbody>
                                                                                                                                              </table>
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="30" style="font-size: 30px; line-height: 30px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r24-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r12-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r21-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r18-c" align="left">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="100%"
                                                                                                                                  class="r25-o"
                                                                                                                                  style="table-layout: fixed; width: 100%;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr>
                                                                                                                                          <td
                                                                                                                                              align="center"
                                                                                                                                              valign="top"
                                                                                                                                              class="r26-i nl2go-default-textstyle"
                                                                                                                                              style="
                                                                                                                                                  font-family: 'Bitter', serif;
                                                                                                                                                  color: #3b3f44;
                                                                                                                                                  font-size: 18px;
                                                                                                                                                  line-height: 1.5;
                                                                                                                                                  text-align: center;
                                                                                                                                              "
                                                                                                                                          >
                                                                                                                                              <div>
                                                                                                                                                  <p style="margin: 0; font-size: 14px;">
                                                                                                                                                      
                                                                                                                                                  </p>
                                                                                                                                                  <p style="margin: 0; font-size: 14px;">&nbsp;</p>
                                                                                                                                              </div>
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                  </tbody>
              </table>
              </body>
      `;
    } else {
     return VariableMessageEmail = `
      <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Bitter&display=swap" rel="stylesheet">
      <title>Auguri di buon compleanno!</title>
      <style type="text/css">
                  #outlook a {
                      padding: 0;
                  }
                  .ExternalClass {
                      width: 100%;
                  }
                  .ExternalClass,
                  .ExternalClass p,
                  .ExternalClass span,
                  .ExternalClass font,
                  .ExternalClass td,
                  .ExternalClass div {
                      line-height: 100%;
                  }
                  table td {
                      border-collapse: collapse;
                      mso-line-height-rule: exactly;
                  }
                  .editable.image {
                      font-size: 0 !important;
                      line-height: 0 !important;
                  }
                  .nl2go_preheader {
                      display: none !important;
                      mso-hide: all !important;
                      mso-line-height-rule: exactly;
                      visibility: hidden !important;
                      line-height: 0px !important;
                      font-size: 0px !important;
                  }
                  body {
                      width: 100% !important;
                      -webkit-text-size-adjust: 100%;
                      -ms-text-size-adjust: 100%;
                      margin: 0;
                      padding: 0;
                  }
                  img {
                      outline: none;
                      text-decoration: none;
                      -ms-interpolation-mode: bicubic;
                  }
                  a img {
                      border: none;
                  }
                  table {
                      border-collapse: collapse;
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                  }
                  th {
                      font-weight: normal;
                      text-align: left;
                  }
                  *[class="gmail-fix"] {
                      display: none !important;
                  }
                  </style>
                  <style type="text/css">
                      @media (max-width: 600px) {
                          .gmx-killpill {
                              content: "03D1";
                          }
                      }
                  </style><style type="text/css">
                  @media (max-width: 600px) {
                      .gmx-killpill {
                          content: "03D1";
                      }
                      .r0-c {
                          box-sizing: border-box !important;
                          width: 100% !important;
                      }
                      .r1-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          width: 100% !important;
                      }
                      .r2-i {
                          background-color: #ffffff !important;
                      }
                      .r3-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: middle !important;
                          width: 320px !important;
                      }
                      .r4-o {
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 320px !important;
                      }
                      .r5-i {
                          padding-bottom: 0px !important;
                          padding-left: 15px !important;
                          padding-right: 15px !important;
                          padding-top: 0px !important;
                      }
                      .r6-c {
                          box-sizing: border-box !important;
                          display: block !important;
                          valign: middle !important;
                          width: 100% !important;
                      }
                      .r7-o {
                          border-style: solid !important;
                          width: 100% !important;
                      }
                      .r8-i {
                          padding-left: 0px !important;
                          padding-right: 0px !important;
                      }
                      .r9-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 200px !important;
                      }
                      .r10-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 200px !important;
                      }
                      .r11-i {
                          padding-bottom: 15px !important;
                          padding-top: 15px !important;
                      }
                      .r12-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 320px !important;
                      }
                      .r13-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 100% !important;
                      }
                      .r14-o {
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 100% !important;
                      }
                      .r15-i {
                          background-color: #ffffff !important;
                          padding-bottom: 20px !important;
                          padding-left: 15px !important;
                          padding-right: 15px !important;
                          padding-top: 20px !important;
                      }
                      .r16-c {
                          box-sizing: border-box !important;
                          display: block !important;
                          valign: top !important;
                          width: 100% !important;
                      }
                      .r17-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 100% !important;
                      }
                      .r18-c {
                          box-sizing: border-box !important;
                          text-align: left !important;
                          valign: top !important;
                          width: 100% !important;
                      }
                      .r19-o {
                          background-size: auto !important;
                          border-style: solid !important;
                          margin: 0 auto 0 0 !important;
                          width: 100% !important;
                      }
                      .r20-i {
                          padding-bottom: 15px !important;
                          padding-top: 15px !important;
                          text-align: center !important;
                      }
                      .r21-i {
                          padding-bottom: 20px !important;
                          padding-left: 15px !important;
                          padding-right: 15px !important;
                          padding-top: 20px !important;
                      }
                      .r22-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          width: 100% !important;
                      }
                      .r23-i {
                          padding-bottom: 30px !important;
                          padding-top: 30px !important;
                      }
                      .r24-o {
                          border-style: solid !important;
                          margin-bottom: 0px !important;
                          margin-top: 0px !important;
                          width: 100% !important;
                      }
                      .r25-o {
                          border-style: solid !important;
                          margin: 0 auto 0 0 !important;
                          width: 100% !important;
                      }
                      .r26-i {
                          color: #3b3f44 !important;
                          padding-bottom: 0px !important;
                          padding-top: 0px !important;
                          text-align: center !important;
                      }
                      .r27-i {
                          color: #3b3f44 !important;
                          padding-bottom: 0px !important;
                          padding-top: 15px !important;
                          text-align: center !important;
                      }
                      .r28-i {
                          color: #3b3f44 !important;
                          padding-bottom: 15px !important;
                          padding-top: 15px !important;
                          text-align: center !important;
                      }
                      .r29-i {
                          padding-bottom: 15px !important;
                          padding-left: 0px !important;
                          padding-right: 0px !important;
                          padding-top: 0px !important;
                      }
                      .r30-c {
                          box-sizing: border-box !important;
                          text-align: center !important;
                          valign: top !important;
                          width: 129px !important;
                      }
                      .r31-o {
                          border-style: solid !important;
                          margin: 0 auto 0 auto !important;
                          width: 129px !important;
                      }
                      body {
                          -webkit-text-size-adjust: none;
                      }
                      .nl2go-responsive-hide {
                          display: none;
                      }
                      .nl2go-body-table {
                          min-width: unset !important;
                      }
                      .mobshow {
                          height: auto !important;
                          overflow: visible !important;
                          max-height: unset !important;
                          visibility: visible !important;
                          border: none !important;
                      }
                      .resp-table {
                          display: inline-table !important;
                      }
                      .magic-resp {
                          display: table-cell !important;
                      }
                  }
              </style>
              <style type="text/css"></style>
              <style type="text/css">
                  p,
                  h1,
                  h2,
                  h3,
                  h4,
                  ol,
                  ul {
                      margin: 0;
                  }
                  a,
                  a:link {
                      color: #0092ff;
                      text-decoration: underline;
                  }
                  .nl2go-default-textstyle {
                      color: #3b3f44;
                      font-family: 'Bitter', serif;
                      font-size: 16px;
                      line-height: 1.5;
                  }
                  .default-button {
                      border-radius: 4px;
                      color: #ffffff;
                      font-family: 'Bitter', serif;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: bold;
                      line-height: 1.15;
                      text-decoration: none;
                      width: 50%;
                  }
                  .default-heading1 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 36px;
                  }
                  .default-heading2 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 32px;
                  }
                  .default-heading3 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 24px;
                  }
                  .default-heading4 {
                      color: #1f2d3d;
                      font-family: 'Bitter', serif;
                      font-size: 18px;
                  }
                  a[x-apple-data-detectors] {
                      color: inherit !important;
                      text-decoration: inherit !important;
                      font-size: inherit !important;
                      font-family: inherit !important;
                      font-weight: inherit !important;
                      line-height: inherit !important;
                  }
                  .no-show-for-you {
                      border: none;
                      display: none;
                      float: none;
                      font-size: 0;
                      height: 0;
                      line-height: 0;
                      max-height: 0;
                      mso-hide: all;
                      overflow: hidden;
                      table-layout: fixed;
                      visibility: hidden;
                      width: 0;
                  }
              </style>
              <style type="text/css">
                  a:link {
                      color: #0092ff;
                      text-decoration: underline;
                  }
              </style>
   </head>
      <body text="#3b3f44" link="#0092ff" yahoo="fix" style="">
      <table cellspacing="0" cellpadding="0" border="0" role="presentation" class="nl2go-body-table" width="100%" style="width: 100%;">
                  <tbody>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r1-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r3-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr>
                                                                          <td class="r5-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="middle" class="r6-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="10" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r9-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r10-o"
                                                                                                                                  style="table-layout: fixed; width: 570px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/63808ba184ab5334960b3052.png"
                                                                                                                                                  width="570"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="10" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="center" class="r12-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r13-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r14-o" style="table-layout: fixed; width: 100%;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r15-i" style="background-color: #ffffff;">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r13-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r17-o"
                                                                                                                                  style="table-layout: fixed; width: 570px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/63808d8cd6245724f11d83dc.png"
                                                                                                                                                  width="570"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td class="r13-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r14-o" style="table-layout: fixed; width: 100%;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r15-i" style="background-color: #ffffff;">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r13-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="210"
                                                                                                                                  class="r17-o"
                                                                                                                                  style="table-layout: fixed; width: 210px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/6380a44595e63a53277c9aa4.png"
                                                                                                                                                  width="210"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td class="r13-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r14-o" style="table-layout: fixed; width: 100%;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r15-i" style="background-color: #ffffff;">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r18-c" align="left">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="100%"
                                                                                                                                  class="r19-o"
                                                                                                                                  style="table-layout: fixed; width: 100%;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td
                                                                                                                                              align="center"
                                                                                                                                              valign="top"
                                                                                                                                              class="r20-i nl2go-default-textstyle"
                                                                                                                                              style="
                                                                                                                                                  color: #3b3f44;
                                                                                                                                                  font-family: 'Bitter', serif;
                                                                                                                                                  font-size: 16px;
                                                                                                                                                  line-height: 1.5;
                                                                                                                                                  text-align: center;
                                                                                                                                              "
                                                                                                                                          >
                                                                                                                                              <div>
                                                                                                                                                  <p style="margin: 0;">
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>Dear ${titleVariable} ${name},</i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>
                                                                                                                                                              Thank you for purchasing the <strong> ${obje} </strong>watch at our Boutique 
                                                                                                                                                              in <strong>${desc_bussines}</strong>.
                                                                                                                                                          </i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>
                                                                                                                                                          Our goal is to satisfy the needs of our customers every day by offering an
                                                                                                                                                           excellent service as well as a high-level product. For this reason we want to
                                                                                                                                                            thank you for placing your trust in our company and we are at your disposal
                                                                                                                                                             for any further information.
                                                                                                                                                          </i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i>We take this opportunity to send you our Best Regards,</i>
                                                                                                                                                      </span>
                                                                                                                                                      <br />
                                                                                                                                                      &nbsp;
                                                                                                                                                  </p>
                                                                                                                                                  <p style="margin: 0;">
                                                                                                                                                      <span style="color: #7a7878; font-family: 'Bitter', serif;">
                                                                                                                                                          <i><strong>Capone Gioiellerie</strong></i>
                                                                                                                                                      </span>
                                                                                                                                                  </p>
                                                                                                                                              </div>
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px; background-color: #ffffff;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r1-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r12-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r21-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r13-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r17-o"
                                                                                                                                  style="table-layout: fixed; width: 570px;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r11-i" style="font-size: 0px; line-height: 0px;">
                                                                                                                                              <img
                                                                                                                                                  src="https://img.mailinblue.com/5370553/images/content_library/original/63808e0909af9f5fa77283bd.png"
                                                                                                                                                  width="570"
                                                                                                                                                  border="0"
                                                                                                                                                  class=""
                                                                                                                                                  style="display: block; width: 100%;"
                                                                                                                                              />
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="15" style="font-size: 15px; line-height: 15px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r12-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r21-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r22-c" align="center">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="570"
                                                                                                                                  class="r14-o"
                                                                                                                                  style="table-layout: fixed;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="30" style="font-size: 30px; line-height: 30px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                      <tr>
                                                                                                                                          <td class="r23-i" style="height: 1px;">
                                                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                                                  <tbody>
                                                                                                                                                      <tr>
                                                                                                                                                          <td>
                                                                                                                                                              <table
                                                                                                                                                                  width="100%"
                                                                                                                                                                  cellspacing="0"
                                                                                                                                                                  cellpadding="0"
                                                                                                                                                                  border="0"
                                                                                                                                                                  role="presentation"
                                                                                                                                                                  valign=""
                                                                                                                                                                  class="r23-i"
                                                                                                                                                                  height="1"
                                                                                                                                                                  style="
                                                                                                                                                                      border-top-style: solid;
                                                                                                                                                                      background-clip: border-box;
                                                                                                                                                                      border-top-color: #4a4a4a;
                                                                                                                                                                      border-top-width: 1px;
                                                                                                                                                                      font-size: 1px;
                                                                                                                                                                      line-height: 1px;
                                                                                                                                                                  "
                                                                                                                                                              >
                                                                                                                                                                  <tbody>
                                                                                                                                                                      <tr>
                                                                                                                                                                          <td height="0" style="font-size: 0px; line-height: 0px;">&shy;</td>
                                                                                                                                                                      </tr>
                                                                                                                                                                  </tbody>
                                                                                                                                                              </table>
                                                                                                                                                          </td>
                                                                                                                                                      </tr>
                                                                                                                                                  </tbody>
                                                                                                                                              </table>
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                      <tr class="nl2go-responsive-hide">
                                                                                                                                          <td height="30" style="font-size: 30px; line-height: 30px;">&shy;</td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td align="" class="r0-c">
                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r24-o" style="table-layout: fixed; width: 100%;">
                                  <!-- -->
                                  <tbody>
                                      <tr>
                                          <td valign="top" class="r2-i" style="background-color: #ffffff;">
                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                  <tbody>
                                                      <tr>
                                                          <td class="r12-c" align="center">
                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r4-o" style="table-layout: fixed; width: 600px;">
                                                                  <!-- -->
                                                                  <tbody>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                      <tr>
                                                                          <td class="r21-i">
                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                  <tbody>
                                                                                      <tr>
                                                                                          <th width="100%" valign="top" class="r16-c" style="font-weight: normal;">
                                                                                              <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                                                  <!-- -->
                                                                                                  <tbody>
                                                                                                      <tr>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                          <td valign="top" class="r8-i">
                                                                                                              <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                  <tbody>
                                                                                                                      <tr>
                                                                                                                          <td class="r18-c" align="left">
                                                                                                                              <table
                                                                                                                                  cellspacing="0"
                                                                                                                                  cellpadding="0"
                                                                                                                                  border="0"
                                                                                                                                  role="presentation"
                                                                                                                                  width="100%"
                                                                                                                                  class="r25-o"
                                                                                                                                  style="table-layout: fixed; width: 100%;"
                                                                                                                              >
                                                                                                                                  <tbody>
                                                                                                                                      <tr>
                                                                                                                                          <td
                                                                                                                                              align="center"
                                                                                                                                              valign="top"
                                                                                                                                              class="r26-i nl2go-default-textstyle"
                                                                                                                                              style="
                                                                                                                                                  font-family: 'Bitter', serif;
                                                                                                                                                  color: #3b3f44;
                                                                                                                                                  font-size: 18px;
                                                                                                                                                  line-height: 1.5;
                                                                                                                                                  text-align: center;
                                                                                                                                              "
                                                                                                                                          >
                                                                                                                                              <div>
                                                                                                                                                  <p style="margin: 0; font-size: 14px;">
                                                                                                                                                      
                                                                                                                                                  </p>
                                                                                                                                                  <p style="margin: 0; font-size: 14px;">&nbsp;</p>
                                                                                                                                              </div>
                                                                                                                                          </td>
                                                                                                                                      </tr>
                                                                                                                                  </tbody>
                                                                                                                              </table>
                                                                                                                          </td>
                                                                                                                      </tr>
                                                                                                                  </tbody>
                                                                                                              </table>
                                                                                                          </td>
                                                                                                          <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">&shy;</td>
                                                                                                      </tr>
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </th>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </td>
                                                                      </tr>
                                                                      <tr class="nl2go-responsive-hide">
                                                                          <td height="20" style="font-size: 20px; line-height: 20px;">&shy;</td>
                                                                      </tr>
                                                                  </tbody>
                                                              </table>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                  </tbody>
              </table>
              </body>
      `;
    }
  
  //   return {
  //     VariableMessageEmail,
  //   };
  };
  