import { useIsFocused } from "@react-navigation/native";
import { useAuth } from "../../../../hooks/useAuth";
import { useProfile } from "../../../../components/NavProfile/hook/useProfile";
import ShowContentSidebar from "../../../../components/NavProfile/ShowContentSidebar";
import { Navbar } from "../../../../components/Navbar";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import NavProfile from "../../../../components/NavProfile/NavProfile";
import { LabelsScreenManifestazione } from "../../LabelsScreenManifestazione";
interface Props extends NativeStackScreenProps<any, any> {

}
const StampaManifestazione = ({navigation,route}:Props) => {
    const { user } = useAuth();
    const isFocused = useIsFocused();
    const { sidebarVisible, toggleSidebar } = useProfile()
    return (
        <>
            <ShowContentSidebar
                sidebarVisible={sidebarVisible}
                toggleSidebar={toggleSidebar}
            />
            <div className=" container-layout" >
                <div className="row row-layout" >
                    <div className={` ${sidebarVisible ? 'col-12 d-block' : 'col-lg-3 col-md-none d-none d-md-non d-lg-block'} sidebar-container-layout sidebar-transition`}  >
                        <Navbar activeNav={isFocused} componentName={route.name} />
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12 content-container-layout" style={{ paddingLeft: '1.5rem' }}>
                        <NavProfile
                            showMenuBack={true}
                            sidebarVisible={sidebarVisible}
                            toggleSidebar={toggleSidebar}
                            goBack="HomeSCDueScreen"
                            goBackName="Pannello di controllo"
                            
                        />
                        <div className="w-100" style={{ paddingTop: '11%' }}>
                            <LabelsScreenManifestazione route={route} navigation={navigation}/>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default StampaManifestazione