import { SafeAreaView } from "react-native";
import { Calender } from "../components/Calender/Calender";
import { MenuMobile } from "../components/MenuMobile";
import { Navbar } from "../components/Navbar";
import { Notify } from "../components/Notify/Notify";
import { stylesDueHome } from "../GeneralStyles/SCDue/HomeSCDue";
import { useAuth } from "../hooks/useAuth";
import ConfigCapone from "../utils/ConfigCapone";
import { News } from "../components/News/News";
import { Tasks } from "../components/Tasks/Tasks";
import NavProfile from "../components/NavProfile/NavProfile";
import '../styles/Home.css'
import { useProfile } from "../components/NavProfile/hook/useProfile";
import ShowContentSidebar from "../components/NavProfile/ShowContentSidebar";
import { useIsFocused } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import EventToday from "../components/Calender/Today/EventToday";
interface Props extends NativeStackScreenProps<any, any> {

}
export const HomeSCDueScreen = ({ route }: Props) => {
  const { isAdmin, permitedCalender, activeNotify, setAtiveNotify, permitedCustomEmail,user } = useAuth();
  const { sidebarVisible, toggleSidebar, setSidebarVisible } = useProfile()
  const isFocused = useIsFocused();
  const [first, setFirst] = useState(false)

  let arrayData: any = route.params || [];

  useEffect(() => {  
    if(arrayData?.refresh){
      setSidebarVisible(false)
    }
  }, [arrayData]) 
  
  return (
    <>
      <ShowContentSidebar
        sidebarVisible={sidebarVisible}
        toggleSidebar={toggleSidebar}
      />
      <div className="container-layout">
        <div className="row row-layout" >
          <div className={` ${sidebarVisible ? 'col-12 d-block' : 'col-lg-3 col-md-none d-none d-md-non d-lg-block'} sidebar-container-layout sidebar-transition`} >
            <Navbar activeNav={isFocused} componentName={route.name}/>
          </div>
          <div className="col-lg-9 col-md-12 col-sm-12 content-container-layout" style={{overflowY: 'auto'}} >
            <NavProfile 
              sidebarVisible={sidebarVisible} 
              toggleSidebar={toggleSidebar}
            />
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-6 mb-2">
                {!permitedCustomEmail ? permitedCalender && <EventToday /> : null}
                <News />
                <Tasks />
                {!permitedCustomEmail ?
                  isAdmin &&
                  <div className="">
                    <Notify />
                  </div>
                  : null}
              </div>
              <div className="col-12 col-sm-12 col-lg-6 mb-5">
                {!permitedCustomEmail ? permitedCalender && <Calender activeNotify={activeNotify} setAtiveNotify={setAtiveNotify} /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};