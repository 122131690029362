interface Props {
  id: string;
  name: string;
  marginleft?: boolean;
  checked: boolean;
  onChange: (id: string) => void;
}

export const LabelStatische = ({
  id,
  name,
  marginleft = false,
  checked,
  onChange,
}: Props) => {
  return (
    <label
      htmlFor={id}
      style={{
        fontSize: 18,
        marginBottom: 5,
        fontFamily: "Arsapia Regular font",
      }}
    >
      <input
        type="checkbox"
        className="checksMagazzino"
        style={{
          margin: marginleft ? 0 : 10,
          marginLeft: marginleft ? 0 : 10,
          marginRight: marginleft ? 15 : 10,
          fontSize: 100,
        }}
        name={name}
        checked={checked}
        onChange={() => onChange(id)}
        id={id}
      />
      {name}
    </label>
  );
};
