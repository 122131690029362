import aplicationConnect from "../../../api/applicationConnect";

export const bussinesNewByIdUserScreen = async (idUser:Number) => {
    try {
      const response = await aplicationConnect.get(`NewBussinesUsers/GetByIdUser`,{
        params:{
            idUser:idUser
        }
      });
      const { data } = response;
      if (data.status === "success") {
        return data.response;
      }
  
    } catch (error) {
      throw new Error();
    }
  };
  //userNewByIdUser
  export const userNewByIdUser = async (idUser:Number) => {
    try {
      const response = await aplicationConnect.get(`NewUser/GetByIdUser`,{
        params:{
            idUser:idUser
        }
      });
      const { data } = response;
      if (data.status === "success") {
        return data.response;
      }
  
    } catch (error) {
      throw new Error();
    }
  };
  export const listByidNewBussines = async (idNewBussines:Number) => {
    try {
      const response = await aplicationConnect.get(`NewBussinesUsers/GetByIdNewBussines`,{
        params:{
          idNewBussines:idNewBussines
        }
      });
      const { data } = response;
      if (data.status === "success") {
        return data.response;
      }
  
    } catch (error) {
      throw new Error();
    }
  };
  export const putnewBussinesIsChecked = async (dataSend) => {
    const dataModify={
      idNewBussinesUsers:Number(dataSend.idNewBussinesUsers),
      idNewBussines:Number(dataSend.idNewBussines),
      idUser:Number(dataSend.idUser),
      isChecked:1
    }
    try {
      const response = await aplicationConnect.put(`NewBussinesUsers`,dataModify);
      const { data } = response;
      if (data.status === "success") {
        return data.response;
      }
  
    } catch (error) {
      throw new Error();
    }
  };
  export const putNewUserIsChecked = async (idNewUser:number) => {
    
    try {
      const response = await aplicationConnect.put(`NewUser/isChecked?idNewUser=${idNewUser}&isChecked=1`);
      const { data } = response;
      if (data.status === "success") {
        return data.response;
      }
  
    } catch (error) {
      throw new Error();
    }
  };