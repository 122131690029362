import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { boolean } from 'yup/lib/locale';


export interface IUserPermission {
    isAdmin: boolean;
    isTecnico: boolean,
    isDipendente: boolean,
    isDirectore: boolean,
    isMarketing: boolean,
    bussinesUser:number
}

export const useUserPermission = () => {

    const { userInLogin } = useContext(UserContext);

    const [isAdmin, setIsAdmin] = useState<boolean>(
        userInLogin?.idRole === "2" ? true : false
    );
    const [bussinesUser, setBussinesUser] = useState<number>(
        Number(userInLogin?.idBussines)
    );

    const [isTecnico, setIsTecnico] = useState<boolean>(
        (userInLogin?.idRole === "3" || userInLogin?.idRole === "7" || userInLogin?.idRole === "8") ? true : false
    );

    const [isDipendente, setIsDipendete] = useState<boolean>(
        userInLogin?.idRole === "4" ? true : false
    );

    const [isDirectore, setIsDirectore] = useState<boolean>(
        userInLogin?.idRole === "5" ? true : false
    );

    const [isMarketing, setIsMarketing] = useState<boolean>(
        userInLogin?.idRole === "6" ? true : false
    );

    const users: IUserPermission = {
        isAdmin: isAdmin,
        isTecnico: isTecnico,
        isDipendente: isDipendente,
        isDirectore: isDirectore,
        isMarketing: isMarketing,
        bussinesUser:bussinesUser
    }

    return users
}