import React, { useState } from 'react'
import { ExportListMagazzino } from '../../../../services/MagazzinoRefactor';
import ReactExport from "react-data-export/";
import { todayFormatDate } from '../../../../utils/helpers';


interface Props {
    magazzinoList:any;
    setLoading:any;
}

const useExportExcelMagazzinoAtelier = (magazzinoList:any,setLoading:any) => {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const nameFileExport = "Tabella Magazzino " + todayFormatDate

    const [tableFilterCsv, setTableFilterCsv] = useState<any>([]);
    const [donwnloadExcel, setDonwnloadExcel] = useState<boolean>(false);
    const [dataset, setDataset] = useState<any>([]);
  
    const handleDataExport = async () => {
      setLoading(true);
      setDonwnloadExcel(true);
      await dataExportMatricola();
      setLoading(false);
      setDonwnloadExcel(false);
    };
  
    const dataExportMatricola = async () => {
      //NEW ENDPOINT EXCEL
      let selectedReferencia = {
        listidReference: [],
      };
      setTableFilterCsv([]);
      for (let i = 0; i < magazzinoList.length; i++) {
        selectedReferencia.listidReference.push(magazzinoList[i].idReference);
      }
      try {
        const response = await Promise.all([
          ExportListMagazzino(selectedReferencia)
        ]);
        const result = response[0]
        setTableFilterCsv(result);
      } catch (error) {
        console.log(error);
      } finally {
        //setLoading(false);
      }
      
    };
  
      const DataSet = [
      {
        columns: [
          { title: "Referenza", style: { font: { sz: "18", bold: true } } },
          { title: "Brand", style: { font: { sz: "18", bold: true } } },
          { title: "Descrizione", style: { font: { sz: "18", bold: true } } },
          { title: "Quantità", style: { font: { sz: "18", bold: true } } },
          { title: "Negozio", style: { font: { sz: "18", bold: true } } },
          { title: "Prezzo", style: { font: { sz: "18", bold: true } } },
          { title: "Matricola", style: { font: { sz: "18", bold: true } } },
        ],
        data:
          tableFilterCsv &&
          tableFilterCsv.map((data: any) => [
            {
              value: data.referenza ? data.referenza : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
            {
              value: data.brand ? data.brand : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
            {
              value: data.desc_famiglia ? data.desc_famiglia : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
            {
              value: data.quantity ? data.quantity : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
            {
              value: data.negozio ? data.negozio : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
            {
              value: data.prezzo ? data.prezzo : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
            {
              value: data.matricolas ? data.matricolas : "N/D",
              style: { font: { sz: "14", bold: false } },
            },
          ]),
      },
    ];
  
  return {donwnloadExcel,DataSet,handleDataExport,ExcelFile,ExcelSheet,nameFileExport}
}

export default useExportExcelMagazzinoAtelier