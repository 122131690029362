import { Text, TextInput, TouchableOpacity, View } from 'react-native'
import { styleInput, styleInputBlock, stylesCRM, styleTextArea } from "../../GeneralStyles/SCDue/ControlPanel";
import { styles } from "../../theme/GlobalStyle";
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it"
import { CheckLabel } from '../CheckLabel';
import { IClient } from '../../interfaces/IClient';
import { BsStarFill } from 'react-icons/bs';
import { useUserPermission } from '../../hooks/useUserPermission';
import { GridListInteressiByClient } from './GridListInteressiByClient';
import useAddListInteressi from '../../hooks/useAddListInteressi';
import AutosuggestInputName from './AutosuggestInputName';
import { validateEmail } from '../../helpers/patterns';
import PopupConfirmation from '../PopupConfirmation';
import CheckEmaildiRingraziamento from './CheckEmaildiRingraziamento';
registerLocale("it", it)

type Props = {
    optionAddEdit,
    setOptionAddEdit,
    setmodaleMessaggeWithUrl,
    setActiveSpinner,
    idClientExist,
    setIdClientExist,
    setModalVisibleCardBlackList,
    IsAtelier?: boolean,
}

export const AddFormListInteressi = (
    {
        optionAddEdit,
        setOptionAddEdit,
        setmodaleMessaggeWithUrl,
        setActiveSpinner,
        idClientExist,
        setIdClientExist,
        setModalVisibleCardBlackList, IsAtelier,
    }: Props) => {

    const [dataClient, setDataClient] = useState<IClient>();
    const errorMessageRequired = "Il campo è obbligatorio";
    const { isDipendente, isDirectore, bussinesUser } = useUserPermission()

    // form
    const valueDefect: any = {
        Name: "",
        Email: "",
        Phone: "",
        Note: "",
        DateInsert: "",
        BlackList: "",
        ListInserire: "",
        PresentCrm: idClientExist > 0 ? "Presente" : "Non presente",
        NoteInteressi: "",
    };

    const {
        setValue,
        getValues,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: valueDefect,
    });

    const {
        dataInteressi,
        dataListInteressiByClient,
        confirmToDelete,
        resultSearch,
        changeEmail,
        onChangeTextName,
        handleData,
        handleDataEdit,
        stars,
        currentValue,
        handleClickStart,
        handleMouseOverStart,
        handleMouseLeaveStart,
        hoverValue,
        colors,
        stateBlackList,
        checkBoxBlackListChecked,
        changeBlackList,
        changeIsImportant,
        checkBoxIsImportantChecked,
        checkBoxEmail,
        searchInputName,
        autosuggestInputName,
        setSearchInputName,
        setAutosuggestInputName,
        confirmToDeleteTrue,
        modal,
        setModal,
        idDeleteUser,
        idDeleteClient,
        checkBoxEmailRingraziamento,
        changeEmailRingraziamento,
        dataBussinesList,
        titleType,
    } = useAddListInteressi(
        idClientExist,
        setIdClientExist,
        dataClient,
        setDataClient,
        getValues,
        setValue,
        optionAddEdit,
        setOptionAddEdit,
        setmodaleMessaggeWithUrl,
        setModalVisibleCardBlackList,
        setActiveSpinner, IsAtelier,
    )
    if (idClientExist > 0) {
        setValue("PresentCrm", idClientExist > 0 ? "Presente" : "Non presente")
    }

    return (
        <View style={styles.container}>
            <div className="row justify-content-between">
                <div className="col-4">
                    <Text
                        style={stylesCRM.label}>
                        Aggiungi cliente
                    </Text>
                </div>
            </div>
            <Text style={styles.label}>Titolo *</Text>
            <Controller
                control={control}
                name="Title"
                render={({ field: { onChange, value, onBlur } }) => (
                    <select
                        className="mr-8 ml-8 "
                        autoFocus={true}
                        style={styleInput}
                        value={value}
                        defaultValue={'defaultNegozio'}
                        onChange={(val) => onChange(val.target.value)}
                        aria-label=".form-select-lg example">

                        <option value="defaultNegozio">Seleziona</option>
                        {titleType?.map((r: any) => (
                            <option key={r.idTitle} value={r.idTitle}>{r.desc_Title}</option>
                        ))}

                    </select>
                )}
                rules={{ required: true }}
            />
            {errors.Title?.type === "required" && (
                <Text style={stylesCRM.labelError}>
                    {errorMessageRequired}
                </Text>
            )}
            <Text style={styles.label}>Nome e cognome</Text>
            <Controller
                control={control}
                name="Name"
                render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                        value={searchInputName}
                        onBlur={() => console.log('search client...')}
                        style={styleInput}
                        onChange={(e: any) => onChange(e?.target?.value)}
                        onChangeText={onChangeTextName}
                    />
                )}
                rules={{ required: true }}
            />
            {errors.Name?.type === "required" && (
                <Text style={stylesCRM.labelError}>
                    {errorMessageRequired}
                </Text>
            )}

            {searchInputName && autosuggestInputName.length > 0 ? (
                <AutosuggestInputName
                    autosuggestInputName={autosuggestInputName}
                    setSearchInputName={setSearchInputName}
                    setAutosuggestInputName={setAutosuggestInputName}
                    resultSearch={resultSearch}
                />
            ) : null}

            <Text style={styles.label}>E-mail</Text>
            <Controller
                control={control}
                name="Email"
                render={({ field: { onChange, onBlur, value } }) => (
                    <input
                        className="form-control"
                        value={value}
                        style={styleInput}
                        onChange={(e) => onChange(e.target.value)}
                    />
                )}
                rules={{
                    required: false,
                    //pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,10})$/g,
                    pattern: validateEmail,
                }}
            />
            {/* {errors.Email?.type === "required" && (
                <Text style={stylesCRM.labelError}>
                    {errorMessageRequired}
                </Text>
            )} */}
            {errors.Email?.type === "pattern" && (
                <Text style={stylesCRM.labelError}>E-mail errata</Text>
            )}

            <Text style={styles.label}>Data di iscrizione</Text>
            <Controller
                control={control}
                name="DateInsert"
                render={({ field: { onChange, onBlur, value } }) => (
                    <DatePicker
                        locale={it}
                        dateFormat='dd/MM/yyyy'
                        style={{ paddingLeft: 100 }}
                        maxDate={new Date()}
                        setDate={new Date()}
                        onChange={(date) => { console.log("Date", date), onChange(date) }}
                        selected={value}
                        showYearDropdown
                        scrollableMonthYearDropdown
                    />
                )}
                rules={{ required: true }}
            />

            {errors.DateInsert?.type === "required" && (
                <Text style={stylesCRM.labelError}>
                    {errorMessageRequired}
                </Text>
            )}

            <Text style={styles.label}>Cellulare</Text>
            <Controller
                control={control}
                name="Phone"
                render={({ field: { onChange, onBlur, value } }) => (
                    <input
                        className="form-control"
                        value={value}
                        style={styleInput}
                        maxLength={25}
                        type="number"
                        onChange={(e) => onChange(e.target.value)}
                    />
                )}
                rules={{ required: true }}
            />

            {errors.Phone?.type === "required" && (
                <Text style={stylesCRM.labelError}>
                    {errorMessageRequired}
                </Text>
            )}
            {!IsAtelier &&
                <div className="col-12 mt-4">
                    <Text style={styles.label}>Negozio </Text>
                    {isDipendente || isDirectore ?
                        <Controller
                            control={control}
                            name="OriginIdBussines"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <select disabled
                                    className="mr-8 ml-8"
                                    style={styleInputBlock}
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                    aria-label=".form-select-lg example">
                                    {dataBussinesList.map((d: any) => {
                                        if (d.idBussines != '9') {
                                            return value ? <option key={Number(d.idBussines)}
                                                value={d.idBussines}>{d.desc_Bussines}</option> : bussinesUser == d.idBussines && <option key={Number(d.idBussines)}
                                                    value={d.idBussines}>{d.desc_Bussines}</option>
                                        }

                                    })}
                                </select>
                            )}
                        />

                        : <Controller
                            control={control}
                            name="OriginIdBussines"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <select
                                    className="mr-8 ml-8"
                                    style={styleInput}
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                    aria-label=".form-select-lg example">
                                    <option value="default">Seleziona</option>
                                    {dataBussinesList.map((d: any) => {
                                        if (d.idBussines != '9') {
                                            return <option key={Number(d.idBussines)}
                                                value={d.idBussines}>{d.desc_Bussines}</option>
                                        }
                                    })}
                                </select>
                            )}
                        />}

                </div>
            }

            <Text style={styles.label}>Note</Text>
            <Controller
                control={control}
                name="Note"
                render={({ field: { onChange, onBlur, value } }) => (
                    <textarea
                        className="form-control"
                        value={value}
                        style={styleTextArea}
                        onChange={(e) => onChange(e.target.value)}
                        rows={6}
                    />
                )}
            />

            <div hidden className="row mt-4">
                <div className="col-12 text-end">
                    <label style={{ fontSize: 18, marginBottom: 5, fontFamily: "Arsapia Regular font" }} >
                        <input
                            type="checkbox"
                            style={{
                                margin: 10,
                                marginLeft: 10,
                                marginRight: 10,
                                fontSize: 100
                            }}
                            disabled={optionAddEdit ? false : true}
                            checked={checkBoxEmail}
                            onChange={(e) => { changeEmail(!checkBoxEmail) }}
                        />
                        Invia e-mail
                    </label>
                </div>
            </div>

            <div className="row mt-4 mb-4">
                <div className="col-12">
                    <Text style={styles.label}>Stelle valutazione cliente</Text>
                    <br /><br />
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: 20 }}>
                        {stars.map((_, index) => {
                            return (
                                <BsStarFill
                                    key={index}
                                    size={40}
                                    onClick={() => handleClickStart(index + 1)}
                                    onMouseOver={() => handleMouseOverStart(index + 1)}
                                    onMouseLeave={handleMouseLeaveStart}
                                    color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                                    style={{
                                        marginRight: 50,
                                        cursor: "pointer",
                                        marginTop: 10
                                    }}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>

            <Text style={styles.label}>CRM</Text>
            <Controller
                control={control}
                name="PresentCrm"
                render={({ field: { onChange, onBlur, value } }) => (
                    <input
                        className="form-control"
                        value={value}
                        style={styleInputBlock}
                        onChange={(e) => onChange(e.target.value)}
                        disabled={true}
                    />
                )}
            />

            {/* list pressentti */}
            <GridListInteressiByClient
                control={control}
                dataListInteressiByClient={dataListInteressiByClient}
                confirmToDelete={confirmToDelete}
                optionAddEdit={optionAddEdit}
                checkBoxEmailRingraziamento={checkBoxEmailRingraziamento}
                changeEmailRingraziamento={changeEmailRingraziamento}
            />
            {!isDipendente &&
                <div className="col-12 mt-4">
                    <Text style={styles.label}>Lista de Inserire </Text>
                    <Controller
                        control={control}
                        name="ListInserire"
                        render={({ field: { onChange, value, onBlur } }) => (
                            <select
                                className="mr-8 ml-8 "
                                style={styleInput}
                                value={value}
                                defaultValue={'default'}
                                onChange={(val) => onChange(val.target.value)}
                                aria-label=".form-select-lg example">
                                <option value="default">Seleziona</option>
                                {dataInteressi.map((d: any) => {
                                    return <option key={Number(d.idList)}
                                        value={d.idList}>{d.name} - {d.desc_Bussines}</option>
                                })}
                            </select>
                        )}
                        rules={{ required: true }}
                    />

                    {errors.ListInserire?.type === "required" && (
                        <Text style={stylesCRM.labelError}>
                            {errorMessageRequired}
                        </Text>
                    )}

                </div>
            }
            <CheckEmaildiRingraziamento
                optionAddEdit={optionAddEdit}
                checkBoxEmailRingraziamento={checkBoxEmailRingraziamento}
                changeEmailRingraziamento={changeEmailRingraziamento}
                id={0}
            />
            {/* Note Client List Interessi */}
            <Text style={styles.label}>Note</Text>
            <Controller
                control={control}
                name="NoteInteressi"
                render={({ field: { onChange, onBlur, value } }) => (
                    <input
                        className="form-control"
                        placeholder='Inserire quadrante e bracciale'
                        value={value}
                        style={styleInput}
                        onChange={(e) => onChange(e.target.value)}
                    />
                )}
                rules={{
                    required: false,
                    //pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g,
                }}
            />
            <div className="col-4 d-flex justify-content-start mt-4">
                <div className="float-right">
                    <CheckLabel
                        name={"blackList"}
                        id={"blackList-" + 1}
                        type="text"
                        text={"Black List"}
                        checked={checkBoxBlackListChecked}
                        onChange={value => changeBlackList(value)}
                    />
                </div>

                {stateBlackList && (
                    <Text style={stylesCRM.labelError}>
                        {errorMessageRequired}
                    </Text>
                )}

            </div>

            <div className="float-right">
                <CheckLabel
                    name={"important"}
                    id={"important-" + 1}
                    type="text"
                    text={"Importante"}
                    checked={checkBoxIsImportantChecked}
                    onChange={value => changeIsImportant(value)}
                />
            </div>
            {!isDipendente &&
                <div className="col-12 d-flex justify-content-end" style={{ marginTop: 33, textAlign: "end", color: "white" }}>
                    <TouchableOpacity
                        style={stylesCRM.button}
                        onPress={optionAddEdit === false
                            ? (handleSubmit(() => handleData(true)))
                            : handleSubmit(() => handleDataEdit(true))
                        }
                    >
                        {optionAddEdit === true ? "Salva Modifiche" : "Salva"}
                    </TouchableOpacity>
                </div>
            }
            {modal && <PopupConfirmation
                title={""}
                message={"Sei sicuro di voler rimuovere il client dall'elenco?"}
                confirmAction={() => confirmToDeleteTrue(idDeleteUser, idDeleteClient)}
                stateModal={modal}
                setStateModal={setModal}
            />}
        </View>

    )
}

