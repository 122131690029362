import aplicationConnect from "../../../../api/applicationConnect";
import { ResponseBackendBrand, ResponseBackendDelete } from "../../../../interfaces/magazzino";
import { ResponseBackendCrmClientAtelier } from "../interfaces/ICrmAtelier";

export const listAllGender = async () => {
  try {
    const results = await aplicationConnect.get<ResponseBackendBrand>(
      "/Gender?parameter=1"
    );
    const { response, status } = results.data;
    if (status === "success") return response;
    return [];
  } catch (error) { }
};

export const listAllHobby = async () => {
  try {
    const results = await aplicationConnect.get<ResponseBackendBrand>(
      "/Hobby?parameter=1"
    );
    const { response, status } = results.data;
    if (status === "success") return response;
    return [];
  } catch (error) { }
};

export const listAllClientAtelier = async (page: number, limit = 50) => {
  try {
    const results = await aplicationConnect.get<ResponseBackendCrmClientAtelier>(
      "/Client/SearchListClientAtelierFilterCrm", {
        params: {
          page: page,
          limit: limit,
          brandFilter: [],
          referenza: '',
          prodotto: ''
        }
      }
    );
    const { response, status, total, currentPage, totalPages } = results.data;
    let newData = {
      response,
      status,
      total,
      currentPage,
      totalPages
    }
    if (status === "success") return newData;
    return [];
  } catch (error) {
    console.log(error);
  }
};

interface SearchCrmClientAtelier  {
  genderFilter: number[];
  startFilter: number[];
  brandFilter: number[];
  valoreSpesaFilter: number[];
  etaFilter: number[];
  hobbyFilter: number[];
  dataInserimentoFilter: number[];
  dataAggiornamentoFilter: number[];
  depricatedBackListEnviaCatalogoFilter: number[];
  referenzaFilter: string,
  MatricolaFilter: string,
  professionFilter: string,
  noteFilter: string,
  clientFilter: string
  page?: number,
  limit?: number,
};

export const listAllCrmClientAtelierWhitSearch = async ({
  genderFilter,
  startFilter,
  brandFilter,
  valoreSpesaFilter,
  etaFilter,
  hobbyFilter,
  dataInserimentoFilter,
  dataAggiornamentoFilter,
  depricatedBackListEnviaCatalogoFilter,
  referenzaFilter,
  MatricolaFilter,
  professionFilter,
  noteFilter,
  clientFilter,
  limit,
  page,
}: SearchCrmClientAtelier) => {
  try {
    const results = await aplicationConnect.get<ResponseBackendCrmClientAtelier>(
      "/Client/SearchListClientAtelierFilterCrm",
      {
        params: {
          genderFilter,
          startFilter,
          brandFilter,
          valoreSpesaFilter,
          etaFilter,
          hobbyFilter,
          dataInserimentoFilter,
          dataAggiornamentoFilter,
          depricatedBackListEnviaCatalogoFilter,
          referenzaFilter,
          MatricolaFilter,
          professionFilter,
          noteFilter,
          clientFilter,
          limit,
          page,
        },
      }
    );
    const { response, status, total, currentPage, totalPages } = results.data;
    let newData = {
      response,
      status,
      total,
      currentPage,
      totalPages
    }
    if (status === "success") return newData;
    return [];
  } catch (error) {
    console.log(error);
  }
};

export const referenciaByAtelier = async (search:string, limit:number = 10) => {
  try {
    const results = await aplicationConnect.get<ResponseBackendBrand>(
      `/ClientProdposseduti/FindAtelierByLimit/${search}/${limit}`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
    return [];
  } catch (error) { }
};

export const DeleteClientAtelier = async (idClient: number)=>{
  try {
    const results = await aplicationConnect.delete<ResponseBackendDelete>(
      `/Client/${idClient}`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
  } catch (error) {}
}


