import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { Text} from "react-native";


interface Props {
  onChangeTextClient: any
  searchInputClient: string
  handleSearchFilter: any
}
export const SearchCrmAtelier = ({onChangeTextClient,searchInputClient,handleSearchFilter}: Props) => {
  
  return (
    <>
      <div className="row" >
        <div className="col-12 col-md-12 col-lg-5">
          <div className="mt-2" style={{ marginLeft: -13 }}>
            <Text
              style={{
                fontWeight: "700",
                fontSize: 20,
                alignSelf: "center",
                paddingLeft: 10,
              }}
            >
              Lista clienti
            </Text>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-7" style={{float: 'right'}}>
            <div className="d-flex justify-content-end align-items-center">
                <input
                    className="input-cerca mr-2"
                    type="text"
                    placeholder="Cerca ..."
                    onChange={(event) => onChangeTextClient(event.target.value)}
                    value={searchInputClient}
                />
                <p className="container-icon-search" onClick={() => handleSearchFilter()}>
                    <IoSearchOutline
                        color="#ffffff"
                        size={20}
                        className=""
                        style={{ cursor: "pointer" }}
                    />
                </p>
            </div>
        </div>
      </div>
    </>
  );
};
