import React, { ButtonHTMLAttributes } from 'react';
import './css/buttonComponent.css';
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    onClick?: () => void;
    variant?: number;
}

const ButtonComponent: React.FC<ButtonProps> = ({ text, onClick, variant = 1, ...rest }) => {
    return (
        <button className={`submit-atelier variant-${variant}`} onClick={onClick} {...rest}>
            {text}
        </button>
    );
}

export default ButtonComponent;
