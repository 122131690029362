import { useNavigation } from "@react-navigation/native";
import { SafeAreaView, StatusBar } from "react-native";
import { NavigationProps } from "../../navigator/NavigatorConfig";
export const HeaderBack = () => {
  const navigation = useNavigation<NavigationProps>();
  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: StatusBar.currentHeight,
        backgroundColor: "#fff",
      }}
    >
      <div className="row mb-4">
        <div className="col-12 col-sm-10 mx-auto">
          <div className="pt-5 roitateDiv">
            <div className="d-flex justify-content-between align-items-center">
              <div
                role="button"
                onClick={() =>
                  navigation.navigate("HomeSCDueScreen", {
                    refresh: true,
                  })
                }
              >
                <img
                  src={require("../../assets/icons/Atras-01.png")}
                  alt=""
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
                <span className="fw-bold ms-2">Pannello di controllo</span>
              </div>
              <div>
                <img
                  src={require("../../assets/images/sergio-capone.jpg")}
                  alt=""
                  style={{
                    width: "150px",
                    height: "50px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SafeAreaView>
  );
};
