import React from "react";
import CSS from "csstype";
import { useUserPermission } from "../../../../hooks/useUserPermission";
import { IClientAtelier } from "../interfaces/ICrmAtelier";


interface Props {
  data: IClientAtelier[];
  confirmToDelete: any;
  emailModal: any;
  navigation: any;
}

export const TableBodyCrmAtelier = ({ data, confirmToDelete, emailModal, navigation }: Props) => {
  const { isAdmin, isMarketing } = useUserPermission()
  return (
    <>
      <tbody>
        {data.map(
          ({ idClient, name, email, phone }, index) => (
            <tr key={idClient} style={{ borderBottom: "1px dashed gray" }}>
              <td style={{ backgroundColor: 'white', textAlign: 'right' }}>
                <img
                  src={require("../../../../assets/icons/sticky-note.png")}
                  style={{ width: 20, height: 20, cursor: 'pointer' }}
                  alt="..."
                  className="icon"
                  onClick={() => navigation.navigate('AggiungiCrmAtelier', { clientList: idClient })}
                />
              </td>
              <td style={tableRow} className="align-self-center mr-4 px-4">
                {name}
              </td>

              <td style={tableRow} className="align-self-center mr-4 px-4">
                {email}
              </td>

              <td
                style={{ ...tableRow, fontSize: 12 }}
                className="align-self-center mr-4 px-4"
              >
                {phone}
              </td>

              <td className="d-flex justify-content-center align-items-center gap-2" style={{ backgroundColor: '#fff'}}>
                {isAdmin || isMarketing ? (
                  <div onClick={() => confirmToDelete(Number(idClient))}>
                    <img
                      style={{ width: 20, height: 20, marginTop: '-7px', cursor: 'pointer' }}
                      src={require("../../../../assets/icons/icon-delete.png")}
                      alt="Delete icon"
                    />
                  </div>
                ) : null}
                {email && email.length > 0
                  ?
                  <div onClick={() => emailModal(email)}>
                    <img
                      style={{ width: 20, height: 20, marginTop: '-7px', cursor: 'pointer', }}
                      src={require("../../../../assets/icons/mail.png")}
                      alt="Delete icon"
                    />
                  </div>
                  :
                  <div style={{
                    // flex: 1,
                    // padding: "5",
                    // alignItems: "center",
                    // justifyContent: "center",
                    width: 20,
                    height: 20
                  }}></div>
                }
              </td>
            </tr>
          )
        )}
      </tbody>
    </>
  );
};
export const tableRow: CSS.Properties = {
  textAlign: "center",
  fontWeight: "400",
  verticalAlign: "middle",
  backgroundColor: "#ffffff",
};
