import React from "react";
import { Text } from "react-native";
import { SearchBox } from "../SearchBox";

export const FilterStatische = () => {
  return (
    <div
      className="row mb-4"
      style={{
        paddingRight: 12,
        paddingLeft: 12,
      }}
    >
      <div className="col-12 col-sm-7 col-md-6 col-lg-9">
        <div className="mt-2" style={{ marginLeft: -13 }}>
          <Text
            style={{
              fontWeight: "500",
              fontSize: 20,
              alignSelf: "center",
            }}
          >
            Statistiche
          </Text>
        </div>
      </div>
      <div className="col-12 col-sm-5 col-md-6 col-lg-3">
      </div>
    </div>
  );
};
