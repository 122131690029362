import { Text } from "react-native";
import { colorsBussines, colorsBussinesRepairs, tableRow } from "../../GeneralStyles/Statistiche";
import { FilterWhit } from "./FilterWhit";
import { Collapse } from "react-collapse";
import { BussinesVenditeProdotti, SearchByCity } from "./BussinesVenditeProdotti";
import Pagination from "../Pagination/pagination";
import { CittaStatische } from "./CittaStatische";
import { TableVenditePerCittaHeader } from "./TableVenditePerCittaHeader";
import TitleStatische from "./TitleStatische";
import { MeseAnno } from "./MeseAnno";
import { FilterMeseAnno } from "./FilterMeseAnno";
import { TableRepairStatisticsHeader } from "./TableRepairStatisticsHeader";
import { NotResultsRepairs } from "./NotResultsRepairs";
import ReactSvgPieChart from "react-svg-piechart";
import { aleatorio } from "../../helpers/sort";
import { useEffect, useState } from "react";
import { LabelStatisticheRepairs } from "../magazzino/LabelStatisticheRepairs";
import { BrandStatische } from "./BrandStatische";
import { BrandOptions } from "./BrandOptions";
interface Props {
    showVC,
    showBRS,
    bussines,
    paginationRipairs,
    handleShowMeseAnnoRepairs,
    showDateRepairs,
    statisticDateRepairs,
    handleChangeRepairs,
    repairStatistics: any;
    setPaginaActualRepairs:any;
    handleChangeDateRepairStatistics,
    paginaActualRepairs,
    brand,
    handleShowBrandRepairStatistics,
    handleCheckBrandRepairStatistics,
    handleChangeCheckBrandRepairStatistics
}
export const RepairStatistics = ({
    brand,
    showVC,
    showBRS,
    bussines,
    handleShowMeseAnnoRepairs,
    showDateRepairs,
    statisticDateRepairs,
    handleChangeRepairs,
    paginationRipairs,
    repairStatistics,
    setPaginaActualRepairs,
    handleChangeDateRepairStatistics,
    handleShowBrandRepairStatistics,
    handleCheckBrandRepairStatistics,
    handleChangeCheckBrandRepairStatistics
}: Props) => {

    const [brandFilter, setBrandFilter] = useState([])

   const valueAppearances: number[] = [];
    useEffect(()=>{
        
        for (let index = 0; index < 1000; index++) {
            const number = aleatorio(0, colorsBussinesRepairs.length - 1);
            if (!valueAppearances.includes(number)) {
             valueAppearances.push(number);

            }
            if (valueAppearances.length === colorsBussinesRepairs.length) {
              break;
            }
          }

        if(brand) {
            let data = brand.filter( x => x.value == '1' || x.value == '2' || x.value == '3' || x.value == '4' )
            data.push({label: 'Altri', value: '999'})
            setBrandFilter(data)
        }
    },[])
    return (
        <>
        <TitleStatische title={"Statistiche Riparazioni"}/>
            <div className="row">
                <div className="col-12 col-sm-3">
                    <FilterWhit />
                    <div className="row">
                     <MeseAnno handleShow={handleShowMeseAnnoRepairs} />
                        <Collapse isOpened={showDateRepairs}>
                        <FilterMeseAnno
                            handleSearch={handleChangeDateRepairStatistics}
                            value={statisticDateRepairs}
                        />
                        </Collapse> 

                        <BrandStatische handleShow={handleShowBrandRepairStatistics} />
                        <Collapse isOpened={showBRS}>
                            <BrandOptions
                                brand={brandFilter}
                                handleChangeCheck={handleChangeCheckBrandRepairStatistics}
                                handleCheck={handleCheckBrandRepairStatistics}
                            />
                        </Collapse>

                    </div>

                </div>
                <div className="col-12 col-sm-9">
                    <div className="table-responsive">
                        
                                {repairStatistics.length > 0 ? (
 
                                    <table className="table table-borderless mt-3">
                                            <TableRepairStatisticsHeader />
                                            <tbody>
                                        {repairStatistics.map(
                                            ({desc_bussines, percentage,statisticsAzienda,statisticsConcluse,statisticsRifutato,statisticsGaranzia}, index) => (
                                                <tr key={index} style={{ borderBottom: "1px solid gray" }}>
                                                    <td
                                                        style={tableRow}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {desc_bussines}
                                                    </td>
                                                    <td
                                                        style={{ ...tableRow, fontSize: 12 }}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {percentage}
                                                    </td>
                                                    <td
                                                        style={{ ...tableRow, fontSize: 12 }}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {statisticsConcluse}
                                                    </td>
                                                    <td
                                                        style={{ ...tableRow, fontSize: 12 }}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {statisticsRifutato}
                                                    </td>
                                                    <td
                                                        style={{ ...tableRow, fontSize: 12 }}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {statisticsAzienda}
                                                    </td>
                                                    <td
                                                        style={{ ...tableRow, fontSize: 12 }}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {statisticsGaranzia}
                                                    </td>
                                                </tr>
                                            )
                                        )}  
                                        </tbody>
                                        </table>)
                                 :<NotResultsRepairs />  }
                          
                        {(repairStatistics.length > 0) && (
                            <Pagination
                                className="pagination-bar"
                                currentPage={paginationRipairs.currentPage}
                                totalCount={paginationRipairs.totalPages}
                                pageSize={1}
                                onPageChange={(page) => setPaginaActualRepairs(page)}
                            />)}
                    </div>
                </div>
                <div className="col-12 col-md-12" style={{ marginTop: 32 }}>
                        <div className="row justify-content-center align-items-center">
                        {repairStatistics.map(({ garanziaPercentage,rifutatoPercentage,color, name, value, totalSale,desc_bussines,repairspercentage,aziendaPercentage,conclusePercentage},index) => {
                             const PReparazioni =(100-(Number(rifutatoPercentage)+Number(garanziaPercentage)+Number(conclusePercentage)+Number(aziendaPercentage)))
                             const resultReparzion = PReparazioni.toFixed(2)
                            const data = [
                                {title: "Riparazioni", value:Number(resultReparzion)*100, color: colorsBussinesRepairs[0]},
                                {title: "Conclusi", value: conclusePercentage*100, color: colorsBussinesRepairs[4]},
                                {title: "Prev. rifiutati", value: rifutatoPercentage*100, color: colorsBussinesRepairs[1]},
                                {title: "In. azienda", value: aziendaPercentage*100, color: colorsBussinesRepairs[2]},
                                {title: "In garanzia", value: garanziaPercentage*100, color: colorsBussinesRepairs[3]},] 
                            return (
                                <div className="col-12 col-md-6 col-lg-4 mb-5">
                                <div className="row mb-5">
                                    <div className="col-4 col-md-6 col-lg-6 col-xs-4 text-center">
                                        <ReactSvgPieChart
                                            data={data}
                                            expandOnHover
                                            angleMargin={0}
                                            strokeWidth={4}
                                            />
                                            <Text
                                                style={{
                                                    fontWeight: "500",
                                                    fontSize: 18,
                                                    alignSelf: "center",
                                                    marginLeft: -10,
                                                }}
                                            >
                                                {desc_bussines}
                                            </Text>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-6 col-xs-8">
                                        <div className="row">
                                            <div className="col-6">
                                            <LabelStatisticheRepairs
                                            name={"Riparazioni"}
                                            color={colorsBussinesRepairs[0]}
                                            value={
                                            value !== 0
                                                ? Number(resultReparzion)
                                                : 0
                                            }
                                            key={name}
                                        />
                                        <LabelStatisticheRepairs
                                            name={"Prev. rifiutati"}
                                            color={colorsBussinesRepairs[1]}
                                            value={
                                            value !== 0
                                                ? rifutatoPercentage
                                                : 0
                                            }
                                            key={name}
                                        />
                                            </div>
                                            <div className="col-6">
                                            <LabelStatisticheRepairs
                                            name={"Conclusi"}
                                            color={colorsBussinesRepairs[4]}
                                            value={
                                            value !== 0
                                                ? conclusePercentage
                                                : 0
                                            }
                                            key={name}
                                        />
                                          
                                          <LabelStatisticheRepairs
                                            name={"In. azienda"}
                                            color={colorsBussinesRepairs[2]}
                                            value={
                                            value !== 0
                                                ? aziendaPercentage
                                                : 0
                                            }
                                            key={name}
                                        />
                                            </div>
                                        </div>
                                          <LabelStatisticheRepairs
                                            name={"In garanzia"}
                                            color={colorsBussinesRepairs[3]}
                                            value={
                                            value !== 0
                                                ? garanziaPercentage
                                                : 0
                                            }
                                            key={name}
                                        />
                                
                                
                                    </div>
                                </div>
                                </div>                     
                            );
                        })}
                        </div>
                    </div>
            </div>
        </>
    );
};
