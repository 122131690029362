import { Text } from "react-native";
import { MESES_COMPLETED } from "../../GeneralStyles/Statistiche";
import { SalesOfMonth as sales } from "../../interfaces/statistiche";
import { ChartStatistiche } from "./ChartStatistiche";
import { FilterMeseAnno } from "./FilterMeseAnno";
import { MeseAnno } from "./MeseAnno";
import { SalesOfMonth } from "./SalesOfMonth";
import { Collapse } from "react-collapse";
import { CharPieFormat, } from "../../services/interfaces/GetBussinesCount";
interface Props {
  items: sales[];
  date: any;
  countBussines:  CharPieFormat[]
  handleShowMeseAnnoGraphic,
  showDateGraphic,
  dateGraphicFilter:any,
  statisticDate,
  handleChangeDateGraphicFilter,
  handleChangeStatistic
}
export const Statische = ({ items, date, handleShowMeseAnnoGraphic, statisticDate, showDateGraphic, handleChangeStatistic, dateGraphicFilter, handleChangeDateGraphicFilter,countBussines }: Props) => {
  const isExist = items.filter((elem) => elem.value !== 0);
  const handleMonth = () => {

    if (date?.length > 0) {
      const array = date[0].split("/");
      return MESES_COMPLETED[Number(array[1]) - 1];
    }
    const now = new Date()
    return MESES_COMPLETED[now.getMonth()]
  };
  return (
    <div className="row" style={{ marginLeft: 0 }}>
      <div className="col-12 mt-5 mb-5 text-center">
        <Text
          style={{
            fontWeight: "500",
            fontSize: 18,
            alignSelf: "center",
            marginLeft: -10,
          }}
        >
          Vendite mese di {handleMonth()}
        </Text>
      </div>
      <div className="row">
        <div className="col-12 col-sm-8 col-md-6 col-lg-3 mb-4">
          <MeseAnno handleShow={handleShowMeseAnnoGraphic} />
          <Collapse isOpened={showDateGraphic}>
          <FilterMeseAnno
            handleSearch={handleChangeStatistic}
            value={statisticDate}
          />
          </Collapse>
        </div>
      </div>


      <SalesOfMonth sales={countBussines} />
      {countBussines.length > 0 && <ChartStatistiche sales={countBussines} />}
    </div>
  );
};
