import {NavigationContainer} from '@react-navigation/native';
import {useMemo, useState} from 'react';
import {UserContext} from './src/context/UserContext';
import {NavigatorConfig} from './src/navigator/NavigatorConfig';
const App = () => {
  const [userInLogin, setUserInLogin] = useState(null);
  const providerValue = useMemo(
    () => ({userInLogin, setUserInLogin}),[userInLogin, setUserInLogin],
  );
  return (
    <UserContext.Provider value={providerValue}>
      <NavigationContainer>
        <NavigatorConfig/>
      </NavigationContainer>
    </UserContext.Provider>
  );
};

export default App