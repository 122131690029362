import Modal from "react-modal";
import "../../GeneralStyles/styleModal.css";
interface Props {
  isOpen: boolean;
  children: any;
  title: string;
  size?: string;
  closeModal: () => void;
  navigation?: any
}
Modal.setAppElement("#root");

export const ModalCustom = ({
  isOpen,
  children,
  title,
  size,
  closeModal,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      //closeTimeoutMS={200}
      closeTimeoutMS={0}
      overlayTransition={0}
      className="modal fade show d-block overflow-auto"
      id="modal-root"
    >
      <div
        className={`modal-dialog modal-dialog-centered ${size} `}
        
        role="document"
      >
        <div className="modal-content" style={{'borderRadius': '20px'}}>
          <div className="modal-header " style={{'border': 'none'}}>
            <h5 className="modal-title w-100 text-center">{title}</h5>
            <button
              onClick={() => closeModal()}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">X</span>
            </button>
          </div>
          <div className="modal-body ">
            <div className="card-body">{children}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const ModalCalendarDay = ({
  isOpen,
  children,
  title,
  size,
  closeModal,
  navigation
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={100}
      overlayTransition={0}
      className="modal fade show d-block overflow-auto"
      id="modal-root"
    >
      <div
        className={`modal-dialog modal-dialog-centered ${size} `}
        
        role="document"
      >
        <div className="modal-content" style={{'borderRadius': '20px'}}>
          <div className="modal-header " style={{'border': 'none'}}>
            <h5 className="modal-title w-100 text-center">{title}</h5>
            <button
              onClick={() => closeModal()}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">X</span>
            </button>
          </div>
          <div className="modal-body ">
            <div className="card-body">{children}</div>
          </div>
          <div className="modal-footer ">
          <button 
            className="btn btn-dark rounded-pill"
            onClick={() => navigation()}
          > Apri
          </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const ModalAplicationClose = ({
  children,
  closeModal,
  isOpen,
  title,
  size,
}: Props) => {
  return (
    <ModalCustom closeModal={closeModal} isOpen={isOpen} title={title}>
      {children}
      <div className="modal-footer">
        <button
          onClick={() => closeModal()}
          type="button"
          className="btn btn-dark mb-2 mt-2"
        >
          chiudere
        </button>
      </div>
    </ModalCustom>
  );
};
export const ModalAplicationBtn = ({
  children,
  closeModal,
  isOpen,
  title,
  size,
}: Props) => {
  return (
    <ModalCustom
      closeModal={closeModal}
      isOpen={isOpen}
      title={title}
      size={size}
    >
      {children}
      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-success mb-2 mt-2"
        >
          Registrare
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="btn btn-dark mb-2 mt-2"
        >
          Chiudere
        </button>
      </div>
    </ModalCustom>
  );
};
