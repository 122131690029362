import React from "react";


const CheckEmaildiRingraziamento =({optionAddEdit,checkBoxEmailRingraziamento,changeEmailRingraziamento,id})=>{
    return(
        <div className="col-12 text-left">
            <label style={{ fontSize: 18, marginBottom: 5, fontFamily: "Arsapia Regular font" }} >
                <input
                    type="checkbox"
                    style={{
                        margin: 10,
                        marginLeft: 10,
                        marginRight: 10,
                        fontSize: 100
                    }}
                    //disabled={optionAddEdit ? false : true}
                    checked={checkBoxEmailRingraziamento[`Ringraziamento-${id}`]}
                    onChange={(e) => { changeEmailRingraziamento(`Ringraziamento-${id}`) }}
                    name={`Ringraziamento-${id}`}
                />
                Invia e-mail di ringraziamento
            </label>
        </div>
    )
}

export default CheckEmaildiRingraziamento;