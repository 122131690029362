import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { AlertCustomProps } from "../components/AlertCustom";
import { AddEvent } from "../interfaces/calender";
import { registerEvent } from "../services/calender";
import { useAuth } from "./useAuth";
import axios from "axios";
import ConfigCapone from "../utils/ConfigCapone";
import moment from "moment";

const initialState: AddEvent = {
  codigo: "",
  date: "",
  start: "",
  end: "",
  title: "",
  bussines: "",
  details: "",
  idUser: undefined,
  fileName: '',
  imageName: ''
};
interface Props {
  handleAddEvent: (event: AddEvent) => void;
  option: string;
  closeModal: () => void;
}
type Response = Omit<AlertCustomProps, "handleClear">;
export const useAddEvent = ({ handleAddEvent, option, closeModal }: Props) => {
  const [response, setResponse] = useState<Response | undefined>(undefined);
  const [nameImgSelected, setNameImgSelected] = useState<string>();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [typeFile, setTypeFile] = useState<any>();
  const [typeImage, setTypeImage] = useState<any>()
  const { id, permitedFilter } = useAuth();
  const [loading, setLoading] = useState(false);
  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);
  const addEventSchema = Yup.object().shape({
    start: Yup.string().required("Campo obbligatorio"),
    end: Yup.string().required("Campo obbligatorio"),
    date: Yup.string().required("Campo obbligatorio"),
    title: Yup.string().required("Campo obbligatorio"),
    bussines: Yup.string().required("Campo obbligatorio"),
  });

  const uploadFiles = (e: any, type: string) => {
    

    const formatDate = "YYYY_MM_DD_HH_mm_ss";
    let fileDate: any = new Date();
    const currentDate = moment(fileDate).format(formatDate);

    const extension: string = e[0].name?.substr(
      e[0].name?.lastIndexOf(".") + 1
    );

    const name: string = e[0].name?.split(".")[0]
    


    if (type == "image") {
      let valueImageCalendar = e ? name + '_' + currentDate + '.' + extension : null;
      formik.setFieldValue("selectedImage", valueImageCalendar);
      setSelectedImage(e ? name + '_' + currentDate + '.' + extension : null);
      setTypeImage(type)
      
    }

    if (type == "document") {
      let valueFileCalendar = e ? name + '_' + currentDate + '.' + extension : null;
      formik.setFieldValue("selectedFile", valueFileCalendar);
      setSelectedFile(e ? name + '_' + currentDate + '.' + extension : null);
      setTypeFile(type);
      
    }
    
    

    if (type == "image") {
      let image: any = document.querySelector("#image-files");
      let imageContainer: any = document.getElementById("img-container");
      imageContainer.classList.remove("d-none");
      imageContainer.src = URL.createObjectURL(image.files[0]);
    } else if (type == "document") {
      let imageContainer: any = document.getElementById("img-container");
      imageContainer.classList.add("d-none");
    }

  };


  const saveFile = (nameFile, type) => {
    
    var formData = new FormData();

    var documentFile: any = document.querySelector("#document-files");
    var imageFile: any = document.querySelector("#image-files");
    
    if (type == "document") {
      
      var originalFileName: string = nameFile;

      const extension: string = originalFileName?.substr(
        originalFileName?.lastIndexOf(".") + 1
      );

      const name: string = originalFileName?.split(".")[0]
      const fileName: string = name + "." + extension;

      let myNewFile: any = null;

      if (extension.length > 0) {
        if (typeFile == "document") {
          
          myNewFile = new File([documentFile.files[0]], fileName, {
            type: documentFile.files[0].type,
          });
  
          formData.append("files", myNewFile);
          formData.append("type", typeFile);
          formData.append("name", fileName);
          formData.append("extension", extension);
  
          axios
            .post(`${ConfigCapone.apicapone}/upload/calendar`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
  
        }
      
    }


  }


    if (type == "image") {
      
      var originalImageName: string = nameFile;

      const extension: string = originalImageName?.substr(
        originalImageName?.lastIndexOf(".") + 1
      );

      const name: string = originalImageName?.split(".")[0]
      const imageName: string = name + "." + extension;
      
      let myNewImage: any = null;

      if (extension.length > 0) {
        
        
        if (typeImage == "image") {
          
          myNewImage = new File([imageFile.files[0]], imageName, {
            type: imageFile.files[0].type,
          });
  
          formData.append("files", myNewImage);
          formData.append("type", typeImage);
          formData.append("name", imageName);
          formData.append("extension", extension);
  
          axios
            .post(`${ConfigCapone.apicapone}/upload/calendar`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            
        }
      }
    }
    
  };

  const removeFile = () => {
    var documentFile: any = document.getElementById("document-files");
    var imageFile: any = document.getElementById("image-files");
    documentFile.value = null;
    imageFile.value = null;
    setSelectedFile(null)
    setNameImgSelected("");
    setSelectedImage(null)
  }

  const formik = useFormik({
    initialValues: {
      ...initialState,
      bussines: permitedFilter ? "" : option,
      selectedFile: null,
      selectedImage: null,
    },
    validationSchema: addEventSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      enableLoading();
      setTimeout(async () => {
        try {
           if (selectedFile) {
            await saveFile(selectedFile, typeFile);
            values.fileName = selectedFile;
          }

          if (selectedImage) {
            await saveFile(selectedImage, typeImage);
            values.imageName = selectedImage;
          }

          const newEvent = {
            ...values,
            idUser: id,
            idBussines: values.bussines,
            bussines: undefined,
            codigo: undefined,
            start: `${values.date} ${values.start}`,
            end: `${values.date} ${values.end}`
          };
          const resp = await registerEvent(newEvent);
          handleAddEvent({
            ...values,
            idUser: Number(id),
            codigo: resp.id,
            start: `${values.date}T${values.start}`,
            end: `${values.date}T${values.end}`,
          });
          setResponse({
            title: "Registrato",
            detail: "Evento registrato correttamente",
            status: "success",
          });

          resetForm();
          closeModal();
        } catch (error) {
          console.log(error);
          setResponse({
            title: "Errore",
            detail: "si è verificato un errore durante la registrazione",
            status: "danger",
          });
        } finally {
          disableLoading();
          setSubmitting(false);
        }
      }, 500);
    },
  });
  const handleClear = () => {
    setResponse(undefined);
  };
  useEffect(() => {
    if (formik.values.start && formik.values.start !== "") {
      let endEvent = new Date(`${formik.values.date} ${formik.values.start}`);
      const sumarsesion = 30;
      const minutes = endEvent.getMinutes();
      endEvent.setMinutes(minutes + sumarsesion);
      formik.setFieldValue(
        "end",
        endEvent.getHours().toString().padStart(2, "0") +
          ":" +
          endEvent.getMinutes().toString().padStart(2, "0")
      );
    }
  }, [formik.values.start]);

  return { formik, loading, response, handleClear, isAdmin: permitedFilter, uploadFiles, removeFile, nameImgSelected, selectedFile, selectedImage };
};