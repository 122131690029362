import { initialState } from '.'
import { FormRepairAction, FormRepairState } from '../../interfaces/formRepair'

export const formRepairReducer = (
  state: FormRepairState = initialState,
  action: FormRepairAction
): FormRepairState => {
  switch (action.type) {
    case '[FORMREPAIR] step': {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return state
    }
  }
}
