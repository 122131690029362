import { ActionsStep } from './ActionsStep'

interface Props {
  children: any;
}

export const WrapperSteps = ({ children }: Props) => {
  return (
    <div className="wrapper-steps">
      <div className="wrapper-steps-info">
        <div className="row">
          <div className="col-md-11 mx-auto">
            <div className="row">{children}</div>
          </div>
        </div>
        <ActionsStep />
      </div>
    </div>
  )
}
