import aplicationConnect from "../../../api/applicationConnect";
import { ResponseBackendBrand } from "../../../interfaces/magazzino";

export const referenciaSearch= async (search:string, limit:number = 10) => {
    try {
      const results = await aplicationConnect.get<any>(
        `/ClientProdposseduti/FindAtelierByReferencia/${search}/${limit}`
      );
      const { response, status } = results.data;
      if (status === "success") return response;
      return [];
    } catch (error) { }
  };