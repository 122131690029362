import { useContext, useEffect, useState } from "react";
import {
  bussinesList,
  bussinesTaskByIdUser,
  deleteTaskData,
  bussinesListByIdTask,
  listByidTaskBussines,
  postTask,
  postTaskBussinesService,
  postTaskBussinesData,
  postTaskUserService,
  putTaskBussinesIsChecked,
  putTaskUserIsChecked,
  userListAll,
  userTaskByIdUser,
  userListByIdTask,
  putTasksData,
  deleteTasksBussinesId,
  deleteTasksDataBussines,
  deleteTasksDataUser,
  usersTasksSelected,
  deleteTasksIdUser,
  listByidTaskChecked,
  getPdf,
  getIdRoleData
} from "../services/TasksServices";
import { UserContext } from "../../../context/UserContext";
import { userListRoleAdmin } from "../../News/services/NewsServices";

const useTasks = () => {
  const [BussinesList, setBussinesList] = useState();
  const [UserList, setUserList] = useState();
  const [defaultUserList, setDefaultUserList] = useState<any>([]);
  const [defaultUserTecnicoList, setDefaultUserTecnicoList] = useState<any>([]);
  const [UserTecnicoList, setUserTecnicoList] = useState();
  const [defaultBussinesList, setDefaultBussinesList] = useState<any>([]);
  const { userInLogin } = useContext(UserContext);
  const [listIdTaskBussinesView, setListIdTaskBussineView] = useState();
  const [ListTaskBussinesById, setListTaskBussinesById] = useState({
    items: [],
    normalizedResults: [],
    length,
  });

  const [stateOptionNew, setStateOptionNew] = useState<any>(null);



  const ListBussines = async () => {
    const result = await bussinesList();
    if (result) {
      let BussinesCustom = result.map(function (option) {
        return { label: option.desc_Bussines, value: option.idBussines };
      });

      BussinesCustom.push({ label: 'Tutti', value: '7' })
      setBussinesList(BussinesCustom);

    }
  };

  const GetListTaskBussinesById = async () => {
    const idUser = Number(userInLogin.idUser);
    // 
    const result = await bussinesTaskByIdUser(idUser);
    const resultUser = await GetListTaskUserById();
    let resultConcat = result;
    console.log('result data 1', result)
    console.log('result data 2', resultUser)
    // 
    if (resultUser) {
      resultConcat = result.concat(resultUser);
    }
    if (resultConcat) {
      resultConcat.sort(function (a, b) {
        return (
          new Date(b.dataInsert).getTime() - new Date(a.dataInsert).getTime()
        );
      });
    }

    if (result) {
      const items = resultConcat.slice(0, 3);
      const normalizedResults = resultConcat;
      const normalizedResultLength = resultConcat.filter(
        (x) => x.isChecked == 0
      );
      const dataRender = {
        items,
        normalizedResults,
        length: normalizedResultLength.length,
      };

      setListTaskBussinesById(dataRender);
      console.log("dataRender", dataRender);
    }
  };

  const defaultListTask = async (id) => {
    await ListUsersByIdTask(id);
    await ListBussinesByIdTask(id);
  };

  const ListBussinesByIdTask = async (id) => {
    const result = await bussinesListByIdTask(id);


    if (result) {
      let BussinesCustom = result.map(function (option) {
        return { label: option.desc_Bussines, value: option.idBussines };
      });
      if (BussinesCustom?.length > 0) {
        setStateOptionNew("option1");
      }


      setDefaultBussinesList(BussinesCustom);
      setDefaultUserList([]);
      setDefaultUserTecnicoList([]);
    }
  };

  const ListUsersByIdTask = async (id) => {
    const result = await userListByIdTask(id);


    if (result) {
      let filterUser = result.filter((x) => Number(x.idRole) === 2 || Number(x.idRole) === 4 || Number(x.idRole) === 5 || Number(x.idRole) === 6);

      let filterTecnico = result.filter((x) => Number(x.idRole) === 3 || Number(x.idRole) === 7 || Number(x.idRole) === 8);

      let filterUser2 = filterUser.filter(x => Number(x.isSelected) === 1)

      let UserCustom = filterUser2.map(function (option) {
        return {
          label: `${option.desc_Role}-${option.userName}`,
          value: option.idUser,
        };
      });
      let UserTencnicoCustom = filterTecnico.map(function (option) {
        return { label: option.userName, value: option.idUser };
      });



      if (UserCustom?.length > 0 && UserTencnicoCustom?.length == 0) {
        setStateOptionNew("option2");
        setDefaultUserList(UserCustom);
        setDefaultBussinesList([]);
        setDefaultUserTecnicoList([]);
      }

      if (UserTencnicoCustom?.length > 0) {
        setStateOptionNew("option3");
        setDefaultUserTecnicoList(UserTencnicoCustom);
        setDefaultBussinesList([]);
        setDefaultUserList([]);
      }
    }
  };

  //ListTaskUser
  const GetListTaskUserById = async () => {
    const idUser = Number(userInLogin.idUser);

    const result = await userTaskByIdUser(idUser);
    if (result) {
      return result;
    }
  };
  //postTaskUser
  const postTaskUser = async (id: Number, List: any) => {

    if (id != 0 && List != "") {

      //admins
      const data = await userListRoleAdmin();

      let list = data.filter(item2 => !List.some(item1 => item1.value === item2.idUser));



      for (let index = 0; index < list.length; index++) {
        const dataCustom = {
          idTask: id,
          idUser: list[index].idUser,
          description: "Dependente",
          isSelected: 0
        }

        await postTaskUserService(dataCustom)

      }


      for (let index = 0; index < List.length; index++) {
        const dataCustom = {
          idTask: id,
          idUser: List[index].value,
          description: "Dependente",
          isSelected: 1
        }
        const result = await postTaskUserService(dataCustom)

      }

    }
    await GetListTaskBussinesById()

    await GetListTaskBussinesById();





  };







  const PostTaskBussines = async (id: Number, List: any) => {

    if (id != 0 && List != "") {

      for (let index = 0; index < List.length; index++) {
        const dataCustom = {
          idTask: id,
          idBussines: List[index].value,

        }
        const result = await postTaskBussinesData(dataCustom)

      }

    }
    await GetListTaskBussinesById()

  };

  const postTaskUserTecnici = async (id: Number, List: any) => {

    if (id != 0 && List != "") {

      //admins
      const data = await userListRoleAdmin();



      for (let index = 0; index < data.length; index++) {
        const dataCustom = {
          idTask: id,
          idUser: data[index].idUser,
          description: "Tecnici",
          isSelected: 0
        }

        await postTaskBussinesService(dataCustom)

      }


      for (let index = 0; index < List.length; index++) {
        const dataCustom = {
          idTask: id,
          idUser: List[index].value,
          description: "Tecnici",
          isSelected: 1
        }
        const result = await postTaskBussinesService(dataCustom)

      }

    }
    await GetListTaskBussinesById()
    defaultListTasks(id);
    await GetListTaskBussinesById();
  };






  //postTask
  const PostTask = async (dataSend: any) => {
    const dataCustom = {
      title: dataSend.Titolo,
      text: dataSend.Testo,
      nameFile: dataSend.nameFile,
      nameImage: dataSend.nameImage,
      date: new Date(),
    };
    const result = await postTask(dataCustom);

    if (Number(result) > 0) {
      if (dataSend.Store != "") {
        await PostTaskBussines(result, dataSend.Store);
      }
      if (dataSend.Dipendenti != "") {
        if (Number(userInLogin.idRole) == 5 || Number(userInLogin.idRole) == 6) {
          dataSend.Dipendenti.push({
            label: `${userInLogin.desc_role}-${userInLogin.name}`,
            value: `${userInLogin.idUser}`
          })
        }
        await postTaskUser(result, dataSend.Dipendenti);
      }

      if (dataSend.Tecnici != "") {
        if (Number(userInLogin.idRole) == 5 || Number(userInLogin.idRole) == 6) {
          dataSend.Tecnici.push({
            label: `${userInLogin.desc_role}-${userInLogin.name}`,
            value: `${userInLogin.idUser}`
          })
        }
        await postTaskUserTecnici(result, dataSend.Tecnici);
      }


      return result;
    }
  };
  //putTask
  const PutTasks = async (dataSend: any) => {


    let optionSelected = stateOptionNew;

    if (optionSelected == "option1" && dataSend.Store == "") {

      console.log("console1");
      const deteBusines = await deleteTasksDataBussines(dataSend.id);

    }

    if (optionSelected == "option2" && dataSend.Dipendenti == "") {

      const deleteUser = await deleteTasksDataUser(dataSend.id);

      console.log("consol2");
    }

    if (optionSelected == "option3" && dataSend.Tecnici == "") {

      const deleteTecnici = await deleteTasksDataUser(dataSend.id);

      console.log("console3");
    }

    const dataCustom = {
      title: dataSend.Titolo,
      text: dataSend.Testo,
      nameFile: dataSend.nameFile ? dataSend.nameFile : "",
      nameImage: dataSend.nameImage ? dataSend.nameImage : "",
      idTask: dataSend.id,
    };

    const result = await putTasksData(dataCustom);

    //const resultUsers = await userListByIdTask(dataSend.id);
    if (result == "OK") {
      if (dataSend.Store != "") {

        const storedSelected = await bussinesListByIdTask(dataSend.id);

        await PutTaskBussines(dataSend.id, dataSend.Store, storedSelected);

      }
      if (dataSend.Dipendenti != "") {


        const usersSelected = await usersTasksSelected(dataSend.id);
        debugger
        await putTaskUserDependente(dataSend.id, dataSend.Dipendenti, usersSelected);

      }
      if (dataSend.Tecnici != "") {

        debugger
        const tecniciSelected = await usersTasksSelected(dataSend.id);

        await putTaskUserTecnici(dataSend.id, dataSend.Tecnici, tecniciSelected);
      }

      return result;
    }
  };
  const defaultListTasks = async (id) => {
    await ListUsersByIdTask(id);
    await ListBussinesByIdTask(id);
  };

  const PutTaskBussines = async (id: Number, List: any, result3: any) => {


    if (result3) {

      let listDifent = List.filter(item2 => !result3.some(item1 => item1.idBussines === item2.value));

      let listDifent2 = result3.filter((item2) => !List.some((item1) => item1.value === item2.idBussines));



      if (id != 0 && listDifent.length > 0) {
        //;
        for (let index = 0; index < listDifent.length; index++) {
          const dataCustom = {
            idTask: id,
            idBussines: listDifent[index].value,
          };
          const result = await postTaskBussinesData(dataCustom);
        }
      }

      if (id != 0 && listDifent2.length > 0) {
        //;
        for (let index = 0; index < listDifent2.length; index++) {
          const dataCustom = {
            idTask: id,
            idBussines: listDifent2[index].idBussines,
          };
          const result = await deleteTasksBussinesId(
            dataCustom.idTask,
            dataCustom.idBussines
          );
          // ;
        }
      }

    }



    await GetListTaskBussinesById();
  };


  const putTaskUserDependente = async (id: Number, List: any, usersSelected: any) => {
    console.log(usersSelected);

    if (usersSelected) {
      if (Number(userInLogin.idRole) == 5 || Number(userInLogin.idRole) == 6) {
        List.push({
          label: `${userInLogin.desc_role}-${userInLogin.name}`,
          value: `${userInLogin.idUser}`
        })
      }
      let listDifent = List.filter((item2) => !usersSelected.some((item1) => item1.idUser === item2.value));

      let listDifent2 = usersSelected.filter((item2) => !List.some((item1) => item1.value === item2.idUser));



      if (id != 0 && listDifent != "") {
        for (let index = 0; index < listDifent.length; index++) {
          const dataCustom = {
            idTask: id,
            idUser: listDifent[index].value,
            isSelected: 1,
          };

          await postTaskUserService(dataCustom);
        }
      }

      if (id != 0 && listDifent2 != "") {
        for (let index = 0; index < listDifent2.length; index++) {
          const dataCustom = {
            idTask: id,
            idUser: listDifent2[index].idUser,

          };
          const result = await deleteTasksIdUser(
            dataCustom.idTask,
            dataCustom.idUser
          );
        }
      }
      defaultListTasks(id);
    } else {
      postTaskUser(id, List);
    }
    await GetListTaskBussinesById();
  };

  const putTaskUserTecnici = async (id: Number, List: any, usersSelected: any) => {

    if (usersSelected) {
      if (Number(userInLogin.idRole) == 5 || Number(userInLogin.idRole) == 6) {
        List.push({
          label: `${userInLogin.desc_role}-${userInLogin.name}`,
          value: `${userInLogin.idUser}`
        })
      }

      let listDifent = List.filter((item2) => !usersSelected.some((item1) => item1.idUser === item2.value));

      let listDifent2 = usersSelected.filter((item2) => !List.some((item1) => item1.value === item2.idUser));



      if (id != 0 && listDifent != "") {
        for (let index = 0; index < listDifent.length; index++) {
          const dataCustom = {
            idTask: id,
            idUser: listDifent[index].value,
            isSelected: 1,
          };

          await postTaskUserService(dataCustom);
        }
      }

      if (id != 0 && listDifent2 != "") {
        //

        for (let index = 0; index < listDifent2.length; index++) {
          const dataCustom = {
            idTask: id,
            idUser: listDifent2[index].idUser,
            isSelected: 0,
          };
          const result = await deleteTasksIdUser(
            dataCustom.idTask,
            dataCustom.idUser
          );
        }
      }
    } else {
      postTaskUserTecnici(id, List);
    }
    await GetListTaskBussinesById();
  };



  const DeleteTask = async (id) => {
    if (id) {
      const result = await deleteTaskData(id);
      return result;
    } else {
      console.log("No se encuentra id");
    }
  };


  const ListUserAll = async () => {
    const result = await userListAll();
    if (result) {
      let filterUser = result.filter(
        (x) =>
          Number(x.idRole) === 2 ||
          Number(x.idRole) === 4 ||
          Number(x.idRole) === 5 ||
          Number(x.idRole) === 6
      );
      let filterTecnico = result.filter(
        (x) =>
          Number(x.idRole) === 3 ||
          Number(x.idRole) === 7 ||
          Number(x.idRole) === 8
      );

      let UserCustom = filterUser.map(function (option) {
        return {
          label: `${option.desc_Role}-${option.userName}`,
          value: option.idUser,
        };
      });
      let UserTencnicoCustom = filterTecnico.map(function (option) {
        return { label: option.userName, value: option.idUser };
      });
      setUserList(UserCustom);
      setUserTecnicoList(UserTencnicoCustom);
    }
  };

  const confirmviewTask = async (data) => {


    if (data.idTaskBussinesUsers) {

      const result = await putTaskBussinesIsChecked(data.idTaskBussinesUsers);
      if (result) {

        await GetListTaskBussinesById();

      }
    }


    if (data.idTaskUser) {
      const result = await putTaskUserIsChecked(data.idTaskUser);

      if (result) {

        await GetListTaskBussinesById();

      }
    }

  };




  const GetListByIdTaskBussines = async (dataSend) => {



    if (dataSend.idTaskBussinesUsers) {
      const result = await listByidTaskBussines(dataSend.idTaskBussines);
      if (result) {

        setListIdTaskBussineView(result);
        console.log(listIdTaskBussinesView)
      }
    }

    if (dataSend.idTaskUser) {
      const result2 = await listByidTaskChecked(dataSend.idTask);
      if (result2) {
        setListIdTaskBussineView(result2);
        console.log(listIdTaskBussinesView)
      }
    }







  };




  const getPDFTask = async (name: string, ext: string) => {

    const result = await getPdf(name, ext)
    return result

  }

  const getIdRole = async () => {
    const idUser = Number(userInLogin.idUser)

    const result = await getIdRoleData(idUser)

    return result.idRole

  }






  useEffect(() => {
    ListBussines();
    ListUserAll();
    GetListTaskBussinesById();
  }, []);
  return {
    BussinesList,
    UserList,
    UserTecnicoList,
    PostTask,
    PutTasks,
    ListTaskBussinesById,
    GetListByIdTaskBussines,
    GetListTaskUserById,
    listIdTaskBussinesView,
    confirmviewTask,
    DeleteTask,
    GetListTaskBussinesById,
    defaultListTask,
    defaultBussinesList,
    defaultUserList,
    defaultUserTecnicoList,
    stateOptionNew,
    getPDFTask,
    getIdRole,
  };
};

export default useTasks;
