import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { headerNewRepairStyle as style } from "../theme/HeaderNewReapairStyle";

interface Props {
  hasRoundedIcon?: boolean;
  hasPadding?: boolean;
  title: string;
  onBack: () => void;
}

export const HeaderNewRepair = ({
  hasRoundedIcon = true,
  title,
  onBack,
  hasPadding = false,
}: Props) => {
  return (
    <div className="row py-5">
      <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex p-0">
        <View
          style={{
            ...style.leftButtonContainer,
            flex: 0.6,
            left: hasPadding ? 25 : 10,
            justifyContent: "center",
          }}
        >
          <TouchableOpacity onPress={() => onBack()}>
            <View>
              <Image
                style={style.leftButton}
                source={require("../assets/icons/Atras-01.png")}
              />
            </View>
          </TouchableOpacity>
        </View>
      </div>
      <div className="col-xl-7 col-lg-7 col-md-3 col-sm-5 col-4 m-auto">
        <View style={[style.container2, { marginLeft: "-12%" }]}>
          {hasRoundedIcon ? (
            <View
              style={{
                justifyContent: "center",
              }}
            >
              <View style={style.sectionIconContainer}>
                <Image
                  style={style.sectionIcon}
                  source={require("../assets/icons/nuovaRiparazioni.png")}
                />
              </View>
            </View>
          ) : (
            <View></View>
          )}
          <View style={style.sectionTextContainer}>
            <Text style={style.sectionText}>{title}</Text>
          </View>
        </View>
      </div>
      <div
        className="col-xl-3 col-lg-3 col-md-7 col-sm-5 col-6 m-auto d-flex justify-flex-end mr-0"
        style={{
          paddingRight: hasPadding ? 30 : 65,
          justifyContent: hasPadding ? "end" : "center",
        }}
      >
        <Image
          style={style.sectionLogo}
          source={require("../assets/images/sergio-capone.jpg")}
        />
      </div>
    </div>
  );
};
