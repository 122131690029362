import Modal from "react-modal";
//import "../../GeneralStyles/styleModal.css";
Modal.setAppElement("#root");
import "./css/grigliaStyle.css";

export const ModalMagazzinoGrigliaAtelier = ({
    setmodalOpen,
    modalOpen,
    uri,
    seturi,
    title,
}: any) => {
function handleImageError(event) {
    event.currentTarget.src = require("../../../../assets/icons/defaultImage2.png");
  }
    return (
        <Modal
            style={{
                content: {
                    background: "rgba(0,0,0,0)",
                    border: "none",
                    padding: 0,
                    margin:0,
                },
            }}
            isOpen={modalOpen}
            transparent={true}
            closeTimeoutMS={1000}
        >
            <div
                className="d-flex justify-content-center w-100 h-100 containerImg"
                onClick={() => {
                    setmodalOpen(false);
                }}
            >
                <img src={uri} alt="" className="imgModal" onError={handleImageError}/>
            </div>
            {/* <div
                className={` modal-dialog modal-dialog-centered modal-lg`}
                
                role="document"
            >
                <div className="modal-content" style={{borderRadius:'5%'}}>
                    <div className="modal-header">
                        <h5 className="modal-title text-uppercase text-center">{title}</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => { setmodalOpen(false) }}
                        >
                            <span aria-hidden="true" >X</span>
                        </button>
                    </div>
                    <div className="modal-body d-flex justify-content-center">
                        <img src={uri} alt="" width={'100%'}/>
                    </div>
                </div>
            </div> */}
        </Modal>
    );
};
