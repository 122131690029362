import aplicationConnect from "../api/applicationConnect";
import { SearchMagazzino } from '../components/MagazzinoRefactor/SearchMagazzino';
import {
  ResponseBackendBrand,
  ResponseBackendMagazzino,
  ResponseBackendNegozio,
  ResponseBackendDelete,
  ResponseBackendReferenzaAndMatricola,
  ResponseBackendClient,
  ResponseBackendEsportaExcel

} from "../interfaces/magazzino";

export const listAllMagazzino = async (page: number, limit = 50) => {
  try {
    const results = await aplicationConnect.get<ResponseBackendMagazzino>(
      "/Magazzino/SearchListMagazzinoFilter", {
        params: {
          page: page,
          limit: limit,
          brandFilter: [],
          bussinesFilter: [],
          quantityFilter: ['1'], // por defecto siempre 1
          referenza: '',
          prodotto: ''
        }
      }
    );
    const { response, status, total, currentPage, totalPages } = results.data;
    let newData = {
      response,
      status,
      total,
      currentPage,
      totalPages
    }
    if (status === "success") return newData;
    return [];
  } catch (error) {
    console.log(error);
  }
};
interface SearchMagazzino {
  filterBrand: number[];
  filterNegozio: number[];
  filterQuantita: number[];
  Referenza:string;
  Descrizione:string;
  Colore:string;
  Misura:string;
  Movimento:string;
  Prodotto:string;
  page?: number;
  limit?: number;
}
export const listAllMagazzinoWhitSearch = async ({
  filterBrand,
  filterNegozio,
  filterQuantita,
  Referenza,
  Descrizione,
  Colore,
  Misura,
  Movimento,
  Prodotto,
  limit,
  page,
}: SearchMagazzino) => {
  try {
    const results = await aplicationConnect.get<ResponseBackendMagazzino>(
      "/Magazzino/SearchListMagazzinoFilter",
      {
        params: {
          limit,
          page,
          brandFilter: filterBrand,
          bussinesFilter: filterNegozio,
          quantityFilter: filterQuantita,
          Referenza:Referenza,
          Descrizione:Descrizione,
          Colore:Colore,
          Misura:Misura,
          Movimento:Movimento,
          Prodotto:Prodotto
        },
      }
    );
    const { response, status, total, currentPage, totalPages } = results.data;
    let newData = {
      response,
      status,
      total,
      currentPage,
      totalPages
    }
    if (status === "success") return newData;
    return [];
  } catch (error) {
    console.log(error);
  }
};

export const listAllNegozio = async () => {
  try {
    const results = await aplicationConnect.get<ResponseBackendNegozio>(
      "/Bussines?parameter=1"
    );
    const { status, response } = results.data;
    if (status === "success") return response;
    return [];
  } catch (error) {
    console.log(error);
  }
};
export const listAllBrand = async () => {
  try {
    const results = await aplicationConnect.get<ResponseBackendBrand>(
      "/Brand?parameter=1"
    );
    const { response, status } = results.data;
    if (status === "success") return response;
    return [];
  } catch (error) {}
};

export const formatNumber = (cash: number = 0) => {
  const formatConfig = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    currencyDisplay: "symbol",
  };
  const spanishNumberFormatter = new Intl.NumberFormat("es-ES", formatConfig);
  return spanishNumberFormatter.format(cash);
};

//
export const DeleteReferenza = async (idReference)=>{
  try {
    const results = await aplicationConnect.delete<ResponseBackendDelete>(
      `/Referenzia/${idReference}`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
  } catch (error) {}
}

export const DeleteMagazzino = async (idMagazzino)=>{
  try {
    const results = await aplicationConnect.delete<ResponseBackendDelete>(
      `/Magazzino/${idMagazzino}`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
  } catch (error) {}
}

export const FindByReference = async (idReference)=>{
  try {
    const results = await aplicationConnect.get<ResponseBackendReferenzaAndMatricola>(
      `/Matricola/FindByReference/${idReference}`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
  } catch (error) {}
}

export const AllDeletematricolaByReference = async (idReference)=>{
  let result = await FindByReference(idReference);
  for (let index = 0; index < result.length; index++) {
     await DeleteMatricola(result[index].idMatricola)
  }
}    

export const DeleteMatricola = async (idMatricola)=>{
  try {
    const results = await aplicationConnect.delete<ResponseBackendDelete>(
      `/Matricola/${idMatricola}`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
  } catch (error) {}
}
export const SearchClient = async(searchModalInputClient)=>{
  try {
    const results = await aplicationConnect.get<ResponseBackendClient>(
      `/Client/${searchModalInputClient}/10`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
  } catch (error) {}
}
export const GetDataClient = async ()=>{
  try {
    const results = await aplicationConnect.get<ResponseBackendClient>(
      `/Client?parameter=1`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
  } catch (error) {}
}
export const ExistingReferenza = async (referencia,idReferencia,idBussines)=>{
  try {
    // const results = await aplicationConnect.get<ResponseBackendClient>(
    //   `/Referenzia/FindByReferencia/${referencia}/${idReferencia}/${idBussines}`
    // );
    const results = await aplicationConnect.get<ResponseBackendClient>(
      `/Referenzia/FindByReferencia`,{
        params:{
          name:referencia,
          id:idReferencia,
          idNegozio:idBussines
        }
      }
    );
    const { response, status } = results.data;
    if (status === "success") return response;
  } catch (error) {}
}

export const SearchMagazzinoSuggestions = async (name:string,action:string) => {
  try {
    const results = await aplicationConnect.get<ResponseBackendMagazzino>(
      `/Magazzino/${name}/10/${action}`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
    return []
  } catch (error) {}
}
export const SearchMagazzinoProdottoSuggestions = async (name:string) => {
  try {
    const results = await aplicationConnect.get<ResponseBackendMagazzino>(
      `/Magazzino/SearchListMagazzinoFilter?limit=10&prodotto=${name}`
    );
    const { response, status } = results.data;
    if (status === "success") return response;
    return []
  } catch (error) {}
}
export const ExportListMagazzino = async (data:any)=>{
  try {
    const results = await aplicationConnect.post<ResponseBackendEsportaExcel>(
      `/Referenzia/ReferenziaEsportaExcel`,data
    );
    const { response, status } = results.data;
    if (status === "success") return response;
    return []
  } catch (error) {}

}
