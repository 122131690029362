import { Controller } from "react-hook-form"
import { Text, TouchableOpacity } from "react-native";
import { styles } from "../../theme/GlobalStyle";
import { styleInputBlock } from "../../GeneralStyles/SCDue/ControlPanel";
import CheckEmaildiRingraziamento from "./CheckEmaildiRingraziamento";

export const GridListInteressiByClient = ({ control, dataListInteressiByClient,confirmToDelete,optionAddEdit,checkBoxEmailRingraziamento,changeEmailRingraziamento}) => {
    return (
        <>
            <Text style={styles.label}>List Presenti</Text>
            {
                dataListInteressiByClient?.map((item, index) => {
                    return (
                        <div key={index} className="col-12">
                                                <Controller
                                control={control}
                                name="ListPresentiByClient"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <input
                                        className="form-control"
                                        value={item?.name + " - " + item?.desc_Bussines}
                                        style={styleInputBlock}
                                        disabled={true}
                                    />
                                )}
                            />
                             <div style={{
                                            position: "relative",                                           
                                            top:-35,
                                            left:30,
                                            zIndex:-1
                                        }}>
                           
                            <TouchableOpacity
                                style={{
                                    flex: 1,
                                    paddingHorizontal: 5,
                                    alignItems: "flex-end",
                                    justifyContent: "flex-end",
                                }}
                                onPress={() => confirmToDelete(item?.idList,item?.idClient)}
                            >
                                <img
                                    style={{ width: 20, height: 20 }}
                                    src={require("../../assets/icons/icon-delete.png")}
                                />
                            </TouchableOpacity>  
                            </div> 
                            <CheckEmaildiRingraziamento
                                optionAddEdit={optionAddEdit}
                                checkBoxEmailRingraziamento={checkBoxEmailRingraziamento}
                                id={item?.id}
                                changeEmailRingraziamento={changeEmailRingraziamento}
                                />                         
                            <br />
                        </div>
                    )
                })
            }
        </>

    )

}


