enum enMeses {
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre"
}
const DiaMesAnio = (fechaObj: Date) => {

    const fechaBolivia = new Date(fechaObj);

    const dia = fechaBolivia.getDate();
    const mes = enMeses[fechaBolivia.getMonth()];
    const anio = fechaBolivia.getFullYear();
    const horas = fechaBolivia.getUTCHours();
    const minutos = fechaBolivia.getMinutes();
    const segundos = fechaBolivia.getSeconds();
    return { dia, mes, anio, horas, minutos, segundos }
}

export const FormatYYMMDD = (fecha: Date | string) => {
    const fechaObj = new Date(fecha);
    const fechaFormat = DiaMesAnio(fechaObj);
    const month = fechaObj.getMonth() + 1
    const monthMod = month > 9 ? month : "0" + month
    const dayMod = fechaFormat.dia > 9 ? fechaFormat.dia : "0" + fechaFormat.dia

    return `${fechaFormat.anio}-${monthMod}-${dayMod}`
}
export const FormatDDMMYY = (fecha: Date | string) => {
    const fechaObj = new Date(fecha);
    const fechaFormat = DiaMesAnio(fechaObj);
    const month = fechaObj.getMonth() + 1
    const monthMod = month > 9 ? month : "0" + month
    const dayMod = fechaFormat.dia > 9 ? fechaFormat.dia : "0" + fechaFormat.dia

    return `${dayMod}/${monthMod}/${fechaFormat.anio}`
}

export const FormatHHSS = (fecha: Date | string, showSeconds = false) => {
    const fechaObj = new Date(fecha);
    const fechaFormat = DiaMesAnio(fechaObj);
    const hour = fechaFormat.horas > 9 ? fechaFormat.horas : "0" + fechaFormat.horas
    const minute = fechaFormat.minutos > 9 ? fechaFormat.minutos : "0" + fechaFormat.minutos
    const second = fechaFormat.segundos > 9 ? fechaFormat.segundos : "0" + fechaFormat.segundos

    return `${hour}:${minute} ${showSeconds ? " : " + second : ""}`
}

export function formatDateItalianFromDB(fechaDB) {
    const fecha = new Date(fechaDB);
  
    let day = fecha.getDate();
    let month = fecha.getMonth() + 1; 
    const year = fecha.getFullYear();
    let hour = fecha.getHours(); 
    const minutes = fecha.getMinutes();
    
    /*hour += 2;

    if (hour >= 24) {
        hour -= 24; 
        day += 1;   
        const daysInMonth = new Date(year, month, 0).getDate(); 
        if (day > daysInMonth) {
            day = 1;     
            month += 1;  
        }
    }*/

    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
    const formattedHour = hour < 10 ? '0' + hour : hour;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${formattedDay}/${formattedMonth}/${year}, ore ${formattedHour}:${formattedMinutes}`;
}




export const valdiateYear = (fecha: Date | string) => {
    const dateValue = new Date(fecha)
    const currentDate = new Date();
    const selectedYear = dateValue.getFullYear();
    const currentYear = currentDate.getFullYear();
    if (selectedYear > currentYear) { return true } else { return false }
}