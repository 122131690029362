import { useEffect } from "react";
import { useWidth } from "../../core/hook/useWidth";

interface Props {
  label: string;
}
export const HeaderDetail = ({ label}: Props) => {
  const { width } = useWidth()
  const arrayLabel = label.split(" ");

  return (
    <div className="d-flex flex-column fw-bold">
      <span className="text-uppercase">{ width > 500 ? arrayLabel[1] : arrayLabel[1].slice(0, 1)}</span>
      <span className="text-uppercase">{arrayLabel[0]}</span>
    </div>
  );
};
