import { useNavigation } from '@react-navigation/native';
import React from 'react'
import { NavigationProps } from '../../../navigator/NavigatorConfig';

const FooterToday = ({ visible }) => {
    const navigation = useNavigation<NavigationProps>();
    const navigate = () => {
        let date = new Date();
        navigation.navigate("CalenderDetailScreen", {
            date: date.getTime().toString()
        })
    }
    return (
        visible ?
            <div className="d-flex justify-content-end">
                <a
                    onClick={navigate}
                    className="px-3 text-white  "
                    style={{ textDecoration: 'none', background: '#359ffc', borderRadius: '10px', cursor: 'pointer', fontSize: '.8em', marginTop: '.5em' }}
                >
                    <span>Vedi tutto</span>
                </a>
            </div>
            :
            <div>
            </div>
    )
}

export default FooterToday
