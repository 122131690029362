import React from 'react'

const EventTodayItem = ({ id, title, detail, start, end }) => {

    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const day = date.getDate();
    //     const month = date.getMonth() + 1;
    //     const year = date.getFullYear();
    //     return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    // };

    function formatDateHour(date: Date) {
        const hour = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        return hour + ':' + minutes;
    }
    return (
        <div className="row p-2 justify-content-between w-100 g-1" key={id}>
            <div className="col-12">
                <div className="d-flex gap-3">
                    <div
                        className="d-flex justify-content-start flex-column mb-2 "
                    >
                        <h3 className="mb-0 fs-6 text-break">
                            {title} <strong className="ml-5 ">({formatDateHour(start)} - {formatDateHour(end)})</strong>
                        </h3> 
                        <span className="text-break" style={{ fontSize: '.8em' }}>{detail}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventTodayItem
