import { useState } from "react";
import { useNotify } from "../../hooks/useNotify";
import Loading from "../Loading";
import { ContentTasks } from "./ContentTasks";
import { FooterTasks } from "./FooterTasks";
import { HeaderTasks } from "./HeaderTasks";
import ModalTaskForm from "./ModalTaskForm";
import useTasks from "./hooks/useTasks";
import { useForm } from "react-hook-form";
import ModalForm from "../ModalForm/ModalForm";
import ModalFormEdit from "../ModalForm/ModalFormEdit";
import PopupMessage from "../PopupMessage";
import ModalFormEditDisable from "../ModalForm/ModalFormEditDisable";

export const Tasks = () => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenFormEdit, setIsOpenFormEdit] = useState(false);
  const [isOpenFormEditDisabled, setIsOpenFormEditDisabled] = useState(false);
  const { notify } = useNotify();
  const {
    BussinesList,
    UserList,
    defaultListTask,
    UserTecnicoList,
    PostTask,
    PutTasks,
    ListTaskBussinesById,
    defaultBussinesList,
    GetListTaskBussinesById,
    confirmviewTask,
    GetListByIdTaskBussines,
    GetListTaskUserById,
    listIdTaskBussinesView,
    defaultUserTecnicoList,
    defaultUserList,
    stateOptionNew,
    getPDFTask,
    DeleteTask,
    getIdRole
  } = useTasks();
  const [activeSpinner, setActiveSpinner] = useState(false);

  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });

  const [dataEdit, setDataEdit] = useState({
    id: null,
    title: "",
    detail: "",
  });

  if (!notify) return <Loading activeSpinner={!notify} />;
 
  const functionPost = async (data: any) => {
    setActiveSpinner(true);
    await PostTask(data).then(e=> {
      
      if(e){
        setModalMessage({
          active: true,
          message: "Compito aggiunto"
        })
      }
    });;
    setActiveSpinner(false);
    setIsOpenForm(!isOpenForm);
  };
  const functionPut = async (data: any) => {
    
    setActiveSpinner(true);
    
    await PutTasks(data).then(e=> {
      
      if(e){
        setModalMessage({
          active: true,
          message: "Compito modificate"
        })
      }
    });
    
    setActiveSpinner(false);
    setIsOpenFormEdit(false);
  };

  const deleteTask = async (id) => {
    setActiveSpinner(true);
    await DeleteTask(id).then((e) => {
      console.log(e);
      if (e.status == "success") {
        setModalMessage({
          active: true,
          message: "",
        });
      }

      GetListTaskBussinesById();
      setIsOpenFormEdit(false);
    });
    setActiveSpinner(false);
  };

  return (
    <>
      <div className="mb-5 pe-4">
        <HeaderTasks
          notify={ListTaskBussinesById.length}
          BussinesList={BussinesList}
          UserList={UserList}
          UserTecnicoList={UserTecnicoList}
          isOpenForm={isOpenForm}
          setIsOpenForm={setIsOpenForm}
          getIdRole={getIdRole}
        />
        <ContentTasks
          setDataEdit={setDataEdit}
          setIsOpenFormEdit={setIsOpenFormEdit}
          defaultListTask={defaultListTask}
          isOpenFormEdit={isOpenFormEdit}
          confirmviewTask={confirmviewTask}
          GetListByIdTaskBussines={GetListByIdTaskBussines}
          listIdTaskBussinesView={listIdTaskBussinesView}
          notify={ListTaskBussinesById.items}
          items={ListTaskBussinesById.normalizedResults}
          getIdRole={getIdRole}
          setIsOpenFormEditDisabled={setIsOpenFormEditDisabled}
          isOpenFormEditDisabled={isOpenFormEditDisabled}
        />
        <FooterTasks items={notify.normalizedResults} />
      </div>
      
      {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      <ModalForm
        actionSave={functionPost}
        title={"Aggiungi tasks"}
        BussinesList={BussinesList}
        isOpen={isOpenForm}
        setIsOpen={setIsOpenForm}
        UserList={UserList}
        UserTecnicoList={UserTecnicoList}
      />
      <ModalFormEdit
        defaultUserTecnicoList={defaultUserTecnicoList}
        defaultUserList={defaultUserList}
        defaultBussinesList={defaultBussinesList}
        deleteTask={deleteTask}
        dataEdit={dataEdit}
        getPDFTask={getPDFTask}
        actionSave={functionPut}
        title={"Modifica tasks"}
        BussinesList={BussinesList}
        isOpen={isOpenFormEdit}
        setIsOpen={setIsOpenFormEdit}
        UserList={UserList}
        UserTecnicoList={UserTecnicoList}
        stateOptionNew={stateOptionNew}
      />

      <ModalFormEditDisable 
        defaultUserTecnicoList={defaultUserTecnicoList}
        defaultUserList={defaultUserList}
        defaultBussinesList={defaultBussinesList}
        deleteTask={deleteTask}
        dataEdit={dataEdit}
        getPDFTask={getPDFTask}
        actionSave={functionPut}
        title={"Modifica tasks"}
        BussinesList={BussinesList}
        isOpen={isOpenFormEditDisabled}
        setIsOpen={setIsOpenFormEditDisabled}
        UserList={UserList}
        UserTecnicoList={UserTecnicoList}
        stateOptionNew={stateOptionNew}
      />


      <PopupMessage
        title={"Success"}
        message={modalMessage.message}
        stateModal={modalMessage.active}
        setStateModal={setModalMessage}
      />
    </>
  );
};
