import { useState } from "react";
import { useNotify } from "../../hooks/useNotify";
import Loading from "../Loading";
import ModalForm from "../ModalForm/ModalForm";
import { ContentNews } from "./ContentNews";
import { FooterNews } from "./FooterNews";
import { HeaderNews } from "./HeaderNews";
import useNews from "./hooks/useNews";
import ModalFormEdit from "../ModalForm/ModalFormEdit";
import PopupMessage from "../PopupMessage";
import ModalFormEditDisable from "../ModalForm/ModalFormEditDisable";

export const News = () => {
  const { notify } = useNotify();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenFormEdit, setIsOpenFormEdit] = useState(false);



  const [isOpenFormEditDisabled, setIsOpenFormEditDisabled] = useState(false);

  const [dataEdit, setDataEdit] = useState({
    id: null,
    title: "",
    detail: "",
  });

  const {
    BussinesList,
    stateOptionNew,
    defaultListNews,
    defaultBussinesList,
    defaultUserList,
    defaultUserTecnicoList,
    UserList,
    UserTecnicoList,
    PutNews,
    PostNews,
    DeleteNews,
    ListNewBussinesById,
    listIdNewBussinesView,
    GetListByIdNewBussines,
    confirmviewNew,
    GetListNewBussinesById,
    getPDFNews,
    getIdRole
  } = useNews();

  const [activeSpinner, setActiveSpinner] = useState(false);

  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });


  if (!notify) return <Loading activeSpinner={!notify} />;
  const functionPost = async (data: any) => {

    setActiveSpinner(true);
    await PostNews(data).then(e => {

      if (e) {
        setModalMessage({
          active: true,
          message: "Notizie aggiunte"
        })
      }
    });


    setActiveSpinner(false);
    setIsOpenForm(!isOpenForm);
  };

  const functionPut = async (data: any) => {

    setActiveSpinner(true);

    debugger

    await PutNews(data).then(e => {

      if (e) {
        setModalMessage({
          active: true,
          message: "Notizie modificate"
        })
      }
    });

    setActiveSpinner(false);
    setIsOpenFormEdit(false);
  };

  const deleteNew = async (id) => {
    setActiveSpinner(true);
    await DeleteNews(id).then((e) => {
      console.log(e);

      if (e.status == 'success') {
        setModalMessage({
          active: true,
          message: ""
        })
      }

      GetListNewBussinesById();
      setIsOpenFormEdit(false);
    });
    setActiveSpinner(false);
  };


  return (
    <>
      <div className="mb-5 pe-4">
        <HeaderNews
          notify={ListNewBussinesById.length}
          isOpenForm={isOpenForm}
          setIsOpenForm={setIsOpenForm}
          getIdRole={getIdRole}
        />
        <ContentNews
          setIsOpenFormEdit={setIsOpenFormEdit}
          setDataEdit={setDataEdit}
          setIsOpenFormEditDisabled={setIsOpenFormEditDisabled}
          isOpenFormEditDisabled={isOpenFormEditDisabled}
          isOpenFormEdit={isOpenFormEdit}
          defaultListNews={defaultListNews}
          confirmviewNew={confirmviewNew}
          GetListByIdNewBussines={GetListByIdNewBussines}
          listIdNewBussinesView={listIdNewBussinesView}
          notify={ListNewBussinesById.items}
          items={ListNewBussinesById.normalizedResults}
          getIdRole={getIdRole}


        />
        <FooterNews items={notify.normalizedResults} /> 
      </div>

      {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      <ModalForm
        actionSave={functionPost}
        title={"Aggiungi news"}
        BussinesList={BussinesList}
        isOpen={isOpenForm}
        setIsOpen={setIsOpenForm}
        UserList={UserList}
        UserTecnicoList={UserTecnicoList}
      />

      <ModalFormEdit
        deleteNew={deleteNew}
        dataEdit={dataEdit}
        actionSave={functionPut}
        title={"Modifica news"}
        BussinesList={BussinesList}
        isOpen={isOpenFormEdit}
        setIsOpen={setIsOpenFormEdit}
        UserList={UserList}
        getPDFNews={getPDFNews}
        UserTecnicoList={UserTecnicoList}
        stateOptionNew={stateOptionNew}
        defaultBussinesList={defaultBussinesList}
        defaultUserList={defaultUserList}
        defaultUserTecnicoList={defaultUserTecnicoList}
      />

      <ModalFormEditDisable
        deleteNew={deleteNew}
        dataEdit={dataEdit}
        actionSave={functionPut}
        title={"Modifica news"}
        BussinesList={BussinesList}
        isOpen={isOpenFormEditDisabled}
        setIsOpen={setIsOpenFormEditDisabled}
        UserList={UserList}
        getPDFNews={getPDFNews}
        UserTecnicoList={UserTecnicoList}
        stateOptionNew={stateOptionNew}
        defaultBussinesList={defaultBussinesList}
        defaultUserList={defaultUserList}
        defaultUserTecnicoList={defaultUserTecnicoList}
      />
      
      <PopupMessage
        title={"Success"}
        message={modalMessage.message}
        stateModal={modalMessage.active}
        setStateModal={setModalMessage}
      />
    </>
  );
};
