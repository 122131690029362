import { useNavigation } from "@react-navigation/native";
import { Notify } from "../../interfaces/Notify";
import { NavigationProps } from "../../navigator/NavigatorConfig";

interface Props {
  title: string;
  detail: String;
  id: number;
  items:Notify[]
  date: string
}
export const NotifyItem = ({ detail, title, id, items ,date}: Props) => {
  const navigation = useNavigation<NavigationProps>();


  return (
    <div
      onClick={() =>
        navigation.navigate("NotifyScreen", {
          id:id.toString(),
          items
        })
      }
      className="d-flex justify-content-between align-items-center  w-100 "
    >
      <h3 className="fs-6">{title} </h3>
      <p className="p-0 m-0 " style={{fontFamily: 'Arial',fontSize:'.8em'}}>{date.substring(0,10)}</p>
      {/* <span style={{fontSize:'.8em'}}>{detail}</span> */}
    </div>
  );
};
