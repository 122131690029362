import React from "react";
import CSS from "csstype";

export const TableHeaderCrmAtelier = () => {
  return (
    <thead style={{ backgroundColor: "black", color: "white" }}>
      <tr className="mr-4">
        <th
          style={{
            borderTopLeftRadius: 50,
            borderBottomLeftRadius: 50,
          }}
          scope="col"
          className="align-self-center mr-4 px-4"
        ></th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Cliente
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          E-mail
        </th>
        <th
          style={tableHeader}
          scope="col"
          className="align-self-center mr-4 px-4"
        >
          Telefono
        </th>
        <th
          style={{
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
          }}
          scope="col"
          className="align-self-center mr-4 px-4"
        ></th>
      </tr>
    </thead>
  );
};

const tableHeader: CSS.Properties = {
  textAlign: "center",
  fontWeight: "400",
  verticalAlign: "middle",
};