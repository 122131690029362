import { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { IoCalendarSharp } from "react-icons/io5";
import DatePicker, { registerLocale } from "react-datepicker";
import { stylesStatisticheMagazzino } from "../../GeneralStyles/Statistiche";

interface Props {
  handleSearch: any;
  value: any;
}

import it from "date-fns/locale/it";
registerLocale("it", it);
export const FilterMeseAnno = ({ handleSearch, value }: Props) => {
  const { search, setSearch, enabled, messageIncorrect } = useSearch({
    handleSearch,
    value,
  });
  const formatDate =(date)=>{
     const valueOfSearch = new Date(date);
  const dataOfSearch =
    valueOfSearch.getDate()+"/"+(valueOfSearch.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    valueOfSearch.getFullYear();
    return dataOfSearch;

  }
 
  //datepickerRange
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const hangleData =(e=[])=>{
    console.log("update",e)
    console.log(formatDate(startDate),formatDate(endDate))
    if(e.length<2){
      if(startDate && endDate){
        handleSearch([formatDate(startDate),formatDate(endDate)])  
      }else{
        alert("Devi selezionare un intervallo valido (due date)");
      }
    }else{
      handleSearch([formatDate(e[0]),formatDate(e[1])])
    }
  }
  return (
    <div className="col-12 d-flex">
       <DatePicker
       locale="it"
      dateFormat="dd/MM/yyyy"
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange([update[0],update[1]]);
        setSearch([update[0],update[1]])
        hangleData(update);
      }}
      isClearable={true}
      />
      <TouchableOpacity
        onPress={
          enabled ? () => {hangleData()} : () => messageIncorrect()
        }
        style={{
          margin: "auto",
        }}
      >
        <View
          style={[
            stylesStatisticheMagazzino.viewDisable,
            stylesStatisticheMagazzino.search,
          ]}
        >
          <i className="d-flex align-items-center justify-content-center">
            <IoCalendarSharp color="white" size={28} />
          </i>
        </View>
      </TouchableOpacity>
    </div>
  );
};

//HOOK PARA EL BUSCADOR
const useSearch = ({ handleSearch, value }: Props) => {
  const [search, setSearch] = useState(value);
  const [enabled, setEnabled] = useState(false);

  const handleChange = (value) => {
    if (value) {
      setSearch(value);
      setEnabled(true);
    } else {
      if (enabled) {
        setEnabled(false);
      }
    }
  };

  const messageIncorrect = () => {
    alert("Devi inserire un formato valido MM/AAAA");
  };

  useEffect(() => {
    search.length === 0 && handleSearch([]);
  }, [search]);
  return {
    search,
    setSearch: handleChange,
    messageIncorrect,
    enabled,
  };
};