import { useState } from "react"

export const useModalEdit = () => {
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [items, setItems] = useState({}) as any;
    const closeModalEdit = () => {
      const element = document.querySelector(".modal-backdrop");
      if (element) {
        element.parentNode?.removeChild(element);
      }
      setIsOpenEdit(false);
      setItems(null);
    };
    const openModalEdit = (e: any) => {
      const overlay = document.createElement("div");
      overlay.className = "modal-backdrop fade show";
      document.querySelector("#root")?.insertAdjacentElement("afterend", overlay);
      setIsOpenEdit(true);
      setItems(e);
    };
    const openModalE = () => {
      const overlay = document.createElement("div");
      overlay.className = "modal-backdrop fade show";
      document.querySelector("#root")?.insertAdjacentElement("afterend", overlay);
      setIsOpenEdit(true);
    };
  
    return {
      isOpenEdit,
      items,
      closeModalEdit,
      openModalEdit,
      openModalE,
    };
  return {}
}
