import React, { useContext } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { AssistenzaSergioCapone } from "../screens/LoginScreen";
import { Login2FactorScreen } from "../screens/Login2FactorScreen";
import { HomeScreen } from "../screens/HomeScreen";
import { SendNotificationScreen } from "../screens/SendNotificationScreen";
import { SendEmailScreen } from "../screens/SendEmailScreen";
import { RepairsScreen } from "../screens/RepairsScreen";
import RepairCardScreen from "../screens/RepairCardScreen";
import { StateRepairScreen } from "../screens/StateRepairScreen";
import { ProspectusRepairScreen } from "../screens/ProspectusRepairScreen";
import { ContactScreen } from "../screens/ContactScreen";
import { AdminScreen } from "../screens/AdminScreen";
import { AdminData } from "../interfaces/IAdminData";
import { IRepairData } from "../api/RepairData";
import { ClientData } from "../api/ContactData";
import { IClient } from "../api/ClientService";
import FormRepairScreen from "../screens/FormRepairScreen";
import PdfScreen from "../screens/PdfScreen";
import BudgetScreen from "../screens/BudgetScreen";

import { MagazzinoHomeScreen } from "../screens/Magazzino/MagazzinoHomeScreen";
import { MagazzinoCercaScreen } from "../screens/Magazzino/MagazzinoCercaScreen";
import { MagazzinoAddScreen } from "../screens/Magazzino/MagazzinoAddScreen";
import { MagazzinoStatistiche } from "../screens/Magazzino/MagazzinoStatistiche";
import { MagazzinoGraphsStatistiche } from "../screens/Magazzino/MagazzinoGraphsStatistiche";

import { CRMHomeScreen } from "../screens/CRM/HomeScreen";
import { HomeManifestazioneScreen } from "../screens/Manifestazione/HomeManifestazioneScreem";
import { ControlPanelManifestazioneScreen } from "../screens/Manifestazione/ControlPanelManifestazioneScreen";
import { ListeScreen } from '../screens/Manifestazione/ListeScreen';
import { DetailsListeScreen } from '../screens/Manifestazione/DetailsListeScreen';
import { ControlPanelScreen } from "../screens/CRM/ControlPanelScreen";
import { AddFromStockScreen } from "../screens/CRM/AddFromStockScreen";
import { ClientListScreen } from "../screens/CRM/ClientListScreen";
import { LabelsScreen } from "../screens/CRM/LabelsScreen";
import { LabelsScreenManifestazione } from "../screens/Manifestazione/LabelsScreenManifestazione";
import { LabelsScreenListDetails } from "../screens/Manifestazione/LabelsScreenListDetails";
import { HomeSCDueScreen } from "../screens/HomeSCDueScreen";
import { ContactInfoScreen } from "../screens/Contact/ContactInfoScreen";
import { ImportExportScreen } from "../screens/ImportExport/ImportExportScreen";
import { CalenderDetailScreen } from "../screens/CalenderDetailScreen";
import { StackNavigationProp } from "@react-navigation/stack";
import { NotifyScreen } from "../screens/NotifyScreen";
import { NewsScreen } from "../screens/New/pages/NewsScreen";
import { TaskScreen } from "../screens/Tasks/pages/TaskScreen";
import FormRepairScreenCopy from "../screens/FormRepairScreenCopy";
import { Notify } from "../interfaces/Notify";
import { Copy } from "../screens/Magazzino/copy";
import PdfScreenStampaFoto from "../screens/PdfScreenStampaFoto";
import MagazzinoAtelier from "../screens/Magazzino/Atelier/MagazzinoAtelier";
import OrdiniAtelier from "../screens/Manifestazione/Atelier/OrdiniAtelier";
import OrdiniAtelierForm from "../screens/Manifestazione/Atelier/Form/OrdineAtelierForm";
import '../styles/Layout.css'
import MagazzinoAtelierForm from "../screens/Magazzino/Atelier/Form/MagazzinoAtelierForm";

import MagazzinoCercaAtelierScreen from "../screens/Magazzino/Atelier/views/MagazzinoCercaAtelierScreen";
import MagazzinoAtelierFormEdit from "../screens/Magazzino/Atelier/Form/MagazzinoAtelierFormEdit";
import CrmAtelier from "../screens/CRM/Atelier/CrmAtelier";
import ClientListAtelierScreen from "../screens/CRM/Atelier/views/ClientListAtelierScreen";
import AggiungiCrmAtelier from "../screens/CRM/Atelier/views/AggiungiCrmAtelier";
import { PrintLabelScreen } from "../screens/CRM/Atelier/views/PrintLabelScreen";
import NewRepairAtelierScreen from "../screens/Assistenza/Atelier/NewRepairAtelierScreen";
import ListOrdiniAtelier from "../screens/Manifestazione/Atelier/lists/ListOrdiniAtelier";
import ListDetailOrdiniAtelier from "../screens/Manifestazione/Atelier/lists/ListDetailOrdiniAtelier";
import NuovaRiparazione from "../screens/Assistenza/Atelier/pages/NuovaRiparazione";
import ControlPanelRiparazione from "../screens/Assistenza/Atelier/pages/ControlPanelRiparazione";
import StatoRiparazioni from "../screens/Assistenza/Atelier/pages/StatoRiparazioni";
import Prospectusreparacione from "../screens/Assistenza/Atelier/pages/Prospectusreparacione";
import ContattiRiparazioni from "../screens/Assistenza/Atelier/pages/ContattiRiparazioni";
import StampaManifestazione from "../screens/Manifestazione/Atelier/pages/StampaManifestazione";
import PreventivoRiparazioni from "../screens/Assistenza/Atelier/pages/PreventivoRiparazioni";
export type RootStackParams = {
  AssistenzaSergioCapone: undefined;
  Login2Factor: undefined;
  HomeScreen: undefined;
  SendNotificationScreen: undefined;
  SendEmailScreen: undefined;
  RepairsScreen: undefined;
  CompilaScreen: undefined;
  DashboardScreen: undefined;
  CalenderDetailScreen: {
    date?: string;
  };
  NotifyScreen: {
    id?: string;
    items: Notify[]
  };
  NewsScreen: {
    id?: string;
    items: any
  };
  TaskScreen: {
    id?: string;
    items: any
  };
  RepairCardScreen: undefined;
  StateRepairScreen: IRepairData[];
  ProspectusRepairScreen: ClientData;
  ContactScreen?: IClient[] | undefined;
  AdminScreen?: AdminData[];
  DataMasterClient: IRepairData;
  FormRepairScreen: IRepairData;
  EditUserScreen?: AdminData | undefined;

  //CRM Screens
  CRMHomeScreen: undefined;
  ControlPanelScreen: undefined;
  AddFromStockScreen: undefined;
  ClientListScreen: undefined;
  LabelsScreen: undefined;
  HomeSCDueScreen: {
    refresh?: boolean
  };
  ContactInfoScreen: undefined;

  // Magazzino Screens
  MagazzinoHomeScreen: undefined;
  MagazzinoCercaScreen: undefined;
  MagazzinoAddScreen: undefined;
  MagazzinoStatistiche: undefined;
  MagazzinoGraphsStatistiche: undefined;

  // Magazzino Atelier
  MagazzinoAtelier: undefined;
  MagazzinoAtelierForm: undefined;
  MagazzinoCercaAtelierScreen: undefined;

  //OrdiniAtelier
  OrdiniAtelier: undefined;
  OrdiniAtelierForm: undefined;
  /* MagazzinoCercaAtelierScreen:undefined; */




};
export type NavigationProps = StackNavigationProp<RootStackParams>;
const { Navigator, Screen } = createNativeStackNavigator();

export const NavigatorConfig = () => {
  return (

    <Navigator
      initialRouteName="Assistenza - Sergio Capone"
      screenOptions={{ title: "Assistenza - Sergio Capone" }}
    >

      <Screen
        options={{ headerShown: false, title: "Calendario" }}
        name="CalenderDetailScreen"
        component={CalenderDetailScreen}
      />
      <Screen
        options={{ headerShown: false, title: "Notifiche" }}
        name="NotifyScreen"
        component={NotifyScreen}
      />
      <Screen
        options={{ headerShown: false, title: "News" }}
        name="NewsScreen"
        component={NewsScreen}
      />
      <Screen
        options={{ headerShown: false, title: "Tasks" }}
        name="TaskScreen"
        component={TaskScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="BudgetScreen"
        component={BudgetScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="Assistenza - Sergio Capone"
        component={AssistenzaSergioCapone}
      />
      <Screen
        options={{ headerShown: false }}
        name="Login2FactorScreen"
        component={Login2FactorScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="HomeScreen"
        component={HomeScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="FormRepairScreen"
        component={FormRepairScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="FormRepairScreenCopy"
        component={FormRepairScreenCopy}
      />
      <Screen
        options={{ headerShown: false }}
        name="SendNotificationScreen"
        component={SendNotificationScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="SendEmailScreen"
        component={SendEmailScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="RepairsScreen"
        component={RepairsScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="RepairCardScreen"
        component={RepairCardScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="StateRepairScreen"
        component={StateRepairScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="ProspectusRepairScreen"
        component={ProspectusRepairScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="ContactScreen"
        component={ContactScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="AdminScreen"
        component={AdminScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="PdfScreen"
        component={PdfScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="PdfScreenStampaFoto"
        component={PdfScreenStampaFoto}
      />

      {/* Magazzino */}
      <Screen
        options={{ headerShown: false }}
        name="MagazzinoHomeScreen"
        component={MagazzinoHomeScreen}
      />

      <Screen
        options={{ headerShown: false }}
        name="MagazzinoCercaScreen"
        component={MagazzinoCercaScreen}
      />

      <Screen
        options={{ headerShown: false }}
        name="MagazzinoAddScreen"
        component={MagazzinoAddScreen}
      />

      <Screen
        options={{ headerShown: false }}
        name="MagazzinoStatistiche"
        component={MagazzinoStatistiche}
      // component={Copy}
      />

      <Screen
        options={{ headerShown: false }}
        name="MagazzinoGraphsStatistiche"
        component={MagazzinoGraphsStatistiche}
      />

      {/* crm Atelier */}
      <Screen
        options={{ headerShown: false }}
        name="CrmAtelier"
        component={CrmAtelier}
      />
      <Screen
        options={{ headerShown: false }}
        name="ClientListAtelierScreen"
        component={ClientListAtelierScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="AggiungiCrmAtelier"
        component={AggiungiCrmAtelier}
      />
      <Screen
        options={{ headerShown: false }}
        name="PrintLabelScreen"
        component={PrintLabelScreen}
      />
      {/* magazzino Atelier */}

      <Screen
        options={{ headerShown: false }}
        name="MagazzinoAtelier"
        component={MagazzinoAtelier}
      />
      <Screen
        options={{ headerShown: false }}
        name="MagazzinoAtelierForm"
        component={MagazzinoAtelierForm}
      />
      <Screen
        options={{ headerShown: false }}
        name="MagazzinoAtelierFormEdit"
        component={MagazzinoAtelierFormEdit}
      />

      <Screen
        options={{ headerShown: false }}
        name="MagazzinoCercaAtelierScreen"
        component={MagazzinoCercaAtelierScreen}
      />

      {/* Assistenza Atelier */}
      <Screen
        options={{ headerShown: false }}
        name="NewRepairAtelierScreen"
        component={NewRepairAtelierScreen}
      />

      <Screen
        options={{ headerShown: false }}
        name="NuovaRiparazione"
        component={NuovaRiparazione}
      />

      <Screen
        options={{ headerShown: false }}
        name="ControlPanelRiparazione"
        component={ControlPanelRiparazione}
      />

      <Screen
        options={{ headerShown: false }}
        name="StatoRiparazioni"
        component={StatoRiparazioni}
      />

      <Screen
        options={{ headerShown: false }}
        name="Prospectusreparacione"
        component={Prospectusreparacione}
      />

      <Screen
        options={{ headerShown: false }}
        name="ContattiRiparazioni"
        component={ContattiRiparazioni}
      />

      <Screen
        options={{ headerShown: false }}
        name="PreventivoRiparazioni"
        component={PreventivoRiparazioni}
      />
      {/* ordiniAtelier */}

      <Screen
        options={{ headerShown: false }}
        name="OrdiniAtelier"
        component={OrdiniAtelier}
      />

      <Screen
        options={{ headerShown: false }}
        name="OrdiniAtelierForm"
        component={OrdiniAtelierForm}
      />

      <Screen
        options={{ headerShown: false }}
        name="ListOrdiniAtelier"
        component={ListOrdiniAtelier}
      />

      <Screen
        options={{ headerShown: false }}
        name="ListDetailOrdiniAtelier"
        component={ListDetailOrdiniAtelier}
      />
      <Screen
        options={{ headerShown: false }}
        name="StampaManifestazione"
        component={StampaManifestazione}
      />
      {/* SC Due */}
      <Screen
        options={{ headerShown: false }}
        name="HomeSCDueScreen"
        component={HomeSCDueScreen}
      />

      {/* CRM Screens */}
      <Screen
        options={{ headerShown: false }}
        name="CRMHomeScreen"
        component={CRMHomeScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="ControlPanelScreen"
        component={ControlPanelScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="AddFromStockScreen"
        component={AddFromStockScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="ClientListScreen"
        component={ClientListScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="LabelsScreen"
        component={LabelsScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="ContactInfoScreen"
        component={ContactInfoScreen}
      />

      {/* Manifestazione Screens */}
      <Screen
        options={{ headerShown: false }}
        name="HomeManifestazioneScreen"
        component={HomeManifestazioneScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="ControlPanelManifestazioneScreen"
        component={ControlPanelManifestazioneScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="ListeScreen"
        component={ListeScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="DetailsListeScreen"
        component={DetailsListeScreen}
      />
      <Screen
        options={{ headerShown: false }}
        name="LabelsScreenManifestazione"
        component={LabelsScreenManifestazione}
      />
      <Screen
        options={{ headerShown: false }}
        name="LabelsScreenListDetails"
        component={LabelsScreenListDetails}
      />
      {/* Import Export */}
      <Screen
        options={{ headerShown: false }}
        name="ImportExportScreen"
        component={ImportExportScreen}
      />
    </Navigator>
  );
};
