import React from "react";
import { SafeAreaView, ScrollView, View } from "react-native";
import { styles } from "../../theme/GlobalStyle";
import { Header } from "../../components/MagazzinoRefactor/Header";
import { useMagazzino } from "../../hooks/useMagazzino";
import { SearchMagazzino } from "../../components/MagazzinoRefactor/SearchMagazzino";
import { ExportList } from "../../components/MagazzinoRefactor/ExportList";
import { Suggestions } from "../../components/MagazzinoRefactor/Suggestions";
import { stylesMagazzino } from "../../GeneralStyles/MagazzinoStyle";
import { FilterOptions } from "../../components/MagazzinoRefactor/FilterOptions";
import { FilterMagazzinoReferenza } from "../../components/MagazzinoRefactor/FilterMagazzinoReferenza";
import { FilterMagazzinoBrand } from "../../components/MagazzinoRefactor/FilterMagazzinoBrand";
import { FilterMagazzinoNegozio } from "../../components/MagazzinoRefactor/FilterMagazzinoNegozio";
import { FilterMagazzinoQuantita } from "../../components/MagazzinoRefactor/FilterMagazzinoQuantita";
import { TableMagazzino } from "../../components/MagazzinoRefactor/TableMagazzino";

import "../../utils/styles.css";
import Loading from "../../components/Loading";
import PopupConfirmation from "../../components/PopupConfirmation";
import SchemaProdottoCard from "../../components/magazzino/SchemaProdottoCard";

interface Props extends StackScreenProps<any, any> { }
import { StackScreenProps } from "@react-navigation/stack";
import ModalOptionMatricola from "../../components/magazzino/ModalOptionMatricola";
import PopUpMessageWithRoute from "../../components/PopUpMessageWithRoute";
import PopPupMessageInfoProdottoCard from "../../components/PopPupMessageInfoProdottoCard";
import { FilterMagazzinoDescrizione } from "../../components/MagazzinoRefactor/FilterMagazzinoDescrizione";
import SchemaProdottoAtelierCard from "./Atelier/views/modals/SchemaProdottoAtelierCard";
import Pagination from "../../components/Pagination/pagination";

export const MagazzinoCercaScreen = ({ route, navigation }: Props) => {
  const {
    handleFilter,
    handleFilterBrand,
    handleFilterNegozio,
    handleFilterQuantita,
    magazzinoList,
    loading,
    brandList,
    negozioList,
    modal,
    setModal,
    IdStateProduct,
    IdStateMagazzino,
    confirmToDelete,
    confirmToDeleteTrue,
    goToSchedaProdotto,
    goToSchedaQuadrante,
    modalVisibleCard,
    setModalVisibleCard,
    modalQuadrante,
    setModalQuadrante,
    dataMagazzino,
    listMatricola,
    listMagazzino,
    listClient,
    setListClient,
    activeBottom,
    searchModalInputClient,
    setSearchModalInputClient,
    handleSearchModalFilter,
    autosuggestModalClientEsistenteInput,
    setAutosuggestModalClientEsistenteInput,
    findReference,
    setFindReference,
    handleData,
    modalVisibleMatricolaCard,
    setModalVisibleMatricolaCard,
    goToMatricola,
    crmSend,
    paginationList,
    currentPage,
    setcurrentPage,
    limit,
    handleSearch,
    setResetCurrentPage,
    searchInputReferenza,
    setSearchInputReferenza,
    onChangeTextReferenza,
    onChangeTextProdotto,
    autosuggestInputReferenza,
    setAutosuggestInputReferenza,
    autosuggestInputProdotto,
    setAutosuggestInputProdotto,
    searchInputProdotto,
    setSearchInputProdotto,
    handleSearchFilter,
    setLoading,
    dataExcelMagazzino,
    filtered,
    showSuccessPopup,
    modaleMessaggeWithUrl,
    setmodaleMessaggeWithUrl,
    confirmAction,
    activeSuggestion,
    activeSuggestionReferenza,
    searchInputDescrizione,
    setSearchInputDescrizione,
    searchInputMisura,
    setSearchInputMisura,
    searchInputColore,
    setSearchInputColore,
    searchInputMovimento,
    setSearchInputMovimento,
    handleSearchDescrizione,
    griglia,
    setgriglia,
    modalVisibleCardAtelier,
    setModalVisibleCardAtelier,
    heightResize,
    divRef
  } = useMagazzino();

  let arrayData: any = route.params || [];
  let dataCrm = arrayData ? arrayData.send : "";
  //fix GP
  let _controlPanel = route.params.controlPanel;

  const goToCrm = async (dataMagazzino?: any, option?: any) => {    
    await navigation.navigate("ControlPanelScreen", {
      dataMagazzino,
      option: { option: option },
    });
  };
  return (
    <>
      <div className="square">
        <ScrollView style={styles.scroll}>
          {loading && <Loading activeSpinner={loading} />}
          <SafeAreaView style={stylesMagazzino.safeAreaView}>
            <Header controlPanel={_controlPanel} />
            <View style={stylesMagazzino.row}>
              <div className="row px-3">
                <SearchMagazzino
                  handleSearchFilter={handleSearchFilter}
                  onChangeTextProdotto={onChangeTextProdotto}
                  searchInputProdotto={searchInputProdotto}
                />
                <div className="col-12 col-sm-7 col-md-6 col-lg-9" />

                {activeSuggestion &&
                  searchInputProdotto &&
                  autosuggestInputProdotto.length > 0 ? (
                  <Suggestions
                    autosuggestInput={autosuggestInputProdotto}
                    setSearchInput={setSearchInputProdotto}
                    setAutosuggestInput={setAutosuggestInputProdotto}
                    isprodotto={true}
                  />
                ) : null}
              </div>
              <ExportList
                magazzinoList={dataExcelMagazzino}
                setLoading={setLoading}
                setgriglia={setgriglia}
                griglia={griglia}
              />
              <div className="mt-4 pt-2">
                <div className="row">
                  <div className="col-12 col-sm-4 col-md-3 col-lg-2 ">
                    <div ref={divRef} className="row " style={{ marginTop: 11 }}>
                      <FilterOptions />
                      <FilterMagazzinoReferenza
                        searchInputReferenza={searchInputReferenza}
                        onChangeTextReferenza={onChangeTextReferenza}
                        handleSearch={handleSearch}
                      />
                      <div className="col-12 ">
                        {activeSuggestionReferenza &&
                          searchInputReferenza &&
                          autosuggestInputReferenza.length > 0 ? (
                          <Suggestions
                            autosuggestInput={autosuggestInputReferenza}
                            setSearchInput={setSearchInputReferenza}
                            setAutosuggestInput={setAutosuggestInputReferenza}
                            isprodotto={false}
                          />
                        ) : null}
                      </div>
                      <FilterMagazzinoDescrizione
                        searchInput={searchInputDescrizione}
                        onChange={setSearchInputDescrizione}
                        handleSearch={handleSearchDescrizione}
                        title={"Descrizione"}
                      />
                      <FilterMagazzinoDescrizione
                        searchInput={searchInputMisura}
                        onChange={setSearchInputMisura}
                        handleSearch={handleSearchDescrizione}
                        title={"Misura        "}
                      />
                      <FilterMagazzinoDescrizione
                        searchInput={searchInputColore}
                        onChange={setSearchInputColore}
                        handleSearch={handleSearchDescrizione}
                        title={"Colore        "}
                      />
                      <FilterMagazzinoDescrizione
                        searchInput={searchInputMovimento}
                        onChange={setSearchInputMovimento}
                        handleSearch={handleSearchDescrizione}
                        title={"Movimento"}
                      />
                      <FilterMagazzinoBrand
                        brandList={brandList}
                        handleFilterBrand={handleFilterBrand}
                      />
                      <FilterMagazzinoNegozio
                        bussinesList={negozioList}
                        handleFilterNegozio={handleFilterNegozio}
                      />
                      <FilterMagazzinoQuantita
                        handleFilterQuantita={handleFilterQuantita}
                      />
                    </div>
                  </div>
                  <TableMagazzino 
                    data={magazzinoList}
                    confirmToDelete={confirmToDelete}
                    goToSchedaProdotto={goToSchedaProdotto}
                    goToSchedaQuadrante={goToSchedaQuadrante}
                    dataCrm={dataCrm}
                    goToMatricola={goToMatricola}
                    crmSend={crmSend}
                    paginationList={paginationList}
                    currentPage={currentPage}
                    setcurrentPage={setcurrentPage}
                    limit={limit}
                    setResetCurrentPage={setResetCurrentPage}
                    griglia={griglia}
                    heightRisize={(heightResize + 40)+'px'}
                  />
                </div>
              </div>
            </View>
          </SafeAreaView>
        </ScrollView>
      </div> 
      {modal && (
        <PopupConfirmation
          title={""}
          message={"Sei sicuro di voler eliminare il prodotto?"}
          confirmAction={() =>
            confirmToDeleteTrue(IdStateProduct, IdStateMagazzino)
          }
          stateModal={modal}
          setStateModal={setModal}
        />
      )}

      {modalVisibleCard && (
        <>
          <SchemaProdottoCard
            modalVisible={modalVisibleCard}
            setModalVisible={setModalVisibleCard}
            dataMagazzino={dataMagazzino}
            listMagazzino={listMagazzino}
            dataMatricola={listMatricola}
            activeButton={activeBottom}
            searchModalInputClient={searchModalInputClient}
            setSearchModalInputClient={setSearchModalInputClient}
            handleSearchModalFilter={handleSearchModalFilter}
            dataClient={listClient}
            setDataClient={setListClient}
            autosuggest={autosuggestModalClientEsistenteInput}
            setAutosuggest={setAutosuggestModalClientEsistenteInput}
            findReference={findReference}
            setFindReference={setFindReference}
            goToSchedaProdotto={() => goToSchedaProdotto(dataMagazzino, "")}
            getDataMagazzino={() => filtered()}
            goToCrm={goToCrm}
            dataCrm={dataCrm}
            navigation={navigation}
          />
        </>
      )}

      {modalVisibleCardAtelier && (
        <>
          <SchemaProdottoAtelierCard
            modalVisible={modalVisibleCardAtelier}
            setModalVisible={setModalVisibleCardAtelier}
            navigation={navigation}
            dataMagazzino={dataMagazzino}
            dataMatricola={listMatricola}
            listMagazzino={listMagazzino}
            goToSchedaProdotto={() => goToSchedaProdotto(dataMagazzino, "")}
            getDataMagazzino={() => filtered()}
            goToCrm={goToCrm}
          />
        </>
      )}

      {modalQuadrante.active && (
        <PopPupMessageInfoProdottoCard
          title={modalQuadrante.title}
          Misura={modalQuadrante.Misura}
          Colore={modalQuadrante.Colore}
          Movimento={modalQuadrante.Movimento}
          stateModal={modalQuadrante.active}
          setStateModal={setModalQuadrante}
        />
      )}

      <ModalOptionMatricola
        modalVisible={modalVisibleMatricolaCard}
        setModalVisible={setModalVisibleMatricolaCard}
        activeButton={activeBottom}
        navigation={navigation}
        goToMatricola={() => goToMatricola(dataMagazzino)}
        dataMatricola={listMatricola}
        dataMagazzino={dataMagazzino}
        goToCrm={goToCrm}
        dataCrm={dataCrm}
      />
      {modaleMessaggeWithUrl.active && (
        <PopUpMessageWithRoute
          dataNav={[]}
          title={modaleMessaggeWithUrl.title}
          message={modaleMessaggeWithUrl.message}
          stateModal={modaleMessaggeWithUrl.active}
          setStateModal={setmodaleMessaggeWithUrl}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};
