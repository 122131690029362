import React from "react";
import { Alert, Modal, StyleSheet, Text, Pressable, View } from "react-native";
interface IPopupConfirmation {
  title: string;
  message: string;
  confirmAction: () => void;
  active?: boolean;
  colorConfirmButton?: string;
  textConfirmButton?: string;
  stateModal?: any;
  setStateModal?: any;
}
const PopupConfirmation = ({
  title,
  message,
  confirmAction,
  stateModal,
  setStateModal,
  colorConfirmButton = "#519259",
  textConfirmButton = "Conferma",
}: IPopupConfirmation) => {
  return (
    <View style={styles.centeredView}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={stateModal}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setStateModal(!stateModal);
        }}
      >
        <View
          style={[styles.centeredView, { backgroundColor: "rgba(0,0,0,.32)" }]}
        >
          <View style={styles.modalView}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  fontWeight: "600",
                  fontSize: 16,
                  marginTop: 5,
                }}
              >
                {title}
              </Text>
              <Pressable onPress={() => setStateModal(!stateModal)}>
                <Text
                  style={[styles.right, { fontWeight: "bold", fontSize: 20 }]}
                >
                  {" "}
                  X
                </Text>
              </Pressable>
            </View>
            <View
              style={{
                marginTop: 20,
              }}
            >
              <Text style={styles.modalText}>{message}</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "flex-end",
                marginTop: 15,
              }}
            >
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => setStateModal(!stateModal)}
              >
                <Text style={[styles.textStyle, { color: "#191919" }]}>
                  Chiudi
                </Text>
              </Pressable>
              <Text> </Text>
              <Pressable
                style={[
                  styles.button,
                  styles.buttonConfirm,
                  { backgroundColor: colorConfirmButton },
                ]}
                onPress={() => confirmAction()}
              >
                <Text style={styles.textStyle}>{textConfirmButton}</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    paddingVertical: 15,
    paddingHorizontal: 20,
    width: 420,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonClose: {
    backgroundColor: "#FFF9F9",
  },
  buttonConfirm: {
    backgroundColor: "#FF5677",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  right: {
    alignSelf: "flex-end",
  },
});
export default PopupConfirmation;
