import { tableRow } from "../../GeneralStyles/Statistiche";
import { ResponseSale } from "../../interfaces/statistiche";
import Pagination from "../Pagination/pagination";
import { TableStatischeHeader } from "./TableStatischeHeader";
interface Props {
  sales: any;
  venditeTotali:number;
}
export const TableStatische = ({ sales,venditeTotali }: Props) => {
  console.log(sales)
  return (
    <div className="col-12 col-sm-8 col-md-9 col-lg-9">
      <div className="table-responsive">
        <table className="table table-borderless mt-3">
          <TableStatischeHeader />
          <tbody>
            {sales.length > 0 ? (
              <>
                {sales.filter(({idUser})=>idUser!=="185").map(
                  ({ idSale, nameUser, desc_bussines, prodottiVenduti }) => (
                    <tr key={Number(idSale)} style={{ borderBottom: "1px solid gray" }}>
                      <td
                        style={tableRow}
                        className="align-self-center mr-4 px-4"
                      >
                        {nameUser}
                      </td>
                      <td
                        style={{ ...tableRow, fontSize: 12 }}
                        className="align-self-center mr-4 px-4"
                      >
                        {desc_bussines}
                      </td>
                      <td
                        style={{ ...tableRow, fontSize: 12 }}
                        className="align-self-center mr-4 px-4"
                      >
                        {prodottiVenduti}
                      </td>
                    </tr>
                  )
                )}
              </>
            ) : undefined}
          </tbody>
          <tfoot>
            <tr style={{ borderBottom: "1px solid gray" }}>
              <td></td>
              <td style={tableRow}
                        className="align-self-center mr-4 px-4"
              >{venditeTotali?`Vendite totali: ${venditeTotali} `:""} </td>
              <td></td>
            </tr>
          </tfoot>
        </table>

        {/* {(sales.length > 0) && (
          <Pagination
              className="pagination-bar"
              currentPage={paginationListe.currentPage}
              totalCount={paginationListe.totalPages}
              pageSize={1}
              onPageChange={(page) => setPaginaActual(page)}
          />)} */}
      </div>
    </div>
  );
};
