export default class PaymentModel {
    idPayment: number = 0;
    idService: number = 0;
    idMethodPayment: number = 0;
    idStatusPay: number = 0;
    subtotal: number = 0;
    iva: number = 0;
    total: number = 0;
    active: boolean = true;
    paymentSerial: string = '';
    totalrow: number = 0;
    desc_TypesPayment: string = "";
    confirm: boolean = false;
    confirmPreventivo: boolean = false;
    nroPreventive: number = 0;
    clockFace: string = '';
    bracelet: string = '';
    conditionReciept: string = '';
    jobsRequired: string = '';
    recommended: string = '';
}