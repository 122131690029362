import React from 'react';
import {Text, Pressable, View} from 'react-native';
import {styles} from '../theme/GlobalStyle';
interface IHeaderModal {
  setModalVisible: any;
  modalVisible: boolean;
  title: string;
  flex?: boolean;
  activatedPadLeft?: boolean;
  styleCols?:Object;
  paddingRight?: any;
}
export const HeaderModal = ({
  setModalVisible,
  modalVisible,
  title,
  flex = true,
  activatedPadLeft = true, 
  paddingRight
}: IHeaderModal) => {
  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        paddingBottom: 5, 
        width: '-webkit-fill-available',
      }}
      className='row'
      >
      {flex ? (
        <div className='col-8 m-auto text-center'>
          <Text
            style={[             
              {
                fontSize: 20,
                fontWeight: '500',
                textAlign: "center",
                margin: 0,
                paddingVertical: 0,
                color: 'black',
              },               
            ]}>
            {title}
          </Text>
        </div>
      ) : (
        <div className='col-8 m-auto text-center'>
          <Text
            style={[ 
              {
                fontSize: 20,
                textAlign: "center",
                fontWeight: '500',
                margin: 0,
                paddingVertical: 0,
                color: 'black',
              },
            ]}>
            {title}
          </Text>
        </div>
      )}
      <div style={{position: "absolute", left: 165, top: 10}}>

      <Pressable onPress={() => setModalVisible(!modalVisible)}>
        <Text
          style={[
            styles.center,
            {
              fontWeight: 'bold',
              textAlign: "center",
              fontSize: 20,
              color: 'black',
              paddingRight: paddingRight && paddingRight
            },
          ]}>
          {' '}
          X
        </Text>
      </Pressable>
      </div>

    </div>
  );
};