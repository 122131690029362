
import React from 'react'

type NavProps = {
    sidebarVisible?:boolean
    toggleSidebar?:any
}

const ShowContentSidebar = ({sidebarVisible,toggleSidebar}:NavProps) => {

    return (
        <>
        {
            sidebarVisible
            &&
                <div className="d-lg-none" style={{zIndex: 1, position: 'fixed', paddingTop:13, cursor: 'pointer', right: 17}} onClick={toggleSidebar}>
                        <img src={require('../../assets/icons/close.png')} alt="" style={{width: 20}} />
                </div>
        }
        </>
    )
}

export default ShowContentSidebar