import React, { useEffect, useState } from "react";
import { MagazzinoResponse } from "../../interfaces/magazzino";
import Pagination from "../Pagination/pagination";
import { TableMagazzinoBody } from "./TableMagazzinoBody";
import { TableMagazzinoHeader } from "./TableMagazzinoHeader";
import ConfigCapone from "../../utils/ConfigCapone";
import "./Styles/grigliaStyle.css";
import { ModalMagazzinoGriglia } from "./ModalMagazzinoGriglia";
import { TouchableOpacity } from "react-native";
import { Image } from "react-native-elements";
import { stylesMagazzino } from "../../GeneralStyles/MagazzinoStyle";
import { useUserPermission } from "../../hooks/useUserPermission";
import { useWidth } from "../../core/hook/useWidth";

interface Props {
  data: MagazzinoResponse[];
  confirmToDelete: any;
  goToSchedaProdotto: any;
  goToSchedaQuadrante: any;
  dataCrm: any;
  goToMatricola: any;
  crmSend: any;
  paginationList: any;
  currentPage: any;
  setcurrentPage: any;
  limit: any;
  setResetCurrentPage: any;
  griglia: boolean;
  heightRisize: string;
}

export const TableMagazzino = ({
  data,
  confirmToDelete,
  goToSchedaProdotto,
  goToSchedaQuadrante,
  dataCrm,
  goToMatricola,
  crmSend,
  paginationList,
  currentPage,
  setcurrentPage,
  limit,
  setResetCurrentPage,
  griglia,
  heightRisize
}: Props) => {
  const [modalOpen, setmodalOpen] = useState(false);
  const [uri, seturi] = useState(``);
  const [title, settitle] = useState(``);
  const [heightCustom, setHeightCustom] = useState(`50vh`);
  const { height } = useWidth()

  const { isAdmin, isMarketing } = useUserPermission()

  function handleImageError(event) {
    event.currentTarget.src = require("../../assets/icons/defaultImage2.png");
  }

  return (
    <div className="col-12 col-sm-8 col-md-9 col-lg-10">

      {griglia ? <div className="card border-0" style={{ overflowX: 'hidden', maxHeight: heightRisize , scrollbarWidth: 'thin'}}>

        <div className="row d-flex justify-content-start">
          <ModalMagazzinoGriglia
            setmodalOpen={setmodalOpen}
            modalOpen={modalOpen}
            uri={uri}
            seturi={seturi}
            settitle={settitle}
            title={title}
          />
          {data
            ? data.map((D, index) => {
              return (
                <div className="card border-0 my-3 text-center headC col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                  <TouchableOpacity>
                    <div className="row d-flex justify-content-center">

                      <img
                        src={`${ConfigCapone.apicapone}/image/prodotto/${D.fileName}`}
                        alt="..."
                        className="col-10 img"
                        onError={handleImageError}
                        onClick={() => {
                          setmodalOpen(true);
                          seturi(
                            `${ConfigCapone.apicapone}/image/prodotto/${D.fileName}`
                          );
                          settitle(`${D.referencia} ${D.desc_brand}`);
                        }}
                      />

                      <div className="col-2 icons" id="icons">

                        {(isAdmin || isMarketing) && (
                          <img
                            src={require("../../assets/icons/icon-delete.png")}
                            onClick={() =>
                              confirmToDelete(D.idReference, D.idMagazzino)
                            }
                            alt="..."
                            className="icon"
                          />
                        )}

                        <img
                          src={require("../../assets/icons/icon-note.png")}
                          alt="..."
                          className="icon"
                          onClick={() =>
                            goToSchedaProdotto(data[index], "open")
                          }
                        />

                        <img
                          src={require("../../assets/icons/info.png")}
                          alt="..."
                          className="icon"
                          onClick={() =>
                            goToSchedaQuadrante(data[index], "open")
                          }
                        />


                      </div>
                    </div>
                  </TouchableOpacity>
                  <div className="card-body card2">
                    <p>
                      {D.referencia} {D.desc_brand}
                    </p>
                    <p>{D.desc_famiglia}</p>
                    <p>
                      <b>Qt:</b> {D.quantity}
                    </p>
                    <p>
                      <b>Messi da parte:</b> {D.putAsideCount}
                    </p>
                    <p>{D.desc_bussines}</p>
                    <p>€{D.prezzo}</p>
                  </div>
                </div>
              );
            })
            : null}
        </div>
      </div> :
        <div className="table-responsive" style={{maxHeight: heightRisize, scrollbarWidth: 'thin'}}>
          <table className="table table-borderless mt-3">

            <TableMagazzinoHeader />

            {data?.length > 0 &&
              <TableMagazzinoBody 
                data={data}
                confirmToDelete={confirmToDelete}
                goToSchedaProdotto={goToSchedaProdotto}
                goToSchedaQuadrante={goToSchedaQuadrante}
                dataCrm={dataCrm}
                goToMatricola={goToMatricola}
                crmSend={crmSend}
              />
            }

          </table>
        </div>}
        <div className="mt-2">
          {
            (paginationList.total > 0) && (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={paginationList.total}
                  pageSize={limit}
                  onPageChange={(page) => { setResetCurrentPage(true), setcurrentPage(page) }}
                />
              )
          }
        </div>

    </div>
  );
};
