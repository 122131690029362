import aplicationConnect from "../../../api/applicationConnect";
import axios from "axios";
import ConfigCapone from "../../../utils/ConfigCapone";

export const bussinesList = async () => {
  try {
    const response = await aplicationConnect.get(`Bussines?parameter=1`);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};

export const bussinesListByIdNew = async (id) => {

  try {
    const response = await aplicationConnect.get(`NewBussines?value=${id}&parameter=idNew`);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};


export const bussinesNewByIdUser = async (idUser: Number) => {
  try {
    const response = await aplicationConnect.get(`NewBussinesUsers/GetByIdUser`, {
      params: {
        idUser: idUser
      }
    });
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};
//userNewByIdUser
export const userNewByIdUser = async (idUser: Number) => {
  try {
    const response = await aplicationConnect.get(`NewUser/GetByIdUser`, {
      params: {
        idUser: idUser
      }
    });
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};

export const userListAll = async () => {
  try {
    const response = await aplicationConnect.get(`User?parameter=1`);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};

// user by role admin
export const userListRoleAdmin = async () => {
  try {
    const response = await aplicationConnect.get(`User?value=2&parameter=rl.idRole`);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};


export const userListByIdNew = async (id) => {
  
  try {
    const response = await aplicationConnect.get(`NewUser?value=${id}&parameter=idNew`);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};




export const usersNewsSelected = async (id) => {

  try {
    const response = await aplicationConnect.get(`NewUser/GetByIdNew/${id}`);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};





export const postNews = async (dataSend: any) => {
  
  
  try {
    const response = await aplicationConnect.post(`New`, dataSend);
    const { data } = response;
    if (data.status === "success") {
      return data.id;
    }

  } catch (error) {
    throw new Error();
  }
};

export const putNewsData = async (dataModifi) => {
 
  try {
    const response = await aplicationConnect.put(`New`, dataModifi);
    const { data } = response;
    
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};







export const deleteNewsData = async (id) => {
  
  deleteNewsDataBussines(id)
  deleteNewsDataUser(id)

  try {
    const response = await aplicationConnect.delete(`New/${id}`)
    
    const { data } = response;
    if (data.status === "success") {
      console.log("NEws eliminado")

      return data;

    }

  } catch (error) {

  }

}

export const deleteNewsDataBussines = async (id) => {
  
  
  try {
    const response = await aplicationConnect.delete(`NewBussines/${id}`)
    
    const { data } = response;
    
    if (data.status === "success") {
      console.log("NEwsBussines eliminado")

      return data;

    }

  } catch (error) {

  }

}

export const deleteNewsDataUser = async (id) => {
  
  try {
    const response = await aplicationConnect.delete(`NewUser/${id}`)
    
    const { data } = response;
    if (data.status === "success") {
      console.log("NEwsUser eliminado")

      return data;

    }

  } catch (error) {

  }

}





export const postNewBussinesService = async (dataSend: any) => {

  try {
    const response = await aplicationConnect.post(`NewBussines`, dataSend);
    const { data } = response;
    if (data.status === "success") {
      return data.id;
    }

  } catch (error) {
    throw new Error();
  }
};

export const deleteNewsBussinesId = async (idNew, idBussines) => {
  
  try {
    const response = await aplicationConnect.delete(`NewBussines/DeleteByIdNewAndIdBussines${idNew}/${idBussines}`)
  
    const { data } = response;
    if (data.status === "success") {
      console.log("NEwsUser eliminado")

      return data;

    }

  } catch (error) {

  }

}

export const deleteNewsIdUser = async (idNew, idUser) => {
 
  try {
    const response = await aplicationConnect.delete(`NewUser/DeleteByIdNewAndIdUser/${idNew}/${idUser}`)
   
    const { data } = response;
    if (data.status === "success") {
      console.log("NEwsUser eliminado")

      return data;

    }

  } catch (error) {

  }

}




//postNewUserService
export const postNewUserService = async (dataSend: any) => {
  
  try {
    const response = await aplicationConnect.post(`NewUser`, dataSend);
    const { data } = response;
    if (data.status === "success") {
      return data.id;
    }

  } catch (error) {
    throw new Error();
  }
};

// apiiiii newUserNotice
export const NewUserByIdNotice = async (idNewBussines: Number) => {
  try {
    const response = await aplicationConnect.get(`New`, {
      params: {
        idNewBussines: idNewBussines
      }
    });
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};


export const listByidNewBussines = async (idNewBussines: Number) => {
  try {
    const response = await aplicationConnect.get(`NewBussinesUsers/GetByIdNewBussines`, {
      params: {
        idNewBussines: idNewBussines
      }
    });
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};

export const listByidNewBussinesUsersCheck = async (idNew: Number) => {
  
  try {
    const response = await aplicationConnect.get(`NewUser/GetByIdNewChecked/${idNew}`)
    const { data } = response;
    
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};


export const putnewBussinesIsChecked = async (dataSend) => {
  
  const dataModify = {
    idNewBussinesUsers: Number(dataSend.idNewBussinesUsers),
    idNewBussines: Number(dataSend.idNewBussines),
    idUser: Number(dataSend.idUser),
    isChecked: 1
  }
  try {
    const response = await aplicationConnect.put(`NewBussinesUsers`, dataModify);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};




export const putNewUserIsChecked = async (idNewUser: number) => {
  
  try {
    const response = await aplicationConnect.put(`NewUser/isChecked?idNewUser=${idNewUser}&isChecked=1`);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};

export const putNewBussinesUserIsChecked = async (idNewUser: number) => {
  
  try {
    const response = await aplicationConnect.put(`NewBussinesUsers/isChecked/${idNewUser}/1`);
    const { data } = response;
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};

export const getPdf = async (name:string,ext:string) => {
  
  try {
    const response = await axios.get(
      `${ConfigCapone.urlapi}/api/UploadImage/${name}/${ext}`,
       
    );
    
    if (response.status == 200) {
      
      return response;
    }
  } catch (error) {
    
  }
};

export const getIdRoleData = async (iduser: number) => {
  
  try {
    const response = await aplicationConnect.get(`User/${iduser}`);
    const { data } = response;
    
    if (data.status === "success") {
      return data.response;
    }

  } catch (error) {
    throw new Error();
  }
};



