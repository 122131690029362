import React, { useContext, useEffect, useState } from "react";
import {
  SafeAreaView,
  StatusBar,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
} from "react-native";
import "bootstrap/dist/css/bootstrap.min.css";
import { HeaderBar } from "../components/HeaderBar";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { styles } from "../theme/GlobalStyle";
import { RootStackParams } from "../navigator/NavigatorConfig";
import {
  getAllServices,
  getBussiness,
  getUserFromId,
  IClient,
  IClientTable,
  IServiceClient,
  IUser,
} from "../api/ClientService";

import "../utils/styles.css";
import axios from "axios";
import { useIsFocused } from "@react-navigation/native";
import { SendNotificationScreen } from "./SendNotificationScreen";
import ConfigCapone from "../utils/ConfigCapone";
import Loading from "../components/Loading";
import PopupMessage from "../components/PopupMessage";
import SelectDropdown from "react-native-select-dropdown";
// @ts-ignore
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// @ts-ignore
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
import { todayFormatDate } from "../utils/helpers";
import { UserContext } from "../context/UserContext";
import RepairCardScreen from "./RepairCardScreen";
import { postNotify } from "../services/Notify";
import { useNotifyCrud } from "../hooks/useNotifyCrud";
import Cookies from 'universal-cookie';
import { useAuth } from "../hooks/useAuth";
import { useRepair } from "./hooks/useRepair";
import { getAllBussines } from "../services/interessiServices";
interface Props extends NativeStackScreenProps<RootStackParams, any> {
  isAtelierr?: boolean;
}
export const ProspectusRepairScreen = ({ navigation, isAtelierr = false }: Props) => {
  const { userInLogin } = useContext(UserContext);
  const { createdPosted } = useNotifyCrud();
  const [activeBottom, setActiveBottom] = useState<boolean>(
    (userInLogin?.idRole === "3" || userInLogin?.idRole === "7" || userInLogin?.idRole === "8") ? false : true
  );
  const [isEmployee, setIsEmployee] = useState<boolean>(
    userInLogin?.idRole === "4" ? true : false
  );
  const [backColorSelect, setBackColorSelect] = useState("white");
  const [toSearch, setToSearch] = useState<string>("");
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [number, setNumber] = useState(5);
  const [page, setPage] = useState(1);
  const [viewPagination, setViewPagination] = useState<boolean>(true);
  const [defaultBusiness, setDefaultBusiness] = useState("");
  const [blockRight, setBlockRight] = useState<boolean>(false);
  const [blockLeft, setBlockLeft] = useState<boolean>(true);
  const [navs, setNavs] = useState([]);
  const [itemsSelectBusiness, setItemsSelectBusiness] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [listUsers, setListUsers] = useState<any[]>();
  const [totalListUsers, setTotalListUsers] = useState<IClientTable[]>();
  const [userFromAPI, setUserFromAPI] = useState<IClientTable[]>([]);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [dataBussines, setDataBussines] = useState([]);
  const [apiBussiness, setApiBussiness] = useState([]);
  const [bussinesByRol, setBussinesByRol] = useState(false);
  const [arrayData, setArrayData] = useState<any>([]);
  const [activeSearch, setActiveSearch] = useState({ tipo: 0 });
  const [defaultBussines, setDefaultBussines] = useState();

  const [nameFileExport, setNameFileExport] = useState<any>(
    "Tabela Prospetto riparazioni" + todayFormatDate
  );
  const [tableCsv, setTableCsv] = useState<any>([]);
  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });
  const cookies = new Cookies();
  const [dataTableSend, setDataTableSend] = useState<any>();
  const [modalVisibleCard, setModalVisibleCard] = useState(false);
  const [dataRepair, setDataRepair] = useState<IServiceClient>();
  const [dataClient, setDataClient] = useState<any>();
  const [operatore, setOperatore] = useState<IUser>();
  const handleNextUsers = () => {
    let dataPagination = totalListUsers?.slice(number, number + 5);
    setListUsers(dataPagination);
    setNumber(number + 5);
    setBlockLeft(false);
    if (number + 5 >= (totalListUsers ? totalListUsers.length : 0)) {
      setBlockRight(true);
    }
  };
  const handlePreviousUsers = () => {
    let data = totalListUsers?.slice(number - 10, number - 5);
    setNumber(number - 5);
    setListUsers(data);
    setBlockRight(false);
    if (number - 5 <= 5) {
      setBlockLeft(true);
    }
  };
  const getAllBuss = async () => {
    const buss = await getAllBussines();
    return buss
  };
  const sendLogg = async (mesaggeN: string) => {
    let selectedMessage = {
      message: mesaggeN,
    };
    await axios
      .post(`${ConfigCapone.urlapi}/api/Notify/Logg/`, selectedMessage, {
        headers: {
          'Authorization': `Bearer ${cookies.get('token')}`
        }
      })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => console.log(error));
  };
  const sendLoggAll = async () => {
    sendLogg("Esportazione dei dati del modulo di Assitenza - Prospetto riparazioni")
    createdPosted({
      actionLog: "Esportare",
      message:
        "Esportazione dei dati del modulo di Assitenza - Prospetto riparazioni",
    })
  }
  const handleSearch = async () => {

    if (toSearch) {
      sendLogg(` ha cercatto ${toSearch} il assistenza-Prospetto riparazioni`)
      let client = await arrayData.filter((data: any) =>
        data.name.toLocaleLowerCase().includes(toSearch.toLocaleLowerCase())
      );

      let product = await arrayData.filter((data: any) =>
        data.desc_Product
          .toLocaleLowerCase()
          .includes(toSearch.toLocaleLowerCase())
      );

      let dateClient = await arrayData.filter((data: any) =>
        data.dateService
          .toLocaleLowerCase()
          .includes(toSearch.toLocaleLowerCase())
      );

      let finalConcat = client.concat(dateClient.concat(product));

      const uniqueArray = [...new Set(finalConcat)];

      if (uniqueArray.length === 0) {
        restartPaginationSearch(userFromAPI);
        setModalMessage({ active: true, message: "Dati non trovati" });
      } else {

        restartPaginationSearch(uniqueArray);
        setActiveSearch({ tipo: 2 });
      }
    } else {
      restartPaginationSearch(userFromAPI);
    }
  };

  const handleSearchSelect = (selectedItem: string) => {
    if (selectedItem === "") {
      restartPaginationSearch(userFromAPI);
    } else {
      let newData = userFromAPI.filter(
        (data: any) => data.desc_Bussines === selectedItem
      );
      if (activeSearch.tipo == 2) {
        newData = arrayData.filter(
          (data: any) => data.desc_Bussines === selectedItem
        );
      }
      restartPaginationSearch(newData);
    }
    setActiveSearch({ tipo: 1 });
  };

  let numPages = 0;
  const loadNumberPages = (pages: number) => {
    numPages = pages;
    let navsAux: any = [];
    for (let i = 0; i < pages; i++) {
      navsAux.push(
        <TouchableOpacity key={i} onPress={() => handleViewPage(i + 1)}>
          <View>
            <Text
              style={{
                fontWeight: "400",
                color: "black",
                fontSize: 14,
                paddingHorizontal: 10,
              }}
            >
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>»</Text>
              )}
              {i + 1}
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>«</Text>
              )}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }
    setNavs(navsAux);
  };
  let dataAux: any = [];
  const handleViewPage = (page: number) => {
    setPage(page);
    const newPage = page * 5;
    if (totalListUsers) {
      if (totalListUsers.length > 0) {
        setListUsers(totalListUsers?.slice(page * 5 - 5, page * 5));
      } else {
        setListUsers(dataAux.slice(page * 5 - 5, page * 5));
      }
    } else {
      setListUsers(dataAux.slice(page * 5 - 5, page * 5));
    }
    setNumber(newPage);
    setBlockRight(false);
    setBlockLeft(true);
    if (page > 1) {
      setBlockLeft(false);
    }
    if (navs.length == 0) {
      if (page == numPages) {
        setBlockRight(true);
      }
    } else {
      if (page == navs.length) {
        setBlockRight(true);
      }
    }
  };

  const handleSearchBySelect = (selectedItem: string, data = []) => {
    setDefaultBusiness(selectedItem)
    console.log('userInLogin', selectedItem)
    if (selectedItem === "") {
      restartPaginationSearch(userFromAPI);
    } else {
      let newData: any;
      if (data.length == 0) {
        newData = userFromAPI.filter(
          (data: any) => data.desc_Bussines === selectedItem
        );
      } else {
        newData = data.filter(
          (data: any) => data.desc_Bussines === selectedItem
        );
      }
      console.log('userInLogin', newData)
      if (activeSearch.tipo == 2) {
        newData = arrayData.filter(
          (data: any) => data.desc_Bussines === selectedItem
        );
      }
      restartPaginationSearch(newData);
    }
    setActiveSearch({ tipo: 1 });
  };

  const handleViewPageFilter = (page: number, response: any) => {
    setPage(page);
    let resp = response.filter((data: IClientTable) => data.idStatus === "5");

    const newPage = page * 5;
    if (resp) {
      if (resp.length > 0) {
        setListUsers(resp?.slice(page * 5 - 5, page * 5));
      } else {
        setListUsers(resp.slice(page * 5 - 5, page * 5));
      }
    }
    setNumber(newPage);
    setBlockRight(false);
    setBlockLeft(true);
    if (page > 1) {
      setBlockLeft(false);
    }
    if (navs.length == 0) {
      if (page == numPages) {
        setBlockRight(true);
      }
    } else {
      if (page == navs.length) {
        setBlockRight(true);
      }
    }
  };
  const selectBussiness = async () => {
    setActiveSpinner(true);
    await axios
      .get(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`)
      .then((response) => {
        setApiBussiness(response.data.response);
        setBussinesByRol(true)
        console.log('userInLogin0', response.data.response)
        if (isEmployee) {
          let defaultBusiness = response.data.response.filter(
            (data: any) => data.idBussines == userInLogin.idBussines
          );
          setDefaultBusiness(defaultBusiness[0].desc_Bussines);
        }
      })
      .catch((err) => console.log(err));
    setActiveSpinner(false);
  };
  useEffect(() => {
    loadNumberPages(
      Math.ceil((totalListUsers ? totalListUsers.length : 0) / 5)
    );
  }, [number]);
  const isFocused = useIsFocused();
  useEffect(() => {
    setActiveSpinner(true);
    selectBussiness();
    if (isEmployee) {
      axios
        .get(
          `${ConfigCapone.urlapi}/api/Client/GetClientTableV2?idBussines=${userInLogin.idBussines}`
        )
        .then((response) => {
          console.log(response.data.response);
          restartPagination(response.data.response);
        });
    } else {
      getAllServices().then((resp) => {
        console.log('userInLogin', resp)
        restartPagination(resp);
      });
    }
    setActiveSpinner(false);
  }, [isFocused]);


  const restartPagination = async (response: IClientTable[]) => {

    //let resp = response.filter((data: IClientTable) => data.idStatus === "5" || (data.idStatus==="6" && data.AutoSwitchNoAccettato ==="1"));
    let resp = response.filter((data: IClientTable) => data.idStatus === "5" || (data.idStatus === "6" && data.autoSwitchNoAccettato === "1") || (data.idStatus === "7" && data.autoSwitchNoAccettato === "1"));
    setTableCsv(resp);

    dataAux = resp;
    setTotalListUsers(resp);
    setUserFromAPI(resp);
    setArrayData(resp)
    setListUsers(resp.slice(0, 5));
    const numberPages = Math.ceil(resp.length / 5);
    loadNumberPages(numberPages);
    setViewPagination(true);
    setBlockLeft(true);
    setBlockRight(false);
    setNumber(5);
    if (resp.length <= 5) {
      setViewPagination(false);
    } else {
      setViewPagination(true);
    }
    if (userInLogin.idRole != '2') {
      let bussines = await getAllBuss()
      bussines = bussines?.find(x => Number(x.idBussines) == Number(userInLogin.idBussines))
      handleSearchBySelect(bussines.desc_Bussines, resp)
    }
  };
  const restartPaginationFilter = (response: IClientTable[]) => {
    let resp = response.filter((data: IClientTable) => data.idStatus === "5");
    setTotalListUsers(resp);
    setUserFromAPI(resp);
    setArrayData(resp)
    setListUsers(resp);
    const numberPages = Math.ceil(resp.length / 5);
    loadNumberPages(numberPages);
    setViewPagination(true);
    setBlockLeft(true);
    setBlockRight(false);
    setNumber(5);
    if (resp.length <= 5) {
      setViewPagination(false);
    } else {
      setViewPagination(true);
    }
  };
  const goToEdit = async (data: any) => {
    let dataService = await axios
      .get(`${ConfigCapone.urlapi}/api/Service/${data.idService}`)
      .then((response) => {
        return response.data.response;
      })
      .catch((err) => console.log(err));
    let dataClient = await axios
      .get(
        `${ConfigCapone.urlapi}/api/Client?value=${data.idClient}&parameter=idClient`
      )
      .then((response) => {
        return response.data.response[0];
      })
      .catch((err) => console.log(err));
    let brandDefault = await axios
      .get(`${ConfigCapone.urlapi}/api/Brand/${dataService.idBrand}`)
      .then((response) => response.data.response);
    let bussinesDefault = await axios
      .get(`${ConfigCapone.urlapi}/api/Bussines/${dataService.idBussines}`)
      .then((response) => response.data.response);
    let clientResult = await axios
      .get(`${ConfigCapone.urlapi}/api/Client/${data.idClient}`)
      .then((response) => response.data.response.idTitle);
    let titleDefault = await axios
      .get(`${ConfigCapone.urlapi}/api/Title/${clientResult}`)
      .then((response) => response.data.response);
    let dataJoin = await {
      ...dataClient,
      ...dataService,
      brandDefault,
      bussinesDefault,
      titleDefault,
      tipo: "Pospectus",
    };
    await navigation.navigate("FormRepairScreen", dataJoin);
  };
  const handleViewPageSearch = (page: number, resp: []) => {
    const newPage = page * 5;
    if (resp.length > 0) {
      setListUsers(resp?.slice(page * 5 - 5, page * 5));
    } else {
      setListUsers(resp?.slice(page * 5 - 5, page * 5));
    }
    setNumber(newPage);
    setBlockRight(false);
    setBlockLeft(true);
    if (page > 1) {
      setBlockLeft(false);
    }
    if (navs.length == 0) {
      if (page == numPages) {
        setBlockRight(true);
      }
    } else {
      if (page == navs.length) {
        setBlockRight(true);
      }
    }
  };
  const loadNumberPagesSearch = (pages: number, resp: []) => {
    numPages = pages;
    let navsAux: any = [];
    for (let i = 0; i < pages; i++) {
      navsAux.push(
        <TouchableOpacity
          key={i}
          onPress={() => handleViewPageSearch(i + 1, resp)}
        >
          <View>
            <Text
              style={{
                fontWeight: "400",
                color: "black",
                fontSize: 14,
                paddingHorizontal: 10,
              }}
            >
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>»</Text>
              )}
              {i + 1}
              {number / 5 == i + 1 && (
                <Text style={{ fontWeight: "bold" }}>«</Text>
              )}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }
    setNavs(navsAux);
  };
  const goToRepairTab = async (data: any) => {
    setActiveSpinner(true);
    setDataTableSend(data);

    let dataService = await axios
      .get(`${ConfigCapone.urlapi}/api/Service/${data.idService}`)
      .then((response) => {
        return response.data.response;
      })
      .catch((err) => console.log(err));

    let dataClient: IClient = await axios
      .get(
        `${ConfigCapone.urlapi}/api/Client?value=${data.idClient}&parameter=idClient`
      )
      .then((response) => {
        return response.data.response[0];
      })
      .catch((err) => console.log(err));

    console.log('prospectus', dataService.idUser);

    await getUserFromId(dataService.idUser).then((result: IUser) =>
      setOperatore(result)
    );
    let dataToNewRepair = await loadDataForUpdate(data);
    setDataRepair(dataService);
    setDataClient(dataToNewRepair);
    setModalVisibleCard(true);
    setActiveSpinner(false);
  };

  const loadDataForUpdate = async (data: any) => {
    let dataService = await axios
      .get(`${ConfigCapone.urlapi}/api/Service/${data.idService}`)
      .then((response) => {
        return response.data.response;
      })
      .catch((err) => console.log(err));
    let dataClient = await axios
      .get(
        `${ConfigCapone.urlapi}/api/Client?value=${data.idClient}&parameter=idClient`
      )
      .then((response) => {
        return response.data.response[0];
      })
      .catch((err) => console.log(err));
    let brandDefault = await axios
      .get(`${ConfigCapone.urlapi}/api/Brand/${dataService.idBrand}`)
      .then((response) => response.data.response);
    let bussinesDefault = await axios
      .get(`${ConfigCapone.urlapi}/api/Bussines/${dataService.idBussines}`)
      .then((response) => response.data.response);
    let clientResult = await axios
      .get(`${ConfigCapone.urlapi}/api/Client/${data.idClient}`)
      .then((response) => response.data.response.idTitle);
    let titleDefault = await axios
      .get(`${ConfigCapone.urlapi}/api/Title/${clientResult}`)
      .then((response) => response.data.response);
    let dataJoin = await {
      ...dataClient,
      ...dataService,
      brandDefault,
      bussinesDefault,
      titleDefault,
      tipo: "StateRepair",
    };

    return dataJoin;
    // await navigation.navigate("FormRepairScreen", dataJoin);
  };
  const restartPaginationSearch = (resp: any) => {
    dataAux = arrayData;
    setTableCsv(resp);
    setTotalListUsers(resp);
    setArrayData(resp);
    setListUsers(resp.slice(0, 5));
    const numberPages = Math.ceil(resp.length / 5);
    loadNumberPagesSearch(numberPages, resp);
    setViewPagination(true);
    setBlockLeft(true);
    setBlockRight(false);
    setNumber(5);
    if (resp.length <= 5) {
      setViewPagination(false);
    } else {
      setViewPagination(true);
    }
  };
  const modalAvi = (data: any) => {
    setDataBussines(data);
    setModalVisible(true);
  };


  console.log("list users: ", listUsers);


  const DataSet = [
    {
      columns: [
        { title: "Cliente", style: { font: { sz: "18", bold: true } } },
        { title: "Orologio", style: { font: { sz: "18", bold: true } } },
        { title: "Data", style: { font: { sz: "18", bold: true } } },
        { title: "Negozio", style: { font: { sz: "18", bold: true } } },
      ],
      data:
        tableCsv &&
        tableCsv.map((data: any) => [
          { value: data.name, style: { font: { sz: "14", bold: false } } },
          {
            value: data.desc_Product ? data.desc_Product : "N/D",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.dateService
              ? data.dateService
                .split("T")[0]
                .toString()
                .split("-")
                .reverse()
                .join("/")
              : "N/D",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.desc_Bussines ? data.desc_Bussines : "N/D",
            style: { font: { sz: "14", bold: false } },
          },
        ]),
    },
  ];

  return (
    <ScrollView style={styles.scroll}>
      {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: StatusBar.currentHeight,
          backgroundColor: "white",
        }}
      >
        {!isAtelierr &&
          <View
            style={{
              paddingHorizontal: 20,
            }}
          >
            <HeaderBar
              title={"Prospetto riparazioni"}
              onBack={() => navigation.navigate("HomeScreen")}
              iconHeader={require("../assets/icons/list.png")}
            />
          </View>
        }
        <View
          style={{
            flex: 1,
            paddingHorizontal: 20,
            paddingVertical: 20,
          }}
        >
          <div
            className="row"
            style={{
              paddingRight: 12,
              paddingLeft: 12,
            }}
          >
            <div className="col-xl-6 col-lg-5 col-md-2 col-sm-0 p-0"></div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-7 p-0">
              <Text
                style={{
                  marginTop: 10,
                  fontWeight: "500",
                  fontSize: 20,
                  marginRight: 30,
                  alignSelf: "center",
                  marginBottom: "5%",
                }}
              >
                Riparazioni completate
              </Text>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 p-0 d-flex justify-content-center">
              <TextInput
                placeholder="Cerca cliente..."
                style={[
                  stylesRepair.input,
                  {
                    alignSelf: "flex-end",
                    paddingRight: 2,
                    paddingLeft: 15,
                    fontSize: 20,
                    borderColor: "#A4A4A4",
                  },
                ]}
                onChangeText={(text) => setToSearch(text)}
              />
              <TouchableOpacity
                onPress={handleSearch}
                style={{
                  marginTop: 7,
                  paddingLeft: 3,
                }}
              >
                <View
                  style={{
                    backgroundColor: "black",
                    borderRadius: 50,
                    justifyContent: "center",
                    width: 40,
                    height: 40,
                  }}
                >
                  <Image
                    style={{
                      width: 20,
                      height: 20,
                      tintColor: "white",
                      alignSelf: "center",
                    }}
                    source={require("../assets/icons/icon-search.png")}
                  />
                </View>
              </TouchableOpacity>
            </div>
          </div>
          <div
            className="row mt-2"
            style={{
              paddingRight: 12,
              paddingLeft: 12,
            }}
          >
            <div className="col-xl-6 col-lg-5 col-md-2 col-sm-0 p-0"></div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-7 p-0">
              <div className="mt-3">
                <Text
                  style={[
                    {
                      alignContent: "center",
                      marginTop: 7,
                      fontWeight: "500",
                      fontSize: 20,
                    },
                  ]}
                >
                  Negozio
                </Text>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 p-0">
              {isEmployee ? (
                <select
                  className=" mt-2"
                  disabled={isEmployee}
                  value={defaultBusiness}
                  style={{
                    alignSelf: "flex-end",
                    borderColor: "#A4A4A4",
                    paddingRight: 2,
                    paddingLeft: 15,
                    fontSize: 20,
                    width: "100%",
                    backgroundColor: "#EAEAEA",
                    height: 43,
                    margin: 4,
                    borderWidth: 1,
                    borderRadius: 50,
                    fontFamily: "Arsapia Regular font",
                    color: "#757575",
                  }}
                  onChange={(e) => handleSearchBySelect(e.target.value)}
                  aria-label=".form-select "
                >
                  {apiBussiness.map((d: any) => (
                    <option key={Number(d.idBussines)} value={d.desc_Bussines}>
                      {d.desc_Bussines}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  className=" mt-2"
                  style={{
                    alignSelf: "flex-end",
                    borderColor: "#A4A4A4",
                    paddingRight: 2,
                    paddingLeft: 15,
                    fontSize: 20,
                    width: "100%",
                    backgroundColor: "transparent",
                    height: 43,
                    margin: 4,
                    borderWidth: 1,
                    borderRadius: 50,
                    fontFamily: "Arsapia Regular font",
                    color: "#757575",
                  }}
                  onChange={(e) => handleSearchBySelect(e.target.value)}
                  aria-label=".form-select "
                  value={defaultBusiness}
                >
                  <option value={""} selected>
                    Seleziona
                  </option>
                  {apiBussiness.map((d: any) => (
                    <option key={Number(d.idBussines)} value={d.desc_Bussines}>
                      {d.desc_Bussines}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          <div className="table-responsive">
            <ExcelFile
              filename={nameFileExport}
              element={
                <button
                  onClick={() => sendLoggAll()
                  }
                  type="button"
                  className="dowloandPdf"
                >
                  Esporta
                </button>
              }
            >
              <ExcelSheet dataSet={DataSet} name="pruen 1 reporte" />
            </ExcelFile>

            <table className="table table-borderless mt-3">
              <thead
                style={{ backgroundColor: "black", color: "white", height: 50 }}
              >
                <tr className="mr-4">
                  <th
                    style={{
                      borderTopLeftRadius: 50,
                      borderBottomLeftRadius: 50,
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  ></th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      verticalAlign: "middle",
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  ></th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      verticalAlign: "middle",
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  >
                    Cliente
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      verticalAlign: "middle",
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  >
                    Orologio
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      verticalAlign: "middle",
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  >
                    Data
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      verticalAlign: "middle",
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  >
                    Data conclusione
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "400",
                      verticalAlign: "middle",
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  >
                    Negozio
                  </th>
                  <th
                    style={{
                      borderTopRightRadius: 50,
                      borderBottomRightRadius: 50,
                    }}
                    scope="col"
                    className="align-self-center mr-4 px-4"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {listUsers &&
                  listUsers.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid gray" }}
                      >
                        <td></td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          <TouchableOpacity
                            style={{
                              flex: 1,
                              paddingHorizontal: 5,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onPress={() => goToRepairTab(data)}
                          >
                            <Image
                              style={{ width: 30, height: 30 }}
                              source={require("../assets/icons/icon-note.png")}
                            />
                          </TouchableOpacity>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          {data.hasNotification ? (
                            data.name
                          ) : (
                            <b>{data.name}</b>
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          {data.desc_Product
                            ? data.desc_Product
                            : "Nessun dato"}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="align-self-center mr-4 px-4"
                        >
                          {data.dateService
                            .split("T")[0]
                            .toString()
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="align-self-center mr-4 px-4"
                        >
                          {((data.idStatus === "6" || data.idStatus === "7") && data.autoSwitchNoAccettato == "1") ? data.desc_Status : data.dateInsert
                            .split(" ")[0]
                            .split("/")[1]
                            .toString() +
                            "/" +
                            data.dateInsert
                              .split(" ")[0]
                              .split("/")[0]
                              .toString() +
                            "/" +
                            data.dateInsert
                              .split(" ")[0]
                              .split("/")[2]
                              .toString()}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="align-self-center mr-4 px-4"
                        >
                          {data.desc_Bussines
                            ? data.desc_Bussines
                            : "Nessun dato"}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="align-self-center mr-4 px-4"
                        >
                          <button
                            style={{
                              flex: 1,
                              width: 90,
                              height: 40,
                              alignSelf: "center",
                              backgroundColor: "transparent",
                              borderRadius: 50,
                              borderColor: "black",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              modalAvi(data);
                            }}
                          >
                            Avvisa
                          </button>
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {viewPagination && (
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="table-responsive">
                  <View style={{ flexDirection: "row", marginTop: 15 }}>
                    {!blockLeft && (
                      <TouchableOpacity onPress={handlePreviousUsers}>
                        <View>
                          <Image
                            style={{
                              width: 15,
                              marginRight: 30,
                              height: 15,
                              tintColor: "rgb(200,200,200)",
                            }}
                            source={require("../assets/icons/icon-left.png")}
                          />
                        </View>
                      </TouchableOpacity>
                    )}
                    {navs}
                    {!blockRight && (
                      <TouchableOpacity onPress={handleNextUsers}>
                        <View>
                          <Image
                            style={{
                              width: 15,
                              marginLeft: 30,
                              height: 15,
                              tintColor: "rgb(200,200,200)",
                            }}
                            source={require("../assets/icons/icon-right.png")}
                          />
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                </div>
              </div>
            </div>
          )}
          {modalVisible && (
            <SendNotificationScreen
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              clientData={dataBussines}
              currentPage="prospetto_riparazioni"
              page={page}
              setPage={page}
              handleViewPageFilter={handleViewPageFilter}
              restartPaginationFilter={restartPaginationFilter}
              setListUsers={setListUsers}
              listUsers={listUsers}
            />
          )}
          {modalMessage.active && (
            <PopupMessage
              title={"Informazione"}
              message={modalMessage.message}
              stateModal={modalMessage.active}
              setStateModal={setModalMessage}
            />
          )}
          {modalVisibleCard && (
            <RepairCardScreen
              modalVisible={modalVisibleCard}
              setModalVisible={setModalVisibleCard}
              dataClient={dataClient}
              dataService={dataRepair}
              operatore={operatore}
              navigation={navigation}
              activeButton={activeBottom}
              dataTableSend={dataTableSend}
              isAtelier={isAtelierr}
            />
          )}
        </View>
      </SafeAreaView>
    </ScrollView>
  );
};
const stylesRepair = StyleSheet.create({
  input: {
    height: 45,
    width: "100%",
    borderWidth: 2,
    fontFamily: "Arsapia Regular font",
    padding: 12,
    borderRadius: 50,
    marginVertical: 6,
  },
  picker: {
    height: 40,
    width: "100%",
    alignSelf: "flex-end",
    fontFamily: "Arsapia Regular font",
    borderWidth: 2,
    paddingHorizontal: 10,
    borderRadius: 50,
    marginVertical: 6,
  },
  statePicker: {
    height: 20,
    width: 85,
    margin: 12,
    fontSize: 10,
    alignSelf: "center",
    borderWidth: 2,
    paddingHorizontal: 10,
    borderRadius: 50,
    marginVertical: 6,
    borderColor: "white",
  },
  dropdown1BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontSize: 16,
    fontFamily: "Arsapia Regular font",
  },
  dropdown1RowStyle: {
    backgroundColor: "#EFEFEF",
    borderBottomColor: "#C5C5C5",
  },
  dropdown1RowTxtStyle: {
    color: "#444",
    fontSize: 16,
    fontFamily: "Arsapia Regular font",
    paddingVertical: 8,
    paddingTop: 12,
    textAlign: "left",
  },
});
