import { useNavigation } from "@react-navigation/native";
import { NavigationProps } from "../../navigator/NavigatorConfig";

interface Props {
  label: string;
  date: Date;
  isOffRange: boolean;
}

export const Row = ({ date, isOffRange, label }: Props) => {
  if (isOffRange) return <></>;
  const navigation = useNavigation<NavigationProps>();

  return (
    <div
      role="button"
      className="d-flex justify-content-center"
      onClick={() =>
        navigation.navigate("CalenderDetailScreen", {
          date: date.getTime().toString(),
        })
      }
    >
      <span className="now-time">{label}</span>
    </div>  
  );
};

