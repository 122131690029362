import {
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { HeaderModal } from "../HeaderModal";
import { styles } from "../../theme/GlobalStyle";
import { CheckLabel } from "../CheckLabel";
import ModalOptionSchemaProdotto from "./ModalOptionSchemaProdotto";
import { useEffect, useState } from "react";
import ModalOptionSpostamentoNegozio from "./ModalOptionSpostamentoNegozio";
import ModalOptionClienteEsistente from "./ModalOptionClienteEsistente";
import ConfigCapone from "../../utils/ConfigCapone";
import axios from "axios";

import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import Loading from "../Loading";
import PopPupMessageInfoProdottoCard from "../PopPupMessageInfoProdottoCard";


interface ISchemaProdottoCard {
  modalVisible: any;
  setModalVisible: any;
  activeButton?: any;
  dataMagazzino: any | undefined;
  dataMatricola: any | undefined;
  searchModalInputClient?: any;
  setSearchModalInputClient?: any;
  findReference?: any;
  setFindReference?: any;
  handleSearchModalFilter?: () => void,
  dataClient?: any,
  listMagazzino?: any,
  setDataClient?: any,
  autosuggest?: any,
  setAutosuggest?: any,
  goToSchedaProdotto?: any,
  getDataMagazzino?: () => void,
  navigation?: any
  goToCrm?: any
  dataCrm?: any
}

const SchemaProdottoCard = ({
  modalVisible = false,
  setModalVisible,
  activeButton = true,
  dataMagazzino,
  dataMatricola,
  searchModalInputClient,
  setSearchModalInputClient,
  findReference,
  setFindReference,
  handleSearchModalFilter,
  dataClient,
  listMagazzino,
  setDataClient,
  autosuggest,
  setAutosuggest,
  goToSchedaProdotto,
  getDataMagazzino,
  navigation,
  goToCrm,
  dataCrm
}: ISchemaProdottoCard) => {

  // console.log("--- dataMagazzino ---");
  // console.log(dataMagazzino);

  const { userInLogin } = useContext(UserContext);
  const [modalVisibleCard, setModalVisibleCard] = useState(false);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [modalSpostamentoNegozioVisibleCard, setModalSpostamentoNegozioVisibleCard] = useState(false);
  const [modalOptionClienteEsistente, setModalOptionClienteEsistente] = useState(false);
  const [activeBottom, setActiveBottom] = useState<boolean>(true);
  const [search, setSearch] = useState<string>();
  const [checked, setCheckBoxChecked] = useState("");
  const [typeMatricola, setTypeMatricola] = useState("");
  const [matricola, setMatricola] = useState("");
  const [idMatricola, setIdMatricola] = useState("");
  const [idBussinessSeller, setIdBussinessSeller] = useState("");
  const [idR, setIdR] = useState<any>();
  const [modaleMessaggeInfo, setmodaleMessaggeInfo] = useState({
    title: "Informazione",
    Misura: "",
    Colore: "",
    Movimento: "",
    active: false,
  });

  let existsIncrement: any;

  const goToEdit = async (dataMagazzino: any, dataMatricola: any) => {
    setModalVisible(false);
    await navigation.navigate("MagazzinoAddScreen", { dataMagazzino, dataMatricola });
  }

  const changeListMatricola = (type: string, value: any, id: any, matricola?: any) => {

    setCheckBoxChecked(value);
    setTypeMatricola(type);
    setIdMatricola(id);

    let checkMagazzino: any = document.getElementsByClassName('checksMagazzino');
    let checkDobles: any = document.getElementsByClassName('checkDoble');
    let countOne = 0;

    //check for only one
    for (let i = 0; i < checkMagazzino.length; i++) {
      if (checkMagazzino[i].checked) {
        countOne++;
      }
    }

    // console.log(countOne);

    // if(countOne > 0){
    //   for(let i=0; i<checkDobles.length; i++){
    //     checkDobles[i].checked = false;
    //   }
    // }

    if (matricola) {
      setMatricola(matricola)
    }

    if (value) {
      if (checked == value) {
        setCheckBoxChecked(null)
      }
    }

  }
  const changeListOnline = (type: string, value: any, id: any, matricola?: any) => {

    // console.log(value);

    let checkDobles: any = document.getElementsByClassName('checkDoble');
    let checkMagazzino: any = document.getElementsByClassName('checksMagazzino');

    //check for double
    let count = 0;

    for (let i = 0; i < checkDobles.length; i++) {
      // console.log(checkDobles[i].checked);
      if (checkDobles[i].checked) {
        count++;
      }
    }

    // console.log(count);

    // if(count == 3){
    //   for(let i=0; i<checkMagazzino.length; i++){
    //     checkMagazzino[i].disabled = true;
    //   }
    // }else{
    //   for(let i=0; i<checkMagazzino.length; i++){
    //     checkMagazzino[i].disabled = false;
    //   }
    // }

    setCheckBoxChecked(value);
    setTypeMatricola(type);
    setIdMatricola(id);

    if (matricola) {
      setMatricola(matricola)
    }

    if (value) {
      if (checked == value) {
        setCheckBoxChecked(null)
      }
    }

  }

  const valueOldReference: any = (id: any) => {
    setIdR(id)
    existsIncrement = id
    return id
  }

  useEffect(() => {
    valueOldReference(idR)
  }, [idR])


  const updateStateMatricole = async (id: any, value: any, type: any, idClient: any) => {

    // setActiveSpinner(true)

    await axios.put(`${ConfigCapone.urlapi}/api/Matricola/UpdateOptions/${id}/${value}/${type}/${idClient}`)
      .then((response) => {
        console.log(response);
      })

    // console.log("data : ", dataMagazzino, "idMatricola: ", id);


    if (type == "PutMoveBusiness" || type == "PutSale" || type == "PutSaleNewCustomer" || type == "PutSaleExistingCustomer") {

      if (type == "PutSale" || type == "PutSaleExistingCustomer") {

        let online: any = false;
        let brand: any;
        let mat: any;
        await axios
          .get(`${ConfigCapone.urlapi}/api/Matricola/${id}`)
          .then((response) => {
            online = response.data.response?.putOnline;
            brand = response.data.response?.marca;
            mat = response.data.response?.matricola;
            // console.log("online: ", response.data.response);                      
          })
          .catch((error) => {
            console.log("error -> ", error);

          });

        // await sendEmail("Nuova Vendita",id)

        if (online) {
          sendEmail("Nuova Vendita", id)
        }

      }

      if (existsIncrement) {
        // console.log("Si");
        //console.log("max",existsIncrement);

        //console.log("max", listMagazzino);


        let data: any = listMagazzino.find((data: any) => data.idReference === existsIncrement)

        let quantityDecrement = dataMagazzino?.quantity ? dataMagazzino?.quantity - 1 : 0;
        let quantityIncrement = parseInt(data?.quantity) + 1;


        // console.log("max", data);



        await axios.put(`${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${dataMagazzino.idMagazzino}/${quantityDecrement}/0`)
          .then((response) => {
            console.log("data:", response);
          })
        await axios.put(`${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${data.idMagazzino}/${quantityIncrement}/0`)
          .then((response) => {
            // console.log("data:",response); 
            // console.log("success1");

            getDataMagazzino();
            dataMagazzino.quantity = quantityDecrement;
            goToSchedaProdotto(dataMagazzino);
          })

        // setActiveSpinner(false)

        // if(quantityDecrement===0){
        //   await axios.delete(`${ConfigCapone.urlapi}/api/Magazzino/${dataMagazzino.idMagazzino}`)
        //   .then((response) => {
        //     console.log("data:",response);          
        //   })
        // }

      } else {
        // console.log("No");
        // console.log(existsIncrement);

        let quantity = dataMagazzino?.quantity ? dataMagazzino?.quantity - 1 : 0;
        await axios.put(`${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${dataMagazzino.idMagazzino}/${quantity}/0`)
          .then((response) => {
            // console.log("data:",response);          
            getDataMagazzino();
            dataMagazzino.quantity = quantity;
            goToSchedaProdotto(dataMagazzino);
            // setActiveSpinner(false)
          })
      }


    } else {
      goToSchedaProdotto();
      // setActiveSpinner(false)
    }

    // setActiveSpinner(false)

  }

  const navegationMatricola = async (option: number, value: any, id: any) => {

    let matricola: any;

    setModalVisible(false)

    await axios
      .get(`${ConfigCapone.urlapi}/api/Matricola/${idMatricola}`)
      .then((response) => {
        if (response.data.status != "empty") {
          matricola = response.data.response.matricola
        }
      })
      .catch((error) => {
        console.log("error");
      })


    let magazzino = { ...dataMagazzino, matricola: id + "-" + matricola }

    // console.log("magazzino : ", magazzino);

    setCheckBoxChecked(value);

    if (value) {
      if (checked == value) {
        setCheckBoxChecked(null)
      }
      if (option) {
        goToCrm(magazzino, option)
      }
    }

  }

  useEffect(() => {
    // console.log("1",dataMagazzino);
    // console.log("is checked: "+checked);
    setIdBussinessSeller(dataMagazzino)
    switch (typeMatricola) {
      case 'PutMoveBusiness':
        setModalSpostamentoNegozioVisibleCard(checked ? true : false);
        break;
      case 'PutAside':
        if (checked) {
          sendEmail("Messo da parte", idMatricola)
        } else {
          sendEmail("Rimosso da: Messo da parte", idMatricola, true)
        }
        updateStateMatricole(idMatricola, checked ? true : false, typeMatricola, 0);
        break;
      case 'PutSale':
        setModalVisibleCard(checked ? true : false);
        break;
      case 'PutSaleNewCustomer':
        navegationMatricola(3, checked ? true : false, idMatricola)
        // updateStateMatricole(idMatricola, checked ? true : false, typeMatricola, 0);

        break;
      case 'PutSaleExistingCustomer':
        setModalOptionClienteEsistente(checked ? true : false);
        break;
      case 'PutOnline':

        // console.log(idMatricola, checked ? "true" : "false", typeMatricola);

        updateStateMatricole(idMatricola, checked ? true : false, typeMatricola, 0);
        if (!checked) {
          sendEmail("Rimosso online", idMatricola)
        } else {
          sendEmail("Messo online", idMatricola)
        }
        break;
    }
  }, [checked]);

  const sendEmail = async (type: string, id: any, product?: any) => {
    let mat: any;
    let brand: any;
    let date = dateTimeNow();
    await axios
      .get(`${ConfigCapone.urlapi}/api/Matricola/${id}`)
      .then((response) => {
        mat = response.data.response?.matricola;
        brand = response.data.response?.marca;
        console.log("online: ", response.data.response);
      })
      .catch((error) => {
        console.log("error -> ", error);

      });

    let emailData = {
      type: "notice",
      email: `${ConfigCapone.emailMarketing}`,
      subject: `${type}`,
      message: `<p> Operazione: ${type}${product ? `,  Il prodotto ${dataMagazzino?.referencia} non è stato acquistato dal cliente` : ''}</p>
                 <p> Operatore: ${userInLogin.userName}</p>
                 <p> Referenza: ${dataMagazzino?.referencia} </p>
                 <p> Brand: ${brand}</p>
                 <p> Descrizione: ${dataMagazzino?.desc_famiglia ? dataMagazzino?.desc_famiglia : 'Nessun dato'} </p>
                 <p> Negozio: ${dataMagazzino?.desc_bussines} </p>
                 <p> Matricola: ${mat}</p>
                 <p> Prezzo: ${dataMagazzino?.prezzo}</p>
                 <p> Data: ${date}</p>`,
    };

    await axios
      .post(`${ConfigCapone.apicapone}/api/forma`, emailData)
      .then((res) => {
        console.log(res);
        // console.log("info : ", emailData);
      })
      .catch((err) => console.log("there was an error: " + err));

  }

  const dateTimeNow = (fecha: string = '') => {
    let date
    if (fecha !== '') {
      date = new Date(fecha)
    } else {
      date = new Date()
    }
    const year = date.getFullYear()
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1
    const day = date.getDate().toString().padStart(2, "0")
    const hour = date.getHours().toString().padStart(2, "0")
    const minute = date.getMinutes().toString().padStart(2, "0")
    const seconds = date.getSeconds().toString().padStart(2, "0")
    const dateNow =
      day +
      '/' +
      month +
      '/' +
      year +
      ', ore ' +
      hour +
      ':' +
      minute +
      ':' +
      seconds

    return dateNow
  }
  console.log("dataMa", dataMagazzino)
  return (
    <View style={styleSchemaProdottoCard.centeredView}>
      {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <View
          style={[styleSchemaProdottoCard.centeredView, { backgroundColor: "rgba(0,0,0,.32)" }]}
        >
          <View style={styleSchemaProdottoCard.modalView}>
            <HeaderModal
              setModalVisible={setModalVisible}
              modalVisible={modalVisible}
              title={"Scheda prodotto"}
            />
            <ScrollView>
              {
                dataMagazzino.fileName &&
                <div className="row">
                  <div className="col-12 text-center" >

                    <img
                      src={`${ConfigCapone.apicapone}/image/prodotto/${dataMagazzino.fileName}`}
                      style={{ margin: '12px', width: '230px' }}
                      id="img-container-send"
                      alt=""
                    />
                  </div>
                </div>
              }

              <Text style={styles.label}>Referenza</Text>
              <TextInput
                style={styleSchemaProdottoCard.inputNotEdit}
                value={
                  dataMagazzino?.referencia ? dataMagazzino?.referencia : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Brand</Text>
              <TextInput
                style={styleSchemaProdottoCard.inputNotEdit}
                value={
                  dataMagazzino?.desc_brand ? dataMagazzino?.desc_brand : "Nessun dato"
                }
                editable={false}
              />
              <Text style={styles.label}>Descrizione</Text>
              <TextInput
                style={styleSchemaProdottoCard.inputNotEdit}
                value={
                  dataMagazzino?.desc_famiglia ? dataMagazzino?.desc_famiglia : "Nessun dato"
                }
                editable={false}
              />
              <Text style={styles.label}>Misura quadrante</Text>
              <TextInput
                style={styleSchemaProdottoCard.inputNotEdit}
                value={
                  dataMagazzino?.misuraQuadrante ? dataMagazzino?.misuraQuadrante : "Nessun dato"
                }
                editable={false}
              />
              <Text style={styles.label}>Colore quadrante</Text>
              <TextInput
                style={styleSchemaProdottoCard.inputNotEdit}
                value={
                  dataMagazzino?.coloreQuadrante ? dataMagazzino?.coloreQuadrante : "Nessun dato"
                }
                editable={false}
              />
              <Text style={styles.label}>Tipo di movimento</Text>
              <TextInput
                style={styleSchemaProdottoCard.inputNotEdit}
                value={
                  dataMagazzino?.tipoDiMovimento ? dataMagazzino?.tipoDiMovimento : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Quantità</Text>
              <TextInput
                style={styleSchemaProdottoCard.inputNotEdit}
                value={
                  dataMagazzino?.quantity ? dataMagazzino?.quantity : 0
                }
                editable={false}
              />

              <Text style={styles.label}>Negozio</Text>
              <TextInput
                style={styleSchemaProdottoCard.inputNotEdit}
                value={
                  dataMagazzino?.desc_bussines ? dataMagazzino?.desc_bussines == 'NULL' ? "Nessun dato" : dataMagazzino?.desc_bussines : "Nessun dato"
                }
                editable={false}
              />

              <Text style={styles.label}>Prezzo</Text>
              <TextInput
                style={styleSchemaProdottoCard.inputNotEdit}
                value={
                  dataMagazzino?.prezzo ? parseFloat(dataMagazzino?.prezzo).toFixed(2) + ' €' : "0.00"
                }
                editable={false}
              />

              {dataMagazzino?.quantity != 0 && <Text style={styles.label}>Matricola</Text>}

              {(dataMatricola)
                ?
                (dataMatricola.map((data, index) => {
                  return (
                    <div className="row mb-5" key={index}>
                      <div className="col-5 mt-2">

                        <Text style={styleSchemaProdottoCard.buttonOutline}
                        >{
                            data?.matricola ? (data?.matricola.length < 10 ? data?.matricola : data?.matricola.substr(1, 7) + "...") : "Nessun dato"}</Text>
                      </div>
                      <div className="col-7">

                        <TouchableOpacity>
                          <CheckLabel
                            name="Spostamento"
                            id={'Spostamento' + data.idMatricola}
                            type="text"
                            checked={checked}
                            onChange={(value) => changeListMatricola("PutMoveBusiness", value, data.idMatricola, data.matricola)}
                            text="Spostamento negozio"
                            marginleft={true}
                          />
                        </TouchableOpacity>


                        <TouchableOpacity>
                          <label htmlFor={'meso' + data.idMatricola} style={{ fontSize: 18, marginBottom: 5, fontFamily: "Arsapia Regular font" }}>
                            <input
                              type="checkbox"
                              name="meso"
                              className="checkDoble"
                              style={{
                                margin: 0,
                                marginRight: 15,
                                fontSize: 100
                              }}
                              checked={data.putAside}
                              id={'meso' + data.idMatricola}
                              onChange={({ target }) => changeListOnline("PutAside", target.value, data.idMatricola)}
                            />
                            Messo da parte
                          </label>

                          {/* <CheckLabel                                                    
                                        name="meso"
                                        id={'meso'+data.idMatricola}
                                        type="text"
                                        checked={checked}
                                        onChange={(value) => changeListOnline("PutAside", value, data.idMatricola)}          
                                        text="Messo da parte"
                                        marginleft={true}
                                    />  */}

                        </TouchableOpacity>

                        <TouchableOpacity>
                          <CheckLabel
                            name="vendita"
                            id={'vendita' + data.idMatricola}
                            checked={checked}
                            onChange={(value) => changeListMatricola("PutSale", value, data.idMatricola)}
                            type="text"
                            text="Vendita"
                            marginleft={true}

                          />
                        </TouchableOpacity>

                        <TouchableOpacity>
                          <CheckLabel
                            name="venditaNuovoCliente"
                            id={'venditaNuovoCliente' + data.idMatricola}
                            checked={checked}
                            onChange={(value) => changeListMatricola("PutSaleNewCustomer", value, data.idMatricola)}
                            type="text"
                            text="Vendita ad un nuovo cliente"
                            marginleft={true}

                          />
                        </TouchableOpacity>

                        <TouchableOpacity>
                          <CheckLabel
                            name="venditaExistClient"
                            id={'venditaExistClient' + data.idMatricola}
                            type="text"
                            checked={checked}
                            onChange={(value) => changeListMatricola("PutSaleExistingCustomer", value, data.idMatricola)}
                            text="Vendita ad un cliente già esistente"
                            marginleft={true}

                          />
                        </TouchableOpacity>

                        <TouchableOpacity>

                          <label htmlFor={'online' + data.idMatricola} style={{ fontSize: 18, marginBottom: 5, fontFamily: "Arsapia Regular font" }}>
                            <input
                              type="checkbox"
                              name="online"
                              className="checkDoble"
                              checked={data.putOnline}
                              style={{
                                margin: 0,
                                marginRight: 15,
                                fontSize: 100
                              }}
                              id={'online' + data.idMatricola}
                              onChange={({ target }) => changeListOnline("PutOnline", target.value, data.idMatricola)}
                            />
                            Online
                          </label>

                          {/* <CheckLabel                                                    
                                          name="online"
                                          id={'online'+data.idMatricola}
                                          type="text"
                                          checked={checked} 
                                          onChange={(value) => changeListMatricola("PutOnline", value, data.idMatricola)}          
                                          text="Online"
                                          marginleft={true}
                                          
                                      />  */}
                        </TouchableOpacity>

                      </div>
                    </div>
                  )
                }))
                : ""
              }


              {dataMagazzino?.quantity >= 0 &&
                <View>
                  <button
                    style={{
                      width: 200,
                      marginRight: 15,
                      marginBottom: 30,
                      marginTop: dataMagazzino?.quantity == 0 && 30,
                      height: 40,
                      alignSelf: "end",
                      backgroundColor: "transparent",
                      borderRadius: 50,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => goToEdit(dataMagazzino, dataMatricola)}
                    disabled={!activeButton}
                  >
                    Modifica Scheda
                  </button>
                </View>
              }

              <ModalOptionSchemaProdotto
                idMatricola={idMatricola}
                idBussinessSeller={idBussinessSeller}
                typeMatricola={typeMatricola}
                goToSchedaProdotto={goToSchedaProdotto}
                updateStateMatricole={() => updateStateMatricole(idMatricola, true, typeMatricola, 0)}
                modalVisible={modalVisibleCard}
                setModalVisible={setModalVisibleCard}
                setModalSchedaProdotto={setModalVisible}
                activeButton={activeBottom}
                referencia={dataMagazzino?.referencia ? dataMagazzino?.referencia : 0}
                prezzo={dataMagazzino?.prezzo ? dataMagazzino?.prezzo : 0}
              // brand={dataMagazzino?.desc_brand ? dataMagazzino?.desc_brand : 0}                                                 
              />

              <ModalOptionSpostamentoNegozio
                dataMatricola={dataMatricola}
                idMatricola={idMatricola}
                setModalSchedaProdotto={setModalVisible}
                idNegozio={dataMagazzino?.idBussines ? dataMagazzino?.idBussines : 0}
                idReference={dataMagazzino?.idReference ? dataMagazzino?.idReference : 0}
                idBrand={dataMagazzino?.idBrand ? dataMagazzino?.idBrand : 0}
                idFamiglia={dataMagazzino?.idFamiglia ? dataMagazzino?.idFamiglia : undefined}
                coloreQuadrante={dataMagazzino?.coloreQuadrante ? dataMagazzino?.coloreQuadrante : undefined}
                fileName={dataMagazzino?.fileName ? dataMagazzino?.fileName : undefined}
                misuraQuadrante={dataMagazzino?.misuraQuadrante ? dataMagazzino?.misuraQuadrante : undefined}
                tipoDiMovimento={dataMagazzino?.tipoDiMovimento ? dataMagazzino?.tipoDiMovimento : undefined}
                brand={dataMagazzino?.desc_brand ? dataMagazzino?.desc_brand : 0}
                desc_famiglia={dataMagazzino?.desc_famiglia ? dataMagazzino?.desc_famiglia : 0}
                referencia={dataMagazzino?.referencia ? dataMagazzino?.referencia : 0}
                prezzo={dataMagazzino?.prezzo ? dataMagazzino?.prezzo : 0}
                typeMatricola={typeMatricola}
                matricola={matricola}
                findReference={findReference}
                setFindReference={setFindReference}
                goToSchedaProdotto={goToSchedaProdotto}
                idR={idR}
                setIdR={setIdR}
                valueOldReference={(e: any) => valueOldReference(e)}
                updateStateMatricole={() => updateStateMatricole(idMatricola, true, typeMatricola, 0)}
                modalVisible={modalSpostamentoNegozioVisibleCard}
                setModalVisible={setModalSpostamentoNegozioVisibleCard}
                activeButton={activeBottom}
              />

              <ModalOptionClienteEsistente
                idMatricola={idMatricola}
                idBussinessSeller={idBussinessSeller}
                typeMatricola={typeMatricola}
                goToSchedaProdotto={goToSchedaProdotto}
                updateStateMatricole={updateStateMatricole}
                modalVisible={modalOptionClienteEsistente}
                setModalVisible={setModalOptionClienteEsistente}
                activeButton={activeBottom}
                searchModalInputClient={searchModalInputClient}
                setSearchModalInputClient={setSearchModalInputClient}
                handleSearchModalFilter={handleSearchModalFilter}
                dataClient={dataClient}
                dataMagazzino={dataMagazzino}
                setDataClient={setDataClient}
                autosuggest={autosuggest}
                setAutosuggest={setAutosuggest}
                setModalSchedaProdotto={setModalVisible}
              />
            </ScrollView>
          </View>
        </View>
      </Modal>
    </View>
  )

}


export const styleSchemaProdottoCard = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    flex: 1,
  },
  inputNotEdit: {
    width: 390,
    height: 40,
    margin: 12,
    borderRadius: 50,
    marginVertical: 6,
    backgroundColor: "rgb(235, 235, 235)",
    fontFamily: "Arsapia Regular font",
    color: "#a2a2a2",
    paddingLeft: 20,
  },
  button: {
    width: 200,
    marginTop: 30,
    height: 40,
    alignSelf: "auto",
    backgroundColor: "transparent",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonOutline: {
    height: 45,
    width: "100%",
    borderWidth: 2,
    fontFamily: "Arsapia Regular font",
    color: "#ADADAC",
    fontWeight: "400",
    paddingLeft: 26,
    paddingRight: 26,
    paddingBottom: 8,
    paddingTop: 8,
    marginTop: '50%',
    textAlign: 'center',
    borderRadius: 50,
    marginRight: "1%",
  }
});

export default SchemaProdottoCard;
