import { tableRow } from "../../GeneralStyles/Statistiche";
import { FilterWhit } from "./FilterWhit";
import { NegozioStatische } from "./NegozioStatische";
import { Collapse } from "react-collapse";
import { CercaStatische } from "../../components/MagazzinoStatistiche";
import { BrandStatische } from "./BrandStatische";
import { ReferenzaStatische } from "./ReferenzaStatische";
import { BrandOptions } from "./BrandOptions";
import { TableVenditeProdottiHeader } from "./TableVenditeProdottiHeader";
import { BussinesVenditeProdotti } from "./BussinesVenditeProdotti";
import Pagination from "../Pagination/pagination";
import { NotResults } from "./NotResults";
import TitleStatische from "./TitleStatische";
import { MeseAnno } from "./MeseAnno";
import { FilterMeseAnno } from "./FilterMeseAnno";
interface Props {
    showNP,
    showRP,
    showBP,
    handleShowBrandProdotti,
    handleShowReferenzaProdotti,
    handleShowNegozioProdotti,
    handleChangeReferenza,
    handleChangeCheckNegozioProdotti,
    handleChangeCheckBrand,
    handleCheckNegozioProdotti,
    handleCheckBrand,
    paginaActual,
    paginationListe: any;
    bussines: any,
    brand,
    venditeProdotti: any;
    setPaginaActual,
    referenza,
    handleShowMeseAnnoVenditeProdotti,
    showDateVenditeProdotti,
    handleChangeDateVenditeProdotti,
    statisticDateVenditeProdotti

}
export const VenditeProdotti = ({
    showNP,
    showRP,
    showBP,
    handleShowBrandProdotti,
    handleShowReferenzaProdotti,
    handleShowNegozioProdotti,
    handleChangeReferenza,
    handleChangeCheckNegozioProdotti,
    handleChangeCheckBrand,
    handleCheckNegozioProdotti,
    handleCheckBrand,
    paginaActual,
    paginationListe,
    bussines,
    referenza,
    brand,
    venditeProdotti,
    setPaginaActual,
    handleShowMeseAnnoVenditeProdotti,
    showDateVenditeProdotti,
    handleChangeDateVenditeProdotti,
    statisticDateVenditeProdotti
}: Props) => {
    return (
        <>
            <TitleStatische title={"Vendite prodotti"} />
            <div className="row">
                <div className="col-12 col-sm-3">
                    <FilterWhit />
                    <div className="row">
                        <MeseAnno handleShow={handleShowMeseAnnoVenditeProdotti} />
                        <Collapse isOpened={showDateVenditeProdotti}>
                        <FilterMeseAnno
                            handleSearch={handleChangeDateVenditeProdotti}
                            value={statisticDateVenditeProdotti}
                        />
                        </Collapse> 
                        <ReferenzaStatische handleShow={handleShowReferenzaProdotti} />
                        <Collapse isOpened={showRP}>
                            <CercaStatische
                                value={referenza}
                                handleSearch={handleChangeReferenza}
                            />
                        </Collapse>
                        <BrandStatische handleShow={handleShowBrandProdotti} />
                        <Collapse isOpened={showBP}>
                            <BrandOptions
                                brand={brand}
                                handleChangeCheck={handleChangeCheckBrand}
                                handleCheck={handleCheckBrand}
                            />
                        </Collapse>

                        <NegozioStatische handleShow={handleShowNegozioProdotti} />
                        <Collapse isOpened={showNP}>
                            <BussinesVenditeProdotti
                                bussines={bussines}
                                handleChangeCheck={handleChangeCheckNegozioProdotti}
                                handleCheck={handleCheckNegozioProdotti}
                            />
                        </Collapse>
                    </div>
                </div>
                <div className="col-12 col-sm-9">
                    <div className="table-responsive">
                        {venditeProdotti.length > 0 ? (
                            <table className="table table-borderless mt-3">
                                <TableVenditeProdottiHeader />
                                <tbody>
                                    <>
                                        {venditeProdotti.map(
                                            ({ idSale, referencia, desc_bussines, desc_brand, desc_famiglia                                            }) => (
                                                <tr key={Number(idSale)} style={{ borderBottom: "1px solid gray" }}>
                                                    <td
                                                        style={tableRow}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {desc_brand}
                                                    </td>
                                                    <td
                                                        style={{ ...tableRow, fontSize: 12 }}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {desc_famiglia}
                                                    </td>
                                                    <td
                                                        style={{ ...tableRow, fontSize: 12 }}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {referencia}
                                                    </td>
                                                    <td
                                                        style={{ ...tableRow, fontSize: 12 }}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {desc_bussines}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </>
                                </tbody>
                            </table>) : <NotResults />}
                        {(venditeProdotti.length > 0) && (
                            <Pagination
                                className="pagination-bar"
                                currentPage={paginationListe.currentPage}
                                totalCount={paginationListe.totalPages}
                                pageSize={1}
                                onPageChange={(page) => setPaginaActual(page)}
                            />)}
                    </div>
                </div>
            </div>
        </>
    );
};
