export default function Debounce(fn: Function, delay: number) {
    let timer: any;
    return function (this: Element) {
        const self = this;
        const args = arguments;
        clearInterval(timer);
        timer = setTimeout(function () {
            fn.apply(self, args)
        }, delay)
    }
}