import { View, Text } from "react-native";
import { IClient } from "../../interfaces/IClient";

const getCustomerSuggestion = (item: IClient) => {
    let mainText = item.name + " - " + item.phone;
    return (
        <div>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ marginLeft: 10, flexShrink: 1 }}>
                    <Text style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}>{mainText}</Text>
                </View>
            </View>
        </div>
    );
};

export default getCustomerSuggestion;