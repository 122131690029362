import React, { useState, useEffect, useContext } from "react";
import {
  ScrollView,
  TouchableOpacity,
  View,
  Text,
  TextInput,
  BackHandler,
  StyleSheet,
  Image,
} from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { renderToStaticMarkup } from 'react-dom/server';
import Icon from "react-native-vector-icons/Ionicons";
import { styles } from "../theme/GlobalStyle";
import * as Print from "expo-print";
import RNHTMLtoPDF from 'react-native-html-to-pdf';
import { HeaderNewRepair as Header } from "../components/HeaderNewRepair";
import api_capone from "../api/api_capone";
import ResponseAPI from "../Models/ResponseAPI";
import ServicesModel from "../Models/ServicesModel";
import ProductModel from "../Models/ProductModel";
import PaymentModel from "../Models/PaymentModel";
import { UserContext } from "../context/UserContext";
import { useForm, Controller } from "react-hook-form";
import { useIsFocused } from "@react-navigation/native";
import CurrencyInput from "react-currency-input-field";
import PopupMessage from "../components/PopupMessage";
import Loading from "../components/Loading";
import axios from "axios";
import ConfigCapone from "../utils/ConfigCapone";
import { type_action, work_required, work_detail } from "../api/payments/PaymentJobs";
import PdfScreen from "./PdfScreen";
import PdfScreenSendemailPreveti from "./PdfScreenSendemailPreveti";
import html2pdf from 'html2pdf.js';

const inputSmallNear = {
  borderWidth: 0,
  backgroundColor: "rgb(235, 235, 235)",
  width: "100%",
  borderColor: "black",
  color: "black",
  height: 50,
  borderRadius: 50,
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
};
const inputSmall = {
  borderWidth: 0,
  backgroundColor: "rgb(235, 235, 235)",
  width: "100%",
  borderColor: "black",
  color: "black",
  height: 50,
  borderRadius: 50,
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
};
const inputSmallMargin = {
  borderWidth: 0,
  backgroundColor: "rgb(235, 235, 235)",
  width: "100%",
  borderColor: "black",
  color: "black",
  height: 50,
  borderRadius: 50,
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
};
const inputSmallMarginTotal = {
  borderWidth: 0,
  backgroundColor: "rgb(235, 235, 235)",
  width: "30%",
  borderColor: "black",
  color: "black",
  height: 50,
  margin: "18px 18px 18px 150px  ",
  borderRadius: 50,
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
};
const inputLarge = {
  borderWidth: 0,
  backgroundColor: "rgb(235, 235, 235)",
  width: "100%",
  borderColor: "black",
  color: "black",
  height: 50,
  borderRadius: 50,
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
};
const inputLargeNon = {
  borderWidth: 2,
  backgroundColor: "transparent",
  width: "100%",
  borderColor: "black",
  color: "black",
  height: 50,
  borderRadius: 50,
  fontFamily: "Arsapia Regular font",
};
const inputTextArea = {
  borderWidth: 0,
  backgroundColor: "rgb(235, 235, 235)",
  width: "100%",
  borderColor: "black",
  color: "black",
  height: "100%",
  borderRadius: 20,
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
};
const customStyles = {
  menu: (provided: any, state: any) => ({
    //lo que despliega drodown
    ...provided,
    background: "white",
    borderColor: "black",
    borderWidth: 2,
    backgroundColor: state.isSelected ? "white" : "white",
  }),
  menuList: (provided: any, state: any) => ({
    //lo que despliega drodown
    ...provided,
    background: "white",
    borderColor: "black",
    borderWidth: 2,
    backgroundColor: state.isSelected ? "white" : "white",
  }),
  menuPortal: (provided: any, state: any) => ({
    //lo que despliega drodown
    ...provided,
    background: "white",
    borderColor: "black",
    borderWidth: 2,
    backgroundColor: state.isSelected ? "white" : "white",
  }),
  option: (provided: any, state: any) => ({
    //cada option
    color: state.isSelected ? "black" : "black",
    padding: 20,
    backgroundColor: state.isSelected ? "white" : "white",
  }),
  control: (base: any, state: any) => ({
    ...base,
    width: "100%",
    height: 50,
    borderRadius: 50,
    paddingLeft: 20,
    fontFamily: "Arsapia Regular font",
    backgroundColor: "white",
    borderWidth: 2,
    boxShadow: "none",
    borderColor: state.isFocused ? "black" : "black",
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "black" : "black",
    },
  }),
  dropdownIndicator: (previus: any, state: any) => ({
    ...previus,
    color: "black",
  }),
};
interface Props extends NativeStackScreenProps<any, any> { 
  isAtelier?: boolean
}
const BudgetScreen = ({ route, navigation, isAtelier = false  }: Props) => { 

  console.log("reute:", route)
  console.log("navigation:", navigation)

  const requiredMessajeErrorJob = "Questo campo è obbligatorio.";
  const requiredMessajeError = "Questo campo è obbligatorio.";
  const minMessageerror = "Questo valore non può essere inferiore a 0.";
  const DatosEnviados: any = route.params;
  const idServiceModule: number = DatosEnviados.id;
  const DateNow = new Date();
  const [issFocused, setIssFocused] = useState(false);
  const [budget, SetBudget] = useState(new PaymentModel());
  const [catalogTypesPayment, setTypesMethod] = useState([]);
  const [typesPayment, setTypesPayment] = useState();
  const [serviceBudget, setServiceBudget] = useState(new ServicesModel());
  const [descProduct, setDescProduct] = useState(Object);
  const [clientService, setClient] = useState(Object);
  const [watchBrand, setWatchBrand] = useState(Object);
  const [numberBudget, setNumberBudget] = useState(0);
  const [subtotal, setSubtotal] = useState("0");
  const [iva, setIva] = useState("0");
  const [total, setTotal] = useState("0");
  const [typePaymentSelected, setTypePaymentSelected] = useState(0);
  const [saveEnable, setSaveEnable] = useState(true);
  const [textButtonAction, setTextButtonAction] = useState("Salva");
  const { userInLogin } = useContext(UserContext);
  const [isTechnique, setIsTechnique] = useState<boolean>((userInLogin?.idRole === '3' || userInLogin?.idRole === '7' || userInLogin?.idRole === '8') ? true : false);
  const [errorPrecio, setErrorPrecio] = useState(false);
  const [errorPreventivo, setErrorPreventivo] = useState(false);
  const [errorPayment, setErrorPayment] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationPreventivo, setConfirmationPreventivo] = useState(false);
  const [sendPreventivo, setSendPreventivo] = useState(false);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
    title: "",
  });
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const [flgEmialExist, setFlgEmialExist] = useState(false);
  const [brandType, setBrandType] = useState([]);
  const [productType, setProductType] = useState([]);
  const [brandRecive, setBrandRecive] = useState();
  const [clockFaces, setClockFaces] = useState('');
  const [bracelets, setBracelets] = useState('');
  const [conditionReciepts, setConditionReciepts] = useState('');
  const [jobsRequireds, setJobsRequireds] = useState('');
  const [recommendeds, setRecommendeds] = useState('');
  const [city, setCity] = useState('');
  const [isPrinting, setIsPrinting] = useState(false);

  const getBrand = async () => {
    const rep = await axios(`${ConfigCapone.urlapi}/api/Brand?parameter=1`)
      .then((response) => {
        setBrandType(response.data.response);
      })
      .catch((error) => console.log(error));
  };
  const [modalMessageWithRoute, setModalMessageWithRoute] = useState({
    active: false,
    message: "",
    route: "",
    title: "",
  });
  const valueDefect = {
    subtotal1: subtotal.replace(".", ","),
    numberBudget1: numberBudget,
  };
  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const onBackPress = () => {
    navigation.navigate("HomeScreen");
    return true;
  };
  BackHandler.addEventListener("hardwareBackPress", onBackPress);
  const NotificacionSuccess = (
    titleMenssage: string,
    bodyMensagge: string,
    Landing: string
  ) => {
    setModalMessage({
      active: true,
      message: bodyMensagge,
      title: "Validato ",
    });
  };
  const Notificacion = (Titulo: string, Cuerpo: string) =>
    setModalMessage({
      active: true,
      message: "Si è verificato un errore durante il salvataggio.",
      title: "",
    });
  const GetPaymentUpdate = async () => {
    var parametro = "idService";
    const objreq = new api_capone<ResponseAPI<PaymentModel[]>>();
    var respuesta = await objreq.GetHttpAPI(
      `value=${idServiceModule}&parameter=${parametro}`,
      "/api/Payment?"
    );

    if (respuesta.status == "success" && respuesta.response.length > 0) {
      setNumberBudget(respuesta.response[0].nroPreventive);
      SetBudget(respuesta.response[0]);
      setValue("paymentMethod", respuesta.response[0].idMethodPayment);
      setValue("product", respuesta.response[0].idMethodPayment);
      setValue("bracelet", respuesta.response[0].bracelet)
      setValue("clockFace", respuesta.response[0].clockFace)
      setValue("conditionReciept", respuesta.response[0].conditionReciept)
      setValue("jobsRequired", respuesta.response[0].jobsRequired)
      setValue("recommended", respuesta.response[0].recommended)
      setBracelets(respuesta.response[0].bracelet);
      setClockFaces(respuesta.response[0].clockFace);
      setConditionReciepts(respuesta.response[0].conditionReciept);
      setJobsRequireds(respuesta.response[0].jobsRequired);
      setRecommendeds(respuesta.response[0].recommended);
      setConfirmation(respuesta.response[0].confirm);
      setConfirmationPreventivo(respuesta.response[0].confirmPreventivo);
      setValue("numberBudget1", respuesta.response[0].nroPreventive);
      let subtotalSend = Number(respuesta.response[0].subtotal - respuesta.response[0].iva).toFixed(2)
      setSubtotal(String(subtotalSend));
      setValue("subtotal1", Number(respuesta.response[0].total));
      setIva(String(respuesta.response[0].iva));
      setTotal(
        respuesta.response[0].total ? String(respuesta.response[0].total) : "0"
      );
      setSaveEnable(false);
      setTextButtonAction("Aggiornare");
    }

    return respuesta;
  };

  const getAllDataJobs = async () => {
    setIsPrinting(false);
    var parametro = "idService";
    const objreq = new api_capone<ResponseAPI<PaymentModel[]>>();
    var respuesta = await objreq.GetHttpAPI(
      `value=${idServiceModule}&parameter=${parametro}`,
      "/api/Payment?"
    );

    if (respuesta.response[0] != undefined || respuesta.response[0] != null) {
      let idPayment = respuesta.response[0].idPayment;

      await axios(`${ConfigCapone.urlapi}/api/WorkDetail/getListWorkDetail/` + idPayment)
        .then((response) => {

          let dataWorkDetail = response.data.response;

          axios(`${ConfigCapone.urlapi}/api/WorkDetail/getListWorkRequired/1`)
            .then((response) => {

              let allJobsPayment = response.data.response;

              axios(`${ConfigCapone.urlapi}/api/WorkDetail/getListWorkRequired/2`)
                .then((response) => {

                  let anotherJobs = response.data.response;

                  allJobsPayment = allJobsPayment.concat(anotherJobs);

                  let jobs = dataWorkDetail;

                  for (let i = 0; i < jobs.length; i++) {
                    for (let j = 0; j < allJobsPayment.length; j++) {
                      if (jobs[i].idWork == allJobsPayment[j].idWork) {
                        jobs[i]["idTypeAction"] = allJobsPayment[j].idTypeAction;
                        jobs[i]["id"] = allJobsPayment[j].idWork;
                        jobs[i]["job"] = allJobsPayment[j].desc_work;
                      }
                    }
                  }

                  let jobsRequired = [];
                  let jobsRecommended = [];

                  jobs.forEach((job: any) => {
                    if (job.idTypeAction == "1") {
                      jobsRequired.push(job);
                    } else {
                      jobsRecommended.push(job);
                    }
                  })

                  for (let i = 0; i < jobsRequired.length; i++) {
                    setValue("subtotal-required-" + i, Number(jobsRequired[i].price));
                  }

                  for (let i = 0; i < jobsRecommended.length; i++) {
                    setValue("subtotal-recommended-" + i, Number(jobsRecommended[i].price));
                  }

                  if (jobsRequired.length == 0) {
                    jobsRequired.push({
                      id: null,
                      job: '',
                      price: '',
                      hasError: false,
                    })
                  }

                  if (jobsRecommended.length == 0) {
                    jobsRecommended.push({
                      id: null,
                      job: '',
                      price: '',
                      hasError: false,
                    })
                  }

                  setCountJobsRequired(jobsRequired);
                  setCountJobsRecommended(jobsRecommended);
                })
            })
            .catch((error) => console.log(error));

        })
        .catch((error) => console.log(error));
    }
  }

  const TypesPaymentCatalog = async () => {
    const rep = await axios(
      `${ConfigCapone.urlapi}/api/TypesPayment?parameter=1`
    )
      .then((response) => setTypesMethod(response.data.response))
      .catch((error) => console.log(error));
  };

  const GetCity = async (id?: number) => {
    const objreq = new api_capone<any>();
    var respuesta = await objreq.GetHttpAPI(`/${id}`, "/api/bussines");

    setCity(respuesta.response.desc_Bussines);
  }

  const Getservice = async () => {
    var id: number = idServiceModule;
    const objreq = new api_capone<ResponseAPI<ServicesModel>>();
    var respuesta = await objreq.GetHttpAPI(`/${id}`, "/api/Service");

    if (respuesta.status == "success") {
      setServiceBudget(respuesta.response);
      GetProduct(respuesta.response.idProduct);
      GetBrand(respuesta.response.idBrand);
      setSendPreventivo(respuesta.response?.hasPreventive)
      GetCity(respuesta.response.idBussines)

      let parametro = "idClient";
      const objreq = new api_capone<ResponseAPI<PaymentModel[]>>();
      var responseClient = await objreq.GetHttpAPI(
        `value=${respuesta.response.idClient}&parameter=${parametro}`,
        "/api/Client?"
      );
      setClient(responseClient.response);
      responseClient.response[0].email ? setFlgEmialExist(false) : setFlgEmialExist(true);
      const objreq1 = new api_capone<ResponseAPI<PaymentModel[]>>();
      let paramWatch = "idBrand";
      var responseBrand = await objreq1.GetHttpAPI(
        `value=${respuesta.response.idBrand}&parameter=${paramWatch}`,
        "/api/Brand?"
      );
      setWatchBrand(responseBrand.response);
    } else {
      navigation.navigate("HomeScreen");
    }
  };
  const GetProduct = async (id?: number) => {
    const objreq = new api_capone<ResponseAPI<ProductModel>>();
    var respuesta = await objreq.GetHttpAPI(`/${id}`, "/api/product");
    setDescProduct(respuesta.response);
  };
  const GetBrand = async (id?: number) => {
    const objreq = new api_capone<any>();
    var respuesta = await objreq.GetHttpAPI(`/${id}`, "/api/brand");
    setBrandRecive(respuesta.response.idBrand);
  };
  const getProductItems = async () => {
    setActiveSpinner(true);
    const rep = await axios(`${ConfigCapone.urlapi}/api/Product?parameter=1`)
      .then((response) => setProductType(response.data.response))
      .catch((error) => console.log(error));
    setActiveSpinner(false);
  };

  const notifySystem = (message: string) => {

    NotificacionSuccess(
      "Preventivo",
      message,
      "BudgetScreen"
    );

  }

  const SaveBudget = async (data: any, hasPrinting: boolean = false) => {

    setRequiredJobs(false);
    setRecommendedJobs(false);

    debugger

    let totalPrice = 0;
    let acumPriceJobRequired: number = 0;
    let acumPriceJobRecommended: number = 0;

    let checkRequiredJobs: boolean = true;
    let checkRecommendedJobs: boolean = true;

    for (let i = 0; i < countJobsRequired.length; i++) {
      if (
        countJobsRequired[i].price != '' &&
        countJobsRequired[i].price != undefined
        && countJobsRequired[i].price != null) {

        let price = null;
        if (countJobsRequired[i].price.toString().includes(',')) {
          price = countJobsRequired[i].price.toString().replace(",", ".")
        } else {
          price = countJobsRequired[i].price.toString()
        }
        acumPriceJobRequired += Number(price);
      }
    }

    for (let i = 0; i < countJobsRequired.length; i++) {
      if (
        countJobsRequired[i].price == '' ||
        countJobsRequired[i].price == null ||
        countJobsRequired[i].price == undefined ||
        countJobsRequired[i].job == '' ||
        countJobsRequired[i].job == null ||
        countJobsRequired[i].job == undefined ||
        countJobsRequired[i].price == '0'
      ) {
        checkRequiredJobs = false;
        let newArr = [...countJobsRequired];
        newArr[i].hasError = true;

        setCountJobsRequired(newArr);
      } else {
        let newArr = [...countJobsRequired];
        newArr[i].hasError = false;

        setCountJobsRequired(newArr);
      }
    }

    for (let i = 0; i < countJobsRecommended.length; i++) {
      if (
        countJobsRecommended[i].price == '' ||
        countJobsRecommended[i].price == null ||
        countJobsRecommended[i].price == undefined ||
        countJobsRecommended[i].job == '' ||
        countJobsRecommended[i].job == null ||
        countJobsRecommended[i].job == undefined
      ) {
        checkRecommendedJobs = true;
        let newArr = [...countJobsRecommended];
        newArr[i].hasError = false;

        setCountJobsRecommended(newArr);
      } else {
        let newArr = [...countJobsRecommended];
        newArr[i].hasError = false;

        setCountJobsRecommended(newArr);
      }
    }


    //totalPrice = Number(acumPriceJobRequired) + Number(acumPriceJobRecommended);

    if (!checkRequiredJobs) {
      setRequiredJobs(true);
    }

    if (!checkRecommendedJobs) {
      setRecommendedJobs(true);
    }

    if (!checkRequiredJobs || !checkRecommendedJobs) {
      setActiveSpinner(false);
      return null;
    }

    totalPrice = Number(acumPriceJobRequired);

    setValue("subtotal1", totalPrice);
    setSubtotal(Number(totalPrice).toFixed(2));

    var result = Number(totalPrice);
    var iva = (result * 22) / 122;
    var resultIVA = Number(iva.toFixed(2));
    var subtotal = Number(result - iva).toFixed(2);
    onchangeLog(result, iva, resultIVA, subtotal);
    setSubtotal(subtotal);
    budget.subtotal = Number(subtotal);
    setIva(String(resultIVA));
    budget.iva = Number(resultIVA);
    var resulttotal = Number(subtotal + iva).toFixed(2);
    setTotal(String(result));
    budget.total = result ? Number(result) : 0;

    setActiveSpinner(true);

    let valueStatus = confirmation ? 2 : 1;
    let valueStatusPreventivo = confirmationPreventivo && 6;
    var errorFlag: boolean = false;
    var DescriptionError: string = "";

    budget.subtotal = totalPrice;

    if (budget.subtotal === 0) {
      errorFlag = true;
      setErrorPrecio(true);
      DescriptionError = "Prezzo mancante." + DescriptionError;
      setActiveSpinner(false);
      return null;
    }
    budget.idMethodPayment = 1;
    if (budget.idMethodPayment == 0) {
      errorFlag = true;
      setErrorPayment(true);
      DescriptionError = "Metodo di pagamento mancante. " + DescriptionError;
      setActiveSpinner(false);
      return null;
    }
    if (errorFlag) {
      Notificacion("Dati mancanti", DescriptionError);
      setActiveSpinner(false);
      return null;
    } else {
      if (saveEnable) {
        console.log("aqui es", confirmation, confirmationPreventivo);

        budget.nroPreventive = 1
        budget.idService = idServiceModule;
        budget.idStatusPay = 2;
        budget.active = true;
        budget.confirm = confirmationPreventivo ? false : confirmation;
        budget.confirmPreventivo = confirmation ? false : confirmationPreventivo;
        budget.clockFace = data.clockFace;
        budget.bracelet = data.bracelet;
        budget.conditionReciept = data.conditionReciept;
        budget.jobsRequired = data.jobsRequired;
        budget.recommended = data.recommended;

        const objreq = new api_capone<ResponseAPI<string>>();
        var respuesta = await objreq.PostHttpAPI(``, "/api/Payment", budget);
        const objreqTwo = new api_capone<ResponseAPI<string>>();

        if (isTechnique) {

          let techniqueId = userInLogin.idUser;

          if (valueStatusPreventivo === 6) {
            var respuestaRes = await objreqTwo.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatusPreventivo}/${techniqueId}`,
              "/api/Service/ChangeStatusService/",
              []
            );
          } else {
            var respuestaRes = await objreqTwo.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatus}/${techniqueId}`,
              "/api/Service/ChangeStatusService/",
              []
            );
          }

        } else {         
          if (valueStatusPreventivo === 6) {
            var respuestaRes = await objreqTwo.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatusPreventivo}/0`,
              "/api/Service/ChangeStatusService/",
              []
            );
          } else {
            var respuestaRes = await objreqTwo.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatus}/0`,
              "/api/Service/ChangeStatusService/",
              []
            );
          }
          await updateStatePreventive()
        }

        if (respuesta.status == "success" && respuestaRes.status == "success") {
       
          let nroPrevent: any = await GetPaymentUpdate();

          setSaveEnable(true);

          let idPayment = respuesta.id;
          //saves jobs

          let allJobs = [
            ...countJobsRequired,
            ...countJobsRecommended
          ];

          let jobsObject: any = [];

          await objreq.DeleteHttpAPI(``, "/api/WorkDetail/" + idPayment);

          allJobs.forEach((element) => {
            let price = element.price;
            if (price.toString().includes(',')) {
              price = price.replace(',', '.')
            }
            jobsObject.push(
              {
                "idWork": Number(element.id),
                "price": Number(price),
                "idPayment": Number(idPayment)
              }
            )
          });

          allJobs.forEach((element) => {
            element.price = parseFloat(element.price).toFixed(2)
          });

          for (let j = 0; j < countJobsRequired.length; j++) {
            countJobsRequired[j].price = Number(countJobsRequired[j].price);
          }

          for (let i = 0; i < jobsObject.length; i++) {
            for (let j = 0; j < countJobsRequired.length; j++) {
              if (i == j) {
                if (countJobsRequired[j].idDetail == null || countJobsRequired[j].idDetail == undefined) {
                  countJobsRequired[j].price = jobsObject[i].price
                }
              }
            }
          }

          setCountJobsRequired(countJobsRequired);


          let arrayRecommended = [];
          for (let i = countJobsRequired.length; i < jobsObject.length; i++) {
            arrayRecommended.push(jobsObject[i]);
          }

          for (let i = 0; i < arrayRecommended.length; i++) {
            for (let j = 0; j < countJobsRecommended.length; j++) {
              if (i == j) {
                if (countJobsRecommended[j].idDetail == null || countJobsRecommended[j].idDetail == undefined) {
                  countJobsRecommended[j].price = arrayRecommended[i].price
                }
              }
            }
          }

          for (let j = 0; j < countJobsRecommended.length; j++) {
            countJobsRecommended[j].price = Number(countJobsRecommended[j].price);
          }

          setCountJobsRecommended(countJobsRecommended);

          if (!isPrinting) {
            notifySystem('Registrazione salvata con successo.')
          }

          jobsObject.forEach(async (element: any) => {
            await objreq.PostHttpAPI(``, "/api/WorkDetail", element);

            setActiveSpinner(false);
            if (isPrinting || hasPrinting) {

              console.log('entrooooo')

              setTimeout(() => {
                navigation.navigate("PdfScreen", [
                  clientService[0],
                  subtotal,
                  totalPrice,
                  iva,
                  serviceBudget,
                  watchBrand[0],
                  nroPrevent.response[0].nroPreventive,
                  DatosEnviados,
                  clockFaces,
                  bracelets,
                  conditionReciepts,
                  countJobsRequired,
                  countJobsRecommended,
                  city,
                ]);
              }, 200); budget.recommended
            }
          });
        }
      } else {

        const objreq = new api_capone<ResponseAPI<string>>();
        budget.nroPreventive = 1
        budget.clockFace = data.clockFace;
        budget.bracelet = data.bracelet;
        budget.conditionReciept = data.conditionReciept;
        budget.jobsRequired = data.jobsRequired;
        budget.recommended = data.recommended;
        budget.confirm = confirmationPreventivo ? false : confirmation;
        budget.confirmPreventivo = confirmation ? false : confirmationPreventivo;
        var respuesta = await objreq.PutHttpAPI(``, "/api/Payment", budget);
        const objreqRes = new api_capone<ResponseAPI<string>>();

        if (isTechnique) {

          let techniqueId = userInLogin.idUser;

          if (valueStatusPreventivo === 6) {
            var respuestaRes = await objreqRes.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatusPreventivo}/${techniqueId}`,
              "/api/Service/ChangeStatusService/",
              []
            );
          } else {
            var respuestaRes = await objreqRes.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatus}/${techniqueId}`,
              "/api/Service/ChangeStatusService/",
              []
            );
          }

        } else {
          console.log("aquiiii passso 2", valueStatus, valueStatusPreventivo);

          if (valueStatusPreventivo === 6) {
            var respuestaRes = await objreqRes.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatusPreventivo}/0`,
              "/api/Service/ChangeStatusService/",
              []
            );
          } else {
            var respuestaRes = await objreqRes.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatus}/0`,
              "/api/Service/ChangeStatusService/",
              []
            );
          }

        }

        let idPayment: any = respuesta.id;
        let allJobs = [
          ...countJobsRequired,
          ...countJobsRecommended
        ];

        let jobsObject: any = [];

        await objreq.DeleteHttpAPI(``, "/api/WorkDetail/" + idPayment);

        allJobs.forEach((element) => {
          let price = element.price;
          if (price.toString().includes(',')) {
            price = price.replace(',', '.')
          }
          jobsObject.push(
            {
              "idWork": Number(element.id),
              "price": Number(price),
              "idPayment": Number(idPayment)
            }
          )
        });

        allJobs.forEach((element) => {
          element.price = parseFloat(element.price).toFixed(2)
        });

        for (let j = 0; j < countJobsRequired.length; j++) {
          countJobsRequired[j].price = Number(countJobsRequired[j].price);
        }

        for (let i = 0; i < jobsObject.length; i++) {
          for (let j = 0; j < countJobsRequired.length; j++) {
            if (i == j) {
              if (countJobsRequired[j].idDetail == null || countJobsRequired[j].idDetail == undefined) {
                countJobsRequired[j].price = jobsObject[i].price
              }
            }
          }
        }

        setCountJobsRequired(countJobsRequired);

        let arrayRecommended = [];
        for (let i = countJobsRequired.length; i < jobsObject.length; i++) {
          arrayRecommended.push(jobsObject[i]);
        }

        for (let i = 0; i < arrayRecommended.length; i++) {
          for (let j = 0; j < countJobsRecommended.length; j++) {
            if (i == j) {
              if (countJobsRecommended[j].idDetail == null || countJobsRecommended[j].idDetail == undefined) {
                countJobsRecommended[j].price = arrayRecommended[i].price
              }
            }
          }
        }

        for (let j = 0; j < countJobsRecommended.length; j++) {
          countJobsRecommended[j].price = Number(countJobsRecommended[j].price);
        }

        setCountJobsRecommended(countJobsRecommended);

        let nroPrevent: any = await GetPaymentUpdate();

        if (!isPrinting) {
          notifySystem("Registrazione aggiornata con successo.")
        }

        jobsObject.forEach(async (element: any) => {
          await objreq.PostHttpAPI(``, "/api/WorkDetail", element);

          setActiveSpinner(false);

          if (isPrinting || hasPrinting == true) {
            setTimeout(() => {
              navigation.navigate("PdfScreen", [
                clientService[0],
                subtotal,
                totalPrice,
                iva,
                serviceBudget,
                watchBrand[0],
                nroPrevent.response[0].nroPreventive,
                DatosEnviados,
                clockFaces,
                bracelets,
                conditionReciepts,
                countJobsRequired,
                countJobsRecommended,
                city,
              ]);
            }, 200);
          }
        });

        await updateStatePreventive()


      }
      setActiveSpinner(false);
    }
  };

  const updateStatePreventive = async () => {
    await axios.put(`${ConfigCapone.urlapi}/api/Service/ModifyPreventive?idService=${serviceBudget.idService}&hasPreventive=${sendPreventivo}`);
    if (!serviceBudget.hasPreventive && sendPreventivo) {
      await sendEmailInviatoPreventivo(sendPreventivo)
    }
    serviceBudget.hasPreventive = sendPreventivo;
  }

  // const savePrinting = async () => {
  //   setIsPrinting(true);
  //   await updateStatePreventive()
  //   setTimeout(() => {
  //     let button: any = document.querySelector("#save-budget");
  //     button.click();
  //   }, 300);
  // }

  const SaveBudgetFoto = async (hasPrinting:boolean = false) => {
    setIsPrinting(true);
    setRequiredJobs(false);
    setRecommendedJobs(false);

    let totalPrice = 0;
    let acumPriceJobRequired: number = 0;
    let acumPriceJobRecommended: number = 0;

    let checkRequiredJobs: boolean = true;
    let checkRecommendedJobs: boolean = true;

    for (let i = 0; i < countJobsRequired.length; i++) {
      if (
        countJobsRequired[i].price != '' &&
        countJobsRequired[i].price != undefined
        && countJobsRequired[i].price != null) {

        let price = null;
        if (countJobsRequired[i].price.toString().includes(',')) {
          price = countJobsRequired[i].price.toString().replace(",", ".")
        } else {
          price = countJobsRequired[i].price.toString()
        }
        acumPriceJobRequired += Number(price);
      }
    }

    for (let i = 0; i < countJobsRequired.length; i++) {
      if (
        countJobsRequired[i].price == '' ||
        countJobsRequired[i].price == null ||
        countJobsRequired[i].price == undefined ||
        countJobsRequired[i].job == '' ||
        countJobsRequired[i].job == null ||
        countJobsRequired[i].job == undefined ||
        countJobsRequired[i].price == '0'
      ) {
        checkRequiredJobs = false;
        let newArr = [...countJobsRequired];
        newArr[i].hasError = true;

        setCountJobsRequired(newArr);
      } else {
        let newArr = [...countJobsRequired];
        newArr[i].hasError = false;

        setCountJobsRequired(newArr);
      }
    }

    for (let i = 0; i < countJobsRecommended.length; i++) {
      if (
        countJobsRecommended[i].price == '' ||
        countJobsRecommended[i].price == null ||
        countJobsRecommended[i].price == undefined ||
        countJobsRecommended[i].job == '' ||
        countJobsRecommended[i].job == null ||
        countJobsRecommended[i].job == undefined
      ) {
        checkRecommendedJobs = true;
        let newArr = [...countJobsRecommended];
        newArr[i].hasError = false;

        setCountJobsRecommended(newArr);
      } else {
        let newArr = [...countJobsRecommended];
        newArr[i].hasError = false;

        setCountJobsRecommended(newArr);
      }
    }


    //totalPrice = Number(acumPriceJobRequired) + Number(acumPriceJobRecommended);

    if (!checkRequiredJobs) {
      setRequiredJobs(true);
    }

    if (!checkRecommendedJobs) {
      setRecommendedJobs(true);
    }

    if (!checkRequiredJobs || !checkRecommendedJobs) {
      setActiveSpinner(false);
      return null;
    }

    totalPrice = Number(acumPriceJobRequired);

    setValue("subtotal1", totalPrice);
    setSubtotal(Number(totalPrice).toFixed(2));

    var result = Number(totalPrice);
    var iva = (result * 22) / 122;
    var resultIVA = Number(iva.toFixed(2));
    var subtotal = Number(result - iva).toFixed(2);
    onchangeLog(result, iva, resultIVA, subtotal);
    setSubtotal(subtotal);
    budget.subtotal = Number(subtotal);
    setIva(String(resultIVA));
    budget.iva = Number(resultIVA);
    var resulttotal = Number(subtotal + iva).toFixed(2);
    setTotal(String(result));
    budget.total = result ? Number(result) : 0;

    setActiveSpinner(true);

    let valueStatus = confirmation ? 2 : 1;
    let valueStatusPreventivo = confirmationPreventivo && 6;
    var errorFlag: boolean = false;
    var DescriptionError: string = "";

    budget.subtotal = totalPrice;

    if (budget.subtotal === 0) {
      errorFlag = true;
      setErrorPrecio(true);
      DescriptionError = "Prezzo mancante." + DescriptionError;
      setActiveSpinner(false);
      return null;
    }
    budget.idMethodPayment = 1;
    if (budget.idMethodPayment == 0) {
      errorFlag = true;
      setErrorPayment(true);
      DescriptionError = "Metodo di pagamento mancante. " + DescriptionError;
      setActiveSpinner(false);
      return null;
    }
    if (errorFlag) {
      Notificacion("Dati mancanti", DescriptionError);
      setActiveSpinner(false);
      return null;
    } else {
      if (saveEnable) {
        console.log("aqui es", confirmation, confirmationPreventivo);

        budget.nroPreventive = 1
        budget.idService = idServiceModule;
        budget.idStatusPay = 2;
        budget.active = true;
        budget.confirm = confirmationPreventivo ? false : confirmation;
        budget.confirmPreventivo = confirmation ? false : confirmationPreventivo;
        budget.clockFace = null;
        budget.bracelet = null;
        budget.conditionReciept = null;
        budget.jobsRequired = null;
        budget.recommended = null;



        const objreq = new api_capone<ResponseAPI<string>>();
        var respuesta = await objreq.PostHttpAPI(``, "/api/Payment", budget);
        const objreqTwo = new api_capone<ResponseAPI<string>>();

        if (isTechnique) {

          let techniqueId = userInLogin.idUser;

          if (valueStatusPreventivo === 6) {
            var respuestaRes = await objreqTwo.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatusPreventivo}/${techniqueId}`,
              "/api/Service/ChangeStatusService/",
              []
            );
          } else {
            var respuestaRes = await objreqTwo.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatus}/${techniqueId}`,
              "/api/Service/ChangeStatusService/",
              []
            );
          }

        } else {
          console.log("aquiiii passs", valueStatus);

          if (valueStatusPreventivo === 6) {

            var respuestaRes = await objreqTwo.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatusPreventivo}/0`,
              "/api/Service/ChangeStatusService/",
              []
            );
          } else {

            var respuestaRes = await objreqTwo.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatus}/0`,
              "/api/Service/ChangeStatusService/",
              []
            );
          }

        }

        if (respuesta.status == "success" && respuestaRes.status == "success") {
          let nroPrevent: any = await GetPaymentUpdate();
          if (!isPrinting) {
            notifySystem("Registrazione salvata con successo.")
          }

          setSaveEnable(true);

          let idPayment = respuesta.id;
          //saves jobs

          let allJobs = [
            ...countJobsRequired,
            ...countJobsRecommended
          ];

          let jobsObject: any = [];

          await objreq.DeleteHttpAPI(``, "/api/WorkDetail/" + idPayment);

          allJobs.forEach((element) => {
            let price = element.price;
            if (price.toString().includes(',')) {
              price = price.replace(',', '.')
            }
            jobsObject.push(
              {
                "idWork": Number(element.id),
                "price": Number(price),
                "idPayment": Number(idPayment)
              }
            )
          });

          allJobs.forEach((element) => {
            element.price = parseFloat(element.price).toFixed(2)
          });

          for (let j = 0; j < countJobsRequired.length; j++) {
            countJobsRequired[j].price = Number(countJobsRequired[j].price);
          }

          for (let i = 0; i < jobsObject.length; i++) {
            for (let j = 0; j < countJobsRequired.length; j++) {
              if (i == j) {
                if (countJobsRequired[j].idDetail == null || countJobsRequired[j].idDetail == undefined) {
                  countJobsRequired[j].price = jobsObject[i].price
                }
              }
            }
          }

          setCountJobsRequired(countJobsRequired);


          let arrayRecommended = [];
          for (let i = countJobsRequired.length; i < jobsObject.length; i++) {
            arrayRecommended.push(jobsObject[i]);
          }

          for (let i = 0; i < arrayRecommended.length; i++) {
            for (let j = 0; j < countJobsRecommended.length; j++) {
              if (i == j) {
                if (countJobsRecommended[j].idDetail == null || countJobsRecommended[j].idDetail == undefined) {
                  countJobsRecommended[j].price = arrayRecommended[i].price
                }
              }
            }
          }

          for (let j = 0; j < countJobsRecommended.length; j++) {
            countJobsRecommended[j].price = Number(countJobsRecommended[j].price);
          }

          setCountJobsRecommended(countJobsRecommended);

          await updateStatePreventive()

          jobsObject.forEach(async (element: any) => {
            await objreq.PostHttpAPI(``, "/api/WorkDetail", element);

            setActiveSpinner(false);
            if (hasPrinting) {
              console.log('entro222')
              setTimeout(() => {
                navigation.navigate("PdfScreenStampaFoto", [
                  clientService[0],
                  subtotal,
                  totalPrice,
                  iva,
                  serviceBudget,
                  watchBrand[0],
                  nroPrevent.response[0].nroPreventive,
                  DatosEnviados,
                  clockFaces,
                  bracelets,
                  conditionReciepts,
                  countJobsRequired,
                  countJobsRecommended,
                  city,
                ]);
              }, 200); budget.recommended
            }
          });
        }
      } else {

        const objreq = new api_capone<ResponseAPI<string>>();
        budget.nroPreventive = 1
        budget.clockFace = null;
        budget.bracelet = null;
        budget.conditionReciept = null;
        budget.jobsRequired = null;
        budget.recommended = null;
        budget.confirm = confirmationPreventivo ? false : confirmation;
        budget.confirmPreventivo = confirmation ? false : confirmationPreventivo;
        var respuesta = await objreq.PutHttpAPI(``, "/api/Payment", budget);
        const objreqRes = new api_capone<ResponseAPI<string>>();

        if (isTechnique) {

          let techniqueId = userInLogin.idUser;

          if (valueStatusPreventivo === 6) {
            var respuestaRes = await objreqRes.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatusPreventivo}/${techniqueId}`,
              "/api/Service/ChangeStatusService/",
              []
            );
          } else {
            var respuestaRes = await objreqRes.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatus}/${techniqueId}`,
              "/api/Service/ChangeStatusService/",
              []
            );
          }

        } else {
          console.log("aquiiii passso 2", valueStatus, valueStatusPreventivo);

          if (valueStatusPreventivo === 6) {
            var respuestaRes = await objreqRes.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatusPreventivo}/0`,
              "/api/Service/ChangeStatusService/",
              []
            );
          } else {
            var respuestaRes = await objreqRes.PostHttpAPI(
              `${serviceBudget.idService}/${valueStatus}/0`,
              "/api/Service/ChangeStatusService/",
              []
            );
          }

        }


        let idPayment: any = respuesta.id;
        let allJobs = [
          ...countJobsRequired,
          ...countJobsRecommended
        ];

        let jobsObject: any = [];

        await objreq.DeleteHttpAPI(``, "/api/WorkDetail/" + idPayment);

        allJobs.forEach((element) => {
          let price = element.price;
          if (price.toString().includes(',')) {
            price = price.replace(',', '.')
          }
          jobsObject.push(
            {
              "idWork": Number(element.id),
              "price": Number(price),
              "idPayment": Number(idPayment)
            }
          )
        });

        allJobs.forEach((element) => {
          element.price = parseFloat(element.price).toFixed(2)
        });

        for (let j = 0; j < countJobsRequired.length; j++) {
          countJobsRequired[j].price = Number(countJobsRequired[j].price);
        }

        for (let i = 0; i < jobsObject.length; i++) {
          for (let j = 0; j < countJobsRequired.length; j++) {
            if (i == j) {
              if (countJobsRequired[j].idDetail == null || countJobsRequired[j].idDetail == undefined) {
                countJobsRequired[j].price = jobsObject[i].price
              }
            }
          }
        }

        setCountJobsRequired(countJobsRequired);

        let arrayRecommended = [];
        for (let i = countJobsRequired.length; i < jobsObject.length; i++) {
          arrayRecommended.push(jobsObject[i]);
        }

        for (let i = 0; i < arrayRecommended.length; i++) {
          for (let j = 0; j < countJobsRecommended.length; j++) {
            if (i == j) {
              if (countJobsRecommended[j].idDetail == null || countJobsRecommended[j].idDetail == undefined) {
                countJobsRecommended[j].price = arrayRecommended[i].price
              }
            }
          }
        }

        for (let j = 0; j < countJobsRecommended.length; j++) {
          countJobsRecommended[j].price = Number(countJobsRecommended[j].price);
        }

        setCountJobsRecommended(countJobsRecommended);

        let nroPrevent: any = await GetPaymentUpdate();

        jobsObject.forEach(async (element: any) => {
          await objreq.PostHttpAPI(``, "/api/WorkDetail", element);

          setActiveSpinner(false);
          setTimeout(() => {
            navigation.navigate("PdfScreenStampaFoto", [
              clientService[0],
              subtotal,
              totalPrice,
              iva,
              serviceBudget,
              watchBrand[0],
              nroPrevent.response[0].nroPreventive,
              DatosEnviados,
              clockFaces,
              bracelets,
              conditionReciepts,
              countJobsRequired,
              countJobsRecommended,
              city,
            ]);
          }, 200);

        });
      }
      await updateStatePreventive();

      setActiveSpinner(false);

    }
  };

  const [workRequired, setWorkRequired] = useState<any>(work_required);
  const [dataJobRequired, setDataJobRequired] = useState<any>([]);
  const [dataJobRecommended, setDataJobRecommended] = useState<any>([]);
  const [dataJobRecommended1, setDataJobRecommended1] = useState<any>([]);
  const getDataJobs = async () => {

    await axios(`${ConfigCapone.urlapi}/api/WorkDetail/getListWorkRequired/1`)
      .then((response) => {
        setDataJobRequired(response.data.response);
      })
      .catch((error) => console.log(error));

    await axios(`${ConfigCapone.urlapi}/api/WorkDetail/getListWorkRequired/2`)
      .then((response) => {
        setDataJobRecommended(response.data.response);
      })
      .catch((error) => console.log(error));
  }

  const [requiredJobs, setRequiredJobs] = useState<any>(false);
  const [recommendedJobs, setRecommendedJobs] = useState<any>(false);

  const [countJobsRequired, setCountJobsRequired] = useState<any>([{
    id: null,
    job: '',
    price: '',
    hasError: false,
  }]);

  const [countJobsRecommended, setCountJobsRecommended] = useState<any>([{
    id: null,
    job: '',
    price: '',
    hasError: false,
  }]);

  const addJobsRequired = () => {
    if (countJobsRequired.length < 10) {
      setCountJobsRequired(
        [
          ...countJobsRequired,
          {
            id: null,
            job: '',
            price: '',
            hasError: false,
          }
        ],
      )

      let idName = countJobsRequired.length;

      setValue("subtotal-required-" + idName, 0);
    }
  }

  const removeJobRequired = (position: number) => {
    let currentJobs = [...countJobsRequired];
    let newJobs = [];

    currentJobs.filter((element, index) => {
      if (index != position) {
        newJobs.push(element);
      }
    });

    setCountJobsRequired(newJobs);

    for (let i = 0; i < newJobs.length; i++) {

      setValue("subtotal-required-" + i, Number(newJobs[i].price.toString().replace(',', '.')));
    }
  }

  const removeJobRecommended = (position: number) => {
    let currentJobs = [...countJobsRecommended];
    let newJobs = [];

    currentJobs.filter((element, index) => {
      if (index != position) {
        newJobs.push(element);
      }
    });



    setCountJobsRecommended(newJobs);

    for (let i = 0; i < newJobs.length; i++) {

      setValue("subtotal-recommended-" + i, Number(newJobs[i].price.toString().replace(',', '.')));
    }
  }

  const addJobsRecommended = () => {
    if (countJobsRecommended.length < 10) {
      setCountJobsRecommended(
        [
          ...countJobsRecommended,
          {
            id: null,
            job: '',
            price: '',
            hasError: false,
          }
        ],
      )

      let idName = countJobsRecommended.length;

      setValue("subtotal-recommended-" + idName, 0);
    }
  }

  const changeJobRequired = (dataValue: any, field: string, index: number) => {

    if (field == 'job') {
      let job = dataJobRequired.filter((data: any) => (data.idWork).toString() == dataValue);

      let newArr = [...countJobsRequired];
      newArr[index] = {
        ...newArr[index],
        [field]: job[0].desc_work
      };
      newArr[index].id = dataValue;

      setCountJobsRequired(newArr);
    } else {
      let newArr = [...countJobsRequired];
      newArr[index] = {
        ...newArr[index],
        [field]: dataValue
      };

      setCountJobsRequired(newArr);
    }
  }

  const changeJobRecommended = (dataValue: any, field: string, index: number) => {

    if (field == 'job') {
      let job = dataJobRecommended.filter((data: any) => (data.idWork).toString() == dataValue);

      if (job.length > 0) {
        let newArr = [...countJobsRecommended];
        newArr[index] = {
          ...newArr[index],
          [field]: job[0].desc_work
        };
        newArr[index].id = dataValue;

        setCountJobsRecommended(newArr);
      } else {
        let newArr = [...countJobsRecommended];
        newArr[index] = {
          ...newArr[index],
          [field]: ''
        };
        newArr[index].id = dataValue;

        setCountJobsRecommended(newArr);
      }

    } else {
      let newArr = [...countJobsRecommended];
      newArr[index] = {
        ...newArr[index],
        [field]: dataValue
      };

      setCountJobsRecommended(newArr);
    }
  }

  const isFocused = useIsFocused();
  useEffect(() => {
    getDataJobs();
    getAllDataJobs();
    TypesPaymentCatalog();
    GetPaymentUpdate();
    Getservice();
    getBrand();
    getProductItems();
    reset(valueDefect);
    getDefaultValuesMessageInviatoPreventivo();
  }, [isFocused]);
  const today = Date.now();
  const event = new Date(today);
  const optionsDate: any = { year: "numeric", month: "long", day: "numeric" };
  const confirmAction = (Landing: any, data: any) => {
    navigation.navigate(Landing, data);
  };
  const onchangeLog = (a: any, b: any, c: any, d: any) => { };
  ///console.log(brandType)

  const sendEmailInviatoPreventivo = async (e: any) => {
    console.log(e)
    // setActiveSpinner(true)
    let totalPrice = 0;
    let acumPriceJobRequired: number = 0;
    for (let i = 0; i < countJobsRequired.length; i++) {
      if (
        countJobsRequired[i].price != '' &&
        countJobsRequired[i].price != undefined
        && countJobsRequired[i].price != null) {

        let price = null;
        if (countJobsRequired[i].price.toString().includes(',')) {
          price = countJobsRequired[i].price.toString().replace(",", ".")
        } else {
          price = countJobsRequired[i].price.toString()
        }
        acumPriceJobRequired += Number(price);
      }
    }
    totalPrice = Number(acumPriceJobRequired);
    let nroPrevent: any = await GetPaymentUpdate();
    totalPrice = Number(acumPriceJobRequired);
    setValue("subtotal1", totalPrice);
    setSubtotal(Number(totalPrice).toFixed(2));
    var result = Number(totalPrice);
    var iva = (result * 22) / 122;
    var resultIVA = Number(iva.toFixed(2));
    var subtotal = Number(result - iva).toFixed(2);
    onchangeLog(result, iva, resultIVA, subtotal);
    setSubtotal(subtotal);
    budget.subtotal = Number(subtotal);
    setIva(String(resultIVA));
    let data = [
      clientService[0],
      subtotal,
      totalPrice,
      iva,
      serviceBudget,
      watchBrand[0],
      nroPrevent.response[0].nroPreventive,
      DatosEnviados,
      clockFaces,
      bracelets,
      conditionReciepts,
      countJobsRequired,
      countJobsRecommended,
      city
    ]
    try {
      // Renderiza el componente PdfScreenSendemailPreveti en formato HTML
      const htmlContent = renderToStaticMarkup(<PdfScreenSendemailPreveti data={data} />);
      console.log("htmlContent", data);
      // Configura las opciones para la generación del PDF
      const options = {
        margin: 10,
        filename: 'pdf_document.pdf',
        image: { type: 'png', quality: 0.98 },
        html2canvas: {
          scale: 2,
          dpi: 300,
          letterRendering: true,
          useCORS: true
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      // Genera el PDF como un objeto Blob
      const pdfBlob = await html2pdf().from(htmlContent).set(options).outputPdf('blob');
      const arrayBuffer = await new Response(pdfBlob).arrayBuffer();
      const byteArray = new Uint8Array(arrayBuffer);
      const pdfBlobaSend = new Blob([byteArray], { type: 'application/pdf' });
      if (clientService[0].email) {
        await sendEmail(pdfBlobaSend);
      }
    } catch (error) {
      // Manejo de errores
      console.error('Error al generar el PDF:', error);
      throw error;
    } finally {
      // setActiveSpinner(false)
    }
  };

  const sendEmail = async (blob) => {
    //setActiveSpinner(true)
    let _fReader = new FileReader();
    _fReader.readAsDataURL(blob);
    _fReader.onloadend = async function () {
      let dataSend = {
        type: "Preventivo",
        email: clientService[0].email,
        subject: getValues("subject"),
        message: getValues("message").replace(/\n/g, '<br>\n'),
        files: _fReader.result || null,
      };
      await axios
        .post(
          `${ConfigCapone.apicapone}/api/forma`,
          dataSend
        )
        .then((res) => {
          setLoading(false);
          setMessageSent(true);
        })
        .catch((err) => {
          setLoading(false);
          console.log("there was an error: " + err);
        }).finally(() => {
          setActiveSpinner(false);
        });
    };
  }
  async function getDefaultValuesMessageInviatoPreventivo() {
    setValue("subject", `Preventivo`);
    setValue(
      "message",
      `Gentile Cliente, 
       siamo a inviarLe il preventivo richiesto. 
       Rimaniamo in attesa di un riscontro e siamo a disposizione per qualsiasi ulteriore informazione. 
       Cordiali Saluti,`
    );
  }


  return (
    <ScrollView>
      {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      {!isAtelier &&
        <View
          style={{
            paddingHorizontal: 12,
            backgroundColor: "white",
          }}
        >
          <Header
            title="Preventivo"
            onBack={() => navigation.goBack()}
            hasRoundedIcon={false}
          />
        </View>
      }
      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="col-md-10 col-sm-10 col-12 m-auto">
          <View style={styles.container}>
            <div className="row mb-5">
              <div className="col-12">
                <Text
                  style={[
                    styles.left,
                    {
                      marginTop: 5,
                      fontWeight: "500",
                      fontSize: 20,
                      marginBottom: 30,
                    },
                  ]}
                >
                  Compila preventivo
                </Text>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>Data *</Text>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-5 col-sm-6 col-8">
                <input
                  className="form-control"
                  style={inputSmallNear}
                  disabled={true}
                  value={DateNow.toLocaleDateString("it-IT", optionsDate)}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>
                  Data scadenza
                </Text>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-5 col-sm-6 col-8">
                <input
                  className="form-control"
                  style={inputSmall}
                  disabled={true}
                  value={new Date(serviceBudget.dateService).toLocaleDateString(
                    "it-IT",
                    optionsDate
                  )}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>
                  Preventivo n°
                </Text>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-5 col-sm-6 col-8">
                <Controller
                  control={control}
                  name="numberBudget1"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <input
                      className="form-control"
                      maxLength={15}
                      style={inputSmall}
                      value={budget.nroPreventive}
                      onChange={(e: any) => {
                        onChange(e.target.value);
                        setNumberBudget(e.target.value);
                        budget.nroPreventive = e.target.value;
                      }}
                      disabled={true}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>Operatore</Text>
              </div>
              <div className="col-8">
                <TextInput
                  style={[
                    styles.inputPreventivo,
                    {
                      backgroundColor: "rgb(235, 235, 235)",
                      width: "100%",
                      color: "black",
                    },
                  ]}
                  editable={false}
                  selectTextOnFocus={false}
                  value={userInLogin ? `${userInLogin?.name}` : "Nessun dato"}
                />
              </div>
            </div>
            <View
              style={{
                marginLeft: 5,
                marginTop: 10,
                width: "100%",
                height: 1,
                backgroundColor: "lightgray",
              }}
            />
            <div className="row mt-5 mb-3">
              <div className="col-12">
                <Text
                  style={[
                    styles.left,
                    {
                      marginTop: 80,
                      fontWeight: "500",
                      fontSize: 20,
                      marginBottom: 30,
                    },
                  ]}
                >
                  Informazioni riparazione
                </Text>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>Orologio</Text>
              </div>
              <div className="col-8">
                <Controller
                  control={control}
                  name="watch"
                  render={({ field: { onChange, value, onBlur } }) => (
                    <select
                      style={inputLarge}
                      className="form-control mr-8 ml-8 "
                      onChange={(val) => onChange(val.target.value)}
                      aria-label=".form-select-lg example"
                      disabled={true}
                    >
                      <option selected>Seleziona</option>
                      {brandType.map((r: any) => (
                        <option
                          key={r.idBrand}
                          value={r.idBrand}
                          selected={r.idBrand == brandRecive}
                        >
                          {r.desc_Brand}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
            </div>
            <div className="row mt-5 mb-3">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>
                  Referenza
                </Text>
              </div>
              <div className="col-8">
                <Controller
                  control={control}
                  name="reference"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <input
                      className="form-control"
                      maxLength={15}
                      style={inputSmall}
                      value={serviceBudget.reference}
                      onChange={(e: any) => {
                        onChange(e.target.value);
                        setNumberBudget(e.target.value);
                        budget.nroPreventive = e.target.value;
                      }}
                      disabled={true}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mt-5 mb-3">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>
                  Matricola
                </Text>
              </div>
              <div className="col-8">
                <Controller
                  control={control}
                  name="matricola"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <input
                      className="form-control"
                      maxLength={15}
                      style={inputSmall}
                      value={serviceBudget.enrollment}
                      onChange={(e: any) => {
                        onChange(e.target.value);
                        setNumberBudget(e.target.value);
                        budget.nroPreventive = e.target.value;
                      }}
                      disabled={true}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mt-5 mb-3">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>
                  Numero Busta
                </Text>
              </div>
              <div className="col-8">
                <Controller
                  control={control}
                  name="envelopeNumber"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <input
                      className="form-control"
                      maxLength={50}
                      style={inputSmall}
                      value={serviceBudget.envelopeNumber}
                      onChange={(e) => onChange(e.target.value)}
                      disabled={true}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>Quadrante</Text>
              </div>
              <div className="col-8">
                <Controller
                  control={control}
                  name="clockFace"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <input
                      className="form-control mt-2"
                      value={value}
                      maxLength={25}
                      style={inputLargeNon}
                      onChange={(e) => { onChange(e.target.value, setClockFaces(e.target.value)) }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>Bracciale</Text>
              </div>
              <div className="col-8">
                <Controller
                  control={control}
                  name="bracelet"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <input
                      className="form-control mt-2"
                      value={value}
                      maxLength={25}
                      style={inputLargeNon}
                      onChange={(e) => { onChange(e.target.value), setBracelets(e.target.value) }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>
                  Condizione al ricevimento
                </Text>
              </div>
              <div className="col-8">
                <Controller
                  control={control}
                  name="conditionReciept"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <input
                      className="form-control mt-2"
                      value={value}
                      maxLength={150}
                      style={inputLargeNon}
                      onChange={(e) => { onChange(e.target.value), setConditionReciepts(e.target.value) }}
                    />
                  )}
                />
              </div>
            </div>
            <View
              style={{
                marginLeft: 5,
                width: "100%",
                height: 1,
                backgroundColor: "lightgray",
              }}
            />
            {
              countJobsRequired.map((element, index) => (
                <div key={index} className="row mt-3 mb-3">
                  <div className="col-4 mt-3">
                    <Text style={[styles.label, { marginLeft: 0 }]}>
                      Lavori richiesti
                    </Text>
                  </div>

                  {/* <div className="col-8">
                    <Controller
                      control={control}
                      name="jobsRequired"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <input
                          className="form-control mt-2"
                          value={value}
                          maxLength={25}
                          style={inputLargeNon}
                          onChange={(e) => {onChange(e.target.value), setJobsRequireds(e.target.value)}}
                        />
                      )}
                    />
                  </div> */}

                  <div className="col-8">
                    <select
                      className="form-select mr-8 ml-8"
                      onChange={({ target }) => changeJobRequired(target.value, 'job', index)}
                      style={inputLargeNon}
                      aria-label=".form-select-lg example"
                    >
                      <option disabled selected value="">Seleziona</option>
                      {dataJobRequired.map((data, index) => (
                        <option
                          key={index}
                          value={data.idWork}
                          selected={data.desc_work == element.job}
                        >
                          {data.desc_work}
                        </option>
                      ))}
                    </select>

                    {countJobsRequired.length > 1
                      &&
                      <div style={{
                        position: "absolute",
                        right: 0
                      }}>
                        <TouchableOpacity
                          style={{
                            flex: 1,
                            paddingHorizontal: 5,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onPress={() => removeJobRequired(index)}
                        >
                          <Image
                            style={{ width: 20, height: 20 }}
                            source={require("../assets/icons/icon-delete.png")}
                          />
                        </TouchableOpacity>
                      </div>
                    }

                  </div>

                  <div className="col-4 mt-3">
                    <Text style={[styles.label, { marginLeft: 0 }]}>
                      Prezzo
                    </Text>
                  </div>
                  <div className="col-8">

                    <Controller
                      control={control}
                      name={`subtotal-required-` + index}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CurrencyInput
                          className="form-control mt-3"
                          value={value ? Number(value).toFixed(2) : 0}
                          style={inputSmallMargin}
                          intlConfig={{ locale: "it-IT" }}
                          decimalsLimit={2}
                          onValueChange={(valueString: any) => {
                            onChange(
                              valueString ? valueString.replace(",", ".") : ""
                            );


                            changeJobRequired(valueString, 'price', index);
                          }}
                        />
                      )}
                    // rules={{ required: true, min: 1 }}
                    />

                    {element.hasError && (
                      <Text style={[stylesBudget.errorLabel]}>
                        {requiredMessajeErrorJob}
                      </Text>
                    )}
                  </div>

                </div>
              ))
            }

            <div>
              <TouchableOpacity
                style={{ marginTop: 5, marginBottom: 30 }}
                onPress={() => addJobsRequired()}
              >
                <Text style={[styles.label, { fontWeight: "bold" }]}>
                  + Aggiungi lavoro richiesto
                </Text>
              </TouchableOpacity>
            </div>
            <View
              style={{
                marginLeft: 5,
                width: "100%",
                height: 1,
                backgroundColor: "lightgray",
              }}
            />

            {
              countJobsRecommended.map((element, index) => (
                <div key={index} className="row mt-3 mb-3">
                  <div className="col-4 mt-3">
                    <Text style={[styles.label, { marginLeft: 0 }]}>
                      Lavori consigliati
                    </Text>
                  </div>
                  <div className="col-8">
                    <select
                      className="form-select mr-8 ml-8"
                      onChange={({ target }) => changeJobRecommended(target.value, 'job', index)}
                      style={inputLargeNon}
                      aria-label=".form-select-lg example"
                    >
                      <option selected value="0">Seleziona</option>
                      {dataJobRecommended.map((data, index) => (
                        <option
                          key={index}
                          selected={data.desc_work == element.job}
                          value={data.idWork}
                        >
                          {data.desc_work}
                        </option>
                      ))}
                    </select>

                    {countJobsRecommended.length > 1
                      &&
                      <div style={{
                        position: "absolute",
                        right: 0
                      }}>
                        <TouchableOpacity
                          style={{
                            flex: 1,
                            paddingHorizontal: 5,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onPress={() => removeJobRecommended(index)}
                        >
                          <Image
                            style={{ width: 20, height: 20 }}
                            source={require("../assets/icons/icon-delete.png")}
                          />
                        </TouchableOpacity>
                      </div>
                    }

                  </div>

                  <div className="col-4 mt-3">
                    <Text style={[styles.label, { marginLeft: 0 }]}>
                      Prezzo
                    </Text>
                  </div>
                  <div className="col-8">

                    <Controller
                      control={control}
                      name={`subtotal-recommended-` + index}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CurrencyInput
                          className="form-control mt-3"
                          value={value ? Number(value).toFixed(2) : 0}
                          style={inputSmallMargin}
                          intlConfig={{ locale: "it-IT" }}
                          decimalsLimit={2}
                          onValueChange={(valueString: any) => {
                            onChange(
                              valueString ? valueString.replace(",", ".") : ""
                            );



                            changeJobRecommended(valueString, 'price', index);
                          }}
                        />
                      )}
                    // rules={{ required: true, min: 1 }}
                    />

                    {element.hasError && (
                      <Text style={[stylesBudget.errorLabel]}>
                        {requiredMessajeErrorJob}
                      </Text>
                    )}

                  </div>

                </div>
              ))
            }

            <div>
              <TouchableOpacity
                style={{ marginTop: 5, marginBottom: 30 }}
                onPress={() => addJobsRecommended()}
              >
                <Text style={[styles.label, { fontWeight: "bold" }]}>
                  + Aggiungi lavoro consigliato
                </Text>
              </TouchableOpacity>
            </div>

            <div className="row mt-3 mb-3 d-none">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>Prezzo *</Text>
              </div>
              <div className="col-5">
                <Controller
                  control={control}
                  name="subtotal1"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CurrencyInput
                      className="form-control"
                      value={value ? Number(value).toFixed(2) : 0}
                      style={inputSmallMargin}
                      intlConfig={{ locale: "it-IT" }}
                      decimalsLimit={2}
                      onValueChange={(valueString: any) => {
                        onChange(
                          valueString ? valueString.replace(",", ".") : ""
                        );
                        let value = valueString
                          ? valueString.replace(",", ".")
                          : "";
                        var result = Number(value);
                        var iva = (result * 22) / 122;
                        var resultIVA = Number(iva.toFixed(2));
                        var subtotal = Number(result - iva).toFixed(2);
                        onchangeLog(result, iva, resultIVA, subtotal);
                        setSubtotal(subtotal);
                        budget.subtotal = Number(subtotal);
                        setIva(String(resultIVA));
                        budget.iva = Number(resultIVA);
                        var resulttotal = Number(subtotal + iva).toFixed(2);
                        setTotal(String(result));
                        budget.total = result ? Number(result) : 0;
                      }}
                    />
                  )}
                // rules={{ required: true, min: 1 }}
                />
                {errors.subtotal1?.type === "required" && (
                  <Text style={[stylesBudget.errorLabel]}>
                    {requiredMessajeError}
                  </Text>
                )}
                {errors.subtotal1?.type === "min" && (
                  <Text style={[stylesBudget.errorLabel]}>
                    {minMessageerror}
                  </Text>
                )}
              </div>
            </div>
            <View
              style={{
                marginLeft: 5,
                marginTop: 10,
                width: "100%",
                height: 1,
                backgroundColor: "lightgray",
              }}
            />
            <div className="row mt-5 mb-3 d-none">
              <div className="col-12">
                <Text
                  style={[
                    styles.left,
                    {
                      marginTop: 80,
                      fontWeight: "500",
                      fontSize: 20,
                      marginBottom: 30,
                    },
                  ]}
                >
                  Informazioni pagamento
                </Text>
              </div>
            </div>

            <div className="row mt-3 mb-3 d-none">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>
                  Metodo di pagamento
                </Text>
              </div>
              <div className="col-8">
                <Controller
                  control={control}
                  name="paymentMethod"
                  render={({ field: { onChange, value, onBlur } }) => (
                    <select
                      className="form-select mr-8 ml-8"
                      value={1}
                      onChange={(val) => {
                        onChange(val.target.value);
                        setTypePaymentSelected(Number(val.target.value));
                        budget.idMethodPayment = Number(val.target.value);
                      }}
                      style={inputLargeNon}
                      aria-label=".form-select-lg example"
                    >
                      <option value="">Seleziona</option>
                      {catalogTypesPayment &&
                        catalogTypesPayment.map((r: any, index: number) => (
                          <option
                            key={index}
                            value={r.idTypesPayment}
                            selected={
                              Number(r.idMethodPayment) === 1
                            }
                          >
                            {r.desc_TypesPayment}
                          </option>
                        ))}
                    </select>
                  )}
                // rules={{ required: true }}
                />
                {errors.paymentMethod?.type === "required" && (
                  <Text style={[styles.label, stylesBudget.errorLabel]}>
                    {requiredMessajeError}
                  </Text>
                )}
              </div>
            </div>

            <View
              style={{
                marginLeft: 5,
                width: "100%",
                height: 1,
                backgroundColor: "lightgray",
              }}
            />
            <div className="row mt-3 mb-3 d-none">
              <div className="col-4 mt-3">
                <Text style={[styles.label, { marginLeft: 0 }]}>Iva</Text>
              </div>
              <div className="col-5">
                <input
                  className="form-control ml-4"
                  style={inputSmallMargin}
                  disabled={true}
                  value={
                    total ? String(Number(iva).toFixed(2)).replace(".", ",") : 0
                  }
                />
              </div>
            </div>
            <View
              style={{
                marginLeft: 5,
                width: "100%",
                height: 1,
                backgroundColor: "lightgray",
              }}
            />
            <View
              style={[
                styles.left,
                { borderBottomWidth: 0, flexDirection: "row" },
              ]}
            >
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inputConfirmation"
                  checked={confirmation}
                  onChange={(e) => {
                    setConfirmation(e.target.checked);
                    budget.confirm = e.target.checked;
                    console.log("preventivo 0 ", e.target.checked);
                    setConfirmationPreventivo(false);
                  }}
                />
                <label className="form-check-label" htmlFor="inputConfirmation">
                  Conferma preventivo
                </label>
              </div>
            </View>

            <View
              style={[
                styles.left,
                { borderBottomWidth: 0, flexDirection: "row", marginTop: -10 },
              ]}
            >
              <div className=" form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inputConfirmationPreventivo"
                  checked={confirmationPreventivo}
                  onChange={(e) => {
                    setConfirmationPreventivo(e.target.checked);
                    budget.confirmPreventivo = e.target.checked;
                    console.log("preventivo ", e.target.checked);
                    setConfirmation(false);

                  }}
                />
                <label className="form-check-label" htmlFor="inputConfirmationPreventivo">
                  Preventivo rifiutato
                </label>
              </div>
            </View>

            <br />
            <View
              style={[
                styles.left,
                { borderBottomWidth: 0, flexDirection: "row", marginTop: -20 },
              ]}
            >
              <div className=" form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inputSendPreventivo"
                  checked={sendPreventivo}
                  disabled={flgEmialExist}
                  onChange={(e) => setSendPreventivo(e.target.checked)}
                /*onChange={(e) => {                    
                  sendEmailInviatoPreventivo(e.target.checked)
                  //budget.confirmPreventivo = e.target.checked;
                  console.log("preventivo ", e.target.checked);
                  setConfirmation(false);
                }}*/
                />
                <label className="form-check-label" htmlFor="inputSendPreventivo">
                  Invia  preventivo
                </label>
              </div>
              {messageSent && (
                <div>
                  <div className="col-12">
                    <div className="alert mb-0 mt-2 alert-success" role="alert" style={{ display: 'flex', top: -25, left: 35 }}>
                      <p className="mb-0">Preventivo inviato con successo</p>
                    </div>
                  </div>
                </div>
              )}
            </View>

            <div className="row mt-4">
              <div className="col-12 ">
                <div className="d-flex justify-content-end">
                  <button
                    className="mr-3"
                    id="save-budget-foto"
                    style={{
                      width: 180,
                      height: 40,
                      alignSelf: "center",
                      backgroundColor: "transparent",
                      borderRadius: 50,
                      alignItems: "center",
                      justifyContent: "center",
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                    }}
                    // onClick={() => savePrinting()}
                    onClick={handleSubmit((e) => SaveBudget(e,true))}
                  >
                    Stampa senza foto
                  </button>
                  <button
                    className="mx-3"
                    style={{
                      width: 140,
                      height: 40,
                      alignSelf: "center",
                      backgroundColor: "transparent",
                      borderRadius: 50,
                      alignItems: "center",
                      justifyContent: "center",
                    }}                   
                    onClick={() => SaveBudgetFoto(true)}
                  >
                    Stampa
                  </button>
                  <button
                    id="save-budget"
                    style={{
                      width: 140,
                      height: 40,
                      alignSelf: "center",
                      backgroundColor: "black",
                      color: "white",
                      borderRadius: 50,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleSubmit(SaveBudget)}
                  >
                    Salva
                  </button>
                </div>
              </div>
            </div>
            <View style={{ flexDirection: "row", marginTop: 40 }}></View>
          </View>
        </div>
      </div>
      {modalMessageWithRoute.active && (
        <PopupMessage
          title={modalMessageWithRoute.title}
          message={modalMessageWithRoute.message}
          stateModal={modalMessageWithRoute.active}
          setStateModal={setModalMessageWithRoute}
        />
      )}
      {modalMessage.active && (
        <PopupMessage
          title={"Informazione"}
          message={modalMessage.message}
          stateModal={modalMessage.active}
          setStateModal={setModalMessage}
        />
      )}
    </ScrollView>

  );
};
const stylesBudget = StyleSheet.create({
  errorLabel: {
    color: "red",
    alignContent: "center",
    justifyContent: "center",
  },
});

export default BudgetScreen;



