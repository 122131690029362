import React, {useState, useEffect} from "react";
import { TasksItem } from "./TasksItem";
import { TaskList } from "./interfaces/TaskList";
import '../../styles/Notify.css'
import '../../styles/PinTriangular.css'
interface Props {
  notify: TaskList[];
  items: TaskList[];
  listIdTaskBussinesView:[];
  GetListByIdTaskBussines
  
  confirmviewTask
  setIsOpenFormEdit
  isOpenFormEdit:boolean
  setDataEdit;
  defaultListTask;
  getIdRole;
  setIsOpenFormEditDisabled
  isOpenFormEditDisabled
}
export const ContentTasks = ({ notify,getIdRole, defaultListTask, items,listIdTaskBussinesView,GetListByIdTaskBussines,confirmviewTask,setIsOpenFormEdit,setDataEdit,isOpenFormEdit, setIsOpenFormEditDisabled, isOpenFormEditDisabled}: Props) => {
  

  const [idRole, setIdRole] = useState()

  useEffect(() => {
    getIdRole().then(e => {
      setIdRole(e)
    })
  }, [])

  ;
  return (
    <div className="position-relative">
      <div className="triangulo"></div>
      <div className="buble-notify d-flex flex-column justify-content-center align-items-center p-2 w-100">
        {notify.length > 0 ? (
          <>
            {notify.map(({ idTask,title,text,nameFile,idTaskBussines,idTaskBussinesUsers,idUser,isChecked ,idTaskUser, nameImage,dateInsert }) => (
              <TasksItem
                  setDataEdit={setDataEdit}
                  defaultListTask={defaultListTask}
                  setIsOpenFormEdit={setIsOpenFormEdit}
                  isOpenFormEdit={isOpenFormEdit}
                  isCheckedRegister={isChecked}
                  confirmviewTask={confirmviewTask}
                  GetListByIdTaskBussines={GetListByIdTaskBussines}
                  listIdTaskBussinesView={listIdTaskBussinesView}
                  key={idTask}
                  idTaskBussines={idTaskBussines}
                  idTaskBussinesUsers={idTaskBussinesUsers}
                  idUser={idUser}
                  idTaskUser={idTaskUser}
                  title={title}
                  detail={text}
                  nameFile={nameFile}
                  nameImage={nameImage}
                  id={idTask}
                  items={items}
                  idRole={idRole}
                  setIsOpenFormEditDisabled={setIsOpenFormEditDisabled}
                  isOpenFormEditDisabled={isOpenFormEditDisabled}
                  dateInsert={dateInsert}
              />
            ))}
          </>
        ) : (
          <h4 className="text-center mt-4 mb-4 fs-6">Nessuna task</h4>
        )}
      </div>
    </div>
  );
};
