import React from 'react'
import EventTodayItem from './EventTodayItem'

const ContentToday = ({eventsToday}) => {
  const isSingleEvent = eventsToday.length > 1;
  return (
    <div className="position-relative">
      <div className="triangulo"/>
      <div className={`buble-notify d-flex flex-column ${isSingleEvent ? '' : 'justify-content-center'} align-items-center p-2 w-100`} style={{ maxHeight: 150, overflowY: 'auto', scrollbarWidth: 'thin' }}>
        {eventsToday.length > 0 ? (
          <>
             {eventsToday.map(({ id, title, details, start, end }) => (
                <EventTodayItem 
                  id={id} 
                  title={title} 
                  detail={details} 
                  start={start}
                  end={end}
                />
             ))}
          </>
        ) : (
          <h4 className="text-center mt-4 mb-4 fs-6 pt-1">Nessuna evento oggi</h4>
        )}
      </div>
    </div>
  )
}

export default ContentToday
