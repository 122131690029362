import { useNotify } from "../../hooks/useNotify";
import Loading from "../Loading";
import { ContentNotify } from "./ContentNotify";
import { FooterNotify } from "./FooterNotify";
import { HeaderNotify } from "./HeaderNotify";


export const Notify = () => {
  const { notify } = useNotify();
  if (!notify) return <Loading activeSpinner={!notify} />;
  return (
    <div className="w-100 pe-4">
      <HeaderNotify notify={notify.length} />
      <ContentNotify notify={notify.items} items={notify.normalizedResults} />
      <FooterNotify items={notify.normalizedResults} />
    </div>
  );
};
