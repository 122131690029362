import { useContext, useEffect, useState } from "react";

export const useProfile = () => {
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    return {
        sidebarVisible,
        setSidebarVisible,
        toggleSidebar
    };

};
