import { useFormik } from "formik";
import React from "react";
import { useFormRepair } from "./useFormRepair";

export const useAditional = () => {
  const { initialValues, next } = useFormRepair();
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        try {
          next(values);
          setSubmitting(false);
        } catch (error) {
          console.log(error);
        }
      }, 500);
    },
  });
  const handleFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const files = evt.target.files;
    if (files) {
      formik.setFieldValue("file", files[0]);
      formik.setFieldValue("fileName", URL.createObjectURL(files[0]));
    }
  };
  const image = formik.values.file
    ? formik.values.fileName
    : require("../../assets/default/default-photo.png");
  return { formik, image, handleFile };
};
