import React from "react";
import { Collapse } from "react-collapse";
import { useShow } from "../../../../../hooks/useShow";
import { CollapseTitleAtelier } from "../../../../Magazzino/Atelier/views/CollapseTitleAtelier";
import { CheckAtelier } from "./CheckAtelier";

interface Props {
  list: any[];
  handleFilter: (value: string) => void;
}
export const FilterCrmCheckboxHobby = ({
  list,
  handleFilter,
}: Props) => {
  const { show, toggle } = useShow();
  return (
    <>
      <CollapseTitleAtelier title="Interessi             " toggle={toggle} />
      <Collapse isOpened={show}>
        <div className="col-12">
          {list?.map(({ idHobby, hobby, image }) => (
            <div key={idHobby}>
              <CheckAtelier
                name={idHobby}
                id={'hobby-'+idHobby}
                text={""}
                marginleft
                handlechange={handleFilter}
              />
              <img src={require(`../../../../../assets/icons-step-due/${image}`)} alt="" style={{width: 36}} />
              <br />
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};
