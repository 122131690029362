export const filterGenders = [
    {
        name: "gender",
        id: "field-men",
        type: "text",
        text: "Uomo"
    },
    {
        name: "gender",
        id: "field-women",
        type: "text",
        text: "Donna"
    },
    {
        name: "gender",
        id: "field-other",
        type: "text",
        text: "Altro"
    }
]

export const filterGender = [
    {
        name: "gender",
        id: "field-men",
        type: "text",
        text: "Uomo"
    },
    {
        name: "gender",
        id: "field-women",
        type: "text",
        text: "Donna"
    },
    {
        name: "gender",
        id: "field-other",
        type: "text",
        text: "Altro"
    }
]

export const filterAddress = [
    {
        name: "address",
        id: "field-room",
        type: "text",
        text: "Abitazione"
    },
    {
        name: "address",
        id: "field-building",
        type: "text",
        text: "Ufficio"
    }
]

export const filterPhone = [
    {
        name: "phone",
        id: "field-room",
        type: "text",
        text: "Abitazione"
    },
    {
        name: "phone",
        id: "field-cell",
        type: "text",
        text: "Cellulare"
    },
    {
        name: "phone",
        id: "field-office",
        type: "text",
        text: "Ufficio"
    }
]

export const filterStar = [
    {
        name: "star",
        id: "star-3",
        type: "text",
        text: "***"
    },
    {
        name: "star",
        id: "star-2",
        type: "text",
        text: "***"
    },
    {
        name: "star",
        id: "star-1",
        type: "text",
        text: "***"
    }
]

export const purchasedBrands = [
    {
        name: "brand",
        id: "field-rolex",
        type: "text",
        text: "Rolex"
    },
    {
        name: "brand",
        id: "field-cartier",
        type: "text",
        text: "Cartier"
    }
]

export const spentValue = [
    {
        name: "spent_value",
        id: "spentValue-1",
        type: "text",
        text: "300 >"
    },
    {
        name: "spent_value",
        id: "spentValue-2",
        type: "text",
        text: "100 a 300"
    },
    {
        name: "spent_value",
        id: "spentValue-3",
        type: "text",
        text: "50 a 100"
    }
]

export const age = [
    {
        name: "age",
        id: "age-1",
        type: "text",
        text: "50 <"
    },
    {
        name: "age",
        id: "age-2",
        type: "text",
        text: "30 - 50"
    },
    {
        name: "age",
        id: "age-3",
        type: "text",
        text: "20 - 30"
    }
]

export const entryDate = [
    {
        name: "entry_date",
        id: "dateInsert-1",
        type: "text",
        text: "Da più di 1 mese"
    },
    {
        name: "entry_date",
        id: "dateInsert-2",
        type: "text",
        text: "Ultimo mese"
    },
    {
        name: "entry_date",
        id: "dateInsert-3",
        type: "text",
        text: "Ultimi 7 giorni"
    },
    {
        name: "entry_date",
        id: "dateInsert-4",
        type: "text",
        text: "Ultimi 3 giorni"
    },
    {
        name: "entry_date",
        id: "dateInsert-5",
        type: "text",
        text: "Ultime 24 ore"
    }
]

export const updateDate = [
    {
        name: "update_date",
        id: "dateUpdate-1",
        type: "text",
        text: "Da più di 1 mese"
    },
    {
        name: "update_date",
        id: "dateUpdate-2",
        type: "text",
        text: "Ultimo mese"
    },
    {
        name: "update_date",
        id: "dateUpdate-3",
        type: "text",
        text: "Ultimi 7 giorni"
    },
    {
        name: "update_date",
        id: "dateUpdate-4",
        type: "text",
        text: "Ultimi 3 giorni"
    },
    {
        name: "update_date",
        id: "dateUpdate-5",
        type: "text",
        text: "Ultime 24 ore"
    }
]

export const others = [
    {
        name: "others",
        id: "clienteSuPiùListe-4",
        type: "text",
        text: "Cliente su più liste"
    },
    {
        name: "others",
        id: "deprecati-1",
        type: "text",
        text: "Deprecati"
    },
    {
        name: "others",
        id: "blackList-2",
        type: "text",
        text: "Black list"
    },
    {
        name: "others",
        id: "enviaCatalogo-3",
        type: "text",
        text: "Invia Catalogo"
    },
    {
        name: "others",
        id: "Nodeprecati-5",
        type: "text",
        text: "No deprecati"
    },
    {
        name: "others",
        id: "NoMailingList-6",
        type: "text",
        text: "No mailing list"
    }
    
]
export const others2 = [
    
    {
        name: "others",
        id: "deprecati-1",
        type: "text",
        text: "Deprecati"
    },
    {
        name: "others",
        id: "blackList-2",
        type: "text",
        text: "Black list"
    },
    {
        name: "others",
        id: "enviaCatalogo-3",
        type: "text",
        text: "Invia Catalogo"
    },
    {
        name: "others",
        id: "Nodeprecati-5",
        type: "text",
        text: "No deprecati"
    },
    {
        name: "others",
        id: "NoMailingList-6",
        type: "text",
        text: "No mailing list"
    }
    
]

export const clientData = [
    {
        id: 1,
        name: 'Mattia Rossi',
        email: 'mattia.rossi@gmail.com',
        phone: '3494567890'
    },
    {
        id: 2,
        name: 'Luigi Bianchi',
        email: 'l.bianchi@gmail.com',
        phone: '3494567890'
    },
    {
        id: 3,
        name: 'Lisa Verdi',
        email: 'lisaverdi@gmail.com',
        phone: '3494567890'
    },
    {
        id: 4,
        name: 'Mattia Sbrana',
        email: 'maria@gmail.com',
        phone: '3494567890'
    },
    {
        id: 5,
        name: 'Luca Palla',
        email: 'lucapalla11@gmail.com',
        phone: '3494567890'
    },
    {
        id: 6,
        name: 'Giovanna Neri',
        email: 'g.neri@gmail.com',
        phone: '3494567890'
    },
]