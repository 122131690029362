import React, { useContext, useEffect, useState } from "react";
import {
  Image,
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { styles } from "../theme/GlobalStyle";
import SelectDropdown from "react-native-select-dropdown";
import { AdminData } from "../interfaces/IAdminData";
import { useForms } from "../hooks/useForm";
import axios from "axios";
import ConfigCapone from "../utils/ConfigCapone";
import { HeaderModal } from "../components/HeaderModal";
import { Input } from "react-native-elements";
import IconF from "react-native-vector-icons/FontAwesome";
import Icon from "react-native-vector-icons/Ionicons";
import Loading from "../components/Loading";
import { useForm, Controller } from "react-hook-form";
import PopUpMessageWithRoute from "../components/PopUpMessageWithRoute";
import { UserContext } from "../context/UserContext";
import Cookies from 'universal-cookie';
import { validateEmail } from "../helpers/patterns";
import { id } from 'date-fns/locale';
interface IEditUserScreen {
  modalVisible: boolean;
  setModalVisible: any;
  data?: any | undefined;
  edit: boolean;
  setAddUser: any;
  allusers: any;
}
const messageError = "Il campo è obbligatorio ";
const messaggeErrorStyle = {
  fontSize: 18,
  marginLeft: 35,
  marginTop: 5,
  fontFamily: "Arsapia Regular font",
  color: "red",
};
const EditUserScreen = ({
  modalVisible,
  setModalVisible,
  data,
  edit,
  setAddUser,
  allusers,
}: IEditUserScreen) => {
  const baseUrl = `${ConfigCapone.urlapi}/api`;
  const [idroleselect, setidroleselect] = useState(0);
  const [idbussneseselect, setidBussineseselect] = useState(0);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });
   //cookie
   const cookies = new Cookies();
  const [showStore, setShowStore] = useState(false);
  let allData: AdminData[] = allusers;
  let admin: AdminData = {
    id_User: 0,
    name_User: "",
    password_User: "",
    rol_User: idroleselect,
    store_User: idbussneseselect,
    des_buss: "",
    des_rol: "",
  };
  const [roleapi, setRole] = useState([
    {
      desc_Role: "",
      idRole: 0,
    },
  ]);
  const [BussinnessArray, setBussinnessArray] = useState([
    {
      desc_Bussines: "",
      idBussines: 0,
    },
  ]);
  const [listBussines, setlistBussines] = useState([
    {
      desc_Bussines: "",
      idBussines: 0,
    },
  ]);

  const getBussiness = async () => {
    const res = await axios(baseUrl + "/Bussines?parameter=1")
      .then((response) => {
        setBussinnessArray(response.data.response)
        setlistBussines(response.data.response)
      })
      .catch((error) =>  setModalMessage({
              active: true,
              message: "Si è verificato un errore ",
            }));
  };
  const getRoles = async () => {
    const response = await axios
      .get(baseUrl + "/Role?parameter=desc_Role")
      .then((response) => {
        setRole(response.data.response);
      })
      .catch((error) => {
         setModalMessage({
              active: true,
              message: "Si è verificato un errore ",
            });
      });
  };
 

  const confirmAction = (l: any, n: any) => {
    setModalVisible(false);
  };

  const valueDefect = {
    user: data.userName ? data.userName : "",
    password: "",
    rol: data.idRole ? data.idRole : "",
    bussiness: data.idBussines ? data.idBussines : "",
    email:data.emailUser?data.emailUser:""
  };


  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: valueDefect,
  });
  if (edit) {
    admin.id_User = data.idUser;
    admin.name_User = data.name;
    admin.password_User = data.pass;
    admin.rol_User = data.idRole;
    admin.store_User = data.idBussines;
    admin.des_rol = data.desc_Role;
    admin.des_buss = data.desc_Bussines;
  } else {
    admin.id_User = 0;
    admin.name_User = "";
    admin.password_User = "";
    admin.rol_User = 1;
    admin.store_User = 1;
    admin.des_rol = "";
    admin.des_buss = "";
  }
  const {
    name_User,
    password_User,
    rol_User,
    store_User,
    des_buss,
    des_rol,
    onChange,
  } = useForms({
    name_User: admin.name_User,
    password_User: "",
    rol_User: admin.rol_User,
    store_User: admin.store_User,
    des_buss: admin.des_buss,
    des_rol: admin.des_rol,
  });
  const indexrol = roleapi.filter((rol) => rol.desc_Role == String(des_rol));
  const indexbuss = BussinnessArray.filter(
    (buss) => buss.desc_Bussines == String(des_buss)
  );
  const [showPassword, setShowPassword] = useState(true);
  const updateAdmin = async (dat: any) => {

    setActiveSpinner(true);
    const response = await axios
      .put(`${baseUrl}/User`, {
        idUser: data.idUser,
        name: getValues("user"),
        lastName: "",
        userName: getValues("user").trim(),
        pass: getValues("password").trim(),
        idBussines: dat.bussiness ? Number(dat.bussiness) : 1,
        idRole: Number(getValues("rol")),
        active: true,
        emailUser:getValues("email").trim()
      },{
        headers: {
          'Authorization': `Bearer ${cookies.get('token')}`
        }
      })
      .catch((err) => {
        setActiveSpinner(false);
        setModalMessage({
          active: true,
          message: "Si è verificato un errore durante il salvataggio.",
        });
      });
    if (response) {
      setActiveSpinner(false);
      setModalMessage({
        active: true,
        message: "Utente aggiornato con successo",
      });
    } else {
      setActiveSpinner(false);
      setModalMessage({
        active: true,
        message: "Si è verificato un errore durante il salvataggio.",
      });
    }
    setActiveSpinner(false);
  };

  const addAdmin = async (dat: any) => {

    setActiveSpinner(true);
    const id = Number(allData.slice(-1).pop()?.id_User) + 1;
    const dataadd: AdminData = {
      id_User: id,
      name_User: getValues("user").trim(),
      password_User: getValues("password").trim(),
      rol_User: Number(getValues("rol")),
      store_User: Number(store_User),
      des_buss: des_buss,
      des_rol: des_rol,
      emailUser:getValues("email").trim()

    };
    const addAdmin: AdminData[] = [...allData, dataadd];
    const response = await axios
      .post(`${baseUrl}/User`, {
        idUser: 0,
        name: getValues("user"),
        lastName: "",
        userName: getValues("user").trim(),
        pass: getValues("password").trim(),
        idBussines: dat.bussiness ? Number(dat.bussiness) : 1,
        idRole: Number(getValues("rol")),
        emailUser:getValues("email").trim(),
        active: true,
      },{
        headers: {
          'Authorization': `Bearer ${cookies.get('token')}`
        }
      })
      .catch((err) => {
        setActiveSpinner(false);
        setModalMessage({
          active: true,
          message: "Si è verificato un errore durante il salvataggio.",
        });
      });
    if (response) {
      setActiveSpinner(false);
      setModalMessage({ active: true, message: "Utente creato con successo" });
    } else {
      setActiveSpinner(false);
      throw setModalMessage({
        active: true,
        message: "Si è verificato un errore durante il salvataggio.",
      });
    }
    setAddUser(addAdmin);
    setActiveSpinner(false);
  };

  const valueRol = (val: any, first: boolean = false) => {
    setBussinnessArray([])
    if (val === 2 || val === "2" || val === "9" || val === undefined || val==="") {
      setShowStore(false)
      setValue('bussiness', 1)
    } else {
      if(Number(val) === 4 || Number(val) === 5) {
        setBussinnessArray(listBussines)
      }else{
        if(Number(val) === 3 || Number(val) === 7 || Number(val) === 8) {
          setBussinnessArray( listBussines.filter( x => x.idBussines == 1 || x.idBussines == 3) )
        }else{
          setBussinnessArray( listBussines.filter( x => x.idBussines != 9) )
        }
      }
      setShowStore(true)
    }
    if(!first) {
      setValue('bussiness', 1)
    }
  }
  useEffect(() => {
    getRoles();
    getBussiness();
    reset(valueDefect);
  }, []);

  useEffect(() => {
    if(data.idUser != 0 && listBussines.length > 1){
      valueRol(data ? data.idRole : 2, true);
    }
  }, [listBussines]);

  const defaultBussines={
    idBussines: data.idBussines,
    desc_Bussines: data.desc_Bussines,
    active: false,
    total: '0'
  }
  return (
    <View style={stylesEdit.centeredView}>
      {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      <Modal animationType="slide" propagateSwipe={true} transparent={true} visible={modalVisible}>
      <ScrollView style={{width: "100%", height: "100%"}}> 
        <View
          style={[
            stylesEdit.centeredView,
            { backgroundColor: "rgba(0,0,0,.32)", paddingTop: 130, paddingBottom: 130 },
          ]}
        >
          <View style={[stylesEdit.modalView]}>
            <HeaderModal
              setModalVisible={setModalVisible}
              modalVisible={modalVisible}
              title={edit ? "Modifica credenziali" : "Nuovo"}
              activatedPadLeft={false}
            />
           
            <View>
              <Text style={[styles.label, { marginLeft: 28 }]}>Nome utente</Text>
              <Controller
                control={control}
                name="user"
                render={({ field: { onChange, value, onBlur } }) => (
                  <Input
                    autoCompleteType="username"
                    inputContainerStyle={[
                      styles.input,
                      {
                        borderBottomWidth: 2,
                        borderColor: "black",
                        borderStyle: "solid",
                        marginBottom: -2,
                      },
                    ]}
                    inputStyle={{ fontSize: 16 }}
                    style={{
                      WebkitBoxShadow: "0 0 0 1000px white inset",
                      outlineStyle: "none",
                      paddingLeft: 8,
                      maxWidth: "85%",
                    }}
                    underlineColorAndroid="transparent"
                    leftIcon={<IconF name="user" size={28} color="black" />}
                    value={value}
                    onChangeText={(text) => onChange(text)}
                    labelStyle={{
                      color: "red",
                      fontFamily: "Montserrat-Light",
                      fontWeight: "normal",
                    }}
                  />
                )}
                rules={{ required: true }}
              />
              {errors.user?.type === "required" && (
                <Text style={messaggeErrorStyle}> {messageError}</Text>
              )}
              <Text style={[styles.label, { marginTop: "8%", marginLeft: 22 }]}>Password</Text>
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, value, onBlur } }) => (
                  <Input
                    autoCompleteType="password"
                    inputContainerStyle={[
                      styles.input,
                      {
                        borderBottomWidth: 2,
                        borderColor: "black",
                        borderStyle: "solid",
                        marginBottom: -2,
                      },
                    ]}
                    underlineColorAndroid="transparent"
                    style={{
                      WebkitBoxShadow: "0 0 0 1000px white inset",
                      outlineStyle: "none",
                      paddingLeft: 8,
                      maxWidth: "100%",
                    }}
                    leftIcon={<IconF name="lock" size={28} color="black" />}
                    rightIcon={
                      <TouchableOpacity
                        onPress={() => setShowPassword(!showPassword)}
                      >
                        <Image
                          source={require(showPassword
                            ? "../assets/iconoskubitsoft/Oculto-01.png"
                            : "../assets/iconoskubitsoft/Oculto-01.png")}
                          style={stylesEdit.iconImage}
                        />
                      </TouchableOpacity>
                    }
                    rightIconContainerStyle={{ marginRight: "2%" }}
                    secureTextEntry={showPassword}
                    onChangeText={(text) => onChange(text)}
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />
             {errors.password?.type === "required" && (
                <Text style={messaggeErrorStyle}> {messageError}</Text>
              )}
              <Text style={[styles.label, { marginTop: "8%", marginLeft: 22 }]}>E-mail</Text>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value, onBlur } }) => (
                  <Input
                    autoCompleteType="username"
                    inputContainerStyle={[
                      styles.input,
                      {
                        borderBottomWidth: 2,
                        borderColor: "black",
                        borderStyle: "solid",
                        marginBottom: -2,
                      },
                    ]}
                    inputStyle={{ fontSize: 16 }}
                    style={{
                      WebkitBoxShadow: "0 0 0 1000px white inset",
                      outlineStyle: "none",
                      paddingLeft: 8,
                      maxWidth: "85%",
                    }}
                    underlineColorAndroid="transparent"
                    leftIcon={<IconF name="envelope" size={28} color="black" />}
                    value={value}
                    onChangeText={(text) => onChange(text)}
                    labelStyle={{
                      color: "red",
                      fontFamily: "Montserrat-Light",
                      fontWeight: "normal",
                    }}
                  />
                )}
                rules={{
                  // pattern:
                  //   /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g,
                  pattern:validateEmail,
                }}
              />
              {errors.email?.type === "pattern" && (
              <Text style={messaggeErrorStyle}>E-mail errata</Text>
            )}
              <Text style={[styles.label, { marginTop: "8%", marginLeft: 22 }]}>Ruolo</Text>
              <Controller
                control={control}
                name="rol"
                render={({ field: { onChange, value, onBlur } }) => (
                  <>
                    <select
                      value={value}
                      onChange={(val) =>{ onChange(val.target.value)
                       valueRol(val.target.value)
                      }}
                      aria-label=".form-select-lg example"
                      style={{
                        width: "93%",
                        height: 50,
                        borderRadius: 50,
                        paddingLeft: "5%",
                        marginLeft: 10,
                        fontFamily: "Arsapia Regular font",
                        flexDirection: "row",
                        alignItems: "center",
                        borderColor: "black",
                        borderWidth: 2,
                        fontSize:18
                      }}
                      // defaultValue={value?value:roleapi[0].idRole}
                    >
                      <option value="">Seleziona</option>
                      {roleapi.map((r: any) => (
                        <option
                          key={r.idRole}
                          value={r.idRole}
                          selected={Number(r.idRole) === Number(data.idStatus)}
                        >
                          {r.desc_Role}
                        </option>
                      ))}
                    </select>
                    
                  </>
                )}
                rules={{ required: true }}
              />

              {errors.rol?.type === "required" && (
                <Text style={messaggeErrorStyle}> {messageError}</Text>
              )}

              {showStore === true &&
                <>
                  <Text style={[styles.label, { marginTop: "8%", marginLeft: 22 }]}>Negozio di</Text>
                  <Controller
                    control={control}
                    name="bussiness"
                    render={({ field: { onChange, value, onBlur } }) => (
                      <>
                        <select
                      value={value}
                      onChange={(val) => onChange(val.target.value)}
                      aria-label=".form-select-lg example"
                      style={{
                        width: "93%",
                        height: 50,
                        borderRadius: 50,
                        paddingLeft: "5%",
                        marginLeft: 10,
                        fontFamily: "Arsapia Regular font",
                        flexDirection: "row",
                        alignItems: "center",
                        borderColor: "black",
                        borderWidth: 2,
                        fontSize:18
                      }}
                     
                    >
                      <option value="">Seleziona</option>
                      {BussinnessArray.map((r: any) => (
                        <option
                          key={r.idBussines}
                          value={r.idBussines}
                          
                        >
                          {r.desc_Bussines}
                        </option>
                      ))}
                    </select>
                        
                      </>
                    )}
                    rules={{ required: true }}
                  />
                  {errors.bussiness?.type === "required" && (
                    <Text style={messaggeErrorStyle}> {messageError}</Text>
                  )}
                </>
              }
              <View>
                {edit ? (
                  <TouchableOpacity
                    activeOpacity={0.6}
                    style={stylesEdit.touchableView}
                    onPress={handleSubmit(updateAdmin)}
                  >
                    <View
                      style={[
                        styles.button,
                        { marginEnd: "6%", marginTop: "3%", height: 35, width: 100 },
                      ]}
                    >
                      <Text style={styles.buttonText}>Salva</Text>
                    </View>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    activeOpacity={0.6}
                    style={stylesEdit.touchableView}
                    onPress={handleSubmit(addAdmin)}
                  >
                    <View
                      style={[
                        styles.button,
                        { marginEnd: "6%", marginTop: "3%", height: 35, width: 100 },
                      ]}
                    >
                      <Text style={styles.buttonText}>Salva</Text>
                    </View>
                  </TouchableOpacity>
                )}
              </View> 
            
            </View>
        
          </View>
        </View>
        </ScrollView>
      </Modal>
      {modalMessage.active && (
        <PopUpMessageWithRoute
          title={"Informazione"}
          message={modalMessage.message}
          stateModal={modalMessage.active}
          setStateModal={setModalMessage}
          confirmAction={confirmAction}
        />
      )}
    </View>
  );
};
export const stylesEdit = StyleSheet.create({
  touchableView: {
    alignSelf: "flex-end",
    marginTop: 30,
  },
  iconImage: {
    paddingLeft: -10,
    margin: -40,
    height: 100,
    width: 100,
    resizeMode: "stretch",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 22,
  },
  modalView: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: 20,
    paddingTop: 15,
    paddingBottom: 45,
    paddingLeft: 45,
    paddingRight: 50,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  right: {
    alignSelf: "flex-end",
  },
  inputStyle: {
    flex: 1,
  },
  icon: {
    paddingLeft: 10,
    fontSize: 16,
  },
  dropdown1BtnStyle: {
    height: "8%",
    backgroundColor: "transparent",
    borderRadius: 100,
    borderWidth: 2,
    borderColor: "black",
    margin: 18,
    paddingLeft: 10,
    fontFamily: "Arsapia Regular font",
  },
  dropdown1BtnTxtStyle: { color: "#444", textAlign: "left", fontSize: 16 },
  dropdown1DropdownStyle: { backgroundColor: "#EFEFEF" },
  dropdown1RowStyle: {
    paddingVertical: 8,
    backgroundColor: "#EFEFEF",
    borderBottomColor: "#C5C5C5",
  },
  dropdown1RowTxtStyle: { color: "#444", textAlign: "left", paddingStart: 20 },
  container: {
    width: "89.5%",
    height: 50,
    marginRight: "6%",
    marginLeft: "8.5%",
    borderRadius: 50,
    marginVertical: 6,
    paddingLeft: "5%",
    fontFamily: "Arsapia Regular font",
    flexDirection: "row",
    alignItems: "center",
    borderColor: "black",
  },
});
export default EditUserScreen;
