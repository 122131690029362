import { StyleSheet } from "react-native";

export const headerBarStyle = StyleSheet.create({
    container: {
        height: 120,
        paddingTop: 50,
        paddingBottom: 30,
        marginBottom: 10,
        paddingHorizontal: 5,
        flexDirection: 'row',
    },
    leftButtonContainer : {
        backgroundColor: "white",
        flex: 0.6,
        left: 10,
        justifyContent: 'center',
    },
    leftButton: {
        width: 40,
        height: 40,
        alignSelf: 'flex-start',
        tintColor: 'black',
    },

    container2: {
        flex: 3, 
        backgroundColor: "white",
        flexDirection: 'row'
    },
    sectionIconContainer: {
        backgroundColor: 'black',
        borderRadius: 50,
        justifyContent: 'center',
        width: 55,
        height: 55,
    },
    sectionIcon: {
        width: 35,
        height: 35,
        tintColor: 'white', 
        alignSelf: 'center',
    },
    sectionTextContainer: {
        paddingLeft: 12, 
        width: 200,
        marginTop: 8,
        flex:1
    },
    sectionText: {
        fontSize: 24,
        marginTop: 2,
        fontWeight: '500'
    },

    container3: { flex: 2, 
        // backgroundColor: "white", 
        // justifyContent: 'flex-start'
    },
    sectionLogo: {
        width: 150,
        height: 50,
        // tintColor: 'black',
        right: 0,
        alignSelf: 'flex-end',
    }
});