import React from "react";

interface Props {
  id: string;
  name: string;
  text: string;
  marginleft: boolean;
  handlechange: (value: string) => void;
}
export const CheckAtelier = ({
  id,
  marginleft,
  name,
  text,
  handlechange,
}: Props) => {
  const handleCheck = (value: string) => {
    const info = value
    handlechange(info)
  };
  return (
    <>
      <label
        htmlFor={id}
        style={{
          fontSize: 18,
          marginBottom: 5,
          fontFamily: "Arsapia Regular font",
        }}
      >
        <input
          type="checkbox"
          className="checksMagazzino"
          style={{
            margin: marginleft ? 0 : 10,
            marginLeft: marginleft ? 0 : 10,
            marginRight: marginleft ? 15 : 10,
            fontSize: 100,
          }}
          name={name}
          id={id}
          onChange={(evt) => handleCheck(evt.target.id)}
        />
        {text}
      </label>
    </>
  );
};
