import axios from "axios";
import aplicationConnect from "../../../../api/applicationConnect";
import { ResponseGetAddresType } from "../interfaces/GetAddresstype";
import { ResponseGetBrand } from "../interfaces/GetBrand";
import { ResponseGetBussines } from "../interfaces/GetBussines";
import { ResponseGetCLientById, ResponseGetClient } from "../interfaces/GetClient";
import { ResponseClientRelationShip } from "../interfaces/GetClientRelationShip";
import { ResponseGetFindByClientLimit } from "../interfaces/GetFindByClientLimit";
import { ResponseGetGender } from "../interfaces/GetGender";
import { ResponseGetHobby } from "../interfaces/GetHobby";
import { ResponseGetPhoneType } from "../interfaces/GetPhonetype";
import { ResponseGetRelationShip } from "../interfaces/GetRelationShip";
import { DataSearchClientTableCrm, ResponseGetSearchClientTableCrm } from "../interfaces/GetSearchClientTableCrm";
import { ResponseGetStatus } from "../interfaces/GetStatus";
import { ResponseGetTitle } from "../interfaces/GetTitle";
import { ResponseGetUserById } from "../interfaces/GetUserById";
import { IFormCrmAtelier, IFormHobby, IFormProdottiPosseduti, IFormProdottoOsservati, IFormRelazioni, IFormStatus } from "../interfaces/IFormCrmAtelier";
import { DataPost } from "../interfaces/PostFormaData";
import { GeneralParams } from "../interfaces/PropsServices";
import ConfigCapone from "../../../../utils/ConfigCapone";
import { IClient } from "../../../../interfaces/IClient";
import { ResponseGetClienHobby } from "../interfaces/GetClientHobby";
import { ResponseGetClientProdposseduti } from "../interfaces/GetClientProdpossedutti";
import { ResponseGetProdasservati } from "../interfaces/GetProdasservati";
export const httpGetTitle = async ({ parameter = "1", value, amountShow, currentPageNumber }: GeneralParams) => {
    const response = await aplicationConnect.get<ResponseGetTitle>("/Title", {
        params: {
            parameter,
            value,
            amountShow,
            currentPageNumber,
        }
    })
    return response.data;
}

export const httpGetGender = async ({ parameter = "1", value, amountShow, currentPageNumber }: GeneralParams) => {
    const response = await aplicationConnect.get<ResponseGetGender>("/Gender", {
        params: {
            parameter,
            value,
            amountShow,
            currentPageNumber,
        }
    })
    return response.data;
}

export const httpGetBussines = async ({ parameter = "1", value, amountShow, currentPageNumber }: GeneralParams) => {
    const response = await aplicationConnect.get<ResponseGetBussines>("/Bussines", {
        params: {
            parameter,
            value,
            amountShow,
            currentPageNumber,
        }
    })
    return response.data;
}

export const httpGetAddresType = async ({ parameter = "1", value, amountShow, currentPageNumber }: GeneralParams) => {
    const response = await aplicationConnect.get<ResponseGetAddresType>("/Addresstype", {
        params: {
            value,
            amountShow,
            currentPageNumber,
            parameter,
        }
    });
    return response.data;
}

export const httpGetPhonetype = async ({ parameter = "1", value, amountShow, currentPageNumber }: GeneralParams) => {
    const response = await aplicationConnect.get<ResponseGetPhoneType>("/Phonetype", {
        params: {
            value,
            amountShow,
            currentPageNumber,
            parameter,
        }
    })
    return response.data;
}

export const httpGetRelationShip = async ({ parameter = "1", value, amountShow, currentPageNumber }: GeneralParams) => {
    const response = await aplicationConnect.get<ResponseGetRelationShip>("/RelationShip", {
        params: {
            value,
            amountShow,
            currentPageNumber,
            parameter,
        }
    })
    return response.data;
}

export const httpGetBrand = async ({ parameter = "1", value, amountShow, currentPageNumber }: GeneralParams) => {
    const response = await aplicationConnect.get<ResponseGetBrand>("/Brand", {
        params: {
            value,
            amountShow,
            currentPageNumber,
            parameter,
        }
    })
    return response.data;
}

export const httpGetHobby = async ({ parameter = "1", value, amountShow, currentPageNumber }: GeneralParams) => {
    const response = await aplicationConnect.get<ResponseGetHobby>("/Hobby", {
        params: {
            value,
            amountShow,
            currentPageNumber,
            parameter,
        }
    })
    return response.data;
}

export const httpGetClient = async (name: string, lasted = 10) => {
    const response = await aplicationConnect.get<ResponseGetClient>(`/Client/FindAtelierByLimit/${name}/${lasted}`);
    return response.data;
}

export const httpGetClientRelationship = async ({ parameter = "1", value, amountShow, currentPageNumber }: GeneralParams) => {
    const response = await aplicationConnect.get<ResponseClientRelationShip>("/ClientRelationship", {
        params: {
            value,
            amountShow,
            currentPageNumber,
            parameter,
        }
    });
    return response.data;
}

export const httpGetSearchClientTableCrm = async (idClient: string | number) => {
    const response = await aplicationConnect.get<ResponseGetSearchClientTableCrm>(`/Client/SearchClientTableCrm`, { params: { idClient } });
    return response.data;
}

export const httpGetStatus = async ({ parameter = "1", value, amountShow, currentPageNumber }: GeneralParams) => {
    const response = await aplicationConnect.get<ResponseGetStatus>(`/Status`, {
        params: {
            value,
            amountShow,
            currentPageNumber,
            parameter,
        }
    });
    return response.data;
}

export const httpGetUserById = async (id: number | string) => {
    const response = await aplicationConnect.get<ResponseGetUserById>(`/User/${id}`);
    return response.data;
}

export const httpGetFindByClientLimit = async (idClient: string | number) => {
    const response = await aplicationConnect.get<ResponseGetFindByClientLimit>(`/StatusClient/FindByClientLimit/${idClient}`);
    return response.data;
}

export const httpPostSaveClientCrm = async (data: IFormCrmAtelier) => {
    const response = await aplicationConnect.post(`/Client/SaveClientCrm`, data);
    return response.data;
}

export const httpPostForma = async (data: DataPost) => {
    const response = await axios.post(`${ConfigCapone.apicapone}/api/forma`, data);
    return response.data;
}

export const httpPostFormaCustom = async (data) => {
    const response = await axios.post(`${ConfigCapone.apicapone}/api/forma`, data);
    return response.data;
}
export const httpPostFormaNoSignature = async (data) => {
    const response = await axios.post(`${ConfigCapone.apicapone}/api/forma/no-signature`, data);
    return response.data;
}


export const httpPostHobby = async (data: IFormHobby) => {
    const response = await aplicationConnect.post(`/ClientHobby`, data);
    return response.data;
}

export const httpPostClientRelationship = async (data: IFormRelazioni) => {
    const response = await aplicationConnect.post(`/ClientRelationship`, data);
    return response.data;
}

export const httpPostClientProdasservati = async (data: IFormProdottoOsservati) => {
    const response = await aplicationConnect.post(`/ClientProdasservati`, data);
    return response.data;
}

export const httpPostProduttoProciduti = async (data: IFormProdottiPosseduti) => {
    const response = await aplicationConnect.post(`/ClientProdposseduti`, data);
    return response.data;
}

export const httpPostStatusCLient = async (data: IFormStatus) => {
    const response = await aplicationConnect.post(`/StatusClient`, data);
    return response.data;
}

export const httpGetClientById = async (idClient: number | string) => {
    const response = await aplicationConnect.get<ResponseGetCLientById>(`/Client/${idClient}`);
    return response.data;
}

export const httpGetClientHobby = async (IdClient:number | string) => {
    const response = await aplicationConnect.get<ResponseGetClienHobby>(`/ClientHobby/SearchHobbyByClient/${IdClient}`);
    return response.data;
}

export const httpGetProduttoProciduti = async (idClient:number | string) => {
    const response = await aplicationConnect.get<ResponseGetClientProdposseduti>(`/ClientProdposseduti/idClient/${idClient}`);
    return response.data;
}

export const httpGetClientProdasservati = async (idClient:number | string) => {
    const response = await aplicationConnect.get<ResponseGetProdasservati>(`/ClientProdasservati/idClient/${idClient}`, );
    return response.data;
}

export const httpPostChangeStattusService = async (idService:number | string, idStatus:number | string, idTecnico:number | string) => {
    const response = await aplicationConnect.post(`/Service/ChangeStatusService/${idService}/${idStatus}/${idTecnico}`);
    return response.data;
}

export const httpPutModifyClientCrm = async (data:IFormCrmAtelier) => {
    const response = await aplicationConnect.put(`/Client/ModifyClientCrm`, data);
    return response.data;
}

export const httpDeletHobby = async (idHobby:number | string) => {
    const response = await aplicationConnect.delete(`/ClientHobby/${idHobby}`);
    return response.data;
} 

export const httpPutProduttoProsseduti = async (data:IFormProdottiPosseduti) => {
    const response = await aplicationConnect.put(`/ClientProdposseduti/${data.idClientProdPosseduti}`, data);
    return response.data;
}

export const httpDeleteProduttoProsseduti = async (idClientProdPosseduti:number | string) => {
    const response = await aplicationConnect.delete(`/ClientProdposseduti/${idClientProdPosseduti}`);
    return response.data;
}

export const httpPutProdottoOsservati = async (data:IFormProdottoOsservati) => {
    const response = await aplicationConnect.put(`/ClientProdasservati/${data.idClientProdAsservati}`, data);
    return response.data;
}

export const httpDeleteProdottoOsservati = async (idClientProdAsservati:number | string) => {
    const response = await aplicationConnect.delete(`/ClientProdasservati/${idClientProdAsservati}`);
    return response.data;
}

export const httpDeleteClientRelationship = async (idClientRelationship:number | string) => {
    const response = await aplicationConnect.delete(`/ClientRelationship/${idClientRelationship}`);
    return response.data;
}

export const httpPutClientRelationship = async (data:IFormRelazioni) => {
    const response = await aplicationConnect.put(`/ClientRelationship/${data.idClientRelationship}`, data);
    return response.data;
}

export const httpDeleteRepair = async (id:number | string) => {
    const response = await aplicationConnect.delete(`/Service/${id}`);
    return response.data;
}

export const httpGetUserAssitenza = async () => {
    const response = await aplicationConnect.get<ResponseGetProdasservati>(`/User?parameter=1`);
    return response.data;
}

export const httpGetServiceById = async (idService:number | string) => {
    const response = await aplicationConnect.get<any>(`/Service/${idService}`);
    return response.data;
}

export const loadDataForUpdateCrmAssistenza = async (data: DataSearchClientTableCrm) => {
    let dataService = await axios
        .get(`${ConfigCapone.urlapi}/api/Service/${data.idService}`)
        .then((response) => {
            return response.data.response;
        })
        .catch((err) => console.log(err));
    let dataClient = await axios
        .get(
            `${ConfigCapone.urlapi}/api/Client?value=${data.idClient}&parameter=idClient`
        )
        .then((response) => {
            return response.data.response[0];
        })
        .catch((err) => console.log(err));
    let brandDefault = await axios
        .get(`${ConfigCapone.urlapi}/api/Brand/${dataService.idBrand}`)
        .then((response) => response.data.response);
    let bussinesDefault = await axios
        .get(`${ConfigCapone.urlapi}/api/Bussines/${dataService.idBussines}`)
        .then((response) => response.data.response);
    let clientResult = await axios
        .get(`${ConfigCapone.urlapi}/api/Client/${data.idClient}`)
        .then((response) => response.data.response.idTitle);
    let titleDefault = await axios
        .get(`${ConfigCapone.urlapi}/api/Title/${clientResult}`)
        .then((response) => response.data.response);
    let dataJoin = await {
        ...dataClient,
        ...dataService,
        brandDefault,
        bussinesDefault,
        titleDefault,
        tipo: "StateRepair",
    };
    return dataJoin;
 }

export const httpGetMagazzinoByID = async (id:number | string) => {
    const response = await aplicationConnect.get<any>(`/Magazzino/${id}`);
    return response.data;
}

export const httpPutMagazzinoQuantity = async (data) => {
    const response = await aplicationConnect.put(`/Magazzino/ModifyQuantity/${data.idMagazzino}/${data.quantity}/${data.negozio}`, );
    return response.data;
}

export const httpPutMatricolaAtelier = async (data) => {
    const response = await aplicationConnect.put(`/Matricola/${data.idMatricola}`, data);
    return response.data;
}

export const httpDeleteMatricolaAtelier = async (id:number | string) => {
    const response = await aplicationConnect.delete(`/Matricola/${id}`);
    return response.data;
} 

export const httpPostSaleAtelier = async (data) => {
    const response = await aplicationConnect.post(`/Sales`, data);
    return response.data;
}
