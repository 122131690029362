import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { StyleSheet } from "react-native";

interface Props {
  placeholder: string;
  handleSearchFilter: () => void;
  marginRight: boolean;
  onChangeTextProdotto:any;
  searchInputProdotto:any;
  dataExcelMagazzino:any;
  setLoading:any;
  setgriglia:any;
  griglia:any;
  donwnloadExcel:any;
  DataSet:any;
  ExcelFile:any;
  nameFileExport:any;
  ExcelSheet:any;
  handleDataExport:any;
  width:any;

}
export const FilterMagazzinoAtelier = ({
  placeholder,
  onChangeTextProdotto,
  searchInputProdotto,
  handleSearchFilter,
  marginRight= false,
  dataExcelMagazzino,
  setLoading,
  setgriglia,
  griglia,
  donwnloadExcel,
  DataSet,
  ExcelFile,
  nameFileExport,
  ExcelSheet,
  handleDataExport,
  width
}: Props) => {

 
  return (
      <>
        <div className="d-flex justify-content-end align-items-center">
          {width > 1111 && 
            <button className="button-container text-truncate" style={{minWidth: 160}} onClick={() => { griglia ? setgriglia(false) : setgriglia(true) }}>{griglia ? 'Vista a tabella' : 'Vista a griglia'}</button>
          } 
          {
              (donwnloadExcel  && width > 1111 &&
              DataSet[0].data.length > 0) && (
              <ExcelFile filename={nameFileExport} hideElement>
                  <ExcelSheet dataSet={DataSet} name="Lista prodotti" />
              </ExcelFile>
              )
          }
          { width > 1111 && 
            <button className="button-container text-truncate" type="button" style={{minWidth: 150}} onClick={() => { handleDataExport() }}>Esporta lista</button>
          }
          <input
            className="input-cerca mr-2"
            type="text"
            placeholder="Cerca ..."
            onChange={(event) => onChangeTextProdotto(event.target.value)}
            value={searchInputProdotto}
          />
          <p className="container-icon-search" onClick={() => handleSearchFilter()}>
              <IoSearchOutline
                color="#ffffff"
                size={20}
                className=""
                style={{ cursor: "pointer" }}
              />
          </p>
        </div>
      </>
  );
};

const stylesRepair = StyleSheet.create({
  input: {
    height: 45,
    width: "110%",
    borderWidth: 2,
    fontFamily: "Arsapia Regular font",
    padding: 6,
    borderRadius: 50,
    marginRight: "1%",
  },
  searchText: {
    alignSelf: "flex-end",
    borderColor: "#A4A4A4",
    paddingRight: 2,
    paddingLeft: 15,
    fontSize: 20,
  },
  searchIcon: {
    width: 20,
    height: 20,
    tintColor: "white",
    alignSelf: "center",
  },
  viewDisable: {
    backgroundColor: "black",
    borderRadius: 50,
    justifyContent: "center",
    width: 40,
    height: 40,
    marginTop: "2%",
  },
});
