import React from 'react'
import { FlatList, Pressable, View } from 'react-native'
import { GetItemText } from '../../../../components/MagazzinoRefactor/GetItemText';


export const SuggestionsMagazzinoAtelierReferenzia = ({ autosuggestInput, setSearchInput, setAutosuggestInput }) => {
  console.log('autosuggestInput',autosuggestInput)
  return (
    <div className="col-12" style={{ float: 'right', width: 298 }}>
      <View
        style={{
          borderWidth: 1,
          marginTop: -25,
          paddingTop: 15,
          marginLeft: 2,
          marginRight: 34,
          borderTopWidth: 0,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        }}
      >
        <FlatList
          data={autosuggestInput}
          showsVerticalScrollIndicator={false}
          renderItem={({ item, index }) => (
            <Pressable
              style={({ pressed }) => [
                { opacity: pressed ? 0.5 : 1 },
              ]}
              onPress={() => {
                setSearchInput(item.referencia),
                  setAutosuggestInput([]);
              }}
            >
              {GetItemText(item)}
            </Pressable>
          )}
          keyExtractor={(item, index) =>
            item.idClient + index
          }
        />
      </View>
    </div>

  )
}
