import { useNavigation } from "@react-navigation/native";
import { closedSidebar } from "../helpers/css";
import { NavigationProps } from "../navigator/NavigatorConfig";
import { useProfile } from "../components/NavProfile/hook/useProfile";

export const useNavigationWhitProps = () => {
  const navigationWhitProps = useNavigation<NavigationProps>();
  const { setSidebarVisible } = useProfile()

  const handleNavigate = (component: any, section?: string) => {
    section 
     ?  navigationWhitProps.navigate(component, { section })
     :  navigationWhitProps.navigate(component)
    setSidebarVisible(false);
    closedSidebar();
  };

  return { handleNavigate, navigationWhitProps };
};
