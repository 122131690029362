import { useNavigation } from "@react-navigation/native";
import { Notify } from "../../interfaces/Notify";
import { NavigationProps } from "../../navigator/NavigatorConfig";

interface Props {
  items: Notify[]
}
export const FooterNews = ({ items }: Props) => {
  const navigation = useNavigation<NavigationProps>();
  return (
    <div className="d-flex justify-content-end">
      <a
        onClick={() => navigation.navigate("NewsScreen", {
          items
        })}
        className="px-3 text-white  "
        style={{textDecoration:'none',background:'#359ffc',borderRadius:'10px', cursor:'pointer', fontSize:'.8em',marginTop:'.5em'}}
      >
        <span>Vedi tutto</span>
      </a>
    </div>
  );
};
