interface Props{
    title:string;
    ModalAction : () => void;
    disabled: boolean
}
const ButtonWhite = ({title,ModalAction, disabled}:Props) => {
    return (  <button
        style={{
          flex: 1,
          width: 150,
          height: 40,
          alignSelf: "center",
          backgroundColor: "transparent",
          borderRadius: 50,
          borderColor: "black",
          alignItems: "center",
          justifyContent: "center",
        }}
        disabled={disabled}
        onClick={() => {
            ModalAction();
        }}
        type="submit"
      >
        
       {title}
      </button> );
}
 
export default ButtonWhite;