import { FcHome } from "react-icons/fc";
import { ImUserPlus } from "react-icons/im";
import { RiHome7Fill } from "react-icons/ri";
import { BsGearFill } from "react-icons/bs";
import { BsBarChartFill } from "react-icons/bs";
import { HiUpload } from "react-icons/hi";
import { BsStarFill } from "react-icons/bs";
import { BsFillHandbagFill } from "react-icons/bs";
import { IoList } from "react-icons/io5";
import { IoIosLogIn } from "react-icons/io";
import { useAuth } from "../../hooks/useAuth";

 

export const menu = [
  {
    name: "Store",
    component: "DashboardScreen",
    active: true,
    permited: ["2", "6", "5", "4", "3", "7", "8", "9"],
    bussinesPermited : ["1","2","3","4","5","6"],
    icon: <FcHome />,
    subItems: [
      {
        name: "CRM",
        component: "CRMHomeScreen",
        active: false,
        permited: ["2", "4", "5", "6"],
        // permited: ["2", "6", "5", "4", "3", "7", "8", "9"],
        bussinesPermited: ["1","2","3","4","5","6"],
        icon: <ImUserPlus />,
      },
      {
        name: "Magazzino",
        component: "MagazzinoHomeScreen",
        active: false,
        permited: ["2", "6", "5", "4", "9"],
        // permited: ["2", "6", "5", "4", "3", "7", "8", "9"],
        bussinesPermited: ["1","2","3","4","5","6"],
        icon: <RiHome7Fill />,
      },
      {
        name: "Assistenza",
        component: "HomeScreen",
        active: false,
        permited: ["2", "6", "5", "4", "3", "7", "8", "9"],
        bussinesPermited: ["1","2","3","4","5","6"],
        icon: <BsGearFill />,
      },
      {
        name: "Manifestazione di interesse",
        component: "HomeManifestazioneScreen",
        active: false,
        permited: ["2", "5"],
        bussinesPermited: ["1","2","3","4","5","6"],
        icon: <BsStarFill />,
        //permited:[]
      },
      {
        name: "Statistiche magazzino",
        component: "MagazzinoStatistiche",
        active: false,
        permited: ["2", "6"],
        bussinesPermited:["1","2","3","4","5","6"],
        icon: <BsBarChartFill />,
      },
      {
        name: "Importa",
        component: "ImportExportScreen",
        active: false,
        permited: ["2", "6"],
        bussinesPermited:["1","2","3","4","5","6"],
        icon: <HiUpload />,
      }
    ]
  },

  // {
  //   name: "Contatti",
  //   component: "ContactInfoScreen",
  //   active: false,
  //   permited: ["1", "2", "4", "5", "6"],
  //   icon:<FcHome />,
  // }
  {
    name: "Atelier",
    component: "AtelierScreen",
    active: true,
    permited: ["2","6","5","4"],
    bussinesPermited : ["1","2","3","4","5","6","9"],
    icon: <BsFillHandbagFill color="#db4444" />,
    subItems: [
      {
        name: "CRM",
        component: "CrmAtelier",
        active: false,
        permited: ["2", "6", "5","4"],
        bussinesPermited:["1","2","3","4","5","6","9"],
        icon: <ImUserPlus />,
        componentChildren:["CrmAtelier","ClientListAtelierScreen","AggiungiCrmAtelier","PrintLabelScreen"]
      },
      {
        name: "Magazzino",
        component: "MagazzinoAtelier",
        active: false,
        permited: ["2", "6", "5","4"],
        bussinesPermited:["1","2","3","4","5","6","9"],
        icon: <RiHome7Fill />,
        componentChildren:["MagazzinoAtelier","MagazzinoCercaAtelierScreen","MagazzinoAtelierForm","MagazzinoAtelierFormEdit"]
      },
      {
        name: "Assistenza",
        component: "ControlPanelRiparazione",
        active: false,
        permited: ["2", "6"],
        bussinesPermited:["1","2","3","4","5","6","9"],
        section: 'atelier',
        icon: <BsGearFill />,
        componentChildren:["ControlPanelRiparazione","ControlPanelRiparazione","StatoRiparazioni","Prospectusreparacione"]
      },
      {
        name: "Ordini",
        component: "OrdiniAtelier",
        active: false,
        permited: ["2", "6"],
        bussinesPermited:["1","2","3","4","5","6","9"],
        icon: <BsStarFill />,
        componentChildren:["OrdiniAtelier","OrdiniAtelierForm"]
      },
    ]
  }
];
export const menuEmail = [
  {
    name: "Pannello di controllo",
    component: "DashboardScreen",
    active: true,
    permited: ["2", "4", "5", "6", "3", "7", "8", "9"],
    subItems: [],
    icon: <ImUserPlus />,
  },
  {
    name: "Assistenza",
    component: "HomeScreen",
    active: false,
    permited: ["1", "2", "3", "4", "5", "6", "7", "8"],
    subItems: []
  },
  {
    name: "Contatti",
    component: "ContactInfoScreen",
    active: false,
    permited: ["1", "2", "4", "5", "6"],
    subItems: []
  }
];
export const menuBtn = [
  {
    icon: <IoList />,
    component: "ContactInfoScreen",
    isAuthorized: false,
    permited: ["2", "6", "5","4","3", "7", "8", "9"],
    bussinesPermited:["1","2","3","4","5","6","9"],
    text: "Contatti",
  },
  {
    icon: <BsGearFill />,
    component: "AdminScreen",
    isAuthorized: true,
    permited: ["2"],
    bussinesPermited:["1","2","3","4","5","6","9"],
    text: "Impostazioni"
  },
  {
    icon: <IoIosLogIn />,
    component: "Assistenza - Sergio Capone",
    isAuthorized: false,
    permited: ["2", "6", "5","4","3", "7", "8", "9"],
    bussinesPermited:["1","2","3","4","5","6","9"],
    text: "Disconnetti",
  },

];
export const menuBtnMail = [
  {
    icon: "Icona-impostazioni.png",
    component: "AdminScreen",
    isAuthorized: true
  },
  {
    icon: "Icona-logout.png",
    component: "Assistenza - Sergio Capone",
    isAuthorized: false
  }
];

