import { useState, useEffect } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { BsBellFill, BsNewspaper } from "react-icons/bs";
import { FiPlusSquare } from "react-icons/fi";
interface Props {
  notify: number;
  isOpenForm: boolean;
  setIsOpenForm;
  getIdRole;
}
export const HeaderNews = ({
  notify,
  isOpenForm,
  setIsOpenForm,
  getIdRole,
}: Props) => {
  const [idRole, setIdRole] = useState();

  useEffect(() => {
    getIdRole().then((e) => {
      setIdRole(e);
    });
  }, []);

  return (
    <div className="d-flex justify-content-between align-items-center  fw-bold ">
      <div className="">
        {notify !== 0 && (
          <div className="">
            <BsNewspaper className="me-2" size={30} color="#359ffc"  />
            {/* <span className="badge badge-success">
              {notify.toString().padStart(2, "0")}
            </span> */}
          </div>
        )}
        {notify === 0 &&
          <BsNewspaper className="me-2" size={30} color="#359ffc" />
        }
        <span>News</span>
      </div>
      <div>
        {(idRole == 2 || idRole == 6 || idRole == 5 || idRole == 9) && (
          <IoIosAddCircle
            color="#359ffc"
            size={30}
            className="ms-4"
            onClick={() => setIsOpenForm(!isOpenForm)}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    </div>
  );
};
