import { Dimensions, StyleSheet } from "react-native";
export const styles = StyleSheet.create({
  globalMargin: {
    backgroundColor: "white",
    borderRadius:
      Math.round(
        Dimensions.get("window").width + Dimensions.get("window").height
      ) / 2,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: Dimensions.get("window").width,
    width: Dimensions.get("window").width,
    position: "absolute",
    transform: [{ rotate: "90deg" }]
  },
  screen: {
    alignItems: "center",
    justifyContent: "center"
  },
  scroll: {
    backgroundColor: "white"
  },
  container: {
    flex: 1,
    backgroundColor: "white",
    paddingHorizontal: 30,
    paddingBottom: 40,
    alignContent: "center",
    justifyContent: "center"
  },
  containerHome: {
    flex: 1,
    paddingRight: 30,
    paddingLeft: 0,
    paddingBottom: 40,
    alignContent: "center",
    justifyContent: "center"
  },
  logo: {
    width: 290,
    height: 100,
    marginTop: 170
  },
  icon: {
    width: 150,
    height: 150,
    paddingHorizontal: "10%"
  },
  center: {
    alignSelf: "center"
  },
  input: {
    width: "100%",
    height: 50,
    borderWidth: 2,
    borderRadius: 50,
    marginVertical: 6,
    paddingLeft: 20,
    fontSize: 15
  },
  label: {
    fontSize: 18,
    marginTop: 35,
    marginLeft: 18,
    fontFamily: "Arsapia Regular font",
    color: "black",
    marginBottom: 5
  },
  labelHeader: {
    fontSize: 18,
    marginLeft: 40,
    marginTop: 30,
    marginBottom: 30,
    fontFamily: "Arsapia Regular font",
    color: "black"
  },
  labelLeft: {
    fontSize: 18,
    marginLeft: 35,
    marginTop: 15,
    fontFamily: "Arsapia Regular font",
    color: "black",
    textAlign: "left"
  },
  button: {
    backgroundColor: "black",
    justifyContent: "center",
    borderRadius: 50,
    width: 140,
    height: 40
  },
  buttonWhite: {
    backgroundColor: "white",
    justifyContent: "center",
    borderRadius: 50,
    width: 130,
    height: 40,
    borderWidth: 2,
    borderColor: "black"
  },
  buttonGrey: {
    backgroundColor: "#B5B2B2",
    justifyContent: "center",
    borderRadius: 50,
    width: 130,
    height: 40,
    borderWidth: 2,
    borderColor: "grey"
  },
  buttonText: {
    color: "white",
    alignSelf: "center",
    fontSize: 18
  },
  buttonTextWhite: {
    color: "black",
    alignSelf: "center",
    fontWeight: "500",
    fontSize: 18
  },
  buttonTextBackground: {
    color: "black",
    alignSelf: "center",
    fontWeight: "500",
    fontSize: 18,
    backgroundColor: "red"
  },
  right: {
    alignSelf: "flex-end",
    marginRight: 35,
    marginTop: 25
  },
  left: {
    alignSelf: "flex-start",
    marginTop: 25
  },
  largeButton: {
    borderRadius: 40,
    borderWidth: 2,
    paddingHorizontal: 40,
    borderColor: "black",
    marginBottom: "13%",
    height: 80,
    width: "102%",
    marginLeft: 10,
    alignItems: "center",
    justifyContent: "center"
  },
  colorBlackText: {
    color: "black"
  },
  inputNotEdit: {
    width: 350,
    height: 40,
    margin: 12,
    borderRadius: 50,
    marginVertical: 6,
    backgroundColor: "rgb(235, 235, 235)",
    fontFamily: "Arsapia Regular font",
    color: "#a2a2a2",
    paddingLeft: 20
  },
  containerCustom: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
    paddingBottom: 120
  },
  iconModal: {
    width: 25,
    cursor: "pointer",
    height: 25
  },
  labelConferma: {
    fontSize: 18,
    marginLeft: 5,
    marginTop: 5,
    fontFamily: "Arsapia Regular font",
    color: "black"
  },
  inputPreventivo: {
    width: "100%",
    height: 50,
    borderRadius: 50,
    marginVertical: 6,
    paddingLeft: 20,
    fontFamily: "Arsapia Regular font"
  },
  inputPreventivoCustom: {
    width: "100%",
    height: 50,
    borderRadius: 50,
    paddingLeft: 20,
    fontFamily: "Arsapia Regular font",
    backgroundColor: "white",
    borderColor: "black",
    borderWidth: 1
  },
  largeButtonAux: {
    borderRadius: 40,
    borderWidth: 2,
    paddingHorizontal: 40,
    borderColor: "black",
    marginBottom: "8%",
    height: 50,
    width: "102%",
    marginLeft: 10,
    alignItems: "center",
    justifyContent: "center"
  },
  inputContentModal: {
    width: 350,
    height: "auto",
    minHeight: 50,
    backgroundColor: "rgb(235, 235, 235)",
    color: "black",
    borderWidth: 0,
    borderRadius: 25,
    textAlignVertical: "auto",
    padding: 15
  },
  inputSubjectModal: {
    width: 350,
    height: "auto",
    minHeight: 50,
    backgroundColor: "rgb(235, 235, 235)",
    color: "black",
    borderWidth: 0,
    borderRadius: 25
  },
  spaceBetweenInput: {
    margin: 0,
    paddingHorizontal: 12,
    flexDirection: "row"
  },
  letterAModal: {
    fontSize: 15,
    fontWeight: "500",
    marginLeft: 20,
    color: "black"
  },
  containerIconsModal: {
    flexDirection: "row",
    paddingHorizontal: 35,
    margin: 0,
    paddingBottom: 15,
    height: "auto"
  }
});

import CSS from "csstype";

export var tableHeader: CSS.Properties = {
  textAlign: "center",
  fontWeight: "400",
  verticalAlign: "middle"
};

export var tableRow: CSS.Properties = {
  textAlign: "center",
  fontWeight: "400",
  verticalAlign: "middle"
};
