import React from 'react'
import HeaderToday from './HeaderToday'
import ContentToday from './ContentToday'
import { useCalender } from '../../../hooks/useCalender';
import FooterToday from './FooterToday';

const EventToday = () => {
  const { eventsByDay} = useCalender();
  return (
    <div className="mb-5 pe-4">
      <HeaderToday  />
      <ContentToday eventsToday={ eventsByDay } />
      <FooterToday visible={eventsByDay.length > 0} />
    </div>
  )
}

export default EventToday
