import { useState } from "react"

export const useModalQuadrante = () => {
    const [isOpenQuadrante, setIsOpenDelete] = useState(false);
    const [items, setItems] = useState({}) as any;
    const closeModalQuadrante = () => {
      const element = document.querySelector(".modal-backdrop");
      if (element) {
        element.parentNode?.removeChild(element);
      }
      setIsOpenDelete(false);
      setItems(null);
    };
    const openModalEdit = (e: any) => {
      const overlay = document.createElement("div");
      overlay.className = "modal-backdrop fade show";
      document.querySelector("#root")?.insertAdjacentElement("afterend", overlay);
      setIsOpenDelete(true);
      setItems(e);
    };
    const openModalQuadrante = () => {
      const overlay = document.createElement("div");
      overlay.className = "modal-backdrop fade show";
      document.querySelector("#root")?.insertAdjacentElement("afterend", overlay);
      setIsOpenDelete(true);
    };
  
    return {
      isOpenQuadrante,
      items,
      closeModalQuadrante,
      openModalEdit,
      openModalQuadrante,
    };
  return {}
}
