import { useNavigation } from "@react-navigation/native";
import { View } from "react-native";
import { NavigationProps } from "../../navigator/NavigatorConfig";
import { HeaderNewRepair } from "../HeaderNewRepair";

export const HeaderStatistiche = () => {
  const navigation = useNavigation<NavigationProps>();
  return (
    <View style={{ paddingHorizontal: 12 }}>
      <HeaderNewRepair
        hasRoundedIcon={false}
        title="Magazzino"
        hasPadding={true}
        onBack={() => navigation.navigate("HomeSCDueScreen", {  refresh  : true })}
      />
    </View>
  );
};
