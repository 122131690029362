import { Text } from "react-native";
import { tableRow } from "../../GeneralStyles/Statistiche";
import { FilterWhit } from "./FilterWhit";
import { Collapse } from "react-collapse";
import { BussinesVenditeProdotti, SearchByCity } from "./BussinesVenditeProdotti";
import Pagination from "../Pagination/pagination";
import { CittaStatische } from "./CittaStatische";
import { TableVenditePerCittaHeader } from "./TableVenditePerCittaHeader";
import TitleStatische from "./TitleStatische";
interface Props {
    showVC,
    paginationListeCitta: any;
    bussines,
    paginaActualCitta,
    venditeProdotti: any;
    setPaginaActualCitta,
    handleShowVenditeCitta,
    handleChangeCheckVenditeCitta,
    handleCheckVenditeCitta,
    handleChangeCity,
    city
}
export const VenditePerCitta = ({
    showVC,
    paginationListeCitta,
    bussines,
    paginaActualCitta,
    venditeProdotti,
    setPaginaActualCitta,
    handleShowVenditeCitta,
    handleChangeCheckVenditeCitta,
    handleCheckVenditeCitta,
    handleChangeCity,
    city
}: Props) => {
    return (
        <>
        <TitleStatische title={"Vendite per città"}/>
            <div className="row">
                <div className="col-12 col-sm-3">
                    <FilterWhit />
                    <div className="row">
                        <CittaStatische handleShow={handleShowVenditeCitta} />
                        <Collapse isOpened={showVC}>
                            <SearchByCity
                                handleSearch={handleChangeCity}
                                value={city}
                            />
                        </Collapse>
                    </div>

                </div>
                <div className="col-12 col-sm-9">
                    <div className="table-responsive">
                        <table className="table table-borderless mt-3">
                            <TableVenditePerCittaHeader />
                            <tbody>
                                {venditeProdotti.length > 0 ? (
                                    <>
                                        {venditeProdotti.map(
                                            ({city, prodottiVenduti}, index) => (
                                                <tr key={index} style={{ borderBottom: "1px solid gray" }}>
                                                    <td
                                                        style={tableRow}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {city}
                                                    </td>
                                                    <td
                                                        style={{ ...tableRow, fontSize: 12 }}
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        {prodottiVenduti}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </>
                                ) : undefined}
                            </tbody>
                        </table>
                        {(venditeProdotti.length > 0) && (
                            <Pagination
                                className="pagination-bar"
                                currentPage={paginationListeCitta.currentPage}
                                totalCount={paginationListeCitta.totalPages}
                                pageSize={1}
                                onPageChange={(page) => setPaginaActualCitta(page)}
                            />)}
                    </div>
                </div>
            </div>
        </>
    );
};
