import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react'
import { Image, SafeAreaView, ScrollView, StatusBar, StyleSheet, View } from 'react-native'
import { HeaderBar } from '../../components/HeaderBar'
import { stylesRepair } from '../../GeneralStyles/SCDue/ContactInfo';
import { styles } from '../../theme/GlobalStyle';

interface Props extends NativeStackScreenProps<any, any> { }
export const ContactInfoScreen = ({ navigation }: Props) => {

    const contacts = [
        {
            name: 'PISA',
            data: [
                {
                    icon: "Icona-posizione.png",
                    description: 'Borgo Stretto, 6, 56127 Pisa PI'
                },
                {
                    icon: "Icona-email.png",
                    description: 'pisa@sergiocapone.com',
                },
                {
                    icon: "Icona-telefono.png",
                    description: '+39 050 971 1408'
                }
            ]
        },
        {
            name: 'VIAREGGIO',
            data: [
                {
                    icon: "Icona-posizione.png",
                    description: 'Viale Guglielmo Marconi, 119, 55049 Viareggio, Italia'
                },
                {
                    icon: "Icona-email.png",
                    description: 'viareggio@sergiocapone.com',
                },
                {
                    icon: "Icona-telefono.png",
                    description: '+39 0584 582776'
                }
            ]
        },
        {
            name: 'FORTE DEI MARMI',
            data: [
                {
                    icon: "Icona-posizione.png",
                    description: 'Via Vittori Veneto, 2B, 55042 Forte dei Marmi LU',
                },
                {
                    icon: "Icona-email.png",
                    description: 'forte@sergiocapone.com',
                },
                {
                    icon: "Icona-telefono.png",
                    description: '+39 0584 80984'
                }
            ]
        },
        {
            name: 'LIVORNO',
            data: [
                {
                    icon: "Icona-posizione.png",
                    description: 'Via Ricasoli, 77/79, 57125 Livorno LI'
                },
                {
                    icon: "Icona-email.png",
                    // description: 'livorno@sergiocapone.com',
                    description: 'livorno@casacapone.com',
                },
                {
                    icon: "Icona-telefono.png",
                    description: '+39 0586 839424'
                }
            ]
        },
        {
            name: 'PONTEDERA',
            data: [
                {
                    icon: "Icona-posizione.png",
                    description: 'Corso G. Matteotti, 133, 56025 Pontedera PI'
                },
                {
                    icon: "Icona-email.png",
                    description: 'pontedera@casacapone.com',
                },
                {
                    icon: "Icona-telefono.png",
                    description: '+39 0587 59383'
                }
            ]
        },
        {
            name: 'VICOPISANO',
            data: [
                {
                    icon: "Icona-posizione.png",
                    description: 'Via del Ponte, 1 56010 Caprona PI'
                },
                {
                    icon: "Icona-email.png",
                    description: 'info@casacapone.com',
                },
                {
                    icon: "Icona-telefono.png",
                    description: '+39 0507 88324'
                }
            ]
        },
        {
            name: 'AMMINISTRAZIONE',
            data: [
                {
                    icon: "Icona-posizione.png",
                    description: `Via Vicolo dell'oro 1, 56125 Pisa PI`
                },
                {
                    icon: "Icona-email.png",
                    description: 'amministrazione@sergiocapone.com',
                },
                {
                    icon: "Icona-telefono.png",
                    description: '0505493940'
                }
            ]
        }
    ];

    return (
        <div className="square">
            <ScrollView style={styles.scroll}>
                <SafeAreaView style={[stylesRepair.areaView]}>
                    <View style={{ paddingHorizontal: 12 }}
                    >
                        <HeaderBar
                            hasRoundedIcon={false}
                            hasLogo={false}
                            title="Contatti"
                            onBack={() => navigation.navigate("HomeSCDueScreen", {  refresh  : true })}
                        />
                    </View>
                    <View style={[stylesRepair.areaContent, { paddingTop: 0 }]}>
                        <div className="row px-5">
                            {
                                contacts.map((item, index) => {
                                    return (
                                        <div key={index} className="col-12 col-sm-6 mt-5">
                                            <h4>
                                                {item.name}
                                            </h4>
                                            {
                                                item.data.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div key={index} className="d-flex mt-4">
                                                                <Image
                                                                    style={stylesRepair.icon}
                                                                    source={require(`../../assets/icons-step-due/${item.icon}`)}
                                                                />
                                                                <p style={{ paddingLeft: 15 }}>
                                                                    {item.description}
                                                                </p>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </View>
                </SafeAreaView>
            </ScrollView>
        </div>
    )
}