import { Image, Text, TouchableOpacity, View } from "react-native";
import { stylesStatisticheMagazzino } from "../../GeneralStyles/Statistiche";
interface Props {
  handleShow: () => void;
}
export const BrandStatische= ({ handleShow }: Props) => {
  return (
    <div className="col-12 d-flex justify-content-between align-items-center">
      <Text style={stylesStatisticheMagazzino.labelFilter}>Brand</Text>
      <TouchableOpacity
        onPress={() => handleShow()}
        style={{
          paddingRight: 30,
        }}
      >
        <View>
          <Image
            style={stylesStatisticheMagazzino.arrowDown}
            source={require("../../assets/icons/arrow_down.png")}
          />
        </View>
      </TouchableOpacity>
    </div>
  );
};
