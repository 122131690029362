import React, { useEffect, useState } from "react";
import { TableHeaderCrmAtelier } from "./TableHeaderCrmAtelier";
import { IClientAtelier } from "../interfaces/ICrmAtelier";
import { TableBodyCrmAtelier } from "./TableBodyCrmAtelier";
import Pagination from "../../../../components/Pagination/pagination";


interface Props {
  data: IClientAtelier[];
  paginationList: any;
  currentPage: any;
  updatePage: any;
  limit: any;
  confirmToDelete: any;
  emailModal: any;
  navigation: any;
}

export const TableCrmAtelier = ({
  data,
  paginationList,
  currentPage,
  updatePage,
  limit,
  confirmToDelete,
  emailModal,
  navigation
}: Props) => {
 
  return (
    <div className="col-12 p-0">
        <div className="table-responsive">
          <table className="table table-borderless mt-3">

            <TableHeaderCrmAtelier />

            {data?.length > 0 &&
              <TableBodyCrmAtelier 
                confirmToDelete={confirmToDelete}
                data={data}
                emailModal={emailModal}
                navigation={navigation}
              />
            }

          </table>
          {
            (paginationList.total > 0) && (
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={paginationList.total}
                pageSize={limit}
                onPageChange={(page) => { updatePage(page) }}
              />
            )
          }
        </div>

    </div>
  );
};
