import React from "react";
import { TouchableOpacity, Text, View, Image, TextInput } from "react-native";
import { Collapse } from "react-collapse";

import { stylesMagazzino } from "../../../../GeneralStyles/MagazzinoStyle";
import { useShow } from "../../../../hooks/useShow";
import { CollapseTitleAtelier } from "./CollapseTitleAtelier";
import { CollapseTitle } from "../../../../components/MagazzinoRefactor/CollapseTitle";


interface Props {
    handleSearch:any;
  searchInput: any;
  onChange: any;
  title:any;
}

export const FilterMagazzinoAtelierDescrizione = ({ handleSearch, searchInput,onChange,title }: Props) => {

  const { show, toggle } = useShow();

  return (
    <>
      <CollapseTitle title={title} toggle={toggle} />
      <Collapse isOpened={show}>
        <div className="col-12 d-flex">
          <TextInput
            placeholder="Cerca..."

            style={[stylesMagazzino.input, stylesMagazzino.subSearch]}
            onChangeText={onChange}
            value={searchInput}
          />
          <TouchableOpacity
            onPress={() => { handleSearch()}}
            style={{
              margin: "auto",
            }}
          >
            <View style={[stylesMagazzino.viewDisable, stylesMagazzino.search]}>
              <Image
                style={stylesMagazzino.subIconSearch}
                source={require("../../../../assets/icons/icon-search.png")}
              />
            </View>
          </TouchableOpacity>
        </div>
      </Collapse>
    </>
  );
};
