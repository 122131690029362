import axios from "axios";
import React, { useState, useContext } from "react";
import {
  Modal,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Linking,
  Image,
  StyleSheet,
  ScrollView,
} from "react-native";
import { HeaderModal } from "../components/HeaderModal";
import { styles } from "../theme/GlobalStyle";
import { stylesModal } from "../theme/ModalStyles";
import { Controller, useForm } from "react-hook-form";
import { SectionLoading } from "../components/SectionLoading";
import PopupMessage from "../components/PopupMessage";
import ConfigCapone from "../utils/ConfigCapone";
import { UserContext } from "../context/UserContext";
import { getAllServices } from "../api/ClientService";
interface ISendNotificationScreen {
  modalVisible: boolean;
  setModalVisible: any;
  clientData?: any;
  currentPage?: any,
  page?: any;
  setPage?: any;
  handleViewPageFilter?: any;
  restartPaginationFilter?: any;
  setListUsers?: any;
  listUsers?: any;
}
export const SendNotificationScreen = ({
  setModalVisible,
  modalVisible,
  clientData,
  currentPage='',
  page,
  setPage,
  handleViewPageFilter = null,
  restartPaginationFilter = null,
  setListUsers,
  listUsers,
}: ISendNotificationScreen) => {

  let nameEmail=clientData.name;
  let titleEmail=clientData.desc_Title;
  let subject = clientData.idStatus;

  let dataTextOg = "";
  
  switch(subject){
    case "1":
      dataTextOg = "Avviso in attesa conferma  preventivo";
      break;
    case "2":
      dataTextOg = "Avviso in attesa conferma lavorazione";
      break;
    case "3":
      dataTextOg = "Avviso lavorazione in corso";
      break;
    case "4":
      dataTextOg = "Avviso attesa ricambi";
      break;
    case "5":
      dataTextOg = "Avviso riparazione completata";
      break;
    default:
      dataTextOg = "Avviso riparazione completata";
      break;
  }

  const dataTextState =
  `Gentile Cliente, ${titleEmail} ${nameEmail} ` +
  `\n` +
  `\n` +
  `\n` +
  `il suo orologio è in lavorazione, sarà nostra premura avvertila una volta che potrà venirlo a ritirare.` +
  `\n` +
  `Grazie per averci scelto,` +
  `\n` +
  `\n` +
  `\n` +
  `Centro Assistenza Capone`;


  const dataTextProsp =
  ` Gentile Cliente, ${titleEmail} ${nameEmail} ` +
  `\n` +
  `\n` +
  `\n` +
  `siamo lieti di informarla che l’orologio in riparazione presso il nostro Centro Assistenza è pronto per ` +
  `essere ritirato. La invitiamo a recarsi in negozio con il cedolino della commissione in suo possesso.` +
  `\n` +
  `Ringraziandola per averci scelto,` +
  `\n` +
  `Le porgiamo i nostri più Cordiali Saluti,` +
  `\n` +
  `\n` +
  `\n` +
  `Capone Gioiellerie`;

  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [panel, setPanel] = useState("email");
  const [nameImgSelected, setNameImgSelected] = useState("");
  const { userInLogin } = useContext(UserContext);
  const [isEmployee, setIsEmployee] = useState<boolean>(userInLogin?.idRole==='4'?true:false);
  const [typeFile, setTypeFile] = useState<any>();
  const {
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      subject: dataTextOg,
      message: currentPage == 'state_repair' ? dataTextState : dataTextProsp,
    },
  });
  const [modalMessage, setModalMessage] = useState({
    active: false,
    message: "",
  });
  const [images, setImages] = useState<any>();
  const uploadFiles = (e: any, type: string) => {
    setImages(e);
    setNameImgSelected(e ? e[0].name : null);
    setTypeFile(type);
    if (type == "image") {
      let image: any = document.querySelector("#image-files");
      let imageContainer: any = document.getElementById("img-container");
      imageContainer.classList.remove("d-none");

      imageContainer.src = URL.createObjectURL(image.files[0]);
    } else if (type == "document") {
      let imageContainer: any = document.getElementById("img-container");
      imageContainer.classList.add("d-none");
    }
  };
  const sendEmail = async () => {
    setLoading(true);
    let message: string = getValues("message");

    if (panel == "email") {
      setMessageSent(false);

      let _uploadFile = true;

      if (nameImgSelected.length == 0) {
        _uploadFile = false;
      }

      if (_uploadFile && images && clientData.email) {
        var fReader = new FileReader();
        fReader.onloadend = async function () {
          let dataSend = {
            type: "notice",
            email: clientData.email,
            subject: getValues("subject"),
            message: getValues("message").replace(/\n/g, '<br>\n'),
            nameImg: images ? images[0].name : null,
            files: fReader.result || null,
          };
          await axios
            .post(
              `${ConfigCapone.apicapone}/api/forma`,
              dataSend
            )
            .then((res) => {
              setLoading(false);
              setMessageSent(true);
              setClientNotification();
            })
            .catch((err) => {
              setLoading(false);
              setModalMessage({
                active: true,
                message: "E-mail non inviata. Il file non deve essere superiore a 50MB.",
              });
              console.log("there was an error: " + err);
            });
        };
        images && fReader.readAsDataURL(images[0]);
      } else if (clientData.email) {
        let dataSend = {
          type: "notice",
          email: clientData.email,
          subject: getValues("subject"),
          message: getValues("message").replace(/\n/g, '<br>\n'),
        };
        await axios
          .post(
            `${ConfigCapone.apicapone}/api/forma`,
            dataSend
          )
          .then((res) => {
            setLoading(false);
            setMessageSent(true);
            setClientNotification();
          })
          .catch((err) => console.log("there was an error: " + err));
      } else {
        setLoading(false);
        setModalMessage({
          active: true,
          message: "Email errata",
        });
      }
    } else if (panel == "sms") {
      await setClientNotification();
      setLoading(false);
      await Linking.openURL("sms:+39" + clientData.phone + "?body=" + message.replace(/\n/g, '%0A\n'));
    } else if (panel == "whatsapp") {
      await setClientNotification();
      setLoading(false);
      await Linking.openURL(
        "https://wa.me/+39" + clientData.phone + "?text=" + message.replace(/\n/g, '%0A\n')
      );
    }
  };

  const setClientNotification = async() => {
    if(currentPage == 'prospetto_riparazioni'){
      await axios
      .put(
        ConfigCapone.urlapi + "/api/Client/PutClientNotification",
        {
          idService: clientData.idService,
          hasNotification: true,
        }
      )
      .then((response) => {
        if(isEmployee){
          axios
            .get(
              `${ConfigCapone.urlapi}/api/Client/GetClientTableV2?idBussines=${userInLogin.idBussines}`
            )
            .then((resp) => {
              if (restartPaginationFilter != null) {
                restartPaginationFilter(resp.data.response);
                handleViewPageFilter(page, resp.data.response);
              }
            });
          }else{
            axios
            .get(
              `${ConfigCapone.urlapi}/api/Client/GetClientTable?parameter=1`
            )
            .then((resp) => {
              if (restartPaginationFilter != null) {
                restartPaginationFilter(resp.data.response);
                handleViewPageFilter(page, resp.data.response);
              }
            });
          }
      })
      .catch((error) => console.log(error));
    }
  }

  const removeFile = () => {
    setNameImgSelected("");

    var imageFile: any = document.getElementById("image-files");
    var documentFile: any = document.getElementById("document-files");

    imageFile.value = null;
    documentFile.value = null;

    let imageContainer: any = document.getElementById("img-container");
    imageContainer.classList.add("d-none");
  };
  return (
    <View style={stylesModal.centeredView}>
      <Modal animationType="slide" propagateSwipe={true} transparent={true} visible={modalVisible}>
      <ScrollView style={{width: "100%", height: "100%"}}> 
        <View
          style={[
            stylesModal.centeredView,
            { backgroundColor: "rgba(0,0,0,.32)", paddingTop: 130, paddingBottom: 130 },
          ]}
        >
          <View style={stylesModal.modalView}>
            <HeaderModal
              setModalVisible={setModalVisible}
              modalVisible={modalVisible}
              title={"Invia notifica al cliente"}
              activatedPadLeft={true}
            />

            {panel == "email" ? (
              <Text style={[styles.left, styles.letterAModal, {marginRight: 20}]}>
                {
                  clientData.email == null || clientData.email == undefined || clientData.email == ''
                  ?
                  <span style={{color: 'red'}}>Per questa azione è richiesta l'e-mail</span>
                  :
                  `A: ${clientData.name} - ${clientData.email}`
                }
              </Text>
              ) : (
                <Text style={[styles.left, styles.letterAModal, {marginRight: 20}]}>
                  A: {clientData.name} - {clientData.phone}
                </Text>
              )
            }
            <View style={[styles.left, styles.containerIconsModal]}>
              {panel == "email" ? (
                <TouchableOpacity
                  onPress={() => {
                    setPanel("email");
                    setMessageSent(false);
                  }}
                >
                  <View>
                    <Image
                      style={[
                        styles.iconModal,
                        {
                          tintColor: "#ff5959",
                        },
                      ]}
                      source={require("../assets/icons/mail.png")}
                    />
                  </View>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    setPanel("email");
                    setMessageSent(false);
                  }}
                >
                  <View>
                    <Image
                      style={[
                        styles.iconModal,
                        {
                          tintColor: "#b3b3b3",
                        },
                      ]}
                      source={require("../assets/icons/mail.png")}
                    />
                  </View>
                </TouchableOpacity>
              )}

              {panel == "sms" ? (
                <TouchableOpacity
                  onPress={() => {
                    setPanel("sms");
                    setMessageSent(false);
                  }}
                >
                  <View>
                    <Image
                      style={[
                        styles.iconModal,
                        {
                          marginHorizontal: 15,
                          tintColor: "#FCC543",
                        },
                      ]}
                      source={require("../assets/icons/message2.png")}
                    />
                  </View>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    setPanel("sms");
                    setMessageSent(false);
                  }}
                >
                  <View>
                    <Image
                      style={[
                        styles.iconModal,
                        {
                          marginHorizontal: 15,
                          tintColor: "#b3b3b3",
                        },
                      ]}
                      source={require("../assets/icons/message2.png")}
                    />
                  </View>
                </TouchableOpacity>
              )}

              {panel == "whatsapp" ? (
                <TouchableOpacity
                  onPress={() => {
                    setPanel("whatsapp");
                    setMessageSent(false);
                  }}
                >
                  <View>
                    <Image
                      style={[
                        styles.iconModal,
                        {
                          tintColor: "#47C858",
                        },
                      ]}
                      source={require("../assets/icons/whatsapp2.png")}
                    />
                  </View>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    setPanel("whatsapp");
                    setMessageSent(false);
                  }}
                >
                  <View>
                    <Image
                      style={[
                        styles.iconModal,
                        {
                          tintColor: "#b3b3b3",
                        },
                      ]}
                      source={require("../assets/icons/whatsapp2.png")}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            {panel == "email" && (
              <View style={styles.spaceBetweenInput}>
                <View style={{ flex: 1 }}>
                  <Controller
                    control={control}
                    name="subject"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        style={[styles.input, styles.inputSubjectModal]}
                        value={value}
                        onChangeText={(e: any) => onChange(e)}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </View>
              </View>
            )}
            {errors.subject?.type === "required" && (
              <Text style={emailFormStyles.labelError}>
                Questo campo è obbligatorio
              </Text>
            )}
            <View style={styles.spaceBetweenInput}>
              <View style={{ flex: 1 }}>
                <Controller
                  control={control}
                  name="message"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      style={[styles.input, styles.inputContentModal]}
                      multiline={true}
                      numberOfLines={11}
                      value={value}
                      onChangeText={(e: any) => onChange(e)}
                      // onChangeText={(e:any)=>setDescription(e.target.value)}
                    />
                  )}
                  rules={{ required: true }}
                />
                {loading && (
                  <div style={{ alignSelf: "center" }}>
                    <SectionLoading />
                  </div>
                )}
              </View>
            </View>
            {errors.message?.type === "required" && (
              <Text style={emailFormStyles.labelError}>
                Questo campo è obbligatorio
              </Text>
            )}
            {nameImgSelected ? (
              <p>
                <b>· Selezionato:</b> {nameImgSelected}
              </p>
            ) : null}

            <img
              src="https://i.postimg.cc/y8BSJnHY/rolex1.png"
              className="d-none"
              style={{ height: "20%", width: "20%" }}
              id="img-container"
              alt=""
            />
            <br />
            {nameImgSelected && (
              <a
                onClick={() => removeFile()}
                style={{ cursor: "pointer" }}
                className="text-danger"
              >
                rimuovere {nameImgSelected}
              </a>
            )}

            {messageSent && (
              <div className="alert mb-0 mt-2 alert-success mt-3" role="alert">
                <p className="mb-0">Messaggio inviato con successo</p>
              </div>
            )}

            <View
              style={[
                {
                  flexDirection: "row",
                  paddingTop: 30,
                  paddingBottom: 30,
                  alignSelf: "flex-end",
                  marginRight: 10,
                },
              ]}
            >
              <View>
                <TouchableOpacity>
                  <label htmlFor="image-files" style={{ cursor: "pointer" }}>
                    <Image
                      style={[
                        styles.iconModal,
                        {
                          tintColor: "#b3b3b3",
                          marginTop: 10,
                        },
                      ]}
                      source={require("../assets/icons/camera2.png")}
                    />
                  </label>
                  <input
                    type="file"
                    name="image-files"
                    id="image-files"
                    accept="image/png, image/gif, image/jpeg"
                    style={{ display: "none" }}
                    onChange={(e) => uploadFiles(e.target.files, "image")}
                  />
                </TouchableOpacity>
              </View>
              <View>
                <TouchableOpacity>
                  <label htmlFor="document-files" style={{ cursor: "pointer" }}>
                    <Image
                      style={[
                        styles.iconModal,
                        {
                          marginHorizontal: 20,
                          tintColor: "#b3b3b3",
                          marginTop: 10,
                        },
                      ]}
                      source={require("../assets/icons/link.png")}
                    />
                  </label>
                  <input
                    type="file"
                    name="document-files"
                    id="document-files"
                    accept="application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => uploadFiles(e.target.files, "document")}
                  />
                </TouchableOpacity>
              </View>

              {panel == "email" ? (
                <View>
                  <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={handleSubmit(sendEmail)}
                    disabled={
                      clientData.email == null || clientData.email == undefined || clientData.email == ''
                      ? true 
                      : false
                    }
                    style={{
                      width: 100,
                      alignSelf: "center",
                    }}
                  >
                    <View
                      style={
                        clientData.email == null || clientData.email == undefined || clientData.email == ''
                        ?
                        [
                          styles.button,
                          { paddingHorizontal: 4, width: 100, backgroundColor: "gray" },
                        ]
                        :
                        [
                          styles.button,
                          { paddingHorizontal: 4, width: 100 },
                        ]
                      }
                    >
                      <Text style={[styles.buttonText, { paddingHorizontal: 4 }]}>
                        {"Invia"}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              ) : (
                <View>
                  <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={handleSubmit(sendEmail)}
                    style={{
                      width: 100,
                      alignSelf: "center",
                    }}
                  >
                    <View
                      style={[
                        styles.button,
                        { paddingHorizontal: 4, width: 100 },
                      ]}
                    >
                      <Text style={[styles.buttonText, { paddingHorizontal: 4 }]}>
                        {"Invia"}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              )
            }
            </View>
            {modalMessage.active && (
              <PopupMessage
                title={"Informazione"}
                message={modalMessage.message}
                stateModal={modalMessage.active}
                setStateModal={setModalMessage}
              />
            )}
          </View>
        </View>
        </ScrollView>
      </Modal>
    </View>
  );
};

const emailFormStyles = StyleSheet.create({
  labelError: {
    fontSize: 16,
    marginLeft: 35,
    fontFamily: "Arsapia Regular font",
    color: "red",
  },
});
