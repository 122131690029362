import { StyleSheet } from "react-native";

export const styleInputBase = {
    width: "100%",
    height: 50,
    margin: 0,
    borderWidth: 2,
    borderRadius: 50,
    borderColor: "black",
    paddingLeft: 20,
    fontFamily: "Arsapia Regular font",
};

export const styleInput = {
    ...styleInputBase,
    backgroundColor: "transparent",
}

export const styleTextArea = {
    width: "100%",
    backgroundColor: "transparent",
    height: "auto",
    borderRadius: 25,
    borderColor: "black",
    borderWidth: 2,
    paddingLeft: 20,
    fontFamily: "Arsapia Regular font",
    textAlignVertical: "auto",
    padding: 30,
}

export const styleInputBlock = {
    ...styleInputBase,
    backgroundColor: "rgb(234, 234, 234)",
}

export const stylesCRM = StyleSheet.create({
    header: {
        paddingHorizontal: 12,
        backgroundColor: "white",
    },
    label: {
        alignSelf: "flex-start",
        marginTop: 25,
        marginBottom: 10,
        fontWeight: "500",
        fontSize: 20,
    },
    labelError: {
        fontSize: 18,
        marginLeft: 35,
        marginTop: 15,
        fontFamily: "Arsapia Regular font",
        color: "red",
    },
    button:{
        width:  200,
        height: 50,
        alignSelf: "center",
        backgroundColor: "black",
        borderRadius: 50,
        alignItems: "center",
        justifyContent: "center",
    }
});