import { ModalCustom } from '../Modal/ModalCustom';
import ButtonWhite from '../Button/ButtonWhite';
 interface Props {
   isOpen: boolean;
   setIsOpen;
   listIdNewBussinesView:[]
 }
const ModalNewsChecked =({isOpen,setIsOpen,listIdNewBussinesView}:Props)=>{
  const modelTime = (d) => {
    if(d){
      const date = new Date(d)
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
  
      const dateString = `${day}/${month}/${year}`;
      const timeString = `${hours}:${minutes}:${seconds}`;
      console.log("first",`${dateString} - ${timeString}`)
      return `${dateString} - ${timeString}`
    }
   
  }
console.log("asdfasdf",listIdNewBussinesView)
return(
    <>
    {isOpen && (
      
        <ModalCustom
          title="Visualizzati"
          isOpen={isOpen}
          closeModal={setIsOpen}
        >
          {listIdNewBussinesView && listIdNewBussinesView.map(({name, title,text,idNew,isChecked,idNewBussinesUsers,idUser,idNewBussines,desc_Bussines,date,desc_role }) => {
          return(
            <p>{name} {desc_role} {desc_role !=="Amministratore" && desc_Bussines} {modelTime(date)}</p>
          );
        })}
          
          <div className='w-100 text-center'>
         <ButtonWhite title='Chiudi' ModalAction={()=>setIsOpen(!isOpen)}/>
          </div>
          
        </ModalCustom>
      )}
     
    </>
) 
}

export default ModalNewsChecked