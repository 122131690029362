import React, { useEffect, useRef, useState } from "react";
import InputComponent from "../../../../components/ComponentsAtelier/inputs/inputComponent";
import { useForm, useFieldArray, Control } from "react-hook-form";
import { useIsFocused } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import PopUpMessageWithRoute from "../../../../components/PopUpMessageWithRoute";
import ConfigCapone from "../../../../utils/ConfigCapone";
import axios from "axios";
import { useProfile } from "../../../../components/NavProfile/hook/useProfile";
import ShowContentSidebar from "../../../../components/NavProfile/ShowContentSidebar";
import { Navbar } from "../../../../components/Navbar";
import NavProfile from "../../../../components/NavProfile/NavProfile";
import InputCheck from "../../../CRM/components/InputChek";
import ButtonComponent from "../../../../components/ComponentsAtelier/buttoms/buttonComponent";
import RatingComponent from "../../../../components/Rating/RatingComponent";
import useFormOrdiniAtelier from "./hooks/useFormOrdiniAtelier";
import { FormatYYMMDD } from "../../../../utils/FormatingDate";
import { ControlPanelManifestazioneScreen } from "../../ControlPanelManifestazioneScreen";

interface Props extends NativeStackScreenProps<any, any> { }

const OrdiniAtelierForm = ({ navigation, route }: Props) => {
  const isFocused = useIsFocused();
  const { sidebarVisible, toggleSidebar } = useProfile();
  // const { handleSubmit, onSubmit, register, title } = useFormOrdiniAtelier();
  return (
    <>
      <ShowContentSidebar
        sidebarVisible={sidebarVisible}
        toggleSidebar={toggleSidebar}
      />
      <div className="container-layout">
        <div className="row row-layout">
          <div
            className={` ${sidebarVisible
              ? "col-12 d-block"
              : "col-lg-3 col-md-none d-none d-md-non d-lg-block"
              } sidebar-container-layout sidebar-transition`}
          >
            <Navbar
              activeNav={isFocused}
              componentName={route.name}
              componentDad="OrdiniAtelierForm"
            />
          </div>
          <div
            className="col-lg-9 col-md-12 col-sm-12 content-container-layout "
            style={{ paddingLeft: "1.5rem" }}
          >
            <NavProfile
              showMenuBack={true}
              sidebarVisible={sidebarVisible}
              toggleSidebar={toggleSidebar}
              goBack="OrdiniAtelier"
              goBackName="Ordini Atelier"
            />


            <div className="magazzino-atelier-form-container">
              <div className="form-magazzino-atelier">
                <ControlPanelManifestazioneScreen navigation={navigation} route={route} isAtelier />
                
                {/* <form className="w-100 h-100" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row justify-content-center align-items-center g-2">
                    <div className="col-lg-12 mb-1" style={{ fontWeight: 500 }}>
                      Aggiungi cliente
                    </div>
                    <div className="col-lg-12">
                      <InputComponent
                        register={register("titolo")}
                        name="titolo"
                        label="Titolo *"
                        type="select"
                        options={title}
                      />
                    </div>
                    <div className="col-lg-12">
                      <InputComponent
                        register={register("nomeCognome")}
                        name="nomeCognome"
                        label="Nome e Cognome "
                        type="text"
                      />
                    </div>
                    <div className="col-lg-12">
                      <InputComponent
                        register={register("email")}
                        name="email"
                        label="Email"
                        type="email"
                      />
                    </div>
                    <div className="col-lg-12">
                      <InputComponent
                        register={register("dataInscricione", {
                          onChange: (evt) => {
                            const { value } = evt.target;
                            const dateValue = new Date(value)
                            const currentDate = new Date();
                            const selectedYear = dateValue.getFullYear();
                            const currentYear = currentDate.getFullYear();
                            (selectedYear > currentYear) ? evt.target.value = FormatYYMMDD(new Date(dateValue.setFullYear(currentYear))) : "";
                          }
                        })}
                        name="dataInscricione"
                        label="Data di inscricione"
                        type="date"
                      />
                    </div>
                    <div className="col-lg-12">
                      <InputComponent
                        register={register("negozio")}
                        name="negozio"
                        label="Negozio"
                        disabledInput
                        type="text"
                      />
                    </div>
                    <div className="col-lg-12">
                      <label style={{ fontWeight: 500 }}>Note</label>
                      <textarea
                        name=""
                        id=""
                        className="w-100 p-4"
                        style={{
                          border: "2px solid #000",
                          minHeight: "10em",
                          resize: "vertical",
                          borderRadius: ".5em",
                        }}
                        {...register("Note")}
                      ></textarea>
                    </div>
                    <div className="col-lg-12 " style={{ height: "auto" }}>
                      <label style={{ fontWeight: 500 }} htmlFor="">
                        Stelle Valutazione
                      </label>
                      <RatingComponent
                        cant={3}
                        size="2.5em"
                        register={register("stars")}
                      />
                    </div>
                    <div className="col-lg-12">
                      <InputComponent
                        register={register("crm")}
                        name="crm"
                        label="CRM"
                        disabledInput
                        type="text"
                      />
                    </div>
                    <div className="col-lg-12">
                      <label style={{ fontWeight: 500 }} htmlFor="">
                        List Presenti
                      </label>
                      <InputComponent
                        register={register("listInserire")}
                        name="listInserire"
                        label="List de inserire"
                        type="select"
                      />
                      <InputCheck
                        name="emailRiagracimiento"
                        text="Invia e-mail di ringraziamento"
                        type="checkbox"
                        register={register("emailRiagracimiento")}
                      />
                    </div>
                    <div className="col-lg-12">
                      <InputComponent
                        register={register("noteLast")}
                        name="noteLast"
                        label="Note"
                        type="text"
                      />
                    </div>
                    <div className="col-lg-12">
                      <InputCheck
                        name="blackList"
                        text="Black List"
                        type="checkbox"
                        register={register("blackList")}
                      />
                    </div>
                    <div className="col-lg-12">
                      <InputCheck
                        name="importante"
                        text="Importante"
                        type="checkbox"
                        register={register("importante")}
                      />
                    </div>
                    <div className="footer-form-magazzino ">
                      <ButtonComponent text={`Salva`} type="submit" />
                    </div>
                  </div>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdiniAtelierForm;
