import { useContext, useEffect, useState } from "react"
import { bussinesList, bussinesListByIdNew, bussinesNewByIdUser, listByidNewBussines, listByidNewBussinesUsersCheck, postNewBussinesService, postNewUserService, postNews, deleteNewsData, putNewUserIsChecked, putNewBussinesUserIsChecked, deleteNewsDataBussines, deleteNewsDataUser, putNewsData, usersNewsSelected, deleteNewsBussinesId, deleteNewsIdUser, getPdf, getIdRoleData, putnewBussinesIsChecked, userListAll, userListByIdNew, userNewByIdUser, userListRoleAdmin } from "../services/NewsServices"
import { UserContext } from "../../../context/UserContext"

const useNews = () => {
    const [BussinesList, setBussinesList] = useState()
    const [defaultBussinesList, setDefaultBussinesList] = useState<any>([])
    const [UserList, setUserList] = useState()
    const [defaultUserList, setDefaultUserList] = useState<any>([])

    const [userRol, setUserRol] = useState()

    const [UserTecnicoList, setUserTecnicoList] = useState()
    const [defaultUserTecnicoList, setDefaultUserTecnicoList] = useState<any>([])
    const [listIdNewBussinesView, setListIdNewBussineView] = useState()
    const [listNewUserView, setListNewUserView] = useState({
        items: [], normalizedResults: [], length
    })
    const [ListNewBussinesById, setListNewBussinesById] = useState({
        items: [], normalizedResults: [], length
    })

    const [stateOptionNew, setStateOptionNew] = useState<any>(null)

    const { userInLogin } = useContext(UserContext);
    const ListBussines = async () => {
        const result = await bussinesList()

        if (result) {
            let BussinesCustom = result.map(function (option) {
                return { label: option.desc_Bussines, value: option.idBussines };
            });

            BussinesCustom.push({ label: 'Tutti', value: '7' })

            //BussinesCustom.push({label: "Tutti", value: 10})

            setBussinesList(BussinesCustom)

        }
    }

    const defaultListNews = async (id) => {

        await ListUsersByIdNew(id);
        await ListBussinesByIdNew(id);
    }

    const ListBussinesByIdNew = async (id) => {



        const result = await bussinesListByIdNew(id)


        if (result) {
            let BussinesCustom = result.map(function (option) {
                return { label: option.desc_Bussines, value: option.idBussines };
            });
            if (BussinesCustom?.length > 0) {
                setStateOptionNew('option1')
            }



            setDefaultBussinesList(BussinesCustom)
            setDefaultUserList([])
            setDefaultUserTecnicoList([])

        }
    }

    const ListUsersByIdNew = async (id) => {




        const result = await userListByIdNew(id)

        if (result) {

            let filterUser = result.filter(x => Number(x.idRole) === 2 || Number(x.idRole) === 4 || Number(x.idRole) === 5 || Number(x.idRole) === 6)
            let filterTecnico = result.filter(x => Number(x.idRole) === 3 || Number(x.idRole) === 7 || Number(x.idRole) === 8)

            let filterUser2 = filterUser.filter(x => Number(x.isSelected) === 1)


            let UserCustom = filterUser2.map(function (option) {
                return { label: `${option.desc_Role}-${option.userName}`, value: option.idUser };
            });

            let UserTencnicoCustom = filterTecnico.map(function (option) {
                return { label: option.userName, value: option.idUser };
            });



            if (UserCustom?.length > 0 && UserTencnicoCustom?.length == 0) {
                setStateOptionNew('option2')
                setDefaultUserList(UserCustom)
                setDefaultBussinesList([])
                setDefaultUserTecnicoList([])
            }

            if (UserTencnicoCustom?.length > 0) {
                setStateOptionNew('option3')
                setDefaultUserTecnicoList(UserTencnicoCustom)
                setDefaultBussinesList([])
                setDefaultUserList([])
            }

        }
    }

    const GetListNewBussinesById = async () => {

        const idUser = Number(userInLogin.idUser)
        const result = await bussinesNewByIdUser(idUser)
        const resultUser = await GetListNewUserById()
        let resultConcat = result;


        if (resultUser) {
            resultConcat = result.concat(resultUser)
        }
        if (resultConcat) {
            resultConcat.sort(function (a, b) {
                return new Date(b.dataInsert).getTime() - new Date(a.dataInsert).getTime();
            });
        }


        if (result) {

            const items = resultConcat.slice(0, 3);
            const normalizedResults = resultConcat
            const normalizedResultLength = resultConcat.filter(x => x.isChecked == 0)
            const dataRender = { items, normalizedResults, length: normalizedResultLength.length }
            setListNewBussinesById(dataRender)

            console.log("dataRender", dataRender)
        }
    }
    //ListNewUser
    const GetListNewUserById = async () => {
        const idUser = Number(userInLogin.idUser)

        const result = await userNewByIdUser(idUser)
        if (result) {
            return result
        }
    }
    //postNewBussines
    const PostNewBussines = async (id: Number, List: any) => {

        if (id != 0 && List != "") {
            for (let index = 0; index < List.length; index++) {
                const dataCustom = {
                    idNew: id,
                    idBussines: List[index].value,
                }
                const result = await postNewBussinesService(dataCustom)

            }

        }
        await GetListNewBussinesById()
    }


    const PutNewBussines = async (id: Number, List: any, result3: any) => {

        if (result3) {
            let listDifent = List.filter(item2 => !result3.some(item1 => item1.idBussines === item2.value));

            let listDifent2 = result3.filter(item2 => !List.some(item1 => item1.value === item2.idBussines));

            if (id != 0 && listDifent.length > 0) {

                for (let index = 0; index < listDifent.length; index++) {
                    const dataCustom = {
                        idNew: id,
                        idBussines: listDifent[index].value,
                    }
                    const result = await postNewBussinesService(dataCustom)

                }

            }

            if (id != 0 && listDifent2.length > 0) {
                for (let index = 0; index < listDifent2.length; index++) {
                    const dataCustom = {
                        idNew: id,
                        idBussines: listDifent2[index].idBussines,
                    }
                    const result = await deleteNewsBussinesId(dataCustom.idNew, dataCustom.idBussines)

                }

            }
        } else {
            PostNewBussines(id, List)
        }

        console.log(result3)

        await GetListNewBussinesById()
    }



    //postNewUser
    const postNewUser = async (id: Number, List: any) => {


        if (id != 0 && List != "") {

            //admins
            const data = await userListRoleAdmin();

            let list = data.filter(item2 => !List.some(item1 => item1.value === item2.idUser));

            for (let index = 0; index < list.length; index++) {
                const dataCustom = {
                    idNew: id,
                    idUser: list[index].idUser,
                    description: "Dependente",
                    isSelected: 0
                }

                await postNewUserService(dataCustom)

            }


            for (let index = 0; index < List.length; index++) {
                const dataCustom = {
                    idNew: id,
                    idUser: List[index].value,
                    description: "Dependente",
                    isSelected: 1
                }
                const result = await postNewUserService(dataCustom)

            }

        }
        await GetListNewBussinesById()

    }

    const postNewUserTecnici = async (id: Number, List: any) => {


        if (id != 0 && List != "") {


            // admins
            const data = await userListRoleAdmin();


            for (let index = 0; index < data.length; index++) {


                const dataCustom = {
                    idNew: id,
                    idUser: data[index].idUser,
                    description: "Tecnici",
                    isSelected: 0
                }
                await postNewUserService(dataCustom)

            }


            for (let index = 0; index < List.length; index++) {
                const dataCustom = {
                    idNew: id,
                    idUser: List[index].value,
                    description: "Tecnici",
                    isSelected: 1
                }
                const result = await postNewUserService(dataCustom)

            }

        }
        defaultListNews(id)
        await GetListNewBussinesById()
    }



    // putDependente

    const putNewUserDependente = async (id: Number, List: any, usersSelected: any) => {

        console.log(usersSelected)

        if (usersSelected) {
            if (Number(userInLogin.idRole) == 5 || Number(userInLogin.idRole) == 6) {
                List.push({
                    label: `${userInLogin.desc_role}-${userInLogin.name}`,
                    value: `${userInLogin.idUser}`
                })
            }
            let listDifent = List.filter(item2 => !usersSelected.some(item1 => item1.idUser === item2.value));

            let listDifent2 = usersSelected.filter(item2 => !List.some(item1 => item1.value === item2.idUser));

            if (id != 0 && listDifent != "") {

                // agregar

                //admins
                // const data = await userListRoleAdmin();

                // let list = data.filter(item2 => !List.some(item1 => item1.value === item2.idUser));

                for (let index = 0; index < listDifent.length; index++) {
                    const dataCustom = {
                        idNew: id,
                        idUser: listDifent[index].value,
                        isSelected: 1
                    }

                    await postNewUserService(dataCustom)

                }
            }

            if (id != 0 && listDifent2 != "") {

                // 

                for (let index = 0; index < listDifent2.length; index++) {
                    const dataCustom = {
                        idNew: id,
                        idUser: listDifent2[index].idUser,

                    }
                    const result = await deleteNewsIdUser(dataCustom.idNew, dataCustom.idUser)

                }

            }

            defaultListNews(id)

        } else {
            postNewUser(id, List)
        }












        await GetListNewBussinesById()
    }


    const putNewUserTecnici = async (id: Number, List: any, usersSelected: any) => {



        if (usersSelected) {
            if (Number(userInLogin.idRole) == 5 || Number(userInLogin.idRole) == 6) {
                List.push({
                    label: `${userInLogin.desc_role}-${userInLogin.name}`,
                    value: `${userInLogin.idUser}`
                })
            }
            let listDifent = List.filter(item2 => !usersSelected.some(item1 => item1.idUser === item2.value));

            let listDifent2 = usersSelected.filter(item2 => !List.some(item1 => item1.value === item2.idUser));

            if (id != 0 && listDifent != "") {
                // agregar

                //admins
                // const data = await userListRoleAdmin();

                // let list = data.filter(item2 => !List.some(item1 => item1.value === item2.idUser));

                for (let index = 0; index < listDifent.length; index++) {
                    const dataCustom = {
                        idNew: id,
                        idUser: listDifent[index].value,
                        isSelected: 1
                    }


                    await postNewUserService(dataCustom)

                }
            }

            if (id != 0 && listDifent2 != "") {
                // 

                for (let index = 0; index < listDifent2.length; index++) {
                    const dataCustom = {
                        idNew: id,
                        idUser: listDifent2[index].idUser,
                        isSelected: 0
                    }
                    const result = await deleteNewsIdUser(dataCustom.idNew, dataCustom.idUser)

                }

            }
        } else {
            postNewUserTecnici(id, List)
        }














        await GetListNewBussinesById()
    }






    //postNews
    const PostNews = async (dataSend: any) => {



        const dataCustom = {
            title: dataSend.Titolo,
            text: dataSend.Testo,
            nameFile: dataSend.nameFile ? dataSend.nameFile : '',
            nameImage: dataSend.nameImage ? dataSend.nameImage : '',
            date: new Date()
        }



        const result = await postNews(dataCustom)



        if (Number(result) > 0) {
            if (dataSend.Store != "") {
                await PostNewBussines(result, dataSend.Store)
            }
            if (dataSend.Dipendenti != "") {
                if (Number(userInLogin.idRole) == 5 || Number(userInLogin.idRole) == 6) {
                    dataSend.Dipendenti.push({
                        label: `${userInLogin.desc_role}-${userInLogin.name}`,
                        value: `${userInLogin.idUser}`
                    })
                }

                await postNewUser(result, dataSend.Dipendenti)

            }

            if (dataSend.Tecnici != "") {
                if (Number(userInLogin.idRole) == 5 || Number(userInLogin.idRole) == 6) {
                    dataSend.Tecnici.push({
                        label: `${userInLogin.desc_role}-${userInLogin.name}`,
                        value: `${userInLogin.idUser}`
                    })
                }
                await postNewUserTecnici(result, dataSend.Tecnici)
            }

            return result;
        }
    }


    const PutNews = async (dataSend: any) => {


        let optionSelected = stateOptionNew

        if (optionSelected == "option1" && dataSend.Store == "") {

            const deteBusines = await deleteNewsDataBussines(dataSend.id)

        }

        if (optionSelected == "option2" && dataSend.Dipendenti == "") {
            const deleteUser = await deleteNewsDataUser(dataSend.id)

        }

        if (optionSelected == "option3" && dataSend.Tecnici == "") {
            const deleteTecnici = await deleteNewsDataUser(dataSend.id)
        }

        const dataCustom = {
            title: dataSend.Titolo,
            text: dataSend.Testo,
            nameFile: dataSend.nameFile ? dataSend.nameFile : '',
            nameImage: dataSend.nameImage ? dataSend.nameImage : '',
            idNew: dataSend.id
        }

        const result = await putNewsData(dataCustom)

        if (result == "OK") {

            if (dataSend.Store != "") {

                const storedSelected = await bussinesListByIdNew(dataSend.id)

                await PutNewBussines(dataSend.id, dataSend.Store, storedSelected)
            }

            if (dataSend.Dipendenti != "") {
                const usersSelected = await usersNewsSelected(dataSend.id)

                await putNewUserDependente(dataSend.id, dataSend.Dipendenti, usersSelected)
            }


            if (dataSend.Tecnici != "") {
                const tecniciSelected = await usersNewsSelected(dataSend.id)
                await putNewUserTecnici(dataSend.id, dataSend.Tecnici, tecniciSelected)
            }

            return result;
        }

        return result

    }






    const DeleteNews = async (id) => {
        if (id) {
            const result = await deleteNewsData(id)
            await GetListNewBussinesById()
            return result
        } else {
            console.log("No se encuentra id")
        }



    }




    const ListUserAll = async () => {
        const result = await userListAll()

        if (result) {
            let filterUser = result.filter(x => Number(x.idRole) === 2 || Number(x.idRole) === 4 || Number(x.idRole) === 5 || Number(x.idRole) === 6)
            let filterTecnico = result.filter(x => Number(x.idRole) === 3 || Number(x.idRole) === 7 || Number(x.idRole) === 8)







            let UserCustom = filterUser.map(function (option) {
                return { label: `${option.desc_Role}-${option.userName}`, value: option.idUser };
            });
            let UserTencnicoCustom = filterTecnico.map(function (option) {
                return { label: option.userName, value: option.idUser };
            });


            setUserList(UserCustom)
            setUserTecnicoList(UserTencnicoCustom)
        }
    }


    const confirmviewNew = async (data) => {

        if (data.idNewBussinesUsers) {

            const result = await putNewBussinesUserIsChecked(data.idNewBussinesUsers);
            if (result) {

                await GetListNewBussinesById();

            }
        }


        if (data.idNewUser) {
            const result = await putNewUserIsChecked(data.idNewUser)
            if (result) {
                await GetListNewBussinesById()
            }
        }




        let conditionalPut = data.idNewUser

        if (conditionalPut) {
            const result = await putNewUserIsChecked(data.idNewUser)
            if (result) {
                await GetListNewBussinesById()
            }
        } else {
            const result = await putnewBussinesIsChecked(data)
            if (result) {
                await GetListNewBussinesById()
            }
        }
    }


    const GetListByIdNewBussines = async (dataSend) => {

        if (dataSend.idNewBussinesUsers) {
            const result = await listByidNewBussines(dataSend.idNew)
            if (result) {

                setListIdNewBussineView(result);


            }
        }

        if (dataSend.idNewUser) {

            const result2 = await listByidNewBussinesUsersCheck(dataSend.idNew);
            if (result2) {
                setListIdNewBussineView(result2);

            }
        }




        // const result = await listByidNewBussines(dataSend)
        // if (result) {
        //     setListIdNewBussineView(result)
        // }
    }

    const getPDFNews = async (name: string, ext: string) => {

        const result = await getPdf(name, ext)

        return result

    }

    const getIdRole = async () => {
        const idUser = Number(userInLogin.idUser)

        const result = await getIdRoleData(idUser)

        return result.idRole

    }






    useEffect(() => {
        ListBussines()
        ListUserAll()
        GetListNewBussinesById()
        GetListNewUserById()
    }, [])

    return {
        BussinesList,
        stateOptionNew,
        defaultListNews,
        defaultBussinesList,
        defaultUserList,
        defaultUserTecnicoList,
        UserList,
        PutNews,
        UserTecnicoList,
        PostNews,
        DeleteNews,
        ListNewBussinesById,
        GetListByIdNewBussines,
        listIdNewBussinesView,
        confirmviewNew,
        getPDFNews,
        GetListNewBussinesById,
        getIdRole
    };
}

export default useNews;