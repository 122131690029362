import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ScrollView,
  Image,
  FlatList,
  Pressable,
} from "react-native";
import "bootstrap/dist/css/bootstrap.min.css";
import { styles } from "../../../theme/GlobalStyle";
import { HeaderNewRepair as Header } from "../../../components/HeaderNewRepair";
import axios from "axios";
import { StackScreenProps } from "@react-navigation/stack";
import Loading from "../../../components/Loading";
import InputComponent from '../../../components/ComponentsAtelier/inputs/inputComponent';
import { useRepairAtelier } from "../hooks/useRepairAtelier";

interface Props extends StackScreenProps<any, any> { }
const styleInput = {
  width: "100%",
  backgroundColor: "transparent",
  height: 50,
  margin: 0,
  borderWidth: 2,
  borderRadius: 50,
  borderColor: "black",
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
};
const styleInputTextArea = {
  width: "100%",
  backgroundColor: "rgb(234, 234, 234)",
  height: "auto",
  borderRadius: 25,
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
  textAlignVertical: "auto",
  padding: 30,
};
export default function NewRepairAtelierScreen({ route, navigation }: Props) {

  let arrayData: any = route.params || [];
  let titleScreen = "Nuova riparazione";
  const [titleHeader, setTitleHeader] = useState(titleScreen);
  const [activeSpinner, setActiveSpinner] = useState(false);

  const { register, errors, title, handleChangeLimitClient, listClients, handleChangeOptionSelect, brand } = useRepairAtelier();

  return (
    <ScrollView>
      {activeSpinner && <Loading activeSpinner={activeSpinner} />}
      <View
        style={{
          paddingHorizontal: 12,
          backgroundColor: "white",
        }}
      >
        <Header
          title={titleHeader}
          onBack={() => navigation.navigate("HomeScreen", { section: "atelier" })}
        />
      </View>
      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="col-md-10 col-sm-10 col-12 m-auto">
          <View style={styles.container}>
            <Text
              style={[
                styles.left,
                {
                  marginTop: 20,
                  marginBottom: 10,
                  fontWeight: "500",
                  fontSize: 20,
                },
              ]}
            >
              Dati anagrafici cliente atelier
            </Text>

            <br />

            <InputComponent
              name="idTitle"
              label="Titolo *"
              type="select"
              register={register('idTitle', { required: 'Il campo è obbligatorio' })}
              errors={errors}
              options={title}
            /> <br />

            <InputComponent
              name="Name"
              label="Nome e Cognome *"
              type="text"
              register={register('Name', { required: 'Il campo è obbligatorio', onChange: (evt) => handleChangeLimitClient(evt) })}
              errors={errors}
              optionsSelect={listClients}
              onOptionSelect={handleChangeOptionSelect}
            /> <br />

            <InputComponent
              name="address"
              label="Indirizzo"
              type="text"
              register={register('address', { /*required: 'Il campo è obbligatorio' */ })}
              errors={errors}
            /> <br />

            <InputComponent
              name="email"
              label="E-mail"
              type="email"
              register={register('Email', { /*required: 'Il campo è obbligatorio' */ })}
              errors={errors}
            /> <br />

            <InputComponent
              name="telephone"
              label="Numero di telefono *"
              type="text"
              register={register('telephone', { /*required: 'Il campo è obbligatorio' */ })}
              errors={errors}
            /> <br />

            <InputComponent
              name="business"
              label="Negozio di riferimento *"
              type="text"
              disabledInput={true}
              register={register('business', { required: 'Il campo è obbligatorio' })}
              errors={errors}
              options={title}
            /> <br />

            <InputComponent
              name="operatore"
              label="Operatore"
              type="text"
              disabledInput={true}
              register={register('operatore')}
              errors={errors}
              options={title}
            /> <br />

            <h3 style={{ marginTop: 50, marginBottom: 10, fontWeight: 600, fontSize: 20 }}>Informazioni riparazione</h3> <br />

            <InputComponent
              name="brandOfProduct"
              label="Orologio marcato *"
              type="select"
              register={register('brandOfProduct', { required: 'Il campo è obbligatorio' })}
              errors={errors}
              options={brand}
            /> <br />

            <InputComponent
              name="referens"
              label="Referenza"
              type="text"
              register={register('referens')}
              errors={errors}
            /> <br />

            <InputComponent
              name="enrollment"
              label="Matricola"
              type="text"
              register={register('enrollment')}
              errors={errors}
            /> <br />

            <InputComponent
              name="envelopeNumber"
              label="Numero Busta"
              type="text"
              register={register('envelopeNumber')}
              errors={errors}
            /> <br />

            <div className="col-12 d-flex justify-content-end">
              <div style={{ cursor: 'pointer' }}>
                <img src={require('../../../assets/icons/camera2.png')} style={{ width: 25, height: 25, marginRight: 15 }} alt="" />
                <input
                  type="file"
                  name="image-files"
                  id="image-files"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                  multiple
                // onChange={(e) => uploadFiles(e.target.files, "image")}
                />
              </div>
              <div style={{ cursor: 'pointer' }}>
                <img src={require('../../../assets/icons/link.png')} style={{ width: 25, height: 25 }} alt="" />
                <input
                  type="file"
                  name="document-files"
                  id="document-files"
                  accept="application/pdf"
                  multiple
                  style={{ display: "none" }}
                // onChange={(e) => uploadFiles(e.target.files, "document")}
                />
              </div>
            </div> <br />

            <InputComponent
              name="note"
              label="Note"
              type="textarea"
              register={register('note')}
              errors={errors}
              rows={9}
            /> <br />


          </View>
        </div>
      </div>
    </ScrollView>
  );
}
