import React, { useContext, useEffect, useMemo, useState } from "react";
import { ImageBackground, ScrollView, Text, TextInput, TouchableOpacity, View, FlatList, Pressable, StyleSheet, } from "react-native";
import { styles, tableHeader, tableRow } from "../../theme/GlobalStyle";
import ConfigCapone from "../../utils/ConfigCapone";
import axios from "axios";
import { CheckLabel } from "../../components/CheckLabel";
import { hobbyData } from "../../api/HobbyData"
import { Image } from "react-native-elements";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { filterAddress, filterGender, filterPhone, others } from "../../api/FilterClientData";
import { styleInput, styleInputBlock, stylesCRM, styleTextArea } from "../../GeneralStyles/SCDue/ControlPanel";
import PopUpMessageWithRoute from "../../components/PopUpMessageWithRoute";
import { HeaderBar } from "../../components/HeaderBar";
import { useForm, Controller } from "react-hook-form";
import { BsStarFill } from "react-icons/bs";
import { getInteressiByClient } from '../../services/InteressiDetails';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it"
registerLocale("it", it)
import Loading from "../../components/Loading";
import { UserContext } from "../../context/UserContext";
import '../../utils/styles.css'
import CurrencyInput from "react-currency-input-field";

import { IClient } from "../../interfaces/IClient";
import { Icon } from "react-native-elements";
import { useIsFocused } from "@react-navigation/native";
import { IRepairData } from "../../api/RepairData";
import RepairCardScreen from "../RepairCardScreen";
import { getUserFromId, IServiceClient, IUser } from "../../api/ClientService";
import { SendNotificationScreen } from "../SendNotificationScreen";
import { SendEmailScreen } from "../SendEmailScreen";
import PopupConfirmation from "../../components/PopupConfirmation";
// import "../../theme/styleBackground.css";

import Cookies from 'universal-cookie';
import { validateEmail } from "../../helpers/patterns";
import { FindClient, ListInteressiByClient } from "../../services/interessiServices";
import { GridListInteressiByClient } from "../../components/Manifestazione/GridListInteressiByClient";
import { GridListInteressiByClientUpdate } from "../../components/Manifestazione/GridListInteressiByClientUpdate";
import { formatMessageEmail } from "./FormatMessageEmail/formatMessageEmail";
import { formatMessageEmailBirthDay } from "./FormatMessageEmail/formatMessageEmailBirthDay";
import Debounce from "../../components/search/Debounce";
import useDebounce from "../../components/search/useDebounce";

import debounce from "lodash.debounce";
import Log from "../../pages/security/Log";

interface Props extends NativeStackScreenProps<any, any> { }
//FECHA HORA
const dateTimeNow = (fecha: string = '') => {
    let date
    if (fecha !== '') {
        date = new Date(fecha)
    } else {
        date = new Date()
    }
    const year = date.getFullYear()
    const month =
        date.getMonth() + 1 < 10
            ? `0${date.getMonth() + 1}`
            : date.getMonth() + 1
    const day = date.getDate().toString().padStart(2, "0")
    const hour = date.getHours().toString().padStart(2, "0")
    const minute = date.getMinutes().toString().padStart(2, "0")
    const seconds = date.getSeconds().toString().padStart(2, "0")
    const dateNow =
        year +
        '-' +
        month +
        '-' +
        day +
        'T' +
        hour +
        ':' +
        minute +
        ':' +
        seconds

    return dateNow
}

const dateTimeNowMessage = (fecha: string = '') => {
    let date
    if (fecha !== '') {
        date = new Date(fecha)
    } else {
        date = new Date()
    }
    const year = date.getFullYear()
    const month =
        date.getMonth() + 1 < 10
            ? `0${date.getMonth() + 1}`
            : date.getMonth() + 1
    const day = date.getDate().toString().padStart(2, "0")
    const hour = date.getHours().toString().padStart(2, "0")
    const minute = date.getMinutes().toString().padStart(2, "0")
    const seconds = date.getSeconds().toString().padStart(2, "0")
    const dateNow =
        day +
        '/' +
        month +
        '/' +
        year +
        ', ore ' +
        hour +
        ':' +
        minute +
        ':' +
        seconds

    return dateNow
}
export const ControlPanelScreen = ({ route, navigation }: Props) => {
    let arrayData: any = route.params || [];
    console.log(arrayData);
    const { userInLogin } = useContext(UserContext);
    const [title, setTitle] = useState('');
    const [titleType, setTitleType] = useState([]);
    const [dataGender, setDataGender] = useState([]);
    const [dataAddressType, setDataAddressType] = useState([]);
    const [dataPhonetype, setDataPhonetype] = useState([]);
    const [dataBrand, setDataBrand] = useState([]);
    const [dataRelationShip, setDataRelationShip] = useState([]);
    const [dataHobby, setDataHobby] = useState([]);
    const [dataLatestUpdates, setDataLatestUpdates] = useState([]);
    const [dataBussinesList, setDataBussinesList] = useState([]);
    // const [sendEmail, setsendEmail] = useState(false);
    const [addProduct, setAddProduct] = useState(false);
    const [activeSpinner, setActiveSpinner] = useState(false);
    const [dataClient, setDataClient] = useState<IClient>();
    const [dataClientList, setDataClientList] = useState<any>();

    const [isDipendente, setIsDipendente] = useState<boolean>(userInLogin?.idRole === '4' ? true : false);
    //verify number
    const [idClientExist, setIdClientExist] = useState<number>(0)
    // reparazioni
    const [listUsers, setListUsers] = useState<any[]>();
    const [apiState, setApiState] = useState([]);
    const [isEmployee, setIsEmployee] = useState<boolean>(userInLogin?.idRole === '4' ? true : false);
    const [isAdmin, setIsAdmin] = useState<boolean>(userInLogin?.idRole === '2' ? true : false);
    const [isTechnique, setIsTechnique] = useState<boolean>((userInLogin?.idRole === '3' || userInLogin?.idRole === '7' || userInLogin?.idRole === '8') ? true : false);
    const [dataBussines, setDataBussines] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalCorreo, setModalCorreo] = useState<boolean>(false);
    const [userList, setUserList] = useState<any>();
    const [idStateRepair, setIdStateRepair] = useState(0);
    const [modal, setModal] = useState(false);
    const [navs, setNavs] = useState([]);
    const [viewPagination, setViewPagination] = useState<boolean>(true);
    const [blockLeft, setBlockLeft] = useState<boolean>(true);
    const [arrayDatas, setArrayDatas] = useState<any>([]);
    const [number, setNumber] = useState(5);
    const [blockRight, setBlockRight] = useState<boolean>(false);
    const [stateRepair, setStateRepair] = useState<any>([]);
    const [totalListUsers, setTotalListUsers] = useState<any[]>();
    const [userFromAPI, setUserFromAPI] = useState([]);
    const [apiBusiness, setApiBusiness] = useState([]);
    const [defaultBusiness, setDefaultBusiness] = useState("");
    const [page, setPage] = useState(1);
    const [modalVisibleCard, setModalVisibleCard] = useState(false);
    const [dataRepair, setDataRepair] = useState<IServiceClient>();
    const [operatore, setOperatore] = useState<IUser>();
    const [activeBottom, setActiveBottom] = useState<boolean>(true);
    const [dataTableSend, setDataTableSend] = useState<any>();
    const [modalMessage, setModalMessage] = useState({
        active: false,
        message: "",
    });
    const [dataListInteressiByClient, setDataListInteressiByClient] = useState([]);
    // filter
    const [optionAddEdit, setOptionAddEdit] = useState(false);
    //
    //cookie
    const cookies = new Cookies();
    const [searchInputName, setSearchInputName] = useState<string>();
    const [autosuggestInputName, setAutosuggestInputName] = useState<IClient[]>([]);

    const [searchInputNameRelation, setSearchInputNameRelation] = useState<string>();
    const [autosuggestInputNameRelation, setAutosuggestInputNameRelation] = useState<IClient[]>([]);

    const [listMatricolaOwnProduct, setListMatricolaOwnProduct] = useState<any[]>();
    const [listMatricolaObservedProduct, setListMatricolaObservedProduct] = useState<any[]>();

    const errorMessageRequired = "Il campo è obbligatorio";

    const [createdTableBy, setCreatedTableBy] = useState(false);
    const [latestUpdates, setLatestUpdates] = useState(false);
    const [repairs, setRepairs] = useState(false);
    const [hands, setHands] = useState(false);
    const [showProdottiObs, setShowProdottiObs] = useState(false);
    const [dateInsert, setDateInsert] = useState(false);

    let dataMagazzino = arrayData ? arrayData.dataMagazzino : "";
    let optionProduct = arrayData ? arrayData.option : "";
    let dataListClient = arrayData ? arrayData.clientList : "";
    let idListeClient = arrayData ? arrayData.arrayData : "";


    const [idBussinesSelect, setIdBussinesSelect] = useState(0);
    //checkBox
    //Invio auguri
    const [checkBoxInvioAuguriChecked, setCheckBoxInvioAuguriChecked] = useState(false);
    const [checkBoxInviaCatCasaChecked, setCheckBoxInviaCatCasaChecked] = useState(false);// agregada
    const [checkBoxGenderChecked, setcheckBoxGenderChecked] = useState(false);
    const [checkBoxAddressTypeChecked, setCheckBoxAddressTypeChecked] = useState(false);
    const [checkBoxPhonetypeChecked, setCheckBoxPhonetypeChecked] = useState(false);
    const [checkBoxHobbyChecked, setCheckBoxHobbyChecked] = useState<any>([]);
    const [checkBoxDeprecatiChecked, setCheckBoxDeprecatiChecked] = useState(false);
    const [checkBoxBlackListChecked, setCheckBoxBlackListChecked] = useState(false);
    const [checkBoxInListaChecked, setCheckBoxInListaChecked] = useState(false);
    const [checkBoxIsImportantChecked, setCheckBoxIsImportantChecked] = useState("");
    const [totalProductPosseduti, setTotalProductPosseduti] = useState(0)

    const [countRelationShip, setCountRelationShip] = useState<any>([{
        idRelationship: null,
        Name: '',
        Id: null,
        hasError: false,
    }]);

    const [countObservedProduct, setCountObservedProduct] = useState<any>([]);
    const [countOwnProducts, setCountOwnProducts] = useState<any>([]);

    const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({
        title: "Informazione",
        message: "",
        active: false,
        route: "",
    });

    //start
    const [currentValue, setCurrentValue] = useState(0);
    const [hoverValue, setHoverValue] = useState(undefined);
    const stars = Array(3).fill(0)

    const colors = {
        orange: "black",
        grey: "#EAECEF"
    };

    const handleClickStart = (value, state?: boolean) => {
        if (value == currentValue && !state) {
            setCurrentValue(0);
        } else {
            setCurrentValue(value)
        }

    }

    const handleMouseOverStart = newHoverValue => {
        setHoverValue(newHoverValue)
    };

    const handleMouseLeaveStart = () => {
        setHoverValue(undefined)
    }

    // change checkbox
    const changeGender = (value: any, state?: boolean) => {

        console.log("value: ", value);

        setcheckBoxGenderChecked(value)
        if (checkBoxGenderChecked == value && !state) {
            setcheckBoxGenderChecked(null)
        }
    }
    // metodo agregado por axel
    const changeInviaCatalogo = (value: any, state?: boolean) => {
        console.log(value);
        setCheckBoxInviaCatCasaChecked(value)
        if (checkBoxInviaCatCasaChecked == value && !state) {
            setCheckBoxInviaCatCasaChecked(null)
        }
    }
    //hasta aqui
    const changeInvioAuguri = (value: any, state?: boolean) => {
        console.log(value);
        setCheckBoxInvioAuguriChecked(value)
        if (checkBoxInvioAuguriChecked == value && !state) {
            setCheckBoxInvioAuguriChecked(null)
        }
    }
    const changeAddresstype = (value: any, state?: boolean) => {
        setCheckBoxAddressTypeChecked(value)
        if (checkBoxAddressTypeChecked == value && !state) {
            setCheckBoxAddressTypeChecked(null)
        }
    }

    const changePhonetype = (value: any) => {
        setCheckBoxPhonetypeChecked(value)
        if (checkBoxPhonetypeChecked == value) {
            setCheckBoxPhonetypeChecked(null)
        }
    }

    const changeHobby = (item: any) => {
        setCheckBoxHobbyChecked((state: any) => ({
            ...state,
            [`hobby-${item.idHobby}`]: state[`hobby-${item.idHobby}`]
                ? null
                :
                {
                    idClient_Hobby: item.idClient_Hobby ? item.idClient_Hobby : '',
                    idHobby: `hobby-${item.idHobby}`,
                    hobby: item.hobby,
                    image: item.image
                }
        }))
    }

    const changeDeprecati = (value: any, state?: boolean) => {

        setCheckBoxDeprecatiChecked(value)
        if (checkBoxDeprecatiChecked == value && !state) {
            setCheckBoxDeprecatiChecked(null)
        }

        // if(checkBoxBlackListChecked){
        //     setCheckBoxBlackListChecked(null)     
        // }

    }

    const changeBlackList = (value: any, state?: boolean) => {

        setCheckBoxBlackListChecked(value)
        if (checkBoxBlackListChecked == value && !state) {
            setCheckBoxBlackListChecked(null)
        }

        // if(checkBoxDeprecatiChecked){
        //     setCheckBoxDeprecatiChecked(null)     
        // }

    }

    const changeIsImportant = (value: any, state?: boolean) => { // changeIsImportant
        console.log("estas en el check: ",value)
        setCheckBoxIsImportantChecked(value)
        if (checkBoxIsImportantChecked == value && !state) {
            setCheckBoxIsImportantChecked(null)
        }
    }

    const changeInLista = (select: boolean) => {

        setCheckBoxInListaChecked(!select)
        console.log("cambio de check", checkBoxInListaChecked)
    }

    useEffect(() => {
        console.log(checkBoxHobbyChecked);
    }, [checkBoxHobbyChecked]);

    // get apis 
    const getTitle = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Title?parameter=1`)
            .then((response) => {
                // let dataTitle = response.data.response.filter(x => x.desc_Title !== "Altro")
                let dataTitle = response.data.response
                setTitleType(dataTitle)
            })
            .catch((error) => console.log(error));
    };

    const getGender = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Gender?parameter=1`)
            .then((response) => setDataGender(response.data.response))
            .catch((error) => console.log(error));
    };

    const getAddresstype = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Addresstype?parameter=1`)
            .then((response) => setDataAddressType(response.data.response))
            .catch((error) => console.log(error));
    };

    const getPhonetype = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Phonetype?parameter=1`)
            .then((response) => setDataPhonetype(response.data.response))
            .catch((error) => console.log(error));
    };

    const getRelationShip = async () => {
        await axios(`${ConfigCapone.urlapi}/api/RelationShip?parameter=1`)
            .then((response) => setDataRelationShip(response.data.response))
            .catch((error) => console.log(error));
    };
    const getBussinesList = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`)
            .then((response) => {
                let data = response.data.response.filter( x => Number(x.idBussines) != 9)
                setDataBussinesList(data)
            })
            .catch((error) => console.log(error));
    };

    const getHobby = async () => {
        await axios(`${ConfigCapone.urlapi}/api/Hobby?parameter=1`)
            .then((response) => {
                setDataHobby(response.data.response)
                console.log(response.data.response)
                let data = response.data.response;
            })
            .catch((error) => console.log(error));
    };

    const getBrand = async () => {
        await axios
            .get(`${ConfigCapone.urlapi}/api/Brand?parameter=1`)
            .then((response) => {
                setDataBrand(response.data.response)
            })
            .catch((err) => console.log(err));
    };

    //

    function onlyOne(checkbox: any) {
        var checkboxes = document.getElementsByName('address')
        checkboxes.forEach((item) => {
            console.log(item);
            // if (item !== checkbox) item.checked = false
        })
    }

    //verify number
    const verifyPhone = async () => {
        let verifyPhone = getValues("Phone") ? getValues("Phone") : null;
        if (verifyPhone) {
            let result = await FindClient(verifyPhone);
            //console.log("dataX",result,"--",verifyPhone)
            if (optionAddEdit) {
                // edit
                if (Object.keys(result)?.length > 0) {
                    let data = result.find(x => Number(x.idClient) !== idClientExist)
                    //console.log("dataX ", data, " - ", Object.keys(result)?.length, " - ", idClientExist);
                    if (data) {
                        if (Object.keys(data)?.length > 0) {
                            let numberExist = result?.find(x => Number(x.idClient) == idClientExist)
                            //console.log("id",numberExist)
                            if (Number(numberExist?.idClient) == idClientExist) {
                                //console.log("dataX ",Number(numberExist?.idClient),"true",idClientExist)
                                return true;
                            } else {
                                //console.log("dataX ",Number(numberExist?.idClient),"false",idClientExist)
                                return false;
                            }
                        }
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                // add
                if (Object.keys(result)?.length > 0) {
                    let data = result?.find(x => Number(x.phone) == getValues("Phone"))
                    //console.log(data)
                    if (data) {
                        if (Number(data?.idClient) == idClientExist) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }

                } else {
                    return true;
                }

            }
        }
        return false;
    }
    const handleData = async (activeNavigation: boolean = false, isChecked?: boolean) => {
        setActiveSpinner(true);
        let verifyN = await verifyPhone()
        if (verifyN) {
            console.log("hola 123", verifyN)
            console.log("hola 123");



            let state: boolean = false;
            let idClient: number;

            let gender: any = checkBoxGenderChecked;
            let idGender: any;
            checkBoxGenderChecked && (idGender = gender?.split("-"))

            let addressType: any = checkBoxAddressTypeChecked;
            let idAddressType: any;
            checkBoxAddressTypeChecked && (idAddressType = addressType?.split("-"))

            let phoneType: any = getValues("PhoneType");
            let idPhoneType: any = phoneType?.split("-");

            // hobby

            let hobbyList: any = [];
            let idhobbyList: any = [];

            if (checkBoxHobbyChecked) {
                for (const key in checkBoxHobbyChecked) {
                    if (Object.prototype.hasOwnProperty.call(checkBoxHobbyChecked, key)) {
                        const element = checkBoxHobbyChecked[key];
                        if (element !== null) {
                            hobbyList.push(element)
                        }
                    }
                }
            }

            if (Object.keys(hobbyList).length > 0) {
                for (let index = 0; index < hobbyList.length; index++) {
                    let id: any = hobbyList[index].idHobby?.split("-");
                    idhobbyList?.push(id[1])
                }
            }

            let addressStreet = getValues("AddressStreet") ? getValues("AddressStreet") : null
            let addressNumber = getValues("AddressNumber") ? getValues("AddressNumber") : null
            let addressCity = getValues("AddressCity") ? getValues("AddressCity") : null
            let zipCode = getValues("ZipCode") ? getValues("ZipCode") : null
            let country = getValues("Country") ? getValues("Country") : null

            let address: any;

            if (addressStreet != null && addressNumber != null && addressCity != null && zipCode != null && country != null) {
                address = addressStreet + " " + addressNumber + ", " + addressCity + ", " + zipCode + ", " + country;
            } else {
                if (addressStreet != null && addressNumber != null) {
                    address = addressStreet + " " + addressNumber;
                } else {
                    if (addressStreet != null) {
                        address = addressStreet;
                    } else {
                        address = null;
                    }
                }
            }
            //setCheckBoxIsImportantChecked(data.isImportant == true ? "important-3" : "0");
            let isImportant = checkBoxIsImportantChecked === "important-3" ?true:false
            const newClient = {
                idUser: Number(userInLogin?.idUser),
                idTitle: Number(getValues("Title")),
                Name: getValues("Name"),
                LastName: getValues("Name"),
                idGender: checkBoxGenderChecked ? Number(idGender[1]) : null,
                Address: address,
                idAddressType: checkBoxAddressTypeChecked ? Number(idAddressType[1]) : null,
                AddressStreet: getValues("AddressStreet") ? getValues("AddressStreet") : null,
                AddressNumber: getValues("AddressNumber") ? getValues("AddressNumber") : null,
                AddressCity: getValues("AddressCity") ? getValues("AddressCity") : null,
                ZipCode: getValues("ZipCode") ? getValues("ZipCode") : null,
                Province: getValues("Province") ? getValues("Province") : null,
                Country: getValues("Country") ? getValues("Country") : null,
                idPhoneType: Number(idPhoneType[1]),
                Phone: getValues("Phone") ? getValues("Phone") : null,
                Email: getValues("Email"),
                Profession: getValues("Profession") ? getValues("Profession") : null,
                DateBirth: getValues("DateBirth") ? dateTimeNow(getValues("DateBirth")) : null,
                // SendEmail : sendEmail ? true : null,
                Note: getValues("Note") ? getValues("Note") : null,
                MeasureLittleFingerLH: Number(getValues("MeasureLittleFingerLH")) ? Number(getValues("MeasureLittleFingerLH")) : null,
                MeasureRingFingerLH: Number(getValues("MeasureRingFingerLH")) ? Number(getValues("MeasureRingFingerLH")) : null,
                MeasureMiddleFingerLH: Number(getValues("MeasureMiddleFingerLH")) ? Number(getValues("MeasureMiddleFingerLH")) : null,
                MeasureIndexFingerLH: Number(getValues("MeasureIndexFingerLH")) ? Number(getValues("MeasureIndexFingerLH")) : null,
                MeasureThumbLH: Number(getValues("MeasureThumbLH")) ? Number(getValues("MeasureThumbLH")) : null,
                MeasurePulseLH: Number(getValues("MeasurePulseLH")) ? Number(getValues("MeasurePulseLH")) : null,
                MeasureLittleFingerRH: Number(getValues("MeasureLittleFingerRH")) ? Number(getValues("MeasureLittleFingerRH")) : null,
                MeasureRingFingerRH: Number(getValues("MeasureRingFingerRH")) ? Number(getValues("MeasureRingFingerRH")) : null,
                MeasureMiddleFingerRH: Number(getValues("MeasureMiddleFingerRH")) ? Number(getValues("MeasureMiddleFingerRH")) : null,
                MeasureIndexFingerRH: Number(getValues("MeasureIndexFingerRH")) ? Number(getValues("MeasureIndexFingerRH")) : null,
                MeasureThumbRH: Number(getValues("MeasureThumbRH")) ? Number(getValues("MeasureThumbRH")) : null,
                MeasurePulseRH: Number(getValues("MeasurePulseRH")) ? Number(getValues("MeasurePulseRH")) : null,
                StarValuation: currentValue != 0 ? currentValue : null,
                Deprecated: checkBoxDeprecatiChecked ? true : null,
                BlackList: checkBoxBlackListChecked ? true : null,
                IsImportant: isImportant ? true : false,
                EnviaCatalogo: checkBoxInviaCatCasaChecked ? true : null,
                invioAuguri: checkBoxInvioAuguriChecked ? true : null, 
                OrigenIdBussines: Number(getValues("OriginIdBussines")) ? Number(getValues("OriginIdBussines")) : Number(userInLogin?.idBussines),
            };
            //console.log(newClient,"DateNew",getValues("DateBirth"),"fffffffff",new Date(getValues("DateBirth")).toLocaleDateString('it-IT'))
            //console.log("pruebagetvaules",Number(getValues("OriginIdBussines")))
            await axios
                .post(`${ConfigCapone.urlapi}/api/Client/SaveClientCrm`, newClient, {
                    headers: {
                        'Authorization': `Bearer ${cookies.get('token')}`
                    }
                })
                .then((response) => {

                    idClient = Number(response.data.id);

                    state = true;

                })
                .catch((error) => {
                    setActiveSpinner(false);
                    setmodaleMessaggeWithUrl({
                        active: true,
                        message: `${error}`,
                        title: "Errore",
                        route: "",
                    });
                });

            if (state) {
                if (ConfigCapone.configTest) {
                    if (newClient.invioAuguri) {
                        const titleClientSend = titleType.find(element => element.idTitle == newClient.idTitle)
                        const emailMessage = formatMessageEmailBirthDay(titleClientSend?.desc_Title, newClient.Name, newClient.Country)
                        for (let i = 0; i < ConfigCapone.emailTest.length; i++) {
                            let dataSend = {
                                from: "customerservice@sergiocapone.com",
                                type: "notice",
                                email: ConfigCapone.emailTest[i],
                                subject: "Tanti auguri dalla Gioielleria Capone",
                                message: emailMessage
                            };
                            await axios
                                .post(
                                    `${ConfigCapone.apicapone}/api/forma`,
                                    dataSend
                                )
                                .then((res) => {
                                    console.log("email send");
                                })
                                .catch((err) => {
                                    console.log("there was an error: " + err)
                                });
                        }
                    }
                }
                (Object.keys(hobbyList).length > 0) && addHobbys(idClient, idhobbyList)

                await insertRelationShip(idClient);

                await insertObservedProduct(idClient);

                isChecked ? await insertOwnProduct(idClient, false, true) : await insertOwnProduct(idClient);

                await insertStatusClient(idClient);

                let client: any = [];

                await axios
                    .get(`${ConfigCapone.urlapi}/api/Client/FindByIdClientCrm/${idClient}`)
                    .then((response) => {
                        client = response.data.response

                    })
                    .catch((err) => console.log(err));
                console.log(client)
                await resultSearch(client)

                try {
                    const res = await axios.get(`${ConfigCapone.urlapi}/api/Client/FindByIdClientCrm/${idClient}`);
                    const clientcrm = await res.data.response;
                    await sendEmailsSave(countOwnProducts, clientcrm);


                } catch (error) {
                    // Handle errors
                }

                setmodaleMessaggeWithUrl({
                    active: true,
                    message: "Salvata con successo.",
                    title: "Informazione",
                    route: "",
                });


            }
        } else {
            setmodaleMessaggeWithUrl({
                active: true,
                message: "Numero di telefono già registrato",
                title: "Errore",
                route: "",
            });
        }
        setActiveSpinner(false);
    }

    const sendEmailsSave = async (countOwnProducts, clientCrm) => {

        for (var i = 0; i < countOwnProducts.length; i++) {

            if (countOwnProducts[i].SendEmail) {
                var data = countOwnProducts[i];
                let state: boolean = false;
                //if (clientCrm?.email?.length > 0) {

                const titleClientSend = titleType.find(element => element.idTitle == clientCrm.idTitle)
                if (data?.Prodotto?.length > 0 && data?.Matricola?.length > 0 && data?.Prezzo?.toString()?.length > 0 && data?.Referenza?.length > 0
                    && data?.idBrand?.length > 0 && data?.Data?.toString()?.length > 0) {
                    let idBussinesMatricola;

                    await axios
                        .get(`${ConfigCapone.urlapi}/api/Matricola/AllRegister/${data.idMatricola}`)
                        .then((response) => {
                            idBussinesMatricola = response.data.response?.idNegozio;
                        })
                        .catch((error) => {
                            console.log("error -> ", error);

                        });

                    const bussinesData = dataBussinesList.find(element => element.idBussines == idBussinesMatricola)
                    const aux = data.Prodotto.split(" ")
                    const dataProdotto = aux[0]
                    const emailMessage = formatMessageEmail(titleClientSend?.desc_Title, clientCrm.name, dataProdotto, bussinesData?.desc_Bussines, clientCrm.country)
                    if (ConfigCapone.configTest) {
                        for (let i = 0; i < ConfigCapone.emailTest.length; i++) {
                            let dataSend = {
                                from: "customerservice@sergiocapone.com",
                                type: "notice",
                                email: ConfigCapone.emailTest[i],
                                subject: "Grazie per il suo acquisto",
                                message: emailMessage
                            };


                            await axios
                                .post(
                                    `${ConfigCapone.apicapone}/api/forma`,
                                    dataSend
                                )
                                .then((res) => {
                                    console.log("email send");
                                    state = true
                                })
                                .catch((err) => {
                                    console.log("there was an error: " + err)
                                    state = false
                                });
                        }
                    } else {
                        //     if(clientCrm?.email?.length > 0) {
                        //     let dataSend = {
                        //         from: "customerservice@sergiocapone.com",
                        //         type: "notice",
                        //         email: clientCrm.email,
                        //         subject: "Grazie per il suo acquisto",
                        //         message: emailMessage
                        //     };


                        //     await axios
                        //         .post(
                        //             `${ConfigCapone.apicapone}/api/forma`,
                        //             dataSend
                        //         )
                        //         .then((res) => {
                        //             console.log("email send");
                        //             state = true
                        //         })
                        //         .catch((err) => {
                        //             console.log("there was an error: " + err)
                        //             state = false
                        //         });
                        // }
                    }


                } else {
                    console.log("no se envia el email");

                }

                // } else {

                //     setmodaleMessaggeWithUrl({
                //         active: true,
                //         message: "L'utente non ha un'email registrata.",
                //         title: "Errore",
                //         route: "",
                //     });
                // }


            }
        }
    }
    const sendEmails = async (countOwnProducts) => {
        for (var i = 0; i < countOwnProducts.length; i++) {
            let conditionalSendEmail;

            console.log("sssdd", countOwnProducts[i].idClientProdPosseduti)
            await axios
                .get(`${ConfigCapone.urlapi}/api/ClientProdposseduti/${countOwnProducts[i].idClientProdPosseduti}`)
                .then((response) => {
                    console.log(response)
                    conditionalSendEmail = response.data.response?.sendEmail;
                })
                .catch((error) => {
                    console.log("error -> ", error);

                });

            console.log("sssdd", conditionalSendEmail)
            if (countOwnProducts[i].SendEmail == true && (conditionalSendEmail == false || conditionalSendEmail == null)) {

                var data = countOwnProducts[i];
                console.log("dataaaa", data)
                let state: boolean = false;

                if (dataClient?.email?.length > 0) {

                    const titleClientSend = titleType.find(element => element.idTitle == dataClient.idTitle)


                    if (data?.Prodotto?.length > 0 && data?.Matricola?.length > 0 && data?.Prezzo?.toString()?.length > 0 && data?.Referenza?.length > 0
                        && data?.idBrand?.length > 0 && data?.Data?.toString()?.length > 0) {
                        let idBussinesMatricola;

                        await axios
                            .get(`${ConfigCapone.urlapi}/api/Matricola/AllRegister/${data.idMatricola}`)
                            .then((response) => {
                                idBussinesMatricola = response.data.response?.idNegozio;
                            })
                            .catch((error) => {
                                console.log("error -> ", error);

                            });
                        const bussinesData = dataBussinesList.find(element => element.idBussines == idBussinesMatricola)

                        const aux = data.Prodotto.split(" ")
                        const dataProdotto = aux[0]
                        const emailMessage = formatMessageEmail(titleClientSend?.desc_Title, dataClient.name, dataProdotto, bussinesData?.desc_Bussines, dataClient.country)
                        if (ConfigCapone.configTest) {
                            for (let i = 0; i < ConfigCapone.emailTest.length; i++) {
                                let dataSend = {
                                    from: "customerservice@sergiocapone.com",
                                    type: "notice",
                                    email: ConfigCapone.emailTest[i],
                                    subject: "Grazie per il suo acquisto",
                                    //message: `Grazie per aver acquistato il prodotto ${matricola}`
                                    message: emailMessage
                                };
                                await axios
                                    .post(
                                        `${ConfigCapone.apicapone}/api/forma`,
                                        dataSend
                                    )
                                    .then((res) => {
                                        console.log("email send");
                                        state = true
                                    })
                                    .catch((err) => {
                                        console.log("there was an error: " + err)
                                        state = false
                                    });
                            }

                        } else {
                            // let dataSend = {
                            //     from: "customerservice@sergiocapone.com",
                            //     type: "notice",
                            //     email: dataClient.email,
                            //     subject: "Grazie per il suo acquisto",
                            //     //message: `Grazie per aver acquistato il prodotto ${matricola}`
                            //     message: emailMessage
                            // };
                            // await axios
                            //     .post(
                            //         `${ConfigCapone.apicapone}/api/forma`,
                            //         dataSend
                            //     )
                            //     .then((res) => {
                            //         console.log("email send");
                            //         state = true
                            //     })
                            //     .catch((err) => {
                            //         console.log("there was an error: " + err)
                            //         state = false
                            //     });
                        }


                    } else {
                        console.log("no se envia el email");

                    }

                } else {

                    setmodaleMessaggeWithUrl({
                        active: true,
                        message: "L'utente non ha un'email registrata.",
                        title: "Errore",
                        route: "",
                    });
                }


            }
        }
    }
    const handleDataEdit = async (activeNavigation: boolean = false) => {
        console.log(optionAddEdit)
        setActiveSpinner(true);
        let verifyN = await verifyPhone()
        console.log("hola 123", verifyN)
        if (verifyN) {
            let state: boolean = false;

            let gender: any = checkBoxGenderChecked;
            let idGender: any;
            checkBoxGenderChecked && (idGender = gender?.split("-"))

            let addressType: any = checkBoxAddressTypeChecked;
            let idAddressType: any;
            checkBoxAddressTypeChecked && (idAddressType = addressType?.split("-"))

            let phoneType: any = getValues("PhoneType");
            let idPhoneType: any = phoneType?.split("-");

            let addressStreet = getValues("AddressStreet") ? getValues("AddressStreet") : null
            let addressNumber = getValues("AddressNumber") ? getValues("AddressNumber") : null
            let addressCity = getValues("AddressCity") ? getValues("AddressCity") : null
            let zipCode = getValues("ZipCode") ? getValues("ZipCode") : null
            let country = getValues("Country") ? getValues("Country") : null

            let address: any;

            if (addressStreet != null && addressNumber != null && addressCity != null && zipCode != null && country != null) {
                address = addressStreet + " " + addressNumber + ", " + addressCity + ", " + zipCode + ", " + country;
            } else {
                if (addressStreet != null && addressNumber != null) {
                    address = addressStreet + " " + addressNumber;
                } else {
                    if (addressStreet != null) {
                        address = addressStreet;
                    } else {
                        address = null;
                    }
                }
            }

            //setCheckBoxIsImportantChecked(data.isImportant == true ? "important-3" : "0");
            let isImportant = checkBoxIsImportantChecked === "important-3" ? true : false
            console.log("checkBoxIsImportantChecked", checkBoxIsImportantChecked === "important-3" ? true : false);
            console.log("isImportant", isImportant);
            const updateClient = {
                idClient: Number(dataClient?.idClient),
                idUser: Number(dataClient?.idUser),
                idTitle: Number(getValues("Title")),
                Name: getValues("Name"),
                LastName: getValues("Name"),
                idGender: checkBoxGenderChecked ? Number(idGender[1]) : null,
                idAddressType: checkBoxAddressTypeChecked ? Number(idAddressType[1]) : null,
                Address: address,
                AddressStreet: getValues("AddressStreet") ? getValues("AddressStreet") : null,
                AddressNumber: getValues("AddressNumber") ? getValues("AddressNumber") : null,
                AddressCity: getValues("AddressCity") ? getValues("AddressCity") : null,
                ZipCode: getValues("ZipCode") ? getValues("ZipCode") : null,
                Province: getValues("Province") ? getValues("Province") : null,
                Country: getValues("Country") ? getValues("Country") : null,
                idPhoneType: Number(idPhoneType[1]),
                Phone: getValues("Phone") ? getValues("Phone") : null,
                Email: getValues("Email"),
                Profession: getValues("Profession") ? getValues("Profession") : null,
                DateBirth: getValues("DateBirth") ? dateTimeNow(getValues("DateBirth")) : null,
                // SendEmail : sendEmail ? true : null,
                Note: getValues("Note") ? getValues("Note") : null,
                MeasureLittleFingerLH: Number(getValues("MeasureLittleFingerLH")) ? Number(getValues("MeasureLittleFingerLH")) : null,
                MeasureRingFingerLH: Number(getValues("MeasureRingFingerLH")) ? Number(getValues("MeasureRingFingerLH")) : null,
                MeasureMiddleFingerLH: Number(getValues("MeasureMiddleFingerLH")) ? Number(getValues("MeasureMiddleFingerLH")) : null,
                MeasureIndexFingerLH: Number(getValues("MeasureIndexFingerLH")) ? Number(getValues("MeasureIndexFingerLH")) : null,
                MeasureThumbLH: Number(getValues("MeasureThumbLH")) ? Number(getValues("MeasureThumbLH")) : null,
                MeasurePulseLH: Number(getValues("MeasurePulseLH")) ? Number(getValues("MeasurePulseLH")) : null,
                MeasureLittleFingerRH: Number(getValues("MeasureLittleFingerRH")) ? Number(getValues("MeasureLittleFingerRH")) : null,
                MeasureRingFingerRH: Number(getValues("MeasureRingFingerRH")) ? Number(getValues("MeasureRingFingerRH")) : null,
                MeasureMiddleFingerRH: Number(getValues("MeasureMiddleFingerRH")) ? Number(getValues("MeasureMiddleFingerRH")) : null,
                MeasureIndexFingerRH: Number(getValues("MeasureIndexFingerRH")) ? Number(getValues("MeasureIndexFingerRH")) : null,
                MeasureThumbRH: Number(getValues("MeasureThumbRH")) ? Number(getValues("MeasureThumbRH")) : null,
                MeasurePulseRH: Number(getValues("MeasurePulseRH")) ? Number(getValues("MeasurePulseRH")) : null,
                StarValuation: currentValue != 0 ? currentValue : null,
                Deprecated: checkBoxDeprecatiChecked ? true : null,
                BlackList: checkBoxBlackListChecked ? true : null,
                IsImportant: isImportant ? true : false,
                EnviaCatalogo: checkBoxInviaCatCasaChecked ? true : null,
                invioAuguri: checkBoxInvioAuguriChecked ? true : null,
                DateUpdateCrm: new Date(),
                OrigenIdBussines: Number(getValues("OriginIdBussines")) ? Number(getValues("OriginIdBussines")) : null,
            };

            console.log("updateClient", updateClient);

            console.log("dataClient,", dataClient);

            console.log("product,", countOwnProducts);

            await axios
                .put(`${ConfigCapone.urlapi}/api/Client/ModifyClientCrm`, updateClient, {
                    headers: {
                        'Authorization': `Bearer ${cookies.get('token')}`
                    }
                })
                .then((response) => {

                    state = true;

                })
                .catch((error) => {
                    setActiveSpinner(false);
                    setmodaleMessaggeWithUrl({
                        active: true,
                        message: `${error}`,
                        title: "Errore",
                        route: "",
                    });
                });

            if (state) {
                if (ConfigCapone.configTest) {
                    console.log("aqui", Number(dataClient.sendEmailBirthday))
                    if (updateClient.invioAuguri && Number(dataClient.sendEmailBirthday) == 0) {
                        const titleClientSend = titleType.find(element => element.idTitle == updateClient.idTitle)
                        const emailMessage = formatMessageEmailBirthDay(titleClientSend?.desc_Title, updateClient.Name, updateClient.Country)
                        for (let i = 0; i < ConfigCapone.emailTest.length; i++) {
                            let dataSend = {
                                from: "customerservice@sergiocapone.com",
                                type: "notice",
                                email: ConfigCapone.emailTest[i],
                                subject: "Tanti auguri dalla Gioielleria Capone",
                                message: emailMessage
                            };
                            await axios
                                .post(
                                    `${ConfigCapone.apicapone}/api/forma`,
                                    dataSend
                                )
                                .then((res) => {
                                    console.log("email send");
                                })
                                .catch((err) => {
                                    console.log("there was an error: " + err)
                                });
                        }
                    }
                }
                await sendEmails(countOwnProducts);
                await updateOwnProduct(Number(dataClient.idClient));
                await updateHobbys(Number(dataClient.idClient));
                await updateObservedProduct(Number(dataClient.idClient));
                await updateRelationShip(Number(dataClient.idClient));
                await updateStatusClient(Number(dataClient.idClient));

                let client: any = [];

                await axios
                    .get(`${ConfigCapone.urlapi}/api/Client/FindByIdClientCrm/${dataClient.idClient}`)
                    .then((response) => {
                        client = response.data.response

                    })
                    .catch((err) => console.log(err));

                await resultSearch(client)

                setmodaleMessaggeWithUrl({
                    active: true,
                    message: "Modifica salvata con successo.",
                    title: "Informazione",
                    route: "",
                });
            }

        } else {
            setmodaleMessaggeWithUrl({
                active: true,
                message: "Numero di telefono già registrato",
                title: "Errore",
                route: "",
            });
        }

        setActiveSpinner(false);
    }

    const addHobbys = async (idClient: number, idhobbyList: any) => {

        if (Object.keys(idhobbyList).length > 0) {

            let status: boolean;

            let dataSend: any = [];

            for (let index = 0; index < idhobbyList.length; index++) {
                dataSend.push({ IdHobby: idhobbyList[index], IdClient: idClient })

            }

            for (let i = 0; i < dataSend.length; i++) {
                await axios
                    .post(`${ConfigCapone.urlapi}/api/ClientHobby`, dataSend[i])
                    .then((response) => {
                        console.log("response", response.data);
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }

        }

    }

    const updateHobbys = async (idClient: number) => {

        let hobbyOriginalList: any = [];
        let ListHobby: any = [];
        let dataPost: any = [];
        let dataUpdate: any = [];
        let dataDelete: any = [];

        if (checkBoxHobbyChecked) {
            for (const key in checkBoxHobbyChecked) {
                if (Object.prototype.hasOwnProperty.call(checkBoxHobbyChecked, key)) {
                    const element = checkBoxHobbyChecked[key];
                    if (element !== null) {
                        hobbyOriginalList.push(element)
                    }
                }
            }
        }

        for (let index = 0; index < hobbyOriginalList.length; index++) {

            if (hobbyOriginalList[index].idClient_Hobby === "") {
                dataPost.push(hobbyOriginalList[index])
            }

            if (hobbyOriginalList[index].idClient_Hobby !== "") {
                dataUpdate.push(hobbyOriginalList[index])
            }

        }

        await axios
            .get(`${ConfigCapone.urlapi}/api/ClientHobby/SearchHobbyByClient/${idClient}`)
            .then((response) => {
                ListHobby = response.data.response
            })
            .catch((err) => console.log(err));

        dataDelete = ListHobby.filter((item: any) => !dataUpdate.some((dataUpdate: any) => dataUpdate.idClient_Hobby === item.idClient_Hobby))

        if (Object.keys(dataPost).length > 0) {

            let status: boolean;

            let dataSend: any = [];

            for (let index = 0; index < dataPost.length; index++) {
                let id = dataPost[index].idHobby?.split("-")
                dataSend.push({ idHobby: Number(id[1]), IdClient: idClient })

            }

            console.log("dataSend:", dataSend);


            for (let i = 0; i < dataSend.length; i++) {
                await axios
                    .post(`${ConfigCapone.urlapi}/api/ClientHobby`, dataSend[i])
                    .then((response) => {
                        console.log("response", response.data);
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }

        }


        if (Object.keys(dataDelete).length > 0) {

            let status: boolean;

            for (let i = 0; i < dataDelete.length; i++) {
                await axios
                    .delete(`${ConfigCapone.urlapi}/api/ClientHobby/${Number(dataDelete[i].idClient_Hobby)}`)
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }
        }

    }

    const insertRelationShip = async (idClient: number) => {

        let dataSend: any = [];
        let dataSendRelation: any = [];
        let dataSendRelationClient: any = [];
        let status: boolean;

        for (let index = 0; index < countRelationShip.length; index++) {
            if ((countRelationShip[index].idRelationship != null && countRelationShip[index].idRelationship != "default") && countRelationShip[index].Name != "") {
                dataSend.push({
                    idClient: idClient,
                    Name: countRelationShip[index].Name,
                    Id: countRelationShip[index].Id,
                    LastName: countRelationShip[index].Name,
                    idRelationship: countRelationShip[index].idRelationship
                })
            }

        }

        if (Object.keys(dataSend).length > 0) {

            for (let i = 0; i < dataSend.length; i++) {

                if (dataSend[i].Id != undefined) {
                    dataSendRelation.push({
                        idClient: idClient, Name: dataSend[i].Name, LastName: dataSend[i].Name,
                        idRelationship: dataSend[i].idRelationship, idClientRelation: dataSend[i].Id
                    })

                    dataSendRelationClient.push({
                        idClient: idClient, Name: dataSend[i].Name, LastName: dataSend[i].Name,
                        idRelationship: dataSend[i].idRelationship, idClientRelation: dataSend[i].Id
                    })
                } else {
                    dataSendRelation.push({
                        idClient: idClient, Name: dataSend[i].Name, LastName: dataSend[i].Name,
                        idRelationship: dataSend[i].idRelationship
                    })
                }

            };

            for (let i = 0; i < dataSendRelation.length; i++) {
                await axios
                    .post(`${ConfigCapone.urlapi}/api/ClientRelationship`, dataSendRelation[i])
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            let ListrelactionClient: any = []

            if (Object.keys(dataSendRelationClient).length > 0) {

                for (let i = 0; i < dataSendRelationClient.length; i++) {

                    let option: any = await optionRelationShip(dataSendRelationClient[i].idRelationship)
                    ListrelactionClient.push({
                        idClient: dataSendRelationClient[i].idClientRelation, Name: getValues("Name"), LastName: getValues("Name"),
                        idRelationship: option, idClientRelation: idClient
                    })

                };
            }

            if (Object.keys(ListrelactionClient).length > 0) {
                for (let i = 0; i < ListrelactionClient.length; i++) {
                    await axios
                        .post(`${ConfigCapone.urlapi}/api/ClientRelationship`, ListrelactionClient[i])
                        .then((response) => {
                            status = true;
                        })
                        .catch((error) => {
                            status = false;
                        });
                };
            }


            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }
        }

    }

    const optionRelationShip = async (id: any) => {

        let option: number = 0
        switch (Number(id)) {
            // Moglie
            case 1:
                option = 2
                break;

            // Marito   
            case 2:
                option = 1
                break;

            // Madre - Padre
            case 3:
                option = 13
                break;

            // Zio-a
            case 4:
                option = 6
                break;

            // Fratello - Sorella
            case 5:
                option = 5
                break;

            // Nipote
            case 6:
                option = 4
                break;

            // Cugini
            case 7:
                option = 7
                break;

            // Suocero-a
            case 8:
                option = 10
                break;

            // Cognato-a
            case 9:
                option = 9
                break;

            // Genero - Nuora
            case 10:
                option = 8
                break;

            // Collega
            case 11:
                option = 11
                break;

            // Amico-a
            case 12:
                option = 12
                break;

            // Figlio-a
            case 13:
                option = 3
                break;

            // Compagno-a
            case 14:
                option = 14
                break;

        }

        return option
    }

    const updateRelationShip = async (idClient: number) => {

        let dataPost: any = [];
        let dataUpdate: any = [];
        let dataDelete: any = [];
        let dataDelete2: any = [];
        let status: boolean;
        let dataResultFilterNegozio: any = []

        let ListOriginalRelationship: any = [];

        await axios
            .get(`${ConfigCapone.urlapi}/api/ClientRelationship?value=${idClient}&parameter=idClient`)
            .then((response) => {
                ListOriginalRelationship = response.data.response
            })


        // dataDelete =  ListOriginalRelationship.filter((item:any) => !dataUpdate.some((dataUpdate:any) => dataUpdate.idClientRelationship === item.idClientRelationship))

        for (let index = 0; index < countRelationShip.length; index++) {

            if (!countRelationShip[index].idClientRelationship && (countRelationShip[index].idRelationship != null && countRelationShip[index].idRelationship != "default") && countRelationShip[index].Name != "") {
                dataPost.push({
                    idClient: idClient,
                    Name: countRelationShip[index].Name,
                    Id: countRelationShip[index].Id,
                    LastName: countRelationShip[index].Name,
                    idRelationship: countRelationShip[index].idRelationship
                })
            }

            if (countRelationShip[index].idClientRelationship && (countRelationShip[index].idRelationship != null && countRelationShip[index].idRelationship != "default") && countRelationShip[index].Name != "") {
                dataUpdate.push({
                    idClientRelationship: countRelationShip[index].idClientRelationship,
                    idClient: idClient,
                    Name: countRelationShip[index].Name,
                    Id: countRelationShip[index].Id,
                    LastName: countRelationShip[index].Name,
                    idRelationship: countRelationShip[index].idRelationship,
                    idClientRelation: countRelationShip[index].idClientRelation
                })

                dataResultFilterNegozio.push(countRelationShip[index].idClientRelationship)

            }

        }


        dataDelete = ListOriginalRelationship?.filter((item) => {
            return !dataResultFilterNegozio.includes(item.idClientRelationship)
        });

        if (Object.keys(dataPost).length > 0) {

            let dataSendRelation: any = [];
            let dataSendNoRelation: any = [];

            console.log('--- data Post ---');

            for (let i = 0; i < dataPost.length; i++) {

                if (dataPost[i].Id != undefined) {
                    dataSendRelation.push({
                        idClient: idClient, Name: dataPost[i].Name, LastName: dataPost[i].Name,
                        idRelationship: dataPost[i].idRelationship, idClientRelation: dataPost[i].Id
                    })
                } else {
                    dataSendNoRelation.push({
                        idClient: idClient, Name: dataPost[i].Name, LastName: dataPost[i].Name,
                        idRelationship: dataPost[i].idRelationship
                    })
                }
            };

            for (let i = 0; i < dataSendNoRelation.length; i++) {
                await axios
                    .post(`${ConfigCapone.urlapi}/api/ClientRelationship`, dataSendNoRelation[i])
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            let ListRelactionClient: any = [];

            if (Object.keys(dataSendRelation).length > 0) {

                for (let i = 0; i < dataSendRelation.length; i++) {

                    let option: any = await optionRelationShip(dataSendRelation[i].idRelationship)
                    ListRelactionClient.push({
                        idClient: dataSendRelation[i].idClientRelation, Name: getValues("Name"), LastName: getValues("Name"),
                        idRelationship: option, idClientRelation: idClient
                    })

                };
            }

            if (Object.keys(ListRelactionClient).length > 0) {
                for (let i = 0; i < ListRelactionClient.length; i++) {
                    await axios
                        .post(`${ConfigCapone.urlapi}/api/ClientRelationship`, ListRelactionClient[i])
                        .then((response) => {
                            status = true;
                        })
                        .catch((error) => {
                            status = false;
                        });
                };
            }

            for (let i = 0; i < dataSendRelation.length; i++) {
                await axios
                    .post(`${ConfigCapone.urlapi}/api/ClientRelationship`, dataSendRelation[i])
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            console.log("dataSendRelation: ", dataSendRelation);
            console.log("dataSendNoRelation: ", dataSendNoRelation);
            console.log("ListRelactionClient: ", ListRelactionClient);


            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }

        }

        if (Object.keys(dataUpdate).length > 0) {

            let dataSendRelation: any = [];
            let dataSendNoRelation: any = [];
            let dataUpdateRelation: any = [];
            let dataUpdateNoRelationClient: any = [];

            console.log("update 123: ", dataUpdate);
            console.log('--- data Update ---');

            for (let i = 0; i < dataUpdate.length; i++) {

                if (dataUpdate[i].Id != undefined) {

                    if (dataUpdate[i].Id != dataUpdate[i].idClientRelation) {
                        // relación nueva
                        dataSendRelation.push({
                            idClientRelationship: dataUpdate[i].idClientRelationship, idClient: idClient,
                            Name: dataUpdate[i].Name, LastName: dataUpdate[i].Name, idRelationship: dataUpdate[i].idRelationship,
                            idClientRelation: dataUpdate[i].Id
                        })

                    } else {
                        // relacion existente
                        dataUpdateRelation.push({
                            idClientRelationship: dataUpdate[i].idClientRelationship, idClient: dataUpdate[i].Id,
                            Name: dataUpdate[i].Name, LastName: dataUpdate[i].Name, idRelationship: dataUpdate[i].idRelationship,
                            idClientRelation: idClient
                        })
                        // datos normales
                        dataUpdateNoRelationClient.push({
                            idClientRelationship: dataUpdate[i].idClientRelationship, idClient: idClient,
                            Name: dataUpdate[i].Name, LastName: dataUpdate[i].Name, idRelationship: dataUpdate[i].idRelationship,
                            idClientRelation: dataUpdate[i].Id
                        })

                        console.log("entro2", dataUpdateRelation);

                    }

                } else {
                    // sin relación
                    dataSendNoRelation.push({
                        idClientRelationship: dataUpdate[i].idClientRelationship, idClient: idClient, Name: dataUpdate[i].Name,
                        LastName: dataUpdate[i].Name, idRelationship: dataUpdate[i].idRelationship
                    })
                }


                // sin relación 

                for (let i = 0; i < dataSendNoRelation.length; i++) {

                    console.log("no entro 1");

                    await axios
                        .put(`${ConfigCapone.urlapi}/api/ClientRelationship/${dataSendNoRelation[i].idClientRelationship}`, dataSendNoRelation[i])
                        .then((response) => {
                            status = true;
                        })
                        .catch((error) => {
                            status = false;
                        });
                };

                // relacion existente

                console.log("dataUpdateRelation: ", dataUpdateRelation);
                console.log("dataSendRelation: ", dataSendRelation);
                console.log("dataSendNoRelation: ", dataSendNoRelation);

                let ListRelactionClient: any = []

                if (Object.keys(dataUpdateRelation).length > 0) {
                    for (let i = 0; i < dataUpdateRelation.length; i++) {

                        await axios
                            .get(`${ConfigCapone.urlapi}/api/ClientRelationship/FindByRelationClient/${dataUpdateRelation[i].idClient}/${dataUpdateRelation[i].idClientRelation}`)
                            .then((response) => {
                                if (response.data.status == "success") {
                                    ListRelactionClient.push({ ...response.data.response, idRelationship: dataUpdateRelation[i].idRelationship })
                                }
                            })

                    };
                }

                if (Object.keys(dataUpdateNoRelationClient).length > 0) {
                    for (let i = 0; i < dataUpdateNoRelationClient.length; i++) {
                        await axios
                            .put(`${ConfigCapone.urlapi}/api/ClientRelationship/${dataUpdateNoRelationClient[i].idClient}`, dataUpdateNoRelationClient[i])
                            .then((response) => {
                                status = true;
                            })
                            .catch((error) => {
                                console.log("error1, ", error);
                                status = false;
                            });
                    }
                }

                let ListUpdateClient: any = []
                if (Object.keys(ListRelactionClient).length > 0) {

                    for (let i = 0; i < ListRelactionClient.length; i++) {
                        let option: any = await optionRelationShip(ListRelactionClient[i].idRelationship)
                        ListUpdateClient.push({
                            idClientRelationship: ListRelactionClient[i].idClientRelationship, idClient: ListRelactionClient[i].idClient, Name: ListRelactionClient[i].name, LastName: ListRelactionClient[i].lastName,
                            idRelationship: option, idClientRelation: ListRelactionClient[i].idClientRelation
                        })
                    }

                }

                console.log("info verify : ", ListRelactionClient);


                if (Object.keys(ListUpdateClient).length > 0) {
                    console.log("no entro 2");
                    for (let i = 0; i < ListUpdateClient.length; i++) {
                        await axios
                            .put(`${ConfigCapone.urlapi}/api/ClientRelationship/${ListUpdateClient[i].idClientRelationship}`, ListUpdateClient[i])
                            .then((response) => {
                                status = true;
                            })
                            .catch((error) => {
                                status = false;
                                console.log("error2, ", error);
                            });
                    }

                }

                // relación nueva
                let ListNewRelationClient: any = []

                if (Object.keys(dataSendRelation).length > 0) {
                    console.log("no entro 3");
                    for (let i = 0; i < dataSendRelation.length; i++) {

                        let option: any = await optionRelationShip(dataSendRelation[i].idRelationship)
                        ListNewRelationClient.push({
                            idClient: dataSendRelation[i].idClientRelation, Name: getValues("Name"), LastName: getValues("Name"),
                            idRelationship: option, idClientRelation: idClient
                        })

                    };
                }

                if (Object.keys(ListNewRelationClient).length > 0) {
                    console.log("no entro 4");
                    for (let i = 0; i < ListNewRelationClient.length; i++) {
                        await axios
                            .post(`${ConfigCapone.urlapi}/api/ClientRelationship`, ListNewRelationClient[i])
                            .then((response) => {
                                status = true;
                            })
                            .catch((error) => {
                                status = false;
                                console.log("error3, ", error);
                            });
                    };
                }

                // console.log("send 1: ", dataSendRelation);


                // if(Object.keys(dataSendRelation).length > 0){
                //     console.log("no entro 4");
                //     for(let i=0; i<dataSendRelation.length; i++) {   
                //         await axios
                //         .post(`${ConfigCapone.urlapi}/api/ClientRelationship`, dataSendRelation[i])
                //             .then((response) => {                              
                //                 status = true;    
                //             })
                //             .catch((error) => {
                //                 status = false;        
                //                 console.log("error3, ", error);  
                //             });
                //     };
                // }

                //
                if (status === false) {
                    setmodaleMessaggeWithUrl({
                        active: true,
                        message: `${"Error"}`,
                        title: "Errore",
                        route: "",
                    });
                }
            }

        }

        console.log('--- data delete ---');
        console.log(dataDelete);

        if (Object.keys(dataDelete).length > 0) {

            for (let i = 0; i < dataDelete.length; i++) {
                await axios
                    .delete(`${ConfigCapone.urlapi}/api/ClientRelationship/${dataDelete[i].idClientRelationship}`)
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });

                let relationDelete: any = [];

                await axios
                    .get(`${ConfigCapone.urlapi}/api/ClientRelationship?value=${dataDelete[i].idClientRelation}&parameter=idClient`)
                    .then((response) => {
                        status = true;
                        if (response.data.response?.length > 0) {
                            relationDelete = response.data.response
                        }
                    })
                    .catch((error) => {
                        status = false;
                    });

                let relation = relationDelete.find((data: any) => data.name == getValues("Name"));
                console.log("rela: ", relation);
                console.log("rela1: ", Object.keys(relation).length);


                if (Object.keys(relation).length > 0) {
                    await axios
                        .delete(`${ConfigCapone.urlapi}/api/ClientRelationship/${relation.idClientRelationship}`)
                        .then((response) => {
                            status = true;
                        })
                        .catch((error) => {
                            status = false;
                        });
                }

            };

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }
        }
    }


    const insertObservedProduct = async (idClient: number) => {

        let dataSend: any = [];
        let status: boolean;

        for (let index = 0; index < countObservedProduct.length; index++) {

            if (countObservedProduct[index].description != "") {

                if (!countObservedProduct[index].idMatricola) {
                    dataSend.push({ idClient: idClient, description: countObservedProduct[index].description })
                }
                if (countObservedProduct[index].idMatricola) {
                    dataSend.push({ idClient: idClient, idMatricola: countObservedProduct[index].idMatricola, description: countObservedProduct[index].description, })
                }

            }

        }

        if (Object.keys(dataSend).length > 0) {

            for (let i = 0; i < dataSend.length; i++) {
                await axios
                    .post(`${ConfigCapone.urlapi}/api/ClientProdasservati`, dataSend[i])
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }
        }

    }

    const updateObservedProduct = async (idClient: number) => {

        let dataPost: any = [];
        let dataUpdate: any = [];
        let dataDelete: any = [];
        let status: boolean;

        for (let index = 0; index < countObservedProduct.length; index++) {
            if (countObservedProduct[index].description != "" && !countObservedProduct[index].idClientProdAsservati) {

                if (!countObservedProduct[index].idMatricola) {
                    dataPost.push({ idClient: idClient, description: countObservedProduct[index].description, })
                }
                if (countObservedProduct[index].idMatricola) {
                    dataPost.push({ idClient: idClient, idMatricola: countObservedProduct[index].idMatricola, description: countObservedProduct[index].description })
                }

            }
            if (countObservedProduct[index].description != "" && countObservedProduct[index].idClientProdAsservati) {
                if (!countObservedProduct[index].idMatricola) {
                    dataUpdate.push({ idClientProdAsservati: countObservedProduct[index].idClientProdAsservati, idClient: idClient, description: countObservedProduct[index].description })
                }
                if (countObservedProduct[index].idMatricola) {
                    dataUpdate.push({ idClientProdAsservati: countObservedProduct[index].idClientProdAsservati, idMatricola: countObservedProduct[index].idMatricola, idClient: idClient, description: countObservedProduct[index].description })
                }
            }
        }

        let ListOriginalObservedProduct: any = [];

        await axios
            .get(`${ConfigCapone.urlapi}/api/ClientProdasservati/idClient/${idClient}`)
            .then((response) => {
                ListOriginalObservedProduct = response.data.response
            })

        dataDelete = ListOriginalObservedProduct.filter((item: any) => !dataUpdate.some((dataUpdate: any) => dataUpdate.idClientProdAsservati === item.idClientProdAsservati))

        if (Object.keys(dataPost).length > 0) {

            for (let i = 0; i < dataPost.length; i++) {
                await axios
                    .post(`${ConfigCapone.urlapi}/api/ClientProdasservati`, dataPost[i])
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }
        }

        if (Object.keys(dataUpdate).length > 0) {

            for (let i = 0; i < dataUpdate.length; i++) {
                await axios
                    .put(`${ConfigCapone.urlapi}/api/ClientProdasservati/${dataUpdate[i].idClientProdAsservati}`, dataUpdate[i])
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }
        }

        if (Object.keys(dataDelete).length > 0) {

            for (let i = 0; i < dataDelete.length; i++) {
                await axios
                    .delete(`${ConfigCapone.urlapi}/api/ClientProdasservati/${dataDelete[i].idClientProdAsservati}`)
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }
        }

    }

    const insertStatusClient = async (idClient: number) => {

        let status: boolean;

        const dataSend = {
            idClient: idClient,
            idUser: Number(userInLogin?.idUser)
        }

        await axios
            .post(`${ConfigCapone.urlapi}/api/StatusClient`, dataSend)
            .then((response) => {
                status = true;
            })
            .catch((error) => {
                status = false;
            });

        if (status === false) {
            setmodaleMessaggeWithUrl({
                active: true,
                message: `${"Error"}`,
                title: "Errore",
                route: "",
            });
        }
    }
    const updateStatusClient = async (idClient: number) => {

        let status: boolean;

        const dataSend = {
            idClient: idClient,
            idUser: Number(userInLogin?.idUser)
        }

        await axios
            .post(`${ConfigCapone.urlapi}/api/StatusClient`, dataSend)
            .then((response) => {
                status = true;
            })
            .catch((error) => {
                status = false;
            });

        if (status === false) {
            setmodaleMessaggeWithUrl({
                active: true,
                message: `${"Error"}`,
                title: "Errore",
                route: "",
            });
        }
    }

    const updateMatricola = async (idMatricola: number, dataUpdate: any, idClient: number) => {

        let status = false;

        const updateMatricola = {
            idMatricola: idMatricola,
            idReferenzia: Number(dataUpdate.idReferenzia),
            idNegozio: Number(dataUpdate.idNegozio),
            Matricola: dataUpdate.Matricola,
            PutAside: false,
            PutMoveBusiness: false,
            PutSale: true,
            PutSaleNewCustomer: false,
            PutSaleExistingCustomer: false,
            PutOnline: false,
            idClient: idClient,
        };

        await axios
            .put(`${ConfigCapone.urlapi}/api/Matricola/${idMatricola}`, updateMatricola)
            .then((response) => {
                status = true;
                console.log("d1");
            })
            .catch((error) => {
                status = false;
                console.log("errro1");

            });

        await axios
            .delete(`${ConfigCapone.urlapi}/api/Matricola/${idMatricola}`)
            .then((response) => {
                console.log("d2");
                status = true;
            })
            .catch((error) => {
                status = false;
                console.log("error2");

            });

    }

    const updateMagazzino = async (idMagazzino: number) => {

        console.log("magazzino 77", idMagazzino);

        let status = false;
        let magazzino: any = [];

        let updateMagazzino: any;

        await axios
            .get(`${ConfigCapone.urlapi}/api/Magazzino/${idMagazzino}`)
            .then((response) => {
                status = true;
                magazzino = response.data.response;
                console.log("español: ", response.data.response);
                updateMagazzino = {
                    idMagazzino: Number(response.data.response?.idMagazzino),
                    quantity: Number(response.data.response?.quantity) - 1,
                    negozio: Number(response.data.response?.idBussines),
                }

            })
            .catch((error) => {
                status = false;
            });


        console.log("magazzinooooo1,", magazzino);
        console.log("magazzinooooo2,", updateMagazzino);

        await axios
            .put(`${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${updateMagazzino.idMagazzino}/${updateMagazzino.quantity}/${updateMagazzino.negozio}`)
            .then((response) => {
                status = true;
            })
            .catch((error) => {
                status = false;
            });

        if (Number(updateMagazzino.quantity) === 0) {
            await axios.delete(`${ConfigCapone.urlapi}/api/Magazzino/${updateMagazzino.idMagazzino}}`)
                .then((response) => {
                    status = true;
                    console.log("hola1");

                })
                .catch((error) => {
                    status = false;
                    console.log("error4");

                });
        }

        if (status === false) {
            setmodaleMessaggeWithUrl({
                active: true,
                message: `${"Error"}`,
                title: "Errore",
                route: "",
            });
        }

    }

    const insertOwnProduct = async (idClient: number, product?: any, isChecked?: boolean) => {

        let dataSend: any = [];
        let dataSendEmail: any = [];
        let dataOwnProduct: any = [];
        let valueOwnProduct: any = [];
        let listProduct: any;
        let status: boolean;



        isChecked ? listProduct = countOwnProducts : listProduct = product ? product : countOwnProducts
        console.log("listproduct", listProduct, optionAddEdit);

        for (let index = 0; index < listProduct.length; index++) {

            if (listProduct[index].idBrand != "" && listProduct[index].Prodotto != "" && listProduct[index].Referenza != "" && listProduct[index].Matricola != "" && listProduct[index].Data != "" && listProduct[index].Prezzo != "") {

                if (optionAddEdit) {

                    if (!listProduct[index].idMatricola) {

                        if (listProduct[index].idClientProdPosseduti) {

                            dataSend.push({
                                idClientProdPosseduti: listProduct[index].idClientProdPosseduti, idClient: idClient, idBrand: listProduct[index].idBrand, Prodotto: listProduct[index].Prodotto,
                                Referenza: listProduct[index].Referenza, Matricola: listProduct[index].Matricola,
                                Data: dateTimeNow(listProduct[index].Data), Prezzo: Number(listProduct[index].Prezzo), PrezzoApplicato: Number(listProduct[index].PrezzoApplicato), SendEmail: listProduct[index].SendEmail
                            })

                        } else {

                            dataSend.push({
                                idClient: idClient, idBrand: listProduct[index].idBrand, Prodotto: listProduct[index].Prodotto,
                                Referenza: listProduct[index].Referenza, Matricola: listProduct[index].Matricola,
                                Data: dateTimeNow(listProduct[index].Data), Prezzo: Number(listProduct[index].Prezzo), PrezzoApplicato: Number(listProduct[index].PrezzoApplicato), SendEmail: listProduct[index].SendEmail
                            })
                        }
                    }

                    if (listProduct[index].idMatricola) {
                        dataSend.push({
                            idMatricola: listProduct[index].idMatricola, idClient: idClient, idBrand: listProduct[index].idBrand, Prodotto: listProduct[index].Prodotto,
                            Referenza: listProduct[index].Referenza, Matricola: listProduct[index].Matricola,
                            Data: dateTimeNow(listProduct[index].Data), Prezzo: Number(listProduct[index].Prezzo), PrezzoApplicato: Number(listProduct[index].PrezzoApplicato), SendEmail: listProduct[index].SendEmail
                        })
                    }

                } else {

                    if (!listProduct[index].idMatricola) {
                        dataSend.push({
                            idClient: idClient, idBrand: listProduct[index].idBrand, Prodotto: listProduct[index].Prodotto,
                            Referenza: listProduct[index].Referenza, Matricola: listProduct[index].Matricola,
                            Data: dateTimeNow(listProduct[index].Data), Prezzo: Number(listProduct[index].Prezzo), PrezzoApplicato: Number(listProduct[index].PrezzoApplicato), SendEmail: listProduct[index].SendEmail
                        })
                    }

                    if (listProduct[index].idMatricola) {
                        dataSend.push({
                            idMatricola: listProduct[index].idMatricola, idClient: idClient, idBrand: listProduct[index].idBrand, Prodotto: listProduct[index].Prodotto,
                            Referenza: listProduct[index].Referenza, Matricola: listProduct[index].Matricola,
                            Data: dateTimeNow(listProduct[index].Data), Prezzo: Number(listProduct[index].Prezzo), PrezzoApplicato: Number(listProduct[index].PrezzoApplicato), SendEmail: listProduct[index].SendEmail
                        })
                    }
                }


            }

            // update magazzino
            if (listProduct[index].idMagazzino) {
                valueOwnProduct.push({
                    idMagazzino: listProduct[index].idMagazzino, idReferenzia: listProduct[index].idReference, idNegozio: listProduct[index].idBussines,
                    idMatricola: listProduct[index].idMatricola,
                    Matricola: listProduct[index].Matricola,
                    Quantity: listProduct[index].Quantity,
                    PutAside: false,
                    PutMoveBusiness: false,
                    PutSale: optionAddEdit ? false : true,
                    PutSaleNewCustomer: optionAddEdit ? false : true,
                    PutSaleExistingCustomer: optionAddEdit ? true : false,
                    PutOnline: false,
                    idClient: idClient
                })
            }

        }

        //

        if (Object.keys(dataSend).length > 0) {


            if (optionAddEdit) {
                //
                let ListOriginalOwnProduct: any = [];
                let ListConvertProduct: any = [];

                console.log("entra aqui,", dataSend);

                await axios
                    .get(`${ConfigCapone.urlapi}/api/ClientProdposseduti/idClient/${idClient}`)
                    .then((response) => {
                        ListOriginalOwnProduct = response.data.response
                    })

                if (Object.keys(ListOriginalOwnProduct).length > 0) {
                    for (let index = 0; index < ListOriginalOwnProduct.length; index++) {
                        console.log("AQUIIIII")
                        ListConvertProduct.push(
                            {
                                idClientProdPosseduti: ListOriginalOwnProduct[index]?.idClientProdPosseduti,
                                idClient: ListOriginalOwnProduct[index]?.idClient,
                                Prodotto: ListOriginalOwnProduct[index]?.prodotto,
                                idBrand: ListOriginalOwnProduct[index]?.idBrand,
                                Referenza: ListOriginalOwnProduct[index]?.referenza,
                                Matricola: ListOriginalOwnProduct[index]?.matricola,
                                idMatricola: ListOriginalOwnProduct[index]?.idMatricola,
                                Data: new Date(ListOriginalOwnProduct[index]?.data),
                                Prezzo: ListOriginalOwnProduct[index]?.prezzo,
                                PrezzoApplicato: ListOriginalOwnProduct[index]?.priceApplied
                            }
                        )

                    }
                    console.log("AQUIIIII", ListOriginalOwnProduct, ListConvertProduct)
                    let data: any = []
                    let dataFilter: any = []
                    data = dataSend.filter((item: any) => !ListConvertProduct.some((ListConvertProduct: any) => ListConvertProduct.idMatricola === item.idMatricola))

                    for (let index = 0; index < data.length; index++) {

                        // if(!data[index].idClientProdPosseduti){
                        //     dataFilter.push(data[index])
                        // }

                        if (data[index].idMatricola) {
                            dataFilter.push(data[index])
                        }

                    }

                    let resultData = dataFilter.reduce((elements, obj, index) => {
                        let existingData = elements.find(element =>
                            element.idMatricola === obj.idMatricola
                        );
                        if (!existingData) {
                            elements.push(obj);
                        }
                        return elements;
                    }, []);

                    console.log("ooop: ", resultData);


                    for (let i = 0; i < resultData.length; i++) {
                        await axios
                            .post(`${ConfigCapone.urlapi}/api/ClientProdposseduti`, resultData[i])
                            .then((response) => {
                                status = true;
                            })
                            .catch((error) => {
                                status = false;
                            });
                    };

                } else {

                    for (let i = 0; i < dataSend.length; i++) {
                        await axios
                            .post(`${ConfigCapone.urlapi}/api/ClientProdposseduti`, dataSend[i])
                            .then((response) => {
                                status = true;
                            })
                            .catch((error) => {
                                status = false;
                            });
                    };

                }
                //    
            } else {

                console.log("xx11 : ", dataSend);

                for (let i = 0; i < dataSend.length; i++) {

                    await axios
                        .post(`${ConfigCapone.urlapi}/api/ClientProdposseduti`, dataSend[i])
                        .then((response) => {
                            status = true;
                        })
                        .catch((error) => {
                            status = false;
                        });


                    if (dataSend[i]?.idMatricola) {

                        let online: any = false;
                        let brand: any;

                        await axios
                            .get(`${ConfigCapone.urlapi}/api/Matricola/${dataSend[i]?.idMatricola}`)
                            .then((response) => {
                                online = response.data.response?.putOnline;
                                brand = response.data.response?.marca;
                                console.log("online: ", response.data.response);
                            })
                            .catch((error) => {
                                console.log("error -> ", error);

                            });

                        console.log("online", online);

                        let date: any = dateTimeNowMessage();

                        if (online) {
                            console.log("dataMa", dataMagazzino)
                            let emailData = {
                                type: "notice",
                                email: `${ConfigCapone.emailMarketing}`,
                                subject: "Nuova Vendita",
                                message: `<p>Operazione: Nuova Vendita</p>
                                        <p>Operatore: ${userInLogin.userName}</p>
                                        <p> Referenza: ${dataMagazzino?.referencia} </p>
                                        <p> Brand: ${brand}</p>
                                        <p> Descrizione: ${dataMagazzino?.desc_famiglia ? dataMagazzino?.desc_famiglia : 'Nessun dato'} </p>
                                        <p> Negozio: ${dataMagazzino?.desc_bussines} </p>
                                        <p> Matricola: ${dataMagazzino?.matricola?.split('-')[1]}</p>
                                        <p>  Prezzo: ${dataMagazzino?.prezzo}</p>
                                        <p>  Data: ${date}</p>`,

                            };

                            await axios
                                .post(
                                    `${ConfigCapone.apicapone}/api/forma`,
                                    emailData
                                )
                                .then((res) => {
                                    console.log(res);
                                    console.log("info : ", emailData);

                                })
                                .catch((err) => console.log("there was an error: " + err));
                        }
                    }

                    if (isChecked && dataSend[i].SendEmail) {
                        console.log(dataSend[i])
                        let state: boolean = false;
                        let dataSendEmail = {
                            type: "notice",
                            email: getValues("Email"),
                            subject: "Nuova Vendita",
                            message: `Grazie per aver acquistato il prodotto ${dataSend[i].Matricola}"`
                            //formatMessageEmail
                            // message:formatMessageEmail("",getValues("Name"),dataMagazzino?.desc_bussines,dataSend[i].Matricola)
                        };
                        await axios
                            .post(
                                `${ConfigCapone.apicapone}/api/forma`,
                                dataSendEmail
                            )
                            .then((res) => {
                                console.log("email send 2");
                                state = true
                            })
                            .catch((err) => {
                                console.log("there was an error: " + err)
                                state = false
                            });



                    }
                };

            }

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }
        }

        if (Object.keys(valueOwnProduct).length > 0) {

            for (let index = 0; index < valueOwnProduct.length; index++) {

                let matricolaId = valueOwnProduct[index].idMatricola;
                let dataUpdateMatricola = valueOwnProduct[index]

                await updateMatricola(matricolaId, dataUpdateMatricola, idClient)

                let updateMagazzino: any = [];

                await axios
                    .get(`${ConfigCapone.urlapi}/api/Magazzino/${Number(valueOwnProduct[index]?.idMagazzino)}`)
                    .then((response) => {
                        status = true;
                        console.log("español: ", response.data.response);
                        updateMagazzino = {
                            idMagazzino: Number(response.data.response?.idMagazzino),
                            quantity: Number(response.data.response?.quantity) - 1,
                            negozio: Number(response.data.response?.idBussines),
                        }

                    })
                    .catch((error) => {
                        status = false;
                    });

                await axios
                    .put(`${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${updateMagazzino.idMagazzino}/${updateMagazzino.quantity}/${updateMagazzino.negozio}`)
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });


                // if( Number(updateMagazzino.quantity) === 0 ){
                //     await axios.delete(`${ConfigCapone.urlapi}/api/Magazzino/${updateMagazzino.idMagazzino}}`)
                //     .then((response) => {
                //         status = true;   
                //     })
                //     .catch((error) => {
                //         status = false;    

                //     });
                // }

                if (status === false) {
                    setmodaleMessaggeWithUrl({
                        active: true,
                        message: `${"Error"}`,
                        title: "Errore",
                        route: "",
                    });
                }

            }

            // sale

            let postSale: any = [];

            await axios
                .get(`${ConfigCapone.urlapi}/api/Sales?value=${idClient}&parameter=idClient`)
                .then((response) => {
                    status = true;
                    postSale = response.data.response;
                })
                .catch((error) => {
                    status = false;
                });

            let dataSale = valueOwnProduct.filter((item: any) => !postSale.some((postSale: any) => postSale.idMatricola === item.idMatricola))

            console.log("data delete : ", dataSale);

            if (Object.keys(dataSale).length > 0) {
                for (let index = 0; index < dataSale.length; index++) {

                    let data = {
                        idBussinessSeller: dataSale[index].idNegozio,
                        idClient: idClient,
                        sellDate: new Date(),
                        idMatricola: dataSale[index].idMatricola,
                        idUser: userInLogin.idUser,
                    }

                    await axios.post(`${ConfigCapone.urlapi}/api/Sales`, data, {
                        headers: {
                            'Authorization': `Bearer ${cookies.get('token')}`
                        }
                    })
                        .then(async (res) => console.log("request update", res))

                    if (dataSale[index]?.idMatricola) {

                        let online: any = false;
                        let data: any;
                        let brand: any;
                        await axios
                            .get(`${ConfigCapone.urlapi}/api/Matricola/${dataSale[index]?.idMatricola}`)
                            .then((response) => {
                                online = response.data.response?.putOnline;
                                brand = response.data.response?.matricola;
                                console.log("online: ", response.data.response);
                                data = response.data.response
                            })
                            .catch((error) => {
                                console.log("error -> ", error);

                            });

                        console.log("data", data);

                        console.log("online", online);

                        console.log("dataMagazzino", dataMagazzino);


                        // if(online){

                        let date: any = dateTimeNowMessage();

                        let emailData = {
                            type: "notice",
                            email: `${ConfigCapone.emailMarketing}`,
                            subject: "Nuova Vendita",
                            message: `<p>Operazione: Nuova Vendita</p>
                                            <p>Operatore: ${userInLogin.userName}</p>
                                            <p> Referenza: ${dataMagazzino?.referencia} </p>
                                            <p> Brand: ${dataMagazzino?.desc_brand}</p>
                                            <p> Negozio: ${dataMagazzino?.desc_bussines} </p>
                                            <p> Matricola: ${dataMagazzino?.matricola?.split('-')[1]}</p> 
                                            <p>  Prezzo: ${dataMagazzino?.prezzo}</p>
                                            <p>  Data: ${date}</p>`,
                        };

                        await axios
                            .post(
                                `${ConfigCapone.apicapone}/api/forma`,
                                emailData
                            )
                            .then((res) => {
                                console.log(res);
                                console.log("info : ", emailData);

                            })
                            .catch((err) => console.log("there was an error: " + err));

                        // }

                    }


                }
            }
            // setListMatricolaOwnProduct(dataOwnProduct)    
        }

        await listUpdateOwnProduct(idClient)

    }

    const listUpdateOwnProduct = async (idClient: number) => {
        if (optionAddEdit) {
            let ListOriginalOwnProduct: any = [];
            let ListConvertProduct: any = [];

            await axios
                .get(`${ConfigCapone.urlapi}/api/ClientProdposseduti/idClient/${idClient}`)
                .then((response) => {
                    ListOriginalOwnProduct = response.data.response
                })

            setCountOwnProducts([]);
            console.log("AAAAAAA", ListOriginalOwnProduct)
            for (let index = 0; index < ListOriginalOwnProduct.length; index++) {

                ListConvertProduct.push(
                    {
                        idClientProdPosseduti: ListOriginalOwnProduct[index]?.idClientProdPosseduti,
                        idClient: ListOriginalOwnProduct[index]?.idClient,
                        Prodotto: ListOriginalOwnProduct[index]?.prodotto,
                        idBrand: ListOriginalOwnProduct[index]?.idBrand,
                        Referenza: ListOriginalOwnProduct[index]?.referenza,
                        idMatricola: ListOriginalOwnProduct[index]?.idMatricola,
                        Matricola: ListOriginalOwnProduct[index]?.matricola,
                        Data: new Date(ListOriginalOwnProduct[index]?.data),
                        Prezzo: ListOriginalOwnProduct[index]?.prezzo,
                        PrezzoApplicato: ListOriginalOwnProduct[index]?.priceApplied,
                        SendEmail: ListOriginalOwnProduct[index]?.sendEmail,
                        hasError: false,
                    }
                )

            }

            const uniqueIds = new Set();

            const unique = ListConvertProduct.filter(element => {
                const isDuplicate = uniqueIds.has(element.idClientProdPosseduti);

                uniqueIds.add(element.id);

                if (!isDuplicate) {
                    return true;
                }

                return false;
            });

            setCountOwnProducts(unique);

        }
    }

    const updateOwnProduct = async (idClient: number, product?: any) => {

        let dataPost: any = [];
        let dataUpdate: any = [];
        let dataDelete: any = [];
        let valueOwnProduct: any = [];
        let status: boolean;

        let listProduct: any = product ? product : countOwnProducts

        for (let index = 0; index < listProduct.length; index++) {

            if (!listProduct[index].idClientProdPosseduti && listProduct[index].idBrand != "" && listProduct[index].Prodotto != "" && listProduct[index].Referenza != "" && listProduct[index].Matricola != "" && listProduct[index].Data != "" && listProduct[index].Prezzo != "") {

                if (!listProduct[index].idMatricola) {
                    dataPost.push({
                        idClient: idClient, idBrand: listProduct[index].idBrand, Prodotto: listProduct[index].Prodotto,
                        Referenza: listProduct[index].Referenza, Matricola: listProduct[index].Matricola,
                        Data: dateTimeNow(listProduct[index].Data), Prezzo: Number(listProduct[index].Prezzo), PrezzoApplicato: Number(listProduct[index].PrezzoApplicato)
                    })
                }

                if (listProduct[index].idMatricola) {
                    dataPost.push({
                        idMatricola: listProduct[index].idMatricola, idClient: idClient, idBrand: listProduct[index].idBrand, Prodotto: listProduct[index].Prodotto,
                        Referenza: listProduct[index].Referenza, Matricola: listProduct[index].Matricola,
                        Data: dateTimeNow(listProduct[index].Data), Prezzo: Number(listProduct[index].Prezzo), PrezzoApplicato: Number(listProduct[index].PrezzoApplicato)
                    })
                }

            }

            if (listProduct[index].idClientProdPosseduti) {

                if (!listProduct[index].idMatricola) {
                    dataUpdate.push({
                        idClientProdPosseduti: listProduct[index].idClientProdPosseduti, idClient: idClient, idBrand: listProduct[index].idBrand, Prodotto: listProduct[index].Prodotto,
                        Referenza: listProduct[index].Referenza, Matricola: listProduct[index].Matricola,
                        Data: dateTimeNow(listProduct[index].Data), Prezzo: Number(listProduct[index].Prezzo), PrezzoApplicato: Number(listProduct[index].PrezzoApplicato)
                    })
                }

                if (listProduct[index].idMatricola) {
                    dataUpdate.push({
                        idClientProdPosseduti: listProduct[index].idClientProdPosseduti, idMatricola: listProduct[index].idMatricola, idClient: idClient, idBrand: listProduct[index].idBrand, Prodotto: listProduct[index].Prodotto,
                        Referenza: listProduct[index].Referenza, Matricola: listProduct[index].Matricola, SendEmail: listProduct[index].SendEmail,
                        Data: dateTimeNow(listProduct[index].Data), Prezzo: Number(listProduct[index].Prezzo), PrezzoApplicato: Number(listProduct[index].PrezzoApplicato)
                    })
                }

            }

            if (listProduct[index].idMagazzino) {
                valueOwnProduct.push({
                    idMagazzino: listProduct[index].idMagazzino, idReferenzia: listProduct[index].idReference, idNegozio: listProduct[index].idBussines,
                    idMatricola: listProduct[index].idMatricola,
                    Matricola: listProduct[index].Matricola, Quantity: listProduct[index].Quantity,
                    PutAside: false,
                    PutMoveBusiness: false, PutSale: false,
                    PutSaleNewCustomer: optionAddEdit ? false : true, PutSaleExistingCustomer: optionAddEdit ? true : false, PutOnline: optionAddEdit ? true : false,
                    idClient: idClient
                })
            }
        }


        //
        let ListOriginalOwnProduct: any = [];
        let ListConvertProduct: any = [];

        await axios
            .get(`${ConfigCapone.urlapi}/api/ClientProdposseduti/idClient/${idClient}`)
            .then((response) => {
                ListOriginalOwnProduct = response.data.response
            })

        for (let index = 0; index < ListOriginalOwnProduct.length; index++) {

            ListConvertProduct.push(
                {
                    idClientProdPosseduti: ListOriginalOwnProduct[index]?.idClientProdPosseduti,
                    idClient: ListOriginalOwnProduct[index]?.idClient,
                    Prodotto: ListOriginalOwnProduct[index]?.prodotto,
                    idBrand: ListOriginalOwnProduct[index]?.idBrand,
                    Referenza: ListOriginalOwnProduct[index]?.referenza,
                    Matricola: ListOriginalOwnProduct[index]?.matricola,
                    Data: new Date(ListOriginalOwnProduct[index]?.data),
                    Prezzo: ListOriginalOwnProduct[index]?.prezzo
                }
            )

        }


        //       
        dataDelete = ListConvertProduct.filter((item: any) => !dataUpdate.some((dataUpdate: any) => dataUpdate.idClientProdPosseduti === item.idClientProdPosseduti))

        if (Object.keys(dataPost).length > 0) {

            for (let i = 0; i < dataPost.length; i++) {
                await axios
                    .post(`${ConfigCapone.urlapi}/api/ClientProdposseduti`, dataPost[i])
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }
        }

        if (Object.keys(dataUpdate).length > 0) {

            for (let i = 0; i < dataUpdate.length; i++) {
                await axios
                    .put(`${ConfigCapone.urlapi}/api/ClientProdposseduti/${dataUpdate[i].idClientProdPosseduti}`, dataUpdate[i])
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }
        }

        if (Object.keys(dataDelete).length > 0) {

            for (let i = 0; i < dataDelete.length; i++) {
                await axios
                    .delete(`${ConfigCapone.urlapi}/api/ClientProdposseduti/${dataDelete[i].idClientProdPosseduti}`)
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });
            };

            if (status === false) {
                setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${"Error"}`,
                    title: "Errore",
                    route: "",
                });
            }
        }

        if (Object.keys(valueOwnProduct).length > 0) {

            for (let index = 0; index < valueOwnProduct.length; index++) {

                let matricolaId = valueOwnProduct[index].idMatricola;
                let dataUpdateMatricola = valueOwnProduct[index]

                updateMatricola(matricolaId, dataUpdateMatricola, idClient)

                let updateMagazzino: any = [];

                await axios
                    .get(`${ConfigCapone.urlapi}/api/Magazzino/${Number(valueOwnProduct[index]?.idMagazzino)}`)
                    .then((response) => {
                        status = true;
                        console.log("español: ", response.data.response);
                        updateMagazzino = {
                            idMagazzino: Number(response.data.response?.idMagazzino),
                            quantity: Number(response.data.response?.quantity) - 1,
                            negozio: Number(response.data.response?.idBussines),
                        }

                    })
                    .catch((error) => {
                        status = false; 
                    });

                await axios
                    .put(`${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${updateMagazzino.idMagazzino}/${updateMagazzino.quantity}/${updateMagazzino.negozio}`)
                    .then((response) => {
                        status = true;
                    })
                    .catch((error) => {
                        status = false;
                    });

                if (Number(updateMagazzino.quantity) === 0) {
                    await axios.delete(`${ConfigCapone.urlapi}/api/Magazzino/${updateMagazzino.idMagazzino}}`)
                        .then((response) => {
                            status = true;
                        })
                        .catch((error) => {
                            status = false;

                        });
                }

                if (status === false) {
                    setmodaleMessaggeWithUrl({
                        active: true,
                        message: `${"Error"}`,
                        title: "Errore",
                        route: "",
                    });
                }

            }
            // setListMatricolaOwnProduct(dataOwnProduct)    
        }

    }

    const valueDefect: any = {
        Title: "",
        Name: "",
        Gender: "",
        AddressType: "",
        AddressStreet: "",
        AddressNumber: "",
        AddressCity: "",
        ZipCode: "",
        Province: "",
        Country: "",
        PhoneType: "",
        Phone: "",
        Email: "",
        Profession: "",
        DateBirth: "",
        Product: "",
        Brand: "",
        Referenza: "",
        Matricola: "",
        DateProduct: "",
        Prezzo: "",
        SendEmail: "",
        Hobby: "",
        Note: "",
        MeasureLittleFingerLH: "",
        MeasureRingFingerLH: "",
        MeasureMiddleFingerLH: "",
        MeasureIndexFingerLH: "",
        MeasureThumbLH: "",
        MeasurePulseLH: "",
        MeasureLittleFingerRH: "",
        MeasureRingFingerRH: "",
        MeasureMiddleFingerRH: "",
        MeasureIndexFingerRH: "",
        MeasureThumbRH: "",
        MeasurePulseRH: "",
        DateInsert: "",
        DateUpdateCrm: "",
        ObservedProduct: "",
        StarValuation: "",
        FamilyName: "",
        FamilyRelationship: "",
        Deprecated: "",
        BlackList: "",
        EnviaCatalogo: "",
        invioAuguri: "",
        CreatedTableBy: "",
        LatestUpdates: "",
        OriginIdBussines: ""
    };

    const addRelationShip = () => {
        setCountRelationShip(
            [
                ...countRelationShip,
                {
                    idRelationship: null,
                    Name: '',
                    Id: null,
                    onFocus: false,
                    hasError: false,
                }
            ],
        )

        console.log(countRelationShip);

    }

    const removeRelationShip = (position: number) => {
        console.log('position: ' + position);
        let currentRelationShip = [...countRelationShip];
        let newRelationShip = [];

        currentRelationShip.filter((element, index) => {
            if (index != position) {
                newRelationShip.push(element);
            }
        });

        console.log(newRelationShip);
        setCountRelationShip([]);
        setCountRelationShip(newRelationShip);
    }

    const changeRelationShip = async (dataValue: any, Matricola: string, index: number) => {

        let newArr = [...countRelationShip];

        console.log(newArr);

        newArr[index] = {
            ...newArr[index],
            [Matricola]: dataValue,
            ['onFocus']: true,
            ['hasError']: dataValue.length == 0 ? true : false,
        };

        console.log(dataValue, Matricola)
        console.log(searchInputNameRelation);

        setTextNameRelation(dataValue);
        setTextIndexRelation(index);
        setCountRelationShip(newArr);
        filterClientByName(dataValue, textIndexRelation);

    }

    const addObservedProduct = () => {
        setCountObservedProduct(
            [
                ...countObservedProduct,
                {
                    description: '',
                    onFocus: false,
                    hasError: false,
                }
            ],
        )
    }

    const removeObservedProduct = (position: number) => {
        let currentObservedProduct = [...countObservedProduct];
        let newObservedProduct = [];

        currentObservedProduct.filter((element, index) => {
            if (index != position) {
                newObservedProduct.push(element);
            }
        });

        console.log(newObservedProduct);
        setCountObservedProduct([]);
        setCountObservedProduct(newObservedProduct);
    }

    const changeObservedProduct = (dataValue: any, Name: string, index: number) => {
        let newArr = [...countObservedProduct];
        newArr[index] = {
            ...newArr[index],
            [Name]: dataValue,
            ['onFocus']: true,
            ['hasError']: dataValue.length == 0 ? true : false,
        };
        setCountObservedProduct(newArr);
    }

    const addOwnProduct = () => {

        setCountOwnProducts(
            [
                ...countOwnProducts,
                {
                    IdClient: dataClient ? dataClient.idClient : '',
                    Prodotto: '',
                    idBrand: '',
                    Referenza: '',
                    Matricola: '',
                    Data: '',
                    Prezzo: '',
                    SendEmail: false,
                    onFocus: false,
                    hasError: false,
                }
            ],
        )

    }

    const [stateEmail, setStateEmail] = useState(false)

    const changeEmailState = async (value?, matricola?, idClientProdPosseduti?, index?, data?: any) => {

        countOwnProducts[index].SendEmail = !value;
        console.log("data : ", data);

        /*
        
                let state: boolean = false;
        
                if (optionAddEdit) {
        
        
                    if (countOwnProducts[index].SendEmail) {
        
                        if (dataClient?.email?.length > 0) {
        
                            const titleClientSend = titleType.find(element => element.idTitle == dataClient.idTitle)
                            if (data?.Prodotto?.length > 0 && data?.Matricola?.length > 0 && data?.Prezzo?.toString()?.length > 0 && data?.Referenza?.length > 0
                                && data?.idBrand?.length > 0 && data?.Data?.toString()?.length > 0) {
                                let idBussinesMatricola;
                                await axios
                                    .get(`${ConfigCapone.urlapi}/api/Matricola/AllRegister/${data.idMatricola}`)
                                    .then((response) => {
                                        idBussinesMatricola = response.data.response?.idNegozio;
                                    })
                                    .catch((error) => {
                                        console.log("error -> ", error);
        
                                    });
                                const bussinesData = dataBussinesList.find(element => element.idBussines == idBussinesMatricola)
                                const emailMessage = formatMessageEmail(titleClientSend?.desc_Title, dataClient.name, data.Prodotto, bussinesData?.desc_Bussines, dataClient.country)
                                let dataSend = {
                                    from: "customerservice@sergiocapone.com",
                                    type: "notice",
                                    email: dataClient.email,
                                    subject: "Grazie per averci scelto - Gioielleria Capone",//"Nuova Vendita",
                                    //message: `Grazie per aver acquistato il prodotto ${matricola}`
                                    message: emailMessage // emailMessage.VariableMessageEmail
                                };
                                await axios
                                    .post(
                                        `${ConfigCapone.apicapone}/api/forma`,
                                        dataSend
                                    )
                                    .then((res) => {
                                        console.log("email send");
                                        state = true
                                    })
                                    .catch((err) => {
                                        console.log("there was an error: " + err)
                                        state = false
                                    });
        
                            } else {
                                console.log("no se envia el email");
        
                            }
        
                        } else {
        
                            setmodaleMessaggeWithUrl({
                                active: true,
                                message: "L'utente non ha un'email registrata.",
                                title: "Errore",
                                route: "",
                            });
                        }
        
        
                        if (state) {
                            idClientProdPosseduti &&
                                await axios
                                    .put(`${ConfigCapone.urlapi}/api/ClientProdposseduti/ModifyEmail/${idClientProdPosseduti}/${countOwnProducts[index].SendEmail}`)
                                    .then((response) => {
                                        console.log("email update")
                                    })
                                    .catch((err) => {
                                        console.log("there was an error: " + err)
                                    });
                        }
        
                    } else {
        
                        if (dataClient?.email?.length > 0) {
        
                            idClientProdPosseduti &&
                                await axios
                                    .put(`${ConfigCapone.urlapi}/api/ClientProdposseduti/ModifyEmail/${idClientProdPosseduti}/${countOwnProducts[index].SendEmail}`)
                                    .then((response) => {
                                        console.log("email update")
                                    })
                                    .catch((err) => {
                                        console.log("there was an error: " + err)
                                    });
        
                        }
                    }
                } else {
        
                    if (data.idBrand != "" && data.Prodotto != "" && data.Referenza != "" && data.Matricola != "" && data.Data != "" && data.Prezzo != "") {
                        handleSubmit(() => handleData(true, true))();
                    }
        
                }
        
        */

    };

    let verify: any;

    const goFilter = async () => {

        let client: any;
        await axios
            .get(`${ConfigCapone.urlapi}/api/Client/FindByIdClientCrm/${dataListClient}`)
            .then((response) => {
                client = response.data.response
            })
            .catch((err) => console.log(err));
        console.log(client)
        await resultSearch(client)
        // getInteressiClient(Number(data.idClient))

    }

    const updateListOwnProduct = async (product?: any) => {
        dataClient && await insertOwnProduct(Number(dataClient?.idClient), product)
    }

    useEffect(() => {

        if (route.params && optionProduct?.option == 1) {
            if (countOwnProducts?.filter((data: any) => data?.idMatricola == dataMagazzino?.matricola?.split("-")[0]).length < 1) {
                let countProduct: any = [
                    ...countOwnProducts,
                    {
                        IdClient: dataClient ? dataClient?.idClient : "",
                        Prodotto: dataMagazzino?.desc_brand + " " + dataMagazzino?.referencia,
                        idBrand: dataMagazzino?.idBrand,
                        Referenza: dataMagazzino?.referencia,
                        idMatricola: dataMagazzino?.matricola?.split("-")[0],
                        Matricola: dataMagazzino?.matricola?.split("-")[1],
                        Data: new Date(),//new Date(dataMagazzino?.dateInsert)
                        Prezzo: dataMagazzino?.prezzo,
                        PrezzoApplicato: dataMagazzino?.prezzo,
                        idMagazzino: dataMagazzino?.idMagazzino,
                        idReference: dataMagazzino?.idReference,
                        idBussines: dataMagazzino?.idBussines,
                        Quantity: dataMagazzino?.quantity,
                        disable: true,
                        onFocus: false,
                        hasError: false,
                    }
                ]
                console.log("count", countProduct)
                setCountOwnProducts(countProduct)

                updateListOwnProduct(countProduct)

            }

        } else {
            console.log("ttttt")
            if (optionProduct?.option == 2) {
                countObservedProduct?.filter((data: any) => data?.idMatricola == dataMagazzino?.matricola?.split("-")[0]).length < 1 &&
                    setCountObservedProduct(
                        [
                            ...countObservedProduct,
                            {
                                IdClient: '',
                                description: dataMagazzino?.referencia + " - " + dataMagazzino?.desc_brand + " - " + dataMagazzino?.desc_bussines,
                                idBrand: dataMagazzino?.idBrand,
                                Referenza: dataMagazzino?.referencia,
                                idMatricola: dataMagazzino?.matricola?.split("-")[0],
                                Matricola: dataMagazzino?.matricola?.split("-")[1],
                                Data: new Date(dataMagazzino?.dateInsert),
                                Prezzo: dataMagazzino?.prezzo,
                                PrezzoApplicato: dataMagazzino?.prezzo,
                                idMagazzino: dataMagazzino?.idMagazzino,
                                idReference: dataMagazzino?.idReference,
                                idBussines: dataMagazzino?.idBussines,
                                Quantity: dataMagazzino?.quantity,
                                disable: true,
                                onFocus: false,
                                hasError: false,
                            }
                        ],
                    )

                console.log("count2", countObservedProduct)
            }


        }

        if (route.params && dataListClient) {
            goFilter();
            console.log("ttttt", dataListClient)
        }

        if (route.params && optionProduct?.option == 3) {
            countOwnProducts?.filter((data: any) => data?.idMatricola == dataMagazzino?.matricola?.split("-")[0]).length < 1 &&
                setCountOwnProducts(
                    [
                        ...countOwnProducts,
                        {
                            IdClient: "",
                            Prodotto: dataMagazzino?.desc_brand + " " + dataMagazzino?.referencia,
                            idBrand: dataMagazzino?.idBrand,
                            Referenza: dataMagazzino?.referencia,
                            idMatricola: dataMagazzino?.matricola?.split("-")[0],
                            Matricola: dataMagazzino?.matricola?.split("-")[1],
                            Data: new Date(),
                            Prezzo: dataMagazzino?.prezzo,
                            PrezzoApplicato: dataMagazzino?.prezzo,
                            idMagazzino: dataMagazzino?.idMagazzino,
                            idReference: dataMagazzino?.idReference,
                            idBussines: dataMagazzino?.idBussines,
                            Quantity: dataMagazzino?.quantity,
                            UpdateMatricola: true,
                            disable: true,
                            onFocus: false,
                            hasError: false,
                        }
                    ],
                )
            console.log(countOwnProducts)
        }


    }, [route.params])

    const restoreMatricola = async (idMatricola: any) => {

        let dataMatricola: any;
        // restore matricola
        await axios
            .get(`${ConfigCapone.urlapi}/api/Matricola/ByIdRemove/${idMatricola}`)
            .then((response) => {
                dataMatricola = response.data.response
            })

        if (Object.keys(dataMatricola).length > 0) {

            let dataReference: any = {}
            await axios
                .get(`${ConfigCapone.urlapi}/api/Referenzia/${dataMatricola.idReferenzia}`)
                .then((response) => {
                    dataReference = response.data.response
                })

            if (Object.keys(dataReference).length > 0) {
                let data = {
                    idMatricola: dataMatricola.idMatricola,
                    idReferenzia: dataMatricola.idReferenzia,
                    idNegozio: dataReference.idBussines,
                    matricola: dataMatricola.matricola,
                    putAside: false,
                    putMoveBusiness: false,
                    putSale: false,
                    putSaleNewCustomer: false,
                    putSaleExistingCustomer: false,
                    putOnline: false,
                    idClient: 0
                }

                // restore matricola
                await axios
                    .put(`${ConfigCapone.urlapi}/api/Matricola/Restore/${idMatricola}`, data)
                    .then((response) => {
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    });

                // remove sale
                let dataSale: any;

                await axios
                    .get(`${ConfigCapone.urlapi}/api/Sales/ByIdClient/${dataClient.idClient}`)
                    .then((response: any) => {
                        if (response.data.status == 'success') {
                            dataSale = response.data?.response
                        }
                    })

                let newData = dataSale?.find(x => x.idMatricola == idMatricola)

                if (Object.keys(newData).length > 0) {
                    await axios
                        .delete(`${ConfigCapone.urlapi}/api/Sales/${newData.idSale}`)
                        .then((response) => {
                            console.log(response)
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                }

                // update magazzino
                let dataMagazzino: any = {};
                let quantity: any;

                await axios
                    .get(`${ConfigCapone.urlapi}/api/Magazzino/ByIdReference/${data.idReferenzia}`)
                    .then((response: any) => {
                        if (response.data.status == 'success') {
                            dataMagazzino = response.data.response
                        }
                    })

                if (Object.keys(dataMagazzino).length > 0) {
                    quantity = Number(dataMagazzino.quantity) + 1
                    await axios
                        .put(
                            `${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantityByReference/${data.idReferenzia}/${quantity}`, {}, {
                            headers: {
                                'Authorization': `Bearer ${cookies.get('token')}`
                            }
                        }
                        )
                        .then((response) => {
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        }

    }

    const removeOwnProduct = async (position: number) => {

        let currentOwnProduct = [...countOwnProducts];
        let newOwnProduct = [];

        currentOwnProduct.filter((element, index) => {
            if (index != position) {
                newOwnProduct.push(element);
            }
        });

        console.log("se elimino ", currentOwnProduct);

        if (optionAddEdit) {

            let ListOriginalOwnProduct: any = [];
            let ListConvertProduct: any = [];
            let dataDelete: any = [];

            await axios
                .get(`${ConfigCapone.urlapi}/api/ClientProdposseduti/idClient/${dataClient.idClient}`)
                .then((response) => {
                    ListOriginalOwnProduct = response.data.response
                })

            for (let index = 0; index < ListOriginalOwnProduct.length; index++) {

                ListConvertProduct.push(
                    {
                        idClientProdPosseduti: ListOriginalOwnProduct[index]?.idClientProdPosseduti,
                        idClient: ListOriginalOwnProduct[index]?.idClient,
                        Prodotto: ListOriginalOwnProduct[index]?.prodotto,
                        idBrand: ListOriginalOwnProduct[index]?.idBrand,
                        Referenza: ListOriginalOwnProduct[index]?.referenza,
                        Matricola: ListOriginalOwnProduct[index]?.matricola,
                        Data: new Date(ListOriginalOwnProduct[index]?.data),
                        Prezzo: ListOriginalOwnProduct[index]?.prezzo,
                        idMatricola: ListOriginalOwnProduct[index]?.idMatricola,
                    }
                )

            }

            //       
            dataDelete = ListConvertProduct.filter((item: any) => !newOwnProduct.some((newOwnProduct: any) => newOwnProduct.idClientProdPosseduti === item.idClientProdPosseduti))

            if (Object.keys(dataDelete).length > 0) {
                let status: boolean;
                for (let i = 0; i < dataDelete.length; i++) {
                    await axios
                        .delete(`${ConfigCapone.urlapi}/api/ClientProdposseduti/${dataDelete[i].idClientProdPosseduti}`)
                        .then((response) => {
                            status = true;
                        })
                        .catch((error) => {
                            status = false;
                        });

                    await restoreMatricola(dataDelete[i].idMatricola)
                };

                if (status === false) {
                    setmodaleMessaggeWithUrl({
                        active: true,
                        message: `${"Error"}`,
                        title: "Errore",
                        route: "",
                    });
                }

                setCountOwnProducts([]);
                setCountOwnProducts(newOwnProduct);
            } else {
                setCountOwnProducts([]);
                setCountOwnProducts(newOwnProduct);
            }

        } else {
            setCountOwnProducts([]);
            setCountOwnProducts(newOwnProduct);
        }

    }

    const changeOwnProduct = async (dataValue: any, Name: string, index: number) => {

        console.log("qqqqq", dataValue);

        let newArr = [...countOwnProducts];
        newArr[index] = {
            ...newArr[index],
            [Name]: dataValue,
            ['onFocus']: true,
            ['hasError']: dataValue.length == 0 ? true : false,
        };
        setCountOwnProducts(newArr);

        dataClient && await updateOwnProduct(Number(dataClient?.idClient), newArr)

    }

    const {
        setValue,
        getValues,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: valueDefect,
    });

    const getItemText = (item: IClient) => {
        let mainText = item.name + " - " + item.phone;
        return (
            <div>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ marginLeft: 10, flexShrink: 1 }}>
                        <Text style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}>{mainText}</Text>
                    </View>
                </View>
            </div>
        );
    };
    const getItemTextRelation = (item: IClient) => {
        let mainText = item.name + " - " + item.phone;
        return (
            <div>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ marginLeft: 10, flexShrink: 1 }}>
                        <Text style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}>{mainText}</Text>
                    </View>
                </View>
            </div>
        );
    };
    const getListClientInteressi = async (id: any) => {
        console.log(id)
        if (id != 0) {
            const result: any = await ListInteressiByClient(id);
            setDataListInteressiByClient(result);
            console.log("ttt", result)
        }
    }

    const resultSearch = async (data: IClient) => {
        console.log("OPPOPO", data)
        setCheckBoxIsImportantChecked(data.isImportant == true ? "important-3" : "0");
        getListClientInteressi(data?.idClient)
        const inter = getInteressiByClient(Number(data?.idClient))
        console.log(inter)
        inter.then((response) => {
            if (response != undefined) {              
                if (response.data.response.length > 0) {
                    setCheckBoxInListaChecked(true)
                }
                else {
                    setCheckBoxInListaChecked(false)
                }
            } else {
            }
        })
        setDataClient(data);
        console.log("data", data)
        setSearchInputName(data.name ? data.name : "")
        setIdClientExist(Number(data?.idClient))
        setValue("Name", data.name ? data.name : "")
        setValue("Title", data.idTitle ? data.idTitle : "")
        setValue("AddressStreet", data.addressStreet ? data.addressStreet : "")
        changeGender(data.idGender ? "gender-" + data.idGender : "", data.idGender && true)
        changeAddresstype(data.idAddressType ? "address-" + data.idAddressType : "", data.idAddressType && true)
        setValue("AddressNumber", data.addressNumber ? data.addressNumber : "")
        setValue("AddressCity", data.addressCity ? data.addressCity : "")
        setValue("ZipCode", data.zipCode ? data.zipCode : "")
        setValue("Province", data.province ? data.province : "")
        setValue("Country", data.country ? data.country : "")
        setValue("PhoneType", data.idPhoneType ? "phone-" + data.idPhoneType : "")
        setValue("Phone", data.phone ? data.phone : "")
        setValue("Email", data.email ? data.email : "")
        setValue("Profession", data.profession ? data.profession : "")
        setValue("DateBirth", data.dateBirth ? new Date(data.dateBirth) : "")

        // changeEmail(data.sendEmail ? "inviaEmail-1" : "inviaEmail-0", data.sendEmail && true)

        setValue("Note", data.note ? data.note : "")
        setValue("MeasureLittleFingerLH", data.measureLittleFingerLH ? data.measureLittleFingerLH : "")
        setValue("MeasureRingFingerLH", data.measureRingFingerLH ? data.measureRingFingerLH : "")
        setValue("MeasureMiddleFingerLH", data.measureMiddleFingerLH ? data.measureMiddleFingerLH : "")
        setValue("MeasureIndexFingerLH", data.measureIndexFingerLH ? data.measureIndexFingerLH : "")
        setValue("MeasureThumbLH", data.measureThumbLH ? data.measureThumbLH : "")
        setValue("MeasurePulseLH", data.measurePulseLH ? data.measurePulseLH : "")
        setValue("MeasureLittleFingerRH", data.measureLittleFingerRH ? data.measureLittleFingerRH : "")
        setValue("MeasureRingFingerRH", data.measureRingFingerRH ? data.measureRingFingerRH : "")
        setValue("MeasureMiddleFingerRH", data.measureMiddleFingerRH ? data.measureMiddleFingerRH : "")
        setValue("MeasureIndexFingerRH", data.measureIndexFingerRH ? data.measureIndexFingerRH : "")
        setValue("MeasureThumbRH", data.measureThumbRH ? data.measureThumbRH : "")
        setValue("MeasurePulseRH", data.measurePulseRH ? data.measurePulseRH : "")

        setValue("OriginIdBussines", data.origenIdBussines ? data.origenIdBussines : "")

        handleClickStart(data.starValuation ? Number(data.starValuation) : 0, data.starValuation && true)

        changeBlackList((data.blackList != null && data.blackList != 0) ? "blackList-1" : "", data.blackList && true)
        changeInviaCatalogo((data.enviaCatalogo != null && data.enviaCatalogo != 0) ? "EnviaCatalogo-1" : "", data.enviaCatalogo && true)
        changeInvioAuguri((data.sendEmailBirthday != null && data.sendEmailBirthday != 0) ? "InvioAuguri-1" : "", data.sendEmailBirthday != 0 && true)
        changeDeprecati((data.deprecated != null && data.deprecated != 0) ? "deprecate-1" : "", data.deprecated && true)


        //

        let ListHobby: any = [];

        setCheckBoxHobbyChecked([]);
        await axios
            .get(`${ConfigCapone.urlapi}/api/ClientHobby/SearchHobbyByClient/${data.idClient}`)
            .then((response) => {
                ListHobby = response.data.response
            })
            .catch((err) => console.log(err));

        ListHobby.forEach((element: any) => { changeHobby(element) });


        //
        let ListOriginalOwnProduct: any = [];
        let ListConvertProduct: any = [];

        await axios
            .get(`${ConfigCapone.urlapi}/api/ClientProdposseduti/idClient/${data.idClient}`)
            .then((response) => {
                ListOriginalOwnProduct = response.data.response
                setListMatricolaOwnProduct(response.data.response)
            })

        setCountOwnProducts([]);
        console.log("aaaqui", ListOriginalOwnProduct)
        for (let index = 0; index < ListOriginalOwnProduct.length; index++) {

            ListConvertProduct.push(
                {
                    idClientProdPosseduti: ListOriginalOwnProduct[index]?.idClientProdPosseduti,
                    idClient: ListOriginalOwnProduct[index]?.idClient,
                    Prodotto: ListOriginalOwnProduct[index]?.prodotto,
                    idBrand: ListOriginalOwnProduct[index]?.idBrand,
                    Referenza: ListOriginalOwnProduct[index]?.referenza,
                    idMatricola: ListOriginalOwnProduct[index]?.idMatricola,
                    Matricola: ListOriginalOwnProduct[index]?.matricola,
                    Data: new Date(ListOriginalOwnProduct[index]?.data),
                    Prezzo: ListOriginalOwnProduct[index]?.prezzo,
                    PrezzoApplicato: ListOriginalOwnProduct[index]?.priceApplied != null ? ListOriginalOwnProduct[index]?.priceApplied : ListOriginalOwnProduct[index]?.prezzo,
                    SendEmail: ListOriginalOwnProduct[index]?.sendEmail,
                    hasError: false,
                }
            )

        }


        console.log("listass:", ListOriginalOwnProduct);


        setCountOwnProducts(ListConvertProduct);

        //
        let ListOriginalObservedProduct: any = [];

        await axios
            .get(`${ConfigCapone.urlapi}/api/ClientProdasservati/idClient/${data.idClient}`)
            .then((response) => {
                ListOriginalObservedProduct = response.data.response
            })

        setCountObservedProduct([]);

        setCountObservedProduct(ListOriginalObservedProduct);

        // 
        let ListOriginalRelationship: any = [];
        let ListConvertRelationship: any = [];

        await axios
            .get(`${ConfigCapone.urlapi}/api/ClientRelationship?value=${data.idClient}&parameter=idClient`)
            .then((response) => {
                console.log(response.data.response);
                ListOriginalRelationship = response.data.response
            })

        for (let index = 0; index < ListOriginalRelationship.length; index++) {

            ListConvertRelationship.push(
                {
                    idClientRelationship: ListOriginalRelationship[index]?.idClientRelationship,
                    idRelationship: ListOriginalRelationship[index]?.idRelationship,
                    Name: ListOriginalRelationship[index]?.name,
                    idClient: ListOriginalRelationship[index]?.idClient,
                    idClientRelation: ListOriginalRelationship[index]?.idClientRelation,
                    Id: ListOriginalRelationship[index]?.idClientRelation,
                    hasError: false,
                }
            )

        }

        console.log("relaciones123", ListConvertRelationship);


        setCountRelationShip([]);

        setCountRelationShip(ListConvertRelationship);

        //

        setValue("DateInsert", data.dateInsert ? new Date(data.dateInsert).toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }) : "")
        setValue("DateUpdateCrm", data.dateUpdateCrm ? new Date(data.dateUpdateCrm).toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }) : "")
        setDateInsert(true)

        // 
        let lisUser: any = [];
        await axios
            .get(`${ConfigCapone.urlapi}/api/User/GetUserTable?parameter=1`)
            .then((response) => {
                lisUser = response.data.response
            })

        let user = lisUser.find((element: any) => element.idUser == data.idUser)
        setValue("CreatedTableBy", user ? user.name + " - " + user.desc_Role : "")
        setCreatedTableBy(true)

        //
        let listLatestUpdates: any = [];
        let listConvertLatestUpdates: any = [];
        await axios
            .get(`${ConfigCapone.urlapi}/api/StatusClient/FindByClientLimit/${data.idClient}`)
            .then((response) => {
                listLatestUpdates = response.data.response
            })

        for (let index = 0; index < listLatestUpdates.length; index++) {

            listConvertLatestUpdates.push(
                {
                    idStatusClient: listLatestUpdates[index]?.idStatusClient,
                    description: listLatestUpdates[index]?.name + " - " + listLatestUpdates[index]?.desc_Role,
                    date: new Date(listLatestUpdates[index]?.dateInsert).toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' })
                        + ", ore " + new Date(listLatestUpdates[index]?.dateInsert).getHours() + ":" + new Date(listLatestUpdates[index]?.dateInsert).getMinutes(),
                    hasError: false,
                }
            )

        }

        setDataLatestUpdates(listConvertLatestUpdates);
        setLatestUpdates(true);

        // reparazioni
        await serviceList(data.idClient);
        setRepairs(true);

        setOptionAddEdit(true);

    }
    const resultSearchRelation = async (data: IClient, index: number) => {

        console.log('client, ', data);

        let newArr = [...countRelationShip];
        newArr[index] = {
            ...newArr[index],
            ['Name']: data.name,
            ['Id']: data.idClient,
        };

        console.log('Data client, ', newArr);

        setCountRelationShip(newArr);



    }

    const onChangeTextName = async (text: string) => {

        setSearchInputName(text);
        if (text.length > 0) {
            // setActiveSpinner(true);

            await axios
                .get(`${ConfigCapone.urlapi}/api/Client/${text}/10/`)
                .then((response) => {
                    setAutosuggestInputName(response.data.response)
                })
                .catch((err) => console.log(err));
            // setActiveSpinner(false);
        }
    };


    const debounceClient: any = useMemo(() => {
        const data = async (value: string, index: any) => {
            await axios
                .get(`${ConfigCapone.urlapi}/api/Client/${value}/10/`)
                .then((response) => {
                    setAutosuggestInputNameRelation(response.data.response)
                    console.log(response.data.response)
                    //show only one list
                    let newArr = [...countRelationShip];
                    newArr.map(element => element.onFocus = false);
                    newArr[index] = {
                        ...newArr[index],
                        ['onFocus']: true,
                    };

                    console.log(newArr);

                    setCountRelationShip(newArr);

                })
                .catch((err) => console.log(err));
        }
        return Debounce(data, 500);
    }, [])

    const filterClientByName = async (text: any, index: number) => {
        let dataOrigin: any;
        let dataConvert: any;
        if (text.length > 2) {
            console.log("entraaqui", text)
            await axios
                .get(`${ConfigCapone.urlapi}/api/Client/${text}/10/`)
                .then((response) => {

                    console.log('test', response.data.response)
                    //show only one list
                    let newArr = [...countRelationShip];
                    newArr.map(element => element.onFocus = false);
                    newArr[index] = {
                        ...newArr[index],
                        ['onFocus']: true,
                    };
                    console.log("assss", newArr);
                    dataOrigin = response.data.response;
                    dataConvert = newArr;
                    //setCountRelationShip(newArr);
                })
                .catch((err) => console.log(err));
        }
        setAutosuggestInputNameRelation(dataOrigin)

        //setCountRelationShip(dataConvert);




    }

    const [textNameRelation, setTextNameRelation] = useState('')
    const [textIndexRelation, setTextIndexRelation] = useState<any>()
    const onChangeTextNameRelation = async (text: any, index: number) => {
        // if (text.length>3) {
        //     //console.log("first",textNameRelation,textIndexRelation)
        //     filterClientByName(textNameRelation, textIndexRelation);
        // }


    };

    // reparazioni

    const selectBussiness = async () => {
        setActiveSpinner(true);
        await axios
            .get(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`)
            .then((response) => {
                setApiBusiness(response.data.response)
                if (isEmployee) {
                    let defaultBusiness = response.data.response.filter((data: any) => data.idBussines == userInLogin.idBussines);
                    setDefaultBusiness(defaultBusiness[0].desc_Bussines);
                }
            })
            .catch((err) => console.log(err));
        setActiveSpinner(false);
    };

    const selectState = async () => {
        setActiveSpinner(true);
        await axios
            .get(`${ConfigCapone.urlapi}/api/Status?parameter=1`)
            .then((response) => setApiState(response.data.response))
            .catch((err) => console.log(err));
        setActiveSpinner(false);
    };

    const handleViewPage = (page: number) => {
        setPage(page)
        const newPage = page * 5;
        if (arrayDatas.length > 0) {
            setListUsers(arrayDatas?.slice(page * 5 - 5, page * 5));
        } else {
            setListUsers(dataAux?.slice(page * 5 - 5, page * 5));
        }
        setNumber(newPage);
        setBlockRight(false);
        setBlockLeft(true);
        if (page > 1) {
            setBlockLeft(false);
        }
        if (navs.length == 0) {
            if (page == numPages) {
                setBlockRight(true);
            }
        } else {
            if (page == navs.length) {
                setBlockRight(true);
            }
        }
    };

    const filterBy72Hours = (data: any) => {
        let dataBy72Hours = data.filter((element: any) => !(element.isMore72Hours > 72 && element.idStatus == '5'));
        return data;
    };

    let numPages = 0;
    const loadNumberPages = (pages: number) => {
        numPages = pages;
        let navsAux: any = [];
        for (let i = 0; i < pages; i++) {
            navsAux.push(
                <TouchableOpacity key={i} onPress={() => handleViewPage(i + 1)}>
                    <View>
                        <Text
                            style={{
                                fontWeight: "400",
                                color: "black",
                                fontSize: 14,
                                paddingHorizontal: 10,
                            }}
                        >
                            {number / 5 == i + 1 && (
                                <Text style={{ fontWeight: "bold" }}>»</Text>
                            )}
                            {i + 1}
                            {number / 5 == i + 1 && (
                                <Text style={{ fontWeight: "bold" }}>«</Text>
                            )}
                        </Text>
                    </View>
                </TouchableOpacity>
            );
        }
        setNavs(navsAux);
    };

    const updateStateOfService = async (id: number, idService: number, idClient?: number) => {

        console.log("id: ", number, " service: ", idService, " idClient: ", idClient);

        if (isTechnique) {

            let techniqueId = userInLogin.idUser;

            await axios
                .post(
                    `${ConfigCapone.urlapi}/api/Service/ChangeStatusService/${idService}/${id}/${techniqueId}`, {
                    headers: {
                        'Authorization': `Bearer ${cookies.get('token')}`
                    }
                }
                )
                .then(async (res) => {
                    if (isEmployee) {
                        axios
                            .get(
                                `${ConfigCapone.urlapi}/api/Client/SearchClientTableCrmV2?idBussines=${userInLogin.idBussines}&idClient=${idClient}`
                            )
                            .then((resp) => {
                                let dataFilter = filterBy72Hours(resp.data.response);
                                restartPagination(dataFilter);
                                // handleViewPageFilter(page, dataFilter);
                            });
                    } else {
                        axios
                            .get(
                                `${ConfigCapone.urlapi}/api/Client/SearchClientTableCrm?idClient=${idClient}`
                            )
                            .then((resp) => {
                                let dataFilter = filterBy72Hours(resp.data.response);
                                restartPagination(dataFilter);
                                // handleViewPageFilter(page, dataFilter);
                            });
                    }
                });
        } else {
            await axios
                .post(
                    `${ConfigCapone.urlapi}/api/Service/ChangeStatusService/${idService}/${id}/0`, {
                    headers: {
                        'Authorization': `Bearer ${cookies.get('token')}`
                    }
                }
                )
                .then(async (res) => {
                    if (isEmployee) {
                        axios
                            .get(
                                `${ConfigCapone.urlapi}/api/Client/SearchClientTableCrmV2?idBussines=${userInLogin.idBussines}&idClient=${idClient}`
                            )
                            .then((resp) => {
                                let dataFilter = filterBy72Hours(resp.data.response);
                                restartPagination(dataFilter);
                                // handleViewPageFilter(page, dataFilter);
                            });
                    } else {
                        axios
                            .get(
                                `${ConfigCapone.urlapi}/api/Client/SearchClientTableCrm?idClient=${idClient}`
                            )
                            .then((resp) => {
                                let dataFilter = filterBy72Hours(resp.data.response);
                                restartPagination(dataFilter);
                                // handleViewPageFilter(page, dataFilter);
                            });
                    }
                });
        }
    };

    const handleNextUsers = () => {
        let data: IRepairData[] = arrayDatas.slice(number, number + 5);
        setListUsers(data);
        setNumber(number + 5);
        setBlockLeft(false);
        if (number + 5 >= arrayDatas.length) {
            setBlockRight(true);
        }
    };

    const handlePreviousUsers = () => {
        let data: IRepairData[] = arrayDatas.slice(number - 10, number - 5);
        setNumber(number - 5);
        setListUsers(data);
        setBlockRight(false);
        if (number - 5 <= 5) {
            setBlockLeft(true);
        }
    };

    let dataAux: any = [];
    const restartPagination = (resp: any) => {
        dataAux = resp;
        setTotalListUsers(resp);
        setUserFromAPI(resp);
        setArrayDatas(resp);
        setListUsers(resp.slice(0, 5));
        const numberPages = Math.ceil(resp.length / 5);
        loadNumberPages(numberPages);
        setViewPagination(true);
        setBlockLeft(true);
        setBlockRight(false);
        setNumber(5);
        if (resp.length <= 5) {
            setViewPagination(false);
        } else {
            setViewPagination(true);
        }
    };

    const selectRepair = async () => {
        setActiveSpinner(true);
        await axios
            .get(`${ConfigCapone.urlapi}/api/ServiceType?parameter=1`)
            .then(async (response) => {
                await setStateRepair(response.data.response);
            });
        setActiveSpinner(false);
    };

    const loadDataForUpdate = async (data: any) => {
        let dataService = await axios
            .get(`${ConfigCapone.urlapi}/api/Service/${data.idService}`)
            .then((response) => {
                return response.data.response;
            })
            .catch((err) => console.log(err));
        let dataClient = await axios
            .get(
                `${ConfigCapone.urlapi}/api/Client?value=${data.idClient}&parameter=idClient`
            )
            .then((response) => {
                return response.data.response[0];
            })
            .catch((err) => console.log(err));
        let brandDefault = await axios
            .get(`${ConfigCapone.urlapi}/api/Brand/${dataService.idBrand}`)
            .then((response) => response.data.response);
        let bussinesDefault = await axios
            .get(`${ConfigCapone.urlapi}/api/Bussines/${dataService.idBussines}`)
            .then((response) => response.data.response);
        let clientResult = await axios
            .get(`${ConfigCapone.urlapi}/api/Client/${data.idClient}`)
            .then((response) => response.data.response.idTitle);
        let titleDefault = await axios
            .get(`${ConfigCapone.urlapi}/api/Title/${clientResult}`)
            .then((response) => response.data.response);
        let dataJoin = await {
            ...dataClient,
            ...dataService,
            brandDefault,
            bussinesDefault,
            titleDefault,
            tipo: "StateRepair",
        };

        return dataJoin;
    }

    const serviceList = async (item?: any) => {
        setActiveSpinner(true);
        //s.idBussines 
        if (isEmployee) {
            await axios
                .get(`${ConfigCapone.urlapi}/api/Client/SearchClientTableCrmV2?idBussines=${userInLogin.idBussines}&idClient=${item}`)
                .then((response) => {
                    let dataFilter = filterBy72Hours(response.data.response);
                    restartPagination(dataFilter);
                    //   setTableCsv(dataFilter);

                });
        } else {
            await axios
                .get(`${ConfigCapone.urlapi}/api/Client/SearchClientTableCrm?idClient=${item}`)
                .then((response) => {
                    let dataFilter = filterBy72Hours(response.data.response);
                    restartPagination(dataFilter);
                    // setTableCsv(dataFilter);

                });
        }
        setActiveSpinner(false);
    };

    const goToRepairTab = async (data: any) => {
        setActiveSpinner(true);

        setDataTableSend(data)

        let dataService = await axios
            .get(`${ConfigCapone.urlapi}/api/Service/${data.idService}`)
            .then((response) => {
                return response.data.response;
            })
            .catch((err) => console.log(err));

        let dataClient: IClient = await axios
            .get(
                `${ConfigCapone.urlapi}/api/Client?value=${data.idClient}&parameter=idClient`
            )
            .then((response) => {
                return response.data.response[0];
            })
            .catch((err) => console.log(err));

        console.log('CRM', dataService.idUser);

        await getUserFromId(dataService.idUser).then((result: IUser) =>
            setOperatore(result)
        );

        console.log("data es: ", data);

        let dataToNewRepair = await loadDataForUpdate(data);

        console.log("new data7: ", dataToNewRepair);

        setDataRepair(dataService);
        setDataClientList(dataToNewRepair);
        setModalVisibleCard(true);
        setActiveSpinner(false);

    };

    useEffect(() => {
        loadNumberPages(Math.ceil((arrayDatas ? arrayDatas.length : 0) / 5));
    }, [number]);

    const isFocused = useIsFocused();
    useEffect(() => {
        selectRepair();
        selectBussiness();
        selectState();
    }, [isFocused]);

    const modalNotice = async (data: any, description: string) => {
        setDataBussines(data);
        description === "Avvisa" && setModalVisible(true);

        if (description === 'ring') {
            setModalCorreo(true);
            await axios
                .get(`${ConfigCapone.urlapi}/api/User?parameter=1`)
                .then((response) => {
                    setUserList(response.data.response);
                });
        }
    };

    const handleDelete = async (id: number) => {
        setActiveSpinner(true);
        await axios
            .get(`${ConfigCapone.urlapi}/api/Service/${id}`)
            .then((response) => response.data.response.idClient)
            .catch((error) => setModalMessage({
                active: true,
                message: "Si è verificato un errore durante la rimozione.",
            }));
        await axios
            .delete(`${ConfigCapone.urlapi}/api/Service/${id}`)
            .then((response) => {
                serviceList(dataClient?.idClient);
            });

        setModal(false);
        setActiveSpinner(false);
    };

    const confirmDelete = (id: number) => {
        setIdStateRepair(id);
        setModal(true);
    };

    // 
    const confirmAction = (Landing: any, data: any) => {
        navigation.navigate(Landing, data);
        setmodaleMessaggeWithUrl({
            title: "Informazione",
            message: "",
            active: false,
            route: "",
        });
    };

    useEffect(() => {
      if(countOwnProducts && countOwnProducts.length > 0){
        let totalPrice = 0;
        countOwnProducts.forEach(item => {
            const prezzo = (item.PrezzoApplicato && item.PrezzoApplicato > 0)  ? Number(item.PrezzoApplicato) : Number(item.Prezzo);
            totalPrice += prezzo;
        });
        setTotalProductPosseduti(totalPrice)
      }
    }, [countOwnProducts])

    useEffect(() => {
        getTitle();
        getGender();
        getAddresstype();
        getPhonetype();
        getBrand();
        getRelationShip();
        getHobby();
        getBussinesList();
        dataListClient && getListClientInteressi(dataListClient);
        setTitle('Pannello di controllo');
    }, [])

    return (
        <ScrollView>
            {activeSpinner && <Loading activeSpinner={activeSpinner} />}
            <View
                style={stylesCRM.header}
            >
                <HeaderBar
                    hasRoundedIcon={false}
                    title="Pannello di controllo"
                    onBack={() => (route.params?.isDetailListe ? navigation.navigate("DetailsListeScreen", [idListeClient[0], idListeClient[1]]) : (route.params && dataListClient) ? navigation.navigate("ClientListScreen") : navigation.navigate("CRMHomeScreen"))}
                />
            </View>
            <div className="row pl-2" style={{ backgroundColor: "white" }}>
                <div className="col-md-10 col-sm-10 col-12 m-auto">
                    <View style={styles.container}>
                        <div className="row justify-content-between">
                            <div className="col-4">
                                <Text
                                    style={stylesCRM.label}>
                                    Aggiungi cliente
                                </Text>
                            </div>
                            <div className="col-4 d-flex justify-content-end">
                                                           
                                <div className="float-right">
                                    <CheckLabel
                                    name={"important"}
                                    id={"important-" + 3}
                                    type="text"
                                    text={"Importante"}
                                    checked={checkBoxIsImportantChecked}
                                    onChange={value => changeIsImportant(value)}
                                />
                                </div>                             
                                                                
                                <div className="float-right">    
                                    <label
                                        htmlFor="e"
                                        style={{
                                            fontSize: 18,
                                            marginBottom: 5,
                                            fontFamily: "Arsapia Regular font",
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="checksMagazzino"
                                            //disabled
                                            style={{
                                                margin: 10,
                                                marginLeft: 10,
                                                marginRight: 10,
                                                fontSize: 100,
                                            }}
                                            name={"dfgdf"}
                                            //onClick={onSelected}
                                            checked={checkBoxInListaChecked}
                                            //checked={multiSelected ? multiSelected : id === checked && true}
                                            //onChange={() => onChange && onChange(id)}
                                            //onChange={() => changeInLista(checkBoxInListaChecked)}
                                            id="e"
                                        />
                                        Presente in Lista
                                    </label>
                                    {/* <label className="d-flex" style={{fontSize: 18,
                                    marginBottom: 5,
                                    fontFamily: "Arsapia Regular font",}} ><input  style={{
                                        margin:  10 ,
                                        marginLeft:  10  ,
                                        marginRight:  10 ,
                                        fontSize: 100,
                                    }} type="checkbox"  checked={checkBoxInListaChecked}/>Presente in Lista</label> */}
                                </div>
                                <div className="float-right">
                                    <CheckLabel
                                        name={"blackList"}
                                        id={"blackList-" + 1}
                                        type="text"
                                        text={"Black List"}
                                        checked={checkBoxBlackListChecked}
                                        onChange={value => changeBlackList(value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <Text style={styles.label}>Titolo *</Text>
                        <Controller
                            control={control}
                            name="Title"
                            render={({ field: { onChange, value, onBlur } }) => (
                                <select
                                    className="mr-8 ml-8 "
                                    autoFocus={true}
                                    style={styleInput}
                                    value={value}
                                    defaultValue={'defaultNegozio'}
                                    onChange={(val) => onChange(val.target.value)}
                                    aria-label=".form-select-lg example">

                                    <option value="defaultNegozio">Seleziona</option>
                                    {titleType.map((r: any) => (
                                        <option key={r.idTitle} value={r.idTitle}>{r.desc_Title}</option>
                                    ))}

                                </select>
                            )}
                            rules={{ required: true }}
                        />
                        {errors.Title?.type === "required" && (
                            <Text style={stylesCRM.labelError}>
                                {errorMessageRequired}
                            </Text>
                        )}

                        <Text style={styles.label}>Nome e cognome *</Text>
                        <Controller
                            control={control}
                            name="Name"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <TextInput
                                    value={searchInputName}
                                    onBlur={() => console.log('search client...')}
                                    style={styleInput}
                                    onChange={(e: any) => onChange(e?.target?.value)}
                                    onChangeText={onChangeTextName}
                                />
                            )}
                            rules={{ required: true }}
                        />
                        {errors.Name?.type === "required" && (
                            <Text style={stylesCRM.labelError}>
                                {errorMessageRequired}
                            </Text>
                        )}


                        {searchInputName && autosuggestInputName.length > 0 ? (
                            <View style={{
                                borderWidth: 1,
                                marginTop: -15,
                                paddingTop: 15,
                                marginLeft: 2,
                                marginRight: 2,
                                borderTopWidth: 0,
                                borderBottomLeftRadius: 20,
                                borderBottomRightRadius: 20,
                            }}>
                                <FlatList
                                    data={autosuggestInputName}
                                    showsVerticalScrollIndicator={false}
                                    renderItem={({ item, index }) => (
                                        <Pressable
                                            style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }]}
                                            onPress={() => {
                                                setSearchInputName(item.name),
                                                    setAutosuggestInputName([]),
                                                    resultSearch(item)
                                            }
                                            }
                                        >
                                            {getItemText(item)}
                                        </Pressable>
                                    )}
                                    keyExtractor={(item, index) => item.idClient + index}
                                />
                            </View>
                        ) : null}


                        <View>
                            <CheckLabel
                                name={'EnviaCatalogo'}
                                id={'EnviaCatalogo-' + 1}
                                type="text"
                                text={'Invia catalogo a casa'}
                                checked={checkBoxInviaCatCasaChecked}
                                onChange={value => changeInviaCatalogo(value)}
                            />
                        </View>

                        {/* hidden en produccion y staging
                        <div hidden={!ConfigCapone.configTest} className="float-right">
                            <CheckLabel
                                name={"InvioAuguri"}
                                id={"InvioAuguri-" + 1}
                                type="text"
                                text={"Invio auguri"}
                                checked={checkBoxInvioAuguriChecked}
                                onChange={value => changeInvioAuguri(value)}
                            />
                        </div>
                        */}
                        <Text style={styles.label}>Genere</Text>
                        <div className="row px-4">
                            {
                                dataGender.map((item, index) => {
                                    return (
                                        <div key={index} className="col-sm-4 col-md-3 col-lg-3">
                                            <CheckLabel
                                                name={item.gender}
                                                id={"gender-" + item.idGender}
                                                type="text"
                                                text={item.gender}
                                                checked={checkBoxGenderChecked}
                                                onChange={value => changeGender(value)}
                                            />
                                            <br />
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <Text style={styles.label}>Indirizzo</Text>
                        <div className="row px-4">
                            {
                                dataAddressType.map((item, index) => {
                                    return (
                                        <div key={index} className="col-sm-4 col-md-3 col-lg-3">
                                            <CheckLabel
                                                name={item.addressType}
                                                id={"address-" + item.idAddressType}
                                                type="text"
                                                text={item.addressType}
                                                checked={checkBoxAddressTypeChecked}
                                                onChange={value => changeAddresstype(value)}
                                            />
                                            <br />
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="row mt-4">
                            <div className="col-8">
                                <Text style={styles.label}>Via/Piazza</Text>
                                <Controller
                                    control={control}
                                    name="AddressStreet"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <input
                                            className="form-control"
                                            value={value}
                                            style={styleInput}
                                            onChange={(e) => onChange(e.target.value)}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-4">
                                <Text style={styles.label}>N°</Text>
                                <Controller
                                    control={control}
                                    name="AddressNumber"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <input
                                            className="form-control"
                                            value={value}
                                            style={styleInput}
                                            onChange={(e) => onChange(e.target.value)}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12">
                                <Text style={styles.label}>Città</Text>
                                <Controller
                                    control={control}
                                    name="AddressCity"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <input
                                            className="form-control"
                                            value={value}
                                            style={styleInput}
                                            onChange={(e) => onChange(e.target.value)}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-4">
                                <Text style={styles.label}>CAP</Text>
                                <Controller
                                    control={control}
                                    name="ZipCode"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <input
                                            className="form-control"
                                            value={value}
                                            style={styleInput}
                                            onChange={(e) => onChange(e.target.value)}
                                        />
                                    )}
                                />
                            </div>

                            <div className="col-8">
                                <Text style={styles.label}>Provincia</Text>
                                <Controller
                                    control={control}
                                    name="Province"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <input
                                            className="form-control"
                                            value={value}
                                            style={styleInput}
                                            onChange={(e) => onChange(e.target.value)}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12">
                                <Text style={styles.label}>Nazione</Text>
                                <Controller
                                    control={control}
                                    name="Country"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <input
                                            className="form-control"
                                            value={value}
                                            style={styleInput}
                                            onChange={(e) => onChange(e.target.value)}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <Text style={styles.label}>Telefono</Text>
                        <Controller
                            control={control}
                            name="PhoneType"
                            render={({ field: { onChange, value, onBlur } }) => (
                                <div className="row px-4">
                                    {
                                        dataPhonetype.map((item, index) => {
                                            return (
                                                <div key={index} className="col-sm-4 col-md-3 col-lg-3">
                                                    <CheckLabel
                                                        name={item.phoneType}
                                                        id={"phone-" + item.idPhoneType}
                                                        type="text"
                                                        text={item.phoneType}
                                                        checked={value}
                                                        onChange={onChange}
                                                    />
                                                    <br />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )}
                        />

                        <Text style={styles.label}>Numero *</Text>
                        <Controller
                            control={control}
                            name="Phone"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <input
                                    className="form-control"
                                    value={value}
                                    style={styleInput}
                                    maxLength={25}
                                    type="number"
                                    onChange={(e) => onChange(e.target.value)}
                                />
                            )}
                            rules={{ required: true }}
                        />
                        {errors.Phone?.type === "required" && (
                            <Text style={stylesCRM.labelError}>
                                {errorMessageRequired}
                            </Text>
                        )}

                        <Text style={styles.label}>E-mail</Text>
                        <Controller
                            control={control}
                            name="Email"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <input
                                    className="form-control"
                                    value={value}
                                    style={styleInput}
                                    onChange={(e) => onChange(e.target.value)}
                                />
                            )}
                            rules={{
                                // required: true, 
                                // pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,10})$/g,
                                pattern: validateEmail,
                            }}
                        />
                        {errors.Email?.type === "required" && (
                            <Text style={stylesCRM.labelError}>
                                {errorMessageRequired}
                            </Text>
                        )}

                        {errors.Email?.type === "pattern" && (
                            <Text style={stylesCRM.labelError}>E-mail errata</Text>
                        )}

                        <Text style={styles.label}>Professione</Text>
                        <Controller
                            control={control}
                            name="Profession"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <input
                                    className="form-control"
                                    value={value}
                                    style={styleInput}
                                    onChange={(e) => onChange(e.target.value)}
                                />
                            )}
                        />

                        <Text style={styles.label}>Data di nascita</Text>
                        <Controller
                            control={control}
                            name="DateBirth"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <DatePicker
                                    locale={it}
                                    dateFormat='dd/MM/yyyy'
                                    style={{ paddingLeft: 100 }}
                                    maxDate={new Date()}
                                    setDate={new Date()}
                                    onChange={(date) => { console.log("Date", date), onChange(date) }}
                                    selected={value}
                                    showYearDropdown
                                    scrollableMonthYearDropdown
                                // readOnly element?.idClientRelationship ? element?.idRelationship :
                                // openToDate={new Date("01/01/1990")}
                                />
                            )}
                        />
                        <div className="col-12 mt-4">
                            <Text style={styles.label}>Negozio </Text>

                            {userInLogin.idRole === "4" || userInLogin.idRole === "5" ?
                                <Controller
                                    control={control}
                                    name="OriginIdBussines"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <select disabled
                                            className="mr-8 ml-8"
                                            style={styleInputBlock}
                                            value={value}
                                            onChange={(e) => onChange(e.target.value)}
                                            aria-label=".form-select-lg example">
                                            {dataBussinesList.map((d: any) => (
                                                value ? <option key={Number(d.idBussines)}
                                                    value={d.idBussines}>{d.desc_Bussines}</option> : userInLogin.idBussines == d.idBussines && <option key={Number(d.idBussines)}
                                                        value={d.idBussines}>{d.desc_Bussines}</option>
                                            ))}
                                        </select>
                                    )}
                                />

                                : <Controller
                                    control={control}
                                    name="OriginIdBussines"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <select
                                            className="mr-8 ml-8"
                                            style={styleInput}
                                            value={value}
                                            onChange={(e) => onChange(e.target.value)}
                                            aria-label=".form-select-lg example">
                                            <option value="default">Seleziona</option>
                                            {dataBussinesList.map((d: any) => (
                                                <option key={Number(d.idBussines)}
                                                    value={d.idBussines}>{d.desc_Bussines}</option>
                                            ))}
                                        </select>
                                    )}
                                />}

                        </div>
                        <Text style={styles.label}>Prodotti posseduti</Text>
                                              
                        {countOwnProducts.map((element, index) => (
                            <div className="row mt-4 mb-4">
                                <div className="col-6">
                                    <Text style={styles.label}>Prodotto</Text>
                                    <input
                                        className="form-control"
                                        value={element.Prodotto}
                                        onChange={({ target }) => changeOwnProduct(target.value, 'Prodotto', index)}
                                        onFocus={({ target }) => changeOwnProduct(target.value, 'Prodotto', index)}
                                        style={element.idMatricola ? styleInputBlock : styleInput}
                                        disabled={element.idMatricola ? true : false}
                                    />
                                </div>
                                <div className="col-6">
                                    <Text style={styles.label}>Brand</Text>

                                    <select
                                        className="mr-8 ml-8"
                                        style={element.idMatricola ? styleInputBlock : styleInput}
                                        disabled={element.idMatricola ? true : false}
                                        //defaultValue={element.idBrand}
                                        onChange={({ target }) => changeOwnProduct(target.value, 'idBrand', index)}
                                        aria-label=".form-select-lg example">
                                        <option value="default">Seleziona</option>
                                        {dataBrand.map((d: any) => (
                                            <option selected={element.idBrand == d.idBrand} key={Number(d.idBrand)}
                                                value={d.idBrand}>{d.desc_Brand}</option>
                                        ))}
                                    </select>
                                    <div style={{
                                        position: "relative",
                                        top: -35,
                                        left: 30,
                                        zIndex: -1
                                    }}>
                                        <TouchableOpacity
                                            style={{
                                                flex: 1,
                                                paddingHorizontal: 5,
                                                alignItems: "flex-end",
                                                justifyContent: "flex-end",
                                            }}
                                            onPress={() => removeOwnProduct(index)}
                                        >

                                            <img src={require("../../assets/icons/icon-delete.png")} alt="" style={{ width: 20, height: 20 }} />

                                        </TouchableOpacity>
                                    </div>
                                </div>
                                <div className="col-6 mt-4"></div>
                                <div className="col-6 mt-4">
                                    <Text style={styles.label}>Referenza</Text>
                                    <input
                                        className="form-control"
                                        value={element.Referenza}
                                        onChange={({ target }) => changeOwnProduct(target.value, 'Referenza', index)}
                                        onFocus={({ target }) => changeOwnProduct(target.value, 'Referenza', index)}
                                        style={element.idMatricola ? styleInputBlock : styleInput}
                                        disabled={element.idMatricola ? true : false}
                                    />
                                </div>
                                <div className="col-6 mt-4"></div>
                                <div className="col-6 mt-4">
                                    <Text style={styles.label}>Matricola</Text>
                                    <input
                                        className="form-control"
                                        value={element.Matricola}
                                        onChange={({ target }) => changeOwnProduct(target.value, 'Matricola', index)}
                                        onFocus={({ target }) => changeOwnProduct(target.value, 'Matricola', index)}
                                        style={element.idMatricola ? styleInputBlock : styleInput}
                                        disabled={element.idMatricola ? true : false}
                                    />
                                </div>
                                <div className="col-6 mt-4"></div>
                                <div className="col-6 mt-4">
                                    <Text style={styles.label}>Data</Text>
                                    <DatePicker
                                        locale="it"
                                        dateFormat='dd/MM/yyyy'
                                        maxDate={new Date()}
                                        style={{ paddingLeft: 100, backgroundColor: element.disable && "#9C9C9C" }}
                                        onChange={(date) => { console.log("DateMatricola", date), changeOwnProduct(date, 'Data', index) }}
                                        selected={element.Data}
                                        disabled={element.idMatricola ? true : false}
                                        showYearDropdown
                                        scrollableMonthYearDropdown
                                    />


                                </div>
                                <div className="col-6 mt-4"></div>
                                <div className="col-6 mt-4">
                                    <Text style={styles.label}>Prezzo</Text>

                                    <input
                                        className="form-control"
                                        value={element.Prezzo}
                                        onChange={({ target }) => changeOwnProduct(target.value, 'Prezzo', index)}
                                        style={element.idMatricola ? styleInputBlock : styleInput}
                                        disabled={element.idMatricola ? true : false}
                                        type="number"
                                    />
                                </div>
                                <div className="col-6 mt-4"></div>
                                <div className="col-6 mt-4">
                                    <Text style={styles.label}>Prezzo Applicato</Text>

                                    <input
                                        className="form-control"
                                        value={element.PrezzoApplicato}
                                        onChange={({ target }) => changeOwnProduct(target.value, 'PrezzoApplicato', index)}
                                        style={styleInput}
                                        // onKeyPress={(e) => {
                                        //     const isValidInput = /^[0-9\b]+$/.test(e.key);
                                        //     if (!isValidInput) {
                                        //         e.preventDefault();
                                        //     }
                                        // }}
                                        type="number"
                                    />
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12 text-end">
                                        <label style={{ fontSize: 18, marginBottom: 5, fontFamily: "Arsapia Regular font" }} >
                                            <input
                                                type="checkbox"
                                                style={{
                                                    margin: 10,
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    fontSize: 100
                                                }}
                                                name="send_email"
                                                // en stagin disable false, en produccion, disabled={optionAddEdit ? false : true}
                                                //disabled={optionAddEdit ? false : true}
                                                //disabled={ true}
                                                disabled={false}
                                                checked={element.SendEmail}
                                                onChange={(e) => { changeEmailState(element.SendEmail, element.Matricola, element.idClientProdPosseduti, index, element), setStateEmail(!stateEmail) }}
                                            />
                                            Invia e-mail
                                        </label>
                                    </div>
                                </div>


                                {/* {optionAddEdit
                                    &&
                                    <div className="row mt-4">
                                        <div className="col-12 text-end">                             
                                            <label style={{  fontSize: 18, marginBottom:5, fontFamily: "Arsapia Regular font"}}>
                                                <input
                                                    type="checkbox"
                                                    style={{ 
                                                        margin: 10 , 
                                                        marginLeft: 10,  
                                                        marginRight: 10,  
                                                        fontSize:100}}
                                                    name="send_email"   
                                                    checked={element.SendEmail} 
                                                    onChange={(e) => {changeEmailState(element.SendEmail,element.Matricola, element.idClientProdPosseduti, index), setStateEmail(!stateEmail)}}  
                                                />
                                                Invia e-mail
                                            </label>
                                        </div>
                                    </div>             
                                } */}

                            </div>
                        ))

                        }

                        {
                            countOwnProducts.length > 0 &&
                            <div className="row mb-2">
                                <div className="col-6" ></div>
                                <div className="col-6" style={{fontSize: 18}}>
                                    <strong>Totale spesa: {totalProductPosseduti} €</strong> 
                                </div>
                            </div>
                        }

                        <div className="row">
                            <div className="col-12">
                                <TouchableOpacity
                                    onPress={() => addOwnProduct()}
                                >
                                    <div className="d-flex">
                                        <Text style={{ fontSize: 35, fontWeight: '900', marginTop: 18 }}> + </Text>
                                        <Text style={[styles.label, { fontWeight: "bold", marginLeft: 0 }]}>
                                            Aggiungi prodotto
                                        </Text>
                                    </div>
                                </TouchableOpacity>
                            </div>
                            <div className="col-12">
                                <TouchableOpacity
                                    onPress={() => navigation.navigate('MagazzinoCercaScreen', { send: 1, controlPanel:true })}
                                >
                                    <div className="d-flex">
                                        <Text style={{ fontSize: 35, fontWeight: '900', marginTop: 18 }}> + </Text>
                                        <Text style={[styles.label, { fontWeight: "bold", marginLeft: 0 }]}>
                                            Aggiungi prodotto da magazzino
                                        </Text>
                                    </div>
                                </TouchableOpacity>
                            </div>
                        </div>

                        {/* <div className="row mt-4">
                           <div className="col-12 text-end">                             
                                 <CheckLabel
                                    name="Invia e-mail"
                                    id={"inviaEmail-"+1}
                                    type="text"
                                    text={"Invia e-mail"}
                                    checked={sendEmail} 
                                    onChange={value => changeEmail(value)}      
                                 />
                            </div>
                        </div> */}

                        <Text style={styles.label}>Hobby, sport, interessi</Text>
                       
                        <div className="row px-4 mt-3">
                            {
                                dataHobby.map((item, index) => {
                                    return (
                                        <div key={index} className="col-6 col-sm-6 col-md-4 col-lg-3 mb-3">
                                            <CheckLabel
                                                name={item.hobby}
                                                id={`hobby-${item.idHobby}`}
                                                type="icon"
                                                icon={require(`../../assets/icons-step-due/${item.image}`)}
                                                iconText={item.hobby}
                                                text={item.hobby}
                                                multiSelected={checkBoxHobbyChecked[`hobby-${item.idHobby}`]}
                                                onChange={() => changeHobby(item)}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <Text style={styles.label}>Note</Text>
                        <Controller
                            control={control}
                            name="Note"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <textarea
                                    className="form-control"
                                    value={value}
                                    style={styleTextArea}
                                    onChange={(e) => onChange(e.target.value)}
                                    rows={6}
                                />
                            )}
                        />

                        {/* Misure mano sinistra */}
                        {!hands &&
                            <div className="col-8 col-md-4 col-lg-2 d-flex justify-content-between">
                                <Text style={styles.label}>Misura mano</Text>
                                <TouchableOpacity
                                    onPress={() => setHands(!hands)}
                                    style={{ margin: "auto" }}
                                >
                                    <View>
                                        <img src={require("../../assets/icons/arrow_down.png")} alt="" style={{ width: 20, height: 20, marginTop: 28, tintColor: "#808080", alignSelf: "center" }} />
                                    </View>
                                </TouchableOpacity>
                            </div>
                        }

                        {hands &&
                            <div>

                                <div className="col-8 col-md-4 col-lg-2 d-flex justify-content-between">
                                    <Text style={styles.label}>Misure mano sinistra</Text>
                                    <TouchableOpacity
                                        onPress={() => setHands(!hands)}
                                        style={{ margin: "auto" }}
                                    >
                                        <View>
                                            <img src={require("../../assets/icons/arrow_down.png")} alt="" style={{ width: 20, height: 20, marginTop: 28, tintColor: "#808080", alignSelf: "center" }} />
                                        </View>
                                    </TouchableOpacity>
                                </div>

                                <div className="col-12 text-center d-flex justify-content-center" style={{ height: 520, marginTop: 80 }}>
                                    <ImageBackground style={{ height: 520, width: 380 }} source={require("../../assets/icons-step-due/sagoma-mano-sinistra.png")} >
                                        <div className="row mt-4">
                                            <div className="col-5" />
                                            <div className="col-4 text-center" style={{ marginTop: -85, marginLeft: -70 }}>
                                                <Text style={[styles.label, { marginLeft: 0 }]}>Medio</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasureMiddleFingerLH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3" />
                                            <div className="col-4 text-center" style={{ marginTop: -35, marginLeft: -110, zIndex: 1 }}>
                                                <Text style={[styles.label, { marginLeft: 0 }]}>Anulare</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasureRingFingerLH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-2" />
                                            <div className="col-4 text-center" style={{ marginTop: -35, marginLeft: 34 }}>
                                                <Text style={[styles.label, { marginLeft: 0 }]}>Inidice</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasureIndexFingerLH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-3" />
                                        </div>

                                        <div className="row">
                                            <div className="col-1" />
                                            <div className="col-4 text-center" style={{ marginTop: 20, marginLeft: -110 }}>
                                                <Text style={[styles.label, { marginLeft: 0 }]}>Mignolo</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasureLittleFingerLH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-5" >
                                            <div className="col-4" />
                                            <div className="col-4 text-center" style={{ marginTop: -16, marginLeft: 130 }}>
                                                <Text style={[styles.label, { marginLeft: 0 }]}>Pollice</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasureThumbLH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-6" />
                                            <div className="col-4 text-center" style={{ marginTop: 100, marginLeft: -70 }}>
                                                <Text style={[styles.label, { marginLeft: -40 }]}>Polso</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasurePulseLH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                    </ImageBackground>

                                </div>

                                {/* Misure mano destra */}

                                <br /><br />
                                <Text style={styles.label}>Misure mano destra</Text>

                                <div className="col-12 text-center d-flex justify-content-center" style={{ height: 520, marginTop: 80 }}>
                                    <ImageBackground style={{ height: 520, width: 380 }} source={require("../../assets/icons-step-due/sagoma-mano-destra.png")} >
                                        <div className="row mt-4">
                                            <div className="col-5" />
                                            <div className="col-4 text-center" style={{ marginTop: -80, marginLeft: 5 }}>
                                                <Text style={[styles.label, { marginLeft: 0 }]}>Medio</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasureMiddleFingerRH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3" />
                                            <div className="col-4 text-center" style={{ marginTop: -20, marginLeft: -60 }}>
                                                <Text style={[styles.label, { marginLeft: 0 }]}>Inidice</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasureIndexFingerRH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="col-12 text-center" style={{ marginTop: -20, width: 135, marginLeft: 60, position: "relative", left: 55 }}>
                                                <Text style={[styles.label, { marginLeft: 0 }]}>Anulare</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasureRingFingerRH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-1" />
                                        </div>

                                        <div className="row">
                                            <div className="col-8" />
                                            <div className="col-4 text-center" style={{ marginTop: 10, position: "relative", left: 80 }}>
                                                <Text style={[styles.label, { marginLeft: 0 }]}>Mignolo</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasureLittleFingerRH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-1" />
                                            <div className="col-4 text-center" style={{ marginTop: -16, position: "relative", right: 50 }}>
                                                <Text style={[styles.label, { marginLeft: 0 }]}>Pollice</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasureThumbRH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-4" />
                                            <div className="col-4 text-center" style={{ marginTop: 100 }}>
                                                <Text style={[styles.label, { marginLeft: 35 }]}>Polso</Text>
                                                <Controller
                                                    control={control}
                                                    name="MeasurePulseRH"
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <CurrencyInput
                                                            className="form-control"
                                                            value={value ? Number(value).toFixed(2) : ""}
                                                            style={styleInput}
                                                            intlConfig={{ locale: "it-IT" }}
                                                            decimalsLimit={2}
                                                            onValueChange={(valueString: any) => {
                                                                onChange(
                                                                    valueString ? valueString.replace(",", ".") : ""
                                                                );

                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </ImageBackground>
                                </div>
                            </div>
                        }

                        {dateInsert &&
                            <div className="mt-5">
                                <Text style={styles.label}>Data di inserimento cliente</Text>

                                <Controller
                                    control={control}
                                    name="DateInsert"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <input
                                            className="form-control"
                                            value={value}
                                            style={styleInputBlock}
                                            onChange={(e) => onChange(e.target.value)}
                                            disabled={true}
                                        />
                                    )}
                                />

                                <div style={{ marginTop: 40 }}></div>

                                <Text style={styles.label}>Data di aggiornamento cliente</Text>
                                <Controller
                                    control={control}
                                    name="DateUpdateCrm"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <input
                                            className="form-control"
                                            value={value}
                                            style={styleInputBlock}
                                            onChange={(e) => onChange(e.target.value)}
                                            disabled={true}
                                        />
                                    )}
                                />

                            </div>
                        }

                        <br /><br />
                        <div className="col-8 col-md-4 col-lg-2 d-flex justify-content-between">
                            <Text style={styles.label}>Prodotti osservati</Text>
                            <TouchableOpacity
                                onPress={() => setShowProdottiObs(!showProdottiObs)}
                                style={{ margin: "auto" }}
                            >
                                <View>
                                    <img src={require("../../assets/icons/arrow_down.png")} alt="" style={{ width: 20, height: 20, marginTop: 28, tintColor: "#808080", alignSelf: "center" }} />
                                </View>
                            </TouchableOpacity>
                        </div>
                        <br />
                        {/* {countObservedProduct.length === 0 && <Text style={{marginBottom:5, fontSize: 18, marginLeft: 18, fontFamily: "Arsapia Regular font", marginTop:-10}}>Prodotti osservati</Text>} */}

                        {showProdottiObs &&
                            <div>
                                {countObservedProduct.map((element, index) => (
                                    <div key={index} className="col-12">
                                        {countObservedProduct.length > 0
                                            &&
                                            <div>
                                                {/* <Text style={styles.label}>Prodotti osservati</Text> */}
                                                <input
                                                    className="form-control"
                                                    value={element.description}
                                                    onFocus={({ target }) => changeObservedProduct(target.value, 'description', index)}
                                                    onChange={({ target }) => changeObservedProduct(target.value, 'description', index)}
                                                    style={element.idMatricola ? styleInputBlock : styleInput}
                                                    disabled={element.idMatricola ? true : false}
                                                />

                                                <div style={{
                                                    position: "relative",
                                                    top: -35,
                                                    left: 30,
                                                    zIndex: -1
                                                }}>
                                                    <TouchableOpacity
                                                        style={{
                                                            flex: 1,
                                                            paddingHorizontal: 5,
                                                            alignItems: "flex-end",
                                                            justifyContent: "flex-end",
                                                        }}
                                                        onPress={() => removeObservedProduct(index)}
                                                    >
                                                        <img src={require("../../assets/icons/icon-delete.png")} alt="" style={{ width: 20, height: 20 }} />
                                                    </TouchableOpacity>
                                                </div>
                                            </div>
                                        }

                                        <br />

                                    </div>
                                ))
                                }
                                <br />
                                <div className="row" style={{ marginTop: -25 }}>
                                    <div className="col-12">
                                        <TouchableOpacity
                                            onPress={() => addObservedProduct()}
                                        >
                                            <div className="d-flex">
                                                <Text style={{ fontSize: 35, fontWeight: '900', marginTop: 18 }}> + </Text>
                                                <Text style={[styles.label, { fontWeight: "bold", marginLeft: 0 }]}>
                                                    Aggiungi prodotto
                                                </Text>
                                            </div>
                                        </TouchableOpacity>
                                    </div>
                                    <div className="col-12">
                                        <TouchableOpacity
                                            onPress={() => navigation.navigate('MagazzinoCercaScreen', { send: 2, controlPanel:true })}
                                        >
                                            <div className="d-flex">
                                                <Text style={{ fontSize: 35, fontWeight: '900', marginTop: 18 }}> + </Text>
                                                <Text style={[styles.label, { fontWeight: "bold", marginLeft: 0 }]}>
                                                    Aggiungi prodotto da magazzino
                                                </Text>
                                            </div>
                                        </TouchableOpacity>
                                    </div>
                                </div>
                            </div>
                        }

                        {optionAddEdit &&
                            <div className="row mt-4 mb-4">
                                <div className="col-12">
                                    <Text style={styles.label}>Stelle valutazione cliente</Text>
                                    <br /><br />
                                    <div style={{ display: "flex", flexDirection: "row", marginLeft: 20 }}>
                                        {stars.map((_, index) => {
                                            return (
                                                <BsStarFill
                                                    key={index}
                                                    size={40}
                                                    onClick={() => !isDipendente && handleClickStart(index + 1)}
                                                    onMouseOver={() => !isDipendente && handleMouseOverStart(index + 1)}
                                                    onMouseLeave={!isDipendente && handleMouseLeaveStart}
                                                    color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                                                    style={{
                                                        marginRight: 50,
                                                        cursor: "pointer",
                                                        marginTop: 10
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="row mt-3">
                            <Text style={styles.label}>Relazioni (familiari, amici, colleghi)</Text>

                            {

                                countRelationShip.map((element, cont_index) => (
                                    <div key={cont_index}>
                                        <div className="col-12 mt-4">
                                            <Text style={styles.label}>Nome e cognome</Text>

                                            <input
                                                className="form-control"
                                                style={styleInput}
                                                id={`name-relationship-${cont_index}`}
                                                value={element.Name}
                                                onChange={({ target }) => changeRelationShip(target.value, 'Name', cont_index)}
                                                //onFocus={({ target }) => changeRelationShip(target.value, 'Name', cont_index)}
                                                onFocus={({ target }) => changeRelationShip(target.value, 'Name', cont_index)}
                                            //onKeyUp={(e: any) => { onChangeTextNameRelation(e.target.value, cont_index) }}
                                            //onKeyDownCapture={(e: any) => { onChangeTextNameRelation(e.target.value, cont_index) }}
                                            />

                                        </div>
                                        {countRelationShip.length > 0
                                            &&
                                            <div style={{
                                                position: "absolute",
                                                right: 0,

                                            }}>
                                                <TouchableOpacity
                                                    style={{
                                                        flex: 1,
                                                        marginTop: 10,
                                                        marginLeft: 10,
                                                        paddingHorizontal: 5,
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                    onPress={() => removeRelationShip(cont_index)}
                                                >
                                                    <img src={require("../../assets/icons/icon-delete.png")} alt="" style={{ width: 20, height: 20 }} />
                                                </TouchableOpacity>
                                            </div>
                                        }
                                        {(autosuggestInputNameRelation && autosuggestInputNameRelation.length > 0 && element.onFocus) ? (
                                            <View style={{
                                                borderWidth: 1,
                                                marginTop: -15,
                                                paddingTop: 15,
                                                marginLeft: 2,
                                                marginRight: 2,
                                                borderTopWidth: 0,
                                                borderBottomLeftRadius: 20,
                                                borderBottomRightRadius: 20,
                                            }}>
                                                <FlatList
                                                    data={autosuggestInputNameRelation}
                                                    showsVerticalScrollIndicator={false}
                                                    renderItem={({ item, index }) => (
                                                        <Pressable
                                                            style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }]}
                                                            onPress={() => {
                                                                setSearchInputNameRelation(item.name),
                                                                    setAutosuggestInputNameRelation([]),
                                                                    resultSearchRelation(item, cont_index)
                                                                setTextNameRelation('')
                                                            }
                                                            }
                                                        >
                                                            {getItemTextRelation(item)}
                                                        </Pressable>
                                                    )}
                                                    keyExtractor={(item, index) => item.idClient + index}
                                                />
                                            </View>
                                        ) : null}
                                        <div className="col-12 mt-4">
                                            <Text style={styles.label}>Relazione</Text>
                                            <select
                                                className="mr-8 ml-8"
                                                style={styleInput}
                                                defaultValue={element?.idClientRelationship ? element?.idRelationship : 'default'}
                                                onChange={({ target }) => changeRelationShip(target.value, 'idRelationship', cont_index)}
                                                aria-label=".form-select-lg example">
                                                <option value="default">Seleziona</option>
                                                {dataRelationShip.map((d: any) => (
                                                    <option key={Number(d.idRelationship)}
                                                        value={d.idRelationship}>{d.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                ))
                            }

                            <div className="col-12 mt-1">
                                <TouchableOpacity
                                    onPress={() => { addRelationShip() }}
                                >
                                    <div className="d-flex">
                                        <Text style={{ fontSize: 35, fontWeight: '900', marginTop: 18 }}> + </Text>
                                        <Text style={[styles.label, { fontWeight: "bold", marginLeft: 0 }]}>
                                            Aggiungi
                                        </Text>
                                    </div>
                                </TouchableOpacity>
                            </div>
                        </div>


                        {(repairs && listUsers?.length > 0) &&

                            <div className="row mt-4">
                                <div className="col-12 mt-4">
                                    <Text style={styles.label}>Riparazioni</Text>

                                    <div className="table-responsive">

                                        {/* <ExcelFile
                                        filename={nameFileExport}
                                        element={<button type="button" className="dowloandPdf" >Esporta</button>}
                                        >
                                        <ExcelSheet dataSet={DataSet} name="pruen 1 reporte"/>
                                        </ExcelFile> */}

                                        <table className="table table-borderless mt-3 mx-0" id="table-to-xls"  >
                                            <thead style={{ backgroundColor: "black", color: "white", height: 50 }}>
                                                <tr className="mr-4">
                                                    <th
                                                        style={{
                                                            borderTopLeftRadius: 50,
                                                            borderBottomLeftRadius: 50,
                                                        }}
                                                        scope="col"
                                                        className="align-self-center mr-4 px-4"
                                                    ></th>
                                                    <th
                                                        scope="col"
                                                        className="align-self-center mr-4 px-4"
                                                    ></th>
                                                    <th
                                                        style={{
                                                            textAlign: "center",
                                                            fontWeight: "400",
                                                            verticalAlign: "middle"
                                                        }}
                                                        scope="col"
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        Cliente
                                                    </th>
                                                    <th
                                                        style={{
                                                            textAlign: "center",
                                                            fontWeight: "400",
                                                            verticalAlign: "middle"
                                                        }}
                                                        scope="col"
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        Orologio
                                                    </th>
                                                    <th
                                                        style={{
                                                            textAlign: "center",
                                                            fontWeight: "400",
                                                            verticalAlign: "middle"
                                                        }}
                                                        scope="col"
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        Data
                                                    </th>
                                                    <th
                                                        style={{
                                                            textAlign: "center",
                                                            fontWeight: "400",
                                                            verticalAlign: "middle"
                                                        }}
                                                        scope="col"
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        Negozio
                                                    </th>
                                                    <th
                                                        style={{
                                                            textAlign: "center",
                                                            fontWeight: "400",
                                                            verticalAlign: "middle"
                                                        }}
                                                        scope="col"
                                                        className="align-self-center mr-4 px-4"
                                                    >
                                                        Stato
                                                    </th>
                                                    <th
                                                        style={{
                                                            textAlign: "center",
                                                            fontWeight: "400",
                                                            verticalAlign: "middle"
                                                        }}
                                                        scope="col"
                                                        className="align-self-center mr-4 px-4"
                                                    ></th>
                                                    <th
                                                        style={{
                                                            textAlign: "center",
                                                            fontWeight: "400",
                                                            verticalAlign: "middle"
                                                        }}
                                                        scope="col"
                                                        className="align-self-center mr-4 px-4"
                                                    ></th>
                                                    <th
                                                        style={{
                                                            borderTopRightRadius: 50,
                                                            borderBottomRightRadius: 50,
                                                        }}
                                                        scope="col"
                                                        className="align-self-center mr-4 px-4"
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listUsers &&
                                                    listUsers.map((data, index) => {
                                                        return (
                                                            <tr
                                                                key={index}
                                                                style={{ borderBottom: "1px solid 	gray" }}
                                                            >
                                                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                    {(data.isEqualOrMore45Days && data.idStatus != 5) && (
                                                                        <Icon
                                                                            name="exclamation-circle"
                                                                            type="font-awesome"
                                                                            color="#f50"
                                                                            containerStyle={{
                                                                                marginLeft: "-3%",
                                                                                justifyContent: "center",
                                                                            }}
                                                                            tvParallaxProperties={undefined}
                                                                        />
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                    className="align-self-center mr-4 px-4"
                                                                >
                                                                    <TouchableOpacity
                                                                        style={{
                                                                            flex: 1,
                                                                            paddingHorizontal: 5,
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                        }}
                                                                        onPress={() => goToRepairTab(data)}
                                                                    >
                                                                        <img
                                                                            style={{ width: 30, height: 30 }}
                                                                            src={require('../../assets/icons/icon-note.png')}
                                                                        />
                                                                    </TouchableOpacity>
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                    className="align-self-center"
                                                                >
                                                                    {data.name}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                    className="align-self-center"
                                                                >
                                                                    {data.desc_Product
                                                                        ? data.desc_Product
                                                                        : "Nessun dato"}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                    className="align-self-center mr-4 px-4"
                                                                >
                                                                    {data.dateService
                                                                        .split("T")[0]
                                                                        .toString()
                                                                        .split("-")
                                                                        .reverse()
                                                                        .join("/")}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                    className="align-self-center mr-4 px-4"
                                                                >
                                                                    {data.desc_Bussines
                                                                        ? data.desc_Bussines
                                                                        : "Nessun dato"}
                                                                </td>
                                                                <td>

                                                                    {console.log("reparacion ", data)}

                                                                    {(data.desc_Status == "Concluso" && data.isMore72Hours > 72)
                                                                        ? <div style={{ textAlign: "center", verticalAlign: "middle", marginTop: 7.5 }}
                                                                            className="align-self-center mr-4 px-4">{data.desc_Status}</div>
                                                                        :
                                                                        (<Controller
                                                                            control={control}
                                                                            name="business"
                                                                            render={({
                                                                                field: { onChange, value, onBlur },
                                                                            }) => (
                                                                                <select
                                                                                    className="form-select selectedState"
                                                                                    style={{
                                                                                        width: "auto",
                                                                                        border: 0,
                                                                                        margin: 'auto',
                                                                                        pointerEvents: isEmployee ? 'none' : 'all'
                                                                                    }}
                                                                                    onChange={(e) =>
                                                                                        updateStateOfService(
                                                                                            Number(e.target.value),
                                                                                            Number(data.idService),
                                                                                            Number(data.idClient)
                                                                                        )
                                                                                    }
                                                                                    aria-label=".form-select-lg "
                                                                                >
                                                                                    {apiState.map((d: any) => (
                                                                                        <option
                                                                                            key={d.idStatus}
                                                                                            selected={
                                                                                                d.idStatus === data.status.idStatus
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            value={d.idStatus}
                                                                                        >
                                                                                            {d.desc_Status}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            )}
                                                                        />)
                                                                    }


                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                    className="align-self-center mr-4 px-4"
                                                                >
                                                                    <button
                                                                        style={{
                                                                            flex: 1,
                                                                            width: 100,
                                                                            height: 40,
                                                                            alignSelf: "center",
                                                                            backgroundColor: "transparent",
                                                                            borderRadius: 50,
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            pointerEvents: isEmployee ? 'none' : 'all'
                                                                        }}
                                                                        onClick={() => {
                                                                            modalNotice(data, "Avvisa");
                                                                        }}
                                                                    >
                                                                        Avvisa
                                                                    </button>
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                >
                                                                    <TouchableOpacity
                                                                        style={{
                                                                            flex: 1,
                                                                            paddingHorizontal: 5,
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            pointerEvents: isEmployee ? 'none' : 'all'
                                                                        }}
                                                                        onPress={() => {
                                                                            modalNotice(data, "ring");
                                                                        }}
                                                                    >
                                                                        <img
                                                                            style={{ width: 20, height: 20 }}
                                                                            src={require("../../assets/icons/Campana-resize.png")}
                                                                        />
                                                                    </TouchableOpacity>
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center", verticalAlign: "middle" }}
                                                                >
                                                                    <TouchableOpacity
                                                                        style={{
                                                                            flex: 1,
                                                                            paddingHorizontal: 5,
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            pointerEvents: isAdmin ? 'all' : 'none'
                                                                        }}
                                                                        onPress={() => confirmDelete(data.idService)}
                                                                    >
                                                                        <img
                                                                            style={{ width: 20, height: 20 }}
                                                                            src={require("../../assets/icons/icon-delete.png")}
                                                                        />
                                                                    </TouchableOpacity>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {viewPagination && (
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-center">
                                                <div className="table-responsive">
                                                    <View style={{ flexDirection: "row", marginTop: 15 }}>
                                                        {!blockLeft && (
                                                            <TouchableOpacity onPress={handlePreviousUsers}>
                                                                <View>
                                                                    <Image
                                                                        style={{
                                                                            width: 15,
                                                                            marginRight: 30,
                                                                            height: 15,
                                                                            tintColor: "rgb(200,200,200)",
                                                                        }}
                                                                        source={require("../../assets/icons/icon-left.png")}
                                                                    />
                                                                </View>
                                                            </TouchableOpacity>
                                                        )}
                                                        {navs}
                                                        {!blockRight && (
                                                            <TouchableOpacity onPress={handleNextUsers}>
                                                                <View>
                                                                    <Image
                                                                        style={stylesCrmRepair.imageWithMargin}
                                                                        source={require("../../assets/icons/icon-right.png")}
                                                                    />
                                                                </View>
                                                            </TouchableOpacity>
                                                        )}
                                                    </View>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <TouchableOpacity
                                        onPress={() => navigation.navigate('FormRepairScreen', { dataCrm: dataClient })}
                                    >
                                        <div className="d-flex">
                                            <Text style={{ fontSize: 35, fontWeight: '900', marginTop: 18 }}> + </Text>
                                            <Text style={[styles.label, { fontWeight: "bold", marginLeft: 0 }]}>
                                                Aggiungi
                                            </Text>
                                        </div>
                                    </TouchableOpacity>
                                </div>


                            </div>
                        }
                        {/* list pressentti */}
                        {dataListInteressiByClient.length > 0 &&
                            <GridListInteressiByClientUpdate
                                control={control}
                                dataListInteressiByClient={dataListInteressiByClient}
                            />
                        }
                        <div className="row mt-4" style={{ paddingLeft: 5 }}>
                            <div className="col-5">
                                <CheckLabel
                                    name={"Deprecate"}
                                    id={"deprecate-" + 1}
                                    type="text"
                                    text={"Deprecati"}
                                    checked={checkBoxDeprecatiChecked}
                                    onChange={value => changeDeprecati(value)}
                                />
                            </div>
                        </div>

                        {createdTableBy &&
                            <div className="row mt-4">
                                <div className="col-12 mt-4">
                                    <Text style={styles.label}>Scheda creata da</Text>
                                    <Controller
                                        control={control}
                                        name="CreatedTableBy"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <input
                                                className="form-control"
                                                value={value}
                                                style={styleInputBlock}
                                                onChange={(e) => onChange(e.target.value)}
                                                disabled={true}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        }

                        {latestUpdates &&
                            <div className="row mt-4">
                                <Text style={styles.label}>Aggiornamenti</Text>
                                {dataLatestUpdates?.length > 0
                                    &&
                                    dataLatestUpdates?.map((element, index) => (
                                        <div className="col-12 mt-4" key={index}>
                                            <Text style={styles.label}>{element.date}</Text>
                                            <input
                                                className="form-control"
                                                disabled={true}
                                                value={element.description}
                                                style={styleInputBlock}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        }


                        <div className="col-12 d-flex justify-content-end" style={{ marginTop: 33, textAlign: "end", color: "white" }}>

                            <TouchableOpacity
                                style={stylesCRM.button}
                                onPress={optionAddEdit === false
                                    ? handleSubmit(() => handleData(true))
                                    : handleSubmit(() => handleDataEdit(true))
                                }
                            >
                                {optionAddEdit === true ? "Salva Modifiche" : "Salva"}
                            </TouchableOpacity>

                        </div>

                    </View>
                </div>
            </div>
            {modaleMessaggeWithUrl.active && (
                <PopUpMessageWithRoute
                    routeNav={modaleMessaggeWithUrl.route}
                    dataNav={[]}
                    title={modaleMessaggeWithUrl.title}
                    message={modaleMessaggeWithUrl.message}
                    stateModal={modaleMessaggeWithUrl.active}
                    setStateModal={setmodaleMessaggeWithUrl}
                    confirmAction={confirmAction}
                />
            )}

            {modal && (
                <PopupConfirmation
                    title={""}
                    message={"Sei sicuro di eliminare la riparazione?"}
                    confirmAction={() => handleDelete(idStateRepair)}
                    stateModal={modal}
                    setStateModal={setModal}
                />
            )}

            {modalVisible && (
                <SendNotificationScreen
                    currentPage='state_repair'
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    clientData={dataBussines}
                />
            )}

            {modalCorreo && (
                <SendEmailScreen
                    modalVisible={modalCorreo}
                    setModalVisible={setModalCorreo}
                    clientData={dataBussines}
                    userList={userList}
                />
            )}

            <RepairCardScreen
                modalVisible={modalVisibleCard}
                setModalVisible={setModalVisibleCard}
                dataClient={dataClientList}
                dataService={dataRepair}
                operatore={operatore}
                navigation={navigation}
                activeButton={activeBottom}
                dataTableSend={dataTableSend}

            />
        </ScrollView>
    );
};

const stylesCrmRepair = StyleSheet.create({
    imageWithMargin: {
        width: 15,
        marginLeft: 30,
        height: 15,
        tintColor: "rgb(200,200,200)",
    },
    arrowDown: {
        width: 20,
        height: 20,
        marginTop: 28,
        tintColor: "#808080",
        // alignSelf: "center",
    }
})