import React from "react";
import { Collapse } from "react-collapse";
import { useShow } from "../../../../../hooks/useShow";
import { CollapseTitleAtelier } from "../../../../Magazzino/Atelier/views/CollapseTitleAtelier";
import { CheckAtelier } from "./CheckAtelier";

interface Props {
  list: any[];
  handleFilter: (value: string) => void;
}
export const FilterCrmCheckboxGender = ({
  list,
  handleFilter,
}: Props) => {
  const { show, toggle } = useShow();
  return (
    <>
      <CollapseTitleAtelier title="Genere               " toggle={toggle} />
      <Collapse isOpened={show}>
        <div className="col-12">
          {list?.map(({ idGender, gender }) => (
            <div key={idGender}>
              <CheckAtelier
                name={gender}
                id={'gender-'+ idGender}
                text={gender}
                marginleft
                handlechange={handleFilter}
              />
              <br />
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};
