import { useFormRepair } from '../../hooks/formRepair/useFormRepair'
import { items } from './elements'
import { ListItem } from './ListItem'

export const TabList = () => {
  const { currentStep, view } = useFormRepair()
  console.log(view)
  return (
    <div className="steps">
      <ul role="tablist">
        {items.map(({ id, title, Icon }) => (
          <ListItem
            key={id}
            Icon={Icon}
            title={title}
            current={id === currentStep}
            done={view.includes(id)}
          />
        ))}
      </ul>
    </div>
  )
}
