export const formatMessageEmailAtelier = (
  title: string,
  name: string,
  desc_brand: string,
  desc_bussines: string
) => {
  const titleVariable = title ? title : "";
  let content;

  return (content = `
    <head>
    <meta http-equiv="Content-Type" content="text/html; " />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Auguri di buon compleanno!</title>
    <style type="text/css" emogrify="no">
        #outlook a {
            padding: 0;
        }
        .ExternalClass {
            width: 100%;
        }
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
            line-height: 100%;
        }
        table td {
            border-collapse: collapse;
            mso-line-height-rule: exactly;
        }
        .editable.image {
            font-size: 0 !important;
            line-height: 0 !important;
        }
        .nl2go_preheader {
            display: none !important;
            mso-hide: all !important;
            mso-line-height-rule: exactly;
            visibility: hidden !important;
            line-height: 0px !important;
            font-size: 0px !important;
        }
        body {
            width: 100% !important;
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
            margin: 0;
            padding: 0;
        }
        img {
            outline: none;
            text-decoration: none;
            -ms-interpolation-mode: bicubic;
        }
        a img {
            border: none;
        }
        table {
            border-collapse: collapse;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
        }
        th {
            font-weight: normal;
            text-align: left;
        }
        *[class="gmail-fix"] {
            display: none !important;
        }
    </style>
    <style type="text/css" emogrify="no"></style>
    <style type="text/css" emogrify="no"></style>
    <!--[if !mso]><!-->
    <style type="text/css" emogrify="no"></style>
    <!--<![endif]-->
    <style type="text/css">
        p,
        h1,
        h2,
        h3,
        h4,
        ol,
        ul,
        li {
            margin: 0;
        }
        a,
        a:link {
            color: #0092ff;
            text-decoration: underline;
        }
        .nl2go-default-textstyle {
            color: #3b3f44;
            font-family: arial, helvetica, sans-serif;
            font-size: 16px;
            line-height: 1.5;
            word-break: break-word;
        }
        .default-button {
            color: #ffffff;
            font-family: arial, helvetica, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: bold;
            line-height: 1.15;
            text-decoration: none;
            word-break: break-word;
        }
        .default-heading1 {
            color: #1f2d3d;
            font-family: arial, helvetica, sans-serif;
            font-size: 36px;
            word-break: break-word;
        }
        .default-heading2 {
            color: #1f2d3d;
            font-family: arial, helvetica, sans-serif;
            font-size: 32px;
            word-break: break-word;
        }
        .default-heading3 {
            color: #1f2d3d;
            font-family: arial, helvetica, sans-serif;
            font-size: 24px;
            word-break: break-word;
        }
        .default-heading4 {
            color: #1f2d3d;
            font-family: arial, helvetica, sans-serif;
            font-size: 18px;
            word-break: break-word;
        }
        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: inherit !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }
        .no-show-for-you {
            border: none;
            display: none;
            float: none;
            font-size: 0;
            height: 0;
            line-height: 0;
            max-height: 0;
            mso-hide: all;
            overflow: hidden;
            table-layout: fixed;
            visibility: hidden;
            width: 0;
        }
    </style>
    <!--[if mso]>
        <xml>
            <o:OfficeDocumentSettings> <o:AllowPNG /> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings>
        </xml>
    <![endif]-->
    <style type="text/css">
        a:link {
            color: #0092ff;
            text-decoration: underline;
        }
    </style>
</head>
<body yahoo="fix" text="#3b3f44" link="#0092ff">
    <div class="moz-text-html" lang="x-unicode">
        <!--[if mso]> <img width="1" height="1" src="https://9f4lr.r.bh.d.sendibt3.com/mk/op/sh/1f8JAEiNUvxLDIiLD2Jbnxbwbl/kDfF20r_ac-G" /> <![endif]-->
        <!--[if !mso]> <!---->
        <img style="display: none;" src="https://9f4lr.r.bh.d.sendibt3.com/mk/op/sh/1f8JAEiNUvxLDIiLD2Jbnxbwbl/kDfF20r_ac-G" />
        <!-- <![endif]-->
        <table role="presentation" class="nl2go-body-table" style="width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td>
                        <table role="presentation" class="r0-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                            <tbody>
                                <tr>
                                    <td class="r1-i" style="background-color: #ffffff;" valign="top">
                                        <table role="presentation" class="r3-o" style="table-layout: fixed; width: 600px;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
                                            <tbody>
                                                <tr>
                                                    <td class="r4-i">
                                                        <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                            <tbody>
                                                                <tr>
                                                                    <th class="r5-c" style="font-weight: normal;" width="100%" valign="middle">
                                                                        <table role="presentation" class="r6-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td class="r7-i" style="padding-left: 10px; padding-right: 10px;" valign="top">
                                                                                        <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td class="r8-c" align="center">
                                                                                                        <table
                                                                                                            role="presentation"
                                                                                                            class="r9-o"
                                                                                                            style="table-layout: fixed; width: 570px;"
                                                                                                            width="570"
                                                                                                            cellspacing="0"
                                                                                                            cellpadding="0"
                                                                                                            border="0"
                                                                                                        >
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td class="r10-i" style="font-size: 0px; line-height: 0px; padding-bottom: 15px; padding-top: 15px;">
                                                                                                                        <img
                                                                                                                            src="https://9f4lr.img.bh.d.sendibt3.com/im/sh/-rud-DZ7sxLx.png?u=7126MWSP0tEIB8aZ3EY01qppgPd"
                                                                                                                            style="display: block; width: 100%;"
                                                                                                                            sib_img_id="2"
                                                                                                                            width="570"
                                                                                                                            border="0"
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table role="presentation" class="r3-o" style="table-layout: fixed; width: 600px;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
                            <tbody>
                                <tr>
                                    <td valign="top">
                                        <table role="presentation" class="r12-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                            <tbody>
                                                <tr>
                                                    <td class="r13-i" style="background-color: #ffffff; padding-bottom: 20px; padding-top: 20px;">
                                                        <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                            <tbody>
                                                                <tr>
                                                                    <th class="r14-c" style="font-weight: normal;" width="100%" valign="top">
                                                                        <table role="presentation" class="r6-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td class="r7-i" style="padding-left: 15px; padding-right: 15px;" valign="top">
                                                                                        <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td class="r11-c" align="center">
                                                                                                        <table
                                                                                                            role="presentation"
                                                                                                            class="r0-o"
                                                                                                            style="table-layout: fixed; width: 570px;"
                                                                                                            width="570"
                                                                                                            cellspacing="0"
                                                                                                            cellpadding="0"
                                                                                                            border="0"
                                                                                                        >
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td class="r10-i" style="font-size: 0px; line-height: 0px; padding-bottom: 15px; padding-top: 15px;">
                                                                                                                        <img
                                                                                                                            src="https://9f4lr.img.bh.d.sendibt3.com/im/sh/Nc63IUcabePS.jpg?u=7126MWSP0tEIBiqqUB5fSltHLgj"
                                                                                                                            style="display: block; width: 100%;"
                                                                                                                            sib_img_id="8"
                                                                                                                            width="570"
                                                                                                                            border="0"
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td class="r11-c" align="center">
                                                                                                        <table
                                                                                                            role="presentation"
                                                                                                            class="r0-o"
                                                                                                            style="table-layout: fixed; width: 254px;"
                                                                                                            width="254"
                                                                                                            cellspacing="0"
                                                                                                            cellpadding="0"
                                                                                                            border="0"
                                                                                                        >
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td class="r15-i" style="font-size: 0px; line-height: 0px; padding-top: 15px;">
                                                                                                                        <img
                                                                                                                            src="https://9f4lr.img.bh.d.sendibt3.com/im/sh/g8uqzvMwDCCc.png?u=7126MWSP0tEIBotYj0BHMvOqxZF"
                                                                                                                            style="display: block; width: 100%;"
                                                                                                                            sib_img_id="9"
                                                                                                                            width="254"
                                                                                                                            border="0"
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table role="presentation" class="r12-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                            <tbody>
                                                <tr>
                                                    <td class="r13-i" style="background-color: #ffffff; padding-bottom: 20px; padding-top: 20px;">
                                                        <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                            <tbody>
                                                                <tr>
                                                                    <th class="r14-c" style="font-weight: normal;" width="100%" valign="top">
                                                                        <table role="presentation" class="r6-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td class="r7-i" style="padding-left: 15px; padding-right: 15px;" valign="top">
                                                                                        <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td class="r16-c" align="left">
                                                                                                        <table
                                                                                                            role="presentation"
                                                                                                            class="r17-o"
                                                                                                            style="table-layout: fixed; width: 100%;"
                                                                                                            width="100%"
                                                                                                            cellspacing="0"
                                                                                                            cellpadding="0"
                                                                                                            border="0"
                                                                                                        >
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        class="r18-i nl2go-default-textstyle"
                                                                                                                        style="
                                                                                                                            color: #3b3f44;
                                                                                                                            font-family: arial, helvetica, sans-serif;
                                                                                                                            font-size: 16px;
                                                                                                                            word-break: break-word;
                                                                                                                            line-height: 1.5;
                                                                                                                            padding-bottom: 15px;
                                                                                                                            padding-top: 15px;
                                                                                                                            text-align: center;
                                                                                                                        "
                                                                                                                        valign="top"
                                                                                                                        align="center"
                                                                                                                    >
                                                                                                                        <div>
                                                                                                                            <p style="margin: 0;">
                                                                                                                                <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                    <i>Gentile ${titleVariable} ${name},</i>
                                                                                                                                </span>
                                                                                                                            </p>
                                                                                                                            <p style="margin: 0;">
                                                                                                                                <br />
                                                                                                                                <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                    <i>
                                                                                                                                        grazie per aver acquistato l’orologio ${desc_brand} presso la nostra Boutique di ${desc_bussines}. Il nostro
                                                                                                                                        obiettivo è soddisfare ogni giorno le esigenze dei nostri clienti offrendo un servizio
                                                                                                                                        d’eccellenza oltre che un prodotto di alto livello. Per questo motivo vogliamo ringraziarLa
                                                                                                                                        nell’aver riposto la fiducia nella nostra realtà e per qualsiasi ulteriore informazione
                                                                                                                                        siamo a sua disposizione.
                                                                                                                                    </i>
                                                                                                                                </span>
                                                                                                                                <br />
                                                                                                                                <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                    <i>Cogliamo l’occasione per porgerLe i nostri più Cordiali Saluti,</i>
                                                                                                                                </span>
                                                                                                                            </p>
                                                                                                                            <p style="margin: 0;">
                                                                                                                                <br />
                                                                                                                                <span style="color: #7a7878; font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                                                    <i><strong>Capone Gioiellerie</strong></i>
                                                                                                                                </span>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table role="presentation" class="r12-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                            <tbody>
                                <tr>
                                    <td class="r1-i" style="background-color: #ffffff;" valign="top">
                                        <table role="presentation" class="r3-o" style="table-layout: fixed; width: 600px;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
                                            <tbody>
                                                <tr>
                                                    <td class="r20-i" style="padding-bottom: 20px; padding-top: 20px;">
                                                        <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                            <tbody>
                                                                <tr>
                                                                    <th class="r14-c" style="font-weight: normal;" width="100%" valign="top">
                                                                        <table role="presentation" class="r6-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td class="r7-i" style="padding-left: 15px; padding-right: 15px;" valign="top">
                                                                                        <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td class="r21-c" style="padding-bottom: 30px; padding-top: 30px;" align="center">
                                                                                                        <table
                                                                                                            role="presentation"
                                                                                                            style="
                                                                                                                border-top-style: solid;
                                                                                                                background-clip: border-box;
                                                                                                                border-top-color: #4a4a4a;
                                                                                                                border-top-width: 1px;
                                                                                                                font-size: 1px;
                                                                                                                line-height: 1px;
                                                                                                            "
                                                                                                            width="100%"
                                                                                                            height="1"
                                                                                                            cellspacing="0"
                                                                                                            cellpadding="0"
                                                                                                            border="0"
                                                                                                        >
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td style="font-size: 0px; line-height: 0px;" height="0">­</td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table role="presentation" class="r22-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                            <tbody>
                                <tr>
                                    <td class="r1-i" style="background-color: #ffffff;" valign="top">
                                        <table role="presentation" class="r3-o" style="table-layout: fixed; width: 600px;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
                                            <tbody>
                                                <tr>
                                                    <td class="r20-i" style="padding-bottom: 20px; padding-top: 20px;">
                                                        <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                            <tbody>
                                                                <tr>
                                                                    <th class="r14-c" style="font-weight: normal;" width="100%" valign="top">
                                                                        <table role="presentation" class="r6-o" style="table-layout: fixed; width: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td class="r7-i" style="padding-left: 15px; padding-right: 15px;" valign="top">
                                                                                        <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td class="r16-c" align="left">
                                                                                                        <table
                                                                                                            role="presentation"
                                                                                                            class="r23-o"
                                                                                                            style="table-layout: fixed; width: 100%;"
                                                                                                            width="100%"
                                                                                                            cellspacing="0"
                                                                                                            cellpadding="0"
                                                                                                            border="0"
                                                                                                        >
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        class="r24-i nl2go-default-textstyle"
                                                                                                                        style="
                                                                                                                            font-family: arial, helvetica, sans-serif;
                                                                                                                            word-break: break-word;
                                                                                                                            color: #3b3f44;
                                                                                                                            font-size: 18px;
                                                                                                                            line-height: 1.5;
                                                                                                                            text-align: center;
                                                                                                                        "
                                                                                                                        valign="top"
                                                                                                                        align="center"
                                                                                                                    >
                                                                                                                        <div>
                                                                                                                            <p style="margin: 0; font-size: 14px;">
                                                                                                                                PISA - VIAREGGIO - FORTE DEI MARMI - PONTEDERA - LIVORNO - VICOPISANO
                                                                                                                            </p>
                                                                                                                            <p style="margin: 0; font-size: 14px;">&nbsp;</p>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</body>
    `);
};
