import React, { useEffect, useState } from "react";
import { TextInput, TouchableOpacity, View } from "react-native";
import { Image } from "react-native-elements";
import { stylesStatisticheMagazzino } from "../../GeneralStyles/Statistiche";
import { SelectValues } from "../../interfaces/calender";
import { LabelStatische } from "./LabelStatische";

interface Props {
  bussines: SelectValues[];
  handleChangeCheck: (value: string) => void;
  handleCheck: (value: string) => boolean;
}

interface CityProps {
  handleSearch: (search: string) => void;
  value: string;
}

export const BussinesVenditeProdotti = ({ bussines, handleChangeCheck, handleCheck }: Props) => {
  return (
    <div className="col-12">
      {bussines?.map(({ label, value }) => (
        <div key={value}>
          <LabelStatische
            name={label}
            id={value}
            marginleft={true}
            checked={handleCheck(value)}
            onChange={handleChangeCheck}
          />
          <br />
        </div>
      ))}
    </div>
  );
};

export const SearchByCity = ({ handleSearch, value }: CityProps) => {
  const { search, setSearch } = useSearch({ handleSearch, value });
  return (
    <div className="col-12 d-flex">
      <TextInput
        placeholder="Cerca..."
        style={[
          stylesStatisticheMagazzino.input,
          stylesStatisticheMagazzino.subSearch,
        ]}
        onChangeText={(evt) => setSearch(evt)}
        value={search}
      />
      <TouchableOpacity
        onPress={() => handleSearch(search)}
        style={{
          margin: "auto",
        }}
      >
        <View
          style={[
            stylesStatisticheMagazzino.viewDisable,
            stylesStatisticheMagazzino.search,
            { paddingLeft: 10 }
          ]}
        >
          <Image
            style={stylesStatisticheMagazzino.subIconSearch}
            source={require("../../assets/icons/icon-search.png")}
          />
        </View>
      </TouchableOpacity>
    </div>
  );
};

//HOOK PARA EL BUSCADOR
const useSearch = ({ handleSearch, value }: CityProps) => {
  const [search, setSearch] = useState(value);
  useEffect(() => {
    search.length === 0 && handleSearch("");
  }, [search]);
  return {
    search,
    setSearch,
  };
};
