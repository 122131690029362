import { NativeStackScreenProps } from '@react-navigation/native-stack'
import React, { useEffect } from 'react'
import { Navbar } from '../../../components/Navbar'
import NavProfile from '../../../components/NavProfile/NavProfile'
import AggiungiCercaContainer from '../../../components/Btn-AggiungiCerca/AggiungiCercaContainer'
import { useAuth } from '../../../hooks/useAuth'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useProfile } from '../../../components/NavProfile/hook/useProfile'
import ShowContentSidebar from '../../../components/NavProfile/ShowContentSidebar'
import { NavigationProps } from '../../../navigator/NavigatorConfig'
//import icono from "../../../assets/images/SergioCaponeLogo.jpg"
import { referenciaByAtelier } from './services/CrmAtelierService';
interface Props extends NativeStackScreenProps<any, any> {

}
const CrmAtelier = ({ route }: Props) => {
    const { user } = useAuth();
    const isFocused = useIsFocused();
    const { sidebarVisible, setSidebarVisible, toggleSidebar } = useProfile()
    const navigation = useNavigation<NavigationProps>();

    const back = () => {
        console.log('back');
        navigation.navigate("HomeSCDueScreen", { refresh: true })
    }

    return (
        <>
            <ShowContentSidebar
                sidebarVisible={sidebarVisible}
                toggleSidebar={toggleSidebar}
            />
            <div className=" container-layout" >
                <div className="row row-layout" >
                    <div className={` ${sidebarVisible ? 'col-12 d-block' : 'col-lg-3 col-md-none d-none d-md-non d-lg-block'} sidebar-container-layout sidebar-transition`}  >
                        <Navbar activeNav={isFocused} componentName={route.name} />
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12 content-container-layout" style={{ paddingLeft: '1.5rem' }}>
                        <NavProfile
                            showMenuBack={true}
                            sidebarVisible={sidebarVisible}
                            toggleSidebar={toggleSidebar}
                            goBack={() => back()}
                            goBackName="Pannello di controllo"
                        />
                        <div className='col-md-12 d-md-block d-lg-none' style={{ marginTop: '27vh' }}></div>
                        <div className='col-lg-12 d-lg-block d-md-none' style={{ marginTop: '5vh' }}></div>
                        <div className="w-100" style={{ paddingTop: '11%' }}>
                            <AggiungiCercaContainer
                                component='AggiungiCrmAtelier'
                                title='Aggiungi cliente'
                                route={route}
                                icono={require("../../../assets/icons-step-due/Icona-account2.png")}
                                propsImage={{
                                    style: {
                                        width: '65px',
                                        top: '-21px',
                                        left: '-17px',
                                    }
                                }}
                            />
                            <AggiungiCercaContainer
                                component='ClientListAtelierScreen'
                                title='Cerca cliente    '
                                route={route}
                                icono={require("../../../assets/iconoskubitsoft/Prospetto-02-01-Refact.png")}
                                propsImage={{
                                    style: {
                                        width: '77px',
                                        top: '-22px',
                                        left: '-22px',
                                    }
                                }}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default CrmAtelier