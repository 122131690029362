import React from "react";
import { SelectValues } from "../../interfaces/calender";
import { LabelStatische } from "./LabelStatische";

interface Props {
  bussines: SelectValues[];
  handleChangeCheck: (value: string) => void;
  handleCheck: (value: string) => boolean;
}
export const BussinesStatistiche = ({ bussines, handleChangeCheck, handleCheck }: Props) => {
  return (
    <div className="col-12">
      {bussines?.map(({ label, value }) => (
        <div key={value}>
          <LabelStatische
            name={label}
            id={value}
            marginleft={true}
            checked={handleCheck(value)}
            onChange={handleChangeCheck}
          />
          <br />
        </div>
      ))}
    </div>
  );
};
