import { SubmitHandler, UseFormRegisterReturn, useForm } from "react-hook-form";
import { IFormNuovaListaOrdiniAtelier } from "../interfaces/IFormNuovaListaOrdiniAtelier";

type Props = {
    operation: 'edit' | "delete" | 'add';
    onBeforeSubmit?: () => void;
    onBeforeAnulla?: () => void
}

const defaultValues: IFormNuovaListaOrdiniAtelier = {

}
const useFormNuovaListaOrdiniAtelier = ({ operation, onBeforeSubmit, onBeforeAnulla }: Props) => {

    //*useForm 
    const { register, handleSubmit, reset } = useForm({ defaultValues });

    //*onSubmit Functions
    const onSubmit: SubmitHandler<IFormNuovaListaOrdiniAtelier> = (data) => {
        console.log(data);
        switch (operation) {
            case 'add':
                console.log('add')
                break;
            case 'edit':
                console.log('edit')
                break;
            case 'delete':
                console.log('delete')
                break;
            default:
                break;
        }
        onBeforeSubmit();
    }

    const handleAnulla = () => {
        reset();
        onBeforeAnulla();
    }

    return {
        //*States
        //*Funcitions Handlers
        register, handleSubmit, onSubmit, handleAnulla,
    }
}

export default useFormNuovaListaOrdiniAtelier