import { StatusBar, StyleSheet } from "react-native";

export const stylesRepair = StyleSheet.create({
    areaView: {
        flex: 1,
        paddingTop: StatusBar.currentHeight,
        backgroundColor: "white",
    },
    areaContent: {
        flex: 1,
        paddingHorizontal: 30,
        paddingVertical: 20,
    },
    sectionTitle: {
        fontWeight: "500",
        fontSize: 20,
        alignSelf: "center",
    }
});