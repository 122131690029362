import { Collapse } from "react-collapse";
import { BsFillBellFill } from "react-icons/bs";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

interface Props {
  active: boolean;
  subject: string;
  handleView: () => void;
  read: boolean;
  user: string;
  message: string;
  dateExport: string;
}
export const NotifyDetails = ({
  active,
  subject,
  read,
  user,
  handleView,
  message,
  dateExport
}: Props) => {
  return (
    <div
      className={`card notify-details ${read ? "read" : ""}`}
      onClick={() => handleView()}
    >
      <div className="card-header border-0">
        <h2 className="mb-0">
          <button className="btn btn-navigate" role={"button"}>
            <div className="select-contain">
              {active ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            <i className="icon-custom">
              <BsFillBellFill className="d-inline-block" />
            </i>
            {subject}
          </button>
        </h2>
      </div>
      <Collapse isOpened={active}>
        <div className="card-body">
          <p>{user}</p>
          <p>{message}</p>
          <p>{dateExport}</p>
        </div>
      </Collapse>
    </div>
  );
};
