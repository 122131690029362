import { StyleSheet } from "react-native";
import CSS from "csstype";

export const stylesStatisticheMagazzino = StyleSheet.create({
  header: {
    paddingHorizontal: 12,
    backgroundColor: "white",
  },
  label: {
    alignSelf: "flex-start",
    marginTop: 25,
    marginBottom: 10,
    fontWeight: "500",
    fontSize: 20,
  },
  labelFilter: {
    alignSelf: "flex-start",
    marginTop: 25,
    marginBottom: 10,
    fontSize: 18,
    fontWeight: "500",
    fontFamily: "Arsapia Regular font",
  },
  labelError: {
    fontSize: 18,
    marginLeft: 35,
    marginTop: 15,
    fontFamily: "Arsapia Regular font",
    color: "red",
  },
  input: {
    height: 45,
    width: "100%",
    borderWidth: 2,
    fontFamily: "Arsapia Regular font",
    padding: 6,
    borderRadius: 50,
    marginRight: "1%",
  },
  arrowDown: {
    width: 20,
    height: 20,
    marginTop: 18,
    tintColor: "#808080",
    alignSelf: "center",
  },
  viewDisable: {
    backgroundColor: "black",
    borderRadius: 50,
    justifyContent: "center",
    width: 40,
    height: 40,
    marginTop: "2%",
  },
  search: {
    marginLeft: "-100%",
  },
  subSearch: {
    alignSelf: "flex-end",
    borderColor: "#A4A4A4",
    paddingRight: 2,
    paddingLeft: 15,
    fontSize: 20,
  },
  subIconSearch: {
    width: 20,
    height: 20,
    tintColor: "white",
    alignSelf: "center",
  },
  iconFilterPer: {
    marginTop: 3,
    width: 42,
    height: 42,
    marginLeft: -7,
    tintColor: "black",
    alignSelf: "center",
  },
  labelFilterPer: {
    marginTop: 12,
    fontSize: 20,
    marginLeft: 15,
    fontFamily: "Arsapia Regular font",
    color: "black",
  },
  wrapper: {
    flex: 1,
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
});

export const tableHeader: CSS.Properties = {
  textAlign: "center",
  fontWeight: "400",
  verticalAlign: "middle",
};

export var tableRow: CSS.Properties = {
  textAlign: "center",
  fontWeight: "400",
  verticalAlign: "middle",
};

const stylesGraphMagazzino = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    paddingHorizontal: 47,
    paddingBottom: 40,
    alignContent: "center",
    justifyContent: "center",
  },
  label: {
    fontFamily: "Arsapia Regular font",
    fontSize: 18,
  },
  labelBotton: {
    position: "relative",
    top: 50,
    right: 20,
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Arsapia Regular font",
  },
  image: {
    position: "relative",
    top: 47,
    width: 30,
    height: 30,
    tintColor: "#999999",
    alignSelf: "center",
  },
});

export const colorsBussines = [
  "#FFE100",
  "#CA6C59",
  "#FF000C",
  "#007EF2",
  "#FF9600",
  "#6CC68D",
  "#FFE4E1",
  "#ADFF2F",
  "#BC8F8F",
  "#8A2BE2"
];
export const colorsBussinesRepairs = [
  "#7F8487",
  "#850000",
  "#19376D",
  "#609966",
  "#E7B10A",
  "#6CC68D",
  "#FFE4E1",
  "#ADFF2F",
  "#BC8F8F",
  "#8A2BE2"
];

export const stylesInputDate = {
  height: 45,
  width: "100%",
  borderWidth: 2,
  fontFamily: "Arsapia Regular font",
  padding: 6,
  borderRadius: 50,
  marginRight: "1%",
  alignSelf: "flex-end",
  borderColor: "#A4A4A4",
  paddingRight: 2,
  paddingLeft: 15,
  fontSize: 20,
}

export const MESES_COMPLETED = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre'
]