import React, { useState } from "react";
import { todayFormatDate } from "../../utils/helpers";
import ReactExport from "react-data-export/";
import { ExportListMagazzino } from "../../services/MagazzinoRefactor";
import useExportExcelMagazzino from "../../hooks/useExportExcelMagazzino";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const nameFileExport = "Tabella Magazzino " + todayFormatDate
interface Props {
  magazzinoList: any;
  setLoading: any;
  setgriglia: any;
  griglia: any
}
export const ExportList = ({ magazzinoList, setLoading, setgriglia, griglia }: Props) => {

  const { donwnloadExcel, DataSet, handleDataExport } = useExportExcelMagazzino(magazzinoList, setLoading)

  return (
    <div className="row mt-5 px-2">
      <div className="col-5"></div>
      <div
        className="col-7 d-flex justify-content-end"
        style={{ marginTop: -20 }}
      >
        <button className="dowloandPdf" style={{
          width: 200,
          fontSize: 18,
          marginRight: '15px',
          paddingTop: 5,
          paddingBottom: 5,
          borderWidth: 1,
          borderBlockColor: "black",
          fontWeight: "500",
        }}
          onClick={() => { griglia ? setgriglia(false) : setgriglia(true) }}
        >
          {griglia ? 'Vista a tabella' : 'Vista a griglia'}
        </button>
        {
          (donwnloadExcel &&
            DataSet[0].data.length > 0) && (
            <ExcelFile filename={nameFileExport} hideElement>
              <ExcelSheet dataSet={DataSet} name="Lista prodotti" />
            </ExcelFile>
          )
        }
        <button
          type="button"
          onClick={() => { handleDataExport() }}
          className="dowloandPdf"
          style={{
            width: 200,
            fontSize: 18,
            paddingTop: 5,
            paddingBottom: 5,
            borderWidth: 1,
            borderBlockColor: "black",
            fontWeight: "500",
          }}
        >
          Esporta lista
        </button>
      </div>
    </div>
  );
};
