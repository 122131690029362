import { Text } from "react-native";
import { SalesOfMonth as Sales } from "../../interfaces/statistiche";
import { NotResults } from "./NotResults";
import { CharPieFormat,  } from "../../services/interfaces/GetBussinesCount";

interface Props {
  sales: CharPieFormat[];
}
export const SalesOfMonth = ({ sales }: Props) => {
  return (
    <div className="row">
      {sales.map((item, index) => (
        <div className="col-12 col-md-6 col-lg-4 col-xl-3" key={index}>
          <div className="card radius-20">
            <div className="card-body position-relative">
              <div className="d-flex align-items-center mt-1">
                <h2 className="negozio">{item.title}</h2>
              </div>
              <div className="d-flex align-items-center mt-4">
                <div>
                  <img
                    src={require("../../assets/icons-step-due/Icona-carrello.png")}
                    alt=""
                  />
                </div>
                <div className="ms-auto">
                  <Text
                    style={{
                      color: "#1d1d1d",
                      fontWeight: "700",
                      fontSize: 18,
                    }}
                  >
                    {item.value}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {(sales.length === 0) && <div className="col-12"><NotResults /></div>}
    </div>
  );
};
